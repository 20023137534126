import { Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { H2, SmallTextSecondaryMedium } from '../../../styles/StylesV3Main';

const StyledButtonRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 24px;
	justify-content: flex-end;
	@media (max-width: 991.98px) {
		padding-right: 0px;
	}
`;

const StyledHeading = styled(H2)`
	margin-bottom: 8px;
`;

const StyledButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	border-radius: 3px;
	border: none;

	width: 48px;
	height: 40px;

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	font-family: 'Roobert Regular';
	font-style: normal;

	background: rgba(25, 26, 28, 1);
	@media (max-width: 991.98px) {
		width: 100%;
	}
`;

const SuccessfulIntegrationModal = ({ showModal, onHide }) => {
	return (
		<Modal show={showModal} animation={false} centered onHide={onHide}>
			<Modal.Body style={{ padding: '24px' }}>
				<StyledHeading>Sync complete</StyledHeading>

				<SmallTextSecondaryMedium>
					Your newly synced employees will show up under the invites tab.
				</SmallTextSecondaryMedium>

				<Row noGutters as={StyledButtonRow}>
					<StyledButton
						onClick={() => {
							onHide();
						}}
					>
						OK
					</StyledButton>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default SuccessfulIntegrationModal;
