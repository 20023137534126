import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useProductContext } from '../../contexts/productContext';
import Carousel from '../utilities/Carousel';
import CarouselCard from '../carousels/Cards/CarouselCard';

const MainContainer = styled.div`
	width: 100%;
`;

const RecommendedProductCarousel = ({ baseProduct, title }: any) => {
	const { products, recommendedProducts } = useProductContext();
	const [currentList, setCurrentList] = useState();
	const gettingRecommended = useRef(false);

	useEffect(() => {
		const getRecommendedProducts = async () => {
			if (baseProduct) {
				gettingRecommended.current = baseProduct;
				try {
					const recoProducts = await recommendedProducts(baseProduct);
					setCurrentList(recoProducts);
				} catch (ex) {
					gettingRecommended.current = null;
				}
			}
		};
		if (gettingRecommended.current !== baseProduct) getRecommendedProducts();
	}, [recommendedProducts, baseProduct]);

	if (!baseProduct) {
		return <p>Loading ...</p>;
	}

	return (
		<MainContainer style={{ overflowX: 'hidden' }}>
			<Carousel
				style={{
					marginBottom: '40px',
				}}
				loading={!currentList}
				title={title}
				itemPadding={16}
				draggable={false}
				items={
					currentList &&
					currentList
						.filter(
							(item) =>
								products.filter((product) => product.id === item.id).length > 0
						)
						.map((item) => (
							<CarouselCard
								key={item.id}
								product={
									products.filter((product) => product.id === item.id)[0]
								}
							/>
						))
				}
			/>
		</MainContainer>
	);
};

export default RecommendedProductCarousel;
