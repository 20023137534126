import styled from 'styled-components';

export const BackgroundWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${(props) => (props.align ? props.align : 'center')};
	background: ${(props) =>
		props.background
			? props.background
			: props.theme.mainColors.color.background};

	@media (max-width: 992px) {
		max-width: 100%;
	}
`;

export const MenuBackgroundWrapper = styled.div`
	width: 100%;
	height: 88px;
	transition: opacity 300ms;
	position: sticky;
	top: 0px;
	z-index: 9999999;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	background: #f8f8f8;

	@media (max-width: 992px) {
		max-width: 100%;
		background: white;
	}
`;

export const Container = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1250px;
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

export const Circle = styled.button`
	width: ${(props) => (props.size ? props.size : '48px')};
	height: ${(props) => (props.size ? props.size : '48px')};
	border-radius: 50%;
	cursor: pointer;
	background: ${(props) => (props.bg ? props.bg : 'rgba(0,0,0,0.05)')};
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		background: ${(props) => (props.bg ? props.bg : 'rgba(0, 0, 0, 0.1)')};
	}
`;

export const DashHorizontal = styled.div`
	height: 4px;
	width: 120px;
	margin: 0px 4px;
	background: ${(props) => (props.bg ? props.bg : '#000000')};
	border-radius: 2px;
	@media (max-width: 500px) {
		width: 80px;
	}
`;

export const BorderedCircle = styled.div`
	width: ${(props) => (props.size ? props.size : '48px')};
	height: ${(props) => (props.size ? props.size : '48px')};
	border-radius: 50%;
	cursor: pointer;
	background: transparent;
	border-width: ${(props) => (props.borderWidth ? props.borderWidth : '1px')};
	border-color: ${(props) =>
		props.disabled
			? 'rgba(0,0,0,0.1)'
			: props.borderColor
			? props.borderColor
			: 'transparent'};
	border-style: solid;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const WarningDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 84px;
	padding: 16px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	color: ${(props) => props.theme.mainColors.color.textWarning};
	background: ${(props) => props.theme.mainColors.color.backgroundWarning};
	border-radius: 3px;
`;

export const WarningText = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	text-align: center;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textWarning};
	margin: 0px;
`;

export const RoundedCard = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 32px;
	padding: 10px;
	border-radius: 16px;
	background: ${(props) => props.background};
	color: ${(props) => props.color};
`;

export const ReadMoreBtn = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 32px;
	padding: 10px;
	margin: 5px 0px 0px;
	border-radius: 3px;
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.mainColors.color.separator};
	color: ${(props) => props.theme.mainColors.color.textPrimary};
`;

export const CheckUnfilled = styled.div`
	width: 24px;
	height: 24px;
	border: 1px solid ${(props) => props.theme.mainColors.color.separator};
	border-radius: 50%;
	position: absolute;
	right: 16px;
	top: 16px;
`;

export const CheckFilled = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	border: 1px solid ${(props) => props.theme.mainColors.color.separator};
	border-radius: 50%;
	position: absolute;
	right: 16px;
	top: 16px;
`;

export const RowView = styled.div`
	width: 100%;
	height: fit-content;
	padding: 32px 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
	border-bottom: ${(props) =>
		props.border
			? `1px solid ${props.theme.mainColors.color.separator}`
			: 'none'};
`;

export const StyledAddOnView = styled.input.attrs({ type: 'file' })`
	width: 100%;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	color: transparent;
	&::-webkit-file-upload-button {
		visibility: hidden;
	}
	&::before {
		content: 'Add a PCR test (+10 pts)';
		color: black;
		display: flex;
		justify-content: start;
		align-items: center;
		background: #fff;
		border: 1px solid black;
		border-radius: 3px;
		height: 56px;
		position: relative;

		padding-left: 16px;
	}
	.&:hover::before {
		border-color: red;
	}
	.&:active {
		outline: 0;
	}
	&:focus {
		outline: 0;
	}
`;

export const StyledTextArea = styled.textarea`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 10px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	width: 100%;
	margin-top: ${(props) => props.marginT};
	height: ${(props) => (props.height ? props.height : '80px')};
	padding-left: 16px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

export const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: 25px;
	color: red;
	width: 100%;
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
`;

export const StyledSubtitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: 25px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	width: 100%;
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
`;

export const SelectStyled = styled.select`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	background: rgba(0, 0, 0, 0.03);
	margin-top: ${(props) => props.marginT};
	cursor: pointer;
	border: none;
	border-radius: 3px;
	width: 100%;
	height: 56px;
	padding: 0px 16px;
	padding-right: 30px;
	display: inline-block;
	box-sizing: border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url('/images/products/ArrowDown.svg');
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.7em auto, 100%;
	&::-ms-expand {
		display: none;
	}
	&:focus {
		outline: 0;
	}
`;

export const StyledLabel = styled.label`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: start;
	line-height: 17px;
	margin-bottom: 0px;
	margin: 24px 0px 8px 0px;
	box-sizing: border-box;
	width: 100%;

	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

export const StyledInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: rgba(0, 0, 0, 0.03);
	margin-top: ${(props) => props.marginT};
	border-radius: 3px;
	width: 100%;
	height: 56px;
	padding-left: 16px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

export const QuantityInput = styled.input`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	background: transparent;
	width: 20px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

export const OrderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 48px;
	width: 560px;
	height: fit-content;
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 26px;
	@media (max-width: 992px) {
		width: 100%;
		padding: 24px;
	}
`;
