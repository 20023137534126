import React from 'react';
import { useCurrentTeam } from '../../hooks';
import CreateJunoCard from './CreateJunoCard';
import CardDetail from './CardDetail';

const JunoCardRouter = () => {
	const currentTeam = useCurrentTeam();
	return !currentTeam.teamMember?.stripeCardholderId ? (
		<CreateJunoCard />
	) : (
		<CardDetail />
	);
};

export default JunoCardRouter;
