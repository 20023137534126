import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const loadingAnimation = keyframes` 
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const ReasonPlaceholder = styled.div`
	width: 90%;

	> div {
		background: #ebebeb;
		opacity: 0.5;
		border-radius: 50px;
		height: 12px;
		margin: 0px 0px 5px 5px;

		:first-child {
			width: 100%;
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;
			animation: ${css`
					${loadingAnimation}`} 2.5s ease-in-out infinite;
		}

		:nth-child(2) {
			width: 100%;
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;
			animation: ${css`
					${loadingAnimation}`} 2.5s ease-in-out 0.75s infinite;
		}
	}
`;

const ReasonContainer = styled.div`
	background: transparent;
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	width: 100%;

	svg {
		margin-top: 4px;
	}
`;

const ImagePlaceholder = styled.div`
	width: 100%;
	height: 162px;
	min-height: 162px;
	background: linear-gradient(to right, #ffffff 0%, #ebebeb 50%, #ffffff 100%);
	animation: ${css`
			${loadingAnimation}`} 2.5s ease-in-out infinite;
`;

const TileContainer = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;

	height: 325px;

	background: #ffffff;
	box-shadow: 0px 0px 22.4px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	overflow: hidden;
	> div {
		margin-bottom: 20px;
		width: 100%;
		padding: 0 16px;

		:first-child {
			width: 100%;
			height: 162px;
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;

			animation: ${css`
					${loadingAnimation}`} 2.5s ease-in-out infinite;
		}
		:nth-child(2) {
			height: 22px;
			> :nth-child(1) {
				display: inline-block !important;
				background: red;
				width: 10%;
				height: 20px;
				border-radius: 50px;
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#ebebeb 50%,
					#ffffff 100%
				);
				background-size: 400% 400%;

				animation: ${css`
						${loadingAnimation}`} 2.5s ease-in-out infinite;
				width: 22px;
				height: 22px;
				border-radius: 50%;

				margin-right: 10px;
			}
			> :nth-child(2) {
				display: inline-block !important;
				background: blue;
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#ebebeb 50%,
					#ffffff 100%
				);
				background-size: 400% 400%;

				animation: ${css`
						${loadingAnimation}`} 2.5s ease-in-out infinite;
				display: inline-block;
				border-radius: 50px;
				width: 50%;
				height: 14px;
				line-height: 20px;
			}

			> :nth-child(3) {
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#ebebeb 50%,
					#ffffff 100%
				);
				background-size: 400% 400%;

				animation: ${css`
						${loadingAnimation}`} 2.5s ease-in-out infinite;
				margin-top: 12px;
				width: 100%;
				height: 14px;
				border-radius: 50px;
			}

			> :nth-child(4) {
				background: linear-gradient(
					to right,
					#ffffff 0%,
					#ebebeb 50%,
					#ffffff 100%
				);
				background-size: 400% 400%;

				animation: ${css`
						${loadingAnimation}`} 2.5s ease-in-out infinite;
				margin-top: 12px;
				width: 164px;
				height: 14px;
				border-radius: 50px;
			}
		}
	}
`;

const ProductCardSkeleton = () => {
	return (
		<div
			style={{
				margin: 0,
				padding: 0,
				flexGrow: 1,
				width: '260px',
				marginBottom: '90px',
			}}
		>
			<TileContainer>
				<ImagePlaceholder />
				<div>
					<div />
					<div
						style={{ marginTop: '12px', display: 'flex', alignItems: 'center' }}
					>
						<div />
						<div />
					</div>
					<div />
					<div />
				</div>
			</TileContainer>
			<ReasonContainer>
				<svg
					width="16"
					height="14"
					viewBox="0 0 16 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 5.15358C0 6.22575 0.164949 7.87524 1.64949 9.35979C2.96909 10.6794 7.34024 13.6485 7.50519 13.8134C7.67014 13.8959 7.83509 13.9784 8.00004 13.9784C8.16499 13.9784 8.32994 13.8959 8.49489 13.8134C8.65984 13.6485 13.031 10.7619 14.3506 9.35979C15.8351 7.87524 16.0001 6.22575 16.0001 5.15358C16.0001 2.67934 14.0207 0.699951 11.5465 0.699951C10.2269 0.699951 8.90726 1.44222 8.08251 2.59687C7.25777 1.44222 5.93817 0.699951 4.45363 0.699951C2.06187 0.699951 0 2.67934 0 5.15358Z"
						fill="#E3E3E3"
					/>
				</svg>

				<ReasonPlaceholder>
					<div />
					<div />
				</ReasonPlaceholder>
			</ReasonContainer>
		</div>
	);
};

export default ProductCardSkeleton;
