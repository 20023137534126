import React from 'react';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { H4, ParagraphGreyMD } from '../../styles/StylesV3Main';

import config from '../../config';

const ProviderInfoContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 64px 0px;

	@media (max-width: 992px) {
		padding: 64px 0px;
	}
`;

const CenterAlign = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const StyledServiceLogo = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	object-fit: cover;
	border: 1px solid ${(props) => props.theme.mainColors.color.separator};
`;

const Text = styled(ParagraphGreyMD)`
	padding: 0px 30%;
	text-align: center;
	@media (max-width: 992px) {
		padding: 0px 10%;
	}
`;

const ProviderInfo = ({ provider }) => {
	return (
		<ProviderInfoContainer>
			<CenterAlign>
				<StyledServiceLogo
					loading="lazy"
					src={
						provider.src
							? provider.src + '?width=120'
							: config.PLACEHOLDER_IMAGE
					}
				/>
				<H4 className="mt-4">{provider.title}</H4>
				<Text className="mt-1">
					<Markdown
						options={{
							forceBlock: true,
							overrides: {
								a: {
									props: {
										target: '_blank',
									},
								},
							},
						}}
					>
						{provider.description}
					</Markdown>
				</Text>
				{/* <VisitButton className="mt-2" bwidth={"fit-content"}>
          Visit Website
        </VisitButton> */}
			</CenterAlign>
		</ProviderInfoContainer>
	);
};

export default ProviderInfo;
