import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
	PSansText,
	PSansBoldText,
	PSansMediumText,
	TR,
	TH,
	TD,
} from '../../../styles/StylesV2';
import DataTable from '../../utilities/DataTable';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;

	@media (max-width: 992px) {
		position: absolute;
		left: 20px;
		bottom: -50px;
	}
	@media (max-width: 500px) {
		position: absolute;
		left: 20px;
		bottom: -140px;
	}
`;

const StyledTableHeader = styled(PSansMediumText)`
	color: rgba(0, 0, 0, 0.3);
	letter-spacing: 0.25px;
	font-size: 10px;
	line-height: 20px;
	text-transform: uppercase;
`;

const StyledMobileView = styled.div`
	display: none;
	width: 100%;
	positon: relative;
	@media (max-width: 991.98px) {
		display: block;
	}
`;

const StyledRowTableBody = styled(Row)`
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #e5e5e5;
	@media (max-width: 991.98px) {
		border-top: 1px solid #e5e5e5;
		padding-top: 15px;
		height: fit-content;
	}
`;

const StyledColumnTBody = styled(Col)`
	display: flex;

	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	@media (max-width: 992px) {
		flex-direction: column;
		align-items: start;
		justify-content: flex-start;
		margin-bottom: 15px;
	}
`;

const MobileTableHeader = styled(PSansMediumText)`
	display: none;
	@media (max-width: 992px) {
		display: block;
		color: rgba(0, 0, 0, 0.3);
		letter-spacing: 0.25px;
		font-size: 10px;
		line-height: 20px;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
`;
const DesktopView = styled.div`
	display: block;
	width: 100%;
	@media (max-width: 992px) {
		display: none;
	}
`;

const StyledP = styled(PSansText)`
	line-height: 160%;
	text-align: center;
	color: #000000;
	flex: none;
	flex-grow: 0;
	margin: 0px 8px 0px 0px;
`;

const Text = styled(PSansText)`
	margin-top: 5px;
`;

const AlignItems = styled.div`
	position: relative;
	margin-bottom: -20px;
`;

const StyledMemberRow = styled(Row)`
	position: absolute;
	left: 20px;
	@media (max-width: 992px) {
		bottom: 96px;
	}
	@media (max-width: 360px) {
		bottom: 100px;
	}
`;

const TeamTransactionsTable = ({ transactions }) => {
	const columns = useMemo(() => {
		return transactions
			? [
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Date</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'transactionDate', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<Text>{new Date(value).toLocaleDateString()}</Text>
						),
						flexGrow: 1,
					},

					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Account Name</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'teamName', // accessor is the "key" in the data
						Cell: ({ value, row }) => (
							<Text>{row.original.teamId ? value : 'Personal'}</Text>
						),
						flexGrow: 1,
					},
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Event</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'event', // accessor is the "key" in the data
						Cell: ({ value }) => <Text>{value}</Text>,
						flexGrow: 1,
					},
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Type</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'type', // accessor is the "key" in the data
						Cell: ({ value }) => <Text>{value}</Text>,
						flexGrow: 1,
					},
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Amount (pts)</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'amount', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<PSansBoldText style={{ marginTop: 5 }}>
								{`${value?.toLocaleString()} point${+value > 1 ? 's' : ''}`}
							</PSansBoldText>
						),
						flexGrow: 1,
					},
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Balance (pts)</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'balance', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<>
								<PSansBoldText style={{ marginTop: 5 }}>
									{`${value?.toLocaleString()} point${+value > 1 ? 's' : ''}`}
								</PSansBoldText>
							</>
						),
						flexGrow: 1,
					},
					{
						Header: (
							<AlignItems>
								<StyledTableHeader>Recipient</StyledTableHeader>
							</AlignItems>
						),
						accessor: 'recipient', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<Text>
								{value
									? value.firstName && value.lastName
										? value.firstName + ' ' + value.lastName
										: value.email
									: 'Team'}
							</Text>
						),
						flexGrow: 1,
					},
			  ]
			: [];
	}, [transactions]);

	const dataDisplay = useMemo(() => {
		return transactions
			? transactions
					.map((item) => ({
						...item,
						teamName: item.transactionDetails.team.name,
						event: item.transactionDetails.type,
						recipient: item.transactionDetails?.user || null,
					}))
					.filter((item) => item)
			: [];
	}, [transactions]);
	return (
		<>
			<DesktopView>
				<Row
					noGutters
					className={'justify-content-between align-items-center mb-4'}
				>
					<StyledDiv>
						<StyledP>
							{transactions?.length} Transaction
							{transactions?.length === 1 ? '' : 's'} available
						</StyledP>
					</StyledDiv>
				</Row>
				<DataTable
					columns={columns}
					data={dataDisplay}
					defaultSorts={[{ id: 'transactionDate', desc: true }]}
					TR={TR}
					TH={TH}
					TD={TD}
				/>
			</DesktopView>
			<StyledMobileView>
				<Row noGutters style={{ position: 'relative' }} className="mt-2">
					<StyledMemberRow>
						<StyledP>
							{transactions?.length} Transaction
							{transactions?.length === 1 ? '' : 's'} available
						</StyledP>
					</StyledMemberRow>
				</Row>
				{transactions.map((item, index) => (
					<Row noGutters as={StyledRowTableBody} key={index}>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Date</MobileTableHeader>
							<PSansText>
								{new Date(item.transactionDate).toLocaleDateString()}
							</PSansText>
						</Col>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Account Name</MobileTableHeader>
							<PSansText>
								{item.teamId ? item.transactionDetails.team.name : 'Personal'}
							</PSansText>
						</Col>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Event</MobileTableHeader>
							<PSansText>{item.transactionDetails.type}</PSansText>
						</Col>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Type</MobileTableHeader>
							<PSansText>{item.type}</PSansText>
						</Col>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Amount (pts)</MobileTableHeader>
							<PSansBoldText>{`${item.amount} point${
								+item.amount > 1 ? 's' : ''
							}`}</PSansBoldText>
						</Col>
						<Col lg xs={4} as={StyledColumnTBody}>
							<MobileTableHeader>Balance (pts)</MobileTableHeader>
							<PSansBoldText>{`${item.balance} point${
								+item.balance > 1 ? 's' : ''
							}`}</PSansBoldText>
						</Col>
					</Row>
				))}
			</StyledMobileView>
		</>
	);
};

export default TeamTransactionsTable;
