import { useMemo } from 'react';
import { useAuth0 } from '../contexts/react-auth0-spa';
import config from '../config';
import { useAxiosContext } from '../contexts/axiosContext';

const useClient = () => {
	const { getTokenSilently, isAuthenticated, setIsAuthenticated } = useAuth0();
	const axios = useAxiosContext();

	const client = useMemo(() => {
		const axiosClient = axios.create({
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			baseURL: config.api.HOST,
		});

		axiosClient.interceptors.request.use(async (config) => {
			const headers = { ...config.headers };
			if (isAuthenticated) {
				try {
					const token = await getTokenSilently();
					headers.Authorization = `Bearer ${token}`;
				} catch (e) {
					if (e.message === 'Login required') {
						setIsAuthenticated(false);
						return {
							...config,
							adapter: () => Promise.resolve({}),
						};
					}
					throw e;
				}
			}
			return {
				...config,
				headers,
			};
		});

		return axiosClient;
	}, [getTokenSilently, isAuthenticated]);

	return client;
};

export default useClient;
