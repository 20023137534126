import React, { useState } from 'react';
import styled from 'styled-components';
import { H5 } from '../../../../styles/StylesV3Main';
import { Menu, MenuItem } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';

const MenuItems = styled(MenuItem)`
	height: 56px;
	background: ${(props) => props.theme.mainColors.color.background};
`;

const Icon = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 0px;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	background-color: rgba(0, 0, 0, 0.05);
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

const ITEM_HEIGHT = 48;

type LongIconMenuProps = {
	handleDefaultChange: () => void;
	handleDelete: () => void;
};

const LongIconMenu = ({
	handleDefaultChange,
	handleDelete,
}: LongIconMenuProps) => {
	const [anchorEl, setAnchorEl] = useState<any | null>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Icon
				aria-label="More"
				aria-owns={open ? 'long-menu' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MdMoreVert size={24} color="#191A1c" />
			</Icon>
			<Menu
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				sx={{
					'& .MuiList-padding': {
						paddingBottom: 0,
						paddingTop: 0,
					},
					'& .MuiPaper-elevation': {
						boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
					},
				}}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: 238,
					},
				}}
			>
				<MenuItems
					onClick={() => {
						handleDefaultChange();
						handleClose();
					}}
					divider
				>
					<H5>Set as default</H5>
				</MenuItems>
				<MenuItems
					onClick={() => {
						handleDelete();
						handleClose();
					}}
				>
					<H5 style={{ color: '#CC1440' }}>Delete payment method</H5>
				</MenuItems>
			</Menu>
		</div>
	);
};

export default LongIconMenu;
