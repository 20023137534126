import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SearchBox from './Search/SearchBox';
import Category from './Categories/Category';
import {
	Disney,
	NHMuseum,
	EmpireState,
	Everything,
	England,
	Experiences,
	Expense,
	Card,
	HumanPeople,
	Apple,
	OddBox,
	Clementine,
	Dandy,
	Peloton,
	Healf,
	Whoop,
	FieldDoctor,
	Feel,
	FighterShots,
	Yield,
	Cowshed,
	Neals,
	Urban,
	Aesop,
	Experts,
	Masterclass,
	CodeAcademy,
	Sculpd,
	Otto,
	BestIntentions,
	Mamamade,
	Peanut,
	Every,
	Explr,
	Headspace,
	EdCanHelp,
	BetterHelp,
	Ally,
	Wildgoose,
	Midstay,
	Vespucci,
	Flown,
	Bloom,
	Soho,
	Vinny,
	Furthr,
	Stipe,
	AirBnB,
	Uber,
	Busuu,
	LastMinute,
	InspirationLiv,
	InspirationJames,
	InspirationAne,
} from './images';
import { useProductContext } from '../../contexts/productContext';
import {
	useCurrentTeam,
	useClient,
	useOnboarding,
	useFlexPot,
} from '../../hooks';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import config from '../../config';
import { useUserContext } from '../../contexts/userContext';
import useSearchParam from './Search/useSearchParam';
import ProductCardSkeleton from '../../styles/shared/ProductCard/ProductCardSkeleton';
import ProductCard from '../../styles/shared/ProductCard/ProductCard';
import Inspiration from './Inspiration/inspiration';

const HomePageContainer = styled.div`
	background: #f8f8f8;
	padding: 50px 0 150px 0;
	@media (max-width: 500px) {
		padding: 30px 24px 100px 24px;
	}
`;

const SectionTitle = styled.h3`
	margin-bottom: 50px;
	font-family: 'TTInterfaces DemiBold';
	font-size: 32px;
	line-height: 40px;
	max-width: 1250px;
	margin: auto;
	color: #0a0f16;
`;

const TilesContainer = styled.div`
	max-width: 1250px;
	margin: auto;
	margin-top: 66px;
	margin-bottom: 125px;
	padding-bottom: 66px;

	@media (max-width: 500px) {
		margin-bottom: 25px;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 0 70px;

	border-bottom: 0.5px solid #e4e4e4;
`;

const CardAndReimbursementContainer = styled.div`
	max-width: 1250px;
	margin: auto;

	> h3 {
		margin-bottom: 50px;
		font-family: 'TTInterfaces DemiBold';
		font-size: 32px;
		line-height: 40px;

		color: #0a0f16;
	}
	> div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 70px;
		bottom: 200px;
		align-items: flex-start;
		justify-content: center;

		> a,
		> div {
			min-height: 380px;
			width: 400px;
			padding: 0;
			border: none;

			box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
			background: #ffffff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;

			flex-grow: 1;

			> div {
				width: 100%;

				> div:nth-of-type(2) {
					padding: 25px 30px 30px 30px;

					h3 {
						font-family: 'TTInterfaces DemiBold';
						font-size: 21px;
						line-height: 21px;
						margin-bottom: 10px;

						display: flex;
						align-items: center;
						color: #0a0f16;
					}

					p {
						font-family: 'TTInterfaces Medium';
						font-style: normal;
						font-size: 17px;

						color: #000000;
						opacity: 0.4;
						margin: 0;
						text-align: left;
					}
				}
			}
		}
	}
`;

const Background = styled.div<{ src: string }>`
	width: 100%;
	height: 100%;
	height: 236px;

	background: url(${(props) => props.src}) center no-repeat, #bbbec2;

	background-size: cover;
	border-radius: 20px 20px 0 0;
	overflow: hidden;
`;

const LoadingCategoriesContainer = styled.div`
	max-width: 1250px;
	margin: auto;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 75px 70px;
	align-items: center;
	justify-content: center;

	@media (max-width: 500px) {
		gap: 75px 50px;
	}

	> div {
		height: 325px;
		width: 260px;

		box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
		background: #ffffff;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex-grow: 1;

		@keyframes loading {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}

		> div:first-of-type {
			width: 100%;
			height: 160px;
			background: #ebebeb;
			border-radius: 0;

			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;
			animation: loading 2.5s ease-in-out infinite;
		}

		> div:nth-of-type(2) {
			padding: 16px 20px;
			height: 84px;
			width: 100%;

			> div:first-of-type {
				width: 100%;
				height: 20px;
				background: rgba(214, 214, 214, 0.5);
				border-radius: 50px;

				background: linear-gradient(
					to right,
					#ebebeb 0%,
					#d6d6d6 50%,
					#ebebeb 100%
				);
				background-size: 400% 400%;
				animation: loading 3s ease-in-out infinite;
			}

			> div:nth-of-type(2) {
				margin-top: 17px;
				width: 100%;
				height: 14px;
				background: #d6d6d6;
				opacity: 0.25;
				border-radius: 50px;

				background: linear-gradient(
					to right,
					#ebebeb 0%,
					#d6d6d6 50%,
					#ebebeb 100%
				);
				background-size: 400% 400%;
				animation: loading 2.5s ease-in-out infinite;
			}

			> div:nth-of-type(3) {
				margin-top: 8px;
				width: 75%;
				height: 14px;
				background: #d6d6d6;
				opacity: 0.25;
				border-radius: 50px;

				background: linear-gradient(
					to right,
					#ebebeb 0%,
					#d6d6d6 50%,
					#ebebeb 100%
				);
				background-size: 400% 400%;
				animation: loading 2.5s ease-in-out infinite;
			}

			> div:nth-of-type(4) {
				margin-top: 17px;
				opacity: 0.25;
				display: flex;
				flex-direction: row;
				gap: 8px;

				div {
					background: #d6d6d6;

					background: linear-gradient(
						to right,
						#ebebeb 0%,
						#d6d6d6 50%,
						#ebebeb 100%
					);
					background-size: 400% 400%;
					animation: loading 2.5s ease-in-out infinite;
					width: 45px;
					height: 45px;
					border-radius: 12px;
				}
			}
		}
	}
`;

const CategorySkeleton = () => (
	<div>
		<div />
		<div>
			<div />
			<div />
			<div />
			<div>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	</div>
);

const HiddenElement = styled.div<{ mimicWidth?: string }>`
	flex: ${({ mimicWidth }) => (mimicWidth ? `1 0 ${mimicWidth}` : '1 0 260px')};
	visibility: hidden;
	content: '';
`;

const HomePageWrapper = () => {
	const { categories, search, reason } = useProductContext();
	const [isLoading, setIsLoading] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [reasonsLookup, setReasonsLookup] = useState({});
	const searchParam = useSearchParam();
	useOnboarding();
	const client = useClient();
	const { userData } = useUserContext();
	const { isAuthenticated } = useAuth0();
	const currentTeam = useCurrentTeam();

	const isStripeCardEnabled = !!currentTeam?.enableStripeVirtualCards;

	useEffect(() => {
		let searchChanged = false;

		setReasonsLookup({});

		if (searchParam) {
			setIsLoading(true);
			(async () => {
				try {
					const data = await search(searchParam);

					setSearchResults(data);

					data.map(async (product: any) => {
						if (!product.reasonable) return;

						const focusReason = await reason(searchParam, product.id);

						if (!searchChanged) {
							setReasonsLookup((prevLookup) => ({
								...prevLookup,
								[product.id]: focusReason,
							}));
						}
					});
				} catch (err: any) {
					// eslint-disable-next-line no-console
					console.error(err.message);
				} finally {
					setIsLoading(false);
				}
			})();
		} else {
			setSearchResults([]);
		}

		return () => {
			searchChanged = true;
		};
	}, [userData, searchParam, client]);

	const determineCategoryLogos = (
		categoryId: string | number,
		fallbackLogos: string[]
	) => {
		const devLogos = fallbackLogos.map((logo) => {
			return { img: logo, alt: 'company-logo' };
		});

		if (config.env !== 'prod') return devLogos;

		switch (categoryId) {
			case 1: // Fitness
				return [
					{ img: Peloton, alt: 'Peloton' },
					{ img: Dandy, alt: 'Dandy' },
					{ img: Healf, alt: 'Healf' },
					{ img: Whoop, alt: 'Whoop' },
				];
			case 2: // Self Care
				return [
					{ img: Cowshed, alt: 'Cowshed' },
					{ img: Neals, alt: 'Neals' },
					{ img: Urban, alt: 'Urban' },
					{ img: Aesop, alt: 'Aesop' },
				];
			case 5: // Nutrition
				return [
					{ img: FieldDoctor, alt: 'FieldDoctor' },
					{ img: Feel, alt: 'Feel' },
					{ img: FighterShots, alt: 'FighterShots' },
					{ img: Yield, alt: 'Yield' },
				];
			case 7: // Learning
				return [
					{ img: Experts, alt: 'Experts' },
					{ img: Masterclass, alt: 'Masterclass' },
					{ img: CodeAcademy, alt: 'CodeAcademy' },
					{ img: Sculpd, alt: 'Sculpd' },
				];
			case 8: // Mind
				return [
					{ img: Headspace, alt: 'Headspace' },
					{ img: EdCanHelp, alt: 'EdCanHelp' },
					{ img: BetterHelp, alt: 'BetterHelp' },
					{ img: Ally, alt: 'Ally' },
				];
			case 9: // Social
				return [
					{ img: Wildgoose, alt: 'Wildgoose' },
					{ img: Midstay, alt: 'Midstay' },
					{ img: Vespucci, alt: 'Vespucci' },
					{ img: Flown, alt: 'Flown' },
				];
			case 11: // Giving
				return [
					{ img: Bloom, alt: 'Bloom' },
					{ img: Soho, alt: 'Soho' },
					{ img: Vinny, alt: 'Vinny' },
					{ img: Furthr, alt: 'Furthr' },
				];
			case 13: // Finance
				return [
					{ img: Otto, alt: 'Otto' },
					{ img: BestIntentions, alt: 'BestIntentions' },
					{ img: Experts, alt: 'Experts' },
					{ img: Stipe, alt: 'Stipe' },
				];
			case 15: // Parenting
				return [
					{ img: Mamamade, alt: 'Mamamade' },
					{ img: Peanut, alt: 'Peanut' },
					{ img: Explr, alt: 'Explr' },
					{ img: Every, alt: 'Every' },
				];
			case 33: // Travel
				return [
					{ img: AirBnB, alt: 'AirBnB' },
					{ img: Uber, alt: 'Uber' },
					{ img: Busuu, alt: 'Busuu' },
					{ img: LastMinute, alt: 'LastMinute' },
				];
			default:
				return devLogos;
		}
	};
	const flexPot = useFlexPot();

	return (
		<HomePageContainer>
			<SearchBox />
			<TilesContainer>
				{!searchResults.length &&
					!isLoading &&
					(categories ? (
						<>
							<Category
								name="Everything"
								description="Target every aspect of your wellbeing to feel happier"
								src={Everything}
								route="categories/all"
								logos={[
									{ img: Apple, alt: 'Apple' },
									{ img: OddBox, alt: 'OddBox' },
									{ img: Clementine, alt: 'Clementine' },
									{ img: HumanPeople, alt: 'HumanPeople' },
								]}
							/>
							{categories.map(
								(category: {
									name: string;
									description: string;
									heroSrc: string;
									id: number;
									logos: string[];
								}) => (
									<Category
										key={category.id}
										name={category.name}
										description={category.description}
										src={`${category.heroSrc}?width=500px`}
										route={`category/${category.id}`}
										logos={determineCategoryLogos(
											category.id,
											category.logos?.slice(0, 4)
										)}
									/>
								)
							)}
							<Category
								name="Experiences"
								description="Unwind with a wine tasting or spa treatment package"
								src={Experiences}
								route="events"
								logos={[
									{ img: NHMuseum, alt: 'NHMuseum' },
									{ img: England, alt: 'England' },
									{ img: EmpireState, alt: 'EmpireState' },
									{ img: Disney, alt: 'Disney' },
								]}
							/>
							<HiddenElement />
							<HiddenElement />
							<HiddenElement />
						</>
					) : (
						<LoadingCategoriesContainer>
							{[1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
								<CategorySkeleton key={id} />
							))}
						</LoadingCategoriesContainer>
					))}
				{!isLoading && searchResults && (
					<>
						{searchResults.map(
							({
								id,
								shortDescription,
								description,
								fromPrice,
								image,
								providerLogo,
								providerTitle,
								title,
								reasonable,
								type,
								from,
								city,
								venueName,
								countries,
								enquire,
							}) => {
								return (
									<ProductCard
										key={id}
										imgUrl={image}
										productTitle={title}
										productDescription={shortDescription || description}
										productId={id}
										provider={providerTitle}
										providerImgUrl={providerLogo}
										pointsValue={fromPrice}
										enquire={enquire}
										reasonable={reasonable}
										reason={reasonsLookup[id]}
										productType={type}
										isFromPrice={from}
										location={`${venueName && `${venueName}, `}${city}, ${
											countries[0]
										}`}
									/>
								);
							}
						)}
						<HiddenElement />
						<HiddenElement />
						<HiddenElement />
					</>
				)}
				{isLoading &&
					[1, 2, 3, 4, 5, 6, 7, 8].map((el) => {
						return <ProductCardSkeleton key={el} />;
					})}
			</TilesContainer>
			{!searchResults.length && !isLoading && (
				<>
					<SectionTitle>How you can spend your Juno Points ®</SectionTitle>
					<TilesContainer>
						<Inspiration
							title="Liv used Juno Points ® to access a local gym"
							buttonText="Discover fitness"
							imgUrl={InspirationLiv}
							url="category/1" // fitness in all 3 envs
						/>
						<Inspiration
							title="James used Juno Points ® to fly to Cape Town"
							buttonText="Book a holiday"
							imgUrl={InspirationJames}
							url={
								process.env.NODE_ENV !== 'production' ? '/card' : '/product/594' // Lastminute.com on production, card on dev/staging
							}
						/>
						<Inspiration
							title="Ane used Juno Points ® to visit a nature reserve"
							buttonText="Discover events"
							imgUrl={InspirationAne}
							url="/events"
						/>
						<HiddenElement mimicWidth="370px" />
					</TilesContainer>

					<div>
						{isAuthenticated && (
							<CardAndReimbursementContainer>
								<h3>Can’t find what you’re looking for?</h3>
								<div>
									{LinkContainer({
										children: (
											<div>
												<Background src={Expense} />
												<div>
													<h3>Juno Expenses ®</h3>
													<p>
														Already paid for something but want to use your Juno
														Points ® for it? Once your expense is approved,
														we’ll pay you via a bank transfer.
													</p>
												</div>
											</div>
										),
										active: currentTeam.id,
										// eslint-disable-next-line no-nested-ternary
										to: !flexPot
											? '/reimbursement'
											: flexPot !== -1
											? `/budget/${flexPot}?expense=true`
											: `/no-flex`,
									})}

									{LinkContainer({
										children: (
											<div>
												<Background src={Card} />
												<div>
													<h3>Juno Flex Card ®</h3>
													<p>
														You can use your virtual card to pay for
														subscriptions or wellbeing purchases at any of our
														approved online retailers.
													</p>
												</div>
											</div>
										),
										active: currentTeam.id && isStripeCardEnabled,
										// eslint-disable-next-line no-nested-ternary
										to: !flexPot
											? '/card'
											: flexPot !== -1
											? `/budget/${flexPot}`
											: `/no-flex`,
									})}
								</div>
							</CardAndReimbursementContainer>
						)}
					</div>
				</>
			)}
		</HomePageContainer>
	);
};

const LinkContainer = ({ children, active, to }: any) =>
	active ? (
		<Link
			style={{
				textDecoration: 'none',
			}}
			to={to}
		>
			{children}
		</Link>
	) : (
		<div style={{ cursor: 'not-allowed', opacity: '50%' }}>{children}</div>
	);

export default HomePageWrapper;
