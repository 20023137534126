import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { Circle } from '../../styles/ProductStyles';
import { handleBackNavigation } from '../utilities/Functions';
import Loading from '../utilities/Loading';
import { useClient, useCurrentTeam } from '../../hooks';
import { H2 } from '../../styles/StylesV2';
import BrandedVirtualCarousel from './BrandedVirtualCarousel';
import Card, { CardDetails } from '../../styles/shared/JunoCard';
import { useUserContext } from '../../contexts/userContext';
import Cardholder, {
	CardholderDetails,
} from '../../styles/shared/CardHolder/Cardholder';
import config from '../../config';
import Button from '../../styles/shared/Button/Button';
import Modal from '../../styles/shared/Modal';
import TransactionsContainer, {
	TransactionTypes,
} from '../../styles/shared/Transactions';

const BackgroundContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
`;

export const Container = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1136px;
	font-family: 'TTInterfaces Regular';

	h1 {
		font-size: 32px;
		font-family: 'TTInterfaces DemiBold';
	}

	@media (max-width: 1250px) {
		padding: 48px 48px 0px;
		margin: 0px;
	}
	@media (max-width: 1136px) {
		padding: 48px 48px 0px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

export const BrandedWrapper = styled.div`
	width: 100%;
	margin: auto;
	max-width: 1136px;
	@media (max-width: 1250px) {
		margin: 0px;
	}
	@media (max-width: 1136px) {
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		margin: 0px;
	}
`;

const WhiteBackgroundContainer = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fff;
`;

const TitleContainer = styled(Row)`
	margin: 32px 0px 0px 0px !important;
`;

const BrandedCardContainer = styled.div`
	width: 100%;
	max-width: 1136px;
	padding-top: 32px;
	background: #fff;
	@media (max-width: 1200px) {
		padding: 0 64px;
	}
	@media (max-width: 991px) {
		padding: 0px 20px;
	}
`;

const ModalContainer = styled.div`
	position: relative;
	background: white;
	width: 560px;

	@media (max-width: 900px) {
		width: unset;
	}

	font-family: 'TTInterfaces Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;

	background-color: #fff;
	padding: 24px;
	box-shadow: 0px 12px 16px rgba(226, 203, 214, 0.3);
	form {
		display: flex;
		flex-direction: column;
		h2 {
			align-self: center;
			font-family: 'TTInterfaces DemiBold';
			font-style: normal;
			font-size: 28px;
			line-height: 34px;

			letter-spacing: -0.03em;
			padding-bottom: 8px;

			@media (max-width: 550px) {
				font-size: 20px;
				line-height: 24px;
			}
		}
	}

	label {
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-size: 14px;
		line-height: 24px;
		color: #191a1c;
		padding-top: 32px;
	}
	input,
	select {
		background: rgba(0, 0, 0, 0.03);
		border-radius: 3px;
		border: 0;
		padding: 13.5px 16px;
		color: #191a1c;
	}

	input[disabled],
	select[disabled] {
		color: #191a1c70;
	}

	hr {
		margin: 32px 0;
		width: 100%;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
	}
	@media (max-width: 992px) {
		margin-top: 8px;
		padding: 20px;
	}
`;

const Heading2 = styled(H2)`
	font-size: 24px;
	line-height: 28.8px;
`;

const CardholderContainer = styled.div`
	margin-top: 56px;
	width: 656px;

	@media (max-width: 992px) {
		margin-top: 20px;
		width: unset;
	}
`;

const TextContainer = styled.div`
	align-items: center;
	height: 100%;

	margin-top: 40px;
	padding: 0 40px;
	flex: 1;
	max-width: 480px;

	@media (max-width: 992px) {
		margin-top: 0px;
		flex: unset;
		max-width: unset;
		padding: 0;
	}

	font-family: 'TTInterfaces Regular';

	color: rgba(0, 0, 0, 0.35);

	p:nth-of-type(1) {
		font-size: 18px;
		line-height: 25px;

		@media (max-width: 992px) {
			padding-top: 24px;
		}
	}

	p:nth-of-type(2) {
		font-size: 13.5px;
		line-height: 16px;
	}

	a,
	a:hover {
		color: inherit;
		text-decoration: underline;
	}
`;
const SetupInformation = styled.div`
	font-family: 'TTInterfaces Regular';

	color: rgba(0, 0, 0, 0.35);

	font-size: 18px;
	line-height: 25px;
`;

const CardDetail = () => {
	const client = useClient();
	const history = useHistory();
	const currentTeam = useCurrentTeam();

	const [transactions, setTransactions] = useState<TransactionTypes | null>(
		null
	);
	const [card, setCard] = useState<CardDetails>();
	const [orderingCard, setOrderingCard] = useState(false);

	const [showShippingAddressModal, setShowShippingAddressModal] =
		useState(false);

	const [fullName, setFullName] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [townOrCity, setTownOrCity] = useState('');
	const [postCode, setPostCode] = useState('');
	const [countryCode, setCountryCode] = useState('');

	useEffect(() => {
		if (card) {
			setFullName(card.fullName);
			setAddressLine1(card.line1);
			setAddressLine2(card.line2);
			setTownOrCity(card.city);
			setPostCode(card.postCode);
			setCountryCode(card.country);
		}
	}, [card]);

	const [open, setOpen] = useState<boolean>(false);
	const [pinOpen, setPinOpen] = useState<boolean>(false);
	const { userData, updateUserData } = useUserContext();

	const availablePoints =
		(userData?.points || 0) + (currentTeam?.teamMember?.points || 0);

	const gettingCard = useRef(false);
	const team = userData.teams.find(
		({ id }: any) => id === userData.currentTeam
	);
	const { stripeCardholderId } = team.teamMember;

	const currencyToPoints = userData.currencyData.pointsToLocalCurrency;

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: userData.currencyData.localCurrency,
	});

	const getCard = async (
		cardholder?: CardholderDetails,
		shippingAddress?: {
			address: {
				line1: string;
				line2?: string;
				city: string;
				country: string;
				// eslint-disable-next-line camelcase
				postal_code: string;
			};
			name: string;
			service: 'standard';
		},
		lookupPhysical?: boolean
	) => {
		try {
			gettingCard.current = true;

			const { data } = await client.post(
				`${config.platform.HOST}/payments/authorized/cards/${currentTeam.id}`,
				{ cardholder, shippingAddress, lookupPhysical }
			);

			setCard({ ...data });
			updateUserData();
		} finally {
			gettingCard.current = false;
		}
	};
	const updateCard = async (active: boolean) => {
		await client.put(
			`${config.platform.HOST}/payments/authorized/cards/${currentTeam.id}`,
			{ active, typePhysical: card?.type === 'physical' }
		);

		setCard((prevCard) => ({ ...prevCard!, active }));
	};

	useEffect(() => {
		if (stripeCardholderId && !gettingCard.current && !card) getCard();
	}, [stripeCardholderId, getCard, card]);

	useEffect(() => {
		const retreiveTransactions = async () => {
			try {
				const typeParam = card?.type === 'physical' ? card?.type : '';

				const { data } = await client.get(
					`${config.platform.HOST}/payments/authorized/cards/${currentTeam.id}/transactions/${typeParam}`
				);

				setTransactions(data);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		};

		if (card?.id && currentTeam.teamMember.userId) {
			retreiveTransactions();
		} else {
			setTransactions({ transactions: [] });
		}
	}, [currentTeam.id, card]);

	const flexCardInfo = !currentTeam.receiptRequired
		? `Juno Flex Cards® are a great way to give you the optimal freedom that you deserve. You can use this card for paying for your subscriptions like Headspace, Calm, Netflix, Games or others. Next to that you can use it for doing wellbeing purchases online. The available balance on your card is connected to the amount of points that you have in your wallet.`
		: `The Juno Flex Card® gives you ultimate freedom over how you use your Juno Points. It is only to be used to pay for wellbeing goods and services, and you will be prompted via email to submit a receipt after every transaction you make, which will be reviewed by the Juno team. Receipts won’t be shared with your company unless purchases are deemed to be outside of the scope of wellbeing.`;

	return (
		<>
			<BackgroundContainer>
				<Container>
					<Row noGutters className="justify-content-start align-items-start">
						<Circle onClick={() => handleBackNavigation(history)}>
							<MdArrowBack size={20} />
						</Circle>
					</Row>
					<Row noGutters>
						<Col sm={12} md={12}>
							<TitleContainer>
								<h1>Juno Flex Card®</h1>
							</TitleContainer>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
								}}
							>
								<div style={{ flex: 1 }}>
									{stripeCardholderId && (!card || card?.phoneNumber) ? (
										<>
											<Card
												balance={
													formatter.format(
														availablePoints / currencyToPoints
													) || '0'
												}
												card={card}
												updateCard={updateCard}
												setOpen={setOpen}
												setPinOpen={setPinOpen}
												open={open}
												pinOpen={pinOpen}
											/>
											{card && (
												<div style={{ marginTop: '24px' }}>
													{card?.type === 'virtual' ? (
														<>
															{!currentTeam.teamMember.stripePhysicalCardId &&
																addressLine1 !== 'With Juno Ltd' && (
																	<Button
																		buttonType="primary"
																		state={
																			availablePoints >= 10
																				? 'default'
																				: 'disabled'
																		}
																		size="small"
																		onClick={() => {
																			setShowShippingAddressModal(true);
																		}}
																		title="10 points are required to purchase a physical flex card"
																	>
																		Request Physical Card 💳 - 10PTS
																	</Button>
																)}

															{currentTeam.teamMember.stripePhysicalCardId && (
																<Button
																	buttonType="secondary"
																	state="default"
																	size="small"
																	onClick={async () => {
																		setCard(undefined);
																		await getCard(undefined, undefined, true);
																	}}
																>
																	Display Physical Card 💳
																</Button>
															)}
														</>
													) : (
														<>
															<Button
																buttonType="secondary"
																state="default"
																size="small"
																onClick={async () => {
																	setCard(undefined);
																	await getCard(undefined, undefined, false);
																}}
															>
																Display Non-Physical Card 💳
															</Button>
															{!card?.isCardOlderThan7Days && (
																<SetupInformation>
																	<p style={{ marginTop: '12px' }}>
																		Delivery Time: 2-3 Business Days
																	</p>
																	<p>
																		Use Chip and Pin on your first transaction.
																		All future transactions can be contactless.
																	</p>
																</SetupInformation>
															)}
														</>
													)}
												</div>
											)}
										</>
									) : (
										<CardholderContainer>
											<Cardholder card={card!} getCard={getCard} />
										</CardholderContainer>
									)}
								</div>
								<TextContainer>
									<p>
										{currentTeam.id !== 737 ? (
											flexCardInfo
										) : (
											<>
												<span>
													Hi team! We are now transitioning to Juno Flex Card
													for your health and well-being benefits. Your Flex
													Card allowance will be the same as the previous points
													system: you will receive 166 points each month (note
													if you signed up for the SafetyWing insurance, your
													balance may be different). Just a quick reminder to
													follow{' '}
												</span>
												<a
													href="https://docs.google.com/document/d/1BIZythFSljUHIhLFY8iAswuAzgypqXy6OKTcw0HxUtM/edit"
													target="_blank"
													rel="noreferrer"
												>
													the guide
												</a>
												<span>
													. Please pay close attention to the &quot;permitted
													categories&quot; for services that are eligible via
													Juno. Each month Juno will run a quick look-over to
													make sure all transactions are within the approved
													categories. Please reach out to{' '}
												</span>
												<a href="mailto:support@withjuno.com">
													support@withjuno.com
												</a>
												<span>
													{' '}
													if you have any questions on how to set up and use the
													card.
												</span>
											</>
										)}
									</p>
								</TextContainer>
							</div>
						</Col>
					</Row>
				</Container>
				<WhiteBackgroundContainer>
					<BrandedWrapper>
						<BrandedCardContainer>
							<Row noGutters className="mt-1">
								<BrandedVirtualCarousel />
							</Row>
						</BrandedCardContainer>
					</BrandedWrapper>
					<Container>
						<Row>
							<Col sm={12} md={12}>
								{transactions && (
									<TransactionsContainer transactions={transactions} />
								)}
								{!transactions && <Loading />}
							</Col>
						</Row>
					</Container>
				</WhiteBackgroundContainer>
			</BackgroundContainer>
			<Modal
				ariaLabel="Confirm Shipping Address"
				ariaDescribedBy="confirm-shipping-modal"
				open={showShippingAddressModal}
				onClose={() => setShowShippingAddressModal(false)}
			>
				<ModalContainer>
					<Heading2>Confirm shipping address</Heading2>

					<form
						onSubmit={async (e) => {
							e.preventDefault();
							if (e.currentTarget.checkValidity()) {
								setOrderingCard(true);

								await getCard(undefined, {
									address: {
										line1: addressLine1,
										...(addressLine2 && { line2: addressLine2 }),
										city: townOrCity,
										country: countryCode,
										postal_code: postCode,
									},
									name: fullName,
									service: 'standard',
								});
							}
							setShowShippingAddressModal(false);
							setOrderingCard(false);
						}}
					>
						<label htmlFor="fullName">Full Name</label>
						<input
							id="fullName"
							required
							value={fullName}
							onChange={({ target }) => setFullName(target.value)}
						/>
						<label htmlFor="addressLine1">Address Line 1</label>
						<input
							id="addressLine1"
							required
							value={addressLine1}
							onChange={({ target }) => setAddressLine1(target.value)}
						/>
						<label htmlFor="addressLine2">Address Line 2</label>
						<input
							id="addressLine2"
							value={addressLine2 || ''}
							onChange={({ target }) => setAddressLine2(target.value)}
						/>
						<label htmlFor="townOrCity">Town or City</label>
						<input
							id="townOrCity"
							required
							value={townOrCity}
							onChange={({ target }) => setTownOrCity(target.value)}
						/>
						<label htmlFor="postalCode">Postal Code</label>
						<input
							id="postalCode"
							required
							value={postCode}
							onChange={({ target }) => setPostCode(target.value)}
						/>
						<label htmlFor="country">Country</label>
						<select
							id="country"
							required
							value={countryCode}
							onChange={({ target }) => setCountryCode(target.value)}
						>
							<option key="AT" value="AT">
								Austria
							</option>
							<option key="BE" value="BE">
								Belgium
							</option>
							<option key="CY" value="CY">
								Cyprus
							</option>
							<option key="EE" value="EE">
								Estonia
							</option>
							<option key="FI" value="FI">
								Finland
							</option>
							<option key="FR" value="FR">
								France
							</option>
							<option key="DE" value="DE">
								Germany
							</option>
							<option key="IE" value="IE">
								Ireland
							</option>
							<option key="IT" value="IT">
								Italy
							</option>
							<option key="LV" value="LV">
								Latvia
							</option>
							<option key="LT" value="LT">
								Lithuania
							</option>
							<option key="LU" value="LU">
								Luxembourg
							</option>
							<option key="MT" value="MT">
								Malta
							</option>
							<option key="NL" value="NL">
								Netherlands
							</option>
							<option key="PT" value="PT">
								Portugal
							</option>
							<option key="SK" value="SK">
								Slovakia
							</option>
							<option key="SI" value="SI">
								Slovenia
							</option>
							<option key="ES" value="ES">
								Spain
							</option>
							<option key="GB" value="GB">
								United Kingdom
							</option>
						</select>
						<hr />
						<Button
							state={!orderingCard ? 'default' : 'loading'}
							type="submit"
							buttonType="primary"
						>
							Order card
						</Button>
					</form>
				</ModalContainer>
			</Modal>
		</>
	);
};

export default CardDetail;
