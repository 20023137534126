import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { AiOutlineLeft } from 'react-icons/ai';
import { ReactComponent as Logo } from '../logowhite.svg';
import { StyledH1, StyledP1 } from '../styles/MainStyles';
import SignIn from '../components/signInUp/SignIn';

import ResetPassword from '../components/signInUp/ResetPassword';
import TroubleLoginPopup from '../components/signInUp/TroubleLoginPopup';
import TermsAndConditionsFooter from '../components/utilities/TermsAndConditionsFooter';

const StyledLeftContainer = styled.div`
	width: 100%;
	height: 100%;
	@media only screen and (max-width: 991.98px) {
		display: none;
	}
`;

const StyledSide = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	@media only screen and (max-width: 1200px) {
		height: 75%;
	}
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 80%;
`;

const StyledLogo = styled.div`
	position: absolute;
	left: 32px;
	top: 32px;
`;

const StyledRightContainer = styled.div`
	width: 100%;
	height: 100vh;
`;

const MainContainer = styled.div`
	height: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media (max-width: 991.98px) {
		margin-top: 9%;
		justify-content: start;
	}
	@media (max-width: 400px) {
		margin-top: 4%;
	}
`;

const FormContainer = styled.div`
	width: 508px;
	height: 503px;
	@media (max-width: 991.98px) {
		width: 90%;
		height: 100%;
	}
	@media (max-width: 400px) {
		padding-bottom: 10%;
	}
`;

const TopButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ showBack }) =>
		showBack ? `space-between` : `flex-end`};
	outline: none;

	height: 77px;
	top: 0px;
	position: relative;
	margin-left: 40px;
	margin-right: 40px;
	@media (max-width: 991.98px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const BackButton = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	height: 35px;
	width: 70px;
	font-weight: bold;
	cursor: pointer;
	background: white;
	border: none;
	outline: none;
	&:focus {
		outline: none;
	}
`;

const StyledBoldButtonText = styled.div`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 22px;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;

const LoginForm = () => {
	const location = useLocation();
	const params = queryString.parse(location.search);
	const [mode, setMode] = useState(params?.referral ? 'signup' : 'login');
	const [error, setError] = useState('');
	const [step, setStep] = useState(0);
	const [showPopup, setShowPopup] = useState(true);
	const [percentRange, setProgress] = useState(33.33);
	const history = useHistory();

	useEffect(() => {
		setError('');
		document.title = 'Juno - Login';
	}, [mode]);

	const handleBackButton = () => {
		if (mode === 'signup') {
			setStep(step > 0 ? step - 1 : 0);
			setProgress(percentRange > 0 ? percentRange - 33.33 : 0);
			if (step === 0) {
				setMode('login');
				history.push('/login');
			}
		}
		if (mode === 'reset') {
			setMode('login');
			history.push('/login');
		}
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	return (
		<Row noGutters>
			<Col xs={12} sm={12} md={12} lg={4}>
				<StyledLeftContainer>
					<StyledSide>
						<StyledImage loading="lazy" src="/images/login/login1.png" />
						<StyledLogo>
							<Logo style={{ stroke: 'none', fill: 'white' }} />
						</StyledLogo>
					</StyledSide>
				</StyledLeftContainer>
			</Col>
			<Col xs={12} sm={12} md={12} lg={8}>
				<StyledRightContainer>
					<TopButtonContainer showBack={mode === 'reset'}>
						{mode === 'reset' && (
							<BackButton onClick={handleBackButton}>
								<AiOutlineLeft
									size={14}
									style={{
										marginRight: 12,
										fontWeight: 'bold',
									}}
								/>
								<StyledBoldButtonText>Back</StyledBoldButtonText>
							</BackButton>
						)}
					</TopButtonContainer>

					<MainContainer>
						{error !== '' && showPopup && (
							<TroubleLoginPopup handleClosePopup={handleClosePopup} />
						)}
						<FormContainer>
							{mode === 'login' && (
								<>
									<StyledH1>Welcome to Juno</StyledH1>
									<SignIn
										errorCallback={(err) => {
											setError(err);
										}}
										modeCallback={setMode}
									/>
									<Row noGutters className="mt-3">
										<TermsAndConditionsFooter text="By using Juno you agree to our" />
									</Row>
								</>
							)}
							{mode === 'reset' && (
								<>
									<StyledH1>Forgot password</StyledH1>
									<StyledP1>
										We will send you an email with a link to reset your
										password.
									</StyledP1>
									<ResetPassword modeCallback={setMode} />
								</>
							)}
						</FormContainer>
					</MainContainer>
				</StyledRightContainer>
			</Col>
		</Row>
	);
};

export default LoginForm;
