import React, { useState } from 'react';
import styled from 'styled-components';
import { IoImagesOutline } from 'react-icons/io5';
import S3FilesUploader from '../../utilities/S3FilesUploader';
import { Spinner } from 'react-bootstrap';
import { IoMdAdd } from 'react-icons/io';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
const StyledImage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 3px;
`;

const StyledPreview = styled.img`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	border-radius: 3px;
	object-fit: cover;
	object-position: 50% 50%;
`;

const Input = styled.input`
	display: none;
	border: 1px solid black;
	border-radius: 3px;
	background-color: lightblue;
`;

const Label = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: black;
	margin-bottom: 0px;
	background: tranparent;
`;

const StyledLabelDefault = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;

	width: 60px;
	height: 60px;
	border-radius: 3px;
	cursor: pointer;
`;

const StyledAddCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:white;
  color:black
  border:none;
  display:flex;
  justify-content:center;
  align-items:center;
  position: absolute;
  bottom: 22px;
  right: 0px;
  cursor: pointer;
`;

const ProfilePicInput = ({
	text,
	accept,
	onFiles,
	files,
	getFilesFromEvent,
	src,
	loading,
}) => {
	return loading ? (
		<Label>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '60px',
					width: '60px',
					textAlign: 'center',
					border: '1px solid black',
					borderRadius: '3px',
				}}
			>
				<Spinner animation="grow" />
			</div>
		</Label>
	) : (
		<>
			<Label htmlFor={'file-input'}>
				{!src ? (
					<StyledLabelDefault>
						<IoImagesOutline size={30} style={{ margin: 10 }} />
					</StyledLabelDefault>
				) : (
					<StyledPreview src={src} />
				)}
				<StyledAddCircle>
					<IoMdAdd />
				</StyledAddCircle>
			</Label>
			<Input
				id="file-input"
				name="file-input"
				type="file"
				style={{ display: 'none' }}
				accept={accept}
				onChange={async (e) => {
					const chosenFiles = await getFilesFromEvent(e);
					onFiles(chosenFiles);
				}}
			/>
		</>
	);
};

const ProfilePicLayout = ({
	input,
	previews,
	submitButton,
	dropzoneProps,
	files,
	extra: { maxFiles },
}) => {
	return (
		<StyledContainer {...dropzoneProps}>
			<StyledImage filesLength={files.length}>{input}</StyledImage>
		</StyledContainer>
	);
};

const TeamPictureUpload = ({ teamId, src, handleChange, ...props }) => {
	const [loading, setLoading] = useState(false);

	return (
		<S3FilesUploader
			styles={{
				dropzone: {
					background: 'transparent',
					border: 0,
					width: 80,
					//height: 170,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
				},
			}}
			{...props}
			id={teamId}
			type="team-profile"
			multiple={false}
			accept="image/*"
			LayoutComponent={ProfilePicLayout}
			InputComponent={(props) => (
				<ProfilePicInput {...props} loading={loading} src={src} />
			)}
			inputContent=""
			onChangeStatus={({ meta }, status) => {
				status !== 'ready' && status !== 'done'
					? setLoading(true)
					: setLoading(false);
				status === 'done' && handleChange(meta.fileUrl);
			}}
		/>
	);
};

export default TeamPictureUpload;
