import React, { useState, useRef, useLayoutEffect } from 'react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import styled from 'styled-components';

const Policy = ({ policy }: any) => {
	const [readMore, setReadMore] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const policyRef = useRef<HTMLSpanElement>(null);
	useLayoutEffect(() => {
		if (policyRef.current) {
			const { scrollHeight, offsetHeight } = policyRef.current;
			setReadMore(offsetHeight < scrollHeight);
		}
	});
	return (
		<PolicyBlock>
			<p>Policy</p>
			<span className={expanded ? 'expanded' : undefined} ref={policyRef}>
				<Editor
					editorState={EditorState.createWithContent(convertFromRaw(policy))}
					onChange={() => {}}
					readOnly
				/>
			</span>
			{(readMore || expanded) && (
				<button
					className={expanded ? 'expanded' : undefined}
					type="button"
					onClick={() => setExpanded(!expanded)}
				>
					{!expanded ? 'Read more' : 'Read less'}
				</button>
			)}
		</PolicyBlock>
	);
};

export default Policy;

const PolicyBlock = styled.div`
	> p {
		color: #959595;
		font-size: 18px;
		font-family: 'TTInterfaces Medium';
		line-height: 28px;
		margin: 40px 0 0 0;
		text-transform: uppercase;
	}
	> span {
		color: #475467;
		font-size: 18px;
		font-family: 'TTInterfaces Medium';
		line-height: 28px;
		overflow: hidden;
		display: inline-block;
		height: 28px;
		max-width: calc(100% - 100px);
		display: -webkit-inline-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	> span.expanded {
		display: unset;
		overflow: unset;
		text-overflow: unset;
		-webkit-line-clamp: unset;
		-webkit-box-orient: unset;
		height: unset;
		max-width: unset;
	}
	> button {
		color: #475467;
		font-size: 18px;
		font-family: 'TTInterfaces Medium';
		line-height: 26px;
		border: 0;
		background: transparent;
		padding: 0;
		vertical-align: top;
		margin-left: 8px;
		text-decoration: underline;
	}
	> button.expanded {
		display: block;
		margin-left: 0;
	}
`;
