import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Modal, ModalBody } from 'react-bootstrap';
import { H2, Small } from '../../styles/StylesV3Main';
import { ModalButton, BoxInputLabel } from '../../styles/StylesV2';
import { StyledTextArea } from '../../styles/ProductStyles';

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
	width: 100%;
`;

const Description = styled(Small)`
	width: 100%;
	margin-top: 8px;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const AddressModal = ({
	showModal,
	setShowModal,
	goBack,
	placeholder,
	value,
	description,
	option,
	setSave,
	...props
}) => {
	const [localeAddress, setLocaleAddress] = useState(value);
	return (
		<Modal
			show={showModal}
			centered
			animation={false}
			onHide={() => {
				setShowModal(false);
			}}
		>
			<ModalBody style={{ padding: 24 }}>
				<Row
					noGutters
					className="mt-3 justify-content-center align-items-center"
				>
					<H2>Update Address</H2>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Address</StyledLabel>
						<StyledTextArea
							height={'136px'}
							rows="5"
							placeholder={placeholder}
							value={localeAddress}
							onChange={({ target: { value: e } }) => {
								setLocaleAddress(e);
							}}
						/>
						{description && <Description>{description}</Description>}
					</Col>
				</Row>
				<StyledButtonRow>
					<Col xs={6} className="px-0 py-0 mr-2">
						<ModalButton
							className="mr-2"
							cancelButton={true}
							width={'90%'}
							onClick={() => goBack()}
						>
							Cancel
						</ModalButton>
					</Col>
					<Col xs={6} className="px-0 py-0 ml-2">
						<ModalButton
							className="ml-2"
							width={'90%'}
							onClick={() => {
								setSave(true);
								props.onChange({
									target: { value: localeAddress },
								});
								setShowModal(false);
							}}
						>
							Update
						</ModalButton>
					</Col>
				</StyledButtonRow>
			</ModalBody>
		</Modal>
	);
};

export default AddressModal;
