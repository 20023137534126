import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Dropzone, { MethodValue } from 'react-dropzone-uploader';
import { RawDraftContentState } from 'draft-js';
import { useClient } from '../../../hooks';
import Button from '../../../styles/shared/Button/Button';
import Modal from '../../../styles/shared/Modal';
import Select from '../../../styles/shared/Select';
import RichTextEditor from '../../../styles/shared/RichTextEditor';
import {
	CreateTeamBudgetInput,
	TeamBudget,
	UpdateActiveTeamBudgetInput,
} from './types';
import { ReactComponent as Info } from '../images/subInfo.svg';
import { ReactComponent as ImagePlus } from '../images/imagePlus.svg';

const Heading2 = styled.h2`
	font-family: 'TTInterfaces DemiBold';
	font-size: 21px;
	line-height: 28px;
	color: #101828;
`;

const ModalContainer = styled.div`
	position: relative;
	background: white;
	width: 560px;
	padding: 24px;

	@media (max-width: 900px) {
		width: unset;
	}

	font-family: 'TTInterfaces Regular';
	font-size: 18px;
	margin: 0px;
	h2 + p {
		color: #475467;
		font-size: 16px;
		margin: 0;
	}
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 24px 0 24px;
`;

const Label = styled.label`
	font-family: 'TTInterfaces DemiBold';
	color: #000;
	font-size: 16px;
	line-height: 17px;
	margin-top: 20px;
	margin-bottom: 18px;
`;

const StyledInput = styled.input`
	width: 100%;
	border: 1.5px solid #eaecf0;
	border-radius: 10px;
	padding: 10px 18px;
	font-family: 'TTInterfaces Regular';
	font-size: 16px;
	margin: 0px;
`;

const ParagraphContainer = styled.div`
	flex: 1;
`;

const SubLabel = styled.p`
	padding-bottom: 8px;
	padding-top: 4px;
	font-family: 'TTInterfaces Regular';
	font-size: 14px;
	color: #a0a0a0;
	svg {
		margin-right: 8px;
	}
`;

const StyledLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 24px;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background-color: #191a1c;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #191a1c;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(16px);
		-ms-transform: translateX(16px);
		transform: translateX(16px);
	}
`;

const FlexContainer = styled.div`
	display: flex;
	gap: 16px;
	padding-top: 20px;
	> div {
		margin-top: 4px;
	}
	label {
		font-family: 'TTInterfaces DemiBold';
		color: #000;
		font-size: 16px;
		margin: 0;
	}
	p {
		opacity: 40%;
		font-size: 14px;
		margin: 0;
	}
`;

const AdminButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-grow: 0;
	margin-top: 24px;
	gap: 16px;
	button {
		border-radius: 8px;
		font-size: 16px;
		padding: 10px 18px;
	}
	button:first-child {
		border: 1px solid #d0d5dd;
		background-color: #fff;
	}
`;

const StyledDateContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const StyledDateInput = styled.input`
	font-family: 'TTInterfaces Regular';
	font-style: normal;
	font-size: 16px;
	width: 100%;
	border: 1.5px solid #eaecf0;
	border-radius: 10px;
	padding: 10px 18px;
`;

const Thumbnail = styled.div`
	> p {
		margin-top: -10px;
		color: #475467;
		font-size: 16px;
	}
	button {
		color: #475467;
		font-size: 16px;
		border: none;
		padding: 0;
		text-decoration: underline;
		background-color: transparent;
	}
	.dzu-inputLabel:has(img) {
		display: block;
	}
`;

const StyledScheduleContainer = styled.div`
	display: flex;
	gap: 24px;

	> div {
		flex: 1;
	}
`;

interface AddEditBudgetModalProps {
	onSaveClick: (
		input: CreateTeamBudgetInput | TeamBudget | UpdateActiveTeamBudgetInput
	) => Promise<void>;
	onCancelClick: () => void;
	inspiration: boolean;
	isOpen: boolean;
	teamId: number;
	editBudget?: TeamBudget;
	currency: string;
}

const AddEditBudgetModal: React.FC<AddEditBudgetModalProps> = ({
	inspiration,
	onSaveClick,
	onCancelClick,
	isOpen,
	teamId,
	editBudget,
	currency,
}) => {
	const client = useClient();
	const [title, setTitle] = useState(editBudget?.name || '');
	const [budgetType, setBudgetType] = useState(editBudget?.budgetType || '');
	const [amount, setAmount] = useState<number>(editBudget?.amount || 0);
	const [repeats, setRepeats] = useState(editBudget?.renewalPeriod || '');
	const [startDate, setStartDate] = useState<Date>(
		editBudget?.startAt || moment().add(1, 'day').toDate()
	);
	const [isRollover, setIsRollover] = useState(
		editBudget?.renewalType === 'accumulate' || false
	);
	const [policy, setPolicy] = useState<RawDraftContentState | undefined>(
		editBudget?.policy
	);
	const [isSaving, setIsSaving] = useState(false);
	const [allowPhysicalCard, setAllowPhysicalCard] = useState(
		editBudget?.physicalCard ?? true
	);
	const [requiresReceipt, setRequiresReceipt] = useState(
		editBudget?.receiptRequired ?? false
	);
	const [thumbnail, setThumbnail] = useState(editBudget?.thumbnail || '');
	const startedBudget =
		editBudget && !inspiration
			? !(
					moment(editBudget?.startAt)
						.startOf('day')
						.diff(moment().startOf('day'), 'days') > 0
			  )
			: false;
	const handleOnSaveClick = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSaving(true);
		await onSaveClick({
			name: title,
			policy: policy!,
			thumbnail,
			budgetType: budgetType as any,
			renewalType:
				isRollover && repeats !== 'once-off' ? 'accumulate' : 'reset',
			renewalPeriod: repeats as any,
			amount: amount!,
			teamId,
			status: 'active',
			startAt: startDate,
			id: !inspiration ? editBudget?.id : undefined,
			physicalCard: allowPhysicalCard,
			receiptRequired: requiresReceipt,
		});
	};

	const getUploadParams = async ({ file, meta }: any) => {
		const response = await client.post(`/s3/signedURL`, {
			fileName: meta.name,
			fileType: meta.type,
			type: 'pot-thumbnail',
		});
		const { directUrl, publicUrl, signedUrl } = response.data;
		return {
			method: 'put' as MethodValue,
			body: file,
			headers: { 'x-amz-acl': 'public-read' },
			meta: { fileUrl: directUrl, publicUrl },
			url: signedUrl,
		};
	};

	const handleChangeStatus = ({ meta, remove }: any, status: string) => {
		if (status === 'headers_received') {
			setThumbnail(meta.fileUrl);
			remove();
		}
	};

	const budgetTypes = [
		{ value: '', label: '' },
		{ value: 'learning', label: 'Learning & Development' },
		{ value: 'transport', label: 'Transport' },
		{ value: 'food', label: 'Food' },
		{ value: 'workfromhome', label: 'Work From Home' },
		{ value: 'fitness', label: 'Fitness' },
		{ value: 'telecoms', label: 'Phone & Internet' },
		{ value: 'other', label: 'Other' },
	];

	const repeatOptions = [
		{ value: '', label: '', disabled: true },
		{ value: 'daily', label: 'Daily (weekday)' },
		{ value: 'weekly', label: 'Weekly' },
		{ value: 'monthly', label: 'Monthly' },
		{ value: 'yearly', label: 'Yearly' },
		{ value: 'quarterly', label: 'Quarterly' },
		{ value: 'biannually', label: 'Bi-Annually' },
		{ value: 'once-off', label: 'Once-off' },
	];
	return (
		<Modal ariaLabel="Add Budget" open={isOpen} onClose={onCancelClick}>
			<ModalContainer>
				<form method="post" onSubmit={(e) => handleOnSaveClick(e)}>
					<Heading2>
						{editBudget && !inspiration ? 'Edit pot' : 'Add new pot'}
					</Heading2>
					{(!editBudget || inspiration) && (
						<p>
							Set up a pot to support your team in their work or lifestyle, and
							control limits, policies, and eligibility.
						</p>
					)}
					{editBudget && startedBudget && (
						<p>
							This pot has started so you are only able to edit the Title, Image
							and Description. Please contact support if you need to edit other
							values.
						</p>
					)}
					<Label htmlFor="title">Title</Label>
					<StyledInput
						placeholder="e.g Work from Home"
						id="title"
						name="title"
						value={title}
						onChange={({ currentTarget }) => {
							setTitle(currentTarget.value);
						}}
						required
					/>

					{!startedBudget && (
						<>
							<Label htmlFor="type">Type</Label>
							<Select
								id="type"
								name="type"
								placeholder="Select a budget type"
								options={budgetTypes}
								value={budgetType}
								onChange={({ target }) => {
									setBudgetType(target.value);
									if (!thumbnail || thumbnail.endsWith(`/${budgetType}.jpg`)) {
										setThumbnail(
											target.value
												? `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/${target.value}.jpg`
												: ''
										);
									}
								}}
								required
							/>
						</>
					)}
					<Thumbnail>
						<Label htmlFor="type">Thumbnail image</Label>
						{!!(budgetType && thumbnail) && (
							<p>
								{!thumbnail.endsWith(`/${budgetType}.jpg`) ? (
									<button
										type="button"
										onClick={() => {
											setThumbnail(
												`https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/${budgetType}.jpg`
											);
										}}
									>
										Use our default image
									</button>
								) : (
									`Use our default image`
								)}{' '}
								or{' '}
								<button
									type="button"
									onClick={() => {
										(
											document.querySelector(
												'.dzu-inputLabel'
											) as HTMLLabelElement
										)?.click();
									}}
								>
									upload your own.
								</button>
							</p>
						)}
						<Dropzone
							getUploadParams={getUploadParams}
							onChangeStatus={handleChangeStatus}
							inputContent={
								!thumbnail ? (
									<ImagePlus key="blank" />
								) : (
									<img
										key="image"
										src={thumbnail}
										alt="pot thumbnail"
										style={{
											width: '100%',
											height: '175px',
											objectFit: 'cover',
										}}
									/>
								)
							}
							maxFiles={1}
							styles={{
								dropzone: {
									width: '100%',
									height: '175px',
									borderRadius: '12px',
									backgroundColor: '#f5f5f5',
									border: 'none',
								},
							}}
						/>
					</Thumbnail>
					{!startedBudget && (
						<>
							<Label htmlFor="amount">{`Amount (${
								{
									gbp: '£',
									eur: '€',
									usd: '$',
								}[currency]
							})`}</Label>
							<StyledInput
								placeholder="0"
								type="number"
								id="amount"
								name="amount"
								min={1}
								max={10000}
								value={Number(amount)}
								onChange={({ currentTarget }) => {
									setAmount(currentTarget.valueAsNumber);
								}}
								required
							/>
							<StyledScheduleContainer>
								<div>
									<Label htmlFor="startDate">Start Date</Label>
									<StyledDateContainer>
										<StyledDateInput
											type="date"
											id="startDate"
											name="startDate"
											value={moment(startDate).format('YYYY-MM-DD')}
											onChange={(e) => {
												setStartDate(new Date(e.target.value));
											}}
											required
										/>
									</StyledDateContainer>
								</div>
								<div>
									<Label htmlFor="repeats">Repeats</Label>
									<Select
										id="repeats"
										name="repeats"
										options={repeatOptions}
										value={repeats}
										onChange={({ target }) => setRepeats(target.value)}
										required
									/>
								</div>
							</StyledScheduleContainer>
							{repeats !== 'once-off' && repeats !== '' && (
								<FlexContainer>
									<div>
										<StyledLabel>
											<input
												id="chkRollover"
												type="checkbox"
												checked={isRollover}
												onChange={() => setIsRollover(!isRollover)}
											/>
											<span className="slider" />
										</StyledLabel>
									</div>
									<ParagraphContainer>
										<label htmlFor="chkRollover">Rollover</label>
										<p>
											Allow employees to save up their allowance across multiple
											periods. You will not be charged unless your team members
											spend from their allowance.
										</p>
									</ParagraphContainer>
								</FlexContainer>
							)}
							<FlexContainer>
								<div>
									<StyledLabel>
										<input
											id="chkPhysicalCard"
											type="checkbox"
											checked={allowPhysicalCard}
											onChange={() => setAllowPhysicalCard(!allowPhysicalCard)}
										/>
										<span className="slider" />
									</StyledLabel>
								</div>
								<ParagraphContainer>
									<label htmlFor="chkPhysicalCard">Physical card</label>
									<p>
										{`Allow employees to order a physical card to their location.
										A charge of ${
											{
												gbp: '£5',
												eur: '€6',
												usd: '$7',
											}[currency]
										} will be added to your next invoice per
										card ordered`}
									</p>
								</ParagraphContainer>
							</FlexContainer>
							<FlexContainer>
								<div>
									<StyledLabel>
										<input
											id="chkRequiresReceipt"
											type="checkbox"
											checked={requiresReceipt}
											onChange={() => setRequiresReceipt(!requiresReceipt)}
										/>
										<span className="slider" />
									</StyledLabel>
								</div>
								<ParagraphContainer>
									<label htmlFor="chkRequiresReceipt">Requires receipt</label>
									<p>
										Employees will be required to upload a receipt when spending
										with their Visa Card (receipts always required for
										expenses/reimbursements).
									</p>
								</ParagraphContainer>
							</FlexContainer>
						</>
					)}

					<Label htmlFor="policy" style={{ marginBottom: '4px' }}>
						Policy
					</Label>
					<SubLabel>
						<Info />
						Write a clear policy that your employees must follow while using
						this pot.
					</SubLabel>
					<RichTextEditor
						placeholder="e.g. Only use this card on desks or other peripherals"
						handleUpdate={setPolicy}
						prefill={policy}
						required
					/>

					<StyledHr style={{ marginBottom: '0' }} />

					<AdminButtons>
						<Button
							type="button"
							size="small"
							onClick={() => {
								onCancelClick();
							}}
							state="default"
							buttonType="secondary"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							state={isSaving ? 'loading' : 'default'}
							size="small"
						>
							{editBudget && !inspiration ? 'Save' : 'Add'}
						</Button>
					</AdminButtons>
				</form>
			</ModalContainer>
		</Modal>
	);
};

export default AddEditBudgetModal;
