/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Circle } from '../../styles/ProductStyles';
import { handleBackNavigation } from '../../components/utilities/Functions';
import Loading from '../../components/utilities/Loading';
import Menu from '../../styles/shared/Menu';
import EventDetail from './EventDetail';
import { useProductContext } from '../../contexts/productContext';
import { useUserContext } from '../../contexts/userContext';
import ProductCard from '../../styles/shared/ProductCard/ProductCard';

type FilterOption = {
	key: string;
	value: string;
};

const Events = ({ match }: any) => {
	const history = useHistory();
	const { allEvents, setOrderBy, orderBy } = useProductContext();
	const { userData } = useUserContext();
	const filterOptions: FilterOption[] = [
		{
			key: 'popularity',
			value: 'Most Popular',
		},
		{
			key: 'price_desc',
			value: 'Cost - High to Low',
		},
		{
			key: 'price_asc',
			value: 'Cost - Low to High',
		},
	];

	const [city] =
		userData?.locationData?.address.address_components?.filter(
			(loc: any) =>
				loc.types.includes('locality') || loc.types.includes('postal_town')
		) || [];

	if (city?.long_name) {
		filterOptions.push({
			key: 'proximity',
			value: `Nearest to ${city?.long_name}`,
		});
	}

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			{!match.params.eventId ? (
				<StyledContainer>
					<Circle
						onClick={() => {
							handleBackNavigation(history);
						}}
					>
						<MdArrowBack size={20} />
					</Circle>
					<FlexContainer justify="space-between" align="baseline">
						<MainHeading>Events</MainHeading>
						<Filters>
							<Menu
								text="Sort By:"
								selected={orderBy}
								setSelected={(value) => {
									setOrderBy(value);
								}}
								options={filterOptions}
							/>
						</Filters>
					</FlexContainer>
					{!allEvents ? (
						<Loading />
					) : (
						<TilesContainer>
							{allEvents.length > 0
								? allEvents.map(
										({
											id,
											image,
											fromPoints,
											city: eventCity,
											state,
											country,
											title,
											shortDescription,
											venueName,
										}: any) => {
											return (
												<ProductCard
													key={id}
													imgUrl={image}
													productTitle={title}
													productDescription={shortDescription}
													productId={id}
													provider=""
													providerImgUrl=""
													pointsValue={fromPoints}
													enquire={false}
													reasonable={false}
													reason=""
													productType="Event"
													isFromPrice
													location={`${
														venueName ? `${venueName}, ` : ''
													}${eventCity}, ${
														state ? `${state}, ` : ''
													}${country}`}
												/>
											);
										}
								  )
								: 'Sorry there are no events in your area.'}
							<HiddenElement />
							<HiddenElement />
							<HiddenElement />
						</TilesContainer>
					)}
				</StyledContainer>
			) : (
				<EventDetail eventId={match.params.eventId} />
			)}
		</div>
	);
};

const HiddenElement = styled.div<{ mimicWidth?: string }>`
	flex: ${({ mimicWidth }) => (mimicWidth ? `1 0 ${mimicWidth}` : '1 0 260px')};
	visibility: hidden;
	content: '';
`;

const StyledContainer = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1250px;
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

const TilesContainer = styled.div`
	max-width: 1250px;
	margin: auto;
	margin-top: 50px;
	margin-bottom: 125px;

	@media (max-width: 500px) {
		margin-bottom: 25px;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 0 70px;
`;

const Filters = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	justify-items: self-end;
	@media (max-width: 992px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		margin-bottom: 16px;
		justify-items: flex-start;
	}
`;

const FlexContainer = styled.div<{
	margin?: string;
	justify?: string;
	align?: string;
}>`
	display: flex;
	margin: ${({ margin }) => margin};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

const MainHeading = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 38px;
	color: #000000;
	margin-top: 31px;
	margin-bottom: 24px;
`;

export default Events;
