const integrationImages = {
	CharlieHR:
		'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_Square_Logo.png',
	Charlie:
		'https://merge-api-production.s3.amazonaws.com/media/CharlieHR_Square_Logo.png',
	Hibob:
		'https://merge-api-production.s3.amazonaws.com/media/Hibob_Square_Logo.jpg',
	BambooHR:
		'https://merge-api-production.s3.amazonaws.com/media/BambooHR_Square_Logo.jpg',
	Humaans:
		'https://merge-api-production.s3.amazonaws.com/media/Humaans_Square_Logo.jpg',
	Workday:
		'https://merge-api-production.s3.amazonaws.com/media/Workday_Square_Logo.jpg',
	Factorial:
		'https://merge-api-production.s3.amazonaws.com/media/Factorial_Square_Logo.png',
	SuccessFactors:
		'https://merge-api-production.s3.amazonaws.com/media/SuccessFactors_Square_Logo.jpg',
	Personio:
		'https://merge-api-production.s3.amazonaws.com/media/Personio_Square_Logo.png',
};

export default integrationImages;
