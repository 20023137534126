import React, { ButtonHTMLAttributes, ForwardedRef } from 'react';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';
import Spinner from '../Spinner/Spinner';

type ButtonType = 'primary' | 'secondary' | 'destructive' | 'hollow';
type ButtonState = 'default' | 'disabled' | 'loading';
type ButtonSize = 'default' | 'small';

type ButtonProps = {
	buttonType?: ButtonType;
	state: ButtonState;
	size?: ButtonSize;
	icon?: IconType | undefined;
} & ButtonHTMLAttributes<HTMLButtonElement>;

interface StyledButtonProps {
	buttonType?: ButtonType;
	buttonSize?: ButtonSize;
	disabled: boolean;
	state: ButtonState;
}

const CalculatedBackground = (type: ButtonType) => {
	switch (type) {
		case 'primary':
			return '#191a1c';
		case 'secondary':
			return 'rgba(0,0,0, 0.05)';
		case 'destructive':
			return '#FAE1E7';
		case 'hollow':
			return '#ffff';
		default:
			return '#191a1c';
	}
};

const CalculatedHoverBackground = (type: ButtonType) => {
	switch (type) {
		case 'primary':
			return '#232426';
		case 'secondary':
			return 'rgba(0,0,0, 0.1)';
		case 'destructive':
			return '#EBD7DD';
		case 'hollow':
			return '#ededed';
		default:
			return '#191a1c';
	}
};

const CalculatedActiveBackground = (type: ButtonType) => {
	switch (type) {
		case 'primary':
			return '#2D2E30';
		case 'secondary':
			return 'rgba(0,0,0, 0.15)';
		case 'destructive':
			return '#E6CDD3';
		case 'hollow':
			return 'rgba(0,0,0, 0.15)';
		default:
			return '#191a1c';
	}
};

const CalculatedFontColor = (type: ButtonType) => {
	switch (type) {
		case 'primary':
			return '#ffffff';
		case 'secondary':
			return '#191a1c';
		case 'destructive':
			return '#CC1440';
		default:
			return '#191a1c';
	}
};

const StyledButton = styled.button<StyledButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: ${(props) => (props.buttonSize === 'default' ? '100%' : '80px')};
	padding: ${({ buttonSize, state }) =>
		// eslint-disable-next-line no-nested-ternary
		buttonSize === 'small'
			? state !== 'loading'
				? '15px 40px'
				: '11px 40px'
			: undefined};
	min-height: ${(props) => (props.buttonSize === 'default' ? '64px' : '40px')};
	background-color: ${(props) =>
		CalculatedBackground(props.buttonType || 'primary')};
	color: ${(props) => CalculatedFontColor(props.buttonType || 'primary')};
	opacity: ${(props) => (props.state === 'disabled' ? '0.5' : '1')};
	border: ${(props) =>
		props.buttonType === 'hollow' ? '1.5px solid #0A0F16' : '0'};
	border-radius: 50px;

	font-family: 'TTInterfaces Demibold';
	font-style: normal;
	font-size: 17px;
	line-height: 15px;
	white-space: nowrap;

	&:hover {
		background-color: ${(props) =>
			CalculatedHoverBackground(props.buttonType || 'primary')};
	}
	&:active {
		background-color: ${(props) =>
			CalculatedActiveBackground(props.buttonType || 'primary')};
	}
	transition: all 0.2s ease-out;

	@media (max-width: 480px) {
		padding: ${(props) =>
			props.buttonSize === 'small' ? '15px 30px' : undefined};
	}
`;

const Button = React.forwardRef(
	(
		{
			buttonType = 'primary',
			state,
			size = 'default',
			icon: Icon,
			children,
			...props
		}: ButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => (
		<>
			{state === 'default' ? (
				<StyledButton
					ref={ref}
					type="submit"
					buttonType={buttonType}
					buttonSize={size}
					disabled={false}
					state={state}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				>
					{children}
					{Icon && <Icon size={24} />}
				</StyledButton>
			) : null}
			{state === 'disabled' ? (
				<StyledButton
					ref={ref}
					type="submit"
					buttonType={buttonType}
					disabled
					state={state}
					buttonSize={size}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				>
					{children}
					{Icon && <Icon size={24} />}
				</StyledButton>
			) : null}
			{state === 'loading' ? (
				<StyledButton
					ref={ref}
					type="submit"
					buttonType={buttonType}
					buttonSize={size}
					disabled
					state={state}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				>
					<Spinner color={CalculatedFontColor(buttonType || 'primary')} />
				</StyledButton>
			) : null}
		</>
	)
);

export default Button;
