import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import {
	imgEmployeesPampered,
	imgFlowersSent,
	imgFocusEmotional,
	imgFocusPhysical,
	imgFocusPsychological,
	imgFocusSocial,
	imgLanguagesLearnt,
	imgPointsDonated,
	imgPointsGiftedByColleaguesThisQuarter,
	imgPointsGiftedByTeamThisQuarter,
	imgTreesPlanted,
} from './ManageWellbeing/WellbeingImages';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 80px;
`;

const StyledTilesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	padding-top: 35px;
	box-sizing: border-box;
	gap: 40px;
`;

const StyledHeading = styled.h1`
	font-family: 'TTInterfaces Bold';
	font-size: 64px;
	line-height: 120%;
	@media (max-width: 760px) {
		font-size: 48px;
		text-align: center;
	}
`;

const StyledSubHeading = styled.h2`
	font-family: 'TTInterfaces DemiBold';
	font-size: 36px;
	line-height: 120%;
	opacity: 0.4;
	margin-top: 12px;
	@media (max-width: 760px) {
		font-size: 24px;
	}
`;

const DownloadButton = styled.button`
	font-family: 'TTInterfaces DemiBold';
	height: 48px;
	background: #000000;
	color: #ffffff;
	font-weight: 600;
	font-size: 19px;
	line-height: 24px;
	border: 0px;
	border-radius: 94px;
	padding: 12px 24px;
	margin-top: 50px;
	margin-bottom: 35px;
`;

const shortenNumber = (num: number): string => {
	if (num >= 1000 && num < 1000000) {
		const prefixes = ['', 'k', 'M', 'B']; // metric prefixes
		const exp = Math.floor(Math.log10(num) / 3); // determine the exponent
		const divisor = 10 ** (exp * 3); // determine the divisor
		const shortNum = Math.round((num / divisor) * 10) / 10; // divide and round to 1 decimal
		return shortNum.toString() + prefixes[exp];
	}
	// for numbers outside the desired range, return as-is
	return num.toString();
};

const ManageWellbeingEngagement = ({ team }: any) => {
	const pdfRef = useRef<HTMLDivElement>(null);

	const engagementReport = team.engagementReport
		? Object.entries(team.engagementReport)
		: [];

	const wellnessValues: ReportTileProps[] =
		engagementReport.reduce((results: any, entry: any) => {
			switch (entry[0]) {
				case 'flowersSent':
					if (entry[1] > 0 && entry[1] < 5)
						results.push({
							name: entry[0],
							tileType: 'badge_smtext',
							badgeText: '💐 Thoughtful',
							badgeColor: 'rgba(255, 255, 255, 0.2);',
							lgText: 'Colleagues sent flowers to a loved one',
							imgUrl: imgFlowersSent,
						});
					if (entry[1] >= 5)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '💐 Thoughtful',
							badgeColor: '#D3AC72;',
							lgText: `${entry[1]}`,
							smText: 'bouquets of flowers sent to loved ones',
						});
					break;
				case 'pointsDonated':
					if (entry[1] > 0 && entry[1] < 250)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '🫶 Giving',
							badgeColor: '#9C86C2',
							lgText: 'Your team donated Juno Points® to charity',
							imgUrl: imgPointsDonated,
						});
					if (entry[1] >= 250)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🫶 Giving',
							badgeColor: '#9C86C2',
							lgText: `${entry[1]}`,
							smText: 'Juno Points® donated to charity',
						});
					break;
				case 'treesPlanted':
					if (entry[1] > 0 && entry[1] < 25)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '🌳 Conscious',
							badgeColor: '#80B8BC',
							lgText: 'Your employees used Juno Points® to plant trees',
							imgUrl: imgTreesPlanted,
						});
					if (entry[1] >= 25)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🌳 Conscious',
							badgeColor: '#80B8BC',
							lgText: `${entry[1]}`,
							smText: 'trees planted with Juno Points®',
						});
					break;
				case 'employeesEngagedPercentage':
					if (entry[1] > 85)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '⚡️ Active',
							badgeColor: '#9C86C2',
							lgText: `${entry[1]}%`,
							smText: 'of your employees engage with Juno',
						});
					break;
				case 'categoryBookedMostThisQuarter':
					if (entry[1])
						results.push({
							name: entry[0],
							tileType: 'badge_smtext_smtext',
							badgeText: '🚀 Trendsetters',
							badgeColor: '#C7A687',
							lgText: entry[1] as unknown as string,
							smText: 'is the most popular category this month',
						});
					break;
				case 'pointsGiftedByColleaguesThisQuarter':
					if (entry[1] > 0 && entry[1] < 50)
						results.push({
							name: entry[0],
							tileType: 'badge_smtext',
							badgeText: '👊 Supportive',
							badgeColor: '#CE7182',
							smText: 'Your team gifted Juno Points® to each other',
							imgUrl: imgPointsGiftedByColleaguesThisQuarter,
						});
					if (entry[1] >= 50)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '👊 Supportive',
							badgeColor: '#80B8BC',
							lgText: `${shortenNumber(entry[1])}`,
							smText: 'Juno Points® gifted to each other',
						});
					break;
				case 'pointsGiftedByTeamThisQuarter':
					if (entry[1] > 0 && entry[1] < 100)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '👊 Supportive',
							badgeColor: '#CE7182',
							lgText:
								'You gifted Juno Points® from the team wallet in the last 90 days',
							imgUrl: imgPointsGiftedByTeamThisQuarter,
						});
					if (entry[1] >= 100)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '👊 Supportive',
							badgeColor: '#CE7182',
							lgText: shortenNumber(entry[1]),
							smText:
								'Juno Points® gifted from the team wallet in the last 90 days',
						});
					break;
				case 'languagesLearnt':
					if (entry[1] > 0 && entry[1] < 3)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '🌍 Globetrotters',
							badgeColor: '#D46F68',
							lgText: 'Your team are learning new languages',
							imgUrl: imgLanguagesLearnt,
						});
					if (entry[1] >= 3)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🌍 Globetrotters',
							badgeColor: '#D46F68',
							lgText: shortenNumber(entry[1]),
							smText: 'of your employees learnt a language',
						});
					break;
				case 'cardsUsedPercentageThisQuarter':
					if (entry[1] > 70)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '💪 Flexible',
							badgeColor: '#7EAF71',
							lgText: `${entry[1]}%`,
							smText:
								'of your employees use Juno Flex Card® in the last 90 days',
						});
					break;
				case 'eventsBooked':
					if (entry[1] >= 10)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🎪 Adventurous',
							badgeColor: '#CA8AB8',
							lgText: shortenNumber(entry[1]),
							smText: 'events and experiences',
						});
					break;
				case 'pointsSpentOnProducts':
					if (entry[1] > 0)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '✨ Impressive',
							badgeColor: '#7EAF71',
							lgText: shortenNumber(entry[1]),
							smText: 'Juno Points® spent on their wellbeing',
						});
					break;
				case 'productsBookedThisYear':
					if (entry[1] >= 50)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🚚 Impactful',
							badgeColor: '#D17E64',
							lgText: shortenNumber(entry[1]),
							smText: 'wellbeing products delivered in the last 12 months',
						});
					break;
				case 'healthFocusThisQuarter':
					if (entry[1]) {
						let img;
						switch (entry[1]) {
							case 'emotional':
								img = imgFocusEmotional;
								break;
							case 'physical':
								img = imgFocusPhysical;
								break;
							case 'psychological':
								img = imgFocusPsychological;
								break;
							case 'social':
								img = imgFocusSocial;
								break;
							default:
								break;
						}

						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '🧘 Mindful',
							badgeColor: 'rgba(255, 255, 255, 0.2);',
							lgText: `This month's focus is on ${entry[1]} wellbeing`,
							imgUrl: img,
						});
					}
					break;
				case 'employeesPampered':
					if (entry[1] > 0 && entry[1] < 5)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext',
							badgeText: '🧖 Relaxed',
							badgeColor: 'rgba(255, 255, 255, 0.2);',
							lgText: 'Your team pampered themselves',
							imgUrl: imgEmployeesPampered,
						});
					if (entry[1] >= 5)
						results.push({
							name: entry[0],
							tileType: 'badge_lgtext_smtext',
							badgeText: '🧖 Relaxed',
							badgeColor: '#CE7182',
							lgText: shortenNumber(entry[1]),
							smText: 'colleagues pampered themselves',
						});
					break;
				default:
					break;
			}
			return results;
		}, []) || [];

	const downloadTiles = async (element: any) => {
		const downloadHelper = await import('./downloadHelper');
		downloadHelper.downloadTiles(element);
	};

	return (
		<StyledContainer id="pdf" ref={pdfRef}>
			<StyledHeading id="heading1">
				{team.engagementReport?.aboveThreshold
					? 'Your employees love Juno.'
					: 'Come back soon!'}
			</StyledHeading>
			<StyledSubHeading id="heading2">
				{team.engagementReport?.aboveThreshold
					? "We'll let the data do the talking"
					: 'We are in the process of compiling your data for this month. Please check back soon to see the latest data.'}
			</StyledSubHeading>
			{team.engagementReport?.aboveThreshold && (
				<>
					<DownloadButton
						id="download-button"
						type="button"
						onClick={() => downloadTiles(pdfRef.current)}
					>
						Download the report
					</DownloadButton>
					<StyledTilesContainer>
						{wellnessValues.map((value) => {
							return (
								<ReportTile
									key={value.name}
									name={value.name}
									tileType={value.tileType}
									lgText={value.lgText}
									smText={value.smText}
									badgeText={value.badgeText}
									badgeColor={value.badgeColor}
									imgUrl={value.imgUrl}
								/>
							);
						})}
					</StyledTilesContainer>
				</>
			)}
		</StyledContainer>
	);
};

type ReportTileProps = {
	name: string;
	tileType:
		| 'badge_lgtext_smtext'
		| 'badge_smtext_smtext'
		| 'badge_lgtext'
		| 'badge_smtext';
	badgeText: string;
	badgeColor: string;
	lgText?: string;
	smText?: string;
	imgUrl?: string;
};

const ReportTile: FC<ReportTileProps> = ({
	tileType,
	badgeText,
	badgeColor = '#7EAF71',
	lgText,
	smText,
	imgUrl,
	name,
}) => {
	const tileRef = useRef<HTMLDivElement>(null);

	const downloadTile = async (tile: any, tileName: any) => {
		const downloadHelper = await import('./downloadHelper');
		downloadHelper.downloadTile(tile, tileName);
	};

	const lgFontSize = () => {
		switch (tileType) {
			case 'badge_lgtext':
			case 'badge_smtext':
				return '34px';
			case 'badge_smtext_smtext':
				return '48px';
			default:
				return '100px';
		}
	};

	return (
		<StyledTileContainer
			id={`wellness-tile-${name}`}
			imgUrl={imgUrl}
			ref={tileRef}
		>
			<TileDownload
				onClick={() => {
					downloadTile(tileRef, name);
				}}
				id={`mini-download-button-${name}`}
			>
				<svg width="24" height="24">
					<path
						d="M11.9123 22.2163C6.23545 22.2163 1.62122 17.602 1.62122 11.9252C1.62122 6.24833 6.23545 1.63409 11.9123 1.63409C17.5891 1.63409 22.2034 6.24833 22.2034 11.9252C22.2034 17.602 17.5891 22.2163 11.9123 22.2163ZM11.9123 3.07006C7.03002 3.07006 3.05718 7.0429 3.05718 11.9252C3.05718 16.8075 7.03002 20.7803 11.9123 20.7803C16.7946 20.7803 20.7674 16.8075 20.7674 11.9252C20.7674 7.0429 16.7946 3.07006 11.9123 3.07006Z"
						fill="black"
					/>
					<path
						d="M11.9123 15.0364C11.5198 15.0364 11.1943 14.7109 11.1943 14.3184V8.57455C11.1943 8.18205 11.5198 7.85657 11.9123 7.85657C12.3048 7.85657 12.6303 8.18205 12.6303 8.57455V14.3184C12.6303 14.7109 12.3048 15.0364 11.9123 15.0364Z"
						fill="black"
					/>
					<path
						d="M11.9123 15.9937C11.7304 15.9937 11.5485 15.9267 11.4049 15.7831L8.53295 12.9112C8.25533 12.6336 8.25533 12.1741 8.53295 11.8964C8.81057 11.6188 9.27008 11.6188 9.5477 11.8964L11.9123 14.261L14.2768 11.8964C14.5544 11.6188 15.0139 11.6188 15.2916 11.8964C15.5692 12.1741 15.5692 12.6336 15.2916 12.9112L12.4196 15.7831C12.276 15.9267 12.0941 15.9937 11.9123 15.9937Z"
						fill="black"
					/>
				</svg>
			</TileDownload>
			<Badge color={badgeColor}>{badgeText}</Badge>
			<StyledTextContainer>
				<LgText fontSize={lgFontSize()}>{lgText}</LgText>
				{[
					'badge_lgtext_smtext',
					'badge_smtext',
					'badge_smtext_smtext',
				].includes(tileType) ? (
					<SmText imgUrl={imgUrl}>{smText}</SmText>
				) : null}
			</StyledTextContainer>
		</StyledTileContainer>
	);
};

const TileDownload = styled.button`
	background: #ffffff;
	box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
	position: absolute;
	border: 0;
	top: 10px;
	right: 10px;
	cursor: pointer;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledTextContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledTileContainer = styled.div<{ imgUrl: string | undefined }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px 40px;
	width: 350px;
	height: 333px;
	background-color: #f2f2f2;
	border-radius: 30px;
	background: ${({ imgUrl }) =>
		imgUrl &&
		`linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),url(${imgUrl}) no-repeat center center / cover`};
	color: ${({ imgUrl }) => (imgUrl ? '#FFFFFF' : '#000000')};
	text-align: center;
`;

type BadgeProps = {
	color: string;
};

const Badge = styled.div<BadgeProps>`
	font-family: 'TTInterfaces DemiBold';
	padding: 6px 12px;
	height: 36px;
	border-radius: 86px;
	background-color: ${({ color }) => color || '#afafaf'};
	color: #ffffff;

	font-size: 16px;
	line-height: 22px;
`;

type LgTextProps = {
	fontSize: string;
};

const LgText = styled.span<LgTextProps>`
	font-family: 'TTInterfaces DemiBold';
	font-size: ${({ fontSize }) => fontSize};
	line-height: 120%;
	margin-top: 12px;
`;

const SmText = styled.span<{ imgUrl: string | undefined }>`
	font-family: 'TTInterfaces DemiBold';
	font-size: 26px;
	line-height: 115%;
	color: ${({ imgUrl }) => (imgUrl ? '#ffffff' : '#000000')};
	opacity: ${({ imgUrl }) => (imgUrl ? 0.75 : 0.35)};
	padding: 6px 12px;
`;

export default ManageWellbeingEngagement;
