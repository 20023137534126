import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import config from '../../config';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import Loading from '../utilities/Loading';
import SubHeader from './ManageTeam/SubHeader';
import TeamTransactionsTable from './ManagePoints/TeamTransactionsTable';
import TeamPoints from './ManagePoints/TeamPoints';
import SearchInput from './ManageTeam/SearchInput';
import PreferenceWrapper from '../../views/teams/teamPoints/Preference/PreferenceWrapper';
import { useClient } from '../../hooks';

const StyledContainer = styled.div`
	padding: 40px 80px;

	@media (max-width: 1250px) {
		padding: 0px;
	}
`;

const StyledRow = styled(Row)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: 1250px) {
		justify-content: space-between;
		margin: 40px 0px;
	}
`;

const StyledLoading = ({ height }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: height || '50vh',
				width: '100%',
				textAlign: 'center',
			}}
		>
			<Loading />
		</div>
	);
};

const ManagePointsWrapper = ({ team, handleUpdate }) => {
	const { loading } = useAuth0();
	const client = useClient();

	const [transactions, setTransactions] = useState([]);
	const [filter, setFilter] = useState('');
	const [filteredTeamMembers, setFilteredTeamMembers] = useState(
		team.teamMembers
	);
	const [filteredTransactions, setFilteredTransactions] = useState([]);
	const [loadingTransactions, setLoadingTransactions] = useState(true);

	const [selectedTable, setSelectedTable] = useState('wallet');

	const getTeamTransactions = useCallback(async () => {
		try {
			setLoadingTransactions(true);
			const response = await client.get(
				`${config.api.HOST}/teams/${team.id}/transactions`
			);
			if (
				response.status === 200 &&
				response.data &&
				response.data.length > 0
			) {
				setTransactions(response.data);
			}
			setLoadingTransactions(false);
		} catch (err) {
			console.error(err.message);
		}
	}, [team.id]);

	useEffect(() => {
		try {
			getTeamTransactions();
		} catch (err) {
			console.error(err.message);
		}
	}, [getTeamTransactions]);

	const handleTransactionsUpdate = async () => {
		try {
			await getTeamTransactions();
		} catch (err) {
			console.error(err.message);
		}
	};

	useEffect(() => {
		try {
			if (filter && filter !== '') {
				const results = team.teamMembers.filter(
					(item) =>
						(item.firstName &&
							item.lastName &&
							`${item.firstName} ${item.lastName}`
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1) ||
						(item.firstName &&
							item.firstName.toLowerCase().indexOf(filter.toLowerCase()) !==
								-1) ||
						(item.lastName &&
							item.lastName.toLowerCase().indexOf(filter.toLowerCase()) !==
								-1) ||
						(item.email &&
							item.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
				);
				const resultsTransactions = transactions.filter(
					(item) =>
						item.transactionDetails.type
							.toLowerCase()
							.indexOf(filter.toLowerCase()) !== -1 ||
						item.description.toLowerCase().indexOf(filter.toLowerCase()) !==
							-1 ||
						item.type.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
						(item.transactionDetails.team &&
							item.transactionDetails.team.name
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1) ||
						(item.transactionDetails.user &&
							item.transactionDetails.user.email
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1) ||
						(item.transactionDetails.user &&
							item.transactionDetails.user.firstName &&
							item.transactionDetails.user.lastName &&
							`${item.transactionDetails.user.firstName} ${item.transactionDetails.user.lastName}`
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1) ||
						(item.transactionDetails.user &&
							item.transactionDetails.user.firstName &&
							item.transactionDetails.user.firstName
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1) ||
						(item.transactionDetails.user &&
							item.transactionDetails.user.lastName &&
							item.transactionDetails.user.lastName
								.toLowerCase()
								.indexOf(filter.toLowerCase()) !== -1)
				);

				setFilteredTeamMembers(results);
				setFilteredTransactions(resultsTransactions);
			} else {
				setFilteredTeamMembers(team.teamMembers);
				setFilteredTransactions(transactions);
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [filter, team.teamMembers, transactions]);

	const handleWalletActivity = () => {
		setSelectedTable('wallet');
	};

	if (loading) {
		return <StyledLoading height="80vh" />;
	}

	return (
		<StyledContainer>
			<TeamPoints
				team={team}
				handleUpdate={handleUpdate}
				handleTransactionsUpdate={handleTransactionsUpdate}
			/>
			<PreferenceWrapper team={team} handleUpdate={handleUpdate} />
			<Row noGutters className="justify-content-between align-items-center ">
				<Col lg={6} xs={12}>
					<Row noGutters className="justify-content-start align-items-center ">
						<SubHeader
							selectedTable={selectedTable}
							handleHeaderOneClick={handleWalletActivity}
							header1="Wallet Activity"
							selectedHeader1="wallet"
						/>
					</Row>
				</Col>
				<Col lg={6} xs={12} md={6}>
					<StyledRow noGutters>
						<SearchInput
							className="mx-2 "
							filter={filter}
							handleChange={(e) => setFilter(e)}
							handleClose={() => setFilter('')}
						/>
					</StyledRow>
				</Col>
			</Row>
			<Row noGutters>
				{selectedTable && selectedTable === 'wallet' && (
					<>
						{loadingTransactions ? (
							<StyledLoading height="20vh" />
						) : (
							<TeamTransactionsTable
								transactions={filteredTransactions}
								handleUpdate={handleUpdate}
							/>
						)}
					</>
				)}
			</Row>
		</StyledContainer>
	);
};

export default ManagePointsWrapper;
