/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PhoneInput, {
	isValidPhoneNumber,
	Value,
} from 'react-phone-number-input';
import Loading from '../../components/utilities/Loading';
import {
	H2,
	Paragraph,
	Separator,
	SmallTextSecondaryMedium,
} from '../../styles/StylesV3Main';
import Button from '../../styles/shared/Button/Button';
import client from '../../api/client';
import config from '../../config';
import { useUserContext } from '../../contexts/userContext';
import { useCurrentTeam } from '../../hooks';

const Checkout = ({
	basket,
	basketId,
	purchased,
	close,
	setPurchased,
}: {
	basket: {
		basketContents: {
			eventName: string;
			quantity: number;
			date: string;
			amount: number;
			currency: string;
			eventImage: string;
		};
		sufficientPoints: boolean;
	} | null;
	basketId: string | null;
	purchased: boolean;
	close: () => void;
	setPurchased: (isPurchased: boolean) => void;
}) => {
	const { userData, updateUserData } = useUserContext();
	const currentTeam = useCurrentTeam();

	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState(userData.firstName || '');
	const [lastName, setLastName] = useState(userData.lastName || '');
	const [email, setEmail] = useState(userData.email);
	const [phoneNumber, setPhoneNumber] = useState<Value | undefined>('');

	const checkout = async () => {
		setLoading(true);
		const teamId = currentTeam?.id;

		try {
			const response = await client.put(
				`${config.products.HOST}/products/events/basket/${basketId}/${
					teamId || ''
				}`,
				{
					firstName,
					lastName,
					email,
					phoneNumber,
				}
			);

			if (response.status === 200) {
				updateUserData();
				setPurchased(true);
				setLoading(false);
			}
		} catch (error) {
			close();
			console.error(error);
		}
	};

	const phoneRef = useRef<HTMLInputElement>(null);

	if (!basket || loading)
		return (
			<div style={{ padding: '40px' }}>
				<Loading />
			</div>
		);

	const { quantity, eventName, date, amount, eventImage } =
		basket.basketContents;

	return (
		<CheckoutContainer>
			{!purchased ? (
				<>
					<H2 style={{ paddingBottom: '4px' }}>Confirm your order</H2>
					<SmallTextSecondaryMedium>
						Please enter your details and pay with your Juno Points.
					</SmallTextSecondaryMedium>
					<Separator style={{ margin: '24px 0' }} />

					<div>
						<h5>Details</h5>
						<div>
							<Paragraph>
								{quantity} ticket(s) for {eventName}
							</Paragraph>

							<Paragraph>{moment(date).format('MMMM Do, YYYY')}</Paragraph>

							<Paragraph>{moment(date).format('h:mm a')}</Paragraph>
						</div>
					</div>

					<Separator style={{ marginTop: '24px' }} />

					<Form
						onSubmit={(e) => {
							e.preventDefault();
							checkout();
						}}
					>
						<label>First name</label>
						<input
							required
							type="text"
							value={firstName}
							onChange={({ target }) => setFirstName(target.value)}
						/>

						<label>Last name</label>
						<input
							required
							type="text"
							value={lastName}
							onChange={({ target }) => setLastName(target.value)}
						/>

						<label>Email</label>
						<input
							required
							type="email"
							value={email}
							onChange={({ target }) => setEmail(target.value)}
						/>

						<label>Phone number</label>

						<div style={{ width: '100%' }}>
							<PhoneInput
								required
								ref={phoneRef as any}
								defaultCountry="GB"
								id="phoneNumber"
								value={phoneNumber}
								onChange={(value) => {
									setPhoneNumber(value);
									phoneRef.current!.setCustomValidity(
										!phoneRef.current!.value ||
											(value && isValidPhoneNumber(value as string))
											? ''
											: 'Please enter a valid phone number'
									);
								}}
							/>
						</div>

						{!basket.sufficientPoints && (
							<TopUpWarning>
								<p>
									This order costs more than you have in your wallet. <br />
								</p>
							</TopUpWarning>
						)}

						<Separator style={{ margin: '24px 0' }} />

						<div>
							<h5>Total</h5>
							<h1>{Math.ceil(amount)} points</h1>
						</div>

						<Button
							style={{ marginTop: '24px' }}
							type="submit"
							state={basket.sufficientPoints ? 'default' : 'disabled'}
							buttonType="primary"
						>
							{basket.sufficientPoints
								? 'Confirm and Pay'
								: 'Insufficient points'}
						</Button>
					</Form>
				</>
			) : (
				<>
					<H2 style={{ paddingBottom: '4px' }}>
						Your tickets are on their way!
					</H2>
					<SmallTextSecondaryMedium>
						Your tickets with instructions will arrive soon in your email inbox
					</SmallTextSecondaryMedium>
					<Separator style={{ margin: '24px 0' }} />

					<img alt="tickitto event" src={eventImage} />

					<div>
						<h5>Details</h5>
						<div>
							<Paragraph>
								{quantity} ticket(s) for {eventName}
							</Paragraph>

							<Paragraph>{moment(date).format('MMMM Do, YYYY')}</Paragraph>

							<Paragraph>{moment(date).format('h:mm a')}</Paragraph>
							<Paragraph>
								{firstName} {lastName}
							</Paragraph>
							<Paragraph>{email}</Paragraph>
							<Paragraph>{phoneNumber}</Paragraph>
						</div>
					</div>
					<div style={{ marginTop: '24px' }}>
						<h5>Total</h5>
						<h1>{Math.round(amount)} points</h1>
					</div>

					<Separator style={{ margin: '24px 0' }} />

					<Button
						type="button"
						state="default"
						buttonType="primary"
						onClick={() => close()}
					>
						Done
					</Button>
				</>
			)}
		</CheckoutContainer>
	);
};

const CheckoutContainer = styled.div`
	margin-top: 16px;

	img {
		width: 100%;
		margin-bottom: 24px;
	}
	div {
		display: flex;
		flex-direction: row;

		h5 {
			flex: 1;

			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;

			color: #5b6166;
		}

		h1 {
			font-family: 'Roobert Bold';
			font-style: normal;
			font-size: 32px;
			line-height: 100%;
			text-align: right;
		}

		div {
			display: flex;
			flex-direction: column;
			text-align: right;
		}
	}
`;

const TopUpWarning = styled.div`
	margin-top: 24px;
	width: 100%;
	height: 84px;
	font: 18px 'Roobert Regular';
	color: ${(props) => props.theme.mainColors.color.textWarning};
	background: ${(props) => props.theme.mainColors.color.backgroundWarning};
	border-radius: 3px;
	p {
		margin: auto;
		width: 100%;
		text-align: center;
	}
`;

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;

	label {
		width: 100%;
		margin-top: 24px;
		font-family: 'Roobert Semi Bold';
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
	}

	input {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px;
		gap: 10px;

		height: 56px;

		background: rgba(0, 0, 0, 0.03);
		border-radius: 3px;
		border: none;
	}

	div:first-of-type {
		flex-direction: row;
		width: 100%;

		div {
			div {
				width: unset;
			}

			input {
				flex: 1;
			}
		}
	}
`;

export default Checkout;
