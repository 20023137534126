import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import RecommendedProductCarousel from './RecommendedProductCarousel';

const RecommendedProductContainer = styled.div`
	width: 100%;
	padding: 64px 0px;
	margin: 0px 152px;
	max-width: 1250px;
	background: #fff;
	@media (max-width: 1200px) {
		padding: 64px;
	}
	@media (max-width: 991px) {
		padding: 50px 20px;
	}
`;

const RecommendedProduct = ({ product }) => {
	return (
		<RecommendedProductContainer>
			<Row noGutters className="mt-1">
				<RecommendedProductCarousel
					type="recommended"
					title="Other things you might like"
					baseProduct={product.id}
				/>
			</Row>
		</RecommendedProductContainer>
	);
};

export default RecommendedProduct;
