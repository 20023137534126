import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RawDraftContentState } from 'draft-js';
import Button from '../../styles/shared/Button/Button';
import RichTextEditor from '../../styles/shared/RichTextEditor';
import { useClient } from '../../hooks';
import config from '../../config';
import BenefitsContainer from '../../styles/shared/Benefits/BenefitsContainer';
import {
	H1,
	H2,
	ParagraphGrey,
	SmallTextSecondaryMedium,
	SmallTextSecondary,
} from '../../styles/StylesV3Main';
import Spinner from '../../styles/shared/Spinner/Spinner';
import Select from '../../styles/shared/Select';
import Modal from '../../styles/shared/Modal';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	margin-top: 8px;
	gap: 40px;

	padding: 40px 80px;

	@media (max-width: 1250px) {
		padding: 0px;
	}
`;

const BenefitsBlurb = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

const AddBenefitButton = styled(Button)`
	margin-top: 20px;

	@media (max-width: 900px) {
		align-self: flex-end;
		margin-right: 8px;
	}
`;

const BenefitsListContainer = styled.div`
	display: flex;
	flex: 1;

	align-items: center
	background: #ffffff;
`;

const StyledParagraphGrey = styled(ParagraphGrey)`
	margin-top: 8px;
`;

const AddModalContainer = styled.div`
	position: relative;
	background: white;
	width: 560px;

	@media (max-width: 900px) {
		width: unset;
	}

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

const EditModalContainer = styled.div`
	position: relative;
	background: white;
	width: 560px;

	@media (max-width: 900px) {
		width: unset;
	}
`;

const Heading2 = styled(H2)`
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
`;

const Heading1 = styled(H1)`
	@media (max-width: 1250px) {
		font-size: 32px;
	}
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const AddModalBody = styled.div`
	padding: 24px;
`;

const EditModalBody = styled.div`
	padding: 24px;
`;

const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
`;

const Label = styled.label`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	margin-top: 24px;
`;

const StyledInput = styled.input`
	width: 512px;
	height: 56px;

	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	border: none;
	padding: 0 16px;

	@media (max-width: 900px) {
		width: 100%;
	}

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

const StyledTextArea = styled.textarea`
	width: 512px;
	height: fit-content;

	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	border: none;
	padding: 16px;

	@media (max-width: 900px) {
		width: 100%;
	}

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

const SubLabel = styled(SmallTextSecondaryMedium)`
	padding-top: 8px;
`;

const OptionalText = styled(SmallTextSecondary)`
	padding-left: 4px;
	display: inline-block;
`;

const ManageBenefitsWrapper = ({ team }: any) => {
	const { id: teamId } = team;

	const client = useClient();
	const [benefits, setBenefits] = useState<
		{
			id: number;
			title: string;
			description: RawDraftContentState;
			signUpSteps?: RawDraftContentState;
			signUpUrl?: string;
			type: string;
			eligibility?: string;
		}[]
	>([]);
	const [selectedType, setSelectedType] = useState('');
	const [title, setTitle] = useState<string | undefined>('');
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [signUpUrl, setSignUpUrl] = useState<string | undefined>('');
	const [selectedBenefitId, setSelectedBenefitId] = useState<number | null>(
		null
	);
	const [eligibility, setEligibility] = useState<string | undefined>('');
	const [description, setDescription] = useState<
		RawDraftContentState | undefined
	>();
	const [signUpSteps, setSignUpSteps] = useState<
		RawDraftContentState | undefined
	>();
	const [loading, setLoading] = useState(true);

	const getBenefits = async () => {
		try {
			const benefitsReponse = await client.get(
				`${config.teams.HOST}/teams/benefits/${teamId}`
			);
			const { benefits: benefitsResponse } = benefitsReponse.data;

			setBenefits(benefitsResponse);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const clearForm = async () => {
		setSelectedBenefitId(null);
		setSelectedType('');
		setTitle('');
		setDescription(undefined);
		setSignUpSteps(undefined);
		setSignUpUrl('');
		setEligibility('');
	};

	const handleAddSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const type = selectedType;

		setAddModalOpen(false);
		await client.post(`${config.teams.HOST}/teams/benefits/${teamId}`, {
			benefit: {
				teamId,
				type,
				title,
				description,
				signUpSteps,
				signUpUrl,
				eligibility,
			},
		});

		clearForm();
		await handleUpdate();
	};

	const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const type = selectedType;

		setEditModalOpen(false);
		await client.put(`${config.teams.HOST}/teams/benefits/${teamId}`, {
			benefit: {
				id: selectedBenefitId,
				teamId,
				type,
				title,
				description,
				signUpSteps,
				signUpUrl,
				eligibility,
			},
		});

		clearForm();
		await handleUpdate();
	};

	const setEditableBenefit = (id: number) => {
		const selectedBenefit = benefits.find((benefit) => benefit.id === id)!;

		if (selectedBenefit) {
			setSelectedBenefitId(selectedBenefit.id);
			setSelectedType(selectedBenefit.type);
			setTitle(selectedBenefit.title);
			setDescription(selectedBenefit.description);
			setSignUpSteps(selectedBenefit.signUpSteps);
			setSignUpUrl(selectedBenefit.signUpUrl);
			setEligibility(selectedBenefit.eligibility);
		}
	};

	const handleUpdate = async () => {
		setLoading(true);

		await getBenefits();
	};

	useEffect(() => {
		getBenefits();
	}, [team.id]);

	const types = [
		{ value: '', label: '' },
		{ value: 'Financial', label: 'Financial' },
		{ value: 'Fitness', label: 'Fitness' },
		{ value: 'Food', label: 'Food' },
		{ value: 'Home', label: 'Home' },
		{ value: 'Health', label: 'Health' },
		{ value: 'Life', label: 'Life' },
		{ value: 'Other', label: 'Other' },
		{ value: 'Other Insurance', label: 'Other Insurance' },
		{ value: 'Pension', label: 'Pension' },
		{ value: 'Transport', label: 'Transport' },
	];

	return (
		<Container>
			<BenefitsBlurb>
				<div>
					<Heading1>Benefits</Heading1>
					<StyledParagraphGrey>
						Add all the benefits your company provides for your employees so
						they have a complete view of what is available to them, all in one
						place.
					</StyledParagraphGrey>
				</div>

				<AddBenefitButton
					state="default"
					buttonType="secondary"
					size="small"
					onClick={() => {
						setAddModalOpen(true);
					}}
				>
					Add benefit
				</AddBenefitButton>
			</BenefitsBlurb>

			<BenefitsListContainer>
				{loading ? (
					<div style={{ margin: 'auto' }}>
						<Spinner />
					</div>
				) : (
					<BenefitsContainer
						benefits={benefits}
						user="admin"
						handleUpdate={handleUpdate}
						openEditModal={(id) => {
							setEditableBenefit(id);
							setEditModalOpen(true);
						}}
						openAddModal={() => {
							setAddModalOpen(true);
						}}
					/>
				)}
			</BenefitsListContainer>

			<Modal
				open={addModalOpen}
				ariaLabel="Add Modal"
				ariaDescribedBy="Confirm a Benefit should be Added"
				onClose={() => {
					setAddModalOpen(false);
					clearForm();
				}}
			>
				<AddModalContainer>
					<form method="post" onSubmit={(e) => handleAddSubmit(e)}>
						<AddModalBody>
							<Heading2>Add your benefits</Heading2>

							<StyledHr style={{ marginBottom: '0' }} />

							<Label htmlFor="type">Type</Label>

							<Select
								name="type"
								placeholder="Select a type"
								value={selectedType || ''}
								options={types}
								required
								onChange={({ target }) => setSelectedType(target.value)}
								autoFocus
							/>

							<Label htmlFor="title">Title</Label>
							<StyledInput
								placeholder="e.g Pension by Nest"
								id="title"
								name="title"
								value={title}
								onChange={({ currentTarget }: any) => {
									setTitle(currentTarget.value);
								}}
								required
							/>

							<Label htmlFor="description">Description</Label>

							<RichTextEditor
								placeholder="Describe your benefit in as much detail as possible"
								handleUpdate={setDescription}
								required
							/>

							<Label htmlFor="signupsteps">
								Sign up steps <OptionalText>Optional</OptionalText>
							</Label>

							<RichTextEditor
								placeholder="Describe how an employee can enable this benefit"
								handleUpdate={setSignUpSteps}
							/>

							<Label htmlFor="signupurl">
								Sign up url <OptionalText>Optional</OptionalText>
							</Label>
							<StyledInput
								placeholder="e.g. https://pension.com/sign-up?=your-company"
								id="signupurl"
								name="signupurl"
								value={signUpUrl}
								onChange={({ currentTarget }: any) => {
									setSignUpUrl(currentTarget.value);
								}}
							/>
							<SubLabel>
								If you have some kind of form or your benefit provider has a
								special sign up page please provide it here{' '}
							</SubLabel>

							<Label htmlFor="Eligibility">
								Eligibility <OptionalText>Optional</OptionalText>
							</Label>
							<StyledTextArea
								placeholder="e.g only employees who have been working at the company for over a year"
								id="eligibility"
								name="eligibility"
								value={eligibility}
								onChange={({ currentTarget }: any) => {
									setEligibility(currentTarget.value);
								}}
							/>

							<StyledHr />

							<ButtonRow>
								<Button
									state="default"
									buttonType="secondary"
									size="small"
									type="reset"
									onClick={() => {
										setAddModalOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									state="default"
									buttonType="primary"
									size="small"
								>
									Save
								</Button>
							</ButtonRow>
						</AddModalBody>
					</form>
				</AddModalContainer>
			</Modal>
			<Modal
				open={editModalOpen}
				ariaLabel="Edit Modal"
				ariaDescribedBy="Edit an existing benefit"
				onClose={() => {
					setEditModalOpen(false);
					clearForm();
				}}
			>
				<EditModalContainer>
					<form method="post" onSubmit={(e) => handleEditSubmit(e)}>
						<EditModalBody>
							<Heading2>Edit your benefit</Heading2>

							<StyledHr style={{ marginBottom: '0' }} />

							<Label htmlFor="type">Type</Label>
							<Select
								name="type"
								placeholder="Select a type"
								value={selectedType || ''}
								options={types}
								required
								onChange={({ target }) => setSelectedType(target.value)}
								autoFocus
							/>

							<Label htmlFor="title">Title</Label>
							<StyledInput
								placeholder="e.g Pension by Nest"
								id="title"
								name="title"
								value={title}
								onChange={({ currentTarget }: any) => {
									setTitle(currentTarget.value);
								}}
								required
							/>

							<Label htmlFor="description">Description</Label>

							<RichTextEditor
								placeholder="Describe your benefit in as much detail as possible"
								handleUpdate={setDescription}
								prefill={description}
								required
							/>

							<Label htmlFor="signupsteps">
								Sign up steps <OptionalText>Optional</OptionalText>
							</Label>

							<RichTextEditor
								placeholder="Describe how an employee can enable this benefit"
								handleUpdate={setSignUpSteps}
								prefill={signUpSteps}
							/>

							<Label htmlFor="signupurl">
								Sign up url <OptionalText>Optional</OptionalText>
							</Label>
							<StyledInput
								placeholder="e.g. https://pension.com/sign-up?=your-company"
								id="signupurl"
								name="signupurl"
								value={signUpUrl}
								onChange={({ currentTarget }: any) => {
									setSignUpUrl(currentTarget.value);
								}}
							/>
							<SubLabel>
								If you have some kind of form or your benefit provider has a
								special sign up page please provide it here{' '}
							</SubLabel>

							<Label htmlFor="Eligibility">
								Eligibility <OptionalText>Optional</OptionalText>
							</Label>
							<StyledTextArea
								placeholder="e.g only employees who have been working at the company for over a year"
								id="eligibility"
								name="eligibility"
								value={eligibility}
								onChange={({ currentTarget }: any) => {
									setEligibility(currentTarget.value);
								}}
							/>

							<StyledHr />

							<ButtonRow>
								<Button
									state="default"
									buttonType="secondary"
									size="small"
									type="reset"
									onClick={() => {
										setEditModalOpen(false);
									}}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									state="default"
									buttonType="primary"
									size="small"
								>
									Save
								</Button>
							</ButtonRow>
						</EditModalBody>
					</form>
				</EditModalContainer>
			</Modal>
		</Container>
	);
};

export default ManageBenefitsWrapper;
