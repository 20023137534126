import { BiChevronRight } from 'react-icons/bi';
import styled from 'styled-components';
import { H5 } from '../../../styles/StylesV3Main';

const StyledText = styled(H5)`
	color: ${(props) => (props.color ? props.color : 'black')};
	padding-left: ${(props) => (props.syncing ? '16px' : '0px')};
	transition: 0.2s;
`;

const IntegrationsListItem = ({ title, color, handler, syncing }) => {
	return (
		<li onClick={() => handler(true)} action>
			<StyledText syncing={syncing} color={color}>
				{title}
			</StyledText>
			<BiChevronRight />
		</li>
	);
};

export default IntegrationsListItem;
