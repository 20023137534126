import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
	H2,
	Paragraph,
	ParagraphGrey,
	H5TextSecondary,
} from '../../styles/StylesV3Main';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import bankdetails from '../../utils/bankdetails.json';

const StyledInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 32px 0px;
	width: 100%;
	height: fit-content;
	border-top: 1px solid ${(props) => props.theme.mainColors.color.separator};
	margin: 0px;

	@media (max-width: 992px) {
		padding: 16px 0px;
	}
`;

const renderOption = ({ item, index }) => {
	switch (item.type) {
		case 'bankdetails':
			const bankDetails =
				bankdetails.filter((el) => el.type).length > 0
					? bankdetails.filter((el) => el.type === item.value.type)[0]
					: null;
			return (
				item.value !== '' && (
					<Row
						noGutters
						as={StyledInfoRow}
						key={index}
						className="justify-content-between align-items-start"
					>
						<Col>
							<H5TextSecondary>{item.title}</H5TextSecondary>
						</Col>
						<Col>
							<Paragraph
								key={index}
								style={{
									textAlign: 'right',
									lineHeight: 'initial',
								}}
							>
								Type: {item.value?.type}
								<br />
								{bankDetails ? bankDetails.firstPart : 'Account Number'}:{' '}
								{item.value?.firstPart}
								<br />
								{bankDetails &&
									bankDetails.secondPart &&
									`${bankDetails.secondPart} : ${item.value?.secondPart}`}
								<br />
							</Paragraph>
						</Col>
					</Row>
				)
			);
		case 'quantity':
			return (
				item.value !== '' && (
					<H2 key={index} className="mr-1">
						{item.value + ' x '}
					</H2>
				)
			);
		case 'address':
			return (
				item.value !== '' && (
					<Row
						noGutters
						as={StyledInfoRow}
						key={index}
						className="justify-content-between align-items-start"
					>
						<Col>
							<H5TextSecondary>{item.title}</H5TextSecondary>
						</Col>
						<Col>
							<Paragraph
								style={{
									textAlign: 'right',
									lineHeight: 'initial',
								}}
							>
								{item.value}
							</Paragraph>
						</Col>
					</Row>
				)
			);
		case 'phone':
			return (
				item.value !== '' && (
					<Row noGutters as={StyledInfoRow} key={index}>
						<Col>
							<H5TextSecondary>{item.title}</H5TextSecondary>
						</Col>
						<Col>
							<Paragraph
								style={{
									textAlign: 'right',
									lineHeight: 'initial',
								}}
							>
								{formatPhoneNumberIntl(item.value)}
							</Paragraph>
						</Col>
					</Row>
				)
			);
		case 'file':
			return item.value !== '' && <></>;
		case 'currencyamount':
			return (
				item.value !== '' && (
					<>
						<Row noGutters as={StyledInfoRow} key={index}>
							<Col className="px-0 py-0">
								<H5TextSecondary style={{ textAlign: 'left' }}>
									{item.title}
								</H5TextSecondary>
							</Col>
							<Col className="px-0 py-0">
								<Paragraph
									key={index}
									style={{
										textAlign: 'right',
										lineHeight: 'initial',
									}}
								>
									Currency : {item.value?.type.toUpperCase()}
									<br />
									Amount :{' '}
									{item.value?.amount !== '' ? item.value.amount : 'Enquiry'}
									<br />
								</Paragraph>
							</Col>
						</Row>
					</>
				)
			);
		default:
			return (
				item.value !== '' && (
					<Paragraph
						key={index}
						style={{ textAlign: 'right', lineHeight: 'initial' }}
					>
						{item.title && item.title + ' : '}
						{item.value instanceof Date
							? item.value.toDateString()
							: item.value}
					</Paragraph>
				)
			);
	}
};

const OrderInformation = ({ product, service, options, type }) => {
	return (
		<>
			<Row noGutters className="justify-content-center align-items-center mb-2">
				<ParagraphGrey>{product.provider.title}</ParagraphGrey>
			</Row>
			<Row
				noGutters
				className="justify-content-start align-items-center mb-4 pb-2"
			>
				{options[service.id].values &&
					options[service.id].values.length > 0 &&
					!type &&
					options[service.id].values.map(
						(item, index) =>
							item.type === 'quantity' && renderOption({ item, index })
					)}
				<H2>{service ? service.title : ''}</H2>
			</Row>

			{options[service.id].values && options[service.id].values.length > 0 && (
				<Row
					noGutters
					as={StyledInfoRow}
					className="justify-content-between align-items-start"
				>
					<Col xs={2}>
						<H5TextSecondary>Details</H5TextSecondary>
					</Col>

					<Col xs={10}>
						{options[service.id].values &&
							options[service.id].values.length > 0 &&
							options[service.id].values.map(
								(item, index) =>
									item.type !== 'quantity' &&
									item.type !== 'phone' &&
									item.type !== 'address' &&
									item.type !== 'bankdetails' &&
									item.type !== 'currencyamount' &&
									item.type !== 'file' &&
									item.type !== 'budget' &&
									item.title !== 'Budget Selected' &&
									renderOption({ item, index })
							)}
					</Col>
				</Row>
			)}
			{options[service.id].values &&
				options[service.id].values.length > 0 &&
				options[service.id].values.map(
					(item, index) =>
						item.value !== '' &&
						item.name === 'bankdetails' &&
						renderOption({ item, index })
				)}
			{options[service.id].values &&
				options[service.id].values.length > 0 &&
				options[service.id].values.map(
					(item, index) =>
						item.value !== '' &&
						item.name === 'currencyamount' &&
						renderOption({ item, index })
				)}
			{options[service.id].values &&
				options[service.id].values.length > 0 &&
				options[service.id].values.map(
					(item, index) =>
						item.value !== '' &&
						item.name === 'phone' &&
						renderOption({ item, index })
				)}
			{options[service.id].values &&
				options[service.id].values.length > 0 &&
				options[service.id].values.map(
					(item, index) =>
						item.value !== '' &&
						item.name === 'address' &&
						renderOption({ item, index })
				)}
		</>
	);
};

export default OrderInformation;
