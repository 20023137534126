import React from 'react';
import Loading from '../components/utilities/Loading';

const StyledLoading = ({ height }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: height ? height : '50vh',
				width: '100%',
				textAlign: 'center',
			}}
		>
			<Loading />
		</div>
	);
};

export default StyledLoading;
