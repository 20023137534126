/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// Modules
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import { useProductContext } from '../contexts/productContext';
import { useAuth0 } from '../contexts/react-auth0-spa';

// Utilities
import config from '../config';
import client from '../api/client';

// Views
import ProductPage from '../components/productComponent/ProductPage';
import ServicePage from '../components/productComponent/ServicePage';
import ConfirmOrderPage from '../components/productComponent/ConfirmOrderPage';

import { useUserContext } from '../contexts/userContext';
import OrderResultPage from '../components/productComponent/OrderResultPage';
import { handleBackNavigation } from '../components/utilities/Functions';

const Product = ({ match }) => {
	// Contexts
	const { userData } = useUserContext();
	const { loading, products } = useProductContext();
	const { getTokenSilently, isAuthenticated } = useAuth0();

	// Hooks
	const history = useHistory();

	// States
	const [product, setProduct] = useState();
	const [service, setService] = useState();
	const [options, setOptions] = useState(null);
	const [step, setStep] = useState(null);
	const [cost, setCost] = useState(null);
	const [fee, setFee] = useState();
	const [booking, setBooking] = useState();
	const [proceedButtons, setProceedButtons] = useState({
		book: true,
		cancel: true,
		transfer: false,
	});
	const [balance, setBalance] = useState();
	const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(false);

	const [processing, setProcessing] = useState(true);

	const getCostUpdate = useCallback(
		async (values, svc) => {
			try {
				setProcessing(true);
				const token = await getTokenSilently();
				client.defaults.headers.Authorization = `Bearer ${token}`;
				const response = await client.post(`${config.api.HOST}/bookings/cost`, {
					service: svc,
					options: Object.keys(values).map((item) => values[item]),
				});
				if (response.status === 200 && response.data) {
					setCost(response.data.cost);
					setBalance(response.data.balance);
					setProceedButtons(response.data.actions);
					setFee(response.data.fee);
				}
				setProcessing(false);
			} catch (err) {
				console.error(err.message);
			}
		},
		[getTokenSilently, userData.id]
	);

	const debouncedCostUpdate = useCallback(
		debounce((values, svc) => {
			getCostUpdate(values, svc);
		}, 500),
		[getCostUpdate]
	);

	// Callbacks
	const handleCostUpdate = useCallback(
		async (values, productType) => {
			try {
				if (productType || (service && !service.enquire && isAuthenticated)) {
					debouncedCostUpdate(
						values ||
							(options[productType ? productType.id : service.id]
								? options[productType ? productType.id : service.id].values
								: []),
						productType || service
					);
				} else {
					setCost(0);
					setBalance(null);
					setProceedButtons({
						book: true,
						cancel: true,
						transfer: false,
					});
					setFee(0);
				}
			} catch (err) {
				console.error(err.message);
			}
		},
		[debouncedCostUpdate, service, options]
	);

	const TypeToValue = (option) => {
		const addDays = (date, days) => {
			const result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		};
		switch (option.type) {
			case 'select':
				return typeof option.options === 'string'
					? option.options.slice(1, -1).split(',')[
							option.defaultValue ? option.defaultValue - 1 : 0
					  ]
					: option.options[option.defaultValue ? option.defaultValue - 1 : 0]
							.label;
			case 'date':
				return addDays(new Date(), 5);
			case 'input':
				return option.placeholder ? option.placeholder : '';
			default:
				return option.defaultValue ? option.defaultValue : '';
		}
	};

	// Change of product URL
	useLayoutEffect(() => {
		try {
			if (!loading && !step) {
				if (
					products &&
					products.length > 0 &&
					products.filter((item) => item.id === match.params.productId * 1)
						.length > 0
				) {
					// set product based on url
					const newProduct = products.filter(
						(item) => item.id === match.params.productId * 1
					)[0];
					setProduct(newProduct);
					if (newProduct.productTypes.length > 0) {
						setOptions((options) =>
							newProduct.productTypes.reduce((acc, cur) => {
								if (!options || !options[cur.id]) {
									const initialOptions = cur.productOptions
										.sort((a, b) => (a.order * 1 > b.order * 1 ? 1 : -1))
										.map((item) => ({
											...item,
											value: TypeToValue(item),
											saved: false,
										}));
									// Then, depending on some specific fields, add some options
									if (cur.emailNeeded) {
										initialOptions.push({
											name: 'email',
											type: 'email',
											title: 'Your email address',
											description:
												'The email address you want to use for this order',
											order: 100,
											compulsory: true,
											value: userData.email ? userData.email : '',
											saved: false,
										});
									}
									if (cur.addressNeeded) {
										initialOptions.push({
											name: 'address',
											type: 'address',
											title: 'Your postal address',
											description:
												'The postal address you want to use for this order',
											order: 101,
											compulsory: true,
											value: userData.address ? userData.address : '',
											saved: true,
										});
									}
									if (cur.phoneNeeded) {
										initialOptions.push({
											name: 'phone',
											type: 'phone',
											title: 'Your phone',
											description:
												'The phone number you want to use for this order',
											order: 102,
											compulsory: true,
											value: userData.phoneNumber ? userData.phoneNumber : '',
											saved: true,
										});
									}
									return {
										...acc,
										[cur.id]: {
											values: initialOptions,
										},
									};
								}
								return {
									...acc,
									[cur.id]: options[cur.id],
								};
							}, {})
						);
					}
				} else {
					history.push('/');
				}
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [
		loading,
		products,
		match.params.productId,
		// match.params.serviceId,
		history,
		userData.address,
		userData.email,
		userData.phoneNumber,
		step,
	]);

	// Change of service url
	useLayoutEffect(() => {
		if (match.params.serviceId) {
			if (product) {
				if (
					product.productTypes.filter(
						(item) => item.id === match.params.serviceId * 1
					).length > 0
				) {
					if (match.params.serviceId * 1 !== service?.id) {
						// if there is a params.serviceId in url, set service to that one
						setService(
							product.productTypes.filter(
								(item) => item.id === match.params.serviceId * 1
							)[0]
						);

						if (isAuthenticated) {
							handleCostUpdate(
								null,
								product.productTypes.filter(
									(item) => item.id === match.params.serviceId * 1
								)[0]
							);
						}
					}
				} else {
					setService(null);
					history.push(`/product/${product.id}`);
				}
			}
		} else {
			setService(null);
			setProcessing(true);
		}
	}, [match.params.serviceId, service, product, handleCostUpdate, history]);

	return (
		<>
			{product && !service && <ProductPage product={product} />}
			{product && service && !step && (
				<ServicePage
					product={product}
					service={service}
					options={options}
					cost={cost}
					fee={fee}
					processing={processing}
					handleCostUpdate={handleCostUpdate}
					proceedButtons={proceedButtons}
					handlePreviousStep={() => {
						setProcessing(true);
						handleBackNavigation(history);
					}}
					handleNextStep={() => {
						setStep('confirm');
					}}
					handleChange={(values) => setOptions(values)}
				/>
			)}
			{product && service && step === 'confirm' && (
				<ConfirmOrderPage
					product={product}
					service={service}
					cost={cost}
					fee={fee}
					options={options}
					balance={balance}
					processingCostUpdate={processing}
					proceedButtons={proceedButtons}
					showModal={showConfirmBookingModal}
					setShowModal={setShowConfirmBookingModal}
					handlePreviousStep={() => {
						setStep(null);
					}}
					handleNextStep={(booking) => {
						setBooking(booking);
						setStep('success');
					}}
				/>
			)}
			{product && service && step === 'success' && (
				<OrderResultPage
					product={product}
					service={service}
					cost={cost}
					fee={fee}
					options={options}
					booking={booking}
					balance={balance}
					processingCostUpdate={processing}
					proceedButtons={proceedButtons}
					showModal={showConfirmBookingModal}
					setShowModal={setShowConfirmBookingModal}
					handleCancel={() => {
						setService(null);
						setStep(null);
						history.push(`/product/${product.id}`);
					}}
					match={match}
				/>
			)}
		</>
	);
};
export default Product;
