// Import modules
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { H1, H5, SmallTextSecondary } from '../../../../styles/StylesV3Main';
import { SelectStyled, StyledInput } from '../../../../styles/ProductStyles';
import { useClient, useCurrentTeam } from '../../../../hooks';
import ChipInputField from './ChipInputFeild';
import Button from '../../../../styles/shared/Button/Button';
import useCurrencyFormatter from '../../../../utils/useCurrencyFormatter';
import Modal from '../../../../styles/shared/Modal';

// eslint-disable-next-line no-shadow
enum PlanStatus {
	NotOnPlan = 'Not on plan',
	OnPlan = 'On plan',
	NotActiveTeamOnPlan = 'NOACTIVETEAMONPLAN',
}

type ActivePlanStatus = PlanStatus.NotOnPlan | PlanStatus.OnPlan;
type NonActivePlanStatus = ActivePlanStatus | PlanStatus.NotActiveTeamOnPlan;
type RoleStatus = 'member' | 'owner';

type SelectPlanAndRoleProps = {
	setFieldValue: any;
	hasExistingActivePlan: string | null;
};

const SelectPlanAndRole = ({
	setFieldValue,
	hasExistingActivePlan,
}: SelectPlanAndRoleProps) => {
	interface RoleOption {
		label: string;
		value: RoleStatus;
	}

	interface PlanOption {
		label: string;
		value: ActivePlanStatus | NonActivePlanStatus;
		disabled: true | false;
	}

	const planOptions: PlanOption[] = [
		{ label: 'On Plan', value: PlanStatus.OnPlan, disabled: false },
		{
			label: 'Not on Plan',
			value: PlanStatus.NotOnPlan,
			disabled: false,
		},
	];

	const nonActivePlanOptions: PlanOption[] = [
		{
			label: 'No active plan on this team',
			value: PlanStatus.NotActiveTeamOnPlan,
			disabled: true,
		},
		{
			label: 'Not on Plan',
			value: PlanStatus.NotOnPlan,
			disabled: false,
		},
	];

	const roleOptions: RoleOption[] = [
		{ label: 'Employee', value: 'member' },
		{ label: 'Admin', value: 'owner' },
	];

	return (
		<Row
			noGutters
			className="justify-content-between align-items-center pt-4 pb-4"
		>
			<Col className="">
				<H5 className="mb-2">Plan</H5>
				<SelectStyled
					name="plan"
					onChange={(e) => {
						setFieldValue('plan', e.target.value);
					}}
				>
					{hasExistingActivePlan !== null
						? planOptions.map((plan) => (
								<option
									disabled={plan.disabled}
									key={plan.value}
									value={plan.value}
								>
									{plan.label}
								</option>
						  ))
						: nonActivePlanOptions.map((plan) => (
								<option
									disabled={plan.disabled}
									key={plan.value}
									value={plan.value}
								>
									{plan.label}
								</option>
						  ))}
				</SelectStyled>
			</Col>
			<Col className="pl-2">
				<H5 className="mb-2">Role</H5>
				<SelectStyled
					name="role"
					onChange={(e) => {
						setFieldValue('role', e.target.value);
					}}
				>
					{roleOptions.map((role) => (
						<option key={role.value} value={role.value}>
							{role.label}
						</option>
					))}
				</SelectStyled>
			</Col>
		</Row>
	);
};

const validationSchema = Yup.object().shape({
	email: Yup.array().of(Yup.string().email().required()).required(),
	amount: Yup.number().when('welcomePoints', {
		is: true,
		then: Yup.number()
			.integer('Welcome points must be a round number')
			.min(0, 'Welcome points must be greater than 0')
			.required('Please enter the welcome points')
			.typeError('Welcome points must be a round number'),
	}),
});

interface AddMemberToTeamProps {
	show: boolean;
	setShow: (show: boolean) => void;
	team: any;
	handleUpdate: any;
}

const AddMemberToTeam = ({
	show,
	setShow,
	team,
	handleUpdate,
}: AddMemberToTeamProps) => {
	const client = useClient();
	const currencyFormatter = useCurrencyFormatter();
	const currentTeam = useCurrentTeam();

	const inviteValues = useRef<{
		email: any;
		plan?: string;
		role?: string;
		welcomePoints: boolean;
		amount: number;
		budget: number;
	} | null>(null);

	const hasExistingActivePlan: any = team?.stripeSubscriptionId;

	const defaultPlanValue =
		hasExistingActivePlan !== null ? 'On plan' : 'Not on plan';

	const handleSubmit = async () => {
		const {
			email,
			role,
			plan: addToPlan,
			welcomePoints,
			amount,
			budget,
		} = inviteValues.current!;

		const inviteBudget =
			budget === team.budget && hasExistingActivePlan ? null : budget;

		const invites = email.map((mail: string) => ({
			email: mail,
			role,
			addToPlan,
			welcomePoints: welcomePoints ? amount : 0,
			budget: inviteBudget,
		}));

		await createInvites(invites);
		setShow(false);
	};

	const createInvites = async (invites: any) => {
		try {
			await client.post(`/invites/${team.id}`, {
				invites: invites.filter((item: any) => item.email !== ''),
			});
			handleUpdate();
		} catch (err: any) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const prevButtonState = useRef<'default' | 'disabled' | 'loading'>('default');

	const handleState = (
		isSubmitting: boolean,
		isValidating: boolean,
		isValid: boolean,
		dirty: boolean
	) => {
		if (!show) return prevButtonState.current;

		if (isSubmitting) prevButtonState.current = 'loading';
		else if (isValidating || !(isValid && dirty))
			prevButtonState.current = 'disabled';
		else prevButtonState.current = 'default';

		return prevButtonState.current;
	};

	return (
		<Modal open={show} onClose={() => setShow(false)}>
			<div
				style={{
					position: 'relative',
					padding: '40px 45px',
					maxWidth: '500px',
				}}
			>
				<Formik
					validationSchema={validationSchema}
					validateOnChange
					initialValues={{
						email: [],
						plan: defaultPlanValue,
						role: 'member',
						welcomePoints: false,
						amount: team.budget,
						budget: team.budget,
					}}
					onSubmit={async (values, { setSubmitting }) => {
						inviteValues.current = values;
						await handleSubmit();
						setSubmitting(false);
					}}
				>
					{({
						values,
						isSubmitting,
						isValidating,
						isValid,
						dirty,
						errors,
						touched,
						setFieldValue,
					}) => (
						<Form>
							<Row noGutters>
								<Col className="px-0 py-0">
									<Row noGutters className="justify-content-center mb-4">
										<H1
											style={{
												color: 'black',
												fontSize: 32,
											}}
										>
											Add a member to the team
										</H1>
									</Row>
									<ChipInputField
										vals={values}
										errs={errors}
										touched={touched}
										setFieldValues={setFieldValue}
									/>
									<SelectPlanAndRole
										setFieldValue={setFieldValue}
										hasExistingActivePlan={hasExistingActivePlan}
									/>

									{values.plan === PlanStatus.OnPlan ? (
										<PointsAmount>
											<H5 htmlFor="amount" as="label">
												Monthly points
											</H5>
											<Field
												as={StyledInput}
												name="budget"
												id="budget"
												type="budget"
												min="0"
												step="1"
												autoFocus
											/>
											<SmallText>
												Equals{' '}
												{currencyFormatter(
													+values.budget,
													currentTeam.currency || 'gbp'
												)}{' '}
												per member per month
											</SmallText>
											<ErrorMessage
												name="budget"
												component={StyledErrorMessage}
											/>
										</PointsAmount>
									) : null}
									<WelcomePoints>
										<Field
											type="checkbox"
											name="welcomePoints"
											id="giveWelcomePoints"
										/>
										<label htmlFor="giveWelcomePoints">
											<H5 as="div">Give them welcome points (recommended)</H5>
											<SmallTextSecondary>
												This enables new team members to use the platform right
												away. The cost will be added to your next invoice.
											</SmallTextSecondary>
										</label>
									</WelcomePoints>
									{values.welcomePoints && (
										<PointsAmount>
											<H5 htmlFor="amount" as="label">
												Welcome points amount
											</H5>
											<Field
												as={StyledInput}
												name="amount"
												id="amount"
												type="number"
												min="0"
												step="1"
												autoFocus
											/>
											<ErrorMessage
												name="amount"
												component={StyledErrorMessage}
											/>
										</PointsAmount>
									)}
									<Row
										noGutters
										style={{ width: '100%' }}
										className="justify-content-between align-items-center pt-2"
									>
										<Col xs={6} className="px-0 py-0 pr-2">
											<Button
												buttonType="secondary"
												state="default"
												onClick={() => setShow(false)}
											>
												Cancel
											</Button>
										</Col>
										<Col> </Col>
										<Col xs={6} className="px-0 py-0 pl-2">
											<Button
												state={handleState(
													isSubmitting,
													isValidating,
													isValid,
													dirty
												)}
												onClick={() => {}}
											>
												Add
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

const WelcomePoints = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 24px;
	input {
		height: 16px;
		width: 16px;
		margin-right: 14px;
		accent-color: #191a1c;
	}
	label {
		flex: 1;
	}
`;

const PointsAmount = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	label {
		padding: 8px 0;
	}
`;

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	margin-top: 8px;
	font: normal normal 400 12px/14px 'Roobert Regular';
	color: #cc1440;
`;

const SmallText = styled(SmallTextSecondary)`
	margin-top: 8px;
`;

export default AddMemberToTeam;
