import styled from 'styled-components';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from 'react-icons/hi';

export const H1 = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 30px;
	line-height: 36px;
	color: ${(props) => props.theme.fonts.color.base};
	margin-bottom: 0px;
`;
export const H1Light = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 30px;
	line-height: 36px;
	color: ${(props) => props.theme.fonts.color.light};
	margin-bottom: 0px;
`;
export const H1LightGrey = styled.h1`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 30px;
	line-height: 36px;
	color: #8f8f8f;
	margin-bottom: 0px;
`;
export const H1Bold = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 32px;
	color: ${(props) => props.theme.fonts.color.light};
	margin-bottom: 0px;
`;
export const H1Title = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 32px;
	color: ${(props) => props.theme.fonts.color.dark};
	margin-bottom: 0px;
`;
export const H1Muted = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 30px;
	line-height: 36px;
	color: ${(props) => props.theme.fonts.color.muted};
	margin-bottom: 0px;
`;
export const H2 = styled.h2`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	color: ${(props) => props.theme.fonts.color.base};
	margin-bottom: 0px;
`;
export const H2Small = styled.h2`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 20px;
	line-height: 24px;
	color: ${(props) => props.theme.fonts.color.base};
	margin-bottom: 0px;
`;
export const H2Muted = styled.h2`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	margin-bottom: 0px;
	color: ${(props) => props.theme.fonts.color.muted};
`;
export const H2Light = styled.h2`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	margin-bottom: 0px;
	color: ${(props) => props.theme.fonts.color.light};
`;
export const H3 = styled.h3`
	font: normal normal 600 60px/72px 'Roobert Semi Bold';
	color: ${(props) => props.theme.fonts.color.base};
	margin-bottom: 0px;
`;
export const H3Upper = styled.h3`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H4 = styled.h4`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H4Muted = styled.h4`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.muted};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H4LightGrey = styled.h1`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 16px;
	color: #8f8f8f;
	text-transform: capitalize;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H4Light = styled.h4`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.light};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H4Bold = styled.h4`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const H3Bold = styled.h3`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;

export const H5 = styled.h5`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;
export const H5Muted = styled.h5`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
	color: ${(props) => props.theme.fonts.color.muted};
`;
export const Header = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 32px;
	text-transform: capitalize;
	margin-bottom: 0px;
`;

export const BoxInputLabel = styled.label`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	text-transform: capitalize;
	margin-bottom: 0px;
`;

export const P = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;
export const P1 = styled.p`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;
export const P2 = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 15px;
	letter-spacing: 0px;
	margin-bottom: 0px;
`;

export const PSansTextMuted = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.muted};
	line-height: 160%;
	color: rgba(0, 0, 0, 0.6);
	margin: 0px;
`;

export const PSansText = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 160%;
	margin: 0px;
`;

export const PSansLightText = styled.p`
	font-family: 'Roobert Light';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 160%;
	margin: 0px;
`;

export const PSansMediumText = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 160%;
	margin: 0px;
`;

export const SemiBoldText = styled.p`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 16px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 160%;
	margin: 0px;
`;

export const PSansBoldText = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 160%;
	margin: 0px;
`;

export const TextGrey = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.5);
	line-height: 160%;
	margin: 0px;
`;

export const P3 = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	color: ${(props) => props.theme.fonts.color.dark};
	line-height: 22px;
	margin-bottom: 0px;
`;
export const PUnderlined = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	text-decoration: underline;
	cursor: pointer;
`;
export const PMuted = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0px;
	color: ${(props) => props.theme.fonts.color.muted};
	margin-bottom: 0px;
`;
export const PLightGrey = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0px;
	color: #8f8f8f;
	margin-bottom: 0px;
`;
export const PLight = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0px;
	color: ${(props) => props.theme.fonts.color.light};
	margin-bottom: 0px;
`;
export const PHighlighted = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0px;
	color: ${(props) => props.theme.fonts.color.muted};
	margin-bottom: 0px;
`;
export const PScroll = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 18px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const U = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	text-decoration: underline;
	cursor: pointer;
`;
export const A = styled.a`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const AMuted = styled.a`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	text-decoration: underline;
	color: ${(props) => props.theme.fonts.color.muted};
`;
export const AUnderlined = styled.span`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	text-decoration: underline;
	cursor: pointer;
`;
export const ASpecial = styled.a`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	margin-bottom: 0px;
	color: ${(props) => props.theme.fonts.color.light};
`;
export const Input = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	border: none;
	border-bottom: ${(props) => `1px solid  ${props.theme.input.borderBottom}`};
`;
export const DataInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	border: none;
	border-bottom: ${(props) => `1px solid  ${props.theme.input.borderBottom}`};
	&:focus {
		outline: 0;
	}
`;
export const BoxInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	text-transform: capitalize;
	line-height: 24px;
	margin-bottom: 0px;
	border: none;
	height: 50px;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 3px;
`;
export const Label = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	border: none;
`;
export const InputV2 = styled.input`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	color: ${(props) => props.theme.fonts.color.base};
	margin-bottom: 0px;
`;
export const InputV3 = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;
export const Select = styled.select`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	cursor: pointer;
`;
export const Option = styled.option`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 10px;
	line-height: 16px;
	color: ${(props) => props.theme.fonts.color.base};
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
`;
export const TextArea = styled.textarea`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	color: ${(props) => props.theme.fonts.color.base};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;
export const Button = styled.button`
	font: ${(props) =>
		props.size
			? props.theme.button[props.size].font
			: props.theme.button.normal.font};
	color: ${(props) =>
		props.version
			? props.theme.button[props.version].active.color
			: props.theme.button.normal.active.color};
	background-color: ${(props) =>
		props.version
			? props.theme.button[props.version].active.background
			: props.theme.button.normal.active.background};
	border: 1px solid;
	border-color: ${(props) =>
		props.version
			? props.theme.button[props.version].active.border
			: props.theme.button.normal.active.border};
	border-radius: 30px;
	min-width: ${(props) =>
		props.size
			? props.theme.button[props.size].minWidth
			: props.theme.button.normal.minWidth};
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	padding: ${(props) =>
		props.size
			? props.theme.button[props.size].padding
			: props.theme.button.normal.padding};
	line-height: 1.42857143;
	text-transform: uppercase;
	letter-spacing: 1.4px;
	margin-bottom: 0px;
	&:disabled {
		color: ${(props) =>
			props.version
				? props.theme.button[props.version].disabled.color
				: props.theme.button.normal.active.color};
		background-color: ${(props) =>
			props.version
				? props.theme.button[props.version].disabled.background
				: props.theme.button.normal.active.background};
		border-color: ${(props) =>
			props.version
				? props.theme.button[props.version].disabled.border
				: props.theme.button.normal.active.border};
	}
	&:hover {
		color: ${(props) =>
			props.version
				? props.theme.button[props.version].hover.color
				: props.theme.button.normal.hover.color};
		background-color: ${(props) =>
			props.version
				? props.theme.button[props.version].hover.background
				: props.theme.button.normal.hover.background};
		border-color: ${(props) =>
			props.version
				? props.theme.button[props.version].hover.border
				: props.theme.button.normal.hover.border};
	}
`;
export const CardDescriptionMarkdown = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-bottom: 0px !important;
	color: ${(props) => (props.active === false ? 'lightgrey' : 'black')};
`;
export const CardDescription = styled.p`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-bottom: 0px !important;
	color: ${(props) => (props.active === 'false' ? 'lightgrey' : 'black')};
`;

export const TH = styled.th`
	width: ${(props) => (props.width ? `${props.width}` : 'fit-content')};
	text-align: ${(props) => (props.align ? `${props.align}` : 'left')};
	margin-bottom: 0px;
`;

export const TD = styled.td`
  flexGrow: 1,
  width: ${(props) => (props.width ? `${props.width}` : 'fit-content')};
  text-align: ${(props) => (props.align ? `${props.align}` : 'left')};
`;

export const TR = styled.tr`
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	height: 50px;
`;

export const MinusSign = styled(HiOutlineMinusCircle)`
	color: ${(props) => (props.disabled ? 'rgba(0,0,0,0.3)' : 'black')};
	cursor: pointer;
`;

export const PlusSign = styled(HiOutlinePlusCircle)`
	color: ${(props) => (props.disabled ? 'rgba(0,0,0,0.3)' : 'black')};
	cursor: pointer;
`;

export const PreviewTitle = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 20px;
	color: ${(props) => props.theme.fonts.color.light};
	color: black;
	line-height: 120%;
	margin-bottom: 0px;
`;

export const PreviewTitleMuted = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 20px;
	color: ${(props) => props.theme.fonts.color.light};
	color: rgba(0, 0, 0, 0.5);
	line-height: 120%;
	margin-bottom: 0px;
`;

export const PreviewTextMuted = styled.p`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 16px;
	color: ${(props) => props.theme.fonts.color.light};
	color: rgba(0, 0, 0, 0.5);
	line-height: 150%;
	margin-bottom: 0px;
`;

export const PreviewText = styled.p`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 16px;
	color: ${(props) => props.theme.fonts.color.light};
	color: black;
	line-height: 150%;
	margin-bottom: 0px;
`;

export const ModalButton = styled.button`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	border: none;
	border-radius: 3px;
	width: ${({ width }) => (width ? width : '188px')};
	height: 50px;
	box-sizing: border-box;
	${({ version, disabled }) => {
		switch (version) {
			case 'danger':
				return `   background: rgba(255, 0, 0, 0.05);
        border: 1.5px solid ${disabled ? 'rgba(255, 0, 0, 0.3)' : '#FF0000'};
        color: ${disabled ? 'rgba(255, 0, 0, 0.3)' : '#FF0000'};`;
			case 'light':
				return `   background: white;
        border: 1px solid ${disabled ? 'rgba(0,0,0,0.3)' : '#000'};
        color: ${disabled ? 'rgba(0,0,0,0.3)' : '#000'};`;
			default:
				return `   background: ${disabled ? 'rgba(0,0,0,0.05)' : '#000'};
        border: 0px solid ${disabled ? 'rgba(0,0,0,0.3)' : '#000'};
        color: ${disabled ? 'rgba(0,0,0,0.3)' : '#FFF'};`;
		}
	}}
	&:focus {
		outline: 1;
	}
	@media (max-width: 500px) {
		width: 150px;
	}
`;

export const SearchInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	width: 100%;
	height: 56px;
	padding-left: 16px;
	border: none;
	&:focus {
		outline: 0;
	}
	opacity: ${(props) => (props.disabled ? '50%' : '100%')};
`;

export const SelectStyled = styled.select`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.03);
	margin-top: ${(props) => props.marginT};
	border: none;
	border-radius: 3px;
	width: 100%;
	height: 56px;
	padding: 0px 16px;
	padding-right: 30px;
	display: inline-block;
	box-sizing: border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url('../images/products/ArrowDown.svg');
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.7em auto, 100%;
	&::-ms-expand {
		display: none;
	}
	&:focus {
		outline: 0;
	}
`;

export const GreenDisplay = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 2px;
	padding: 3px 6px;
	color: #33c345;
	line-height: 13px;
	letter-spacing: 1px;
	text-transform: uppercase;
	width: fit-content;
	height: 19px;
	background: rgba(51, 195, 69, 0.14);
	border-radius: 63px;
`;

export const GreyCircle = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 50%;
`;

export const Divider = styled.div`
	max-height: 1px;
	border-bottom: 1px solid #c4c4c4;
	margin: 0px 0px 0px 0px;
	width: 100%;
	opacity: 0.3;
`;

export const StyledSelect = styled.select`
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  outline: 0;
  border: none;
  width: 100%;
  height: 50px;
  cursor: pointer;
  padding: 0px 10px 0px 10px;

  &:focus {
    outline: 0;`;

export const InfoContainer = styled.div`
	width: 100%;
	height: ${(props) => (props.height ? `${props.height}` : 'fit-content')};
	background: #ffffff;
	display: flex;
	justify-content: start;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	box-shadow: 0px 5px 20px rgba(10, 16, 48, 0.05);
	border-radius: 3px;
	@media (max-width: 992px) {
		height: ${(props) =>
			props.mheight ? `${props.mheight}` : `${props.height}`};
	}
`;
