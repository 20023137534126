import React, { Suspense, useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import { MdMenu, MdOutlineClose } from 'react-icons/md';

import { TrackJS } from 'trackjs';
import './App.css';

// Import modules
import {
	Router,
	Route,
	Switch,
	Redirect,
	useRouteMatch,
	Link,
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BaseCSS } from 'styled-bootstrap-grid';
import CookieConsent from 'react-cookie-consent';
import config from './config';

// Import utilities
import authConfig from './utils/auth_config.json';
import history from './utils/history';
import {
	useAuth0,
	Auth0Provider,
	Auth0Context,
} from './contexts/react-auth0-spa';
import { useUserContext, UserProvider } from './contexts/userContext';
import { ProductProvider } from './contexts/productContext';
import { useCurrentTeam } from './hooks';
import { getUsernameEmail } from './components/utilities/Functions';

// Import components
import NavBarContainer from './components/navbar/NavBarContainer';
import Location from './components/navbar/Location';
import NavBarPoints from './components/navbar/NavBarPoints';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/utilities/ScrollToTop';

// Import css
import Theme from './styles/theme';

// Import views
import AccountSettings from './views/AccountSettings';
import Team from './views/Team';
import Invite from './views/Invite';
import Product from './views/Product';
import Wallet from './views/Wallet';
import { JunoCardRouter } from './components/junoCard';
import Budget from './views/Budget';
import { CreateReimbursement } from './components/reimbursements';
import PrivacyPolicy from './views/PrivacyPolicy';
import NotFound from './views/NotFound';
import SplashScreen from './views/SplashScreen';
import Category from './views/Category';
import LoginForm from './views/LoginForm';
import SignUpForm from './views/SignUpForm';
import HomepageWrapper from './views/HomePageWrapper';
import Events from './views/Events';
import NoFlex from './views/NoFlex';
import { ReactComponent as Logo } from './logo.svg';

const LocalhostAuth0Provider = React.lazy(() =>
	import('./contexts/localhost-auth0-spa')
);

const marketingSite =
	document.location.host === 'try.withjuno.com' ||
	document.location.host === 'try-staging.withjuno.com' ||
	document.location.host === 'localhost:3030';

// Initialise the Stripe object
const promise = loadStripe(config.stripe.PUBLISHABLE_API_KEY);

function useIdentifyUser() {
	const { userData, walletPoints } = useUserContext();
	const currentTeam = useCurrentTeam();

	const { isAuthenticated } = useAuth0();

	React.useEffect(() => {
		if (isAuthenticated) {
			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id:
					window.location.host === 'app.withjuno.com' ? 't7eby1t9' : 'xepfccbv',
				user_id: userData.id,
				user_hash: userData.userHash,
				phone: +userData.phone,
				points: walletPoints,
				team: currentTeam?.name,
				user_role: currentTeam?.teamMember?.currentTeamRole,
				name: getUsernameEmail(userData),
				email: userData.email,
				role: currentTeam?.teamMember?.currentTeamRole,
				company: {
					id: currentTeam?.id,
					name: currentTeam?.name,
					active_subscription: !!currentTeam.stripeSubscriptionData,
				},
			});
		}
	}, [userData, walletPoints]);
}

const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};

const unauth0Context = {
	isAuthenticated: false,
};

const AuthProvider =
	config.env !== 'dev' ? Auth0Provider : LocalhostAuth0Provider;

const AppAuthWrapper = () =>
	!marketingSite ? (
		<Suspense fallback={null}>
			<AuthProvider
				domain={authConfig.domain}
				client_id={authConfig.clientId}
				redirect_uri={window.location.origin}
				onRedirectCallback={onRedirectCallback}
				audience={authConfig.audience}
				cacheLocation="localstorage"
				useRefreshTokens
				scope={authConfig.scope}
			>
				<AppUserWrapper />
			</AuthProvider>
		</Suspense>
	) : (
		<Auth0Context.Provider value={unauth0Context}>
			<AppUserWrapper />
		</Auth0Context.Provider>
	);

const AppUserWrapper = () => {
	const { loading } = useAuth0();

	if (loading) {
		return <SplashScreen />;
	}

	return (
		<Router history={history}>
			<UserProvider>
				<Theme>
					<AppRouterWrapper />
					<BaseCSS />
				</Theme>
			</UserProvider>
		</Router>
	);
};

const AppRouterWrapper = () => {
	const { loading } = useUserContext();

	return (
		<ProductProvider>{loading ? <SplashScreen /> : <App />}</ProductProvider>
	);
};

const ProductRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${path}/`} component={Product} />
			<Route path={`${path}/service/:serviceId?/:step?`} component={Product} />
		</Switch>
	);
};

const Public = () => (
	<>
		<NavBarContainer />
		<Elements stripe={promise}>
			<Switch>
				<Route path="/" exact component={HomepageWrapper} />
				<Route path="/product/:productId" component={ProductRoutes} />
				<Route path="/categories/all" exact component={Category} />
				<Route path="/category/:categoryId" component={Category} />
				<Route exact path="/events" component={Events} />
				<Route path="/events/:eventId" component={Events} />
				<Route path="/" component={NotFound} />
			</Switch>
		</Elements>
		<Footer />
	</>
);

const View = memo(() => (
	<Elements stripe={promise}>
		<Switch>
			<Route path="/" exact component={HomepageWrapper} />
			<Route path="/login" render={() => <Redirect to="/" />} />
			<Route path="/signup" render={() => <Redirect to="/" />} />
			<Route path="/team/:teamId/:view?" component={Team} />
			<Route path="/wallet" component={Wallet} />
			<Route
				path="/account-settings/:view/:benefitId?"
				component={AccountSettings}
			/>
			<Route path="/account-settings/:view?" component={AccountSettings} />
			<Route path="/card" exact component={JunoCardRouter} />
			<Route path="/reimbursement" exact component={CreateReimbursement} />
			<Route path="/budget/:budgetId" component={Budget} />
			<Route path="/no-flex" component={NoFlex} />
			<Route path="/product/:productId" component={ProductRoutes} />
			<Route path="/categories/all" exact component={Category} />
			<Route path="/category/:categoryId" component={Category} />
			<Route exact path="/events" component={Events} />
			<Route path="/events/:eventId" component={Events} />
			<Route path="/" component={NotFound} />
		</Switch>
	</Elements>
));

const Secure = () => {
	const { loading, isAuthenticated } = useAuth0();
	const [active, setActive] = useState(false);

	if (loading) {
		return <SplashScreen />;
	}

	if (!isAuthenticated) {
		return (
			<Switch>
				<Route path="/signup" component={SignUpForm} />
				<Route path="/" component={LoginForm} />
			</Switch>
		);
	}

	return (
		<Container>
			<NavBarContainer active={active} setActive={setActive} />
			<Content>
				<TopBar active={active}>
					<div style={{ width: '35px' }}>
						<div>
							<button
								type="button"
								style={{
									border: '0',
									backgroundColor: 'transparent',
									display: !active ? 'block' : 'none',
								}}
								onClick={() => setActive(true)}
							>
								<MdMenu size={30} color="#000" />
							</button>
							<button
								type="button"
								style={{
									border: '0',
									backgroundColor: 'transparent',
									display: active ? 'block' : 'none',
								}}
								onClick={() => setActive(false)}
							>
								<MdOutlineClose size={30} color="#000" />
							</button>
						</div>
					</div>
					<div style={{ margin: 'auto' }}>
						<Location />
						<Link to="/">
							<Logo />
						</Link>
					</div>
					<NavBarPoints />
				</TopBar>
				<View />
				<Footer />
				<CookieConsent
					acceptOnScroll
					debug={false}
					style={{ paddingLeft: '10vw', paddingRight: '10vw' }}
				>
					This website uses cookies to enhance the user experience. See our{' '}
					<span>
						<a
							style={{ color: 'white' }}
							target="_blank"
							rel="noopener noreferrer"
							href={`${config.web.HOST}/privacy-policy`}
						>
							Privacy Policy
						</a>
					</span>{' '}
					for more details.
				</CookieConsent>
			</Content>
		</Container>
	);
};

const App = () => {
	useIdentifyUser();

	const { userData } = useUserContext();

	useEffect(() => {
		if (config.env !== 'dev') {
			TrackJS.install({
				token: '0ccc6313ced7443984edbf78e3374867',
				console: { watch: ['error'] },
				version: process.env.REACT_APP_VERSION,
				userId: userData.id ? userData.id.toString() : 'public',
			});
		}
	}, [userData]);

	return (
		<ScrollToTop>
			<Switch>
				{/* Public routes outside of app */}
				<Route path="/invite/:hash" component={Invite} />
				<Route path="/privacy-policy" component={PrivacyPolicy} />

				{/* app */}
				<Route path="/" component={!marketingSite ? Secure : Public} />
			</Switch>
		</ScrollToTop>
	);
};

const Container = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	background-color: #f0f0f0;
	padding-top: 25px;
	padding-right: 30px;
	@media (max-width: 500px) {
		padding: 0;
	}
`;

const Content = styled.div`
	flex: 1;
	overflow: hidden;
	padding: 0 34px;
	background-color: #f8f8f8;
	border-radius: 20px 20px 0 0;
	@media (max-width: 500px) {
		padding: 90px 0 0 0;
		border-radius: 0;
	}
`;

const TopBar = styled.div`
	height: 65px;
	background-color: #f8f8f8;
	padding-top: 30px;
	display: flex;
	justify-content: center;
	> div:first-child > div {
		display: none;
	}
	> div:nth-child(2) form {
		display: block;
	}
	> div:nth-child(2) a {
		display: none;
	}
	@media (max-width: 500px) {
		background-color: ${({ active }) => (!active ? '#f8f8f8f' : '#f0f0f0')};
		transition-property: background-color;
		transition-duration: 0.5s;
		position: fixed;
		height: 95px;
		top: 0;
		left: 0;
		right: 0;
		z-index: 21;
		padding: 30px 24px;
		> div:first-child > div {
			display: block;
		}
		> div:first-child button {
			padding: 0;
		}
		> div:nth-child(2) form {
			display: none;
		}
		> div:nth-child(2) a {
			display: block;
		}
	}
`;

export default AppAuthWrapper;
