/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as Add } from '../images/add.svg';
import { ReactComponent as Alarm } from '../images/alarm.svg';
import { ReactComponent as Trash } from '../images/trash.svg';
import Search from '../images/Search';
import NoTeamMembers from './NoTeamMembers';
import Pager from '../../../styles/shared/Pager';
import Menu from '../../../styles/shared/Menu';

export type Invite = {
	inviteId: number;
	email: string;
	onPlan: boolean;
	createdAt: Date;
	admin: boolean;
	welcomePoints: number;
	budget: number;
	reminder: Date | null;
};

const InvitesTable = ({
	team,
	invites,
	sendReminder,
}: {
	team: {
		id: number;
		budget: number;
	};
	invites: Invite[] | null;
	sendReminder: (inviteId: number) => Promise<void>;
}) => {
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	let page = +(params.get('page') || 1);
	const nameSearch = params.get('search') || '';
	const history = useHistory();
	const query = (setParams: (p: URLSearchParams) => void) => {
		const newParams = new URLSearchParams(search);
		setParams(newParams);
		return newParams.toString();
	};
	if (!invites) return null;
	const filteredInvites = invites.filter(
		({ email }) =>
			!nameSearch ||
			(email && email.toLowerCase().indexOf(nameSearch.toLowerCase()) !== -1)
	);
	const numberOfPages = Math.ceil(filteredInvites.length / 10);
	page = Math.min(page, numberOfPages);
	const start = (page - 1) * 10;
	const invitesPage = filteredInvites.slice(start, start + 10);
	return (
		<Invites>
			<div className="header">
				<h3>Pending invites</h3>
				<div style={{ flex: 1 }} />
				<button
					type="button"
					disabled={!invites.length}
					onClick={() => {
						history.push(
							`${pathname}?${query((p) => {
								p.set('remind-all', 'true');
							})}`,
							{
								scroll: false,
							}
						);
					}}
				>
					<Alarm />
					Remind all
				</button>
				<Menu
					text="Invite team member"
					selected="email"
					setSelected={(option) => {
						history.push(
							`${pathname}?${query((p) => {
								p.set('invite-member', option);
							})}`,
							{
								scroll: false,
							}
						);
					}}
					options={[
						{
							key: 'email',
							value: 'Add by email address',
						},
						{
							key: 'csv',
							value: 'Upload a csv',
						},
					]}
				>
					<Add />
					Invite team member
				</Menu>
			</div>
			<div className="filter">
				<div className="search">
					<Search stroke="rgb(102, 112, 133)" />
					<input
						placeholder="Search for people..."
						aria-label="Search for people"
						value={nameSearch}
						onChange={(e) => {
							history.replace(
								`${pathname}?${query((p) => {
									if (e.target.value) p.set('search', e.target.value);
									else p.delete('search');
									p.set('page', '1');
								})}`,
								{
									scroll: false,
								}
							);
							return false;
						}}
					/>
				</div>
			</div>
			{!invitesPage.length && <NoTeamMembers nameSearch={nameSearch} />}
			{!!invitesPage.length && (
				<>
					<table>
						<thead>
							<tr>
								<th>Email</th>
								<th>Plan</th>
								<th>Invited</th>
								<th>Role</th>
								<th>Welcome Points</th>
								<th>Monthly Budget</th>
								<th>Reminder</th>
								<th>Reminder Sent</th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							{invitesPage.map(
								({
									inviteId,
									email,
									onPlan,
									createdAt,
									admin,
									welcomePoints,
									budget,
									reminder,
								}) => (
									<tr key={inviteId}>
										<td>
											<h4>Email</h4>
											{email}
										</td>
										<td>
											<h4>Plan</h4>
											{onPlan ? 'On plan' : 'Not on plan'}
										</td>
										<td>
											<h4>Invited</h4>
											{moment(createdAt).isSame(moment(), 'days')
												? 'Today'
												: moment(createdAt).format('DD MMM YYYY')}
										</td>
										<td>
											<h4>Role</h4>
											{admin ? 'Admin' : 'Employee'}
										</td>
										<td>
											<h4>Welcome points</h4>
											{welcomePoints || '-'}
										</td>
										<td>
											<h4>Monthly Budget</h4>
											{budget ?? team.budget}
										</td>
										<td>
											<button
												type="button"
												onClick={() => {
													sendReminder(inviteId);
												}}
											>
												Send Reminder
											</button>
										</td>
										<td>
											{reminder ? moment(reminder).format('YYYY-MM-DD') : '-'}
										</td>
										<td>
											<button
												type="button"
												aria-label="Remove invite"
												onClick={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('cancel-invite', inviteId.toString());
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												<Trash />
											</button>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<Pager total={filteredInvites.length} />
				</>
			)}
		</Invites>
	);
};

const Invites = styled.div`
	background: #fff;
	border-radius: 18px;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	h3 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		line-height: 28px;
		margin: 0;
		margin-right: 8px;
	}
	.header,
	.filter {
		display: flex;
		padding: 20px 24px;
	}
	.header {
		border-bottom: 1px solid rgb(234, 236, 240);
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.header button {
		display: flex;
		font-family: 'TTInterfaces DemiBold';
		font-size: 14.5px;
		line-height: 20px;
		color: rgb(52, 64, 84);
		padding: 10px 16px;
		border-radius: 8px;
		border: 1px solid rgb(208, 213, 221);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	.header button[disabled] {
		opacity: 40%;
	}
	.header button svg {
		margin-right: 8px;
	}
	.header button:nth-child(1) {
		background: rgb(25, 26, 28);
		color: #fff;
	}
	.filter {
		border-bottom: 1px solid rgb(234, 236, 240);
	}
	.filter > div.search {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		box-shadow: none;
		height: unset;
		svg {
			position: relative;
			left: 36px;
		}
		input {
			max-width: 400px;
			display: flex;
			flex: 1;
			border: 1px solid rgb(208, 213, 221);
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			padding: 10px 14px;
			border-radius: 8px;
			padding-left: 48px;
		}
	}
	table {
		width: 100%;
	}
	button {
		border: 0;
		background: transparent;
	}
	tr {
		background: #fff;
		border-bottom: 1px solid rgb(234, 236, 240);
	}
	thead tr {
		background: rgb(252, 252, 253);
	}
	th {
		font-family: 'TTInterfaces Medium';
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		height: 44px;
		padding: 12px 24px;
	}
	td {
		font-family: 'TTInterfaces Regular';
		font-size: 14px;
		line-height: 20px;
		padding: 16px 24px;
		color: rgb(71, 84, 103);
		h4 {
			display: none;
		}
	}
	td:nth-child(7) button {
		font-family: 'TTInterfaces DemiBold';
		font-size: 14px;
		line-height: 20px;
		color: rgb(52, 64, 84);
		padding: 10px 16px;
		border: 1px solid rgb(208, 213, 221);
		border-radius: 8px;
		white-space: nowrap;
	}
	@media (max-width: 1200px) {
		th,
		td {
			padding: 16px 24px;
		}
		.header {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			button {
				flex: 1;
				width: 100%;
			}
		}
		.filter {
			display: flex;
			flex-direction: column;
			a {
				width: 50%;
				display: flex;
				justify-content: center;
			}
			div.search {
				padding-top: 20px;
				position: relative;
				svg {
					position: absolute;
					left: 16px;
				}
				input {
					width: 100%;
					max-width: unset;
				}
			}
		}
		thead {
			display: none;
		}
		tr {
			display: flex;
			flex-wrap: wrap;
			position: relative;
		}
		td h4 {
			display: block;
			margin: 0;
			padding: 0;
			font-family: 'TTInterfaces Medium';
			font-size: 12px;
			line-height: 18px;
			color: rgba(71, 84, 103, 0.6);
			padding-bottom: 6px;
		}
		td:nth-child(1),
		td:nth-child(6) {
			flex: 100%;
		}
		td:nth-child(2),
		td:nth-child(3),
		td:nth-child(4),
		td:nth-child(5),
		td:nth-child(7),
		td:nth-child(8) {
			flex: 50%;
			label {
				display: block;
				font-family: 'TTInterfaces Medium';
				font-size: 12px;
				line-height: 18px;
				color: rgba(71, 84, 103, 0.6);
				padding-bottom: 6px;
			}
		}
		td:nth-child(9) {
			position: absolute;
			right: 0;
		}
	}
`;

export default InvitesTable;
