import React, { useState } from 'react';
import styled from 'styled-components';
import { IoImagesOutline } from 'react-icons/io5';
import { P2 } from '../../styles/StylesV2';
import S3FilesUploader from './S3FilesUploader';
import { useUserContext } from '../../contexts/userContext';
import { Spinner } from 'react-bootstrap';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// padding-top: 40px;
	// padding-bottom: 36px;
	@media (max-width: 991.98px) {
		// padding-top: 30px;
		// padding-bottom: 26px;
	}
`;
const StyledImage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 166px;
	height: 166px;
	border-radius: 50%;
`;

const StyledPreview = styled.img`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 166px;
	height: 166px;
	border-radius: 50%;
	object-fit: cover;
	object-position: 50% 50%;
	border: 1px solid black;
`;

const Input = styled.input`
	display: none;
	border: 1px solid black;
	border-radius: 50%;
	background-color: lightblue;
`;

const Label = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: black;
	margin-bottom: 0px;
	background: tranparent;
`;

const StyledLabelDefault = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f7f7f7;
	border: 1px dashed #000000;
	width: 166px;
	height: 166px;
	border-radius: 50%;
	cursor: pointer;
`;

const ProfilePicInput = ({
	text,
	accept,
	onFiles,
	files,
	getFilesFromEvent,
	src,
	loading,
}) => {
	return loading ? (
		<Label>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '150px',
					width: '150px',
					textAlign: 'center',
					border: '1px solid black',
					borderRadius: '75px',
				}}
			>
				<Spinner animation="grow" />
			</div>
		</Label>
	) : (
		<>
			<Label htmlFor={'file-input'}>
				{!src && (
					<StyledLabelDefault>
						<IoImagesOutline size={30} color={'black'} style={{ margin: 10 }} />
						<P2>Upload Photo</P2>
					</StyledLabelDefault>
				)}

				{src && <StyledPreview src={src} />}
			</Label>
			<Input
				id="file-input"
				name="file-input"
				type="file"
				style={{ display: 'none' }}
				accept={accept}
				onChange={async (e) => {
					const chosenFiles = await getFilesFromEvent(e);
					onFiles(chosenFiles);
				}}
			/>
		</>
	);
};

const ProfilePicLayout = ({
	input,
	previews,
	submitButton,
	dropzoneProps,
	files,
	extra: { maxFiles },
}) => {
	return (
		<StyledContainer {...dropzoneProps}>
			<StyledImage filesLength={files.length}>{input}</StyledImage>
		</StyledContainer>
	);
};

const ImageUpload = ({ id, ...props }) => {
	const { userData, updateProfilePic } = useUserContext();
	const [loading, setLoading] = useState(false);

	const handleImageUpload = async (directUrl) => {
		try {
			await updateProfilePic(directUrl);
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<S3FilesUploader
			styles={{
				dropzone: {
					background: 'transparent',
					border: 0,
					width: 170,
					//height: 170,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
				},
			}}
			{...props}
			type="user-profile"
			id={id}
			multiple={false}
			accept="image/*"
			LayoutComponent={ProfilePicLayout}
			InputComponent={(props) => (
				<ProfilePicInput
					{...props}
					loading={loading}
					src={userData.src}
					text={'Upload Photo'}
				/>
			)}
			inputContent=""
			onChangeStatus={({ meta }, status) => {
				status !== 'ready' && status !== 'done'
					? setLoading(true)
					: setLoading(false);
				status === 'done' && handleImageUpload(meta.fileUrl);
			}}
		/>
	);
};

export default ImageUpload;
