import { Row, Col } from 'react-bootstrap';
import { H3, Paragraph } from '../../styles/StylesV3Main';
import styled from 'styled-components';

const ContentContainer = styled.div`
	padding: 40px;
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	width: 100%;
`;

const StyledContainer = styled.div`
	width: 100%;
	margin-top: 24px;
`;

const StyledH3 = styled(H3)`
	padding-bottom: 16px;
`;

const Text = styled(Paragraph)`
	overflow: hidden;
	word-wrap: break-word;
	color: rgba(0, 0, 0, 0.5);
	padding-bottom: 16px;
`;

type BoxProps = {
	description: string;
	title: string;
	children: any;
};

const Box = ({ title, description, children }: BoxProps) => {
	return (
		<StyledContainer>
			<ContentContainer>
				<Row>
					<Col>
						<StyledH3>{title}</StyledH3>
						<Text>{description}</Text>
					</Col>
				</Row>
				{children}
			</ContentContainer>
		</StyledContainer>
	);
};

export { Box };
