import React, { Dispatch, SetStateAction, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Modal, ModalBody } from 'react-bootstrap';
import { BiLoaderAlt } from 'react-icons/bi';
import moment from 'moment';
import ModalButton from '../../../../styles/Modal/ModalButton';
import { H1, SmallTextSecondary } from '../../../../styles/StylesV3Main';
import BudgetSelector from '../../../subscription/BudgetSelector';
import JunoDaySelector from '../../../subscription/JunoDaySelector';
import useCurrencyFormatter from '../../../../utils/useCurrencyFormatter';

type EditSubscriptionProps = {
	showModalEditSubscription: boolean;
	setShowModalEditSubscription: Dispatch<SetStateAction<boolean>>;
	budget: string;
	currency: string;
	currentJunoDay: string;
	subscriptionUpdater: (budget: string, junoDay: number) => {};
	updating: boolean;
	setUpdating: Dispatch<SetStateAction<boolean>>;
};

const EditSubscription = ({
	showModalEditSubscription,
	setShowModalEditSubscription,
	budget,
	currency,
	currentJunoDay,
	subscriptionUpdater,
	updating,
	setUpdating,
}: EditSubscriptionProps) => {
	const [points, setPoints] = useState(budget);

	const [junoDay, setJunoDay] = useState(currentJunoDay);
	const currencyFormatter = useCurrencyFormatter();

	const currentDay = moment().date();

	const billingStartDate = moment().date(+junoDay);

	if (+junoDay < currentDay) billingStartDate.add(1, 'months');

	return (
		<StyledModal
			show={showModalEditSubscription}
			onHide={() => setShowModalEditSubscription(false)}
			centered
			animation={false}
		>
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					if (!updating) {
						subscriptionUpdater(points, +junoDay);
						setUpdating(true);
					}
				}}
			>
				<StyledModalBody>
					<Title>Edit budget</Title>
					<Row noGutters className="justify-content-start align-items-center">
						<VerticalDivider />
					</Row>
					<BudgetSelector
						points={points}
						setPoints={setPoints}
						teamBudget={budget}
					/>
					<CurrencyContainer>
						<SmallText as="label" htmlFor="currency">
							Equals {currencyFormatter(+points, currency || 'gbp')} per member
							per month
						</SmallText>
					</CurrencyContainer>
					<Row noGutters className="justify-content-start align-items-center">
						<VerticalDivider />
					</Row>
					<StyledRow
						noGutters
						className="justify-content-start align-items-center"
					>
						<JunoDayContainer>
							<JunoDaySelector
								junoDay={junoDay}
								setJunoDay={setJunoDay}
								disableCurrentDay
								autoFocus={false}
							/>
						</JunoDayContainer>
					</StyledRow>
					<StyledRow
						noGutters
						className="justify-content-start align-items-center mt-2"
					>
						<SmallTextSecondary>
							Juno Day is the day all the members in your team will receive
							their Juno Points.
						</SmallTextSecondary>
						<JunoDayText>
							The next Juno day will be on{' '}
							{billingStartDate.format('MMMM Do, YYYY')}.
						</JunoDayText>
					</StyledRow>

					<VerticalDivider />
					<StyledRow noGutters className="justify-content-end">
						<ModalButton
							type="button"
							version="grey"
							onClick={() => setShowModalEditSubscription(false)}
							width="75px"
							height="40px"
							className="mr-3"
						>
							Cancel
						</ModalButton>
						<ModalButton
							type="submit"
							version="black"
							width="58px"
							height="40px"
						>
							{updating ? <Spinner size={30} fill="#fff" /> : 'Save'}
						</ModalButton>
					</StyledRow>
				</StyledModalBody>
			</form>
		</StyledModal>
	);
};

const spinnerFrames = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled(BiLoaderAlt)`
	animation: ${spinnerFrames} 1s linear infinite;
`;

const VerticalDivider = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	margin: 24px 0px;
`;

const StyledModal = styled(Modal)`
	> div {
		width: 560px;
		max-width: none !important;

		@media (max-width: 580px) {
			width: unset;
			max-width: unset;
		}
	}
`;

const StyledModalBody = styled(ModalBody)`
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
	padding: 24px !important;
`;

const Title = styled(H1)`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #191a1c;
`;

const CurrencyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
`;

const SmallText = styled.h5`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #5b6166;
`;

const StyledRow = styled(Row)`
	width: 100%;
`;

const JunoDayContainer = styled.div`
	flex: 1;
	label {
		margin-top: 0px;
	}
`;

const JunoDayText = styled(SmallTextSecondary)`
	font-family: 'Roobert Semi Bold';
`;

export default EditSubscription;
