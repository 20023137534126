import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { H4, SmallTextSecondary } from '../../../styles/StylesV3Main';
import config from '../../../config';

const StyledContainer = styled.div`
	width: 368px;
	height: 420px;
	border: 1px solid rgba(0, 0, 0, 0.05);

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		transition: 0.2s;
	}
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	cursor: pointer;

	@media (max-width: 1200px) {
		width: 320px;
		height: 430px;
	}

	@media (max-width: 1080px) {
		width: 450px;
	}

	@media (max-width: 992.8px) {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 16px;
	}
`;

const CardImage = styled.img<{ size?: string }>`
	width: 100%;
	min-height: 280px;
	object-fit: cover;
	object-position: center;
	max-height: ${(props) => (props.size === 'hero' ? 'auto' : '25vh')};
`;

const SRow = styled.div`
	width: 100%;
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #fff;
	align-items: start;
	padding: 24px;
`;

const TitleView = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
`;

const BrandedCard = ({ product }: any) => {
	return (
		<Row noGutters style={{ position: 'relative', width: '100%' }}>
			<Link
				className="px-0 py-0"
				to={`/product/${product.id}`}
				style={{
					textDecoration: 'none',
					width: '100%',
				}}
			>
				<Col lg={4} xs={12} className="px-0 py-0">
					<StyledContainer>
						<CardImage
							loading="lazy"
							src={
								product.imagePath
									? `${product.imagePath}?width=500`
									: config.PLACEHOLDER_IMAGE
							}
						/>
						<SRow>
							<TitleView>
								<H4>{product.title}</H4>
								<SmallTextSecondary>
									{product.provider.title}
								</SmallTextSecondary>
							</TitleView>
						</SRow>
					</StyledContainer>
				</Col>
			</Link>
		</Row>
	);
};

export default BrandedCard;
