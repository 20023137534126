import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../styles/StylesV3Main';
const H4Text = styled(H4)`
	font-size: 16px;
`;

const Texts = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
`;

const ServicePoints = ({ productType, version, size, ...props }) => {
	return (
		<Texts>
			{productType.enquire ? (
				<>
					<H4Text>Upon Enquiry</H4Text>
				</>
			) : (
				<>
					<H4Text>{productType.baseCost} Points</H4Text>
				</>
			)}
		</Texts>
	);
};

export default ServicePoints;
