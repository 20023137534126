/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// ignored due to richtext accesibility limitations
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import {
	Editor,
	EditorState,
	RichUtils,
	getDefaultKeyBinding,
	convertToRaw,
	convertFromRaw,
} from 'draft-js';

const RichTextEditor = ({
	placeholder,
	handleUpdate,
	prefill,
	required,
}: any) => {
	const [editorState, setEditorState] = useState(
		prefill
			? EditorState.createWithContent(convertFromRaw(prefill))
			: EditorState.createEmpty()
	);

	const editor = useRef<any>(null);

	const handleKeyCommand = (command: any, edState: any) => {
		const newState = RichUtils.handleKeyCommand(edState, command);
		if (newState) {
			setEditorState(newState);
			return 'handled';
		}
		return 'not-handled';
	};

	const mapKeyToEditorCommand = (e: any) => {
		if (e.keyCode === 9) {
			const newEditorState = RichUtils.onTab(e, editorState, 4);
			if (newEditorState !== editorState) {
				setEditorState(newEditorState);
			}
			return null;
		}
		return getDefaultKeyBinding(e);
	};

	const onChange = (newEditorState: EditorState) => {
		setEditorState(newEditorState);

		const storedState = convertToRaw(newEditorState.getCurrentContent());
		handleUpdate(storedState);
	};

	return (
		<InputWrapper>
			<Toolbar className="toolbar">
				<BlockStyleControls
					editorState={editorState}
					onToggle={(blockType: any) =>
						setEditorState(RichUtils.toggleBlockType(editorState, blockType))
					}
				/>
				<InlineStyleControls
					editorState={editorState}
					onToggle={(inlineStyle: any) => {
						setEditorState(
							RichUtils.toggleInlineStyle(editorState, inlineStyle)
						);
					}}
				/>
			</Toolbar>
			<EditorContainer>
				<div
					style={{ padding: '16px' }}
					onClick={() => editor.current!.focus()}
				>
					<Editor
						ref={editor}
						editorState={editorState}
						handleKeyCommand={handleKeyCommand}
						keyBindingFn={mapKeyToEditorCommand}
						onChange={onChange}
						placeholder={placeholder}
						spellCheck
					/>
				</div>
			</EditorContainer>

			{required && (
				<input
					name="editorValidation"
					onFocus={() => editor.current!.focus()}
					tabIndex={-1}
					autoComplete="off"
					value={editorState.getCurrentContent().hasText() ? 'Content' : ''}
					required={required}
					onChange={() => {}}
				/>
			)}
		</InputWrapper>
	);
};

const BLOCK_TYPES = [
	{ label: 'Title', style: 'header-five' },
	{ label: 'List', style: 'ordered-list-item' },
];

const BlockStyleControls = ({ editorState, onToggle }: any) => {
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<>
			{BLOCK_TYPES.map((type) => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					onMouseDown={(e: any) => {
						e.preventDefault();
						onToggle(type.style);
					}}
				>
					{type.label}
				</StyleButton>
			))}
		</>
	);
};

const INLINE_STYLES = [
	{ label: 'Bold', style: 'BOLD' },
	{ label: 'Italic', style: 'ITALIC' },
	{ label: 'Underline', style: 'UNDERLINE' },
];

const InlineStyleControls = ({ editorState, onToggle }: any) => (
	<>
		{INLINE_STYLES.map((type) => (
			<StyleButton
				key={type.label}
				active={editorState.getCurrentInlineStyle().has(type.style)}
				onMouseDown={(e: any) => {
					e.preventDefault();
					onToggle(type.style);
				}}
			>
				{type.label}
			</StyleButton>
		))}
	</>
);

const EditorContainer = styled.div`
	width: 512px;
	font-family: 'TTInterfaces Regular';
	font-size: 16px;
	border-radius: 3px;
	border: none;
	min-height: 100px;
	max-height: 300px;
	overflow: scroll;

	@media (max-width: 900px) {
		width: 100%;
	}

	.public-DraftEditorPlaceholder-root {
		color: #9197a3;
		position: absolute;
	}
`;

const Toolbar = styled.div`
	gap: 16px;
	padding: 9px 12px;
	margin: 0 8px;
	color: #5b6166;
	display: flex;
	top: 0;
	left: 0;
	font-size: 12px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	div {
		padding: 0 4px;
	}
`;

const StyleButton = styled.div<{ active: boolean }>`
	background-color: ${({ active }) => (active ? 'black' : 'unset')};
	color: ${({ active }) => (active ? 'white' : 'unset')};
	font-family: 'TTInterfaces DemiBold';
`;

const InputWrapper = styled.div`
	position: relative;
	border: 1.5px solid #eaecf0;
	border-radius: 10px;
	> input {
		opacity: 0;
		height: 0;
		width: 0;
		padding: 0;
		top: 70px;
		left: 200px;
		position: absolute;
	}
`;

export default RichTextEditor;
