import React from 'react';
// import styled from 'styled-components';
import Select from 'react-select';

// const SelectStyled = styled(Select)`
//   font: normal normal 600 14px 'Roobert Regular';
//   background: rgba(0, 0, 0, 0.03);
//   border: none;
//   border-radius: 3px;
//   width: 100%;
//   height: 56px;
//   padding: 0px 16px;
//   display: inline-block;
//   box-sizing: border-box;
//   padding-left: -17px; /* remove the damn :after space */

//   &:focus {
//     outline: 0;
//   }
// `;

const SingleSelect = ({
	value,
	options,
	defaultValue,
	className,
	...props
}) => {
	return (
		<Select
			{...props}
			className={className}
			classNamePrefix="select"
			defaultValue={defaultValue ? defaultValue : ['all']}
			closeMenuOnSelect={true}
			isClearable={true}
			isSearchable={true}
			options={options}
			value={value}
		/>
	);
};

export default SingleSelect;
