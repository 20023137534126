import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';

const StyledTroubleLoginPopup = styled.div`
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	box-shadow: 0px 14px 29px rgba(0, 0, 0, 0.09);
	border-radius: 3px;
	position: absolute;
	width: 345px;
	top: 20px;
	right: 10px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 22px 20px;
	@media only screen and (max-width: 991.83px) {
		display: none;
	}
`;

const StyledKey = styled.div`
	background: #f8f8f8;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3%;
`;

const StyledKeyImage = styled.img`
	object-fit: contain;
`;

const StyledTextContainer = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 12px;
`;

const StyledHeader = styled.p`
	margin: 0px;
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	line-height: 14px;
`;
const StyledP = styled.p`
	margin: 0px;
	color: rgba(0, 0, 0, 0.5);
	padding: 10px 0px 10px 0px;
	line-height: 18px;
`;

const StyledButton = styled.button`
	border: none;
	cursor: pointer;
	border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 3px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white;
	height: 32px;
	padding: 10px 12px;
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
`;

const StyledClose = styled(IoMdClose)`
	position: absolute;
	top: 13%;
	right: 5%;
	cursor: pointer;
`;

const StyledAnchor = styled.a`
	cursor: pointer;
	text-decoration: none !important;
	color: black;
	&:focus {
		outline: 0;
		text-decoration: none !important;
		color: black;
	}
	&:hover: {
		text-decoration: none !important;
		color: black;
	}
`;

const TroubleLoginPopup = ({ handleClosePopup }) => {
	return (
		<StyledTroubleLoginPopup>
			<StyledKey>
				<StyledKeyImage src="../../images/login/VectorKey.png"></StyledKeyImage>
			</StyledKey>
			<StyledTextContainer>
				<StyledHeader>Having trouble logging in? </StyledHeader>
				<StyledP>
					First, try "Sign In with Google" as you may have signed up using this
					method. If that doesn't work, take a look at our Help Centre
				</StyledP>
				<StyledButton>
					<StyledAnchor target="_blank" href="http://help.withjuno.com">
						Go to Help Center
					</StyledAnchor>
				</StyledButton>
			</StyledTextContainer>
			<StyledClose size={18} color={'grey'} onClick={handleClosePopup} />
		</StyledTroubleLoginPopup>
	);
};

export default TroubleLoginPopup;
