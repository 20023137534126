import React, { useState } from 'react';
import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { StyledTeamSearchInput } from '../../../styles/Form';

const StyledInputContainer = styled.div`
	height: 30px;
	display: flex;
	justify-content: start;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
`;

const StyledClose = styled(IoMdClose)`
	justify-content: end;
	color: grey;
	cursor: pointer;
`;

// const ResetInput = styled.input.attrs({
//   type: "reset",
// })``;

const StyledSearchInput = styled(StyledTeamSearchInput)``;

const SearchInput = ({ filter, handleChange, handleClose }) => {
	const [showClose, setShowClose] = useState(false);
	return (
		<StyledInputContainer className="px-0 ">
			<FiSearch className="mb-3" color="#e5e5e5" size="22" />
			<StyledSearchInput
				className="mb-2 ml-3"
				type="text"
				placeholder="Search for people..."
				value={filter}
				onChange={({ target: { value } }) => handleChange(value)}
				onClick={() => setShowClose(true)}
			/>
			{showClose && (
				<StyledClose
					className="mb-2 mr-1"
					onClick={() => {
						setShowClose(false);
						handleClose();
					}}
				/>
			)}
		</StyledInputContainer>
	);
};

export default SearchInput;
