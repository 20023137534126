import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
	BorderedCircle,
	RowView,
	StyledTextArea,
	StyledLabel,
	StyledInput,
	SelectStyled,
} from '../../styles/ProductStyles';
import {
	H5,
	H5SemiBold,
	H5TextSecondary,
	Paragraph,
	Small,
	SmallTextSecondary,
} from '../../styles/StylesV3Main';
import { MdRemove, MdAdd, MdDateRange, MdCloudUpload } from 'react-icons/md';
import AddressModal from './AddressModal';
import PhoneModal from './PhoneModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import bankdetails from '../../utils/bankdetails.json';
import S3FilesUploader from '../utilities/S3FilesUploader';
import { IoDocumentText } from 'react-icons/io5';

const Description = styled(Small)`
	width: 100%;
	margin-top: 8px;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const LabelH5 = styled.label`
	font: normal 600 14px 'Roobert Regular';
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

const CheckboxHidden = styled.input`
	display: none;
`;

const Add = styled(MdAdd)`
	color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'black')};
`;

const Remove = styled(MdRemove)`
	color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.1)' : 'black')};
`;

const OptionalText = styled(SmallTextSecondary)`
	line-height: 17px;
`;

// const SInput = styled.input`
//   border: none;
//   border-bottom: 2px solid black;
//   width: 70px;
//   text-align: center;
//   ::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   ::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   -moz-appearance: textfield;
//   @media (max-width: 1200px) {
//     width: 50px;
//   }
//   @media (max-width: 400px) {
//     width: 30px;
//   }
//   &:focus {
//     outline: none;
//   }
// `;

const QuantityRow = styled(Row)`
	width: 100%;
`;

export const BookingSelect = ({ option, service, setNewValue }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}

			<SelectStyled
				onChange={({ target: { value } }) => setNewValue(value)}
				marginT={option.title ? '0px' : '24px'}
				value={option.value}
			>
				{typeof option.options === 'string'
					? option.options
							.slice(1, -1)
							.split(',')
							.map((optionSelect, indexSelect) => {
								return (
									<option
										key={indexSelect}
										defaultValue={option.value === optionSelect ? true : false}
										value={optionSelect}
									>
										{`${option.options.slice(1, -1).split(',')[indexSelect]} ${
											option.costMultiplier.slice(1, -1).split(',')[
												indexSelect
											] *
												1 >
											1
												? '(' +
												  Math.round(
														service.baseCost *
															option.costMultiplier.slice(1, -1).split(',')[
																indexSelect
															] *
															1
												  ) +
												  ' points)'
												: option.costAdder.slice(1, -1).split(',')[
														indexSelect
												  ] *
														1 >
												  0
												? '(+' +
												  option.costAdder.slice(1, -1).split(',')[
														indexSelect
												  ] *
														1 +
												  ' points)'
												: ''
										}`}
									</option>
								);
							})
					: option.options
							.filter((item) => item.label !== '')
							.map((optionSelect, indexSelect) => {
								return (
									<option
										key={indexSelect}
										defaultValue={
											option.value === optionSelect.label ? true : false
										}
										value={optionSelect.label}
									>
										{`${option.options[indexSelect].label} ${
											option.options[indexSelect].costMultiplier > 1
												? '(' +
												  Math.round(
														service.baseCost *
															option.options[indexSelect].costMultiplier
												  ) +
												  ' points)'
												: option.options[indexSelect].costAdder > 0
												? '(+' +
												  option.options[indexSelect].costAdder +
												  ' points)'
												: ''
										}`}
									</option>
								);
							})}
			</SelectStyled>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

export const BookingTextArea = ({ option, ...props }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<StyledTextArea
				rows="2"
				marginT={option.title ? '0px' : '24px'}
				placeholder={option.placeholder}
				value={option.value ? option.value : ''}
				{...props}
			/>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

export const BookingAddress = ({ option, setSave, ...props }) => {
	const [showAddressModal, setShowAddressModal] = useState(false);
	return (
		<>
			<Col xs={12} className="px-0 py-0">
				<RowView border={true}>
					<Col className={'px-0'}>
						{option.title && (
							<>
								<H5SemiBold>{option.title}</H5SemiBold>
								{!option.compulsory && (
									<OptionalText className="ml-1">Optional</OptionalText>
								)}
							</>
						)}
					</Col>
					<Col style={{ textAlign: 'right' }} className={'px-0'}>
						<Paragraph style={{ fontSize: 16 }}>
							{option.value ? option.value : ''}
						</Paragraph>
						<H5
							style={{ cursor: 'pointer' }}
							onClick={() => setShowAddressModal(true)}
						>
							{option.value !== '' ? 'Update' : 'Enter your address'}
						</H5>
					</Col>

					<AddressModal
						setShowModal={setShowAddressModal}
						showModal={showAddressModal}
						goBack={() => {
							setShowAddressModal(false);
						}}
						description={option.description}
						value={option.value ? option.value : ''}
						placeholder={option.placeholder}
						name="Your address"
						setSave={setSave}
						{...props}
					/>
				</RowView>
			</Col>
		</>
	);
};
export const BookingPhone = ({ option, setSave, ...props }) => {
	const [showPhoneModal, setShowPhoneModal] = useState(false);
	return (
		<>
			<RowView border={true}>
				<Col className={'px-0'}>
					{option.title && (
						<>
							<H5SemiBold>{option.title}</H5SemiBold>
							{!option.compulsory && (
								<OptionalText className="ml-1">Optional</OptionalText>
							)}
						</>
					)}
				</Col>

				<Col style={{ textAlign: 'right' }} className={'px-0'}>
					{option.value && (
						<Paragraph style={{ fontSize: 16 }}>
							{formatPhoneNumberIntl(option.value.trim())
								? formatPhoneNumberIntl(option.value.trim())
								: option.value.trim()}
						</Paragraph>
					)}
					<LabelH5
						htmlFor="Your phone number"
						style={{ cursor: 'pointer' }}
						onClick={() => setShowPhoneModal(true)}
					>
						{option.value !== '' ? 'Update' : 'Enter Your Phone'}
					</LabelH5>
					<CheckboxHidden
						checked={option.saved ? option.saved : false}
						name="Your phone number"
						type="checkbox"
						onChange={({ target: { checked } }) => {
							setSave(checked);
						}}
					/>
				</Col>

				<PhoneModal
					setShowModal={setShowPhoneModal}
					value={option.value ? option.value.trim() : ''}
					showModal={showPhoneModal}
					required={option.compulsory}
					name="Your phone number"
					description={option.description}
					goBack={() => {
						setShowPhoneModal(false);
					}}
					{...props}
				/>
			</RowView>
		</>
	);
};

export const BookingEmail = ({ option, ...props }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<StyledInput
				type="email"
				marginT={option.title ? '0px' : '24px'}
				placeholder="Enter email"
				value={option.value ? option.value : ''}
				{...props}
			/>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

export const BookingNumber = ({ option, setNewValue }) => {
	return (
		<>
			{option.editable ? (
				<>
					{option.title && (
						<StyledLabel>
							{option.title}
							{!option.compulsory && (
								<OptionalText className="ml-1">Optional</OptionalText>
							)}
						</StyledLabel>
					)}
					<StyledInput
						type="number"
						marginT={option.title ? '0px' : '24px'}
						placeholder="Enter amount"
						value={option.value}
						pattern="[0-9]*"
						onChange={({ target: { value: e } }) => {
							setNewValue(e);
						}}
					/>
					{option.description && (
						<Description style={{ margin: 0 }}>
							{option.description}
						</Description>
					)}
				</>
			) : (
				<Row
					noGutters
					style={{ width: '100%' }}
					className="justify-content-center align-items-center"
				>
					<Col xs={7} md={9} lg={7}>
						{option.title && (
							<StyledLabel>
								<H5SemiBold>{option.title}</H5SemiBold>
								{!option.compulsory && (
									<OptionalText className="ml-1">Optional</OptionalText>
								)}
							</StyledLabel>
						)}
						{option.description && (
							<Description style={{ margin: 0 }}>
								{option.description}
							</Description>
						)}
					</Col>
					<Col xs={5} md={3} lg={5}>
						<QuantityRow
							noGutters
							className="justify-content-around align-items-center mt-4 mb-1"
						>
							<BorderedCircle
								size={'40px'}
								borderWidth={'2px'}
								borderColor={'black'}
								onClick={() => {
									setNewValue(
										option.value * 1 > option.minValue * 1
											? option.value * 1 - 1
											: option.value * 1
									);
								}}
								disabled={
									option.value * 1 === option.minValue * 1 ? true : false
								}
							>
								<Remove
									size={20}
									disabled={
										option.value * 1 === option.minValue * 1 ? true : false
									}
								/>
							</BorderedCircle>
							{/* {option.editable ? (
                <SInput
                  value={
                    option.value === "" ? option.defaultValue : option.value * 1
                  }
                  type="number"
                  onChange={({ target: { value: e } }) => {
                    e && e >= option.minValue && setNewValue(e * 1);
                  }}
                />
              ) : ( */}
							<H5>
								{option.value === '' ? option.defaultValue : option.value * 1}
							</H5>
							{/* )} */}
							<BorderedCircle
								size={'40px'}
								borderWidth={'2px'}
								borderColor={'black'}
								onClick={() => {
									setNewValue(option.value * 1 + 1);
								}}
							>
								<Add size={20} />
							</BorderedCircle>
						</QuantityRow>
					</Col>
				</Row>
			)}
		</>
	);
};

export const BookingTel = ({ option, ...props }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}

			<StyledInput
				marginT={option.title ? '0px' : '24px'}
				value={option.value ? option.value.trim() : ''}
				type="tel"
				{...props}
			/>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

export const BookingInput = ({ option, ...props }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<StyledInput
				marginT={option.title ? '0px' : '24px'}
				value={option.value ? option.value : ''}
				type="text"
				{...props}
			/>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

const renderBankDetailsInputs = ({ option, setNewValue }) => {
	const bankDetails =
		bankdetails.filter((item) => item.type === option.value?.type).length > 0
			? bankdetails.filter((item) => item.type === option.value?.type)[0]
			: null;

	return (
		<>
			<StyledLabel>{bankDetails.firstPart}</StyledLabel>
			<StyledInput
				marginT="0px"
				value={option.value?.firstPart ? option.value.firstPart : ''}
				type="text"
				onChange={({ target: { value } }) =>
					setNewValue({ ...option.value, firstPart: value })
				}
			/>
			{bankDetails.secondPart && (
				<>
					<StyledLabel>{bankDetails.secondPart}</StyledLabel>
					<StyledInput
						marginT="0px"
						value={option.value?.secondPart ? option.value.secondPart : ''}
						type="text"
						onChange={({ target: { value } }) =>
							setNewValue({ ...option.value, secondPart: value })
						}
					/>
				</>
			)}
			{bankDetails.address && (
				<BookingAddress
					option={{
						...option,
						title: 'Your address',
						placeholder: '',
						value: option.value?.address,
					}}
					setSave={() => {}}
					onChange={({ target: { value } }) =>
						setNewValue({ ...option.value, address: value })
					}
				/>
			)}
			{bankDetails.notes && (
				<>
					<StyledLabel>
						Notes
						<OptionalText className="ml-1">Optional</OptionalText>
					</StyledLabel>
					<StyledTextArea
						rows="2"
						marginT={'0px'}
						value={option.value?.notes ? option.value.notes : ''}
						onChange={({ target: { value } }) =>
							setNewValue({ ...option.value, notes: value })
						}
					/>
				</>
			)}
		</>
	);
};

export const BookingBankDetails = ({ option, setNewValue }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<SelectStyled
				value={option.value?.type ? option.value.type : ''}
				onChange={({ target: { value } }) =>
					setNewValue({ ...option.value, type: value })
				}
				marginT={option.title ? '0px' : '24px'}
			>
				{bankdetails.map((item, index) => (
					<option key={index} defaultValue={true} value={item.type}>
						{item.title}
					</option>
				))}
			</SelectStyled>
			{renderBankDetailsInputs({ option, setNewValue })}
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

const StyledDropZoneContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 120px;
`;

const Input = styled.input`
	display: none;
	border: 1px solid black;
	border-radius: 50%;
	background-color: lightblue;
	width: 100%;
	min-height: 120px;
	height: auto;
`;

const Label = styled.label`
	color: #fff;
	cursor: pointer;
	border-radius: 3;
	color: black;
	margin-bottom: 0px;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const PreviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => (props.length === 1 ? 'center' : 'start')};
	align-items: center;
	width: 100%;
	height: auto;
`;

const ImageContainer = styled.img`
	width: 120px;
	height: 48px;
	object-fit: cover;

	border-radius: 3px;
	@media (max-width: 1200px) {
		width: 90px;
	}
`;

const PDFPurpleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	background-color: #d6bbf7;
	width: 120px;
	height: 48px;
	@media (max-width: 1200px) {
		width: 90px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: ${(props) => (props.length > 2 ? '24px' : '0px')};
`;

const BookingFileInputComponent = ({
	text,
	accept,
	onFiles,
	option,
	files,
	getFilesFromEvent,
}) => {
	// files.map((item, index) => {
	//   console.log(item);
	// });

	return (
		<StyledDropZoneContainer>
			{option.value.length === 0 && (
				<Col className="px-0 py-0">
					<Label>
						<MdCloudUpload size={20} color={'#5B6166'} />
						<H5TextSecondary>{text}</H5TextSecondary>
						<Input
							type="file"
							accept={accept}
							multiple
							onChange={async (e) => {
								const chosenFiles = await getFilesFromEvent(e);
								onFiles(chosenFiles);
							}}
						/>
					</Label>
				</Col>
			)}
			<PreviewContainer length={files && files.length}>
				<Row noGutters style={{ width: '100%' }}>
					{option.value &&
						option.value.length > 0 &&
						option.value.map((item, index) => (
							<Col
								xs={
									option.value.length === 1
										? 12
										: option.value.length === 2
										? 6
										: 4
								}
								key={index}
								className="px-0 py-0"
							>
								{item.meta.type !== 'application/pdf' && (
									<Container length={option.value && option.value.length}>
										<ImageContainer src={item.meta.previewUrl} />
										<SmallTextSecondary className="mt-2">
											{item.meta.name}
										</SmallTextSecondary>
										<H5
											className="mt-2"
											onClick={item.remove}
											style={{ cursor: 'pointer' }}
										>
											Remove
										</H5>
									</Container>
								)}
								{item.meta.type === 'application/pdf' && (
									<Container>
										<PDFPurpleContainer>
											<IoDocumentText size={20} color="#B585F1" />
										</PDFPurpleContainer>
										<SmallTextSecondary className="mt-2">
											{item.meta.name}
										</SmallTextSecondary>
										<H5
											className="mt-2"
											onClick={item.remove}
											style={{ cursor: 'pointer' }}
										>
											Remove
										</H5>
									</Container>
								)}
							</Col>
						))}
				</Row>
			</PreviewContainer>
		</StyledDropZoneContainer>
	);
};

const LayoutContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => (props.length === 1 ? 'center' : 'start')};
	align-items: center;
	width: 100%;
	border: 0px;
	height: auto;
	padding: 16px;
`;

const BookingFileLayout = ({
	input,
	dropzoneProps,
	files,
	extra: { maxFiles },
}) => {
	return (
		<>
			<LayoutContainer {...dropzoneProps} length={files && files.length}>
				{files.length < maxFiles && input}
			</LayoutContainer>
		</>
	);
};

export const BookingFileInput = ({
	option,
	disabled,
	id,
	setNewValue,
	...props
}) => {
	const handleAdd = (files) => {
		try {
			setNewValue(files);
		} catch (err) {
			console.error(err.message);
		}
	};
	// console.log(option);

	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<S3FilesUploader
				noUpload
				accept="image/*, application/pdf"
				onChangeStatus={({ meta }, status, file) => {
					switch (status) {
						case 'done':
							handleAdd(file);
							break;
						default:
							break;
					}
				}}
				InputComponent={(props) =>
					!disabled ? (
						<BookingFileInputComponent
							{...props}
							text={'Drag and drop a file, or click to browse'}
							option={option}
						/>
					) : null
				}
				//PreviewComponent={BookingFilePreview}
				LayoutComponent={BookingFileLayout}
				disabled={(files) =>
					disabled ||
					files.some((f) =>
						['preparing', 'getting_upload_params', 'uploading'].includes(
							f.meta.status
						)
					)
				}
				canRestart={true}
				maxFiles={20}
				styles={{
					dropzone: {
						background: '#f8f8f8',
						borderRadius: '3px',
						minHeight: '120px',
						border: 0,
						height: 'auto',
						cursor: 'pointer',
						color: 'black',
						marginBottom: '0px',
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					},
				}}
			/>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

const DateContainer = styled.div`
	position: relative;
	width: 100%;
	height: 56px;
	margin-top: ${(props) => props.marginT};
`;

const StyledDate = styled.div`
	width: 100%;
	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		display: block;
		width: 100%;
		height: 56px;
		border: none;
		border-radius: 3px;
		background: rgba(0, 0, 0, 0.01);
		cursor: pointer;
	}

	.react-datepicker__day-names {
		display: none;
		width: 100%;
	}

	.react-datepicker__day--selected {
		background-color: black;
		color: white;
	}
	.react-datepicker__day--disabled {
		background-color: rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3);
	}
	&:focus {
		outline: 0;
	}
`;

const CustomDatePickDiv = styled.div`
	padding-left: 16px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 56px;
	border: none;
	border-radius: 3px;
	background: rgba(0, 0, 0, 0.01);
	cursor: pointer;
`;

const DateLabel = styled.label`
	margin: 0px;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
`;

export const CustomDatePicker = ({ option, ...props }) => {
	const addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<CustomDatePickDiv onClick={props.onClick}>
				<DateLabel style={{ margin: 0 }} onClick={props.onClick} ref={ref}>
					{props.value || props.placeholder}
				</DateLabel>

				<MdDateRange
					size={20}
					onClick={props.onClick}
					style={{
						position: 'absolute',
						top: 16,
						right: 16,
					}}
				/>
			</CustomDatePickDiv>
		);
	});

	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<DateContainer marginT={option.title ? '0px' : '24px'}>
				<StyledDate>
					<DatePicker
						dateFormat={'dd/MM/yyyy'}
						placeholderText={'Select a date'}
						selectsRange={option.rangeSelection ? option.rangeSelection : false}
						minDate={addDays(
							new Date(),
							option.acceptedRangeMinDayFuture
								? option.acceptedRangeMinDayFuture * 1
								: 5
						)}
						maxDate={addDays(
							new Date(),
							option.acceptedRangeMaxDayFuture
								? option.acceptedRangeMaxDayFuture * 1
								: 30
						)}
						selected={option.value}
						customInput={<CustomInput />}
						{...props}
					/>
				</StyledDate>
			</DateContainer>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};

const CurrencySelect = styled.select`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	background: rgba(0, 0, 0, 0.03);
	margin-top: ${(props) => props.marginT};
	cursor: pointer;
	border: none;
	//border-right: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	width: 22%;
	height: 56px;
	padding: 0px 16px;
	padding-right: 30px;
	display: inline-block;
	box-sizing: border-box;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url('/images/products/ArrowDown.svg');
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.7em auto, 100%;
	&::-ms-expand {
		display: none;
	}
	&:focus {
		outline: 0;
	}
`;

const SeperatorDiv = styled.div`
	width: 3%;
	height: 56px;
	background: rgba(0, 0, 0, 0.03);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Seperator = styled.div`
	width: 2px;
	height: 26px;
	background-color: rgba(0, 0, 0, 0.1);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	margin: 0px 16px;
`;

const CurrencyInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: rgba(0, 0, 0, 0.03);
	margin-top: ${(props) => props.marginT};
	border-radius: 3px;
	width: 75%;
	height: 56px;
	padding-left: 16px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

export const BookingCurrencyAmount = ({ option, setNewValue }) => {
	return (
		<>
			{option.title && (
				<StyledLabel>
					{option.title}{' '}
					{!option.compulsory && (
						<OptionalText className="ml-1">Optional</OptionalText>
					)}
				</StyledLabel>
			)}
			<Row
				noGutters
				className="justify-content-start align-items-center"
				style={{ width: '100%' }}
			>
				<CurrencySelect
					value={option.value?.type ? option.value.type : null}
					onChange={({ target: { value } }) =>
						setNewValue({ ...option.value, type: value })
					}
				>
					{['gbp', 'eur', 'usd'].map((item) => (
						<option>{item.toUpperCase()}</option>
					))}
				</CurrencySelect>
				<SeperatorDiv>
					<Seperator />
				</SeperatorDiv>
				<CurrencyInput
					type="number"
					step={1}
					min={0}
					marginT={option.title ? '0px' : '24px'}
					placeholder="Enter Amount"
					value={option.value?.amount ? option.value.amount : ''}
					onChange={({ target: { value } }) =>
						setNewValue({ ...option.value, amount: Number(value) })
					}
				/>
			</Row>
			{option.description && <Description>{option.description}</Description>}
		</>
	);
};
