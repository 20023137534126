import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HiLocationMarker } from 'react-icons/hi';
import { useUserContext } from '../../contexts/userContext';
import LocationSearch from '../utilities/LocationSearch';
import Modal from '../../styles/shared/Modal';
import Button from '../../styles/shared/Button/Button';
import config from '../../config';

(window as any).mapsLoaded = () => {};

const loadGoogleMaps = (callback: () => void) => {
	const existingScript = document.getElementById('googleMaps');

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${config.gmaps.API_KEY}&callback=mapsLoaded&libraries=places,geometry,geometry&language=en-GB`;
		script.id = 'googleMaps';
		document.body.appendChild(script);

		script.onload = () => {
			if (callback) callback();
		};
	}

	if (existingScript && callback) callback();
};

const Location = () => {
	const { userData, updateUserFields } = useUserContext();
	const [showLocationModal, setShowLocationModal] = useState(false);
	const [localAddress, setLocalAddress] = useState(userData.locationData);
	const [updating, setUpdating] = useState('');
	const [mapsLoaded, setMapsLoaded] = useState(false);
	const defaultType = !updating ? 'default' : 'disabled';

	useEffect(() => {
		loadGoogleMaps(() => {
			setMapsLoaded(true);
		});
	}, []);
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					setShowLocationModal(true);
				}}
			>
				<Change type="submit">
					{!userData.locationData || !userData.locationData.latLng
						? 'Set your location'
						: userData.locationData.address.formatted_address}
				</Change>
			</form>
			{showLocationModal && (
				<Modal
					open={showLocationModal}
					onClose={() => {
						setLocalAddress(userData.locationData);
						setShowLocationModal(false);
						setUpdating('');
					}}
				>
					<ModalContainer>
						<h2>Set your location</h2>
						<h3>
							This can be anything: a postal address, a city, a region, even a
							country.
						</h3>
						<Current>
							{localAddress?.address?.formatted_address ? (
								<div>
									Your current location
									<div>
										<HiLocationMarker size={30} />
										{localAddress?.address?.formatted_address}
									</div>
								</div>
							) : (
								<>Your location is not set.</>
							)}
						</Current>
						<div style={{ textAlign: 'center' }}>
							{mapsLoaded && (
								<LocationSearch
									updatedAddress={(location: any) => setLocalAddress(location)}
								/>
							)}
						</div>
						<Buttons>
							<Button
								state={updating === 'reset' ? 'loading' : defaultType}
								buttonType="hollow"
								size="small"
								onClick={async () => {
									setUpdating('reset');
									await updateUserFields({
										field: 'locationData',
										value: null,
									});
									setLocalAddress(null);
									setShowLocationModal(false);
									setUpdating('');
								}}
							>
								Reset
							</Button>
							<Button
								state={updating === 'done' ? 'loading' : defaultType}
								buttonType="primary"
								size="small"
								onClick={async () => {
									setUpdating('done');
									await updateUserFields({
										field: 'locationData',
										value: localAddress,
									});
									setShowLocationModal(false);
									setUpdating('');
								}}
							>
								Done
							</Button>
						</Buttons>
					</ModalContainer>
				</Modal>
			)}
		</>
	);
};

const Change = styled.button`
	background-color: #a3a3a3;
	color: #fff;
	border-radius: 50px;
	border: 0;
	padding: 6.5px 18px;
	font-family: 'TTInterfaces Demibold';
	font-size: 15px;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ModalContainer = styled.div`
	max-width: 475px;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px 45px;
	h2 {
		font-family: 'TTInterfaces Demibold';
		font-weight: 600;
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		margin: 0;
		padding-bottom: 12px;
	}

	h3 {
		font-family: 'TTInterfaces Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 140%;
		color: rgba(0, 0, 0, 0.35);
		margin: 0;
		text-align: center;
	}
`;

const Current = styled.div`
	display: flex;
	justify-content: center;
	font-family: 'TTInterfaces Regular';
	text-align: center;
	padding: 32px 0;
	> div {
		display: flex;
		flex-direction: column;
	}
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 16px;
	padding-top: 24px;
`;

export default Location;
