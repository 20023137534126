import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../../styles/shared/Button/Button';
import { ReactComponent as Back } from './images/back.svg';
import arrow from './images/arrow.svg';

const RequestPhysicalCard = ({ card, save }: any) => {
	const [fullName, setFullName] = useState(card.fullName);
	const [addressLine1, setAddressLine1] = useState(card.line1);
	const [addressLine2, setAddressLine2] = useState(card.line2 || '');
	const [townOrCity, setTownOrCity] = useState(card.city);
	const [postalCode, setPostalCode] = useState(card.postCode);
	const [country, setCountry] = useState(card.country);
	const [saving, setSaving] = useState(false);
	const { pathname, search } = useLocation();
	const history = useHistory();
	return (
		<Request>
			<p>Delivery Time: 2-3 Business Days</p>
			<p>
				Use Chip and Pin for your first transaction. All future transactions can
				be contactless.
			</p>
			<div>
				<div>
					<button
						type="button"
						onClick={() => {
							const newParams = new URLSearchParams(search);
							newParams.delete('physical');
							history.push(`${pathname}?${newParams}`, {
								scroll: false,
							});
						}}
					>
						<Back />
					</button>
				</div>
				<div>
					<h2>Request a physical card</h2>
					<p>Confirm shipping address.</p>
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							if (e.currentTarget.checkValidity()) {
								setSaving(true);
								await save({
									address: {
										line1: addressLine1,
										line2: addressLine2 || undefined,
										city: townOrCity,
										country,
										postal_code: postalCode,
									},
									name: fullName,
									service: 'standard',
								});
							}
						}}
					>
						<div>
							<input
								id="fullName"
								placeholder="Full name*"
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
								required
								pattern=".*[^\s]+[\s]+[^\s]+.*"
								value={fullName}
								onChange={({ target }) => {
									setFullName(target.value);
									target.setCustomValidity(
										target.validity.patternMismatch
											? 'Must include first and last name.'
											: ''
									);
								}}
							/>
							<label htmlFor="fullName">Full name*</label>
						</div>
						<div>
							<input
								id="addressLine1"
								placeholder="Address line 1*"
								required
								value={addressLine1}
								onChange={({ target }) => setAddressLine1(target.value)}
							/>
							<label htmlFor="addressLine1">Address line 1*</label>
						</div>
						<div>
							<input
								id="addressLine2"
								placeholder="Address line 2"
								value={addressLine2}
								onChange={({ target }) => setAddressLine2(target.value)}
							/>
							<label htmlFor="addressLine2">Address line 2</label>
						</div>
						<div>
							<input
								id="townOrCity"
								placeholder="Town or City*"
								required
								value={townOrCity}
								onChange={({ target }) => setTownOrCity(target.value)}
							/>
							<label htmlFor="townOrCity">Town or city*</label>
						</div>
						<div>
							<input
								id="postalCode"
								placeholder="Postal Code*"
								required
								value={postalCode}
								onChange={({ target }) => setPostalCode(target.value)}
							/>
							<label htmlFor="postalCode">Postal Code*</label>
						</div>
						<div>
							<select
								id="country"
								required
								data-value={country}
								value={country}
								onChange={({ target }) => setCountry(target.value)}
							>
								<option value="">Country*</option>
								<option value="AT">Austria</option>
								<option value="BE">Belgium</option>
								<option value="CY">Cyprus</option>
								<option value="EE">Estonia</option>
								<option value="FI">Finland</option>
								<option value="FR">France</option>
								<option value="DE">Germany</option>
								<option value="IE">Ireland</option>
								<option value="IT">Italy</option>
								<option value="LV">Latvia</option>
								<option value="LT">Lithuania</option>
								<option value="LU">Luxembourg</option>
								<option value="MT">Malta</option>
								<option value="NL">Netherlands</option>
								<option value="PT">Portugal</option>
								<option value="SK">Slovakia</option>
								<option value="SI">Slovenia</option>
								<option value="ES">Spain</option>
								<option value="GB">United Kingdom</option>
							</select>
							<label htmlFor="country">Country*</label>
						</div>
						<div>
							<Button state={!saving ? 'default' : 'loading'}>
								Request card
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Request>
	);
};

const Request = styled.div`
	> p:first-child {
		margin: 40px 0 0 0;
		color: #959595;
		font-size: 18px;
		font-family: 'TTInterfaces Medium';
		line-height: 28px;
		@media (max-width: 992px) {
			margin-top: 35px;
		}
	}
	> p + p {
		margin: 0 0 40px 0;
		color: #475467;
		font-size: 18px;
		font-family: 'TTInterfaces Regular';
		line-height: 28px;
		@media (max-width: 992px) {
			margin-bottom: 35px;
		}
	}
	> div {
		display: flex;
		border-radius: 26px;
		background-color: #fff;
		padding: 47px 12% 40px 30px;
		gap: 60px;
		> div:first-child {
			flex-basis: 40px;
			button {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				border: 0;
				background-color: #525252;
				box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
			}
		}
		> div:last-child {
			flex: 1;
		}
		@media (max-width: 1200px) {
			flex-direction: column;
			padding: 24px;
			gap: 20px;
		}
	}
	h2 {
		font-size: 24px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 21px;
		margin-bottom: 8px;
		+ p {
			color: black;
			font-size: 18px;
			font-family: 'TTInterfaces Regular';
			line-height: 28px;
			margin-bottom: 26px;
			@media (max-width: 992px) {
				margin-bottom: 20px;
			}
		}
	}
	form {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 50px;
		row-gap: 30px;
		> div {
			position: relative;
		}
		> div:last-child {
			grid-row-start: 4;
			grid-row-end: 5;
			grid-column-start: 2;
			grid-column-end: 3;
			display: flex;
			justify-content: flex-end;
		}
		button {
			border-radius: 60px;
			padding: 12px 72px;
			background-color: #000;
			color: #f6f6f6;
			font-size: 16px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 26px;
			width: unset;
			min-width: unset;
			min-height: unset;
			@media (max-width: 992px) {
				width: 100%;
			}
		}
		@media (max-width: 1200px) {
			column-gap: 24px;
		}
		@media (max-width: 992px) {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
	input,
	select {
		border-radius: 7px;
		padding: 14px 16px;
		border: 1px solid #d0d0d0;
		font-size: 16px;
		font-family: 'TTInterfaces Medium';
		width: 100%;
	}
	select {
		appearance: none;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
	}
	input:not(:placeholder-shown),
	select:not([data-value='']) {
		padding: 16px 16px 12px 16px;
	}
	label {
		color: black;
		font-size: 11px;
		font-family: 'TTInterfaces Regular';
		position: absolute;
		left: 17px;
		top: 4px;
		line-height: 13px;
		transition: opacity 0.2s ease-in-out;
	}
	input:placeholder-shown + label {
		opacity: 0;
	}
	input:not(:placeholder-shown) + label {
		opacity: 1;
	}
	select[data-value=''] + label {
		opacity: 0;
	}
	select:not([data-value='']) + label {
		opacity: 1;
	}
`;

export default RequestPhysicalCard;
