import React from 'react';
import styled from 'styled-components';
// import { ReactComponent as Logo } from '../logo.svg';
import { Spinner } from 'react-bootstrap';

const SContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SDiv = styled.div`
	width: 100vw;
	height: 40vh;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
`;

const SplashScreen = () => {
	return (
		<SContainer>
			<SDiv>
				{/* <Logo height='100%' width='50vw' />
        <h1>Loading...</h1> */}
				<Spinner animation="grow" />
			</SDiv>
		</SContainer>
	);
};

export default SplashScreen;
