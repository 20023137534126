import styled from 'styled-components';

export const PrimaryButton = styled.button`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	opacity: ${({ disabled }) => disabled && 0.5};
	color: ${(props) => props.theme.mainColors.color.textWhite};
	background: ${(props) => props.theme.mainColors.color.textBlack};
	border: none;
	border-radius: 3px;
	width: ${({ width }) => (width ? width : '188px')};
	height: ${({ height }) => (height ? height : '48px')};
	box-sizing: border-box;
	${({ cancelButton }) =>
		cancelButton &&
		`   background: white;
        border: 1.5px solid black;
        color: #000;
    `}
	${({ cancel }) =>
		cancel &&
		`   background: rgba(255, 0, 0, 0.05);
        border: 1.5px solid #FF0000;
        color: #FF0000;
    `}
  &:focus {
		outline: 0;
	}
	@media (max-width: 500px) {
		width: ${({ width }) => (width ? width : '150px')};
	}
`;

export const SecondoryButton = styled.button`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	background: transparent;
	border: 2px solid #000000;
	border-radius: 3px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	width: ${({ bwidth }) => (bwidth ? '224px' : '100%')};
	height: 48px;
	box-sizing: border-box;
	${({ disabled }) =>
		disabled &&
		`   opacity: 0.5;
        color: #191A1C;
    `}
	&:focus {
		outline: 0;
	}
	@media (max-width: 500px) {
		width: 150px;
	}
`;
