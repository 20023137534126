import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../styles/StylesV3Main';

const Texts = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
	justify-content: ${(props) => props.align || 'start'};
	align-items: center;
`;

const H4Text = styled(H4)`
	font-size: ${(props) => (props.size ? props.size : '16px')};
`;

const getMinPoints = (product) => {
	return product.productTypes
		.sort((a, b) => {
			return a.baseCost > b.baseCost ? 1 : -1;
		})
		.find((service) => !service.enquire && service.active)?.baseCost;
};

const getMaxPoints = (product) => {
	return product.productTypes
		.sort((a, b) => {
			return a.baseCost < b.baseCost ? 1 : -1;
		})
		.find((service) => !service.enquire && service.active).baseCost;
};

const JunoPoints = ({ product, size, align }) => {
	const getPoints = (product) => {
		try {
			if (
				product.productTypes.filter((service) => service.active).length === 1
			) {
				return product.productTypes[0].baseCost;
			} else {
				const min = getMinPoints(product);

				const max = getMaxPoints(product);

				return min === max ? min : `From ${min}`;
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	const showPointsMessage = (product) => {
		if (product.productTypes.length > 0) {
			if (
				product.productTypes.filter(
					(service) => service.active && service.enquire
				).length === product.productTypes.length
			) {
				return 'Upon Enquiry';
			} else {
				return `${getPoints(product)} points`;
			}
		}
	};

	return (
		<Texts align={align}>
			<H4Text size={size}>{showPointsMessage(product)}</H4Text>
		</Texts>
	);
};

export default JunoPoints;
