// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { WebAuth } from 'auth0-js';
import config from '../utils/auth_config.json';

const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	...initOptions
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [user, setUser] = useState();
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState(true);
	const [webAuthClient, setWebAuth] = useState();

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client(initOptions);
			setAuth0(auth0FromHook);

			if (
				window.location.search.includes('code=') &&
				window.location.search.includes('state=')
			) {
				const { appState } = await auth0FromHook.handleRedirectCallback();
				onRedirectCallback(appState);
			}
			const isAuthenticated = await auth0FromHook.isAuthenticated();

			setIsAuthenticated(isAuthenticated);

			if (isAuthenticated) {
				const user = await auth0FromHook.getUser();
				setUser(user);
			}

			const client = new WebAuth({
				redirectUri: initOptions.redirect_uri,
				domain: config.domain,
				clientID: config.clientId,
				responseType: 'token id_token',
				audience: config.audience,
			});

			setWebAuth(client);

			if (window.location.hash.includes('access_token=')) {
				const x = await handleAuthentication(client);
				setIsAuthenticated(true);
				setUser({ ...x.idTokenPayload, appState: x.appState });
				if (
					x?.appState?.redirectUrl &&
					x?.appState?.pathname !== '/' &&
					x?.appState?.pathname !== '/login' &&
					x?.appState?.pathname !== '/signup'
				) {
					onRedirectCallback({
						targetUrl: x?.appState?.pathname + x?.appState?.search || '',
					});
				}
			}

			setLoading(false);
		};
		initAuth0();
		// eslint-disable-next-line
	}, []);

	const handleAuthentication = (client) =>
		new Promise((resolve, reject) => {
			client.parseHash((err, authResult) => {
				if (authResult && authResult.accessToken && authResult.idToken) {
					resolve(authResult);
				} else {
					reject(err);
				}
			});
		});

	const handleRedirectCallback = async () => {
		setLoading(true);
		await auth0Client.handleRedirectCallback();
		const user = await auth0Client.getUser();
		setLoading(false);
		setIsAuthenticated(true);
		setUser(user);
	};

	const loginWithCredentials = async (username, password, redirectUrl) => {
		await auth0Client.logout({ localOnly: true });
		return new Promise((resolve, reject) => {
			webAuthClient.login(
				{
					realm: 'Username-Password-Authentication',
					username,
					password,
					appState: {
						redirectUrl: redirectUrl?.href,
						pathname: redirectUrl?.pathname,
						search: redirectUrl?.search,
					},
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					return resolve();
				}
			);
		});
	};

	const loginWithCredentialsInvite = async (username, password, invite) => {
		await auth0Client.logout({ localOnly: true });
		return new Promise((resolve, reject) => {
			webAuthClient.login(
				{
					realm: 'Username-Password-Authentication',
					username,
					password,
					appState: invite,
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					return resolve();
				}
			);
		});
	};

	const loginWithSocialInvite = async (connection, invite) => {
		await auth0Client.logout({ localOnly: true });
		return new Promise((resolve, reject) => {
			webAuthClient.authorize(
				{
					connection: connection,
					appState: invite,
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					return resolve();
				}
			);
		});
	};

	const signUpWithCredentialsInvite = (email, password, invite) =>
		new Promise((resolve, reject) => {
			webAuthClient.signup(
				{
					email,
					password,
					connection: 'Username-Password-Authentication',
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					loginWithCredentialsInvite(email, password, invite);
					return resolve();
				}
			);
		});

	const loginWithSocial = async (connection, redirectUrl) => {
		await auth0Client.logout({ localOnly: true });
		return new Promise((resolve, reject) => {
			webAuthClient.authorize(
				{
					connection: connection,
					appState: {
						redirectUrl: redirectUrl?.href,
						pathname: redirectUrl?.pathname,
						search: redirectUrl?.search,
					},
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					return resolve();
				}
			);
		});
	};

	const signUpWithCredentials = (email, password, redirectUrl) =>
		new Promise((resolve, reject) => {
			webAuthClient.signup(
				{
					email,
					password,
					connection: 'Username-Password-Authentication',
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					loginWithCredentials(email, password, redirectUrl);
					return resolve();
				}
			);
		});

	const changePasswordCredentials = (email) =>
		new Promise((resolve, reject) => {
			webAuthClient.changePassword(
				{
					email: email,
					connection: 'Username-Password-Authentication',
				},
				(err) => {
					if (err) {
						return reject(err);
					}
					return resolve();
				}
			);
		});

	return (
		<Auth0Context.Provider
			value={{
				isAuthenticated,
				setIsAuthenticated,
				user,
				loading,
				handleRedirectCallback,
				getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
				loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
				getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
				getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
				logout: (...p) => auth0Client.logout(...p),
				loginWithCredentials,
				loginWithSocial,
				signUpWithCredentials,
				loginWithSocialInvite,
				signUpWithCredentialsInvite,
				loginWithCredentialsInvite,
				changePasswordCredentials,
			}}
		>
			{children}
		</Auth0Context.Provider>
	);
};
