import React from 'react';

import cancelImg from './cancel.svg';
import removeImg from './remove.svg';
import restartImg from './restart.svg';

import { SmallTextSecondary } from '../../styles/StylesV3Main';

export const formatBytes = (b) => {
	const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0;
	let n = b;

	while (n >= 1024) {
		n /= 1024;
		l += 1;
	}

	return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`;
};

export const formatDuration = (seconds) => {
	const date = new Date(0);
	date.setSeconds(seconds);
	const dateString = date.toISOString().slice(11, 19);
	if (seconds < 3600) return dateString.slice(3);
	return dateString;
};

const iconByFn = {
	cancel: { backgroundImage: `url(${cancelImg})` },
	remove: { backgroundImage: `url(${removeImg})` },
	restart: { backgroundImage: `url(${restartImg})` },
};

class FilePreview extends React.PureComponent {
	render() {
		const {
			className,
			imageClassName,
			style,
			imageStyle,
			fileWithMeta: { cancel, remove, restart },
			meta: {
				name = '',
				percent = 0,
				size = 0,
				previewUrl,
				status,
				duration,
				validationError,
			},
			isUpload,
			canCancel,
			canRemove,
			canRestart,
			extra: { minSizeBytes },
		} = this.props;

		let title = `${name || '?'}, ${formatBytes(size)}`;
		if (duration) title = `${title}, ${formatDuration(duration)}`;

		if (status === 'error_file_size' || status === 'error_validation') {
			return (
				<div className={className} style={style}>
					<SmallTextSecondary className="dzu-previewFileNameError">
						{title}
					</SmallTextSecondary>
					{status === 'error_file_size' && (
						<SmallTextSecondary>
							{size < minSizeBytes ? 'File too small' : 'File too big'}
						</SmallTextSecondary>
					)}
					{status === 'error_validation' && (
						<SmallTextSecondary>{String(validationError)}</SmallTextSecondary>
					)}
					{canRemove && (
						<SmallTextSecondary
							className="dzu-previewButton"
							style={iconByFn.remove}
							onClick={remove}
						/>
					)}
				</div>
			);
		}

		if (
			status === 'error_upload_params' ||
			status === 'exception_upload' ||
			status === 'error_upload'
		) {
			title = `${title} (upload failed)`;
		}
		if (status === 'aborted') title = `${title} (cancelled)`;

		return (
			<div className={className} style={style}>
				{previewUrl && (
					<img
						className={imageClassName}
						style={imageStyle}
						src={previewUrl}
						alt={title}
						title={title}
					/>
				)}
				{!previewUrl && (
					<SmallTextSecondary className="dzu-previewFileName">
						{title}
					</SmallTextSecondary>
				)}

				<div className="dzu-previewStatusContainer">
					{isUpload && percent < 100 && (
						<progress
							id="fileProgress"
							max={100}
							value={
								status === 'done' || status === 'headers_received'
									? 100
									: percent
							}
						/>
					)}
					{isUpload && percent === 100 && (
						<SmallTextSecondary style={{ paddingRight: '20px' }}>
							Uploaded
						</SmallTextSecondary>
					)}

					{status === 'uploading' && canCancel && (
						<SmallTextSecondary
							className="dzu-previewButton"
							style={iconByFn.cancel}
							onClick={cancel}
						/>
					)}
					{status !== 'preparing' &&
						status !== 'getting_upload_params' &&
						status !== 'uploading' &&
						canRemove && (
							<SmallTextSecondary
								className="dzu-previewButton"
								style={iconByFn.remove}
								onClick={remove}
							/>
						)}
					{[
						'error_upload_params',
						'exception_upload',
						'error_upload',
						'aborted',
						'ready',
					].includes(status) &&
						canRestart && (
							<SmallTextSecondary
								className="dzu-previewButton"
								style={iconByFn.restart}
								onClick={restart}
							/>
						)}
				</div>
			</div>
		);
	}
}

export default FilePreview;
