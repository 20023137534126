import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { H3, ParagraphGrey } from '../../styles/StylesV3Main';
import { useProductContext } from '../../contexts/productContext';
import { IoGlobe } from 'react-icons/io5';
import AddOnProductCard from './ManageAddOn/AddonProductCard';
import Loading from '../utilities/Loading';
import { Link } from 'react-router-dom';

const StyledLoading = ({ height }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: height ? height : '50vh',
				width: '100%',
				textAlign: 'center',
			}}
		>
			<Loading />
		</div>
	);
};

const EmptyState = styled.div`
	width: 100%;
	height: 400px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const AboutText = styled(ParagraphGrey)`
	margin-right: 45%;
	@media (max-width: 768px) {
		margin-right: 10%;
	}
`;

const Text = styled(ParagraphGrey)`
	width: 100%;
	max-width: 368px;
	@media (max-width: 768px) {
		max-width: 100%;
		padding: 0% 10%;
	}
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 40px 80px;

	@media (max-width: 1250px) {
		padding: 0px;
	}
`;

const AddonContainer = styled.div`
	width: 100%;
	max-width: 1250px;
	margin: 0px 135px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 1250px) {
		padding: 50px;
		margin: 0px;
	}
	@media (max-width: 1250px) {
		padding: 50px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 30px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
const ProductContainer = styled.div`
	width: 100%;
	max-width: 1250px;
	display: flex;
	justify-content: start;
	align-items: center;

	@media (max-width: 992px) {
		width: 100%;
		max-width: 100%;
		justify-content: start;
		margin-right: 0px;
	}
`;

const DesktopRow = styled(Row)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: auto;
	& > a {
		/*
    1/3  - 3 columns per row
    10px - spacing between columns 
  */
		box-sizing: border-box;
		margin: 0px 16px 16px 0px;
		width: ${({ length }) =>
			length >= 3 && `calc(1 / 3 * 100% - (1 - 1 / 3) * 16px);`};
	}
	& > a:nth-child(3n) {
		margin-right: 0;
	}

	&::after {
		content: '';
		flex: auto;
	}

	@media (min-width: 1250px) {
		width: 1250px;
	}
	@media (max-width: 1250px) {
		width: 100%;
		margin: 0px 10px 10px 0px;
		justify-content: start;
	}
`;

const DesktopView = styled.div`
	display: block;
	@media (max-width: 992px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;

	@media (max-width: 992px) {
		display: block;
	}
`;

const ManageProductAddon = ({ team }) => {
	const { loading, allProducts } = useProductContext();
	const [productsOwners, setProductsOwners] = useState();

	useEffect(() => {
		try {
			if (!loading) {
				const groupProducts = allProducts
					.filter(
						(item) =>
							item.productTypes.filter(
								(itemService) => itemService.groupBooking
							).length > 0
					)
					.filter((item) => !item.customProduct)
					.filter((item) => item.active)
					.filter((item) => item.productTypes.length > 0);

				setProductsOwners(groupProducts);
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [loading, allProducts]);

	if (loading) {
		return <StyledLoading height="80vh" />;
	}
	return (
		<Wrapper>
			<AddonContainer>
				<ProductContainer>
					<Row
						noGutters
						className="justify-content-start"
						style={{ width: '100%' }}
					>
						<Col className="px-0 py-0">
							<H3 className="mb-2 ">About add-ons</H3>
							<AboutText className="mb-5 justify-content-start">
								Juno add-ons are here to power up your team. These are
								activities or products that you can request for team building
								online and in person.
							</AboutText>
						</Col>
					</Row>
				</ProductContainer>
				<ProductContainer>
					<Row noGutters className="justify-content-start align-items-start">
						<Col xs={12} className="px-0 py-0">
							<Row noGutters>
								<DesktopView>
									<DesktopRow
										noGutters
										length={productsOwners && productsOwners.length}
									>
										{productsOwners &&
											productsOwners.length > 0 &&
											productsOwners.map((item, index) => (
												<Link
													className="px-0 py-0"
													to={`/product/${item.id}`}
													style={{
														textDecoration: 'none',
													}}
													key={index}
													title={'addonproduct'}
												>
													<AddOnProductCard product={item} />
												</Link>
											))}
									</DesktopRow>
								</DesktopView>
								<MobileView>
									<Row noGutters>
										{productsOwners &&
											productsOwners.length > 0 &&
											productsOwners.map((item, index) => (
												<Col xs={12} md={12} key={index} className="px-0 py-0">
													<Link
														to={`/product/${item.id}`}
														key={index}
														style={{
															textDecoration: 'none',
														}}
													>
														<AddOnProductCard product={item} />
													</Link>
												</Col>
											))}
									</Row>
								</MobileView>
							</Row>
						</Col>
					</Row>
				</ProductContainer>
				{productsOwners && productsOwners.length === 0 && (
					<EmptyState>
						<IoGlobe size={105} color={'rgba(0,0,0,0.1)'} className="mb-4" />
						<Text className="text-center">
							We don’t have any products or experiences for your location just
							yet. We will try to add them as soon as possible.
						</Text>
					</EmptyState>
				)}
			</AddonContainer>
		</Wrapper>
	);
};

export default ManageProductAddon;
