import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { MdFilterList } from 'react-icons/md';
import CategoryFilter from './CategoryFilter';
import CostSlider from './CostFilter';
import Button from '../Button/Button';
import Menu from '../Menu';

type FilterFormProps = {
	open: boolean;
	applied: {
		range: [number, number] | null;
		categories: { [key: number]: boolean } | null;
	};
	maxValue: number;
	showCategories: boolean;
	applyFilters: (
		value: [number, number],
		categories: { [key: number]: boolean },
		cleared: boolean
	) => void;
	setOpen: (open: boolean) => void;
};

const FilterForm = ({
	open,
	applied,
	maxValue,
	showCategories,
	applyFilters,
	setOpen,
}: FilterFormProps) => {
	const [value, setValue] = useState([0, maxValue] as [
		number | string,
		number | string
	]);
	const [categories, setCategories] = useState<{ [key: number]: boolean }>({});
	useEffect(() => {
		if (open) return;
		setValue(
			applied.range
				? [
						Math.min(applied.range[0], maxValue),
						Math.min(applied.range[1], maxValue),
				  ]
				: [0, maxValue]
		);
		setCategories(applied.categories ? applied.categories : {});
	}, [applied, maxValue, open]);
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				applyFilters([+value[0], +value[1]], categories, false);
				setOpen(false);
			}}
		>
			<CostSlider value={value} setValue={setValue} maxValue={maxValue} />
			<VerticalDivider />
			{showCategories && (
				<>
					<CategoryFilter
						categories={categories}
						setCategories={setCategories}
					/>
					<VerticalDivider />
				</>
			)}
			<ButtonContainer>
				<ClearButton
					type="reset"
					onClick={() => {
						setCategories({});
						setValue([0, maxValue]);
						applyFilters([0, maxValue], {}, true);
						setOpen(false);
					}}
				>
					Clear filters
				</ClearButton>
				<ApplyButton type="submit">Apply</ApplyButton>
			</ButtonContainer>
		</form>
	);
};

type FilterProps = {
	maxValue: number;
	showCategories: boolean;
	orderBy: 'relevance' | 'newest' | 'dearest' | 'cheapest' | 'alphabetical';
	setOrderBy: (orderBy: string) => {};
	applyFilters: (
		value: [number, number],
		categories: { [key: number]: boolean }
	) => void;
};

const Filter = ({
	maxValue,
	showCategories,
	orderBy,
	applyFilters,
	setOrderBy,
}: FilterProps) => {
	const [open, setOpen] = useState(false);
	const [applied, setApplied] = useState<{
		range: [number, number] | null;
		categories: { [key: number]: boolean } | null;
	}>({ range: null, categories: null });
	const menu = useRef<HTMLDialogElement>(null);
	const filterButton = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const close = ({ type, key, target }: any) => {
			if (
				(type === 'keydown' && key === 'Escape') ||
				(type === 'mousedown' && !menu.current?.parentNode?.contains(target)) ||
				type === 'resize' ||
				type === 'close'
			) {
				setOpen(false);
			}
		};
		document.addEventListener('mousedown', close);
		document.addEventListener('keydown', close);
		window.addEventListener('resize', close);
		menu.current?.addEventListener('close', close);
		return () => {
			document.removeEventListener('mousedown', close);
			document.removeEventListener('keydown', close);
			window.removeEventListener('resize', close);
			menu.current?.removeEventListener('close', close);
		};
	});
	useEffect(() => {
		if (open) menu.current?.show();
		else menu.current?.close();
	}, [open]);
	let top = filterButton.current?.offsetHeight || 0;
	let left = 0;
	let parent: HTMLElement = filterButton.current!;
	while (parent && getComputedStyle(parent).position !== 'absolute') {
		top += parent.offsetTop;
		left += parent.offsetLeft;
		parent = parent.offsetParent as HTMLElement;
	}
	return (
		<>
			{typeof HTMLDialogElement === 'function' && (
				<div>
					<Button
						ref={filterButton}
						buttonType={
							applied.range || applied.categories ? 'primary' : 'secondary'
						}
						state="default"
						size="small"
						type="button"
						icon={MdFilterList}
						onClick={() => {
							setOpen(!open);
						}}
					>
						{`Filters${applied.range || applied.categories ? ' (active)' : ''}`}
					</Button>
					<StyledFilterContainer
						ref={menu}
						style={{
							top,
							left: Math.max(left - 186, 0) || left,
							marginTop: 8,
						}}
					>
						<FilterForm
							open={open}
							applied={applied}
							maxValue={maxValue}
							showCategories={showCategories}
							applyFilters={(
								range: [number, number],
								categories: { [key: number]: boolean },
								clear: boolean
							) => {
								setApplied({
									range:
										!clear && (range[0] !== 0 || range[1] !== maxValue)
											? range
											: null,
									categories:
										!clear && !!Object.keys(categories).length
											? categories
											: null,
								});
								applyFilters(range, categories);
							}}
							setOpen={setOpen}
						/>
					</StyledFilterContainer>
				</div>
			)}
			<div style={{ marginLeft: 16 }}>
				<Menu
					text="Sort By:"
					selected={orderBy}
					setSelected={setOrderBy}
					options={[
						{
							key: 'relevance',
							value: 'Relevance',
						},
						{
							key: 'newest',
							value: 'Newest First',
						},
						{
							key: 'dearest',
							value: 'Cost - High to Low',
						},
						{
							key: 'cheapest',
							value: 'Cost - Low to High',
						},
						{
							key: 'alphabetical',
							value: 'A to Z',
						},
					]}
				/>
			</div>
		</>
	);
};

const StyledFilterContainer = styled.dialog`
	position: absolute;
	z-index: 1010;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	width: 280px;
	background: #fff;
	margin: unset;
	border: none;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		border-radius: 3px;
	}
`;

const VerticalDivider = styled.div`
	width: 100%;
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	margin: 16px 0px;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const ClearButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: none;
	background: transparent;
	font-family: 'Roobert Regular';
	font-size: 14px;
	line-height: 160%;
	color: #191a1c;
`;

const ApplyButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 54px;
	height: 30px;
	background: #191a1c;
	border-radius: 3px;
	border: none;
	font-family: 'Roobert Bold';
	font-size: 14px;
	line-height: 160%;
	color: #fff;
`;

export default Filter;
