/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import BankDetailsFromCurrency, {
	BookingFormSelect,
} from './BankDetailsFromCurrency';
import { H1 } from '../../styles/StylesV3Main';
import currenciesList from './currencies.json';
import { bankTypes, bankTypeOptions } from './bankTypes';
import { useClient, useCurrentTeam, useCurrency } from '../../hooks';
import RecieptUploader from './ReceiptUploader';
import { useUserContext } from '../../contexts/userContext';
import config from '../../config';
import { ExpenseCategories } from './categories';
import Button from '../../styles/shared/Button/Button';

const InsufficientWarning = styled.div`
	padding: 16px;
	width: 100%;
	background: #fae1e7;
	font-family: 'TTInterfaces Medium';
	font-size: 14px;
	line-height: 160%;
	text-align: center;
	color: #cc1440;
	border-radius: 10px;
`;

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	margin-top: 8px;
	font-size: 12px;
	line-height: 14px;
	font-family: 'TTInterfaces Regular';
	color: #cc1440;
`;

const FullRow = styled(Row)`
	padding: 0px;
	margin: 0px !important;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 14px;

	input {
		width: 100%;
		padding: 15px 18px;
		height: 50px;
		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	textarea {
		width: 100%;
		padding: 15px 18px;
		height: 100px;
		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	label:first-of-type {
		font-family: 'TTInterfaces Demibold';
		font-size: 16px;
		line-height: 20px;
		margin: 0;
	}

	label:nth-of-type(2) {
		font-family: 'TTInterfaces Medium';
		font-size: 14px;
		line-height: 20px;
		margin: 0;
		color: rgba(0, 0, 0, 0.35);
	}

	div {
		border-radius: 10px;
		input {
			opacity: 0;
			height: 0;
			width: 0;
			padding: 0;
			left: 50%;
			position: absolute;
		}
	}
	> div {
		width: 100%;
		background: transparent;

		> div {
			> div {
				background: #fff;
			}
		}
	}
`;

const FormContainer = styled.div`
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 35px;
	}
`;

const StyledInput = styled.input`
	font-family: 'TTInterfaces Regular';
	width: 100%;
	padding: 15px 18px;
	height: 50px;
	border: 1.5px solid #e4e4e4;
	border-radius: 10px;
`;

const REIMBURSEMENT_CURRENCY_KEY = 'REIMBURSEMENT_CURRENCY';

const bankTypeMap = {
	GBP: 'UK Bank Account',
	USD: 'US Bank Account',
	CAD: 'Canadian Bank Account',
	EUR: 'IBAN Bank Account',
};

const CreateReimbursementForm = ({
	setBooking,
	selectedBudgetId,
	maxFiles,
}: {
	setBooking: (booking) => any,
	selectedBudgetId: number | undefined,
	maxFiles: number | undefined,
}) => {
	const client = useClient();
	const { userData, updateUserData } = useUserContext();
	const formikRef = useRef(null);

	const {
		teamMember,
		currency: teamCurrency = 'gbp',
		fees = true,
	} = useCurrentTeam();
	const currencies = useCurrency();

	const [exchangeRates, setExchangeRates] = useState(null);

	const [files, setFiles] = useState({
		ready: [],
		all: [],
	});

	useEffect(async () => {
		const { data: rates } = await client.get(
			`${config.platform.HOST}/payments/authorized/reimbursements/exchange-rates`
		);
		setExchangeRates(rates);
	}, []);

	const submitBooking = async ({
		item,
		amount,
		currency,
		firstName,
		lastName,
		category,
		bankType,
		email,
		iban,
		accountNumber,
		sortCode,
		institutionNumber,
		transitNumber,
		routingNumber,
		accountType: checking,
		line1,
		line2,
		city,
		state,
		zip,
	}) => {
		const accountType = {
			[bankTypes.UK]: 'UK',
			[bankTypes.US]: 'US',
			[bankTypes.Canada]: 'CAD',
			[bankTypes.IBAN]: 'IBAN',
			[bankTypes.Other]: 'OTHER',
		};
		try {
			const url = selectedBudgetId
				? `${config.platform.HOST}/payments/authorized/reimbursements/budgets/${selectedBudgetId}`
				: `${config.platform.HOST}/payments/authorized/reimbursements/${
						userData.currentTeam ? userData.currentTeam : ''
				  }`;
			const { data: booking } = await client.post(url, {
				item,
				amount: +amount,
				currency,
				firstName,
				lastName,
				category,
				receipts: files.ready.map(({ meta: { name, type, key } }) => ({
					fileName: name,
					fileType: type,
					key,
				})),
				bankDetails: {
					accountType: accountType[bankType],
					iban,
					accountNumber,
					sortCode,
					institutionNumber,
					transitNumber,
					routingNumber,
					checking: checking !== 'SAVING',
					email: bankType === 'Other' && email.length ? email : undefined,
					addressLine1: line1,
					addressLine2: line2,
					city,
					state,
					zip,
				},
			});
			updateUserData();

			const costData = getCost(+amount, currency);
			setBooking({
				...booking,
				options: {
					[booking.productTypeId]: {
						values: booking.bookingData,
					},
				},
				cost: costData?.cost,
				fee: costData?.fee,
				enquiry: false,
			});
			window.scrollTo(0, 0);
		} catch (e) {
			console.error(e);
		}
	};

	const getCost = (amount = 0, currency = 'gbp') => {
		if (!exchangeRates) {
			return {
				hasEnough: false,
			};
		}
		let unroundedCost =
			(currency === teamCurrency.toUpperCase()
				? 1
				: exchangeRates[currency][teamCurrency.toUpperCase()]) *
			amount *
			(selectedBudgetId ? 100 : currencies[teamCurrency]);
		unroundedCost =
			currency === teamCurrency.toUpperCase() && selectedBudgetId
				? Math.round(unroundedCost)
				: unroundedCost;
		const cost = Math.ceil(unroundedCost);
		const totalCost = Math.ceil(unroundedCost * (!fees ? 1 : 1.01));
		const fee = totalCost - cost;
		let available = 0;
		if (!selectedBudgetId) {
			available = (userData?.points || 0) + (teamMember?.points || 0);
		} else {
			const budgetData = userData.teams
				.find(
					({ id, teamMember: tm }) =>
						id === userData.currentTeam && tm.subStatus === 'active'
				)
				.budgets.find(({ id }) => id === +selectedBudgetId)
				.budgetMembers.find(({ userId }) => userId === userData.id);
			available = budgetData.points;
		}
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: teamCurrency,
		});
		return {
			cost,
			fee,
			totalCost,
			formattedTotalCost: selectedBudgetId
				? formatter.format(totalCost / 100)
				: `${totalCost} points`,
			formattedFee: selectedBudgetId
				? formatter.format(fee / 100)
				: `${fee} points`,
			hasEnough:
				Math.round(available * (!selectedBudgetId ? 1 : 100)) >= cost + fee,
		};
	};

	const validateBankDetails = async (bank, field, value, extraFields) => {
		let body = {
			accountType: bank,
			[field]: value,
		};

		if (extraFields?.length > 0) {
			const extendBodyParams = {};
			extraFields.forEach((extraField) => {
				extendBodyParams[extraField] = document.querySelector(
					`[name="${extraField}"]`
				)?.value;
			});
			body = { ...body, ...extendBodyParams };
		}

		const response = await client.post(
			`${config.platform.HOST}/payments/authorized/reimbursements/validate`,
			body
		);

		if (response.data.validation === 'success') {
			return true;
		}
		return false;
	};

	const validationSchema = Yup.object({
		item: Yup.string()
			.required('This field is required.')
			.typeError('This field is required.'),
		bankType: Yup.string()
			.required('This field is required.')
			.typeError('This field is required.'),
		category: !selectedBudgetId
			? Yup.string()
					.required('This field is required.')
					.typeError('This field is required.')
			: undefined,
		firstName: Yup.string()
			.required('First name is required.')
			.typeError('First name is required.'),
		lastName: Yup.string()
			.required('Last name is required.')
			.typeError('Last name is required.'),
		currency: Yup.string()
			.required('Currency is required.')
			.typeError('Currency is required.'),
		amount: Yup.number()
			.min(1)
			.required('Amount is required and must be more than 0.')
			.typeError('Amount must be a number.'),
		accountNumber: Yup.string()
			.when('bankType', {
				is: (val) =>
					[bankTypes.US, bankTypes.UK, bankTypes.Canada].includes(val),
				then: Yup.string().required('Account Number is required.'),
			})
			.when('bankType', {
				is: (val) => val === bankTypes.UK,
				then: Yup.string()
					.min(8, 'Must be exactly 8 digits')
					.max(8, 'Must be exactly 8 digits')
					.test(
						'Valid Account Number',
						'Must be a valid Account number.',
						(value) => {
							return validateBankDetails('UK', 'accountNumber', value);
						}
					),
			})
			.when('bankType', {
				is: (val) => val === bankTypes.US,
				then: Yup.string().test(
					'Valid Account Number',
					'Must be a valid Account number.',
					(value) => {
						return validateBankDetails('US', 'accountNumber', value);
					}
				),
			})
			.when('bankType', {
				is: (val) => val === bankTypes.Canada,
				then: Yup.string().test(
					'Valid Account Number',
					'Must be a valid Account number.',
					(value) => {
						return validateBankDetails('CAD', 'accountNumber', value, [
							'institutionNumber',
							'transitNumber',
						]);
					}
				),
			}),
		routingNumber: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.US,
			then: Yup.string()
				.required('Routing Number is required.')
				.test(
					'Valid Routing Number',
					'Must be a valid Routing number.',
					(value) => {
						return validateBankDetails('US', 'routingNumber', value);
					}
				),
		}),
		line1: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.US,
			then: Yup.string().required('Line 1 is required.'),
		}),
		line2: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.US,
			then: Yup.string().optional(),
		}),
		city: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.US,
			then: Yup.string().required('City is required.'),
		}),
		zip: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.US,
			then: Yup.string().required('Zip Code is required.'),
		}),
		sortCode: Yup.string()
			.when('bankType', {
				is: (val) => val === bankTypes.UK,
				then: Yup.string().required('Sort Code is required.'),
			})
			.when('bankType', {
				is: (val) => val === bankTypes.UK,
				then: Yup.string()
					.min(6, 'Must be exactly 6 digits')
					.max(6, 'Must be exactly 6 digits')
					.test('Valid Sort Code', 'Must be a valid Sort Code.', (value) => {
						return validateBankDetails('UK', 'sortCode', value);
					}),
			}),
		iban: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.IBAN,
			then: Yup.string()
				.required('IBAN is required.')
				.test('Valid IBAN', 'Must be a valid IBAN.', (value) => {
					return validateBankDetails('IBAN', 'iban', value);
				}),
		}),
		institutionNumber: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.Canada,
			then: Yup.string()
				.required('Institution Number is required.')
				.test(
					'Valid Insitution Number',
					'Must be a valid Institution number.',
					(value) => {
						return validateBankDetails('CAD', 'institutionNumber', value);
					}
				),
		}),
		transitNumber: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.Canada,
			then: Yup.string()
				.required('Transit Number is required.')
				.test(
					'Valid Transit Number',
					'Must be a valid Transit number.',
					(value) => {
						return validateBankDetails('CAD', 'transitNumber', value, [
							'institutionNumber',
						]);
					}
				),
		}),
		email: Yup.string().when('bankType', {
			is: (val) => val === bankTypes.Other,
			then: Yup.string().email().required('Email is required.'),
		}),
	});

	return (
		<FormContainer>
			<Formik
				innerRef={formikRef}
				validationSchema={validationSchema}
				initialValues={{
					currency: localStorage.getItem(REIMBURSEMENT_CURRENCY_KEY) || 'GBP',
					firstName: userData?.firstName || '',
					lastName: userData?.lastName || '',
					accountNumber: '',
					routingNumber: '',
					line1: '',
					line2: '',
					city: '',
					zip: '',
					state: 'AL',
					sortCode: '',
					iban: '',
					institutionNumber: '',
					transitNumber: '',
					email: '',
				}}
				onSubmit={(values) => {
					submitBooking(values);
				}}
			>
				{({
					values,
					isSubmitting,
					isValidating,
					isValid,
					dirty,
					setFieldValue,
				}) => {
					const costData = getCost(values.amount, values.currency);
					return (
						<Form autoComplete="off">
							<InputContainer style={{ paddingTop: '15px' }}>
								<label htmlFor="receiptLoader">Upload your receipts</label>
								<RecieptUploader
									id="receiptLoader"
									orderId="12345"
									setFiles={setFiles}
									disabled={false}
									maxFiles={maxFiles}
								/>
								<label>
									Make sure the receipt is a clear scan, picture or file with a
									logo of the vendor and the amount. We only accept JPG, PNG or
									PDF.
								</label>
							</InputContainer>
							{!selectedBudgetId && (
								<InputContainer>
									<label htmlFor="category">Select a category</label>
									<Field
										name="category"
										as={BookingFormSelect}
										options={ExpenseCategories}
										value={values.category}
										defaultValue="PLACEHOLDER"
										onChange={(e) => {
											setFieldValue('category', e.target.value);
										}}
										required
									/>
									<ErrorMessage
										name="category"
										component={StyledErrorMessage}
									/>
								</InputContainer>
							)}
							<InputContainer>
								<label htmlFor="expenseItem">What are you expensing?</label>
								<Field
									id="expenseItem"
									type="text"
									name="item"
									as={StyledInput}
									value={values.item}
									onChange={(e) => {
										setFieldValue('item', e.target.value);
									}}
								/>
								<ErrorMessage name="item" component={StyledErrorMessage} />
							</InputContainer>
							<InputContainer>
								<label htmlFor="currency">Currency</label>
								<Field
									name="currency"
									id="currency"
									as={BookingFormSelect}
									options={currenciesList}
									value={values.currency}
									onChange={(e) => {
										setFieldValue('currency', e.target.value);
										localStorage.setItem(
											REIMBURSEMENT_CURRENCY_KEY,
											e.target.value
										);
										setFieldValue(
											'bankType',
											bankTypeMap[e.target.value] || 'Other'
										);
									}}
								/>
								<ErrorMessage name="currency" component={StyledErrorMessage} />
							</InputContainer>
							<InputContainer>
								<label htmlFor="amount">Amount</label>
								<Field
									type="number"
									id="amount"
									name="amount"
									as={StyledInput}
									value={values.amount}
									onWheel={(e) => e.target.blur()}
									onChange={(e) => {
										setFieldValue('amount', e.target.value);
									}}
								/>
								<ErrorMessage name="amount" component={StyledErrorMessage} />
							</InputContainer>
							<InputContainer>
								<label htmlFor="firstName">First name</label>
								<Field
									type="text"
									name="firstName"
									id="firstName"
									as={StyledInput}
									value={values.firstName}
									onChange={(e) => {
										setFieldValue('firstName', e.target.value);
									}}
								/>
								<ErrorMessage name="firstName" component={StyledErrorMessage} />
							</InputContainer>
							<InputContainer>
								<label htmlFor="lastName">Last name</label>
								<Field
									type="text"
									id="lastName"
									name="lastName"
									as={StyledInput}
									value={values.lastName}
									onChange={(e) => {
										setFieldValue('lastName', e.target.value);
									}}
								/>
								<ErrorMessage name="lastName" component={StyledErrorMessage} />
							</InputContainer>
							<InputContainer>
								<label htmlFor="bankType">Bank details</label>
								<Field
									name="bankType"
									id="bankType"
									as={BookingFormSelect}
									options={bankTypeOptions}
									value={values.bankType}
									defaultValue="PLACEHOLDER"
									onChange={(e) => {
										setFieldValue('bankType', e.target.value);
									}}
								/>
								<ErrorMessage name="bankType" component={StyledErrorMessage} />
							</InputContainer>
							<BankDetailsFromCurrency
								currencyCode={values.bankType}
								values={values}
								setFieldValue={setFieldValue}
							/>
							{exchangeRates && values.amount && (
								<>
									{costData?.fee > 0 && (
										<div
											style={{
												justifyContent: 'space-between',
												paddingTop: '10px',
												fontFamily: 'TTInterfaces DemiBold',
											}}
										>
											<h6>Fee (only for completed expenses)</h6>
											<h4>{costData.formattedFee}</h4>
										</div>
									)}
									<FullRow
										style={{
											justifyContent: 'space-between',
											paddingTop: '20px',
										}}
									>
										<H1>Total:{` ${costData.formattedTotalCost}`}</H1>
									</FullRow>
								</>
							)}
							{exchangeRates && !costData.hasEnough && (
								<FullRow
									style={{
										paddingTop: '20px',
									}}
								>
									{selectedBudgetId ? (
										<InsufficientWarning>
											You do not have enough money in your budget to request
											this expense. You could do an expense for a portion of the
											total amount.
										</InsufficientWarning>
									) : (
										<InsufficientWarning>
											You do not have enough points for this transaction. You
											could do an expense for a portion of the total amount.
										</InsufficientWarning>
									)}
								</FullRow>
							)}
							<InputContainer>
								<Button
									type="submit"
									size="small"
									state={
										teamMember?.status === 'suspended' ||
										!files.ready.length ||
										!costData.hasEnough ||
										!exchangeRates ||
										isSubmitting ||
										isValidating ||
										!(isValid && dirty)
											? 'disabled'
											: 'default'
									}
									buttonType="primary"
								>
									Request expense
								</Button>
							</InputContainer>
						</Form>
					);
				}}
			</Formik>
		</FormContainer>
	);
};

export default CreateReimbursementForm;
