import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { convertFromRaw, EditorState } from 'draft-js';

const StyledBoxContainer = styled.li<{ selected: boolean }>`
	border-radius: 12px;
	border: 2px solid ${(props) => (props.selected ? '#4ca30d' : '#EAECF0')}; // 4ca30d
	background: ${(props) => (props.selected ? '#f3fee7' : '#FFFFFF')};
	width: 100%;
	padding: 16px;
	display: flex;
	align-items: flex-start;
	gap: 16px;
`;

const BudgetTitle = styled.label<{ selected: boolean }>`
	font-family: 'TTInterfaces DemiBold';
	color: ${(props) => (props.selected ? '#4ca30d' : '#344054')};
	font-size: 15px;
	line-height: 20px;
	display: flex;
	align-items: center;
`;

const BudgetPolicy = styled.div<{ selected: boolean }>`
	font-family: 'TTInterfaces Regular';
	color: ${(props) => (props.selected ? '#4ca30d' : '#475467')};
	font-size: 14px;
	line-height: 20px;
	height: 45px;
	padding-top: 6px;
	display: -webkit-box;
	display: -moz-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Checkbox = styled.input`
	margin-top: 4px;
	accent-color: #4ca30d;
	vertical-align: middle;
`;

type StyledBoxProps = {
	budget: any;
	onToggle: (budgetId: number) => void;
	currency: string;
	selected: boolean;
	invalid: boolean;
};

const BudgetDetail = styled.div`
	display: flex;
	justify-content: space-between; /* evenly space the children */
`;

const BudgetDetailHeader = styled.div`
	font-family: 'TTInterfaces DemiBold';
	color: #475467;
	font-size: 12px;
	line-height: 20px;
	width: 160px; /* width set as in original table */
`;

const BudgetDetailValue = styled.div`
	font-family: 'TTInterfaces Regular';
	color: #475467;
	font-size: 12px;
	line-height: 20px;
`;

const BudgetCard: React.FC<StyledBoxProps> = ({
	budget,
	onToggle,
	currency,
	selected,
	invalid,
}) => {
	const chkRef = useRef<HTMLInputElement>(null);
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency || 'gbp',
	});

	useEffect(() => {
		chkRef.current?.setCustomValidity(
			invalid ? 'Please select at least 1 budgget' : ''
		);
	}, [invalid]);

	return (
		<StyledBoxContainer selected={selected}>
			<Checkbox
				ref={chkRef}
				id={budget.id.toString()}
				type="checkbox"
				checked={selected}
				required={invalid}
				onChange={() => {
					onToggle(budget.id);
				}}
			/>
			<div style={{ width: '100%' }}>
				<BudgetTitle htmlFor={budget.id.toString()} selected={selected}>
					{budget.name}
				</BudgetTitle>
				<BudgetPolicy selected={selected}>
					{EditorState.createWithContent(convertFromRaw(budget.policy))
						.getCurrentContent()
						.getPlainText('\u0001')}
				</BudgetPolicy>
				<hr />
				<BudgetDetail>
					<BudgetDetailHeader>Amount</BudgetDetailHeader>
					<BudgetDetailValue>
						{formatter.format(budget.amount)}
					</BudgetDetailValue>
				</BudgetDetail>

				<BudgetDetail>
					<BudgetDetailHeader>Cycle</BudgetDetailHeader>
					<BudgetDetailValue>{budget.renewalPeriod}</BudgetDetailValue>
				</BudgetDetail>
			</div>
		</StyledBoxContainer>
	);
};

export default BudgetCard;
