import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import styled from 'styled-components';
import { DataInput } from '../../styles/StylesV2';
import { Table, Row, Col, Button } from 'react-bootstrap';
import { PSansText, PSansMediumText } from '../../styles/StylesV2';
import {
	BiChevronRight,
	BiChevronLeft,
	BiChevronsRight,
	BiChevronsLeft,
} from 'react-icons/bi';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import moment from 'moment';

const Circle = styled.span`
	width: 32px;
	height: 32px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-sizing: border-box;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover,
	&:focus {
		background: #000;
		color: white;
	}
`;

const StyledSelect = styled.select`
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	outline: 0;
	border: none;
	width: 90%;
	height: 50px;
	cursor: pointer;
	padding: 0px 10px 0px 10px;

	&:focus {
		outline: 0;
	}
`;

const DataTable = ({
	columns,
	data,
	defaultFilters,
	defaultSorts,
	defaultPageSize,
	TR,
	TD,
	TH,
	filtersActive,
}) => {
	// Use the state and functions returned from useTable to build your UI
	const filterTypes = React.useMemo(
		() => ({
			// Or, override the default text filter to use
			// "startWith"
			filterGreaterThan: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue >= filterValue;
				});
			},
			filterGreaterThanPlan: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue ? rowValue.split(' ')[0] * 1 >= filterValue : false;
				});
			},
			select: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue ? rowValue === filterValue : false;
				});
			},
			multiselect: (rows, id, filterValue) => {
				return filterValue.length > 0
					? rows.filter((row) => {
							const rowValue = row.values[id];
							return (
								filterValue.filter((item) => item.value === rowValue).length > 0
							);
					  })
					: rows;
			},
			datefilter: (rows, id, filterValue) => {
				return filterValue
					? rows.filter((row) => {
							const rowValue = row.values[id];
							return rowValue === moment(filterValue.date).format('YYYY-MM-DD');
					  })
					: rows;
			},
			daterangefilter: (rows, id, filterValue) => {
				const after =
					filterValue && filterValue.after
						? rows.filter((row) => {
								const rowValue = row.values[id];
								return moment(rowValue).isAfter(moment(filterValue.after.date));
						  })
						: rows;
				return filterValue && filterValue.before
					? after.filter((row) => {
							const rowValue = row.values[id];
							return moment(rowValue).isBefore(moment(filterValue.before.date));
					  })
					: after;
			},
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: <></>,
		}),
		[]
	);

	const initialFilters = React.useMemo(
		() => (defaultFilters ? defaultFilters : []),
		[defaultFilters]
	);

	const [tableState, setTableState] = useState({
		sortBy: defaultSorts || [],
		pageSize: defaultPageSize || 10,
		pageIndex: 0,
	});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setAllFilters,
		// Get the state from the instance
		state: { pageIndex, pageSize, sortBy, filters },
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: {
				filters: [],
				sortBy: tableState.sortBy,
				pageSize: tableState.pageSize,
				pageIndex: tableState.pageIndex,
			},
		},
		useFilters, // useFilters!
		useSortBy,
		usePagination
	);

	useEffect(() => {
		setTableState({
			sortBy,
			pageIndex: data.length > pageIndex * pageSize ? pageIndex : 0,
			pageSize,
		});
	}, [sortBy, pageIndex, pageSize, data]);

	useEffect(() => {
		setAllFilters(initialFilters);
	}, [initialFilters, setAllFilters]);

	// Render the UI for your table
	return (
		<Row noGutters style={{ width: '100%' }}>
			<Col lg={12}>
				<div style={{ overflowX: 'scroll' }}>
					<Table {...getTableProps()} responsive borderless>
						<thead>
							{headerGroups.map((headerGroup) => (
								<TR {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<TH
											{...column}
											{...column.getHeaderProps(column.getSortByToggleProps())}
										>
											<span>
												<label>{column.render('Header')} </label>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<FaChevronDown className="ml-2" />
													) : (
														<FaChevronUp className="ml-2" />
													)
												) : (
													''
												)}
											</span>
											{filtersActive && (
												<div className="mt-2">
													{column.canFilter ? column.render('Filter') : null}
												</div>
											)}
										</TH>
									))}
								</TR>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<TR {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return (
												<TD {...cell.column} {...cell.getCellProps()}>
													{cell.render('Cell')}
												</TD>
											);
										})}
									</TR>
								);
							})}
						</tbody>
					</Table>
					<Row
						noGutters
						className="justify-content-between align-items-end mb-2"
					>
						<PSansMediumText className="mx-2">
							Showing {page.length} of {data.length} results
						</PSansMediumText>
						{filters.length > 0 && (
							<Button
								variant="info"
								onClick={() => {
									setAllFilters([]);
								}}
							>
								Clear filters
							</Button>
						)}
					</Row>
					<Row
						noGutters
						className="justify-content-between align-items-end"
						style={{}}
					>
						<Row noGutters>
							<PSansText className="mx-2">
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{' '}
							</PSansText>
							<PSansMediumText className="mx-2">
								| &nbsp; Go to page:{' '}
								<DataInput
									type="number"
									defaultValue={pageIndex + 1}
									onChange={(e) => {
										const page = e.target.value
											? Number(e.target.value) - 1
											: 0;
										gotoPage(page);
									}}
									style={{ width: '30px' }}
								/>
							</PSansMediumText>
						</Row>
						<Row noGutters>
							<Circle
								className="mx-2"
								onClick={() => gotoPage(0)}
								disabled={!canPreviousPage}
							>
								<BiChevronsLeft />
							</Circle>
							<Circle
								className="mx-2"
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
							>
								<BiChevronLeft />
							</Circle>
							<Circle
								className="mx-2"
								onClick={() => nextPage()}
								disabled={!canNextPage}
							>
								<BiChevronRight />
							</Circle>

							<Circle
								className="mx-2"
								onClick={() => gotoPage(pageCount - 1)}
								disabled={!canNextPage}
							>
								<BiChevronsRight />
							</Circle>
						</Row>
						<Row noGutters>
							<StyledSelect
								className="ml-auto"
								value={pageSize}
								onChange={(e) => {
									setPageSize(Number(e.target.value));
								}}
							>
								{[1, 5, 10, 20, 50, 100, 1000].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</StyledSelect>
						</Row>
					</Row>
				</div>
			</Col>
		</Row>
	);
};

export default DataTable;
