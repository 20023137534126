import { Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { PSansText } from '../../../styles/StylesV2';
import {
	H2,
	Separator,
	SmallTextSecondaryMedium,
} from '../../../styles/StylesV3Main';
import { useClient } from '../../../hooks';
import config from '../../../config';

const StyledButtonRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	@media (max-width: 991.98px) {
		padding-right: 0px;
	}
`;

const StyledDeleteButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 4px 12px;
	margin-left: 16px;
	border: none;

	width: 147px;
	height: 40px;

	background: #cc1440;
	border-radius: 3px;

	@media (max-width: 991.98px) {
		margin-top: 16px;
		margin-left: 0px;
		width: 100%;
	}

	color: #ffffff;
	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	font-family: 'Roobert Regular';
	font-style: normal;
`;

const StyledCancelButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 4px 12px;
	border: none;

	width: 75px;
	height: 40px;

	background: rgba(25, 26, 28, 0.05);
	border-radius: 3px;

	font-weight: 600;
	font-size: 16px;
	line-height: 160%;
	font-family: 'Roobert Regular';
	font-style: normal;

	@media (max-width: 991.98px) {
		width: 100%;
	}
`;

const StyledHeading = styled(H2)`
	margin-bottom: 8px;
`;

export const StyledInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	width: 100%;
	height: 56px;
	padding-left: 16px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

const StyledSeparator = styled(Separator)`
	margin: 24px 0px;
`;

const RemoveIntegrationsModal = ({
	showModal,
	onHide,
	onSuccess,
	integrationName,
	teamId,
}) => {
	const client = useClient();

	const deleteIntegration = async () => {
		try {
			await client.get(
				`${config.teams.HOST}/teams/merge/delete-merge-integration/${teamId}`
			);
			onSuccess();
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<Modal show={showModal} animation={false} centered onHide={onHide}>
			<Modal.Body style={{ padding: '24px' }}>
				<StyledHeading>Delete Integration</StyledHeading>

				<SmallTextSecondaryMedium>
					Are you sure you want to delete this integration with{' '}
					{integrationName}? Deleting the integration will stop the syncing with{' '}
					{integrationName}. The members part of your team on Juno will not be
					affected.
				</SmallTextSecondaryMedium>
				<StyledSeparator />

				<Row noGutters as={StyledButtonRow}>
					<StyledCancelButton
						onClick={() => {
							onHide();
						}}
					>
						Cancel
					</StyledCancelButton>
					<StyledDeleteButton
						autoFocus
						onClick={() => {
							deleteIntegration();
						}}
						type="submit"
					>
						Confirm deletion
					</StyledDeleteButton>
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default RemoveIntegrationsModal;
