import React from 'react';
import styled from 'styled-components';
import { SmallTextSecondary } from '../../styles/StylesV3Main';

type TermsAndConditionsProps = {
	text: string;
};
const TermsAndConditions = ({ text }: TermsAndConditionsProps) => (
	<Container as="footer">
		{text}{' '}
		<a
			href="https://www.withjuno.com/juno-terms-and-conditions"
			target="_blank"
		>
			Terms &amp; Conditions
		</a>{' '}
		&amp;{' '}
		<a href="/privacy-policy" target="_blank">
			Privacy Policy
		</a>
	</Container>
);

const Container = styled(SmallTextSecondary)`
	p {
		margin-bottom: 0px;
	}
	a {
		font-family: 'Roobert Medium';
		color: ${({ theme }) => theme.mainColors.color.textPrimary};
		text-decoration: underline;
		&:hover {
			color: ${({ theme }) => theme.mainColors.color.textPrimary};
		}
	}
`;

export default TermsAndConditions;
