import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { H4 } from '../../styles/StylesV3Main';

const StyledRow = styled(Row)`
	background-color: ${(props) =>
		props.selected === false ? `white` : `#f8f8f8`};
	border-left: ${(props) =>
		props.selected === false ? `none` : `3px solid black`};
	height: 52px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: ${(props) => (props.selected === false ? `23px` : `20px`)};
	border-radius: 3px;
	cursor: pointer;
`;

const AccountsSideMenu = ({ selected, handleSelected, title }) => {
	return (
		<Row noGutters as={StyledRow} selected={selected} onClick={handleSelected}>
			<H4 className="ml-2">{title}</H4>
		</Row>
	);
};

export default AccountsSideMenu;
