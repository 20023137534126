import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const Dialog = styled.dialog`
	border: none;
	padding: 0;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 18px;

	@media (max-width: 500px) {
		overflow-y: auto;
	}

	&::backdrop {
		background: rgba(0, 0, 0, 0.4);
	}
`;

const Backdrop = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
`;

const Modal = ({
	children,
	open,
	onClose,
	ariaLabel,
	ariaDescribedBy,
}: any) => {
	const element = useRef<HTMLDialogElement>(null);

	useEffect(() => {
		if (open) {
			element.current?.showModal();
		} else {
			element.current?.close();
		}
	}, [open]);

	return (
		open && (
			<Dialog
				aria-labelledby={ariaLabel}
				aria-describedby={ariaDescribedBy}
				ref={element}
				// @ts-ignore
				onClose={onClose}
			>
				<Backdrop onClick={onClose} />
				{children}
			</Dialog>
		)
	);
};

export default Modal;
