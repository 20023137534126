/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { PSansMediumText } from '../../styles/StylesV2';
import { useUserContext } from '../../contexts/userContext';
import ManageTeamWrapper from './ManageTeamWrapper';
import ManagePlanWrapper from './ManagePlanWrapper';
import ManageBudgetsWrapper from './ManageBudgetsWrapper';
import ManagePointsWrapper from './ManagePointsWrapper';
import ManageProductAddon from './ManageProductAddon';
import Can from '../utilities/Can';
import { getRoles } from '../utilities/Roles';
import config from '../../config';
import TeamHeader from './TeamPage/TeamHeader';
import { BackgroundWrapper } from '../../styles/ProductStyles';
import ManageBenefitsWrapper from './ManageBenefitsWrapper';
import ManageWellbeingEngagement from './ManageWellbeingEngagement';
import { useClient, useCurrentTeam, useOnboarding } from '../../hooks';

const HeaderContainer = styled.div`
	width: 100%;
	max-width: 1250px;

	padding: 40px 0;
	margin: 0px;

	@media (max-width: 1250px) {
		max-width: 100%;
		padding: 20px;
		margin: 0px;
	}
`;

const StyledBackgroundWrapper = styled(BackgroundWrapper)`
	border-bottom: 2px solid rgba(0, 0, 0, 0.07);
	border-radius: 24px 24px 0 0;

	@media (max-width: 600px) {
		border-radius: unset;
	}
`;

const NavContainer = styled.div`
	width: 100%;
	max-width: 1250px;

	@media (max-width: 1250px) {
		width: 100%;
		padding: 0px;
		margin: 0px;
		padding-left: 0px;
		overflow-x: scroll;
		overflow-y: hide;
		-webkit-overflow-scrolling: touch;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 1250px;
	margin: 0px;

	@media (max-width: 1250px) {
		max-width: 100%;
		padding: 20px;
		margin: 0px;
	}
`;

const StyledTeamNavigation = styled.div`
	width: 100%;
	height: 55px;

	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;

	@media (max-width: 1250px) {
		padding-left: 0px;
		justify-content: space-evenly;
		height: 50px;
		overflow-x: scroll;
		overflow-y: hide;
		-webkit-overflow-scrolling: touch;
		width: 200%;
		max-width: 1250px;
	}
`;

const StyledNav = styled(PSansMediumText)`
	font-size: 16px;
	line-height: 160%;
	padding: 17px 1px 17px 1px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${(props) =>
		props.selected &&
		`
    border-bottom: 2px solid black;
    font-family: "Roobert Bold";
    font-style: normal;
    font-size: 16px;

  `};
	margin-right: 40px;
	@media (max-width: 1250px) {
		font-size: 14px;
		font-family: Product Sans Medium;
		padding: 17px 1px 16px 1px;
		margin-right: 25px;
	}
`;

const navmenu = [
	{
		title: 'Manage Team',
		value: 'team',
	},
	{
		title: 'Manage Plan',
		value: 'plan',
	},
	{
		title: 'Pots',
		value: 'budgets',
	},
	{
		title: 'Team Points',
		value: 'points',
	},
	{
		title: 'Wellbeing Report',
		value: 'engagement',
	},
	{
		title: 'Benefits',
		value: 'benefits',
	},
	{
		title: 'Add-ons',
		value: 'addon',
	},
];

const TeamPageWrapper = ({ match }) => {
	const { userData, updateUserData, changeCurrentTeam } = useUserContext();
	useOnboarding();
	const client = useClient();
	const currentTeam = useCurrentTeam();
	const history = useHistory();
	const [teamMembers, setTeamMembers] = useState([]);
	const [selected, setSelected] = useState('team');
	const team = userData.teams.find(({ id }) => id === +match.params.teamId);

	const getTeamMembers = useCallback(async () => {
		try {
			const response = await client.get(
				`${config.teams.HOST}/teams/${team.id}`
			);
			setTeamMembers(response.data);
		} catch (err) {
			console.error(err.message);
		}
	}, [match.params.teamId]);

	const handleUpdate = useCallback(async () => {
		try {
			if (
				userData.teams.filter((item) => item.id === match.params.teamId * 1)
					.length > 0
			) {
				getTeamMembers();
				await updateUserData();
			} else {
				history.push('/');
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [
		getTeamMembers,
		updateUserData,
		userData.teams,
		match.params.teamId,
		history,
	]);

	// Init teams
	useEffect(() => {
		getTeamMembers();
	}, [getTeamMembers]);

	// Menu selection
	useEffect(() => {
		setSelected(
			match.params.view &&
				navmenu.filter((item) => item.value === match.params.view).length > 0
				? match.params.view
				: 'team'
		);
	}, [match.params.view]);

	useEffect(() => {
		if (team?.id && currentTeam.id !== team.id) changeCurrentTeam(team.id);
	}, [team?.id, currentTeam.id]);

	return (
		<Switch>
			<Route
				path="/team/:teamId/"
				render={() => (
					<Can
						role={userData.currentUserRole}
						perform="team:visit"
						data={{
							roles: getRoles({
								userData,
								teamId: match.params.teamId * 1,
							}),
						}}
						yes={() => (
							<>
								<BackgroundWrapper background="#f8f8f8">
									<HeaderContainer>
										<TeamHeader
											team={{
												...team,
												teamMembers,
											}}
											handleUpdate={handleUpdate}
											match={match}
										/>
									</HeaderContainer>
								</BackgroundWrapper>
								<StyledBackgroundWrapper>
									<NavContainer>
										<StyledTeamNavigation>
											{navmenu
												.filter(
													() =>
														getRoles({
															userData,
															teamId: match.params.teamId * 1,
														}).indexOf('owner') !== -1
												)
												.map((item, index) => (
													<StyledNav
														key={index}
														selected={selected === item.value}
														onClick={() => {
															setSelected(item.value);
															history.push(
																`/team/${match.params.teamId}/${item.value}`
															);
														}}
													>
														{item.title}
													</StyledNav>
												))}
										</StyledTeamNavigation>
									</NavContainer>
								</StyledBackgroundWrapper>
								<BackgroundWrapper>
									<Container>
										<Switch>
											<Route
												path="/team/:teamId/plan"
												render={(props) => (
													<Can
														role={userData.currentUserRole}
														perform="plan:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => (
															<ManagePlanWrapper
																{...props}
																team={{
																	...team,
																	teamMembers,
																}}
																handleUpdate={handleUpdate}
															/>
														)}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/budgets"
												render={() => (
													<Can
														role={userData.currentUserRole}
														perform="team:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => <ManageBudgetsWrapper team={team} />}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/points"
												render={() => (
													<Can
														role={userData.currentUserRole}
														perform="team:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => (
															<ManagePointsWrapper
																team={{
																	...team,
																	teamMembers,
																}}
																handleUpdate={handleUpdate}
															/>
														)}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/health"
												render={() => (
													<Redirect
														to={`/team/${match.params.teamId}/engagement`}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/engagement"
												render={() => (
													<Can
														role={userData.currentUserRole}
														perform="team:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => (
															<ManageWellbeingEngagement team={team} />
														)}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/benefits"
												render={() => (
													<Can
														role={userData.currentUserRole}
														perform="plan:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => <ManageBenefitsWrapper team={team} />}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId/addon"
												render={(props) => (
													<Can
														role={userData.currentUserRole}
														perform="team:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => (
															<ManageProductAddon
																{...props}
																team={{
																	...team,
																	teamMembers,
																}}
																handleUpdate={handleUpdate}
															/>
														)}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
											<Route
												path="/team/:teamId"
												render={(props) => (
													<Can
														role={userData.currentUserRole}
														perform="team:manage"
														data={{
															roles: getRoles({
																userData,
																teamId: match.params.teamId * 1,
															}),
														}}
														yes={() => (
															<ManageTeamWrapper
																key={team.id}
																{...props}
																team={{
																	...team,
																	teamMembers,
																}}
																setTeamMembers={setTeamMembers}
																handleUpdate={handleUpdate}
															/>
														)}
														no={() => (
															<Redirect
																to={`/team/${match.params.teamId * 1}`}
															/>
														)}
													/>
												)}
											/>
										</Switch>
									</Container>
								</BackgroundWrapper>
							</>
						)}
						no={() => <Redirect to={`/team/${match.params.teamId * 1}`} />}
					/>
				)}
			/>
		</Switch>
	);
};

export default TeamPageWrapper;
