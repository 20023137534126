import React from 'react';
import styled from 'styled-components';
import products from './products.png';
import { ReactComponent as Unlock } from './unlock.svg';

const CtaContainer = styled.div`
	width: 100%;
	background-color: #ffffff;
`;

const Top = styled.div`
	width: 100%;
	height: 386px;
	background-image: linear-gradient(to bottom, transparent, white 90% 100%),
		url(${products});
	background-size: 100%;
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 50px;
	padding-top: 9px;
	text-align: center;

	@media (max-width: 992px) {
		padding-left: 32px;
		padding-right: 32px;
	}
`;
const StyledH2 = styled.h2`
	font-family: Roobert Semi Bold;
	font-size: 28px;
	line-height: 34px;
	margin-bottom: 16px;
	letter-spacing: -0.03em;

	@media (max-width: 992px) {
		font-size: 23px;
		line-height: 27px;
	}
`;
const StyledP = styled.p`
	font-family: Roobert Regular;
	font-size: 18px;
	line-height: 22px;
	opacity: 0.7;
	margin-bottom: 32px;

	@media (max-width: 992px) {
		font-size: 17px;
		line-height: 21px;
	}
`;
const StyledUnlock = styled(Unlock)`
	margin-right: 10px;
`;

const StyledA = styled.a`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	border: none;
	border-radius: 6px;
	background: linear-gradient(87.35deg, #e08177 0%, #d67186 100%);
	padding: 20px;
	color: #ffffff !important;
	font-family: Roobert Semi Bold;
	font-size: 18px;
	line-height: 22px;
	text-decoration: none !important;
	cursor: pointer;

	transition: opacity 150ms ease-out;
	:hover {
		opacity: 70%;
	}

	@media (max-width: 992px) {
		padding: 13px;
		font-size: 17px;
		line-height: 21px;
	}
`;

const OrderOptionsPageUnauthenticated = () => {
	return (
		<CtaContainer>
			<Top />
			<Bottom>
				<StyledH2>
					Give your team the freedom to choose what matters to them
				</StyledH2>
				<StyledP>
					Unlock hundreds of products for you and your team. Create and onboard
					your team in minutes.
				</StyledP>
				<StyledA href="https://www.withjuno.com/sign-up">
					<StyledUnlock />
					Get started
				</StyledA>
			</Bottom>
		</CtaContainer>
	);
};

export default OrderOptionsPageUnauthenticated;
