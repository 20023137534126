import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
	font-size: 1rem;
	border-radius: ${(props) =>
		props.size
			? props.theme.points[props.size].borderRadius
			: props.theme.points.normal.borderRadius};
	border-radius: 3px;
	background-color: ${(props) =>
		props.version === 'light' ? 'white' : 'black'};
	color: ${(props) => (props.version === 'light' ? 'black' : 'white')};
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Square = styled.div`
	padding-bottom: 100%;
	width: ${(props) =>
		props.size
			? props.theme.points[props.size].size
			: props.theme.points.normal.size};
	position: relative;
	overflow: hidden;
`;

const Texts = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${(props) =>
		props.size
			? props.theme.points[props.size].padding
			: props.theme.points.normal.padding};
`;

const StyledNumber = styled.p`
	font: ${(props) =>
		props.size
			? props.theme.points[props.size].fontNumber
			: props.theme.points.normal.fontNumber};
	letter-spacing: 0px;
	margin-bottom: 0px;
`;

const StyledEnquiry = styled.p`
	font: ${(props) =>
		props.size
			? props.theme.points[props.size].fontEnquiry
			: props.theme.points.normal.fontEnquiry};
	color: white;
	letter-spacing: 0px;
	margin-bottom: 0px;
`;

const StyledPoints = styled.p`
	font: ${(props) =>
		props.size
			? props.theme.points[props.size].fontText
			: props.theme.points.normal.fontText};
	color: rgba(255, 255, 255, 0.6);
	letter-spacing: 0px;
	margin-bottom: 0px;
`;

const Points = ({ product, version, size, ...props }) => {
	const getPoints = (product) => {
		try {
			if (
				product.productTypes.filter((service) => service.active === true)
					.length === 1
			) {
				return product.productTypes[0].baseCost;
			} else {
				const min = product.productTypes
					.filter((service) => service.active === true)
					.sort((a, b) => {
						return a.baseCost > b.baseCost ? 1 : -1;
					})[0].baseCost;
				const max = product.productTypes
					.filter((service) => service.active === true)
					.sort((a, b) => {
						return a.baseCost > b.baseCost ? 1 : -1;
					})[
					product.productTypes.filter((service) => service.active === true)
						.length - 1
				].baseCost;

				return min === max ? min : min + '+';
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<StyledDiv version={version} size={size} {...props}>
			<Square size={size}>
				<Texts>
					{product?.productTypes &&
					product?.productTypes.length > 0 &&
					product?.productTypes.filter((service) => service.active === true)
						.length === 1 &&
					product?.productTypes.filter((service) => service.enquire === true)
						.length === 1 ? (
						<>
							<StyledEnquiry size={size} className="text-center">
								upon
							</StyledEnquiry>
							<StyledEnquiry size={size} className="m-0 text-center">
								enquiry
							</StyledEnquiry>
						</>
					) : (
						<>
							<StyledNumber size={size} className="text-center">
								{getPoints(product)}
							</StyledNumber>
							<StyledPoints size={size} className="m-0 text-center">
								Points
							</StyledPoints>
						</>
					)}
				</Texts>
			</Square>
		</StyledDiv>
	);
};

export default Points;
