/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
	PSansBoldText,
	PSansMediumText,
	PSansText,
	H1Title,
} from '../../../styles/StylesV2';
import { getRoles } from '../../utilities/Roles';
import TopUp from './Wallet/TopUp';
import Can from '../../utilities/Can';

import { useUserContext } from '../../../contexts/userContext';

const TeamPointsContainer = styled.div`
	padding: 10px 0px 50px 0px;
	border-bottom: 1.5px solid rgba(0, 0, 0, 0.07);
	margin-bottom: 48.5px;
	@media (max-width: 992px) {
		border-bottom: none;
		padding: 0px;
	}
`;

const GreyCircle = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background: #f8f8f8;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DottedCircle = styled.div`
	width: 128px;
	height: 128px;
	border-radius: 50%;
	border: 0.5px dashed #000000;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Points = styled(PSansBoldText)`
	font-size: 32px;
	line-height: 39px;
`;

const PointsText = styled(PSansMediumText)`
	font-size: 10px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
`;

const Button = styled.button`
	width: 100%;
	order: 1;
	border: none;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background: transparent;
	border: 1.5px solid black;
	border-radius: 3px;
	color: black;

	&:focus {
		outline: 0;
	}
`;

const Text = styled(PSansText)`
	@media (max-width: 1250px) {
		text-align: center;
	}
`;

const Centered = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const AlignRow = styled(Row)`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	@media (max-width: 1250px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const Margin = styled.div`
	margin-left: 50px;
	@media (max-width: 1250px) {
		margin-left: 0px;
		margin-top: 35px;
	}
`;

const TeamPoints = ({ team, handleUpdate, handleTransactionsUpdate }) => {
	const { userData } = useUserContext();
	const [showModalTopUp, setShowModalTopUp] = useState(false);

	return (
		<TeamPointsContainer>
			<Row noGutters className="justify-content-center align-items-center">
				<Col lg={5} xs={12} style={{ minWidth: '400px' }}>
					<Row noGutters>
						<Col>
							<Centered>
								<GreyCircle>
									<DottedCircle>
										<Points>{team?.points?.toLocaleString()}</Points>
										<PointsText>
											{team?.points !== 1 ? 'Points' : 'Point'}
										</PointsText>
									</DottedCircle>
								</GreyCircle>
							</Centered>
						</Col>
						<Col xs={12} lg={7}>
							<Margin>
								<Row noGutters as={AlignRow}>
									<H1Title style={{ color: 'black' }}>Team Wallet</H1Title>
									<Text className="mt-2 mb-2 ">
										This is where you can keep extra points for one-off rewards
										or group booking for your team
									</Text>
								</Row>

								<Row
									noGutters
									className="justify-content-between align-items-center"
								>
									<Can
										role={userData.currentUserRole}
										perform="plan:manage"
										data={{
											roles: getRoles({
												userData,
												teamId: team.id,
											}),
										}}
										yes={() => (
											<Col>
												<Button
													className="mr-2"
													onClick={() => setShowModalTopUp(true)}
													marginRight={true}
												>
													<PSansBoldText>Top Up</PSansBoldText>
												</Button>
												{showModalTopUp && (
													<TopUp
														setShowModal={setShowModalTopUp}
														showModal={showModalTopUp}
														goBack={() => {
															setShowModalTopUp(false);
														}}
														team={team}
														handleUpdate={handleUpdate}
														handleTransactionsUpdate={handleTransactionsUpdate}
													/>
												)}
											</Col>
										)}
										no={() => <></>}
									/>
								</Row>
							</Margin>
						</Col>
					</Row>
				</Col>
			</Row>
		</TeamPointsContainer>
	);
};

export default TeamPoints;
