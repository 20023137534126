import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import StripeLogo from '../../utilities/StripeLogo';
import { H1Title, Divider, StyledSelect } from '../../../styles/StylesV2';
import { Row, Col, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { BoxInputLabel } from '../../../styles/StylesV2';
import SetupCard from './PaymentMethods/SetupCard';
import SetupBACS from './PaymentMethods/SetupBACS';
import SetupSEPA from './PaymentMethods/SetupSEPA';

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const Select = styled(StyledSelect)`
	background: rgba(0, 0, 0, 0.03);
`;

const StyledInputRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@media (max-width: 991.98px) {
		padding-top: 0%;
		padding-bottom: 0%;
		width: 100%;
		margin: 0px;
	}
`;

const StyledClose = styled(IoMdClose)`
	position: absolute;
	top: 4%;
	right: 4%;
	cursor: pointer;
	z-index: 1022;
	@media (max-width: 991.98px) {
		top: 4%;
	}
`;

const handleOptions = (currency) => {
	try {
		switch (currency) {
			case 'gbp':
				return [
					{ value: 'card', label: 'Credit Card' },
					{ value: 'bacs', label: 'BACS Debit' },
				];
			case 'usd':
				return [{ value: 'card', label: 'Credit Card' }];
			case 'eur':
				return [
					{ value: 'card', label: 'Credit Card' },
					{ value: 'sepa', label: 'SEPA Debit' },
				];
			case null:
				return [
					{ value: 'card', label: 'Credit Card' },
					{ value: 'bacs', label: 'BACS Debit' },
					{ value: 'sepa', label: 'SEPA Debit' },
				];
			default:
				return null;
		}
	} catch (err) {
		console.error(err.message);
	}
};

const AddNewPaymentMethodWrapper = ({ show, onHide, team }) => {
	const [typePaymentMethod, setTypePaymentMethod] = useState('card');
	const [options, setOptions] = useState(null);

	useEffect(() => {
		try {
			if (team.stripeCustomerData) {
				setOptions(handleOptions(team.stripeCustomerData.currency));
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [team.stripeCustomerData]);

	return (
		<Modal
			animation={false}
			show={show}
			onHide={onHide}
			size="md"
			className="px-0"
			centered
		>
			<StyledClose onClick={onHide} size={25} color={'rgba(0,0,0,0.3)'} />
			<Row noGutters className="justify-content-center py-3">
				{typePaymentMethod === 'card' ? (
					<H1Title style={{ color: 'black' }}>Add New Card</H1Title>
				) : (
					<H1Title style={{ color: 'black' }}>Add New Direct Debit</H1Title>
				)}
			</Row>
			<Row noGutters as={StyledInputRow} className="w-100 py-4 px-4">
				{options && options.length > 1 && (
					<Col xs={12} lg={12} className="px-0 py-0">
						<StyledLabel>Type</StyledLabel>

						<Select
							value={typePaymentMethod}
							onChange={({ target: { value } }) => setTypePaymentMethod(value)}
						>
							{options.map((option, indexOption) => (
								<option key={indexOption} value={option.value}>
									{option.label}
								</option>
							))}
						</Select>
					</Col>
				)}
				{typePaymentMethod === 'card' && (
					<SetupCard onHide={onHide} onSuccess={onHide} teamId={team.id} />
				)}
				{typePaymentMethod === 'bacs' && team.stripeSubscriptionId && (
					<SetupBACS onHide={onHide} team={team} />
				)}
				{typePaymentMethod === 'sepa' && (
					<SetupSEPA onHide={onHide} onSuccess={onHide} teamId={team.id} />
				)}
				<Divider className="px-4 my-3" />
				<Row noGutters className="text-center">
					<StripeLogo />
				</Row>
			</Row>
		</Modal>
	);
};

export default AddNewPaymentMethodWrapper;
