import React from 'react';
import styled from 'styled-components';
import { Small } from '../../styles/StylesV3Main';
import { IoHeart } from 'react-icons/io5';
import { useUserContext } from '../../contexts/userContext';
import config from '../../config';
import { getUsernameEmail } from './Functions';

const StyleAddtoFavoutite = styled(IoHeart)`
	color: #e2a1c0;
`;

const StyledRemoveFromFavoutite = styled(IoHeart)`
	color: #5b6166;
`;

const StyledButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 16px;
	width: fit-content;
	height: 46px;
	margin: 8px 0px;
	cursor: pointer;

	background: transparent;
	/* Separator */

	border: 1px solid rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	border-radius: 3px;
	transition: all 0.15s;

	&:hover {
		background: rgba(0, 0, 0, 0.03);
		${StyledRemoveFromFavoutite} {
			color: #191a1c;
		}
	}
`;

const StyledInnerButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 100%;
`;

const FavouritesButton = ({ product }) => {
	const { userData, changeFavorite } = useUserContext();
	const isFavourite = userData?.favorites?.find((item) => item === product.id);
	return (
		<StyledButton
			onClick={(event) => {
				event.stopPropagation();
				changeFavorite(product.id);
			}}
		>
			{isFavourite ? (
				<StyledInnerButton title="Remove from Favourites">
					<StyleAddtoFavoutite size={20} className="mr-2" />
					<Small>Remove from favourites</Small>
				</StyledInnerButton>
			) : (
				<StyledInnerButton title="Add to favourites">
					<StyledRemoveFromFavoutite size={20} className="mr-2" />
					<Small>Add to favourites</Small>
				</StyledInnerButton>
			)}
		</StyledButton>
	);
};

export default FavouritesButton;
