import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
	H1Title,
	PSansText,
	PSansMediumText,
	TR,
	TH,
	TD,
} from '../../../styles/StylesV2';
import { FiDownloadCloud } from 'react-icons/fi';
import { MdOpenInNew } from 'react-icons/md';
import Loading from '../../utilities/Loading';
// Import utilities
import config from '../../../config';
import client from '../../../api/client';
// Import contexts
import { useAuth0 } from '../../../contexts/react-auth0-spa';
import DataTable from '../../utilities/DataTable';
import { useCurrency } from '../../../hooks';

const StyledLoading = ({ height }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: height ? height : '50vh',
				width: '100%',
				textAlign: 'center',
			}}
		>
			<Loading />
		</div>
	);
};

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
`;

const Title = styled(H1Title)`
	font-size: 28px;
	color: black;
	margin-bottom: 5px;
`;

const StyledTableHeader = styled(PSansMediumText)`
	color: rgba(0, 0, 0, 0.3);
	letter-spacing: 0.25px;
	font-size: 10px;
	line-height: 20px;
	text-transform: uppercase;
`;

const StyledRowTableBody = styled(Row)`
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #e5e5e5;
	@media (max-width: 991.98px) {
		border-top: 1px solid #e5e5e5;
		padding-top: 15px;
		height: fit-content;
	}
`;

const StyledColumnTBody = styled(Col)`
	display: flex;

	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	@media (max-width: 992px) {
		flex-direction: column;
		align-items: start;
		justify-content: flex-start;
		margin-bottom: 15px;
	}
`;

const MobileTableHeader = styled(PSansMediumText)`
	display: none;
	@media (max-width: 992px) {
		display: block;
		color: rgba(0, 0, 0, 0.3);
		letter-spacing: 0.25px;
		font-size: 10px;
		line-height: 20px;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
`;
const DesktopView = styled.div`
	display: block;
	@media (max-width: 992px) {
		display: none;
	}
`;
const MobileView = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: block;
	}
`;

const TextUnderline = styled(PSansText)`
	text-decoration: underline;
	text-underline-position: under;
	text-transform: capitalize;
	text-decoration-thickness: 1.5px;
	cursor: pointer;
`;

const TeamInvoice = ({ teamId, handleUpdate }) => {
	const { getTokenSilently } = useAuth0();
	const currencies = useCurrency();
	const symbols = { gbp: '£', usd: '$', eur: '€' };

	const [invoices, setInvoices] = useState(null);

	const initTransactions = useCallback(async () => {
		try {
			const token = await getTokenSilently();
			client.defaults.headers.Authorization = 'Bearer ' + token;
			const response = await client.get(
				`${config.platform.HOST}/payments/authorized/customers/${teamId}/invoices`
			);

			if (response.data) {
				setInvoices(response.data);
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [teamId, getTokenSilently]);

	useEffect(() => {
		initTransactions();
	}, [initTransactions]);

	const columns = useMemo(() => {
		return invoices
			? [
					{
						Header: <StyledTableHeader>Account Name</StyledTableHeader>,
						accessor: 'accountName', // accessor is the "key" in the data
						Cell: ({ value }) => <PSansText>{value}</PSansText>,
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Date Paid</StyledTableHeader>,
						accessor: 'paidAt', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<PSansText>
								{value ? new Date(value * 1000).toLocaleDateString() : '-'}
							</PSansText>
						),
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Amount</StyledTableHeader>,
						accessor: 'amountDue', // accessor is the "key" in the data
						Cell: ({ value, row }) => (
							<PSansText>
								{symbols[row.original.currency]}
								{(value / 100).toFixed(2)}
							</PSansText>
						),
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Created</StyledTableHeader>,
						accessor: 'created', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<PSansText>
								{new Date(value * 1000).toLocaleDateString()}
							</PSansText>
						),
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Invoice No</StyledTableHeader>,
						accessor: 'number', // accessor is the "key" in the data
						Cell: ({ value }) => <PSansText>{value}</PSansText>,
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Invoice</StyledTableHeader>,
						accessor: 'pdf', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<>
								{value && (
									<a
										style={{
											color: 'black',
											cursor: 'pointer',
										}}
										href={value}
									>
										<Row noGutters className="align-items-center">
											<FiDownloadCloud size={16} className="mr-1" />
											<TextUnderline>Download</TextUnderline>
										</Row>
									</a>
								)}
							</>
						),
						flexGrow: 1,
					},
					{
						Header: <StyledTableHeader>Receipt</StyledTableHeader>,
						accessor: 'chargeUrl', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<>
								{value && (
									<a
										href={value}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											color: 'black',
											cursor: 'pointer',
										}}
									>
										<Row noGutters className="align-items-center">
											<MdOpenInNew size={16} className="mr-1" />
											<TextUnderline>View</TextUnderline>
										</Row>
									</a>
								)}
								{!value && <p>-</p>}
							</>
						),
						flexGrow: 1,
					},
			  ]
			: [];
	}, [invoices, currencies]);

	const dataDisplay = useMemo(() => {
		return invoices || [];
	}, [invoices]);

	if (!invoices) {
		return <StyledLoading height="20vh" />;
	}

	return (
		<>
			<Row
				noGutters
				className={'justify-content-between align-items-center mb-4'}
			>
				<StyledDiv>
					<Title>Invoices</Title>
					<PSansText>
						{invoices.length} invoice
						{invoices.length === 1 ? '' : 's'} available
					</PSansText>
				</StyledDiv>
			</Row>
			<DesktopView>
				<DataTable
					columns={columns}
					data={dataDisplay}
					TR={TR}
					TH={TH}
					TD={TD}
				/>
			</DesktopView>
			<MobileView>
				{invoices &&
					invoices.map((item, index) => (
						<Row noGutters as={StyledRowTableBody} key={index}>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Account Name</MobileTableHeader>
								<PSansText>{item.accountName}</PSansText>
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Date Paid</MobileTableHeader>
								<PSansText>
									{item.paidAt
										? new Date(item.paidAt * 1000).toLocaleDateString()
										: '-'}
								</PSansText>
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Amount</MobileTableHeader>
								<PSansText>
									{symbols[item.currency]}
									{(item.amountDue / 100).toFixed(2)}
								</PSansText>
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Created</MobileTableHeader>
								<PSansText>
									{new Date(item.created * 1000).toLocaleDateString()}
								</PSansText>
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Invoice No</MobileTableHeader>
								<PSansText>{item.number}</PSansText>
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Invoice</MobileTableHeader>
								{item.pdf && (
									<a
										style={{
											color: 'black',
											cursor: 'pointer',
										}}
										href={item.pdf}
									>
										<Row noGutters className="align-items-center">
											<FiDownloadCloud size={16} className="mr-1" />
											<TextUnderline>Download</TextUnderline>
										</Row>
									</a>
								)}
							</Col>
							<Col lg xs={4} as={StyledColumnTBody}>
								<MobileTableHeader>Receipt</MobileTableHeader>
								{item.chargeUrl && (
									<a
										href={item.chargeUrl}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											color: 'black',
											cursor: 'pointer',
										}}
									>
										<Row noGutters className="align-items-center">
											<MdOpenInNew size={16} className="mr-1" />
											<TextUnderline>View</TextUnderline>
										</Row>
									</a>
								)}
								{!item.chargeUrl && <p>-</p>}
							</Col>
						</Row>
					))}
			</MobileView>
		</>
	);
};

export default TeamInvoice;
