import React from 'react';
import styled from 'styled-components';
import { H1Title } from '../../../styles/StylesV2';
import { Row } from 'react-bootstrap';

const StyledHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
`;
const StyledHeading = styled(H1Title)`
	font-size: 28px;
	line-height: 120%;
	flex: none;
	flex-grow: 0;
	margin: 0px 34px 15px 0px;
	color: rgba(0, 0, 0, 1);
	cursor: pointer;
	${({ selectedTable }) => !selectedTable && `color: rgba(0, 0, 0, 0.5);`}
	@media(max-width:992px) {
		font-size: 20px;
		margin: 0px 20px 15px 0px;
	}
	@media (max-width: 320px) {
		font-size: 16px;
		margin: 0px 20px 15px 0px;
	}
`;

const SubHeader = ({
	selectedTable,
	handleHeaderOneClick,
	handleHeaderTwoClick,
	header1,
	header2,
	selectedHeader1,
	selectedHeader2,
}) => {
	return (
		<StyledHeaderContainer>
			<Row noGutters>
				<StyledHeading
					style={{ order: 0 }}
					selectedTable={selectedTable === selectedHeader1 ? true : false}
					onClick={handleHeaderOneClick}
				>
					{header1}
				</StyledHeading>
				<StyledHeading
					selectedTable={selectedTable === selectedHeader2 ? true : false}
					style={{ order: 1 }}
					onClick={handleHeaderTwoClick}
				>
					{header2}
				</StyledHeading>
			</Row>
		</StyledHeaderContainer>
	);
};

export default SubHeader;
