const configs = {
	development: {
		web: {
			HOST: 'http://localhost:3000',
		},
		admin: {
			HOST: 'http://localhost:4000',
		},
		api: {
			HOST: 'http://localhost:3001',
		},
		platform: {
			HOST: 'http://localhost:3129',
		},
		teams: {
			HOST: 'http://localhost:3130',
		},
		products: {
			HOST: 'http://localhost:3132',
		},
		users: {
			HOST: 'http://localhost:3133',
		},
		stripe: {
			PUBLISHABLE_API_KEY: 'pk_test_FcYlyfcZxW8dNfgFGQq5ggXw00MwS9L8ZS',
			issuingAccount: {
				PUBLISHABLE_API_KEY:
					'pk_test_51KbhsKBzVX7k8KnIOC1i9y2n6Co86p9qVHRUqgYY3K3B2h9NCr32i14PxLsaFGd6TckxFB0B3Lx7P6FZmg2tHKTJ00VyzJlCd0',
			},
		},
		gmaps: {
			API_KEY: 'AIzaSyDnQY3PUy4PzByiawKw5_sGSRjgg90mVPY',
		},
		customPages: {
			cards: {
				PRODUCT: 225,
				CREATESERVICE: 469,
				TOPUPSERVICE: 794,
			},
			concierge: {
				PRODUCT: 139,
			},
			reimbursement: {
				PRODUCT: 382,
				FEES_SERVICE: 830,
				NO_FEES_SERVICE: 835,
			},
			junoCard: {
				PRODUCT: 384,
			},
		},
		env: 'dev',
	},

	staging: {
		web: {
			HOST: 'https://app-staging.withjuno.com',
		},
		admin: {
			HOST: 'http://admin-staging.withjuno.com',
		},
		api: {
			HOST: 'https://api-staging.withjuno.com',
		},
		platform: {
			HOST: 'https://staging.platform.withjuno.com',
		},
		teams: {
			HOST: 'https://staging.platform.withjuno.com',
		},
		products: {
			HOST: 'https://staging.platform.withjuno.com',
		},
		users: {
			HOST: 'https://staging.platform.withjuno.com',
		},
		stripe: {
			PUBLISHABLE_API_KEY: 'pk_test_FcYlyfcZxW8dNfgFGQq5ggXw00MwS9L8ZS',
			issuingAccount: {
				PUBLISHABLE_API_KEY:
					'pk_test_51KbhsKBzVX7k8KnIOC1i9y2n6Co86p9qVHRUqgYY3K3B2h9NCr32i14PxLsaFGd6TckxFB0B3Lx7P6FZmg2tHKTJ00VyzJlCd0',
			},
		},
		gmaps: {
			API_KEY: 'AIzaSyBQdZCdU5VdY-AJM4AF6oub7lm0_bZxs0E',
		},
		customPages: {
			cards: {
				PRODUCT: 225,
				CREATESERVICE: 469,
				TOPUPSERVICE: 794,
			},
			concierge: {
				PRODUCT: 139,
			},
			reimbursement: {
				PRODUCT: 382,
				FEES_SERVICE: 830,
				NO_FEES_SERVICE: 835,
			},
			junoCard: {
				PRODUCT: 384,
			},
		},
		env: 'staging',
	},

	production: {
		web: {
			HOST: 'https://app.withjuno.com',
		},
		admin: {
			HOST: 'http://admin.withjuno.com',
		},
		api: {
			HOST: 'https://api.withjuno.com',
		},
		platform: {
			HOST: 'https://prod.platform.withjuno.com',
		},
		teams: {
			HOST: 'https://prod.platform.withjuno.com',
		},
		products: {
			HOST: 'https://prod.platform.withjuno.com',
		},
		users: {
			HOST: 'https://prod.platform.withjuno.com',
		},
		stripe: {
			PUBLISHABLE_API_KEY: 'pk_live_jj9t1gjYOgUzdArhh9GoTbf300Qfg8iljZ',
			issuingAccount: {
				PUBLISHABLE_API_KEY:
					'pk_live_51KbhsKBzVX7k8KnIbrZGiy6lNHeUrOG69DCk49JInhn4IJa3uBN8O2OOIZvZoU94K8uj9TuNY1cBzOKHnVNBTqIC00Um2Kme2D',
			},
		},
		gmaps: {
			API_KEY: 'AIzaSyDkSfKr6Mfy01DTVpubgvf-AFQLbU1a1q0',
		},
		customPages: {
			cards: {
				PRODUCT: 225,
				CREATESERVICE: 469,
				TOPUPSERVICE: 794,
			},
			concierge: {
				PRODUCT: 139,
			},
			reimbursement: {
				PRODUCT: 382,
				FEES_SERVICE: 868,
				NO_FEES_SERVICE: 1164,
			},
			junoCard: {
				PRODUCT: 421,
			},
		},
		env: 'prod',
	},
};

const configEnv = configs[process.env.REACT_APP_STAGE] || configs.development;

const config = {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	BASE_PATH: 'src/',
	PLACEHOLDER_IMAGE: '/images/products/imagePlaceholder.svg',
	...configEnv,
};

export default config;
