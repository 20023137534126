import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Modal, ModalBody } from 'react-bootstrap';
import {
	H2,
	H5SemiBold,
	Paragraph,
	SmallTextSecondary,
} from '../../styles/StylesV3Main';
import { ModalButton, BoxInputLabel } from '../../styles/StylesV2';
import { RowView } from '../../styles/ProductStyles';
import options from '../../data/countries.json';
import SingleSelect from './SingleSelect';

const LabelH5 = styled.button`
	font: normal 600 14px 'Roobert Regular';
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
	border: 0;
	background: transparent;
`;

const OptionalText = styled(SmallTextSecondary)`
	line-height: 17px;
`;

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledInputBox = styled.input`
  font: normal normal 400 14px/16px "Product Sans Regular";
  width: 100%;
  height: 50px;
  border: none;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  padding 10px;
`;

const StyledButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
	width: 100%;
`;

const StyledSelect = styled(SingleSelect)`
    &:nth-child(1) {
        background-color: rgba(0, 0, 0, 0.03) !important;
    }

    .select__control {
        font: normal normal 400 14px/16px "Product Sans Regular";
        width: 100%;
        height: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        padding 10px;
    }
`;

const AddressModal = ({ showModal, setShowModal, value, ...props }: any) => {
	const [address, setAddress] = useState(value);

	const handleChange = (e) => {
		setAddress({
			...address,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<Modal
			show={showModal}
			centered
			animation={false}
			onHide={() => {
				setShowModal(false);
			}}
		>
			<ModalBody style={{ padding: 24 }}>
				<Row
					noGutters
					className="mt-3 justify-content-center align-items-center"
				>
					<H2>Update your address</H2>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Address Line 1</StyledLabel>
						<StyledInputBox
							type="text"
							name="line1"
							value={address?.line1 || ''}
							onChange={handleChange}
						/>
					</Col>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Address Line 2</StyledLabel>
						<StyledInputBox
							type="text"
							name="line2"
							value={address?.line2 || ''}
							onChange={handleChange}
						/>
					</Col>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Town or City</StyledLabel>
						<StyledInputBox
							type="text"
							name="city"
							value={address?.city || ''}
							onChange={handleChange}
						/>
					</Col>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Postal Code</StyledLabel>
						<StyledInputBox
							type="text"
							name="postalCode"
							value={address?.postalCode || ''}
							onChange={handleChange}
						/>
					</Col>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel>Country</StyledLabel>
						<StyledSelect
							name="country"
							placeholder="Select Country"
							onChange={(e) => {
								handleChange({
									target: {
										name: 'country',
										value: e?.value,
									},
								});
							}}
							isDisabled={!options.length}
							options={options}
						/>
					</Col>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<StyledButtonRow>
						<Col xs={6} className="px-0 py-0 mr-2">
							<ModalButton
								className="mr-2"
								cancelButton
								width="90%"
								version="light"
								onClick={() => {
									setAddress(value);
									setShowModal(false);
								}}
							>
								Cancel
							</ModalButton>
						</Col>
						<Col xs={6} className="px-0 py-0 ml-2">
							<ModalButton
								className="ml-2"
								width="90%"
								disabled={
									!(
										!!address?.line1 &&
										!!address?.city &&
										!!address?.postalCode &&
										!!address?.country
									)
								}
								onClick={() => {
									props.onChange({
										target: { value: address },
									});
									setShowModal(false);
								}}
							>
								Update
							</ModalButton>
						</Col>
					</StyledButtonRow>
				</Row>
			</ModalBody>
		</Modal>
	);
};

const MultiPartAddress = ({ option, ...props }: any) => {
	const [showAddressModal, setShowAddressModal] = useState(false);
	return (
		<RowView border>
			<Col className="px-0">
				{option.title && (
					<>
						<H5SemiBold>{option.title}</H5SemiBold>
						{!option.compulsory && (
							<OptionalText className="ml-1">Optional</OptionalText>
						)}
					</>
				)}
			</Col>

			<Col style={{ textAlign: 'right' }} className="px-0">
				{option.value && (
					<>
						<Paragraph style={{ fontSize: 16 }}>
							{option?.value?.line1}
						</Paragraph>
						<Paragraph style={{ fontSize: 16 }}>
							{option?.value?.line2}
						</Paragraph>
						<Paragraph style={{ fontSize: 16 }}>
							{option?.value?.city}
						</Paragraph>
						<Paragraph style={{ fontSize: 16 }}>
							{option?.value?.postalCode}
						</Paragraph>
						<Paragraph style={{ fontSize: 16 }}>
							{option?.value?.country}
						</Paragraph>
					</>
				)}
				<LabelH5
					htmlFor="Your Address"
					style={{ cursor: 'pointer' }}
					onClick={() => setShowAddressModal(true)}
				>
					{!option.value ? 'Enter your address' : 'Edit your address'}
				</LabelH5>
			</Col>

			<AddressModal
				setShowModal={setShowAddressModal}
				value={option.value || null}
				showModal={showAddressModal}
				required={option.compulsory}
				name="Enter Address"
				description={option.description}
				goBack={() => {
					setShowAddressModal(false);
				}}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		</RowView>
	);
};

export default MultiPartAddress;
