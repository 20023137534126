/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory, Link, Route, Switch } from 'react-router-dom';
import { ContentState, convertToRaw } from 'draft-js';
import Spinner from '../../styles/shared/Spinner/Spinner';
import { useClient } from '../../hooks';
import config from '../../config';
import { TeamBudget } from './ManageBudgets/types';
import AddEditBudgetModal from './ManageBudgets/AddEditBudgetModal';
import ConfirmDeleteModal from './ManageBudgets/ConfirmDeleteModal';
import Budgets from './ManageBudgets';
import Transactions from './ManageBudgets/Transactions';
import { ReactComponent as Info } from './images/info.svg';

const inspirations = [
	{
		id: 1,
		name: 'Lunch allowance',
		tagline:
			'Provide your staff with a lunch allowance, perhaps for the days that they are working from the office.',
		policy: convertToRaw(
			ContentState.createFromText(
				'Example Policy: Use this allowance to buy yourself lunch when working from the office. Why not invite a colleague from a different team or department to join you, to expand your connections within the business!'
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/lunch.jpg`,
		budgetType: 'food',
		renewalPeriod: 'weekly',
		renewalType: 'reset',
		amount: 15,
		receiptRequired: false,
	},
	{
		id: 2,
		name: 'Professional Development',
		tagline:
			'Assign your staff an allowance to further their professional development or learn new skills!',
		policy: convertToRaw(
			ContentState.createFromText(
				'Example Policy: This allowance is for you to learn new skills that will help your professional and career development. Please discuss your plans with your Line Manager before using your allowance, to ensure they are compliant with the policy.'
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/development.jpg`,
		budgetType: 'learning',
		renewalPeriod: 'yearly',
		renewalType: 'reset',
		amount: 250,
		receiptRequired: false,
	},
	{
		id: 3,
		name: 'Home Office',
		tagline:
			'Save admin time by managing your employee WFH allowance through Juno.',
		policy: convertToRaw(
			ContentState.createFromText(
				'Example Policy: Use this allowance to improve your home office set up. Please note that laptops and monitors are provided to all new joiners, so think of things like ergonomic chairs, a new desk, a laptop stand or perhaps some new headphones!'
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/home.jpg`,
		budgetType: 'workfromhome',
		renewalPeriod: 'once-off',
		renewalType: 'reset',
		amount: 500,
		receiptRequired: false,
	},
	{
		id: 4,
		name: 'Work Travel',
		tagline:
			'Looking to encourage staff to return to the office, or provide them with an allowance for client-related travel?',
		policy: convertToRaw(
			ContentState.createFromText(
				'Example Policy: This allowance is exclusively to be used for travel to work on anchor days. Use your Juno card to pay for the tube, for train travel or for car parking fees. You may also use this allowance to purchase an annual railcard.'
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/work.jpg`,
		budgetType: 'transport',
		renewalPeriod: 'monthly',
		renewalType: 'reset',
		amount: 50,
		receiptRequired: false,
	},
	{
		id: 5,
		name: 'Team Social',
		tagline:
			'Avoid gathering receipts from your team’s day out by assigning an allowance for users to spend.',
		policy: convertToRaw(
			ContentState.createFromText(
				`Example Policy: This allowance is for our upcoming away day at Celtic Manor. Transport, meals and accommodation have all been taken care of for all, so this allowance is for evening drinks, snacks or to treat yourself in the spa during downtime.`
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/social.jpg`,
		budgetType: 'other',
		renewalPeriod: 'once-off',
		renewalType: 'reset',
		amount: 50,
		receiptRequired: false,
	},
	{
		id: 6,
		name: 'Sports & Fitness',
		tagline:
			'Set aside a budget for your team to use on gym memberships or activities and hobbies to support their fitness.',
		policy: convertToRaw(
			ContentState.createFromText(
				`Example Policy: The purpose of this allowance to support your physical fitness and can be used on gym memberships, fitness equipment, sports apparel, fitness classes and other sports and physical activities. If you’re unsure if your activity qualifies, double-check with your Line Manager beforehand!`
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/gym.jpg`,
		budgetType: 'fitness',
		renewalPeriod: 'monthly',
		renewalType: 'accumulate',
		amount: 35,
		receiptRequired: false,
	},
	{
		id: 7,
		name: 'Therapy',
		tagline:
			'Provide your team with a dedicated allowance to speak to a counsellor or to gain mental health support.',
		policy: convertToRaw(
			ContentState.createFromText(
				`Example Policy: This allowance is to support your mental health on an ongoing basis, and can be used for things like mental health applications, home relaxation products and counselling sessions. Remember we have a separate Employee Assistance Programme if you need access to immediate support.`
			)
		),
		thumbnail: `https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/therapy.jpg`,
		budgetType: 'other',
		renewalPeriod: 'quarterly',
		renewalType: 'reset',
		amount: 75,
		receiptRequired: false,
	},
] as (TeamBudget & { tagline: string })[];

const ManageBudgetsWrapper = ({ team }: any) => {
	const { id: teamId } = team;
	const client = useClient();
	const { pathname, search } = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(search);
	const addBudget = !!params.get('add');
	const editBudgetId = +(params.get('edit') || 0);
	const deleteBudgetId = +(params.get('delete') || 0);
	const inspirationId = +(params.get('inspiration') || 0);
	const [budgets, setBudgets] = useState<TeamBudget[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			setLoading(true);
			await getBudgets();
			setLoading(false);
		})();
	}, [team.id]);

	const closeModal = () => {
		history.push(`${pathname}`, {
			scroll: false,
		});
	};

	const getBudgets = async () => {
		try {
			const budgetResponse = await client.get(
				`${config.teams.HOST}/teams/budgets/${teamId}`
			);
			setBudgets(budgetResponse.data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleSaveBudget = async (input: any) => {
		try {
			if (input.id) {
				setBudgets((prevBudgets) =>
					prevBudgets.map((budget) => (budget.id !== input.id ? budget : input))
				);
				closeModal();
				await client.put(
					`${config.teams.HOST}/teams/budgets/${input.id}`,
					input
				);
			} else {
				const { data } = await client.post(
					`${config.teams.HOST}/teams/budgets/${teamId}`,
					input
				);
				setBudgets((prevBudgets) => [
					...prevBudgets,
					{ ...input, id: data.id },
				]);
				closeModal();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			getBudgets();
		}
	};

	const handleConfirmBudgetDelete = async () => {
		try {
			setBudgets((prevBudgets) =>
				prevBudgets.filter(({ id }) => id !== deleteBudgetId)
			);
			closeModal();
			await client.delete(
				`${config.teams.HOST}/teams/budgets/${deleteBudgetId}`
			);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			getBudgets();
		}
	};

	const editBudget = editBudgetId
		? budgets.find(({ id }) => id === editBudgetId)
		: null;
	const deleteBudget = deleteBudgetId
		? budgets.find(({ id }) => id === deleteBudgetId)
		: null;
	const inspirationBudget = inspirationId
		? inspirations.find(({ id }) => id === inspirationId)
		: null;
	return (
		<>
			{loading ? (
				<div
					style={{
						width: '100%',
						height: '50vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner />
				</div>
			) : (
				<Pots>
					{!budgets.length && <h2>Start your first pot</h2>}
					{!!budgets.length && (
						<div className="filter">
							<div>
								<Link
									to={{
										pathname: `/team/${team.id}/budgets`,
										state: { scroll: false },
									}}
									className={
										!pathname.endsWith('/transactions') ? 'selected' : undefined
									}
								>
									Pots
								</Link>
								<Link
									to={{
										pathname: `/team/${team.id}/budgets/transactions`,
										state: { scroll: false },
									}}
									className={
										pathname.endsWith('/transactions') ? 'selected' : undefined
									}
								>
									Transactions
								</Link>
							</div>
						</div>
					)}
					<div>
						<p>
							{pathname.indexOf('transactions') === -1 ? (
								<>
									You can manage all your employee allowances through Juno by
									creating separate Pots and assigning them to employees via the
									Manage Team page. Reimbursement requests will be reviewed by
									Juno against the Policy you set, and you can also request
									receipt uploads for employee spend on Visa Cards so that you
									can review these at a later date.
								</>
							) : (
								<>
									Use this page to review team spending on Pots. Receipts for
									approved Pot Reimbursements will be available for you to view.
									If enabled, receipts for Visa Card expenditure will be
									available for you to review. Mark as &apos;Reviewed&apos; or
									&apos;Flagged&apos; to remind you to take any followup
									actions. You can also send reminder notifications to users to
									upload missing receipts.
								</>
							)}
						</p>
						<p className="info">
							<Info />
							When your team spends from their Pots, the amount will be added to
							your next invoice as a separate line per pot.
						</p>
					</div>
					<Switch>
						<Route
							path="/team/:teamId/budgets/transactions"
							render={() => <Transactions team={team} budgets={budgets} />}
						/>
						<Route
							path="/team/:teamId/budgets"
							render={() => (
								<Budgets
									budgets={budgets}
									inspirations={inspirations}
									currency={team.currency || 'gbp'}
								/>
							)}
						/>
					</Switch>
				</Pots>
			)}
			{(editBudget || addBudget || inspirationBudget) && (
				<AddEditBudgetModal
					onSaveClick={handleSaveBudget}
					onCancelClick={closeModal}
					inspiration={!!inspirationBudget}
					isOpen
					teamId={teamId}
					editBudget={editBudget || inspirationBudget || undefined}
					currency={team.currency || 'gbp'}
				/>
			)}
			{deleteBudget && (
				<ConfirmDeleteModal
					budget={deleteBudget}
					onSaveClick={handleConfirmBudgetDelete}
					onCancelClick={closeModal}
					isOpen
				/>
			)}
		</>
	);
};

const Pots = styled.div`
	padding: 45px 0;
	h2 {
		color: '#000';
		font-size: 28px;
		line-height: 35px;
		font-family: 'TTInterfaces Bold';
		margin-bottom: 16px;
		@media (max-width: 900px) {
			font-size: 21px;
		}
	}
	h2 + div,
	div.filter + div {
		> p:first-child {
			line-height: 27px;
			font-size: 18px;
			opacity: 40%;
			font-family: 'TTInterfaces Medium';
			margin: 0;
			@media (max-width: 900px) {
				font-size: 16px;
			}
		}
		> p.info {
			padding: 18px 25px;
			background-color: #f3efed;
			border-radius: 20px;
			display: flex;
			align-items: start;
			line-height: 25px;
			fon-size: 18px;
			font-family: 'TTInterfaces Medium';
			color: #8a6857;
			gap: 12px;
			margin: 35px 0 60px 0;
			@media (max-width: 900px) {
				margin-bottom: 40px;
				font-size: 15px;
				svg {
					min-width: 15px;
				}
			}
		}
	}
	.filter > div {
		display: flex;
		align-items: center;
		height: 40px;
		margin-bottom: 16px;
	}
	.filter > div {
		a {
			font-family: 'TTInterfaces DemiBold';
			font-size: 14.5px;
			line-height: 20px;
			white-space: nowrap;
			padding: 10px 16px;
			color: rgb(29, 41, 57);
			text-decoration: none;
			display: flex;
			background: linear-gradient(0deg, #ffffff, #ffffff);
		}
		a:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border: 1px solid rgb(208, 213, 221);
		}
		a:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border: 1px solid rgb(208, 213, 221);
			border-left: 0;
		}
		a.selected {
			color: #fff;
			background: #000;
		}
	}
`;

export default ManageBudgetsWrapper;
