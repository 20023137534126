/* eslint-disable react/prop-types */
import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CSVLink } from 'react-csv';
import * as _ from 'lodash';
import { BoxInputLabel } from '../../../styles/StylesV2';
import { Paragraph } from '../../../styles/StylesV3Main';
import ConfirmationPopUp from '../../utilities/ConfirmationPopUp';
import useClient from '../../../hooks/useClient';
import config from '../../../config';

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	color: red;
`;

const StyledDateContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const StyledInputBox = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 10px;
`;

const StyledLabel = styled(BoxInputLabel)`
	text-align: left;
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledCsvLink = styled(CSVLink)`
	display: none;
`;

const DatePickerDescription =
	'Select a start and end date to generate a report on how many points your team members received and spent during that period';

const ReportHeaders = [
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Email', key: 'email' },
	{ label: 'Amount Received (Points)', key: 'pointsIn' },
	{ label: 'Amount Received (Currency)', key: 'pointsInCurrency' },
	{ label: 'Amount Spent (Points)', key: 'pointsOut' },
	{ label: 'Amount Spent (Currency)', key: 'pointsOutCurrency' },
	{ label: 'Currency', key: 'currency' },
	{ label: 'Team Status', key: 'teamStatus' },
	{ label: 'Subscription Status', key: 'subscriptionStatus' },
];

const TeamReports = ({ team, showDatePickers, setShowDatePickers }) => {
	const client = useClient();
	const csvRef = useRef();
	const [reportData, setReportData] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const validationSchema = Yup.object({
		startDate: Yup.date()
			.max(moment().endOf('day'), 'Start date cannot be in the future.')
			.required()
			.typeError('Start date is required.'),
		endDate: Yup.date()
			.min(Yup.ref('startDate'), 'End date cannot be before the start date.')
			.required()
			.typeError('End date is required.'),
	});

	const handleReportRequest = useCallback(
		async (values) => {
			setIsLoading(true);
			const startDate = moment(values.startDate).format('YYYY-MM-DD');
			const endDate = moment(values.endDate).format('YYYY-MM-DD');
			const searchParams = new URLSearchParams();
			searchParams.set('startDate', startDate);
			searchParams.set('endDate', endDate);
			try {
				const response = await client.get(
					`${config.teams.HOST}/teams/tax-report/${team.id}?${searchParams}`
				);
				setReportData({
					fileName: `${team.name} - ${moment(values.startDate).format(
						'YYYYMMDD'
					)} to ${moment(values.endDate).format('YYYYMMDD')}.csv`,
					data: _.sortBy(response.data, ['email']),
				});
				csvRef.current.link.click();
				setShowDatePickers(false);
			} finally {
				setIsLoading(false);
			}
		},
		[team.id]
	);

	return (
		<div>
			{!!reportData && (
				<StyledCsvLink
					data={reportData.data}
					filename={reportData.fileName}
					headers={ReportHeaders}
					ref={csvRef}
				/>
			)}
			{showDatePickers && (
				<Formik
					validationSchema={validationSchema}
					initialValues={{
						startDate: null,
						endDate: null,
					}}
				>
					{({
						values,
						isSubmitting,
						isValidating,
						isValid,
						dirty,
						setFieldValue,
					}) => {
						return (
							<Form>
								<ConfirmationPopUp
									show={showDatePickers}
									showCallback={() => {
										setShowDatePickers(false);
									}}
									callback={() => handleReportRequest(values)}
									title="Generate Tax Report"
									confirmText="Download Report"
									disabled={isSubmitting || isValidating || !(isValid && dirty)}
									isLoading={isLoading}
								>
									<Row noGutters>
										<Col xs={{ span: 10, offset: 1 }} className="px-0 py-0">
											<Paragraph className="mt-2">
												{DatePickerDescription}
											</Paragraph>
										</Col>
										<Col xs={{ span: 10, offset: 1 }} className="px-0 py-0">
											<StyledLabel>Start Date</StyledLabel>
											<StyledDateContainer>
												<Field
													type="date"
													name="startDate"
													as={StyledInputBox}
													value={moment(values.startDate).format('YYYY-MM-DD')}
													onChange={(e) => {
														setFieldValue('startDate', moment(e.target.value));
													}}
												/>
											</StyledDateContainer>
											<ErrorMessage
												name="startDate"
												component={StyledErrorMessage}
											/>
										</Col>
										<Col xs={{ span: 10, offset: 1 }} className="px-0 py-0">
											<StyledLabel>End Date</StyledLabel>
											<StyledDateContainer>
												<Field
													type="date"
													name="endDate"
													as={StyledInputBox}
													value={moment(values.endDate).format('YYYY-MM-DD')}
													onChange={(e) => {
														setFieldValue('endDate', moment(e.target.value));
													}}
												/>
											</StyledDateContainer>
											<ErrorMessage
												name="endDate"
												component={StyledErrorMessage}
											/>
										</Col>
									</Row>
								</ConfirmationPopUp>
							</Form>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

export default TeamReports;
