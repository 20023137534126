import React from 'react';
import styled from 'styled-components';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import StatusInfo from '../../../components/utilities/StatusInfo';
import Loading from '../../../components/utilities/Loading';
import { PSansMediumText } from '../../StylesV2';
import { Paragraph } from '../../StylesV3Main';

export type TransactionTypes = {
	transactions: { [key: string | 'authorization' | 'transactions']: string }[];
};

const GreyTextStyled = styled.p<{
	color?: string;
	strike?: boolean;
}>`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	margin: 0px;
	${(props) =>
		props.strike
			? `
    text-decoration: line-through;
  `
			: ''}
`;

const PointsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: hidden;
`;

const InfoContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: start;
	align-items: center;
`;

const StyledMerchant = styled(Paragraph)``;

const StyledDate = styled(Paragraph)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const StyledCurrencyAmount = styled.h5<{
	strike: boolean;
}>`
	white-space: nowrap;
	font-family: 'TTInterfaces DemiBold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	font-size: 16px;
	text-align: right;
	${(props) =>
		props.strike
			? `
    text-decoration: line-through;
  `
			: ''}
`;

const StyledCol = styled(Col)`
	padding: 0;
`;

const CenteredCol = styled(Col)`
	align-items: center;
	justify-content: end;
	display: flex;
	padding-right: 0px;
`;

const shouldStrikeThrough = (type: string) => {
	switch (type) {
		case 'Refunded':
		case 'Declined':
			return true;
		default:
			return false;
	}
};

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 48px 0 48px;
`;

const SubTitle = styled.h1`
	font-style: normal;
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 32px;
	@media (max-width: 992px) {
		font-size: 24px;
		line-height: 29px;
		width: calc(100% - 136px);
		padding-top: 16px;
	}
`;

const TransactionContainer = styled.div`
	@media (max-width: 992px) {
		display: none;
	}
`;

const MobileTransactionContainer = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: block;
	}
`;

const TransactionsTable = ({ records }: { records: TransactionTypes }) => {
	const merchantInfo = (row: any) => {
		return (
			<InfoContainer>
				<Row
					style={{
						position: 'absolute',
						width: '90vh',
						minWidth: '600px',
					}}
				>
					<StyledCol md={4} lg={2}>
						<StyledDate>{`${moment(row.created).format('MMM DD')}`}</StyledDate>
					</StyledCol>
					<Col md={8} lg={10}>
						<StyledMerchant>
							{row.merchant_data?.name ?? 'Transaction'}
						</StyledMerchant>
					</Col>
				</Row>
			</InfoContainer>
		);
	};

	const amountInfo = (row: any) => {
		const shouldStrike = shouldStrikeThrough(row.status.toLowerCase());
		return (
			<PointsContainer>
				<Row style={{ justifyContent: 'end' }}>
					<CenteredCol md={{ span: 3, offset: 4 }}>
						<StatusInfo text={row.status} status={row.status.toLowerCase()} />
					</CenteredCol>
					<CenteredCol md={3}>
						<StyledCurrencyAmount
							strike={shouldStrike}
						>{`${row.amount.toLocaleString('en-GB', {
							style: 'currency',
							currency: row.currency || 'GBP',
						})}`}</StyledCurrencyAmount>
					</CenteredCol>
				</Row>
			</PointsContainer>
		);
	};

	const customStyles = {
		head: {
			style: {
				display: 'none',
			},
		},
		rows: {
			style: {
				minHeight: '72px',
			},
		},
		cells: {
			style: {
				paddingLeft: '0px',
				paddingRight: '0px',
			},
		},
	};

	const columns = [
		{
			name: 'Date',
			cell: (row: any) => merchantInfo(row),
		},
		{
			name: 'Amount (Currency)',
			cell: (row: any) => amountInfo(row),
		},
	];

	return (
		<DataTable
			columns={columns}
			data={records.transactions}
			pagination
			progressComponent={<Loading />}
			customStyles={customStyles}
		/>
	);
};

const MobileTransactionsTable = ({
	records,
}: {
	records: TransactionTypes;
}) => {
	const mobileMerchantInfo = (row: any) => {
		return (
			<div>
				<Row>
					<PSansMediumText>
						{row.merchant_data?.name ?? 'Transaction'}
					</PSansMediumText>
				</Row>
				<Row className="d-flex justify-content-start align-items-center">
					<GreyTextStyled>{`${moment(row.created).format(
						'MMM DD'
					)}`}</GreyTextStyled>
					<PSansMediumText style={{ padding: '0px 5px' }}>
						{' • '}
					</PSansMediumText>
					<StatusInfo
						text={row.status}
						status={row.status.toLowerCase()}
						background={false}
					/>
				</Row>
			</div>
		);
	};

	const mobileAmountInfo = (row: any) => {
		const shouldStrike = shouldStrikeThrough(row.status.toLowerCase());

		return (
			<PointsContainer>
				<Row noGutters style={{ justifyContent: 'end' }}>
					<StyledCurrencyAmount
						strike={shouldStrike}
					>{`${row.amount.toLocaleString('en-GB', {
						style: 'currency',
						currency: row.currency || 'GBP',
					})}`}</StyledCurrencyAmount>
				</Row>
			</PointsContainer>
		);
	};

	const customStyles = {
		head: {
			style: {
				display: 'none',
			},
		},
		rows: {
			style: {
				minHeight: '72px',
			},
		},
	};

	const columns: TableColumn<any>[] = [
		{
			name: 'Info',
			cell: (row) => mobileMerchantInfo(row),
		},
		{
			name: 'Amount',
			cell: (row) => mobileAmountInfo(row),
		},
	];

	return (
		<DataTable
			columns={columns}
			data={records.transactions}
			pagination
			progressComponent={<Loading />}
			customStyles={customStyles}
		/>
	);
};

const Transactions = ({ transactions }: { transactions: TransactionTypes }) => {
	return (
		<>
			<StyledHr />
			<SubTitle>Transactions</SubTitle>
			<TransactionContainer>
				<TransactionsTable records={transactions} />
			</TransactionContainer>
			<MobileTransactionContainer>
				<MobileTransactionsTable records={transactions} />
			</MobileTransactionContainer>
		</>
	);
};

export default Transactions;
