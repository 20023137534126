import React from 'react';
import { useAuth0 } from '../../../contexts/react-auth0-spa';
import Authenticated from './Authenticated';
import NonAuthenticated from './NonAuthenticated';

const Booking = (props: any) => {
	const { isAuthenticated } = useAuth0();
	// eslint-disable-next-line react/jsx-props-no-spreading
	return isAuthenticated ? <Authenticated {...props} /> : <NonAuthenticated />;
};

export default Booking;
