/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// import modules
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

// Import context
import { useAuth0 } from '../contexts/react-auth0-spa';

// Import utilities
import config from '../config';
import client from '../api/client';

// Import styles
import {
	StyledPFancy,
	StyledPSans,
	StyledPSansMedium,
	MainButton,
	StyledP2,
	StyledH1,
	StyledP,
} from '../styles/MainStyles';

// Import component
import { ReactComponent as Logo } from '../logowhite.svg';
import AuthoriseInvite from '../components/signInUp/AuthoriseInvite';
import ResetPassword from '../components/signInUp/ResetPassword';
import BackButtonContainer from '../components/signInUp/BackButtonContainer';
import TroubleLoginPopup from '../components/signInUp/TroubleLoginPopup';
import { useUserContext } from '../contexts/userContext';

const StyledLeftContainer = styled.div`
	width: 100%;
	height: 100%;
	@media only screen and (max-width: 991.98px) {
		display: none;
	}
`;

const StyledSide = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	@media only screen and (max-width: 1200px) {
		height: 100%;
	}
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 80%;
`;

const StyledLogo = styled.div`
	position: absolute;
	left: 32px;
	top: 32px;
`;

const StyledContainer = styled.div`
	width: 100%;
	padding: 10px 0px;
`;

const StyledCenterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const StyledPara = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
`;

const StyledRowSpaceBetween = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 10px;

	@media only screen and (max-width: 991.98px) {
		padding: 2% 0%;
	}
`;

const StyledRightContainer = styled.div`
	width: 100%;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-top: 3%;
	@media (max-width: 991.98px) {
		margin-top: 9%;
		justify-content: start;
	}
`;
const SubContainer = styled.div`
	width: 508px;

	@media (max-width: 991.98px) {
		width: 90%;
		height: 100%;
	}
`;

const StyledRow = styled(Row)`
	min-height: 100vh;

	@media (max-width: 991.98px) {
		min-height: auto;
	}
`;

const StyledCol = styled(Col)`
	min-height: 750px;

	@media (max-width: 991.98px) {
		min-height: auto;
	}
`;

const Invite = ({ match }) => {
	// Contexts
	const { user, logout } = useAuth0();
	const { updateUserData } = useUserContext();

	// States
	const [step, setStep] = useState('start');
	const [invite, setInvite] = useState();
	const [error, setError] = useState('');
	const [showPopup, setShowPopup] = useState(true);

	const consumeInvite = useCallback(async () => {
		try {
			await client.put(
				`${config.users.HOST}/users/invite/${match.params.hash}`
			);
		} catch (err) {
			console.error(err.message);
		}
	}, [match.params.hash]);

	const initInvite = useCallback(async () => {
		try {
			const response = await client.get(
				`${config.api.HOST}/invite/${match.params.hash}`
			);
			if (response.status !== 200 || response.data.msg) {
				setStep('no invite found');
			} else {
				const { status, email: inviteEmail } = response.data.invite;
				switch (status) {
					case 'Open':
						if (!user) {
							setInvite(response.data);
							setStep('invite ready');
						} else if (
							inviteEmail === 'pinnacle invite' ||
							user.email.toLowerCase() === inviteEmail.toLowerCase()
						) {
							await consumeInvite();
							await updateUserData();
							setStep('redirect');
						} else {
							setStep('wrong email');
						}
						break;
					case 'Consumed':
						setStep('invite consumed');
						break;
					default:
						setStep('invite cancelled');
						break;
				}
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [match.params.hash, consumeInvite, user, updateUserData]);

	useEffect(() => {
		initInvite();
	}, [initInvite]);

	return (
		<StyledRow noGutters>
			<StyledCol xs={12} sm={12} md={12} lg={4}>
				<StyledLeftContainer>
					<StyledSide>
						<StyledImage loading="lazy" src="../images/login/login1.png" />
						<StyledLogo>
							<Logo style={{ stroke: 'none', fill: 'white' }} />
						</StyledLogo>
					</StyledSide>
				</StyledLeftContainer>
			</StyledCol>
			<Col xs={12} sm={12} md={12} lg={8}>
				<StyledRightContainer>
					{step === 'redirect' && <Redirect to="/" />}
					{step === 'start' && <StyledPFancy>Loading...</StyledPFancy>}
					{step !== 'start' && (
						<>
							{(step === 'reset password' || step === 'invite ready') && (
								<BackButtonContainer
									show={step === 'reset password'}
									handleBackButton={() => setStep('invite ready')}
								/>
							)}
							<MainContainer
								style={{ justifyContent: 'center' }}
								className="pb-5"
							>
								<SubContainer>
									{step === 'no invite found' && (
										<>
											<StyledCenterContainer>
												<StyledH1 className="mt-4 mb-3">Your invite</StyledH1>
											</StyledCenterContainer>

											<StyledCenterContainer>
												<StyledPSans>
													No invite has been found matching your link.
												</StyledPSans>
											</StyledCenterContainer>

											<MainButton
												className="my-4"
												onClick={() => {
													window.location.href = 'https://www.withjuno.com';
												}}
											>
												Go back to Juno main site
											</MainButton>
										</>
									)}
									{step === 'invite consumed' && (
										<>
											<StyledCenterContainer>
												<StyledH1 className="mt-4 mb-3">Your invite</StyledH1>
											</StyledCenterContainer>

											<StyledCenterContainer>
												<StyledPSans>
													This invite has already been used.
												</StyledPSans>
											</StyledCenterContainer>
											<MainButton
												className="my-4"
												onClick={() => {
													setStep('redirect');
												}}
											>
												Go to Juno homepage
											</MainButton>
										</>
									)}
									{step === 'invite cancelled' && (
										<>
											<StyledCenterContainer>
												<StyledH1 className="mt-4 mb-3">Your invite</StyledH1>
											</StyledCenterContainer>

											<StyledCenterContainer>
												<StyledPSans>
													This invite has been cancelled.
												</StyledPSans>
											</StyledCenterContainer>
											<MainButton
												className="my-4"
												onClick={() => {
													setStep('redirect');
												}}
											>
												Go to Juno homepage
											</MainButton>
										</>
									)}
									{step === 'wrong email' && (
										<>
											<StyledCenterContainer>
												<StyledH1 className="mb-4">Your invite</StyledH1>
											</StyledCenterContainer>
											<StyledP2>
												Congratulations! You have been invited to join Juno by
												your team admin. The details are below. All you have to
												do now is to accept the invitation, sign up and you will
												automatically be added to your new team.
											</StyledP2>

											<StyledP2 className="pt-3">
												The email you are logged in with is different from the
												one in the invite. If you wish to be added to the team,
												you need to logout from this account and try again with
												the right email.
											</StyledP2>
											<StyledCenterContainer>
												<MainButton
													className="my-4"
													onClick={() => {
														logout();
													}}
												>
													Logout
												</MainButton>
											</StyledCenterContainer>
										</>
									)}
									{step === 'reset password' && (
										<StyledContainer mode="signup">
											<StyledCenterContainer>
												<StyledH1 className="mt-5 mb-4">Your invite</StyledH1>
											</StyledCenterContainer>

											<Row noGutters as={StyledPara} className="mb-5">
												<StyledP2>
													{`Congratulations! You have been invited to join Juno by your team
                                admin. The details are below. All you have to do now is to sign ${
																	invite.user ? 'in' : 'up'
																}
                                and you will automatically be added to your new team.`}
												</StyledP2>
											</Row>

											<Row
												noGutters
												className="justify-content-between mb-5"
												as={StyledRowSpaceBetween}
											>
												<Row noGutters as={StyledCenterContainer}>
													<StyledPSans>Your role: </StyledPSans>
													<StyledPSansMedium>
														{invite.invite.role === 'member'
															? 'Employee'
															: invite.invite.role.slice(0, 1).toUpperCase() +
															  invite.invite.role
																	.slice(1, invite.invite.role.length)
																	.toLowerCase()}
													</StyledPSansMedium>
												</Row>
												<Row noGutters as={StyledCenterContainer}>
													<StyledPSans>Your team: </StyledPSans>
													<StyledPSansMedium>
														{invite.team.name}
													</StyledPSansMedium>
												</Row>
											</Row>
											<ResetPassword
												modeCallback={() => setStep('invite ready')}
											/>
										</StyledContainer>
									)}
									{step === 'invite ready' && (
										<>
											{error !== '' && showPopup && (
												<TroubleLoginPopup
													handleClosePopup={() => setShowPopup(false)}
												/>
											)}
											<StyledContainer mode="signup">
												<StyledCenterContainer>
													<StyledH1 className="mt-5 mb-4">Your invite</StyledH1>
												</StyledCenterContainer>

												<Row noGutters as={StyledPara} className="mb-5">
													<StyledP2>
														{`Congratulations! You have been invited to join Juno by your team
                                admin. The details are below. All you have to do now is to sign ${
																	invite.user ? 'in' : 'up'
																}
                                and you will automatically be added to your new team.`}
													</StyledP2>
												</Row>

												<Row
													noGutters
													className="justify-content-between mb-5"
													as={StyledRowSpaceBetween}
												>
													<Row noGutters as={StyledCenterContainer}>
														<StyledPSans>Your role: </StyledPSans>
														<StyledPSansMedium>
															{invite.invite.role}
														</StyledPSansMedium>
													</Row>
													<Row noGutters as={StyledCenterContainer}>
														<StyledPSans>Your team: </StyledPSans>
														<StyledPSansMedium>
															{invite.team.name}
														</StyledPSansMedium>
													</Row>
												</Row>

												<AuthoriseInvite
													errorCallback={(err) => {
														setError(err);
													}}
													handleChangePasswordReset={() =>
														setStep('reset password')
													}
													invite={invite}
												/>
											</StyledContainer>
										</>
									)}
								</SubContainer>
							</MainContainer>
						</>
					)}
					{step === 'start' && <StyledP>Loading...</StyledP>}
				</StyledRightContainer>
			</Col>
		</StyledRow>
	);
};

export default Invite;
