import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { SelectStyled, StyledLabel } from '../../styles/ProductStyles';

type JunoDaySelectorProps = {
	junoDay: string;
	setJunoDay: (junoDay: string) => void;
	disableCurrentDay?: boolean;
	autoFocus: boolean;
};

const JunoDaySelector = ({
	junoDay,
	setJunoDay,
	disableCurrentDay = false,
	autoFocus,
}: JunoDaySelectorProps) => {
	const days = Object.entries({
		'1st': 1,
		'2nd': 2,
		'3rd': 3,
		'4th': 4,
		'5th': 5,
		'6th': 6,
		'7th': 7,
		'8th': 8,
		'9th': 9,
		'10th': 10,
		'11th': 11,
		'12th': 12,
		'13th': 13,
		'14th': 14,
		'15th': 15,
		'16th': 16,
		'17th': 17,
		'18th': 18,
		'19th': 19,
		'20th': 20,
		'21st': 21,
		'22nd': 22,
		'23rd': 23,
		'24th': 24,
		'25th': 25,
		'26th': 26,
		'27th': 27,
		'28th': 28,
	});
	const currentDay = moment().date();

	return (
		<>
			<StyledLabel htmlFor="junoDay">Juno Day</StyledLabel>
			<SelectInput
				id="junoDay"
				value={junoDay}
				onChange={({ currentTarget }: any) => {
					setJunoDay(currentTarget.value);
				}}
				autoFocus={autoFocus}
			>
				{days.map((day) => {
					return (
						<option
							value={day[1]}
							key={day[0]}
							disabled={disableCurrentDay && +day[1] === currentDay}
						>
							Every {day[0]} of the month
						</option>
					);
				})}
			</SelectInput>
		</>
	);
};

const SelectInput = styled(SelectStyled)`
	&:focus {
		outline: revert;
	}
`;

export default JunoDaySelector;
