import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { CSVLink } from 'react-csv';
import { useCSVReader } from 'react-papaparse';
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Formik, Form } from 'formik';
import {
	H1,
	H4TextSecondary,
	Paragraph,
	ParaSemiBold,
	Small,
} from '../../../../styles/StylesV3Main';
import { useUserContext } from '../../../../contexts/userContext';
import { useClient } from '../../../../hooks';
import Button from '../../../../styles/shared/Button/Button';
import Modal from '../../../../styles/shared/Modal';

const ShowErrorMessage = () => {
	return (
		<StyledErrorMessage>
			This file might be in the wrong format or a field is empty. Please try
			again.
		</StyledErrorMessage>
	);
};

interface CSVUploadModalProps {
	show: boolean;
	setShowCSV: (val: boolean) => void;
	team: any;
	handleUpdate: any;
}

const CSVUploadModal = ({
	show,
	setShowCSV,
	team,
	handleUpdate,
}: CSVUploadModalProps) => {
	const inviteValues = useRef<
		| {
				email: any;
				plan?: string;
				role?: string;
				welcomePoints: number;
				budget: number;
		  }[]
		| null
	>(null);
	const { userData } = useUserContext();
	const { CSVReader } = useCSVReader();
	const client = useClient();
	const [csvData, setCsvData] = useState<unknown[]>([]);

	const UploadInstructions = [
		{ description: 'Download the CSV template', key: 1 },
		{
			description:
				'Fill in the CSV as per instructions. Keep the headers as they are',
			key: 2,
		},
		{ description: 'Save the file as .csv', key: 3 },
		{ description: 'Upload the saved file', key: 4 },
		{ description: 'Click Add', key: 5 },
	];

	const csvSampleData = [
		{
			email: 'example@withjuno.com',
			role: 'Employee',
			addToPlan: 'Yes',
			welcomePoints: '0',
			budget: '150',
		},
	];

	const validationSchema = Yup.object().shape({
		invites: Yup.array().of(
			Yup.object().shape({
				email: Yup.string()
					.email('This file is not in the right format, please try again')
					.required('Email is required')
					.nullable(),
				role: Yup.string().required('Role is required').nullable(),
				addToPlan: Yup.string().required('Plan is required').nullable(),
				welcomePoints: Yup.number().integer().required().min(0).nullable(),
				budget: Yup.number().integer().min(0).nullable(),
			})
		),
	});

	const createInvites = async (invites: any) => {
		try {
			await client.post(`/invites/${team.id}`, {
				invites: invites.filter((item: any) => item.email !== ''),
				senderId: userData.id,
			});

			handleUpdate();
		} catch (err: any) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const handleInput = (
		results: any,
		values: {
			invites: {
				email: string;
				role: string;
				addToPlan: string;
				welcomePoints: number;
				budget: number;
			}[];
		},
		setValues: any,
		setErrors: any
	) => {
		const { data } = results;

		try {
			const invitesLocal = data
				.filter(
					(item: any) => item.email && item.email !== 'example@withjuno.com'
				)
				.map((item: any) => {
					const addToPlan =
						item?.addToPlan.toLowerCase() === 'yes' ? 'On plan' : 'Not on plan';
					return {
						email: item?.email.toLowerCase(),
						role: item?.role.toLowerCase() === 'admin' ? 'owner' : 'member',
						addToPlan: team?.stripeSubscriptionId ? addToPlan : 'Not on plan',
						welcomePoints: +item.welcomePoints,
						budget: item?.budget,
					};
				});

			setCsvData((previousData: any) => {
				const newData = previousData;
				invitesLocal.forEach((row: any) => newData.push(row));
				return newData;
			});

			setValues({
				...values,
				invites: csvData,
			});
		} catch (err: any) {
			// eslint-disable-next-line no-console
			console.error(err);
		}

		setErrors({});
		setCsvData([]);
	};

	const headers = [
		{ label: 'Email address', key: 'email' },
		{
			label: "Role ('Employee' or 'Admin')",
			key: 'role',
		},
		{
			label: "On Plan ('Yes' or 'No')",
			key: 'addToPlan',
		},
		{
			label: 'Welcome points',
			key: 'welcomePoints',
		},
		{
			label: 'Budget',
			key: 'budget',
		},
	];

	const headersMap: {
		[key: string]: string;
	} = {
		'Email address': 'email',
		"Role ('Employee' or 'Admin')": 'role',
		"On Plan ('Yes' or 'No')": 'addToPlan',
		'Welcome points': 'welcomePoints',
		Budget: 'budget',
	};

	const prevButtonState = useRef<'default' | 'disabled' | 'loading'>('default');

	const handleState = (
		isSubmitting: boolean,
		isValidating: boolean,
		isValid: boolean,
		dirty: boolean
	) => {
		if (!show) return prevButtonState.current;

		if (isSubmitting) prevButtonState.current = 'loading';
		else if (isValidating || !(isValid && dirty))
			prevButtonState.current = 'disabled';
		else prevButtonState.current = 'default';

		return prevButtonState.current;
	};

	return (
		<Modal open={show} onClose={() => setShowCSV(false)}>
			<div
				style={{
					position: 'relative',
					padding: '40px 45px',
					maxWidth: '500px',
				}}
			>
				<Formik
					initialValues={{
						invites: [
							{
								email: '',
								role: 'Employee',
								addToPlan: 'On plan',
								welcomePoints: 0,
								budget: 0,
							},
						],
					}}
					validationSchema={validationSchema}
					enableReinitialize
					onSubmit={async (values, { setSubmitting, setValues, setErrors }) => {
						inviteValues.current = values.invites;
						await createInvites(values.invites);
						setShowCSV(false);
						setSubmitting(false);
						setValues(values);
						setErrors({});
					}}
				>
					{({
						values,
						errors,
						setValues,
						isSubmitting,
						isValidating,
						isValid,
						dirty,
						setErrors,
					}) => {
						return (
							<Form>
								<Row noGutters>
									<Col className="px-0 py-0">
										<StyledRow
											noGutters
											className="justify-content-center align-items-center mb-4"
										>
											<H1
												style={{
													color: 'black',
													fontSize: 32,
												}}
											>
												Add a member to the team
											</H1>
										</StyledRow>

										<StyledRow
											noGutters
											className="justify-content-start align-items-center pb-4"
										>
											<H4TextSecondary>Instructions</H4TextSecondary>
										</StyledRow>

										{UploadInstructions.map((item) => (
											<StyledRow
												key={item.key}
												noGutters
												className="justify-content-start align-items-start pb-4"
											>
												{item.key === 1 ? (
													<StyledRow
														key={item.key}
														noGutters
														className="justify-content-start align-items-start"
													>
														<Col xs={1} className="px-0 py-0">
															<Paragraph>{item.key}.</Paragraph>
														</Col>
														<InstructionCol xs={11} className="px-0 py-0">
															<CSVLink
																data={csvSampleData}
																headers={headers}
																filename="Juno-invites-template.csv"
																style={{
																	width: '100%',
																}}
															>
																<TextUnderline>
																	{item.description}
																</TextUnderline>
															</CSVLink>
														</InstructionCol>
													</StyledRow>
												) : (
													<>
														<Col xs={1} className="px-0 py-0">
															<Paragraph>{item.key}. </Paragraph>
														</Col>
														<InstructionCol xs={11} className="px-0 py-0">
															<Paragraph>{item.description}</Paragraph>
														</InstructionCol>
													</>
												)}
											</StyledRow>
										))}
									</Col>
								</Row>
								<UploadButtonDiv>
									<StyledRow
										noGutters
										className="justify-content-start align-items-center"
									>
										<CSVReader
											config={{
												header: true,
												dynamicTyping: true,
												skipEmptyLines: true,
												transformHeader: (header: any) => {
													return headersMap[header];
												},
											}}
											onUploadAccepted={(results: any) => {
												handleInput(results, values, setValues, setErrors);
											}}
											onDragOver={(event: DragEvent) => {
												event.preventDefault();
											}}
											onDragLeave={(event: DragEvent) => {
												event.preventDefault();
											}}
										>
											{({ getRootProps, acceptedFile }: any) => (
												<>
													<div // eslint-disable-next-line react/jsx-props-no-spreading
														{...getRootProps()}
													>
														<StyledRow
															noGutters
															className="justify-content-start align-items-center"
														>
															<UploadCSVButton>
																<CloudUploadIcon />
																<ParaSemiBold className="ml-2">
																	Upload CSV
																</ParaSemiBold>
															</UploadCSVButton>
														</StyledRow>
													</div>
													{acceptedFile && (
														<Paragraph>{acceptedFile.name}</Paragraph>
													)}
												</>
											)}
										</CSVReader>

										<StyledRow
											noGutters
											className="justify-content-start align-items-center"
										>
											{errors.invites !== undefined && <ShowErrorMessage />}
										</StyledRow>
									</StyledRow>
								</UploadButtonDiv>
								<StyledRow
									noGutters
									className="justify-content-between align-items-center pt-4"
								>
									<Col xs={6} className="px-0 py-0 pr-2">
										<Button
											buttonType="secondary"
											state="default"
											onClick={() => setShowCSV(false)}
										>
											Cancel
										</Button>
									</Col>
									<Col xs={6} className="px-0 py-0 pl-2">
										<Button
											state={handleState(
												isSubmitting,
												isValidating,
												isValid,
												dirty
											)}
											onClick={() => {}}
										>
											Add
										</Button>
									</Col>
								</StyledRow>
							</Form>
						);
					}}
				</Formik>
			</div>
		</Modal>
	);
};

const StyledErrorMessage = styled(Small)`
	font-family: 'Roobert Medium';
	color: #cc1440;
	margin-top: 24px;
`;

const TextUnderline = styled(Paragraph)`
	text-decoration: underline;
	text-underline-position: under;
	text-transform: capitalize;
	text-decoration-thickness: 1.5px;
	cursor: pointer;
`;

const StyledRow = styled(Row)`
	width: 100%;
`;

const InstructionCol = styled(Col)`
	position: relative;
	right: 4%;
`;

const UploadButtonDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px;
	padding: 24px 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 1px solid rgba(0, 0, 0, 0.05);
`;

const UploadCSVButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	cursor: pointer;
	width: 155px;
	height: 45px;

	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
	margin-right: 16px;
`;

export default CSVUploadModal;
