import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import ChipInput from 'material-ui-chip-input';
import { Field } from 'formik';
import { H5, SmallTextSecondary } from '../../../../styles/StylesV3Main';

type ChipInputFieldProps = {
	vals: {
		email: any;
		plan?: string;
		role?: string;
	};
	errs: any;
	touched: any;
	setFieldValues: any;
};

const ChipInputField = ({
	vals,
	errs,
	touched,
	setFieldValues,
}: ChipInputFieldProps) => {
	const handleAdd = (
		chip: string,
		values: {
			email: any;
			plan?: string;
			role?: string;
		},
		setFieldValue: any | ''
	) => {
		const removeSpaces = chip.replace(/\s/gi, '');

		setFieldValue('email', [...values.email, removeSpaces]);
	};

	const handlePaste = (
		evt: React.ClipboardEvent<HTMLInputElement>,
		values: {
			email: any;
			plan?: string | undefined;
			role?: string | undefined;
		},
		setFieldValue: any | ''
	) => {
		evt.preventDefault();

		const paste: string = evt.clipboardData.getData('text');

		const removeSpaces = paste.replace(/\s/gi, '');

		setFieldValue('email', [
			...values.email,
			...removeSpaces.split(',').filter((t) => t.length > 0),
		]);
	};

	const handleDelete = (
		deletedEmail: string,
		values: {
			email: any;
			plan?: string;
			role?: string;
		},
		setFieldValue: any | ''
	) => {
		const e = values.email.filter(
			(emailAddr: string) => emailAddr !== deletedEmail
		);
		setFieldValue('email', e);
	};

	return (
		<Row
			noGutters
			className="justify-content-start align-items-center py-1 w-100"
		>
			<Col lg={12} xs={12}>
				<H5 className="mb-2">Email Address</H5>
				<Field
					id="email"
					name="email"
					as={ChipInput}
					style={{
						padding: ' 16px',
						background: 'rgba(0, 0, 0, 0.03)',
						borderRadius: '3px',
						minHeight: 61,
					}}
					blurBehavior="add"
					disableUnderline
					onPaste={(evt: React.ClipboardEvent<HTMLInputElement>) => {
						handlePaste(evt, vals, setFieldValues);
					}}
					value={vals.email}
					placeholder="john@juno.com"
					fullWidth
					newChipKeys={['Enter', 'Tab', ',']}
					onAdd={(chip: string) => {
						handleAdd(chip, vals, setFieldValues);
					}}
					onDelete={(deletedEmail: string) => {
						handleDelete(deletedEmail, vals, setFieldValues);
					}}
				/>

				<SmallTextSecondary className="mt-2">
					Add multiple at a time by separating each email with a comma
				</SmallTextSecondary>
				{errs.email && touched.email && (
					<StyledErrorMessage>
						{vals.email?.length
							? 'Please check if all emails are entered correctly'
							: 'Please enter one or more emails'}
					</StyledErrorMessage>
				)}
			</Col>
		</Row>
	);
};

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	margin-top: 8px;
	font: normal normal 400 12px/14px 'Roobert Regular';
	color: #cc1440;
`;

export default ChipInputField;
