import React, { useState, useEffect, useLayoutEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useCurrentTeam, useClient } from '../../hooks';
import config from '../../config';
import { useUserContext } from '../../contexts/userContext';
import Loading from '../../components/utilities/Loading';
import { CardDetails } from '../../styles/shared/JunoCard';
import CardDetail from '../../styles/shared/CardDetail';
import { ReactComponent as Juno } from './images/juno.svg';
import googlePay from './images/google_pay.svg';
import { ReactComponent as Visa } from './images/visa.svg';
import { ReactComponent as Plus } from './images/plus.svg';
import Pager from '../../styles/shared/Pager';
import RequestPhysicalCard from './RequestPhysicalCard';
import SetupCard from './SetupCard';
import SubmitExpense from './SubmitExpense';
import Pin from '../../styles/shared/Pin';

const Budget = () => {
	const { budgetId } = useParams<{ budgetId: string }>();
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	const history = useHistory();
	let page = +(params.get('page') || 1);
	const [card, setCard] = useState<CardDetails>();
	const showDetails = !!params.get('details') && !!card;
	const submitExpense = !!params.get('expense');
	const [transactions, setTransactions] = useState<[] | null>(null);
	const [physicalSuccess, setPhysicalSuccess] = useState(false);
	const [expenseSuccess, setExpenseSuccess] = useState(false);
	const client = useClient();
	const team = useCurrentTeam();
	const { userData, updateUserData } = useUserContext();
	const { stripeCardholderId } = team.teamMember;
	const setup = !!params.get('setup') && !card && !stripeCardholderId;
	const {
		name,
		policy,
		points,
		thumbnail,
		nextRenewal,
		renewalPeriod,
		renewalType,
		receiptRequired,
		physicalCard,
		budgetMembers,
	} = team.budgets.find(({ id }: any) => id === +budgetId);
	const requestPhysical =
		!!params.get('physical') &&
		!!card &&
		card.type !== 'physical' &&
		physicalCard;
	const memberAmount = budgetMembers[0].points;
	const { amountToLocalCurrency } = userData.currencyData;
	const formatter = (currency: string) =>
		new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency,
		});
	const memberLocalAmount = formatter(
		userData.currencyData.localCurrency
	).format(memberAmount * amountToLocalCurrency || 0);
	const budgetAmount = formatter(team.currency || 'gbp').format(points);
	useEffect(() => {
		(async () => {
			try {
				if (!stripeCardholderId) return;
				const { data } = await client.post(
					`${config.platform.HOST}/payments/authorized/cards/budgets/${budgetId}`
				);
				setCard({ ...data });
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		})();
	}, [stripeCardholderId, budgetId]);
	const query = (setParams: (p: URLSearchParams) => void) => {
		const newParams = new URLSearchParams(search);
		setParams(newParams);
		return newParams.toString();
	};
	const numberOfPages = Math.ceil((transactions?.length || 0) / 10);
	page = Math.min(page, numberOfPages + 1);
	const start = (page - 1) * 10;
	const transactionsPage = transactions?.slice(start, start + 10);
	useEffect(() => {
		(async () => {
			try {
				const { data } = await client.get(
					`${config.platform.HOST}/payments/authorized/cards/budgets/transactions/${budgetId}`
				);
				setTransactions(data.transactions);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		})();
	}, [budgetId]);
	useLayoutEffect(() => {
		if (expenseSuccess || physicalSuccess) {
			setTimeout(() => {
				document.querySelector('.success')?.scrollIntoView();
			}, 0);
		}
	}, [expenseSuccess, physicalSuccess]);
	return (
		<Container>
			<Banner thumbnail={thumbnail}>
				<div>
					<h1>
						{name} <span>{memberLocalAmount}</span>
					</h1>
					<div>
						<div>
							💰 {budgetAmount} {renewalPeriod}
						</div>
						{renewalType === 'accumulate' && <div>⏭️ Rolls over</div>}
						{nextRenewal && (
							<div>
								💸 Renews on {moment(nextRenewal).format('Do MMM YYYY')}
							</div>
						)}
					</div>
				</div>
			</Banner>
			{!submitExpense &&
				!expenseSuccess &&
				!requestPhysical &&
				!physicalSuccess &&
				!setup && (
					<>
						<Actions>
							<div>
								{stripeCardholderId &&
									(card ? (
										<Card>
											<div>
												<div>
													<div style={{ flex: 1 }}>
														<Juno />
													</div>
													<div className="cardNumber">
														•••• •••• •••• {card.last4}
													</div>
													<div className="cardholder">{card.fullName}</div>
												</div>
												<div>
													<div className={card.active ? 'active' : 'inactive'}>
														<div />
														{card.active ? 'Active' : 'Inactive'}
													</div>
													<div style={{ flex: 1 }} />
													<div className="googlePay" />
													<Visa />
												</div>
											</div>
											<div>
												<ActionButton
													type="button"
													onClick={() => {
														history.push(
															`${pathname}?${query((p) => {
																p.set('details', 'true');
															})}`,
															{
																scroll: false,
															}
														);
													}}
												>
													{card.type !== 'physical'
														? 'View card details'
														: 'View Pin'}
												</ActionButton>
												{card.type !== 'physical' && physicalCard && (
													<ActionButton
														type="button"
														onClick={() => {
															history.push(
																`${pathname}?${query((p) => {
																	p.set('physical', 'true');
																})}`,
																{
																	scroll: false,
																}
															);
														}}
													>
														Request physical card
													</ActionButton>
												)}
												<button
													className={card.active ? 'deactivate' : 'activate'}
													type="button"
													onClick={async () => {
														try {
															setCard({ ...card, active: !card.active });
															await client.put(
																`${config.platform.HOST}/payments/authorized/cards/budgets/${budgetId}`,
																{ active: !card.active }
															);
														} catch (error) {
															// eslint-disable-next-line no-console
															console.log(error);
															const { data } = await client.post(
																`${config.platform.HOST}/payments/authorized/cards/budgets/${budgetId}`
															);
															setCard({ ...data });
														}
													}}
												>
													{card.active ? 'Deactivate card' : 'Activate card'}
												</button>
												{card.type === 'physical' &&
													!card.isCardOlderThan7Days && (
														<div>
															<p>Delivery time: 2-3 business days</p>
															<p>
																Use Chip and Pin for your first transaction.
															</p>
														</div>
													)}
											</div>
										</Card>
									) : (
										<Loading />
									))}
								{!stripeCardholderId && (
									<Create>
										<div>
											<button
												type="button"
												onClick={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('setup', 'true');
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												<Plus />
											</button>
											<h2>Create a card</h2>
											<p>You can use your card to pay online or in person</p>
										</div>
										<div>
											<ActionButton
												type="button"
												onClick={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('setup', 'true');
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												Set up card
											</ActionButton>
										</div>
									</Create>
								)}
								<div>
									<Create>
										<div>
											<button
												type="button"
												onClick={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('expense', 'true');
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												<Plus />
											</button>
											<h2>Submit a new expense</h2>
											<p>
												Get reimbursed directly to your bank account for
												transactions made using your own funds
											</p>
										</div>
										<div>
											<ActionButton
												type="button"
												onClick={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('expense', 'true');
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												Submit expense
											</ActionButton>
										</div>
									</Create>
								</div>
							</div>
							<div>
								<h2>Policy</h2>
								<Editor
									editorState={EditorState.createWithContent(
										convertFromRaw(policy)
									)}
									onChange={() => {}}
									readOnly
								/>
							</div>
						</Actions>
						{showDetails && card.type !== 'physical' && (
							<CardDetail
								open
								setOpen={() => {
									history.push(
										`${pathname}?${query((p) => {
											p.delete('details');
										})}`,
										{
											scroll: false,
										}
									);
								}}
								cardInfo={card!}
							/>
						)}
						{showDetails && card.type === 'physical' && (
							<Pin
								pinOpen
								setPinOpen={() => {
									history.push(
										`${pathname}?${query((p) => {
											p.delete('details');
										})}`,
										{
											scroll: false,
										}
									);
								}}
								cardId={card.id}
							/>
						)}
						{(!transactions || !!transactions.length) && (
							<Transactions>
								<div />
								<h2>
									<span>💳</span> Transactions
								</h2>
								{transactions?.length ? (
									<div className="table">
										<table>
											<thead>
												<tr>
													<th>Date</th>
													<th>Transaction</th>
													<th>Status</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												{transactionsPage?.map(
													(
														{
															created,
															// eslint-disable-next-line camelcase
															merchant_data,
															status,
															amount,
															currency,
														}: any,
														id
													) => (
														// eslint-disable-next-line react/no-array-index-key
														<tr key={id}>
															<td>
																<h3>Date</h3>
																{moment(created).format('MMM DD')}
															</td>
															<td>
																<h3>Transaction</h3>
																{merchant_data?.name}
															</td>
															<td className={status.toLowerCase()}>
																<h3>Status</h3>
																<div>{status}</div>
															</td>
															<td className={status.toLowerCase()}>
																<h3>Amount</h3>
																<div>
																	{formatter(currency || 'gbp').format(amount)}
																</div>
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
										<Pager total={transactions.length} />
									</div>
								) : (
									!transactions && <Loading />
								)}
							</Transactions>
						)}
					</>
				)}
			{submitExpense && (
				<SubmitExpense
					memberAmount={memberAmount}
					receiptRequired={receiptRequired}
					policy={policy}
					save={async (expense: any) => {
						try {
							await client.post(
								`${config.platform.HOST}/payments/authorized/reimbursements/budgets/${budgetId}`,
								expense
							);
							await updateUserData();
							setExpenseSuccess(true);
							history.push(
								`${pathname}?${query((p) => {
									p.delete('expense');
								})}`,
								{
									scroll: false,
								}
							);
							const { data } = await client.get(
								`${config.platform.HOST}/payments/authorized/cards/budgets/transactions/${budgetId}`
							);
							setTransactions(data.transactions);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log(error);
						}
					}}
				/>
			)}
			{requestPhysical && (
				<RequestPhysicalCard
					card={card}
					save={async (shippingAddress: any) => {
						try {
							const { data } = await client.post(
								`${config.platform.HOST}/payments/authorized/cards/budgets/${budgetId}`,
								{ shippingAddress }
							);
							setPhysicalSuccess(true);
							setCard({ ...data });
							history.push(
								`${pathname}?${query((p) => {
									p.delete('physical');
								})}`,
								{
									scroll: false,
								}
							);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log(error);
						}
					}}
				/>
			)}
			{setup && (
				<SetupCard
					policy={policy}
					save={async (cardholder: any) => {
						try {
							const { data } = await client.post(
								`${config.platform.HOST}/payments/authorized/cards/budgets/${budgetId}`,
								{ cardholder }
							);
							await updateUserData();
							setCard({ ...data });
							history.push(
								`${pathname}?${query((p) => {
									p.delete('setup');
								})}`,
								{
									scroll: false,
								}
							);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log(error);
						}
					}}
				/>
			)}
			{expenseSuccess && (
				<Success className="success">
					<h2>💰Success</h2>
					<p>
						Your expense request has been submitted successfully, you now have{' '}
						{memberLocalAmount} in your pot!
					</p>
					<div>
						<button
							type="button"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							onClick={() => setExpenseSuccess(false)}
						>
							Back to pot
						</button>
					</div>
					<p>
						Approved reimbursements will be paid directly to your bank account
						on Tuesday mornings (for requests received before 5PM on Mondays)
						and Thursday evenings (for requests received before 4PM on
						Thursdays).
					</p>
				</Success>
			)}
			{physicalSuccess && (
				<Success className="success">
					<h2>💳 Success</h2>
					<p>Your card will arrive in 2-3 business days</p>
					<p>
						Use chip and pin for your first transaction. All future transactions
						can be contactless.
					</p>
					<p>Visit your pot page to see your pin.</p>
					<div>
						<button
							type="button"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							onClick={() => setPhysicalSuccess(false)}
						>
							Back to pot
						</button>
					</div>
				</Success>
			)}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1250px;
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

const Banner = styled.div<{ thumbnail: string }>`
	height: 400px;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	background-image: url(${({ thumbnail }) => thumbnail});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	> div {
		border-radius: 0 0 30px 30px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0 40px 40px 40px;
		height: 224px;
		color: #fff;
		background: linear-gradient(
			180deg,
			rgba(48, 48, 48, 0) 0%,
			rgba(48, 48, 48, 0.66) 48%,
			rgba(47.81, 47.81, 47.81, 0.95) 100%
		);
		h1 {
			font-size: 36px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 36px;
			font-weight: normal;
			margin: 0;
			span {
				font-family: 'TTInterfaces Regular';
			}
			@media (max-width: 992px) {
				line-height: 26px;
				font-size: 26px;
			}
		}
		h1 + div {
			font-size: 17px;
			font-family: 'TTInterfaces Medium';
			line-height: 24px;
			display: flex;
			margin-top: 32px;
			flex-wrap: wrap;
			gap: 12px;
			> div {
				border-radius: 50px;
				background-color: rgba(255, 255, 255, 0.12);
				padding: 4px 14px;
			}
			@media (max-width: 992px) {
				margin-top: 21px;
				font-size: 14px;
				line-height: 21px;
			}
		}
		@media (max-width: 992px) {
			padding: 0 24px 24px 24px;
		}
	}
	@media (max-width: 992px) {
		height: 264px;
		overflow: hidden;
	}
`;

const ActionButton = styled.button`
	display: flex;
	justify-content: center;
	border: 1px solid #d0d5dd;
	padding: 12px 16px;
	width: 100%;
	border-radius: 8px;
	background-color: transparent;
	color: #344054;
	font-size: 14px;
	font-family: 'TTInterfaces DemiBold';
	line-height: 20px;
	white-space: nowrap;
`;

const Create = styled.div`
	display: flex;
	padding: 20px 30px 30px 30px;
	gap: 12px;
	> div {
		display: flex;
		flex-direction: column;
	}
	> div:first-child {
		flex: 1;
		> button {
			width: 40px;
			height: 40px;
			margin-bottom: 26px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
			border-radius: 5px;
			border: none;
			background-color: transparent;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		h2 {
			color: #525252;
			font-size: 24px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 21px;
			margin-bottom: 14px;
		}
		p {
			color: #525252;
			font-size: 18px;
			font-family: 'TTInterfaces Regular';
			line-height: 21px;
			margin: 0;
			@media (max-width: 992px) {
				line-height: 24px;
			}
		}
	}
	${ActionButton} {
		width: 167px;
		@media (max-width: 992px) {
			margin-top: 20px;
		}
	}
	> div:last-child {
		justify-content: flex-end;
	}
	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

const Actions = styled.div`
	display: flex;
	padding-top: 50px;
	gap: 50px;
	> div {
		width: 100%;
	}
	> div:first-child {
		flex: 0.6;
		display: flex;
		flex-direction: column;
		gap: 50px;
		> div {
			background-color: #fff;
			border-radius: 26px;
		}
		> div:first-child {
			min-height: 260px;
		}
		${Create}:first-child {
			min-height: unset;
		}
		@media (max-width: 1200px) {
			flex-basis: 100%;
			gap: 35px;
		}
	}
	> div:nth-child(2) {
		flex: 0.4;
	}
	> div:last-child {
		h2 {
			text-align: center;
			color: black;
			font-size: 12px;
			font-family: 'TTInterfaces DemiBold';
			text-transform: uppercase;
			letter-spacing: 1.5px;
			opacity: 0.4;
			padding-top: 28px;
		}
		h2 + div {
			padding: 0 30px;
			color: #475467;
			font-size: 18px;
			font-family: 'TTInterfaces Medium';
			line-height: 27px;
		}
		@media (max-width: 1200px) {
			flex-basis: 100%;
			h2 {
				text-align: unset;
				padding-top: 0;
			}
			h2 + div {
				padding: 0;
			}
		}
	}
	@media (max-width: 1200px) {
		flex-wrap: wrap;
		padding-top: 35px;
		gap: 35px;
	}
`;

const Card = styled.div`
	display: flex;
	gap: 6%;
	padding: 28px;
	> div:first-child {
		flex: 0.6;
		max-width: 70%;
		border-radius: 20px;
		padding: 24px;
		background: linear-gradient(112deg, #383939 0%, #0f1012 100%);
		color: #fff;
		display: flex;
		justify-content: space-between;
		color: white;
		> div:first-child {
			display: flex;
			flex-direction: column;
		}
		> div:nth-child(2) {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			div.active,
			div.inactive {
				display: flex;
				align-items: center;
				border-radius: 17px;
				padding: 2px 6px;
				font-size: 14px;
				font-family: 'TTInterfaces Medium';
				line-height: 19px;
				div:first-child {
					margin-right: 6px;
					width: 7px;
					height: 7px;
					border-radius: 50%;
				}
			}
			div.googlePay {
				width: 61px;
				height: 29px;
				background: url(${googlePay}) no-repeat right bottom;
				background-origin: content-box;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
			}
			.googlePay + svg {
				margin-top: 12px;
			}
			div.active {
				border: 1.6px solid #caf0ac;
				color: #caf0ac;
				div:first-child {
					background-color: #caf0ac;
				}
			}
			div.inactive {
				border: 1.6px solid #ff4122;
				color: #ff4122;
				div:first-child {
					background-color: #ff4122;
				}
			}
		}
		.cardNumber {
			font-size: 16px;
			font-family: Courier;
		}
		.cardholder {
			font-size: 14px;
			font-family: 'TTInterfaces DemiBold';
		}
		@media (max-width: 1200px) {
			aspect-ratio: 2 / 1;
		}
		@media (max-width: 992px) {
			max-width: unset;
		}
	}
	> div:nth-child(2) {
		flex: 0.4;
		min-width: 120px;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
	button.deactivate,
	button.activate {
		border: 0;
		background-color: transparent;
		font-size: 16px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 20px;
	}
	button.deactivate {
		color: #dc1f41;
	}
	button.activate {
		color: #1cae71;
	}
	p {
		color: #959595;
		font-size: 13px;
		font-family: 'TTInterfaces Medium';
		margin: 0;
	}
	@media (max-width: 992px) {
		flex-direction: column;
	}
`;

const Transactions = styled.div`
	> div:first-child {
		margin: 60px 0;
		border-top: 0.5px solid rgba(0, 0, 0, 0.15);
		@media (max-width: 1200px) {
			margin: 35px 0 0 0;
			border: 0;
		}
	}
	h2 {
		font-size: 26px;
		font-family: 'TTInterfaces DemiBold';
		margin: 0;
		margin-bottom: 42px;
		span {
			margin-right: 16px;
		}
		@media (max-width: 992px) {
			padding: 15px 24px;
			border-bottom: 1px solid #eaecf0;
			margin-bottom: 0;
		}
	}
	div.table {
		border-radius: 20px;
		background-color: #fff;
	}
	table {
		width: 100%;
		th {
			color: #1d1d1d;
			font-size: 17px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 18px;
			padding: 14px 24px;
			border: none;
		}
		th:first-child {
			padding: 14px 32px;
		}
		td {
			color: #76808d;
			font-size: 17px;
			font-family: 'TTInterfaces Regular';
			padding: 16px 24px;
			h3 {
				display: none;
			}
			@media (max-width: 992px) {
				padding: 20px;
				h3 {
					display: block;
					color: #1d1d1d;
					font-size: 16px;
					font-family: 'TTInterfaces Bold';
					line-height: 8px;
					margin-bottom: 12px;
				}
			}
		}
		td:first-child {
			padding: 16px 32px;
			@media (max-width: 992px) {
				grid-column-start: 2;
				grid-row-start: 2;
			}
		}
		td:nth-child(2) {
			@media (max-width: 992px) {
				grid-row-start: 1;
				grid-column-start: 1;
				grid-column-end: 2;
			}
		}
		td:nth-child(4) {
			@media (max-width: 992px) {
				grid-row-start: 3;
				grid-column-start: 1;
				grid-column-end: 2;
			}
		}
		td:nth-child(3) {
			display: flex;
			@media (max-width: 992px) {
				grid-row-start: 2;
				grid-column-start: 1;
				grid-column-end: 1;
				display: unset;
			}
		}
		td:nth-child(3) div {
			display: inline-block;
			border-radius: 16px;
			padding: 6px 12px;
			justify-content: center;
			font-family: 'TTInterfaces Medium';
			line-height: 17px;
		}
		td:nth-child(3).pending div {
			border: 1.5px solid #76808d;
			color: #76808d;
		}
		td:nth-child(3).completed div {
			border: 1.5px solid #067647;
			color: #067647;
		}
		td:nth-child(3).refunded div {
			border: 1.5px solid #76808d;
			color: #76808d;
		}
		td:nth-child(3).declined div {
			border: 1.5px solid #cc1440;
			color: #cc1440;
		}
		td:nth-child(4).refunded div,
		td:nth-child(4).declined div {
			text-decoration: line-through;
		}
		tr {
			border-bottom: 1px solid #eaecf0;
			@media (max-width: 992px) {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
		}
		td,
		thead th {
			border: none;
		}
	}
	@media (max-width: 1200px) {
		flex-direction: column;
	}
	@media (max-width: 992px) {
		border-radius: 20px;
		background-color: #fff;
		thead {
			display: none;
		}
	}
`;

const Success = styled.div`
	padding: 60px 0;
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f5f7e3;
	border: 1px solid #d3d5bc;
	border-radius: 26px;
	h2 {
		color: black;
		font-size: 30px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 21px;
		margin-bottom: 22px;
		@media (max-width: 992px) {
			font-size: 24px;
		}
	}
	> p {
		margin: 0;
		color: black;
		font-size: 18px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 28px;
		@media (max-width: 992px) {
			font-size: 14px;
			line-height: 20px;
		}
	}
	> h2 + p {
		width: 50%;
		text-align: center;
		@media (max-width: 992px) {
			width: 85%;
		}
	}
	> div + p {
		font-family: 'TTInterfaces Regular';
		width: 70%;
		text-align: center;
		@media (max-width: 992px) {
			width: 90%;
		}
	}
	p + p {
		font-family: 'TTInterfaces Regular';
		@media (max-width: 992px) {
			text-align: center;
			width: 80%;
		}
	}
	button {
		padding: 12px 72px;
		border-radius: 60px;
		color: #f6f6f6;
		font-size: 16px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 26px;
		background-color: #000;
		margin-top: 26px;
		border: 0;
	}
	> div + p,
	> div + p + p {
		color: #8c8c8d;
		font-size: 11px;
		font-family: 'TTInterfaces Regular';
		line-height: 20px;
	}
	> div + p {
		margin-top: 24px;
	}
`;

export default Budget;
