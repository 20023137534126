import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import PasswordPopover from './PasswordPopover';
import { AiOutlineInfoCircle, AiFillCheckCircle } from 'react-icons/ai';
import { Popover, PopoverContent, Overlay } from 'react-bootstrap';

const StyledPopover = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 200px;
	height: 200px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border: 0px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
`;

const StyledChecklistTitle = styled.div`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	margin: 3px;
	padding-left: 12%;
`;

const PasswordOverlay = ({ values, position }) => {
	const target = useRef(null);
	const [passwordCorrect, setPasswordCorrect] = useState(false);

	const validatePassword = useCallback((password) => {
		return [
			{
				title: 'One lower case',
				valid: password.toUpperCase() !== password,
			},
			{
				title: 'One upper case',
				valid: password.toLowerCase() !== password,
			},
			{ title: 'One number', valid: /\d/.test(password) },
			{
				title: 'One special characters !@#$%^&*.',
				valid: /[~`!#$%@&*+=\-\]\\';,/{}|\\":<>]/g.test(password),
			},
			{ title: 'Should be 8 chars minimum', valid: password.length >= 8 },
		];
	}, []);

	useEffect(() => {
		setPasswordCorrect(
			validatePassword(values.password).reduce(
				(acc, cur) => acc + !cur.valid,
				false
			)
		);
	}, [values, validatePassword]);

	return (
		<>
			<div
				ref={target}
				style={{
					position: 'absolute',
					right: 15,
					top: 15,
					cursor: 'pointer',
					width: '20px',
					height: '20px',
				}}
			>
				{passwordCorrect ? (
					<AiOutlineInfoCircle size={20} style={{ position: 'inherit' }} />
				) : (
					<AiFillCheckCircle
						color={'green'}
						size={20}
						style={{ position: 'inherit' }}
					/>
				)}
			</div>
			<Overlay
				target={target.current}
				show={values.password !== '' && passwordCorrect}
				placement={position}
			>
				{({ ...props }) => (
					<Popover {...props} style={{ marginLeft: '20px', ...props.style }}>
						<PopoverContent as={StyledPopover}>
							<StyledChecklistTitle>Password must contain</StyledChecklistTitle>
							{validatePassword(values.password).map((data, index) => (
								<PasswordPopover key={index} data={data} />
							))}
						</PopoverContent>
					</Popover>
				)}
			</Overlay>
		</>
	);
};

export default PasswordOverlay;
