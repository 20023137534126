/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import config from '../../../../config';
import { PreferenceProps } from '../../types/preferenceProps';
import { useClient } from '../../../../hooks';
import Button from '../../../../styles/shared/Button/Button';
import useCurrencyFormatter from '../../../../utils/useCurrencyFormatter';
import { SmallTextSecondary } from '../../../../styles/StylesV3Main';

const Row = styled.div`
	margin-right: 0;
	margin-left: 0;
	> div {
		padding: 0;
	}
`;

const AlertMessage = styled.div`
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	margin: 0px 0 5px;
`;

const StyledLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
	margin-left: 12px;
	margin-top: auto;
	margin-bottom: auto;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 24px;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background-color: #1cae71;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #1cae71;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(16px);
		-ms-transform: translateX(16px);
		transform: translateX(16px);
	}
`;

const Label = styled.label`
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: black;
`;

const StyledInputContainer = styled.div`
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
`;

const BirthdayDialog = styled.dialog`
	border: none;
	padding: 0;
`;

const StatusLabel = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	padding: 4px 12px;
	text-transform: uppercase;
	margin-left: 16px;
	background: rgba(42, 185, 125, 0.1);
	color: #1cae71;
	border-radius: 3px;
`;

const NumberInput = styled.input`
	width: 100%;
	height: 56px;
	padding: 16px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	border: none;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: #191a1c;
`;

const PreferencePointsContainer = styled.div`
	padding: 10px 0px 10px 0px;
	margin-bottom: 60px;
	@media (max-width: 992px) {
		border-bottom: none;
		padding: 10px 0px;
	}
`;

const ModalOpacityBackground = styled.div`
	background: rgba(0, 0, 0, 0.4);
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
`;

const BirthdayModalContainer = styled.div`
	max-width: 600px;
	position: relative;
	background: white;
`;

const BirthdayModalBody = styled.div`
	padding: 24px;
`;

const PreferenceOptions = styled.div`
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	padding: 40px;
	margin-top: 20px;
	@media (max-width: 992px) {
		border-bottom: none;
		padding: 10px 0px;
	}
`;

const ParagraphContainer = styled.div`
	width: 90%;
`;

const FlexContainer = styled.div`
	display: flex;
`;

const Heading2 = styled.h2`
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
`;

const Heading6 = styled.h6`
	font-weight: 600;
	color: #5b6166;
	font-size: 12px;
	line-height: 14.4px;
	letter-spacing: 2%;
	text-transform: uppercase;
`;

const Paragraph = styled.p<{
	size: string;
	weight: string;
	lineHeight: string;
	margin: string;
	color: string;
}>`
	font-size: ${(props) => (props.size ? props.size : '18px')};
	font-weight: ${(props) => (props.weight ? props.weight : '0')};
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : '28.8px')};
	margin: ${(props) => (props.margin ? props.margin : '')};
	color: ${(props) => (props.color ? props.color : '#191a1c')};
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	button {
		margin-left: 16px;
	}
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const SmallText = styled(SmallTextSecondary)`
	margin-top: 8px;
`;

const PreferenceWrapper = ({ team, handleUpdate }: PreferenceProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState({
		status: false,
		message: '',
	});
	const [checkbox, setCheckbox] = useState(false);
	const birthdayDialogElement = useRef(null);
	const [inputPoints, setInputPoints] = useState(
		Math.abs(team?.birthdayPoints).toString()
	);
	const currencyFormatter = useCurrencyFormatter();

	const client = useClient();

	const dialog = (status: boolean) => {
		const pointsInput = document.getElementById(
			'pointsInput'
		) as HTMLInputElement;
		pointsInput.value = `${Math.abs(team.birthdayPoints)}`;
		setFormSubmitted({
			status: false,
			message: '',
		});
		const dialogModal: any = birthdayDialogElement.current;

		if (status) {
			return dialogModal.showModal();
		}
		return dialogModal.close();
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			await client.post(
				`${config.teams.HOST}/teams/update-team-preferences/${team.id}`,
				{
					status: checkbox,
					birthdayPoints: +inputPoints,
				}
			);
			await handleUpdate();
			dialog(false);
			setIsSubmitting(false);
			setFormSubmitted({
				status: true,
				message: 'Successfully updated.',
			});
		} catch (err) {
			console.log(err);
			setIsSubmitting(false);
			setFormSubmitted({
				status: true,
				message: 'There was an error when updating.',
			});
		}
	};

	useEffect(() => {
		setCheckbox(team.birthdayPoints > 0);
	}, []);

	return (
		<PreferencePointsContainer>
			<Row>
				<div>
					<Heading2>Preferences</Heading2>
				</div>
			</Row>

			<PreferenceOptions>
				<Row>
					<div>
						<Heading6>
							birthday points robot
							{team.birthdayPoints > 0 && <StatusLabel>active</StatusLabel>}
						</Heading6>
						<Paragraph weight="" size="" lineHeight="" margin="" color="">
							{team.birthdayPoints > 0 ? (
								<>
									You will send {team.birthdayPoints} points automatically to
									anyone in your team who have set their birthday on their
									account.
								</>
							) : (
								<>
									Send Juno Points automatically to someone when it&apos;s their
									birthday. 🎉
								</>
							)}
						</Paragraph>
						<Button
							buttonType="secondary"
							state="default"
							size="small"
							onClick={() => {
								dialog(true);
							}}
						>
							{team.birthdayPoints > 0 ? 'Edit' : 'Set up'}
						</Button>
					</div>
				</Row>
			</PreferenceOptions>
			<BirthdayDialog
				ref={birthdayDialogElement}
				aria-labelledby="Birthday Points"
				aria-describedby="Set preference on birthday points"
			>
				<ModalOpacityBackground onClick={() => dialog(false)} />
				<BirthdayModalContainer>
					<form method="post" onSubmit={handleSubmit}>
						<BirthdayModalBody>
							<Heading2>Birthday points</Heading2>
							<StyledHr />
							<div>
								<FlexContainer>
									<ParagraphContainer>
										<Label htmlFor="autoPoints">Auto birthday points</Label>
										<Paragraph
											weight="400"
											size="14px"
											lineHeight="22.4px"
											margin=""
											color=""
										>
											Members in your team will automatically receive points on
											their birthdays. The cost will be added to your next
											invoice.
										</Paragraph>
									</ParagraphContainer>
									<StyledInputContainer>
										<StyledLabel>
											<input
												id="autoPoints"
												type="checkbox"
												checked={checkbox}
												onChange={() => setCheckbox(!checkbox)}
											/>
											<span className="slider" />
										</StyledLabel>
									</StyledInputContainer>
								</FlexContainer>
								<div>
									<Label htmlFor="pointsInput">Amount</Label>
									<NumberInput
										id="pointsInput"
										name="pointsInput"
										type="number"
										required
										min={checkbox ? '1' : '0'}
										defaultValue={Math.abs(team.birthdayPoints)}
										placeholder="Enter points"
										onChange={(e) => {
											setInputPoints(e.target.value);
										}}
										onWheel={(e) => e.currentTarget.blur()}
									/>
									<SmallText>
										Equals{' '}
										{currencyFormatter(
											+inputPoints,
											team.stripeSubscriptionData?.metadata?.currency || 'gbp'
										)}{' '}
										per member
									</SmallText>
								</div>
							</div>
							<StyledHr />
							{formSubmitted.status && (
								<AlertMessage>{formSubmitted?.message}</AlertMessage>
							)}

							<ButtonContainer>
								<Button
									buttonType="secondary"
									state="default"
									type="button"
									size="small"
									onClick={(e: any) => {
										e.preventDefault();
										dialog(false);
									}}
								>
									Cancel
								</Button>

								<Button
									state={isSubmitting ? 'loading' : 'default'}
									onClick={() => false}
									size="small"
								>
									{isSubmitting ? 'Processing' : 'Save'}
								</Button>
							</ButtonContainer>
						</BirthdayModalBody>
					</form>
				</BirthdayModalContainer>
			</BirthdayDialog>
		</PreferencePointsContainer>
	);
};

export default PreferenceWrapper;
