/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
export const getRoles = ({ userData, teamId }: any) => {
	try {
		return userData.teams &&
			userData.teams.length > 0 &&
			userData.teams.filter((item: any) => item.id === teamId).length > 0
			? userData.teams.filter((item: any) => item.id === teamId)[0].teamMember
					.roles
			: [];
	} catch (err) {
		console.error(err);
	}
};
