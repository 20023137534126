import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WalletBg from './images/wallet.png';
import { useClient, useCurrentTeam } from '../hooks';
import config from '../config';
import { useUserContext } from '../contexts/userContext';

const StyledContainer = styled.div`
	width: 100%;
	align-items: center;
	font-family: 'TTInterfaces Demibold';
	margin: 75px 0;
	padding: 0 34px;

	@media (max-width: 500px) {
		margin: 25px 0;
	}

	> div {
		display: flex;
		flex-direction: column;
		gap: 35px;
		align-items: center;

		h2 {
			font-size: 32px;
			line-height: 40px;
		}
	}
`;

const CardContainer = styled.div`
	width: 100%;
	max-width: 900px;
	background: #1d1d1d;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	border-radius: 35px;

	img {
		height: 250px;
		max-width: 900px;

		@media (max-width: 500px) {
			height: 180px;
		}
	}

	> div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		color: white;
		height: 100%;
		width: 100%;
		padding: 35px 40px;
		gap: 20px;

		@media (max-width: 500px) {
			padding: 25px 30px;
		}

		div:first-of-type {
			display: flex;
			flex-direction: column;
			flex: 1;
			flex-wrap: wrap;
			gap: 10px;
			min-width: 300px;

			button {
				padding: 0px 20px;
			}

			p {
				margin: 0;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 0.12em;
				opacity: 0.6;

				@media (max-width: 500px) {
					font-size: 13px;
					line-height: 16px;
				}
			}

			h3 {
				font-size: 28px;
				line-height: 35px;

				@media (max-width: 500px) {
					font-size: 24px;
					line-height: 30px;
				}
			}
		}

		div:nth-of-type(2) {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			flex-direction: row;

			@media (max-width: 500px) {
				width: 100%;
			}

			button {
				padding: 0px 20px;
				height: 40px;

				@media (max-width: 500px) {
					width: 100%;
				}
			}
		}
	}
`;

const Transactions = styled.div`
	> h2 {
		padding: 100px 0 25px 0;
		font-size: 32px;
		line-height: 40px;
	}
	> div {
		padding: 40px 45px;
		background: #fff;
		border-radius: 35px;
		width: 100%;
		overflow-x: auto;
		@media (max-width: 500px) {
			padding: 30px 35px;
		}
	}
	table {
		width: 100%;
		font-size: 18px;
		line-height: 22px;
		border-spacing: 20px 0;
		border-collapse: separate;
		@media (max-width: 500px) {
			border-spacing: 10px 0;
		}
		th {
			font-family: 'TTInterfaces Demibold';
			padding-bottom: 12.5px;
		}
		td {
			font-family: 'TTInterfaces Medium';
			color: rgba(29, 29, 29, 0.7);
			opacity: 0.6;
			padding: 12.5px 0;
			vertical-align: top;
		}
	}
`;

const Wallet = () => {
	const client = useClient();
	const { userData } = useUserContext();
	const currentTeam = useCurrentTeam();

	const [wallet, setWallet] = useState<{
		points: number;
		transactions: any[];
	} | null>(null);

	const fetchWalletDetails = async () => {
		const { data } = await client.get(
			`${config.users.HOST}/users/wallet/${currentTeam?.id || ''}`
		);
		setWallet(data);
	};

	useEffect(() => {
		fetchWalletDetails();
	}, [userData]);

	return (
		<StyledContainer>
			<div>
				<h2>Your wallet</h2>

				<CardContainer>
					<img src={WalletBg} alt="wallet-background-cloud" />

					<div>
						<div>
							<p>TOTAL BALANCE</p>

							<h3>
								{(userData?.points || 0) +
									(userData?.teams.reduce(
										(
											acc: Number,
											cur: {
												teamMember: { roles: string | string[]; points: any };
												id: any;
											}
										) => {
											return cur.teamMember.roles.includes('member') &&
												cur.id === userData.currentTeam
												? acc + cur.teamMember.points
												: acc;
										},
										0
									) || 0)}{' '}
								Juno Points ®
							</h3>
						</div>
					</div>
				</CardContainer>
			</div>
			{!!wallet?.transactions.length && (
				<Transactions>
					<h2>Transactions</h2>
					<div>
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Event</th>
									<th>Description</th>
									<th>Amount</th>
									<th>Balance</th>
								</tr>
							</thead>
							<tbody>
								{wallet.transactions.map(
									({ date, type, description, amount, balance }, i) => (
										// eslint-disable-next-line react/no-array-index-key
										<tr key={i}>
											<td>{Intl.DateTimeFormat().format(new Date(date))}</td>
											<td>{type}</td>
											<td>{description}</td>
											<td style={{ color: amount < 0 ? '#f3636c' : '#85b16a' }}>
												{amount}
											</td>
											<td>{balance}</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				</Transactions>
			)}
		</StyledContainer>
	);
};

export default Wallet;
