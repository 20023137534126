import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useClient } from '../../../hooks';
import config from '../../../config';
import Spinner from '../../../styles/shared/Spinner/Spinner';
import Modal from '../../../styles/shared/Modal';
import Button from '../../../styles/shared/Button/Button';
import { Booking } from './types';
import { ReactComponent as Close } from '../images/close.svg';

const ReceiptModal = ({
	booking,
	onUpdate,
	onCancel,
}: {
	booking: Booking | { id: number };
	onUpdate: (status: 'flagged' | 'reviewed' | 'unmarked') => void;
	onCancel: () => void;
}) => {
	const client = useClient();
	const [bookingReceipt, setBookingReceipt] = useState(booking);
	const [receiptLoaded, setReceiptLoaded] = useState(false);
	const {
		id,
		firstName,
		lastName,
		email,
		budgetName,
		card,
		merchant,
		amount,
		currency,
		receiptStatus,
		receiptRequired,
		receiptUrl,
		receiptPdf,
	} = bookingReceipt as any;
	const getReceipt = async () => {
		try {
			const { data } = await client.get(
				`${config.teams.HOST}/teams/budgets/booking/${id}`
			);
			setBookingReceipt(data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	useEffect(() => {
		(async () => {
			await getReceipt();
		})();
	}, []);
	return (
		<Modal open onClose={onCancel}>
			<ModalContainer>
				<form
					method="post"
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<div>
						<h2>View receipt</h2>
						<button type="button" onClick={onCancel} aria-label="close">
							<Close />
						</button>
					</div>
					<dl>
						<div>
							<dt>User:</dt>
							<dd>
								{firstName && lastName ? `${firstName} ${lastName}` : email}
							</dd>
						</div>
						<div>
							<dt>Merchant:</dt>
							<dd>{card ? merchant : 'N/A'}</dd>
						</div>
						<div>
							<dt>Cost:</dt>
							<dd>
								{currency &&
									Intl.NumberFormat('en-US', {
										style: 'currency',
										currency,
									}).format(amount)}
							</dd>
						</div>
						<div>
							<dt>Pot:</dt>
							<dd>{budgetName}</dd>
						</div>
					</dl>
					<h3>Receipt image</h3>
					{receiptUrl &&
						(!receiptPdf ? (
							<img
								src={receiptUrl}
								alt="receipt"
								style={{
									opacity: receiptLoaded ? 1 : 0,
									height: receiptLoaded ? 'unset' : 0,
									position: receiptLoaded ? 'unset' : 'absolute',
								}}
								onLoad={() => setReceiptLoaded(true)}
							/>
						) : (
							<embed
								src={`${receiptUrl}#view=FitH`}
								onLoad={() => setReceiptLoaded(true)}
								style={{
									height: '336px',
								}}
								type="application/pdf"
							/>
						))}
					{!receiptLoaded && !(receiptUrl && receiptPdf) && (
						<div>
							<Spinner />
						</div>
					)}
					{receiptRequired && card && (
						<>
							<h3>Mark receipt as</h3>
							<div className="buttons">
								<Button
									type="submit"
									size="small"
									state="default"
									buttonType="destructive"
									style={{
										backgroundColor:
											receiptStatus === 'flagged'
												? 'rgba(204, 20, 64, 0.1)'
												: '#fff',
									}}
									onClick={() => {
										onUpdate(
											receiptStatus !== 'flagged' ? 'flagged' : 'unmarked'
										);
									}}
								>
									Flagged
								</Button>
								<Button
									type="submit"
									state="default"
									buttonType="secondary"
									size="small"
									style={{
										backgroundColor:
											receiptStatus === 'reviewed'
												? 'rgba(7, 148, 85, 0.1)'
												: '#fff',
									}}
									onClick={() => {
										onUpdate(
											receiptStatus !== 'reviewed' ? 'reviewed' : 'unmarked'
										);
									}}
								>
									Reviewed
								</Button>
							</div>
						</>
					)}
				</form>
			</ModalContainer>
		</Modal>
	);
};

const ModalContainer = styled.div`
	width: 560px;
	position: relative;
	padding: 24px;
	display: flex;
	flex-direction: column;
	h2 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		color: #101828;
		line-height: 28px;
		margin-bottom: 10px;
	}
	h2 + button {
		padding: 0;
		background: none;
		border: none;
	}
	h3 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 16px;
		color: #000;
		margin-bottom: 10px;
	}
	dl {
		display: grid;
		column-gap: 8px;
		grid-template-columns: auto 1fr;
		color: #475467;
		font-family: 'TTInterfaces Regular';
		font-size: 16px;
		line-height: 28px;
		margin-bottom: 28px;
		> div:first-child {
			max-width: 250px;
		}
		> div {
			display: flex;
		}
		@media (max-width: 900px) {
			display: flex;
			flex-direction: column;
		}
	}
	dt {
		font-family: 'TTInterfaces Bold';
		font-weight: unset;
	}
	dd {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 8px;
	}
	dl + h3 + div,
	dl + h3 + img + div,
	dl + h3 + embed + div {
		width: 100%;
		height: 336px;
		border-radius: 12px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid rgba(0, 0, 0, 0.2);
	}
	img,
	embed {
		width: 100%;
		border-radius: 12px;
		margin-bottom: 20px;
	}
	> form > div {
		display: flex;
		gap: 8px;
	}
	div.buttons {
		button {
			font-size: 14px;
			line-height: 18px;
			padding: 8px 20px;
		}
		button:nth-child(1) {
			border: 1.5px solid rgb(204, 20, 64);
			border-radius: 8px;
			color: rgb(204, 20, 64);
		}
		button:nth-child(2) {
			border: 1.5px solid rgb(7, 148, 85);
			border-radius: 8px;
			color: rgb(7, 148, 85);
		}
	}
	@media (max-width: 900px) {
		width: calc(100vw - 48px);
	}
`;

export default ReceiptModal;
