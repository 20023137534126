import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row } from 'react-bootstrap';
import {
	StyledInputBox,
	LoginButton,
	StyledOR,
	StyledORText,
	StyledORDivider,
	SocialLoginButton,
	StyledLabelForm,
	StyledPasswordContainer,
	StyledShowPwd,
	StyledErrorMessage,
} from '../../styles/Form';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import PasswordOverlay from './PasswordOverlay';

const StyledImage = styled.img`
	max-height: 20px;
	object-fit: contain;
	position: absolute;
	left: 15px;
`;

const StyledInputContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const SignUp = ({ errorCallback, handleNextSignUp }) => {
	const [passwordShown, setPasswordShown] = useState(false);

	const [position, setPosition] = useState('right');
	const [error, setError] = useState('');

	const marketingSignUpEmail = useMemo(() => {
		const getMarketingCookie = () => {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; marketingSignUp=`);
			if (parts.length === 2) return parts.pop().split(';').shift();
			return false;
		};
		const marketingUser = JSON.parse(getMarketingCookie());
		return marketingUser?.email;
	}, []);

	useEffect(() => {
		if (window.innerWidth < 998) setPosition('bottom');
	}, [position]);

	const { signUpWithCredentials, loginWithSocial } = useAuth0();

	const togglePasswordVisiblity = (password) => {
		setPasswordShown(!password);
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.required('Please enter your email address.')
			.email('This email is not in a recognised format'),
		password: Yup.string()
			.required('Please enter your password')
			.min(8, 'Password is too short - should be 8 chars minimum.')
			.matches(
				/^(?=.*[A-Za-z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/,
				'Password must contain at least one lower case, one upper case, one number and one among these special characters !@#$%^&*.'
			),
	});

	const handleSignUpWithCredentials = async (values) => {
		try {
			return await signUpWithCredentials(
				values.email,
				values.password,
				new URL(window.location)
			);
		} catch (err) {
			errorCallback(err.description);
			setError(err.description);
			console.error(err);
		}
	};

	const handleSignUpWithSocial = async () => {
		try {
			return await loginWithSocial('google-oauth2', new URL(window.location));
		} catch (err) {
			errorCallback(err.description);
			setError(err.description);
			console.error(err.message);
		}
	};

	return (
		<div>
			<Formik
				initialValues={{
					email: marketingSignUpEmail || '',
					password: '',
				}}
				validateOnChange={false}
				enableReinitialize
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleSignUpWithCredentials(values);
					handleNextSignUp();
					setSubmitting(false);
				}}
			>
				{({ submitting, handleSubmit, values }) => {
					return (
						<Form onSubmit={handleSubmit} onChange={() => setError('')}>
							<Row noGutters>
								<StyledLabelForm htmlFor="email">Email</StyledLabelForm>
								<Field
									type="email"
									name="email"
									placeholder="you@example.com"
									as={StyledInputBox}
								/>
								<ErrorMessage name="email" component={StyledErrorMessage} />
							</Row>
							<Row noGutters>
								<StyledPasswordContainer>
									<StyledLabelForm htmlFor="password">Password</StyledLabelForm>
									<StyledShowPwd
										onClick={() => togglePasswordVisiblity(passwordShown)}
									>
										{!passwordShown ? 'Show' : 'Hide'}
									</StyledShowPwd>
								</StyledPasswordContainer>
								<StyledInputContainer>
									<Field
										type={passwordShown ? 'text' : 'password'}
										name="password"
										placeholder="Please enter your password"
										as={StyledInputBox}
									/>
									<PasswordOverlay values={values} position={position} />
								</StyledInputContainer>

								<ErrorMessage name="password" component={StyledErrorMessage} />
								{error !== '' && (
									<StyledErrorMessage>{error}</StyledErrorMessage>
								)}
							</Row>
							<Row noGutters>
								<LoginButton type="submit" disabled={submitting}>
									Sign Up
								</LoginButton>
							</Row>
						</Form>
					);
				}}
			</Formik>
			<Row noGutters>
				<StyledOR>
					<StyledORDivider />
					<StyledORText>OR</StyledORText>
					<StyledORDivider />
				</StyledOR>
			</Row>
			<Row noGutters>
				<SocialLoginButton onClick={() => handleSignUpWithSocial()}>
					<StyledImage src="../images/login/google/g-logo.png" />
					Sign Up with Google
				</SocialLoginButton>
			</Row>
		</div>
	);
};

export default SignUp;
