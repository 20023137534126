import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { H2, PSansText } from '../../styles/StylesV2';
import Loading from './Loading';
import ModalButton from '../../styles/Modal/ModalButton';

type ConfirmationPopUpBoxProps = {
	show?: boolean;
	showCallback?: () => void;
	callback?: () => void;
	title?: string;
	subtitle?: string;
	children?: React.ReactNode;
	backText?: string;
	disabled?: boolean;
	confirmText?: string;
	version?: string;
	isLoading?: boolean;
};

const ConfirmationPopUpBox = ({
	show,
	showCallback,
	callback,
	title,
	subtitle,
	children,
	backText,
	disabled,
	confirmText,
	version,
	isLoading,
}: ConfirmationPopUpBoxProps) => {
	return (
		<Modal show={show} onHide={showCallback} centered animation={false}>
			<Row noGutters className="my-5 px-2 justify-content-center text-center">
				<Col>
					<H2>{title}</H2>
					<PSansText className="mt-1">{subtitle}</PSansText>
					{children}
				</Col>
			</Row>
			<Row noGutters className="justify-content-around my-3 mb-4">
				<ModalButton version="light" onClick={showCallback}>
					{backText || `Cancel`}
				</ModalButton>
				<ModalButton
					version={version ? version : 'dark'}
					disabled={disabled}
					onClick={callback}
				>
					{isLoading && <Loading />}
					{!isLoading && (confirmText || `Confirm`)}
				</ModalButton>
			</Row>
		</Modal>
	);
};

export default ConfirmationPopUpBox;
