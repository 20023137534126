import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../../styles/shared/Button/Button';
import { ReactComponent as Back } from './images/back.svg';
import currencies from '../../components/reimbursements/currencies.json';
import states from '../../components/reimbursements/states.json';
import Policy from './Policy';
import { useUserContext } from '../../contexts/userContext';
import { useClient, useCurrentTeam } from '../../hooks';
import config from '../../config';
import ReceiptUploader from '../../components/reimbursements/ReceiptUploader';
import arrow from './images/arrow.svg';

const SubmitExpense = ({
	memberAmount,
	receiptRequired,
	policy,
	save,
}: any) => {
	const { userData } = useUserContext();
	const { currency: teamCurrency = 'gbp', fees = true } = useCurrentTeam();
	const [firstName, setFirstName] = useState(userData.firstName || '');
	const [lastName, setLastName] = useState(userData.lastName || '');
	const [expenseItem, setExpenseItem] = useState('');
	const [currency, setCurrency] = useState(
		userData.currencyData.localCurrency.toUpperCase()
	);
	const [amount, setAmount] = useState('');
	const [files, setFiles] = useState({
		ready: [],
		all: [],
	});
	const [bankType, setBankType] = useState('');
	const [sortCode, setSortCode] = useState('');
	const [accountNumber, setAccountNumber] = useState('');
	const [accountType, setAccountType] = useState('');
	const [routingNumber, setRoutingNumber] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [stateUS, setStateUS] = useState('AL');
	const [zip, setZip] = useState('');
	const [institutionNumber, setInstitutionNumber] = useState('');
	const [transitNumber, setTransitNumber] = useState('');
	const [iban, setIban] = useState('');
	const [email, setEmail] = useState(userData.email);
	const sortCodeCheck = useRef<Promise<void>>();
	const accountNumberCheck = useRef<Promise<void>>();
	const routingNumberCheck = useRef<Promise<void>>();
	const institutionNumberCheck = useRef<Promise<void>>();
	const transitNumberCheck = useRef<Promise<void>>();
	const ibanCheck = useRef<Promise<void>>();
	const transitNumberInput = useRef<HTMLInputElement>(null);
	const accountNumberInput = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [exchangeRates, setExchangeRates] = useState(null);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const client = useClient();
	let maxAmount: number | undefined;
	let totalCost: number | undefined;
	let fee: number | undefined;
	const canCalculateCost =
		currency === teamCurrency.toUpperCase() || exchangeRates;
	if (canCalculateCost) {
		maxAmount = (memberAmount * 100) / (!fees ? 1 : 1.01);
		maxAmount =
			currency === teamCurrency.toUpperCase()
				? Math.floor(maxAmount)
				: maxAmount;
		maxAmount =
			Math.floor(
				maxAmount /
					(currency === teamCurrency.toUpperCase()
						? 1
						: exchangeRates![currency][teamCurrency.toUpperCase()])
			) / 100;

		let unroundedCost =
			(currency === teamCurrency.toUpperCase()
				? 1
				: exchangeRates![currency][teamCurrency.toUpperCase()]) *
			((+amount || 0) * 100);
		unroundedCost =
			currency === teamCurrency.toUpperCase()
				? Math.round(unroundedCost)
				: unroundedCost;
		const cost = Math.ceil(unroundedCost);
		totalCost = Math.ceil(unroundedCost * (!fees ? 1 : 1.01));
		fee = totalCost - cost;
	}
	useEffect(() => {
		(async () => {
			const { data: rates } = await client.get(
				`${config.platform.HOST}/payments/authorized/reimbursements/exchange-rates`
			);
			setExchangeRates(rates);
		})();
	}, []);
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: teamCurrency,
	});
	const validateBankDetail = async (
		target: HTMLInputElement,
		ref: MutableRefObject<Promise<void> | undefined>,
		relatedDetails?: any
	) => {
		let resolve = () => {};
		// eslint-disable-next-line no-param-reassign
		ref.current = new Promise((res) => {
			resolve = res;
		}) as Promise<void>;
		try {
			if (target.value) {
				const response = await client.post(
					`${config.platform.HOST}/payments/authorized/reimbursements/validate`,
					{
						accountType: bankType,
						[target.id]: target.value,
						...relatedDetails,
					}
				);
				if (response.data.validation !== 'success')
					target.setCustomValidity(
						`Invalid ${target.placeholder.slice(0, -1)}.`
					);
			}
		} finally {
			resolve();
		}
	};
	return (
		<Request>
			<Policy policy={policy} />
			<div className="content">
				<div>
					<button
						type="button"
						onClick={() => {
							const newParams = new URLSearchParams(search);
							newParams.delete('expense');
							history.push(`${pathname}?${newParams}`, {
								scroll: false,
							});
						}}
					>
						<Back />
					</button>
				</div>
				<div>
					<h2>Submit an expense</h2>
					<p>
						Your expense request will be reviewed by Juno against the Pot
						policy. All expense receipts are available to your employer to
						review
					</p>
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							const theForm = e.currentTarget;
							await sortCodeCheck.current;
							await accountNumberCheck.current;
							await routingNumberCheck.current;
							await institutionNumberCheck.current;
							await transitNumberCheck.current;
							await ibanCheck.current;
							theForm.reportValidity();
							if (theForm.checkValidity()) {
								setSaving(true);
								await save({
									item: expenseItem,
									amount: +amount,
									currency,
									firstName,
									lastName,
									receipts: files.ready.map(
										({ meta: { name, type, key } }) => ({
											fileName: name,
											fileType: type,
											key,
										})
									),
									bankDetails: {
										accountType: bankType,
										iban,
										accountNumber,
										sortCode,
										institutionNumber,
										transitNumber,
										routingNumber,
										checking: accountType !== 'SAVING',
										email:
											bankType === 'OTHER' && email.length ? email : undefined,
										addressLine1,
										addressLine2,
										city,
										state: stateUS,
										zip,
									},
								});
							}
						}}
					>
						<div>
							<input
								id="firstName"
								placeholder="First name*"
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
								required
								value={firstName}
								onChange={({ target }) => setFirstName(target.value)}
							/>
							<label htmlFor="firstName">First name*</label>
						</div>
						<div>
							<input
								id="lastName"
								placeholder="Last name*"
								required
								value={lastName}
								onChange={({ target }) => setLastName(target.value)}
							/>
							<label htmlFor="lastName">Last name*</label>
						</div>
						<div>
							<input
								id="expenseItem"
								placeholder="What are you expensing*"
								required
								value={expenseItem}
								onChange={({ target }) => setExpenseItem(target.value)}
							/>
							<label htmlFor="expenseItem">What are you expensing*</label>
						</div>
						<div>
							<select
								id="currency"
								required
								data-value={currency}
								value={currency}
								onChange={({ target }) => setCurrency(target.value)}
							>
								{currencies.map(({ value, label, disabled }) => (
									<option key={value} value={value} disabled={disabled}>
										{label}
									</option>
								))}
							</select>
							<label htmlFor="currency">Currency*</label>
						</div>
						<div>
							<input
								id="amount"
								placeholder="Amount*"
								type="number"
								required
								step="any"
								min="0.01"
								max={maxAmount}
								value={amount}
								onChange={({ target }) => setAmount(target.value)}
							/>
							<label htmlFor="amount">Amount*</label>
						</div>
						<div>
							<select
								id="bankType"
								required
								data-value={bankType}
								value={bankType}
								onChange={({ target }) => {
									setSortCode('');
									setAccountNumber('');
									setAccountType('');
									setAccountNumber('');
									setRoutingNumber('');
									setAddressLine1('');
									setAddressLine2('');
									setCity('');
									setStateUS('AL');
									setZip('');
									setAccountNumber('');
									setInstitutionNumber('');
									setTransitNumber('');
									setIban('');
									setBankType(target.value);
								}}
							>
								<option value="" disabled>
									Bank account type*
								</option>
								<option value="UK">UK Bank Account</option>
								<option value="US">US Bank Account</option>
								<option value="CAD">Canadian Bank Account</option>
								<option value="IBAN">IBAN Bank Account</option>
								<option value="OTHER">Other</option>
							</select>
							<label htmlFor="bankType">Bank account type*</label>
						</div>
						{bankType === 'UK' && (
							<>
								<div>
									<input
										id="sortCode"
										placeholder="Sort Code*"
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										required
										value={sortCode}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setSortCode(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter')
												validateBankDetail(currentTarget, sortCodeCheck);
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, sortCodeCheck);
										}}
									/>
									<label htmlFor="sortCode">Sort Code*</label>
								</div>
								<div>
									<input
										id="accountNumber"
										placeholder="Account Number*"
										required
										value={accountNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setAccountNumber(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter')
												validateBankDetail(currentTarget, accountNumberCheck);
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, accountNumberCheck);
										}}
									/>
									<label htmlFor="accountNumber">Account Number*</label>
								</div>
							</>
						)}
						{bankType === 'US' && (
							<>
								<div>
									<select
										id="accountType"
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										required
										data-value={accountType}
										value={accountType}
										onChange={({ target }) => {
											setAccountType(target.value);
										}}
									>
										<option value="" disabled>
											Account type*
										</option>
										<option value="CHECKING">Checking</option>
										<option value="SAVING">Saving</option>
									</select>
									<label htmlFor="accountType">Account type*</label>
								</div>
								<div>
									<input
										id="accountNumber"
										placeholder="Account Number*"
										required
										value={accountNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setAccountNumber(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter')
												validateBankDetail(currentTarget, accountNumberCheck);
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, accountNumberCheck);
										}}
									/>
									<label htmlFor="accountNumber">Account Number*</label>
								</div>
								<div>
									<input
										id="routingNumber"
										placeholder="Routing Number*"
										required
										value={routingNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setRoutingNumber(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter')
												validateBankDetail(currentTarget, routingNumberCheck);
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, routingNumberCheck);
										}}
									/>
									<label htmlFor="routingNumber">Routing Number*</label>
								</div>
								<div>
									<input
										id="addressLine1"
										placeholder="Address line 1*"
										required
										value={addressLine1}
										onChange={({ target }) => setAddressLine1(target.value)}
									/>
									<label htmlFor="addressLine1">Account line 1*</label>
								</div>
								<div>
									<input
										id="addressLine2"
										placeholder="Address line 2"
										value={addressLine2}
										onChange={({ target }) => setAddressLine2(target.value)}
									/>
									<label htmlFor="addressLine2">Account line 2</label>
								</div>
								<div>
									<input
										id="city"
										placeholder="City*"
										required
										value={city}
										onChange={({ target }) => setCity(target.value)}
									/>
									<label htmlFor="city">City*</label>
								</div>
								<div>
									<select
										id="state"
										required
										data-value={stateUS}
										value={stateUS}
										onChange={({ target }) => setStateUS(target.value)}
									>
										{states.map(({ value, label, disabled }) => (
											<option key={value} value={value} disabled={disabled}>
												{label}
											</option>
										))}
									</select>
									<label htmlFor="state">State*</label>
								</div>
								<div>
									<input
										id="zip"
										placeholder="Zip Code*"
										required
										value={zip}
										onChange={({ target }) => setZip(target.value)}
									/>
									<label htmlFor="zip">Zip Code*</label>
								</div>
							</>
						)}
						{bankType === 'CAD' && (
							<>
								<div>
									<select
										id="accountType"
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										required
										data-value={accountType}
										value={accountType}
										onChange={({ target }) => {
											setAccountType(target.value);
										}}
									>
										<option value="" disabled>
											Account type*
										</option>
										<option value="CHECKING">Checking</option>
										<option value="SAVING">Saving</option>
									</select>
									<label htmlFor="accountType">Account type*</label>
								</div>
								<div>
									<input
										id="institutionNumber"
										placeholder="Instition Number*"
										required
										value={institutionNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											transitNumberInput.current!.setCustomValidity('');
											accountNumberInput.current!.setCustomValidity('');
											setInstitutionNumber(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter') {
												validateBankDetail(
													currentTarget,
													institutionNumberCheck
												);
												validateBankDetail(
													transitNumberInput.current!,
													transitNumberCheck,
													{ institutionNumber }
												);
												validateBankDetail(
													accountNumberInput.current!,
													accountNumberCheck,
													{
														institutionNumber,
														transitNumber,
													}
												);
											}
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, institutionNumberCheck);
											validateBankDetail(
												transitNumberInput.current!,
												transitNumberCheck,
												{ institutionNumber }
											);
											validateBankDetail(
												accountNumberInput.current!,
												accountNumberCheck,
												{
													institutionNumber,
													transitNumber,
												}
											);
										}}
									/>
									<label htmlFor="institutionNumber">Institution Number*</label>
								</div>
								<div>
									<input
										id="transitNumber"
										ref={transitNumberInput}
										placeholder="Transit Number*"
										required
										value={transitNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setTransitNumber(target.value);
											accountNumberInput.current!.setCustomValidity('');
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter') {
												validateBankDetail(currentTarget, transitNumberCheck, {
													institutionNumber,
												});
												validateBankDetail(
													accountNumberInput.current!,
													accountNumberCheck,
													{
														institutionNumber,
														transitNumber,
													}
												);
											}
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, transitNumberCheck, {
												institutionNumber,
											});
											validateBankDetail(
												accountNumberInput.current!,
												accountNumberCheck,
												{
													institutionNumber,
													transitNumber,
												}
											);
										}}
									/>
									<label htmlFor="transitNumber">Transit Number*</label>
								</div>
								<div>
									<input
										id="accountNumber"
										ref={accountNumberInput}
										placeholder="Account Number*"
										required
										value={accountNumber}
										onChange={({ target }) => {
											target.setCustomValidity('');
											setAccountNumber(target.value);
										}}
										onKeyDown={({ key, currentTarget }) => {
											if (key === 'Enter')
												validateBankDetail(currentTarget, accountNumberCheck, {
													institutionNumber,
													transitNumber,
												});
										}}
										onBlur={async ({ currentTarget }) => {
											validateBankDetail(currentTarget, accountNumberCheck, {
												institutionNumber,
												transitNumber,
											});
										}}
									/>
									<label htmlFor="accountNumber">Account Number*</label>
								</div>
							</>
						)}
						{bankType === 'IBAN' && (
							<div>
								<input
									id="iban"
									placeholder="IBAN*"
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									required
									value={iban}
									onChange={({ target }) => {
										target.setCustomValidity('');
										setIban(target.value);
									}}
									onKeyDown={({ key, currentTarget }) => {
										if (key === 'Enter')
											validateBankDetail(currentTarget, ibanCheck);
									}}
									onBlur={async ({ currentTarget }) => {
										validateBankDetail(currentTarget, ibanCheck);
									}}
								/>
								<label htmlFor="iban">IBAN*</label>
							</div>
						)}
						{bankType === 'OTHER' && (
							<>
								<div>
									<input
										id="email"
										placeholder="Email*"
										type="email"
										// eslint-disable-next-line jsx-a11y/no-autofocus
										autoFocus
										required
										value={email}
										onChange={({ target }) => setEmail(target.value)}
									/>
									<label htmlFor="email">Email*</label>
								</div>
								<p>
									After your reimbursement is approved, you will receive an
									email from Wise, our payment provider, with steps to retrieve
									the amount you&apos;ve requested. This will happen on Tuesday
									mornings for reimbursements approved before Monday at 17:00
									(GMT), and Thursday evenings for reimbursements approved
									before Thursday 16:00 (GMT).
								</p>
							</>
						)}
						<div>
							<ReceiptUploader
								setFiles={setFiles}
								maxFiles={receiptRequired ? 1 : undefined}
								budget
							/>
							{!files.ready.length && (
								<input
									name="receiptValidation"
									id="receiptValidation"
									type="file"
									tabIndex={-1}
									autoComplete="off"
									required
									onClick={(e) => {
										e.preventDefault();
										(
											document.querySelector(
												'.dzu-inputLabel'
											) as HTMLLabelElement
										)?.click();
									}}
									onChange={() => {}}
								/>
							)}
						</div>
						{canCalculateCost && (
							<div className="cost">
								{fees && (
									<p>
										Fee (only for completed expenses):&nbsp;
										{formatter.format(fee! / 100)}
									</p>
								)}
								<p>Total {formatter.format(totalCost! / 100)}</p>
							</div>
						)}
						<div>
							<Button
								state={
									// eslint-disable-next-line no-nested-ternary
									!saving
										? canCalculateCost
											? 'default'
											: 'disabled'
										: 'loading'
								}
							>
								Request expense
							</Button>
						</div>
						<p>
							Approved reimbursements will be paid directly to your bank account
							on Tuesday mornings (for requests received before 5PM on Mondays)
							and Thursday evenings (for requests received before 4PM on
							Thursdays).
						</p>
					</form>
				</div>
			</div>
		</Request>
	);
};

const Request = styled.div`
	> div.content {
		margin-top: 40px;
		display: flex;
		border-radius: 26px;
		background-color: #fff;
		padding: 47px 12% 40px 30px;
		gap: 60px;
		> div:first-child {
			flex-basis: 40px;
			button {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				border: 0;
				background-color: #525252;
				box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
			}
		}
		> div:last-child {
			flex: 1;
		}
		@media (max-width: 1200px) {
			margin-top: 35px;
			flex-direction: column;
			padding: 24px;
			gap: 20px;
		}
	}
	h2 {
		font-size: 24px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 21px;
		margin-bottom: 8px;
		+ p,
		+ p > a {
			color: black;
			font-size: 18px;
			font-family: 'TTInterfaces Regular';
			line-height: 28px;
			margin-bottom: 26px;
			@media (max-width: 992px) {
				margin-bottom: 20px;
			}
		}
		+ p > a {
			text-decoration: underline;
		}
	}
	form {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 50px;
		row-gap: 30px;
		> div {
			position: relative;
		}
		> p:last-child,
		> div:nth-last-child(2),
		> div:nth-last-child(3) {
			grid-column-start: 1;
			grid-column-end: 3;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		> p:nth-child(8) {
			color: #8c8c8d;
			font-size: 11px;
			font-family: 'TTInterfaces Regular';
			line-height: 16px;
			margin: 0;
		}
		> p:last-child {
			color: #8c8c8d;
			font-size: 11px;
			font-family: 'TTInterfaces Regular';
			line-height: 21px;
			text-align: center;
			width: 80%;
			margin: 0 auto;
			@media (max-width: 992px) {
				line-height: 20px;
				width: unset;
			}
		}
		> div:nth-last-child(4) {
			grid-column-start: 1;
			grid-column-end: 3;
		}
		> div:nth-last-child(3) {
			color: black;
			font-size: 18px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 26px;
			margin-top: 20px;
			p {
				margin-bottom: 0;
			}
			p:last-child {
				font-size: 26px;
				line-height: 26px;
				font-family: 'TTInterfaces Bold';
				@media (max-width: 992px) {
					font-size: 24px;
				}
			}
			p + p {
				margin-top: 14px;
			}
			@media (max-width: 992px) {
				font-size: 14px;
				text-align: center;
				margin-top: 12px;
			}
		}
		button {
			border-radius: 60px;
			padding: 12px 72px;
			background-color: #000;
			color: #f6f6f6;
			font-size: 16px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 26px;
			width: unset;
			min-width: unset;
			min-height: unset;
		}
		@media (max-width: 1200px) {
			column-gap: 24px;
		}
		@media (max-width: 992px) {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
	input,
	select {
		border-radius: 7px;
		padding: 14px 16px;
		border: 1px solid #d0d0d0;
		font-size: 16px;
		font-family: 'TTInterfaces Medium';
		width: 100%;
	}
	select {
		appearance: none;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
	}
	input:not(:placeholder-shown),
	select:not([data-value='']) {
		padding: 16px 16px 12px 16px;
	}
	input + label,
	select + label {
		color: black;
		font-size: 11px;
		font-family: 'TTInterfaces Regular';
		position: absolute;
		left: 17px;
		top: 4px;
		line-height: 13px;
		transition: opacity 0.2s ease-in-out;
	}
	input:placeholder-shown + label {
		opacity: 0;
	}
	input:not(:placeholder-shown) + label {
		opacity: 1;
	}
	select[data-value=''] + label {
		opacity: 0;
	}
	select:not([data-value='']) + label {
		opacity: 1;
	}
	#receiptValidation {
		opacity: 0;
		height: 0;
		width: 0;
		padding: 0;
		top: 70px;
		left: 50%;
		position: absolute;
	}
`;

export default SubmitExpense;
