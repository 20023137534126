import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {
	StyledInputBox,
	LoginButton,
	StyledOR,
	StyledORText,
	StyledORDivider,
	SocialLoginButton,
	StyledLabelForm,
	StyledPasswordContainer,
	StyledShowPwd,
	StyledForgotPassword,
	StyledForgotPwdText,
	StyledErrorMessage,
} from '../../styles/Form';
import { useAuth0 } from '../../contexts/react-auth0-spa';

const StyledImage = styled.img`
	max-height: 20px;
	object-fit: contain;
	position: absolute;
	left: 15px;
`;

const StyledInputContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const SignIn = ({ modeCallback }: any) => {
	const { loginWithCredentials, loginWithSocial } = useAuth0();
	const [passwordShown, setPasswordShown] = useState(false);
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const errors = {
		social: 'social',
		credentials: 'credentials',
	};
	const [error, setError] = useState(errors[params.get('invalid-auth')] ?? '');

	const validationSchema = Yup.object({
		email: Yup.string()
			.email('This email is not in a recognised format')
			.required('Please enter your email address'),
		password: Yup.string().required('Please enter your password'),
	});

	const togglePasswordVisiblity = () => {
		setPasswordShown(!passwordShown);
	};

	const handleLoginWithCredentials = async (values) => {
		try {
			await loginWithCredentials(
				values.email,
				values.password,
				new URL(window.location)
			);
		} catch (err) {
			setError('credentials');
		}
	};

	const handleLoginWithSocial = async () => {
		loginWithSocial('google-oauth2', new URL(window.location));
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{ email: '', password: '' }}
				validateOnChange={false}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleLoginWithCredentials(values);
					setSubmitting(false);
				}}
			>
				{({ handleSubmit, setFieldValue }) => {
					return (
						<Form onSubmit={handleSubmit} className="mt-5">
							<Row noGutters>
								<StyledLabelForm htmlFor="email">Email</StyledLabelForm>
								<Field
									type="email"
									name="email"
									placeholder="you@example.com"
									onChange={({ target: { value } }) => {
										setError('');
										setFieldValue('email', value);
									}}
									as={StyledInputBox}
								/>
								<ErrorMessage name="email" component={StyledErrorMessage} />
							</Row>

							<Row noGutters>
								<StyledPasswordContainer>
									<StyledLabelForm htmlFor="password">Password</StyledLabelForm>
									<StyledShowPwd onClick={togglePasswordVisiblity}>
										{passwordShown ? 'Hide' : 'Show'}
									</StyledShowPwd>
								</StyledPasswordContainer>
								<StyledInputContainer>
									<Field
										type={passwordShown ? 'text' : 'password'}
										name="password"
										placeholder="Please enter your password"
										as={StyledInputBox}
										onChange={({ target: { value } }) => {
											setError('');
											setFieldValue('password', value);
										}}
									/>
								</StyledInputContainer>

								<ErrorMessage name="password" component={StyledErrorMessage} />
								{error !== '' && (
									<StyledErrorMessage>
										{error === 'credentials' && (
											<div>
												Try &quot;Sign In with Google&quot; as you may have
												signed up using this method. If that doesn&apos;t work,
												please contact us at{' '}
												<a
													target="_blank"
													rel="noreferrer"
													href="mailto:support@withjuno.com"
													style={{
														color: 'unset',
														textDecoration: 'underline',
													}}
												>
													support@withjuno.com
												</a>
											</div>
										)}
										{error === 'social' && (
											<div>
												Try signing in with your email and password as you may
												have signed up using this method. If that doesn&apos;t
												work, try{' '}
												<button
													type="button"
													style={{
														border: '0',
														background: 'none',
														padding: '0',
														color: 'unset',
														textDecoration: 'underline',
													}}
													onClick={() => modeCallback('reset')}
												>
													forgot password
												</button>{' '}
												or please contact us at{' '}
												<a
													target="_blank"
													rel="noreferrer"
													href="mailto:support@withjuno.com"
													style={{
														color: 'unset',
														textDecoration: 'underline',
													}}
												>
													support@withjuno.com
												</a>
											</div>
										)}
									</StyledErrorMessage>
								)}
							</Row>
							<Row noGutters>
								<StyledForgotPassword onClick={() => modeCallback('reset')}>
									<StyledForgotPwdText>
										Forgot your password?
									</StyledForgotPwdText>
								</StyledForgotPassword>
							</Row>
							<Row noGutters>
								<LoginButton type="submit" id="submitButton">
									Sign In
								</LoginButton>
							</Row>
						</Form>
					);
				}}
			</Formik>
			<Row noGutters>
				<StyledOR>
					<StyledORDivider />
					<StyledORText>OR</StyledORText>
					<StyledORDivider />
				</StyledOR>
			</Row>
			<Row noGutters>
				<SocialLoginButton onClick={() => handleLoginWithSocial()}>
					<StyledImage src="/images/login/google/g-logo.png" />
					Sign In with Google
				</SocialLoginButton>
			</Row>
		</>
	);
};

export default SignIn;
