import React, { ForwardedRef, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

type LabelSize = 'default' | 'small';
type LabelRadius = 'default' | 'rounded';

type LabelProps = {
	size?: LabelSize;
	radius?: LabelRadius;
	backgroundColor?: string;
	fontColor?: string;
} & LabelHTMLAttributes<HTMLLabelElement>;

interface StyledLabelProps {
	labelSize?: LabelSize;
	borderRadius?: LabelRadius;
	backgroundColor?: string;
	fontColor?: string;
}

const StyledLabel = styled.label<StyledLabelProps>`
	display: grid;
	align-items: center;
	justify-content: center;
	padding: ${(props) => (props.labelSize === 'small' ? '8px 12px' : '12px')};
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : '#000'};
	color: ${(props) => (props.fontColor ? props.fontColor : '#fff')};
	font-size: 14px;
	font-weight: 500;
	line-height: 22.4px;
	border-radius: ${(props) =>
		props.borderRadius === 'default' ? 'none' : '20px'};
	transition: all 0.2s ease-out;
`;

const Label = React.forwardRef(
	(
		{
			size = 'default',
			radius = 'default',
			backgroundColor = undefined,
			fontColor = undefined,
			children,
			...props
		}: LabelProps,
		ref: ForwardedRef<HTMLLabelElement>
	) => (
		<StyledLabel
			ref={ref}
			labelSize={size}
			borderRadius={radius}
			backgroundColor={backgroundColor}
			fontColor={fontColor}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{children}
		</StyledLabel>
	)
);

export default Label;
