import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { H4, SmallTextSecondary } from '../../StylesV3Main';
import config from '../../../config';

type TickittoEvent = {
	event: {
		id: string;
		image: string;
		city: string;
		fromPoints: number;
		state: string;
		country: string;
		title: string;
		shortDescription: string;
		venueName: string;
	};
};

const EventCard = ({ event }: TickittoEvent) => {
	const [orientation, setOrientation] = useState<string>();

	return (
		<StyledContainer>
			<Link to={`/events/${event.id}`} style={{ textDecoration: 'none' }}>
				<CardImageContainer>
					<CardImageBackground backgroundImage={event.image} />

					<CardImage
						loading="lazy"
						alt={event.title}
						src={event.image || config.PLACEHOLDER_IMAGE}
						onLoad={({ currentTarget }) => {
							if (currentTarget.naturalWidth > currentTarget.naturalHeight) {
								setOrientation('cover');
							} else {
								setOrientation('contain');
							}
						}}
						orientation={orientation}
					/>
				</CardImageContainer>
				<SRow>
					<TitleView>
						<H3Text>{event.title}</H3Text>

						<SmallTextSecondary>
							{event.venueName}, {event.city}, {event.country}
						</SmallTextSecondary>
					</TitleView>
					<H4>{event.fromPoints} points</H4>
				</SRow>
			</Link>
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	width: 100%;
	background-color: white;
	display: flex;
	border: 1px solid rgba(0, 0, 0, 0.05);
	flex-direction: column;
	justify-content: start;
	align-items: start;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		transition: 0.2s;
	}
	a {
		width: 100%;
	}

	@media (max-width: 992px) and (min-width: 550px) {
		border: none;
		height: 190px;
		margin-bottom: 6px;
		a {
			display: flex;
			height: 190px;
		}
	}
`;

const CardImage = styled.img<{ orientation?: string }>`
	width: 100%;
	height: 281px;
	object-fit: ${({ orientation }) => orientation};
	object-position: center;
	position: relative;
	z-index: 1;
	@media (max-width: 992px) and (min-width: 550px) {
		object-fit: cover;
		height: 100%;
		max-height: 190px;
	}
`;

const CardImageBackground = styled.div<{
	backgroundImage: string;
}>`
	background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
		url(${({ backgroundImage }) => backgroundImage});
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		backdrop-filter: blur(120px);
	}
`;

const CardImageContainer = styled.div`
	position: relative;
	overflow: hidden;
	@media (max-width: 992px) and (min-width: 550px) {
		width: 100%;
		height: calc(100%);
	}
`;

const SRow = styled.div`
	width: 100%;
	height: 170px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	padding: 24px;
	@media (max-width: 992px) and (min-width: 550px) {
		padding: 0 16px;
	}
`;

const H3Text = styled.h3`
	font-family: 'Roobert Semi Bold';
	font-size: 18px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-right: 2px;
	line-height: 24px;
	color: #191a1c;
	margin-bottom: 0px;
	font-weight: 500;
`;

const TitleView = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	@media (max-width: 992px) and (min-width: 550px) {
		justify-content: center;
	}
`;

export default EventCard;
