/* eslint-disable no-console */
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import config from '../../../../config';
import { useClient } from '../../../../hooks';
import Modal from '../../../../styles/shared/Modal';
import MinusButton from './minus.png';
import PlusButton from './plus.png';
import Button from '../../../../styles/shared/Button/Button';
import useCurrencyFormatter from '../../../../utils/useCurrencyFormatter';

const TopUp = ({
	showModal,
	setShowModal,
	goBack,
	team,
	handleUpdate,
	handleTransactionsUpdate,
}: any) => {
	const client = useClient();
	const [quantity, setQuantity] = useState(20);
	const [processing, setProcessing] = useState(false);
	const teamStatus = team.stripeSubscriptionData?.status || 'inactive';

	const currencyFormatter = useCurrencyFormatter();

	const handleSubmit = async (ev: any) => {
		ev.preventDefault();
		setProcessing(true);

		try {
			await client.post(`${config.teams.HOST}/teams/topup/${team.id}`, {
				points: quantity,
			});

			await handleUpdate();
			handleTransactionsUpdate();

			setShowModal(false);
		} catch (err) {
			console.error(err);
		}

		setProcessing(false);
	};

	return (
		<Modal
			open={showModal}
			centered
			onClose={() => {
				setProcessing(false);
				setShowModal(false);
			}}
		>
			<Container>
				<div>
					<h2>Top up Juno Points</h2>
					<p>{`Top up your ${team.name} team wallet. The cost will be added to your next invoice.`}</p>
				</div>
				<Row noGutters className="justify-content-around">
					<StyledBlock>
						<form id="payment-form" className="w-100" onSubmit={handleSubmit}>
							<div>
								<div>
									<button
										type="button"
										onClick={() => {
											if (quantity > 1) setQuantity(+quantity - 1);
										}}
									>
										<img src={MinusButton} alt="minus-button" />
									</button>
									<input
										aria-label="points"
										type="number"
										min="1"
										value={quantity}
										{...{ autofocus: 'true' }}
										onChange={({ target: { value } }) => {
											setQuantity(+value);
										}}
									/>
									<button
										type="button"
										onClick={() => {
											setQuantity(+quantity + 1);
										}}
									>
										<img src={PlusButton} alt="plus-button" />
									</button>
								</div>
							</div>

							<div>
								<p>{currencyFormatter(quantity, team.currency || 'gbp')}</p>
							</div>

							<div>
								{goBack && (
									<Button
										type="button"
										size="small"
										onClick={() => {
											goBack();
										}}
										state="default"
										buttonType="hollow"
									>
										Cancel
									</Button>
								)}

								<Button
									type="submit"
									size="small"
									state={
										// eslint-disable-next-line no-nested-ternary
										!['active', 'trialing', 'past_due'].includes(teamStatus)
											? 'disabled'
											: processing
											? 'loading'
											: 'default'
									}
									buttonType="primary"
								>
									Top Up
								</Button>
							</div>
						</form>
					</StyledBlock>
				</Row>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	padding: 40px 45px;
	position: relative;
	max-width: 450px;
	font-family: 'TTInterfaces Medium';
	gap: 20px;
	display: flex;
	flex-direction: column;

	> div:first-of-type {
		text-align: center;

		h2 {
			font-family: 'TTInterfaces Demibold';
			font-size: 24px;
			line-height: 130%;
			color: #1d1d1d;
		}

		p {
			font-family: 'TTInterfaces Medium';
			font-size: 18px;
			line-height: 140%;
			color: rgba(29, 29, 29, 0.35);
		}
	}

	@media (max-width: 500px) {
		width: 100%;
		padding: 20px;
	}
`;

const StyledBlock = styled.div`
	h2 {
		text-align: left;
		font-size: 16px;
		font-family: 'TTInterfaces Demibold';
	}

	width: 100%;
	text-align: center;
	background-color: white;

	form > div:first-of-type {
		div:first-of-type {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;

			input {
				width: 190px;
				height: 113px;

				font-family: 'TTInterfaces Medium';
				font-size: 90px;
				line-height: 112px;

				border: none;
				text-align: center;
				color: #1d1d1d;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type='number'] {
				-moz-appearance: textfield;
			}

			button {
				align-self: center;
				background: none;
				border: none;
				padding: 0;
				border-radius: 50px;

				img {
					width: 32px;
					height: 32px;
				}
			}
		}
	}

	form > div:last-of-type {
		display: flex;
		flex-direction: row;
		margin: 45px 0 20px;
		justify-content: space-evenly;
	}
`;

export default TopUp;
