import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { H5, Small, ParagraphGrey } from '../../styles/StylesV3Main';
import { RoundedCard } from '../../styles/ProductStyles';
import { MdAdd, MdRemove } from 'react-icons/md';

import Markdown from 'markdown-to-jsx';

import { animated, useSpring } from 'react-spring';

const H5TextSecondary = styled(H5)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const InformationView = styled.div`
	width: 463px;
	height: fit-content;
	border: 1px solid ${(props) => props.theme.mainColors.color.separator};
	padding: 0px 16px;
	border-radius: 3px;
	margin-botton: 48px;
	@media (max-width: 1080px) {
		width: 420px;
	}

	@media (max-width: 992px) {
		width: 100%;
	}
`;

const InfoRow = styled.div`
	width: 100%;
	min-height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	overflow: hidden;
	border-bottom: ${(props) =>
		props.border
			? `1px solid ${props.theme.mainColors.color.separator}`
			: 'none'};
`;

const BookingHeader = styled.div`
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.mainColors.color.separator};
	cursor: pointer;
	${({ open }) =>
		open
			? `border-bottom:none;`
			: ` border-bottom: 1px solid ${(props) =>
					props.theme.mainColors.color.separator};`}
`;
const BookingBody = styled(animated.div)`
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const BenefitsHeader = styled.div`
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	${({ open }) =>
		open
			? `border-bottom:none;`
			: ` border-bottom: 1px solid ${(props) =>
					props.theme.mainColors.color.separator};`}
`;
const BenefitsBody = styled(animated.div)`
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const ServiceDetail = ({ service, product }) => {
	const [openBenefits, setOpenBenefits] = useState(false);
	const [openUponBooking, setOpenUponBooking] = useState(false);

	const bookingprops = useSpring({
		from: { height: 0 },
		to: { height: openUponBooking ? 'fit-content' : 0 },
	});

	const props = useSpring({
		from: { height: 0 },
		to: { height: openBenefits ? 'fit-content' : 0 },
		config: { easing: 'd3-ease' },
	});

	const healthScore = (score) => {
		if (score <= 1) return 'Slight';
		else if (score <= 2 && score > 1) return 'Small';
		else if (score <= 3 && score > 2) return 'Good';
		else if (score <= 4 && score > 3) return 'High';
		else if (score > 4) return 'Very high';
	};
	return (
		<InformationView>
			{service.location.address.formatted_address !== '' && (
				<InfoRow border>
					<Col
						xs={3}
						className="justify-content-start align-items-center px-0 py-0"
					>
						<H5TextSecondary style={{ textAlign: 'left' }}>
							Location
						</H5TextSecondary>
					</Col>
					<Col
						xs={9}
						className="justify-content-start align-items-center px-0 py-1"
					>
						<H5 style={{ textAlign: 'right' }}>
							{service.location.address.formatted_address}
						</H5>
					</Col>
				</InfoRow>
			)}
			{service.cancellationPolicy !== '' &&
				service.cancellationPolicy !== 'none' && (
					<InfoRow border>
						<Col
							xs={3}
							className="justify-content-start align-items-center px-0 py-0"
						>
							<H5TextSecondary style={{ textAlign: 'left' }}>
								Cancellation
							</H5TextSecondary>
						</Col>
						<Col
							xs={9}
							className="justify-content-start align-items-center px-0 py-1"
						>
							<H5 style={{ textAlign: 'right' }}>
								<Markdown
									options={{
										forceBlock: true,
										overrides: {
											p: {
												props: {
													className: 'my-0 mx-0',
												},
											},
											a: {
												props: {
													target: '_blank',
												},
											},
										},
									}}
								>
									{service.cancellationPolicy}
								</Markdown>
							</H5>
						</Col>
					</InfoRow>
				)}
			{service.suitableFor !== '' && service.suitableFor !== 'none' && (
				<InfoRow border>
					<Col
						xs={3}
						className="justify-content-start align-items-center px-0 py-0"
					>
						<H5TextSecondary style={{ textAlign: 'left' }}>
							Suitable for
						</H5TextSecondary>
					</Col>
					<Col
						xs={9}
						className="justify-content-start align-items-center px-0 py-1"
					>
						<H5 style={{ textAlign: 'right', position: 'relative' }}>
							<Markdown
								options={{
									forceBlock: true,
									overrides: {
										p: {
											props: {
												className: 'my-0 mx-0',
											},
										},
										a: {
											props: {
												target: '_blank',
											},
										},
									},
								}}
							>
								{service.suitableFor}
							</Markdown>
						</H5>
					</Col>
				</InfoRow>
			)}
			{service.uponBooking !== '' && service.uponBooking !== 'none' && (
				<Row noGutters>
					<Col>
						<BookingHeader
							open={openUponBooking}
							onClick={() => setOpenUponBooking(!openUponBooking)}
						>
							<H5TextSecondary>Upon Booking</H5TextSecondary>
							{!openUponBooking ? (
								<MdAdd size={20} style={{ cursor: 'pointer' }} />
							) : (
								<MdRemove size={20} style={{ cursor: 'pointer' }} />
							)}
						</BookingHeader>

						<BookingBody
							style={{
								...bookingprops,
								display: 'flex',
								direction: 'row',
								alignItems: 'flex-start',
							}}
						>
							{openUponBooking && (
								<Col className="px-0 py-0">
									<InfoRow border>
										<H5>
											<Markdown
												options={{
													forceBlock: true,
													overrides: {
														H5: {
															props: {
																className: 'my-0',
															},
														},
														a: {
															props: {
																target: '_blank',
															},
														},
													},
												}}
											>
												{service.uponBooking}
											</Markdown>
										</H5>
									</InfoRow>
								</Col>
							)}
						</BookingBody>
					</Col>
				</Row>
			)}
			<Row noGutters>
				<Col>
					<BenefitsHeader
						open={openBenefits}
						onClick={() => setOpenBenefits(!openBenefits)}
					>
						<H5TextSecondary>Juno Benefits</H5TextSecondary>

						{!openBenefits ? (
							<MdAdd size={20} style={{ cursor: 'pointer' }} />
						) : (
							<MdRemove size={20} style={{ cursor: 'pointer' }} />
						)}
					</BenefitsHeader>

					<BenefitsBody
						style={{
							...props,
							display: 'flex',
							direction: 'column',
							alignItems: 'flex-start',
						}}
					>
						{openBenefits && (
							<Col className="px-0 py-0">
								<InfoRow border>
									<ParagraphGrey>Socialising</ParagraphGrey>
									<RoundedCard background={'#FFDA79'}>
										<Small>{healthScore(product.healthBenefits.soc)}</Small>
									</RoundedCard>
								</InfoRow>
								<InfoRow border>
									<ParagraphGrey>Emotional Benefit</ParagraphGrey>
									<RoundedCard background={'#F3B398'}>
										<Small>{healthScore(product.healthBenefits.emo)}</Small>
									</RoundedCard>
								</InfoRow>
								<InfoRow border>
									<ParagraphGrey>Figuring out life</ParagraphGrey>
									<RoundedCard background={'#D6BBF7'}>
										<Small>{healthScore(product.healthBenefits.psy)}</Small>
									</RoundedCard>
								</InfoRow>
								<InfoRow border>
									<ParagraphGrey>Getting fit</ParagraphGrey>
									<RoundedCard background={'#A1E2CF'}>
										<Small>{healthScore(product.healthBenefits.phy)}</Small>
									</RoundedCard>
								</InfoRow>
							</Col>
						)}
					</BenefitsBody>
				</Col>
			</Row>
		</InformationView>
	);
};

export default ServiceDetail;
