import React from 'react';
import styled from 'styled-components';
import events from './events.png';

const Booking = () => (
	<Layout>
		<div />
		<div>
			<h2>Give your team the freedom to choose what matters to them</h2>
			<p>
				Unlock hundreds of events and experiences for you and your team. Create
				and onboard your team in minutes.
			</p>
			<a href="https://www.withjuno.com/sign-up">Get started</a>
		</div>
	</Layout>
);

const Layout = styled.div`
	flex-basis: 525px;
	align-self: flex-start;
	background-color: #fff;
	@media (max-width: 992px) {
		flex-grow: 1;
	}
	div:first-child {
		background-image: url(${events});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 380px;
		width: 100%;
	}
	div:nth-child(2) {
		padding: 0 50px 50px;
		@media (max-width: 550px) {
			padding: 0 32px 32px;
		}
	}
	h2 {
		font-family: Roobert Semi Bold;
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 34px;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 16px;
		margin: 0;
		@media (max-width: 550px) {
			padding-top: 0;
			font-size: 21px;
			line-height: 25px;
		}
	}
	p {
		font-family: Roobert Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		padding-bottom: 30px;
		margin: 0;
		@media (max-width: 550px) {
			font-size: 13px;
			line-height: 16px;
		}
	}
	a,
	a:hover {
		background: linear-gradient(87.35deg, #e08177 0%, #d67186 100%);
		border-radius: 6px;
		display: block;
		text-align: center;
		color: #fff;
		text-decoration: none;
		font-family: Roobert Regular;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		padding: 19px 0;
		@media (max-width: 550px) {
			font-size: 13px;
			line-height: 16px;
		}
	}
`;

export default Booking;
