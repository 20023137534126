import styled from 'styled-components';

export const StyledDivider = styled.div`
	max-height: 1px;
	border-bottom: 1px solid black;
	margin: 0px 0px 0px 0px;
	width: 100%;
`;

export const StyledH1 = styled.h1`
	font-family: 'Roobert Bold';
	font-size: 38px;
	@media (max-width: 991.98px) {
		font-size: 38px;
	}
	@media (max-width: 370px) {
		font-size: 38px;
	}
`;

export const StyledH2 = styled.h2`
	font-family: 'Roobert Bold';
	font-size: 1.25rem;
`;

export const StyledA = styled.a`
	font-family: 'Roobert Bold';
	font-size: 1.25rem;
`;

export const StyledH4 = styled.h4`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	@media (max-width: 991.98px) {
		font-size: 20px;
	}
`;

export const StyledP1 = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 32px;
	margin-bottom: 5%;
	@media (max-width: 991.98px) {
		font-size: 15px;
		line-height: 1.5rem;
	}
`;

export const StyledP2 = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 1.8rem;
	margin: 0;
	@media (max-width: 991.98px) {
		font-size: 15px;
		line-height: 1.5rem;
	}
`;

export const StyledP2Bold = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	margin: 0;
`;

export const StyledP = styled.p`
	font-family: 'AT Surt SemiBold';
	font-size: 1rem;
	margin: 0px;
`;

export const StyledPSmall = styled.p`
	font-family: 'AT Surt SemiBold';
	font-size: 0.625rem;
	margin: 0px;
`;

export const StyledPFancy = styled.p`
	font-family: 'Bianco Serif';
	font-size: 1rem;
`;

export const StyledPSans = styled.p`
	font-family: 'Product Sans Regular';
	font-size: 1rem;
	margin: 5px;
`;

export const StyledPSansMedium = styled.p`
	font-family: 'Product Sans Medium';
	font-size: 1rem;
	margin: 0px;
`;

export const StyledU = styled.u`
	font-family: 'AT Surt SemiBold';
	font-size: 1rem;
	margin: 0px;
`;

export const StyledMuted = styled.p`
	font-family: 'AT Surt SemiBold';
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.875rem;
`;

export const MainButton = styled.button`
	font-family: 'Product Sans Medium';
	width: 100%;
	height: 50px;
	border-radius: 3px;
	color: white;
	background-color: black;
	display: flex;
	text-transform: capitalize;
	justify-content: center;
	align-items: center;
	border: none;
	&:focus {
		border: none;
		outline: none;
	}
`;
