import React from 'react';
import styled from 'styled-components';
import { IoHeart } from 'react-icons/io5';
import { useUserContext } from '../../contexts/userContext';
import config from '../../config';
import { getUsernameEmail } from './Functions';

const StyledDiv = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  color: white;
  z-index: 1000;
  cursor: pointer;
  }
`;

const StyleAddtoFavoutite = styled(IoHeart)`
	color: #e2a1c0;
	transition: all 0.15s;
	&:hover {
		transform: scale(1.15, 1.15);
	}
`;

const StyledRemoveFromFavoutite = styled(IoHeart)`
	color: '#5B6166';
	opacity: 20%;
	transition: all 0.15s;
	&:hover {
		transform: scale(1.15, 1.15);
		opacity: 50%;
	}
`;

const Favourite = ({
	size,
	position,
	product,
	compensate,
	searchCard,
	...props
}) => {
	const { userData, changeFavorite } = useUserContext();
	const isFavourite = userData?.favorites?.find((item) => item === product.id);
	const handleClick = (e) => {
		e.stopPropagation();
		changeFavorite(product.id);
	};

	return (
		<StyledDiv
			position={position}
			compensate={compensate}
			onClick={handleClick}
			searchCard={searchCard}
			{...props}
		>
			{isFavourite ? (
				<StyleAddtoFavoutite
					size={size ? size : 20}
					color="#E2A1C0"
					title="Remove from Favourites"
				/>
			) : (
				<StyledRemoveFromFavoutite
					size={size ? size : 20}
					color="#5B6166"
					title="Add to Favourites"
				/>
			)}
		</StyledDiv>
	);
};

export default Favourite;
