/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import MultiSelect from '../../../styles/shared/Select/MultiSelect';
import { PSansBoldText } from '../../../styles/StylesV2';
import {
	H2,
	Separator,
	SmallTextSecondaryMedium,
} from '../../../styles/StylesV3Main';
import config from '../../../config';
import { useClient } from '../../../hooks';
import Button from '../../../styles/shared/Button/Button';

const StyledButtonRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	@media (max-width: 991.98px) {
		padding-right: 0px;
	}
`;

const StyledSeparator = styled(Separator)`
	margin: 24px 0px;
`;

const InvisibleSeperator = styled.div`
	margin: 24px 0px;
`;

const StyledInputContainer = styled.div`
	display: flex;
	margin-top: auto;
	margin-bottom: auto;
`;

const StyledRow = styled.div`
	display: flex;
	padding-top: 4px;
`;

const StyledHeading = styled(H2)`
	margin-bottom: 10px;
`;
const StyledLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
	margin-left: 12px;
	margin-top: auto;
	margin-bottom: auto;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 24px;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}

	input:checked + .slider {
		background-color: #1cae71;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #1cae71;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(16px);
		-ms-transform: translateX(16px);
		transform: translateX(16px);
	}
`;

const StyledInput = styled.input`
	margin-left: 12px;

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	display: flex;
	justify-content: start;
	align-items: center;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	width: 120px;
	height: 40px;
	padding: 5.5px 16px;
	border: none;
`;

const StyledButton = styled(Button)`
	margin-left: 16px;
	@media (max-width: 991.98px) {
		width: 100%;
		margin-left: 0px;
		margin-bottom: 16px;
	}
`;

// Used for the 'Teams to sync' multi-select dropdown
const ALL_TEAMS = { label: 'All Teams', value: 'ALL_TEAMS' };

const PreferencesModal = ({
	showModal,
	onHide,
	onSuccess,
	team,
	lastSyncDate,
	handleInitialSync,
}) => {
	const [defaultPlan, setDefaultPlan] = useState(team.defaultAddToPlan);
	const [welcomePoints, setWelcomePoints] = useState(
		team.welcomePoints || team.budget
	);
	const [syncTeams, setSyncTeams] = useState([]);
	const [selectedSyncTeams, setSelectedSyncTeams] = useState([ALL_TEAMS]);
	const [isLoadingSyncTeams, setIsLoadingSyncTeams] = useState(false);
	const client = useClient();

	const handleSave = async () => {
		try {
			const hrisTeams =
				selectedSyncTeams.reduce(
					(previous, current) =>
						current.value !== ALL_TEAMS.value
							? previous.concat(current.value)
							: previous,
					[]
				) || [];

			await client.post(
				`${config.teams.HOST}/teams/update-default-welcome/${team.id}`,
				{
					defaultAddToPlan: defaultPlan,
					welcomePoints,
					hrisTeams,
				}
			);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('failed to update defaults', err.message);
		}
		onSuccess();
	};

	const fetchTeams = async () => {
		try {
			setIsLoadingSyncTeams(true);

			const response = await client.get(
				`${config.teams.HOST}/teams/merge/merge-teams/${team.id}`
			);

			const { fetchedTeams } = response.data;

			setSyncTeams([
				ALL_TEAMS,
				...fetchedTeams.map((t) => {
					return { label: t.name, value: t.id };
				}),
			]);

			const previousSelectedSyncTeams = fetchedTeams.filter((t) =>
				team.mergeTeams.includes(t.id)
			);

			if (previousSelectedSyncTeams.length) {
				setSelectedSyncTeams(
					previousSelectedSyncTeams.map((t) => {
						return { label: t.name, value: t.id };
					})
				);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('failed to fetch list of teams', err);
		} finally {
			setIsLoadingSyncTeams(false);
		}
	};

	const handleSelectTeams = async (newValue, actionMeta) => {
		if (
			(actionMeta.action === 'select-option' &&
				actionMeta.option?.value === ALL_TEAMS.value) ||
			actionMeta.action === 'clear'
		) {
			setSelectedSyncTeams([ALL_TEAMS]);
		} else {
			const newAllTeamsExcluded = newValue.filter(
				(nv) => nv.value !== ALL_TEAMS.value
			);

			setSelectedSyncTeams(newAllTeamsExcluded);
		}
	};

	useEffect(() => {
		try {
			fetchTeams();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err.message);
		}
	}, []);

	return (
		<Modal show={showModal} animation={false} onHide={onHide} centered>
			<Modal.Body style={{ padding: '24px' }}>
				<StyledHeading>Preferences</StyledHeading>
				<SmallTextSecondaryMedium>
					Review your preferences about how we sync with your HRIS platform
				</SmallTextSecondaryMedium>
				<StyledSeparator />

				<form
					onSubmit={async (e) => {
						e.preventDefault();
						await handleSave();
						// eslint-disable-next-line no-unused-expressions
						handleInitialSync();
					}}
				>
					<PSansBoldText>Automatically add to plan</PSansBoldText>

					<StyledRow>
						<div>
							<SmallTextSecondaryMedium>
								Employees who accept their invite are automatically added to the
								plan and receive points on Juno Day
							</SmallTextSecondaryMedium>
						</div>
						<StyledInputContainer>
							<StyledLabel>
								<input
									type="checkbox"
									checked={defaultPlan}
									onChange={() => setDefaultPlan(!defaultPlan)}
								/>
								<span className="slider" />
							</StyledLabel>
						</StyledInputContainer>
					</StyledRow>

					<InvisibleSeperator />

					<PSansBoldText>Welcome Points</PSansBoldText>

					<StyledRow>
						<div>
							<SmallTextSecondaryMedium>
								The amount of points employees will receive automatically the
								moment they accept their invite. Please note: when there are not
								enough points in your team wallet when an invite gets accepted,
								your employee will not receive any welcome points.
							</SmallTextSecondaryMedium>
						</div>

						<StyledInputContainer>
							<StyledInput
								autoFocus
								name="welcomePoints"
								id="welcomePoints"
								type="number"
								min="0"
								step="1"
								defaultValue={welcomePoints}
								onChange={(currentTarget) => {
									setWelcomePoints(Number(currentTarget.target.value));
								}}
							/>
						</StyledInputContainer>
					</StyledRow>
					<InvisibleSeperator />
					<PSansBoldText>Teams to sync</PSansBoldText>
					<StyledRow>
						<div style={{ width: '100%' }}>
							<MultiSelect
								placeholder="Select team(s)..."
								isMulti
								isLoading={isLoadingSyncTeams}
								options={syncTeams}
								value={selectedSyncTeams}
								onChange={handleSelectTeams}
							/>
						</div>
					</StyledRow>

					<StyledSeparator />

					<StyledButtonRow noGutters>
						{lastSyncDate ? (
							<StyledButton
								buttonType="secondary"
								state="default"
								size="small"
								onClick={() => onHide()}
							>
								Cancel
							</StyledButton>
						) : null}
						<StyledButton
							type="submit"
							state={isLoadingSyncTeams ? 'loading' : 'default'}
							size="small"
						>
							Save & Start Syncing
						</StyledButton>
					</StyledButtonRow>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default PreferencesModal;
