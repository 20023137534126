import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BiLoaderAlt } from 'react-icons/bi';

const spinnerFrames = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

interface StyledSpinnerProps {
	color?: string;
}

const StyledSpinner = styled(BiLoaderAlt)<StyledSpinnerProps>`
	width: 24px;
	height: 24px;
	animation: ${spinnerFrames} 1s linear infinite;
	color: ${(props) => props.color};
`;

interface SpinnerProps {
	color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ color }) => {
	return <StyledSpinner color={color} />;
};

Spinner.defaultProps = {
	color: '#000000',
};

export default Spinner;
