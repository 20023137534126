import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Modal, ModalBody } from 'react-bootstrap';
import { H2, Small } from '../../styles/StylesV3Main';
import { ModalButton, BoxInputLabel, SearchInput } from '../../styles/StylesV2';
import PhoneInput, {
	isValidPhoneNumber,
	formatPhoneNumberIntl,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
	width: 100%;
`;

const Description = styled(Small)`
	width: 100%;
	margin-top: 8px;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const PhoneModal = ({
	showModal,
	setShowModal,
	goBack,
	description,
	value,
	checked,
	required,
	...props
}) => {
	const [localValue, setLocalValue] = useState(value);

	return (
		<Modal
			show={showModal}
			centered
			animation={false}
			onHide={() => {
				setShowModal(false);
			}}
		>
			<ModalBody style={{ padding: 24 }}>
				<Row
					noGutters
					className="mt-3 justify-content-center align-items-center"
				>
					<H2>Phone Number</H2>
				</Row>
				<Row
					noGutters
					className="justify-content-start align-items-center"
					style={{ width: '100%' }}
				>
					<Col className="px-0 py-0">
						<StyledLabel style={{ marginLeft: '40px' }}>Phone</StyledLabel>
						<PhoneInput
							type="tel"
							value={
								localValue
									? formatPhoneNumberIntl(localValue.trim())
										? formatPhoneNumberIntl(localValue.trim())
										: localValue.trim()
									: ''
							}
							placeholder="+33 345672222"
							countryCallingCodeEditable={true}
							inputComponent={SearchInput}
							onChange={(e) => {
								e ? setLocalValue(e) : setLocalValue('');
							}}
							international
						/>
						{description && <Description>{description}</Description>}
					</Col>
				</Row>
				<StyledButtonRow>
					<Col xs={6} className="px-0 py-0 mr-2">
						<ModalButton
							className="mr-2"
							cancelButton={true}
							width={'90%'}
							onClick={() => {
								setLocalValue(value);
								goBack();
							}}
						>
							Cancel
						</ModalButton>
					</Col>
					<Col xs={6} className="px-0 py-0 ml-2">
						<ModalButton
							className="ml-2"
							width={'90%'}
							disabled={
								required
									? localValue
										? !isValidPhoneNumber(localValue)
										: true
									: false
							}
							onClick={() => {
								props.onChange({
									target: { value: localValue },
								});
								setShowModal(false);
							}}
						>
							Update
						</ModalButton>
					</Col>
				</StyledButtonRow>
			</ModalBody>
		</Modal>
	);
};

export default PhoneModal;
