/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
	WarningDiv,
	WarningText,
	RowView,
	StyledErrorMessage,
} from '../../../styles/ProductStyles';
import {
	CustomDatePicker,
	BookingInput,
	BookingPhone,
	BookingTel,
	BookingNumber,
	BookingEmail,
	BookingAddress,
	BookingTextArea,
	BookingSelect,
	BookingBankDetails,
	BookingFileInput,
	BookingCurrencyAmount,
} from '../OrderOptions';
import config from '../../../config';
import { PrimaryButton } from '../../../styles/Buttons';
import {
	H1,
	H5,
	H3,
	Separator,
	SmallTextSecondary,
} from '../../../styles/StylesV3Main';
import { useUserContext } from '../../../contexts/userContext';
import { useCurrentTeam } from '../../../hooks';
import bankdetails from '../../../utils/bankdetails.json';

const OrderContainer = styled.div`
	padding: 48px;
	width: 100%;
	height: fit-content;
	background: #ffffff;
	border-radius: 3px;
	@media (max-width: 992px) {
		padding: 24px;
	}
`;

const OrderOptionsPageAuthenticated = ({
	product,
	service,
	cost,
	fee,
	type,
	processing,
	options,
	handleCostUpdate,
	handleNextStep,
	proceedButtons,
	handleOptionsChange,
}) => {
	const { userData } = useUserContext();
	const { teamMember } = useCurrentTeam();

	const renderOption = ({
		option,
		handleCostUpdate,
		setOptions,
		index,
		setOptionsSave,
	}) => {
		switch (option.type) {
			case 'phone':
				return (
					<Field
						as={BookingPhone}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={async ({ target: { value } }) => {
							setOptions(value);
						}}
						setSave={(value) => setOptionsSave(value)}
					/>
				);
			case 'tel':
				return (
					<Field
						as={BookingTel}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={({ target: { value } }) => {
							setOptions(value);
						}}
						setSave={(value) => setOptionsSave(value)}
					/>
				);
			case 'input':
				return (
					<Field
						as={BookingInput}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={({ target: { value } }) => {
							setOptions(value);
						}}
						setSave={(value) => setOptionsSave(value)}
					/>
				);
			case 'number':
				return (
					<>
						<Field
							as={BookingNumber}
							name={`${index}.value`}
							key={index}
							option={option}
							setNewValue={async (value) => {
								setOptions(value);
								handleCostUpdate(value);
							}}
						/>
						{type === 'card' && option.value !== '' && (
							<SmallTextSecondary className="mt-2">
								{renderConversionLabel({ value: option.value })}
							</SmallTextSecondary>
						)}
					</>
				);
			case 'email':
				return (
					<Field
						as={BookingEmail}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={({ target: { value } }) => {
							setOptions(value);
						}}
					/>
				);
			case 'address':
				return (
					<Field
						as={BookingAddress}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={({ target: { value } }) => {
							setOptions(value);
						}}
						setSave={(value) => setOptionsSave(value)}
					/>
				);
			case 'textarea':
				return (
					<Field
						as={BookingTextArea}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={({ target: { value } }) => {
							setOptions(value);
							setOptions(value);
						}}
					/>
				);
			case 'select':
				return (
					<Field
						as={BookingSelect}
						name={`${index}.value`}
						key={index}
						option={option}
						service={service}
						setNewValue={(value) => {
							setOptions(value);
							handleCostUpdate(value);
						}}
					/>
				);
			case 'date':
				return (
					<Field
						as={CustomDatePicker}
						name={`${index}.value`}
						key={index}
						option={option}
						onChange={(value) => {
							setOptions(value);
						}}
					/>
				);
			case 'bankdetails':
				return (
					<Field
						as={BookingBankDetails}
						name={`${index}.value`}
						key={index}
						option={option}
						setNewValue={(value) => {
							setOptions(value);
						}}
					/>
				);
			case 'file':
				return (
					<Field
						as={BookingFileInput}
						name={`${index}.value`}
						key={index}
						option={option}
						id={userData.id}
						disabled={false}
						setNewValue={(value) => {
							setOptions(value);
						}}
					/>
				);
			case 'currencyamount':
				return (
					<Field
						as={BookingCurrencyAmount}
						name={`${index}.value`}
						key={index}
						option={option}
						setOptions={setOptions}
						setNewValue={(value) => {
							setOptions(value);
						}}
					/>
				);
			default:
				break;
		}
	};

	const renderError = ({ option, index, error }) => {
		const bankDetails =
			bankdetails.filter((item) => item.type === option.value.type).length > 0
				? bankdetails.filter((item) => item.type === option.value.type)[0]
				: null;

		switch (option.type) {
			case 'bankdetails':
				return (
					<>
						{error.value?.type && (
							<StyledErrorMessage key={`${index}type`}>
								{error.value?.type}
							</StyledErrorMessage>
						)}
						{error.value?.firstPart && (
							<StyledErrorMessage key={`${index + bankDetails.type}firstPart`}>
								{error.value?.firstPart}
							</StyledErrorMessage>
						)}
						{error.value?.secondPart && (
							<StyledErrorMessage key={`${index + bankDetails.type}secondPart`}>
								{error.value?.secondPart}
							</StyledErrorMessage>
						)}
					</>
				);
			case 'currencyamount':
				return (
					<>
						{error.value?.type && (
							<StyledErrorMessage key={`${index}type`}>
								{error.value?.type}
							</StyledErrorMessage>
						)}
						{error.value?.amount && (
							<StyledErrorMessage key={`${index}amount`}>
								{error.value?.amount}
							</StyledErrorMessage>
						)}
					</>
				);
			default:
				return (
					<StyledErrorMessage key={index}>
						{typeof error.value === 'string' && error.value}
					</StyledErrorMessage>
				);
		}
	};

	const validationOption = (option) => {
		switch (option.type) {
			case 'email':
				return option.compulsory
					? Yup.string().email('Invalid email address').required('Required')
					: Yup.string().email('Invalid email address');
			case 'phone':
				return option.compulsory
					? Yup.string()
							.matches(
								/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
								'Phone number is not valid'
							)
							.required('Required')
					: Yup.string().matches(
							/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
							'Phone number is not valid'
					  );
			case 'bankdetails':
				const bankDetails =
					bankdetails.filter((item) => item.type === option.value.type).length >
					0
						? bankdetails.filter((item) => item.type === option.value.type)[0]
						: null;

				return option.compulsory
					? Yup.object().shape({
							type: Yup.string().required('Type required'),
							firstPart: Yup.string().required(
								`${
									bankDetails ? bankDetails.firstPart : 'Bank field #1'
								} required`
							),
							secondPart: Yup.string().when('type', {
								is: (type) =>
									bankdetails.filter((item) => item.type === type).length > 0 &&
									bankdetails.filter((item) => item.type === type)[0]
										.secondPart,
								then: Yup.string().required(
									`${
										bankDetails ? bankDetails.secondPart : 'Bank field #2'
									} required`
								),
								otherwise: Yup.string(),
							}),
					  })
					: Yup.object().shape({
							type: Yup.string(),
							firstPart: Yup.string(),
							secondPart: Yup.string(),
					  });
			case 'number':
				return option.minValue
					? Yup.number()
							.min(option.minValue, `Must be more than ${option.minValue}`)
							.required('Required')
					: Yup.number().required('Required');
			case 'currencyamount':
				return option.compulsory
					? Yup.object().shape({
							type: Yup.string().required('Type required'),
							amount: Yup.number()
								.typeError('Amount must be a number')
								.required('Amount required')
								.min(0, 'Please enter amount more than zero')
								.nullable(),
					  })
					: Yup.object().shape({
							type: Yup.string(),
							amount: Yup.number(),
					  });
			default:
				return option.compulsory ? Yup.string().required('Required') : null;
		}
	};

	return (
		<Row noGutters>
			<Col xs={12}>
				{service && options && options[service.id]?.values?.length > 0 ? (
					<Formik
						enableReinitialize
						validationOnMount
						validateOnChange
						initialValues={options[service.id].values.reduce(
							(acc, cur, index) => ({ ...acc, [index]: cur }),
							{}
						)}
						validationSchema={Yup.object({
							...options[service.id].values.reduce((acc, cur, index) => {
								const test = validationOption(cur);

								return test
									? {
											...acc,
											[index]: Yup.object({
												value: test,
											}),
									  }
									: acc;
							}, {}),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							await handleOptionsChange({
								...options,
								[service.id]: {
									...options[service.id],
									values: Object.keys(values).map((item) => ({
										...values[item],
										value: values[item].value,
									})),
								},
							});
							setSubmitting(false);
							handleNextStep();
						}}
					>
						{({
							values,
							errors,
							isSubmitting,
							isValidating,
							setFieldValue,
							submitForm,
						}) => {
							return (
								<Form>
									<OrderContainer>
										<Row noGutters>
											<Col xs={12} className="px-0 py-0">
												<H1>
													{type && type
														? service.title
														: service && service.enquire
														? 'Your enquiry'
														: 'Your order'}
												</H1>
											</Col>
											<Col xs={12} className="px-0 py-4 mt-2">
												<Separator />
											</Col>
											<Col xs={12} className="px-0 py-0">
												{Object.keys(values).map((option, index) => (
													<Row key={index} noGutters>
														{renderOption({
															option: values[option],
															handleCostUpdate: async (value) =>
																await handleCostUpdate({
																	...values,
																	[index]: {
																		...values[option],
																		value: value,
																	},
																}),
															setOptions: (value) => {
																setFieldValue(`${index}.value`, value);
																handleOptionsChange({
																	...options,
																	[service.id]: {
																		...options[service.id],
																		values: Object.keys(values).map((item) =>
																			item * 1 === index
																				? {
																						...values[item],
																						value: value,
																				  }
																				: values[item]
																		),
																	},
																});
															},
															setOptionsSave: (value) => {
																setFieldValue(`${index}.saved`, value);
																handleOptionsChange({
																	...options,
																	[service.id]: {
																		...options[service.id],
																		values: Object.keys(values).map((item) =>
																			item * 1 === index
																				? {
																						...values[item],
																						saved: value,
																				  }
																				: values[item]
																		),
																	},
																});
															},
															index,
															values,
														})}
														{errors[index] &&
															renderError({
																option: values[option],
																index,
																error: errors[index],
															})}
													</Row>
												))}
											</Col>

											{!processing ? (
												<>
													{type && fee !== 0 && (
														<>
															{!type && (
																<Col xs={12} className="px-0 py-4 mt-2">
																	<Separator />
																</Col>
															)}
															<Col
																xs={12}
																className={
																	type !== ''
																		? 'px-0 py-0 mt-4 pt-2'
																		: 'px-0 py-0'
																}
															>
																<RowView
																	border={false}
																	className="align-items-center"
																	style={{
																		padding: 0,
																	}}
																>
																	<H5>
																		{type === 'card'
																			? 'Fee'
																			: type === 'concierge'
																			? 'Concierge Fee (only for completed orders)'
																			: type === 'reimbursement'
																			? 'Fee (only for completed reimbursements)'
																			: ''}
																	</H5>
																	<H3>{fee} points</H3>
																</RowView>
															</Col>
														</>
													)}
													<Col xs={12} className="px-0 py-0">
														<RowView
															border={false}
															className="align-items-center"
														>
															<H5>Total</H5>
															<H1>
																{service.enquire
																	? 'Upon enquiry'
																	: `${fee > 0 ? cost + fee : cost} points`}
															</H1>
														</RowView>
													</Col>
													{service.enquire && fee > 0 && proceedButtons?.topup && (
														<Col xs={12} className="px-0 py-0">
															<WarningDiv className="mt-4 mb-1">
																<Row noGutters>
																	<WarningText>
																		This order costs more than you have in your
																		wallet.
																	</WarningText>
																</Row>
															</WarningDiv>
														</Col>
													)}
													{!service.enquire && proceedButtons?.topup && (
														<Col xs={12} className="px-0 py-0">
															<WarningDiv className="mt-4 mb-1">
																<Row noGutters>
																	<WarningText>
																		This order costs more than you have in your
																		wallet.
																	</WarningText>
																</Row>
															</WarningDiv>
														</Col>
													)}
													{type ? (
														<Col xs={12} className="px-0 py-3">
															<PrimaryButton
																type="button"
																width="100%"
																height="64px"
																disabled={
																	teamMember?.status === 'suspended' ||
																	Object.keys(errors).length > 0 ||
																	(isSubmitting && !isValidating)
																		? true
																		: false
																}
																onClick={submitForm}
															>
																{isSubmitting
																	? 'Submitting...'
																	: Object.keys(errors).length > 0
																	? 'Check your selection'
																	: type === 'card'
																	? 'Proceed'
																	: type === 'concierge'
																	? 'Enquire'
																	: type === 'reimbursement'
																	? 'Request Reimbursement'
																	: service.enquire
																	? 'Enquire'
																	: 'Confirm Order'}
															</PrimaryButton>
														</Col>
													) : (
														<Col xs={12} className="px-0 py-3">
															<PrimaryButton
																type="button"
																width="100%"
																height="64px"
																disabled={
																	!!(
																		teamMember?.status === 'suspended' ||
																		Object.keys(errors).length > 0 ||
																		(isSubmitting && !isValidating) ||
																		proceedButtons?.topup
																	)
																}
																onClick={submitForm}
															>
																{isSubmitting
																	? 'Submitting...'
																	: Object.keys(errors).length > 0
																	? 'Check your selection'
																	: service.enquire
																	? 'Enquire'
																	: 'Confirm Order'}
															</PrimaryButton>
														</Col>
													)}
												</>
											) : (
												<Col xs={12} className="px-0 py-0">
													<RowView
														border={false}
														className="align-items-center justify-content-center"
													>
														<Spinner size="sm" animation="grow" />
													</RowView>
												</Col>
											)}
										</Row>
									</OrderContainer>
								</Form>
							);
						}}
					</Formik>
				) : (
					<Formik
						enableReinitialize
						validateOnChange
						initialValues={{}}
						validationSchema={Yup.object({})}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(false);
							handleNextStep();
						}}
					>
						{({ errors, isSubmitting, isValidating, submitForm }) => {
							return (
								<Form>
									<OrderContainer>
										<Row noGutters>
											<Col xs={12} className="px-0 py-0">
												<H1>
													{type && type
														? service.title
														: service && service.enquire
														? 'Your enquiry'
														: 'Your order'}
												</H1>
											</Col>
											<Col xs={12} className="px-0 py-4 mt-2">
												<Separator />
											</Col>
											{!processing ? (
												<>
													<Col xs={12} className="px-0 py-0">
														<RowView
															border={false}
															className="align-items-center"
														>
															<H5>Total</H5>
															<H1>
																{service.enquire
																	? 'Upon enquiry'
																	: `${fee > 0 ? cost + fee : cost} points`}
															</H1>
														</RowView>
													</Col>
													{!service.enquire && proceedButtons?.topup && (
														<Col xs={12} className="px-0 py-0">
															<WarningDiv className="mt-4 mb-1">
																<Row noGutters>
																	<WarningText>
																		This order costs more than you have in your
																		wallet.
																	</WarningText>
																</Row>
															</WarningDiv>
														</Col>
													)}
													<Col xs={12} className="px-0 py-3">
														<PrimaryButton
															type="button"
															onClick={submitForm}
															width="100%"
															height="64px"
															disabled={
																teamMember?.status === 'suspended' ||
																Object.keys(errors).length > 0 ||
																(isSubmitting && !isValidating)
																	? true
																	: false
															}
														>
															{isSubmitting
																? 'Submitting...'
																: Object.keys(errors).length > 0
																? 'Check your selection'
																: service.enquire
																? 'Enquire'
																: 'Confirm Order'}
														</PrimaryButton>
													</Col>
												</>
											) : (
												<Col xs={12} className="px-0 py-0">
													<RowView
														border={false}
														className="align-items-center justify-content-center"
													>
														<Spinner size="sm" animation="grow" />
													</RowView>
												</Col>
											)}
										</Row>
									</OrderContainer>
								</Form>
							);
						}}
					</Formik>
				)}
			</Col>
		</Row>
	);
};

export default OrderOptionsPageAuthenticated;
