import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Box } from '../../utilities/Box';
import IntegrationsInfo from './IntegrationsInfo';
import IntegrationsListItem from './IntegrationsListItem';
import PreferencesModal from './PreferencesModal';
import RemoveIntegrationsModal from './RemoveIntegrationsModal';
import { useClient } from '../../../hooks';
import config from '../../../config';
import { SmallTextSecondaryMedium } from '../../../styles/StylesV3Main';
import SuccessfulIntegrationModal from './SucessfulIntegrationModal';

const SetupIntegrationButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-family: 'Roobert Semi Bold';
	font-size: 14px;
	line-height: 160%;
	border: none;
	height: 32px;
	width: 170px;
	padding: 4px 12px;
	border-radius: 3px;
	background: rgba(25, 26, 28, 0.05);
	&:hover {
		background: rgba(25, 26, 28, 0.1);
	}
`;

const StyledContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	border-radius: 8px;
	width: 100%;
	margin: 24px 0px 0px 0px;
	padding: 40px;
	@media (max-width: 1200px) {
		flex-wrap: wrap;
		margin: 24px 0px 0px 0px;
	}
	@media (max-width: 992px) {
		margin: 15px 0px 0px 0px;
	}
`;

const LeftView = styled.div`
	display: flex;
	flex-direction: column;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 3px 0px 0px 3px;
	width: 50%;
	padding-right: 40px;
	@media (max-width: 992px) {
		padding: 0px;
		width: 100%;
		margin: auto;
		border: 0px;
	}
	@media (max-width: 350px) {
		width: 100%;
	}
`;
const RightView = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	padding-left: 40px;
	margin: auto;

	@media (max-width: 992px) {
		width: 100%;
		padding: 40px 0 0 0;
		margin: auto;
		border: 0px;
	}
`;

const IntegrationsText = styled(Row)`
	text-align: left;
	padding-top: 16px;
`;

const StyledListGroup = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;

	li {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px;
		margin-top: -1px; /* Prevent double borders */
		border: 1px solid rgba(0, 0, 0, 0.05);
		cursor: pointer;

		transition: 0.2s;

		svg {
			opacity: 0.5;
			font-size: 1.2em;
		}

		:hover {
			padding-right: 12px !important;
			background: rgba(0, 0, 0, 0.03);

			svg {
				opacity: 1;
			}
		}

		:first-child {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
		:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
`;

const IntegrationsCard = ({
	firstTimeMerge,
	handleFirstTimeMerge,
	handleSetup,
	integrationName,
	team,
	handleUpdate,
	slotAvailable,
}: any) => {
	const [showPreferencesModal, setShowPreferencesModal] = useState(false);
	const [syncingHris, setSyncingHris] = useState(false);
	const [showRemoveIntegrationModal, setShowRemoveIntegrationModal] =
		useState(false);

	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const client = useClient();

	const handleShowPreferencesModal = (boolean) => {
		setShowPreferencesModal(boolean);
	};

	const handleShowRemoveIntegrationModal = (boolean) => {
		setShowRemoveIntegrationModal(boolean);
	};

	const handleShowSuccessModal = (boolean) => {
		setShowSuccessModal(boolean);
	};

	const synchroniseJunoWithHRIS = async () => {
		try {
			setSyncingHris(true);

			await client.get(
				`${config.teams.HOST}/teams/synchronize-hris/${team.id}`
			);
			setShowSuccessModal(true);
			setSyncingHris(false);
			handleUpdate();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	return !integrationName ? (
		<Box
			title="Integrate with your HRIS platform"
			description={
				!team.fees && slotAvailable
					? 'Integrate Juno with your HRIS platform to automatically add and remove employees to your team.'
					: 'Contact support to integrate Juno with your HRIS platform to automatically add and remove employees to your team.'
			}
		>
			<SetupIntegrationButton
				disabled={team.fees || !slotAvailable}
				onClick={() => handleSetup()}
			>
				Set up your integration
			</SetupIntegrationButton>
		</Box>
	) : (
		<StyledContainer>
			<LeftView>
				<IntegrationsInfo
					integrationName={integrationName}
					syncing={syncingHris}
					lastSyncDate={team.lastMergeSyncTimestamp}
				/>
				<IntegrationsText className="m-auto p-auto" noGutters>
					<SmallTextSecondaryMedium>
						Every time an employee becomes active on {integrationName} they will
						be invited to Juno automatically. When they leave the company, they
						will be removed from the team as well.
					</SmallTextSecondaryMedium>
				</IntegrationsText>
			</LeftView>
			<RightView>
				<div className="align-items-center">
					<StyledListGroup>
						<IntegrationsListItem
							title="Sync Now"
							handler={() => synchroniseJunoWithHRIS()}
							syncing={syncingHris}
						/>
						<IntegrationsListItem
							title="Update Preferences"
							handler={() => handleShowPreferencesModal(true)}
						/>
						<IntegrationsListItem
							color="#CC1440"
							title="Delete Integration"
							handler={() => handleShowRemoveIntegrationModal(true)}
						/>
					</StyledListGroup>
				</div>
			</RightView>

			<PreferencesModal
				showModal={showPreferencesModal || firstTimeMerge}
				onHide={() => {
					handleFirstTimeMerge();
					handleShowPreferencesModal(false);
				}}
				onSuccess={() => {
					handleFirstTimeMerge();
					handleShowPreferencesModal(false);
					if (!team.lastMergeSyncTimestamp) synchroniseJunoWithHRIS();
					else handleUpdate();
				}}
				team={team}
				lastSyncDate={team.lastMergeSyncTimestamp}
				handleInitialSync={() => synchroniseJunoWithHRIS()}
			/>

			<RemoveIntegrationsModal
				showModal={showRemoveIntegrationModal}
				onHide={() => {
					handleShowRemoveIntegrationModal(false);
				}}
				onSuccess={() => {
					handleShowRemoveIntegrationModal(false);
					handleUpdate(true);
				}}
				integrationName={integrationName}
				teamId={team.id}
			/>
			<SuccessfulIntegrationModal
				showModal={showSuccessModal}
				onHide={() => {
					handleShowSuccessModal(false);
				}}
			/>
		</StyledContainer>
	);
};

export default IntegrationsCard;
