import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div<{ src: string }>`
	padding: 0;
	border: none;

	height: 500px;
	width: 370px;
	background: #ffffff;
	box-shadow: 0px 0px 22.4px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-grow: 1;
	margin-bottom: 75px;

	@media (max-width: 500px) {
		margin-bottom: 50px;
	}

	> div:first-of-type {
		width: 100%;
		height: 300px;

		background: url(${(props) => props.src}) top no-repeat, #bbbec2;

		background-size: cover;
	}
`;

const InspirationTitle = styled.h3`
	font-family: 'TTInterfaces DemiBold';
	font-size: 24px;
	line-height: 34px;
	color: #0a0f16;
	margin: 25px 30px 0px 25px;
`;

const ActionButton = styled.div`
	height: 46px;
	background: #0a0f16;
	border-radius: 45px;
	margin: 25px 30px 0px 25px;
	padding: 0 27px;
	border: 0;
	display: inline-flex;
	align-items: center;
`;

const ActionText = styled.span`
	font-family: 'TTInterfaces DemiBold';
	font-size: 18px;
	line-height: 22px;
	color: #ffffff;
	padding-right: 11px;
`;

type InspirationProps = {
	title: string;
	buttonText: string;
	url: string;
	imgUrl: string;
};

const Inspiration = ({ title, buttonText, url, imgUrl }: InspirationProps) => {
	return (
		<StyledContainer src={imgUrl}>
			<div />
			<InspirationTitle>{title}</InspirationTitle>

			<Link to={url}>
				<ActionButton>
					<ActionText>{buttonText}</ActionText>
					<svg
						width="19"
						height="14"
						viewBox="0 0 19 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.1654 13.592C11.9805 13.592 11.7956 13.5239 11.6496 13.3779C11.3674 13.0957 11.3674 12.6286 11.6496 12.3464L17.0406 6.95543L11.6496 1.56443C11.3674 1.28223 11.3674 0.81514 11.6496 0.532939C11.9318 0.250739 12.3989 0.250739 12.6811 0.532939L18.5879 6.43969C18.8701 6.72189 18.8701 7.18898 18.5879 7.47118L12.6811 13.3779C12.5352 13.5239 12.3503 13.592 12.1654 13.592Z"
							fill="white"
						/>
						<path
							d="M17.907 7.68622H1.52963C1.13066 7.68622 0.799805 7.35536 0.799805 6.95639C0.799805 6.55742 1.13066 6.22656 1.52963 6.22656H17.907C18.306 6.22656 18.6368 6.55742 18.6368 6.95639C18.6368 7.35536 18.306 7.68622 17.907 7.68622Z"
							fill="white"
						/>
					</svg>
				</ActionButton>
			</Link>
		</StyledContainer>
	);
};

export default Inspiration;
