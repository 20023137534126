import React from 'react';
import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';

const StyledCheckboxButton = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	${({ valid }) =>
		valid
			? `
        background: #36d579;
      `
			: `
        background: #fff;
        border:1px solid grey;
      `}
`;

const StyledContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	margin: 3px;
`;

const StyledCheckboxContainer = styled.div`
	height: 12%;
	margin: 3px;
`;

const StyledTitle = styled.div`
	height: 12%;
	margin: 3px;
`;

const PasswordPopover = ({ data }) => {
	return (
		<>
			<StyledContainer>
				<StyledCheckboxContainer>
					<StyledCheckboxButton valid={data.valid}>
						{data.valid && (
							<FiCheck
								color={'white'}
								size={10}
								style={{ postion: 'relative' }}
							/>
						)}
					</StyledCheckboxButton>
				</StyledCheckboxContainer>
				<StyledTitle>{data.title}</StyledTitle>
			</StyledContainer>
		</>
	);
};

export default PasswordPopover;
