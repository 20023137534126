import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { JunoProviderLogo } from '../images/images';

type ProductType = 'Card' | 'Delivery' | 'Physical' | 'Online' | 'Event';

export type ProductCardProps = {
	productId: string;
	imgUrl: string;
	productTitle: string;
	productDescription: string;
	provider: string;
	providerImgUrl: string;
	pointsValue: number;
	reason: string;
	reasonable: boolean;
	productType: ProductType;
	isFromPrice: boolean;
	location: string;
	enquire: boolean;
};

const ProductCard = ({
	productId,
	imgUrl,
	productTitle,
	provider,
	providerImgUrl,
	productDescription,
	pointsValue,
	reason,
	reasonable,
	productType,
	isFromPrice,
	location,
	enquire,
}: ProductCardProps) => {
	const iconUrl = productType === 'Event' ? JunoProviderLogo : providerImgUrl;
	return (
		<Container>
			<TileContainer
				to={
					productType === 'Event'
						? `/events/${productId.replace('t_', '')}` // Remove t_ prefix from events as added to embeddings db
						: `/product/${productId}`
				}
				style={{
					textDecoration: 'none',
				}}
			>
				<CardImage
					style={{
						backgroundImage: imgUrl ? `url(${imgUrl}?width=500)` : undefined,
					}}
				>
					<IndicatorType
						pointsValue={pointsValue}
						productType={productType}
						fromPrice={isFromPrice}
						enquire={enquire}
					/>
				</CardImage>

				<CardBody>
					<ProductTitle>{productTitle}</ProductTitle>
					<ProviderContainer>
						<ProviderImage
							style={{
								backgroundImage: iconUrl
									? `url(${iconUrl}?width=75px&height=75px)`
									: undefined,
							}}
						/>
						<ProviderName>
							{productType === 'Event' ? 'Juno Events' : provider}
						</ProviderName>
					</ProviderContainer>
					<ProductDescription>
						{productType === 'Event' ? location : productDescription}
					</ProductDescription>
				</CardBody>
			</TileContainer>
			{reasonable && (
				<ReasonContainer>
					<ReasonIcon
						width="16"
						height="14"
						viewBox="0 0 16 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0 5.15358C0 6.22575 0.164949 7.87524 1.64949 9.35979C2.96909 10.6794 7.34024 13.6485 7.50519 13.8134C7.67014 13.8959 7.83509 13.9784 8.00004 13.9784C8.16499 13.9784 8.32994 13.8959 8.49489 13.8134C8.65984 13.6485 13.031 10.7619 14.3506 9.35979C15.8351 7.87524 16.0001 6.22575 16.0001 5.15358C16.0001 2.67934 14.0207 0.699951 11.5465 0.699951C10.2269 0.699951 8.90726 1.44222 8.08251 2.59687C7.25777 1.44222 5.93817 0.699951 4.45363 0.699951C2.06187 0.699951 0 2.67934 0 5.15358Z"
							fill="#E3E3E3"
						/>
					</ReasonIcon>

					<ReasonPlaceholder>
						{reason ? (
							<ReasonText>{reason}</ReasonText>
						) : (
							<>
								<div />
								<div />
							</>
						)}
					</ReasonPlaceholder>
				</ReasonContainer>
			)}
		</Container>
	);
};

const IndicatorType: React.FC<{
	pointsValue: number;
	productType: ProductType;
	fromPrice: boolean;
	enquire: boolean;
}> = ({ pointsValue, productType, fromPrice, enquire }) => {
	if (enquire) {
		return (
			<PointsContainer pointsType="enquiry">
				<p>Upon Enquiry</p>
			</PointsContainer>
		);
	}
	if (productType === 'Card') {
		return (
			<PointsContainer pointsType="card">
				<svg
					width="27"
					height="27"
					viewBox="0 0 27 27"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M24.7346 10.8314H2.92621C2.47914 10.8314 2.1084 10.4607 2.1084 10.0136C2.1084 9.56654 2.47914 9.1958 2.92621 9.1958H24.7346C25.1817 9.1958 25.5524 9.56654 25.5524 10.0136C25.5524 10.4607 25.1817 10.8314 24.7346 10.8314Z"
						fill="#292D32"
					/>
					<path
						d="M9.46838 19.5548H7.28754C6.84047 19.5548 6.46973 19.1841 6.46973 18.737C6.46973 18.2899 6.84047 17.9192 7.28754 17.9192H9.46838C9.91545 17.9192 10.2862 18.2899 10.2862 18.737C10.2862 19.1841 9.91545 19.5548 9.46838 19.5548Z"
						fill="#292D32"
					/>
					<path
						d="M16.5564 19.5548H12.1948C11.7477 19.5548 11.377 19.1841 11.377 18.737C11.377 18.2899 11.7477 17.9192 12.1948 17.9192H16.5564C17.0035 17.9192 17.3743 18.2899 17.3743 18.737C17.3743 19.1841 17.0035 19.5548 16.5564 19.5548Z"
						fill="#292D32"
					/>
					<path
						d="M19.8931 23.9164H7.76767C3.4278 23.9164 2.1084 22.6079 2.1084 18.3116V9.3484C2.1084 5.05215 3.4278 3.74365 7.76767 3.74365H19.8822C24.2221 3.74365 25.5415 5.05215 25.5415 9.3484V18.3007C25.5524 22.6079 24.233 23.9164 19.8931 23.9164ZM7.76767 5.37928C4.34376 5.37928 3.74403 5.96811 3.74403 9.3484V18.3007C3.74403 21.681 4.34376 22.2699 7.76767 22.2699H19.8822C23.3061 22.2699 23.9059 21.681 23.9059 18.3007V9.3484C23.9059 5.96811 23.3061 5.37928 19.8822 5.37928H7.76767Z"
						fill="#292D32"
					/>
				</svg>
			</PointsContainer>
		);
	}
	return (
		<PointsContainer pointsType="normal">
			<p>
				{pointsValue}
				{(fromPrice || productType === 'Event') && '+'}
			</p>
			<p>PTS</p>
		</PointsContainer>
	);
};

type PointsContainerProps = {
	pointsType: 'normal' | 'enquiry' | 'card';
};

const PointsContainer = styled.div<PointsContainerProps>`
	position: absolute;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	right: 12px;
	top: 12px;
	min-width: 46px;
	padding: 0 5px;
	height: 46px;

	background: #ffffff;
	box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.06);
	border-radius: 9.5px;
	color: #000000;
	z-index: 20;
	${({ pointsType }) =>
		pointsType === 'normal' &&
		css`
			> p {
				margin: 0px;
				text-align: center;
			}
			> p:first-child {
				font-family: 'TTInterfaces Bold';
				font-size: 14px;
				line-height: 17px;
			}
			> p:nth-child(2) {
				font-family: 'TTInterfaces Bold';
				font-size: 11px;
				line-height: 100%;
				opacity: 0.4;
			}
		`};

	${({ pointsType }) =>
		pointsType === 'enquiry' &&
		css`
			> p {
				margin: 0px;
				text-align: center;
				font-family: 'TTInterfaces DemiBold';
				font-size: 11px;
				line-height: 12px;
				margin-bottom: 2px;
				max-width: 40px;
			}
		`};
	${({ pointsType }) =>
		pointsType === 'card' &&
		css`
			color: #292d32;
		`}
`;

const Container = styled.div`
	width: 260px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: 90px;

	@media (max-width: 500px) {
		margin-bottom: 50px;
	}
`;
const TileContainer = styled(Link)`
	height: 325px;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	cursor: pointer;
	overflow: hidden;

	text-decoration: none !important;
`;

const CardImage = styled.div`
	background: #ebebeb;
	width: 100%;
	height: 162px;
	min-height: 162px;
	object-fit: cover;
	object-position: center;
	position: relative;
	background-position: center;
	background-size: cover;
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px 22px 0px 22px;
	height: 100%;
	background-color: #fff;
`;

const ProductTitle = styled.h2`
	font-family: 'TTInterfaces Demibold';
	font-size: 21px;
	line-height: 28px;
	color: #0a0f16;

	display: -webkit-box;
	display: -moz-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 1;
	-moz-line-clamp: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 10px;
`;

const ProductDescription = styled.p`
	font-family: 'TTInterfaces Medium';
	font-size: 17px;
	line-height: 27px;
	color: #0a0f16;
	opacity: 0.4;

	display: -webkit-box;
	display: -moz-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ProviderImage = styled.div`
	display: inline-block;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	margin: 0px 10px 0px 0px;
	background-position: center;
	background-size: 100%;
	background-color: #ffffff;
`;

const ProviderName = styled.h6`
	font-family: 'TTInterfaces Regular';
	font-size: 17px;
	line-height: 21px;
	color: rgba(10, 15, 22, 0.7);
	margin-bottom: 3px;

	display: -webkit-box;
	display: -moz-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 1;
	-moz-line-clamp: 1;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ProviderContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
`;

const ReasonContainer = styled.div`
	background: transparent;
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	height: 54px;
`;

const ReasonIcon = styled.svg`
	border-radius: 50%;
	margin-top: 4px;
`;

const ReasonPlaceholder = styled.div`
	width: 100%;

	> div {
		background: #ebebeb;
		opacity: 0.5;
		border-radius: 50px;
		height: 12px;
		margin: 0px 0px 5px 5px;

		:first-child {
			width: 210px;
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;
			animation: loading 2.5s ease-in-out infinite;
		}

		:nth-child(2) {
			width: 185px;
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ebebeb 50%,
				#ffffff 100%
			);
			background-size: 400% 400%;
			animation: loading 2.5s ease-in-out 0.75s infinite;
		}
	}
	@keyframes loading {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
`;

const ReasonText = styled.p`
	font-family: 'TTInterfaces Regular';
	font-size: 14px;
	line-height: 18px;
	color: #b1b1b1;
	margin-left: 10px;

	display: -webkit-box;
	display: -moz-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
	animation: fadein 2s;
	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export default ProductCard;
