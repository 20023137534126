import Disney from './disney.png';
import NHMuseum from './nhmuseum.png';
import EmpireState from './empirestate.png';
import Everything from './everything.png';
import England from './england.png';
import Experiences from './experiences.png';
import Expense from './expense.png';
import Card from './card.png';
import JunoProvider from './junoProvider.png';
import OpenAILogo from './openailogo.png';
import Apple from './apple.svg';
import Clementine from './clementine.svg';
import OddBox from './oddbox.svg';
import Dandy from './Dandy.svg';
import Peloton from './Peloton.svg';
import Healf from './Healf.svg';
import Every from './Every.svg';
import Mamamade from './Mamamade.svg';
import Peanut from './Peanut.svg';
import HumanPeople from './HumanPeople.svg';
import Cowshed from './Cowshed.svg';
import Neals from './Neals.svg';
import Otto from './Otto.svg';
import BestIntentions from './BestIntentions.svg';
import AirBnB from './AirBnB.svg';
import Uber from './Uber.svg';
import Busuu from './Busuu.svg';
import FieldDoctor from './FieldDoctor.svg';
import Feel from './Feel.svg';
import FighterShots from './FighterShots.svg';
import CodeAcademy from './CodeAcademy.svg';
import Masterclass from './Masterclass.svg';
import Wildgoose from './Wildgoose.svg';
import Midstay from './Midstay.svg';
import Vespucci from './Vespucci.svg';
import Bloom from './Bloom.svg';
import Soho from './Soho.svg';
import Vinny from './Vinny.svg';
import Headspace from './Headspace.svg';
import EdCanHelp from './EdCanHelp.svg';
import Stipe from './Stipe.png';
import Whoop from './whoop.png';
import InspirationAne from './inspirationAne.jpeg';
import InspirationJames from './inspirationJames.jpeg';
import InspirationLiv from './inspirationLiv.jpeg';

const BetterHelp =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/3caf4777b87cfebd3a21b8eaf5b816980cfffdd4';

const Ally =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/b07d946a8c8a787ac47c6c1ea3de8d751318d8d0';

const Explr =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/3b167848ac02e09a86239cc9395fd02b24944325';

const Urban =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/28cc2dfb37d58a9022fc65421f0bd7b22d069c74';

const Aesop =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/b3244d76cf1cff994beb0cacb2785408b533966a';

const Experts =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/412fb5bc95834d2a272874b122dff6325bdee1fc';

const LastMinute =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/065ba030edec341b8f61b5da4d8bfcabfecd05ac';

const Yield =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/51e2c81d569b731713ce456976258e11895b62c4';

const Sculpd =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/d0eca6ea5152c521555fe0deacf7fd01b1abccc4';

const Flown =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/90930dcbbb6e3deaeba716703a549189687d4484';

const Furthr =
	'https://d2a92oex8mzmkk.cloudfront.net/providers/3f239ab69743aa5b4ae26c49e08f001e70ed03eb';

export {
	Disney,
	NHMuseum,
	EmpireState,
	Everything,
	England,
	Experiences,
	Expense,
	Card,
	JunoProvider,
	OpenAILogo,
	HumanPeople,
	Apple,
	Clementine,
	OddBox,
	Dandy,
	Peloton,
	Healf,
	Whoop,
	BetterHelp,
	Ally,
	Every,
	Mamamade,
	Peanut,
	Explr,
	Urban,
	Aesop,
	Neals,
	Cowshed,
	Experts,
	Otto,
	BestIntentions,
	Stipe,
	AirBnB,
	Uber,
	Busuu,
	LastMinute,
	Yield,
	FieldDoctor,
	Feel,
	FighterShots,
	Masterclass,
	CodeAcademy,
	Sculpd,
	Flown,
	Wildgoose,
	Midstay,
	Vespucci,
	Furthr,
	Bloom,
	Soho,
	Vinny,
	Headspace,
	EdCanHelp,
	InspirationAne,
	InspirationJames,
	InspirationLiv,
};
