import React from 'react';
import styled from 'styled-components';

type ModalButtonProps = {
	version?: string;
	width?: string;
	height?: string;
	disabled?: boolean;
};

const handleVersion = (props: ModalButtonProps) => {
	if (props.version === 'danger') {
		return `background: rgba(255, 0, 0, 0.05);
        border: 1.5px solid ${
					props.disabled ? 'rgba(255, 0, 0, 0.3)' : '#FF0000'
				};
        color: ${props.disabled ? 'rgba(255, 0, 0, 0.3)' : '#FF0000'};`;
	} else if (props.version === 'light') {
		return `   background: white;
        border: 1px solid ${props.disabled ? 'rgba(0,0,0,0.3)' : '#000'};
        color: ${props.disabled ? 'rgba(0,0,0,0.3)' : '#000'};`;
	} else if (props.version === 'grey') {
		return `   background: rgba(25, 26, 28, 0.05);
        border: none;
        color: ${props.disabled ? 'rgba(0,0,0,0.3)' : '#000'};`;
	} else if (props.version === 'red') {
		return `   background: #CC1440;
        border: none;
        color: ${props.disabled ? 'rgba(255, 255, 255, 0.3)' : '#fff'};`;
	} else {
		return `   background: ${props.disabled ? 'rgba(0,0,0,0.05)' : '#000'};
            border: 0px solid ${props.disabled ? 'rgba(0,0,0,0.3)' : '#000'};
            color: ${props.disabled ? 'rgba(0,0,0,0.3)' : '#FFF'};`;
	}
};

const ModalButton = styled.button<ModalButtonProps>`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	border: none;
	border-radius: 3px;
	width: ${({ width }) => width || '188px'};
	height: ${({ height }) => height || '50px'};
	box-sizing: border-box;
	${(props) => handleVersion(props)}
	@media (max-width: 500px) {
		width: ${({ width }) => width || '150px'};
	}
`;

export default ModalButton;
