import React, { useState } from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';

const StyledInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: 1.4px;
	color: #000000;
	text-transform: uppercase;
	text-align: center;
	opacity: 1;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.mainColors.color.separator};
	min-height: 43px;
	width: 50%;
	margin-bottom: 10px;
	outline: none;
`;

const Suggestions = styled.div`
	border-radius: 10px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	overflow: hidden;
`;

const LocationSearch = ({
	updatedAddress,
	searchOptions,
	full,
	placeholder,
}: any) => {
	const [address, setAddress] = useState('');

	const handleChange = (address) => {
		setAddress(address);
	};

	const handleSelect = async (address, placeId) => {
		if (!placeId) return;
		const addressGeo = await geocodeByAddress(address);
		const placeGeo = await geocodeByPlaceId(placeId);
		const latLng = await getLatLng(addressGeo[0]);
		setAddress(addressGeo[0].formatted_address);
		updatedAddress({
			address: addressGeo[0],
			latLng,
			geometry: {
				east: placeGeo[0].geometry.viewport.getNorthEast().lng(),
				north: placeGeo[0].geometry.viewport.getNorthEast().lat(),
				south: placeGeo[0].geometry.viewport.getSouthWest().lat(),
				west: placeGeo[0].geometry.viewport.getSouthWest().lng(),
			},
		});
	};

	const fullStyle = {
		position: 'absolute',
		backgroundColor: '#f6f6f6',
		zIndex: '5000',
		overflowX: 'visible',
		width: '100%!important',
		border: '1px solid black',
	};

	return (
		<PlacesAutocomplete
			value={address}
			searchOptions={searchOptions}
			onChange={handleChange}
			onSelect={handleSelect}
			onError={(status, clearSuggestions) => {
				if (status !== 'ZERO_RESULTS') {
					console.error(
						'[react-places-autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
						status
					);
				} else {
					clearSuggestions();
				}
			}}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div className={`mx-auto`}>
					<StyledInput
						{...getInputProps({
							placeholder: placeholder ? placeholder : 'Your address',
							className: 'location-search-input',
						})}
						full={full ? true : false}
					/>
					<div
						style={full && suggestions.length > 0 ? { ...fullStyle } : {}}
						className={`autocomplete-dropdown-container`}
					>
						{loading && <div>Loading...</div>}
						{suggestions.length > 0 && (
							<Suggestions>
								{suggestions.map((suggestion) => {
									const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
										? {
												backgroundColor: '#B2D4FF',
												cursor: 'pointer',
												font: 'normal normal normal 12px/17px "TTInterfaces Medium"',
												textTransform: 'uppercase',
												minHeight: '43px',
												display: 'inline-flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: '100%',
										  }
										: {
												backgroundColor: '#fff',
												cursor: 'pointer',
												font: 'normal normal normal 12px/17px "TTInterfaces Medium"',
												textTransform: 'uppercase',
												minHeight: '43px',
												display: 'inline-flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: '100%',
										  };
									return (
										<div
											key={suggestion.description}
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</Suggestions>
						)}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
};

export default LocationSearch;
