import React from 'react';
import client from '../../api/client';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import config from '../../config';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const S3FilesUploader = ({ type, id, noUpload, ...props }) => {
	const { getTokenSilently } = useAuth0();

	const getSignedUrl = async ({ fileName, fileType }) => {
		try {
			const token = await getTokenSilently();
			client.defaults.headers.Authorization = 'Bearer ' + token;
			let response;

			switch (type) {
				case 'user-profile':
					response = await client.post(
						`${config.api.HOST}/s3/signedURL/userPic`,
						{
							fileName,
							fileType,
						}
					);
					break;
				case 'team-profile-initial':
					response = await client.post(
						`${config.api.HOST}/s3/signedURL/teamPic`,
						{
							fileName,
							fileType,
						}
					);
					break;
				case 'team-profile':
					response = await client.post(
						`${config.api.HOST}/s3/signedURL/teamPic/${id}`,
						{
							fileName,
							fileType,
						}
					);
					break;
				default:
					response = await client.post(`${config.api.HOST}/s3/signedURL`, {
						fileName,
						fileType,
						type,
						id,
					});
					break;
			}
			if (response.data) {
				return response.data;
			} else {
				return null;
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	// specify upload params and url for your files
	const getUploadParams = async ({ file, meta }) => {
		const result = await getSignedUrl({
			fileName: meta.name,
			fileType: meta.type,
		});
		return {
			method: 'put',
			body: file,
			headers: { 'x-amz-acl': 'public-read' },
			meta: { fileUrl: result.directUrl, publicUrl: result?.publicUrl },
			url: result.signedUrl,
		};
	};

	return <Dropzone getUploadParams={!noUpload && getUploadParams} {...props} />;
};

export default S3FilesUploader;
