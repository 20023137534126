import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import PhoneInput, {
	Value,
	isValidPhoneNumber,
} from 'react-phone-number-input';
import Button from '../../styles/shared/Button/Button';
import countries from '../../data/countries.json';
import { ReactComponent as Back } from './images/back.svg';
import Policy from './Policy';
import arrow from './images/arrow.svg';

const SetupCard = ({ policy, save }: any) => {
	const [fullName, setFullName] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [townOrCity, setTownOrCity] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [country, setCountry] = useState('');
	const [phoneNumber, setPhoneNumber] = useState<Value | undefined>();
	const [saving, setSaving] = useState(false);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const phoneRef = useRef<HTMLInputElement>(null);
	return (
		<Request>
			<Policy policy={policy} />
			<div className="content">
				<div>
					<button
						type="button"
						onClick={() => {
							const newParams = new URLSearchParams(search);
							newParams.delete('setup');
							history.push(`${pathname}?${newParams}`, {
								scroll: false,
							});
						}}
					>
						<Back />
					</button>
				</div>
				<div>
					<h2>Set up card</h2>
					<p>Confirm billing address.</p>
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							if (e.currentTarget.checkValidity()) {
								setSaving(true);
								await save({
									fullName,
									addressLine1,
									addressLine2,
									townOrCity,
									postalCode,
									country:
										country !== 'GB_' && country !== 'US_'
											? country
											: country.substring(0, 2),
									phoneNumber: phoneNumber! as string,
								});
							}
						}}
					>
						<div>
							<input
								id="fullName"
								placeholder="Full name*"
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
								required
								pattern=".*[^\s]+[\s]+[^\s]+.*"
								value={fullName}
								onChange={({ target }) => {
									setFullName(target.value);
									target.setCustomValidity(
										target.validity.patternMismatch
											? 'Must include first and last name.'
											: ''
									);
								}}
							/>
							<label htmlFor="fullName">Full name*</label>
						</div>
						<div>
							<input
								id="addressLine1"
								placeholder="Address line 1*"
								required
								value={addressLine1}
								onChange={({ target }) => setAddressLine1(target.value)}
							/>
							<label htmlFor="addressLine1">Address line 1*</label>
						</div>
						<div>
							<input
								id="addressLine2"
								placeholder="Address line 2"
								value={addressLine2}
								onChange={({ target }) => setAddressLine2(target.value)}
							/>
							<label htmlFor="addressLine2">Address line 2</label>
						</div>
						<div>
							<input
								id="townOrCity"
								placeholder="Town or City*"
								required
								value={townOrCity}
								onChange={({ target }) => setTownOrCity(target.value)}
							/>
							<label htmlFor="townOrCity">Town or city*</label>
						</div>
						<div>
							<input
								id="postalCode"
								placeholder="Postal Code*"
								required
								value={postalCode}
								onChange={({ target }) => setPostalCode(target.value)}
							/>
							<label htmlFor="postalCode">Postal Code*</label>
						</div>
						<div>
							<select
								id="country"
								required
								data-value={country}
								value={country}
								onChange={({ target }) => setCountry(target.value)}
							>
								{[
									{ value: '', label: 'Country*' },
									{ value: 'GB_', label: 'United Kingdom' },
									{ value: 'US_', label: 'United States' },
									...countries.slice(2),
								].map(({ value, label }) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</select>
							<label htmlFor="country">Country*</label>
						</div>
						<div>
							<PhoneInput
								required
								ref={phoneRef as any}
								defaultCountry="GB"
								id="phoneNumber"
								placeholder="Phone number*"
								value={phoneNumber}
								onChange={(value: any) => {
									setPhoneNumber(value);
									phoneRef.current!.setCustomValidity(
										!phoneRef.current!.value ||
											(value && isValidPhoneNumber(value as string))
											? ''
											: 'Please enter a valid phone number'
									);
								}}
							/>
							<label
								id="phoneLabel"
								htmlFor="phoneNumber"
								data-value={phoneNumber || ''}
							>
								Phone number*
							</label>
						</div>
						<div>
							<Button state={!saving ? 'default' : 'loading'}>
								Set up card
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Request>
	);
};

const Request = styled.div`
	> div.content {
		margin-top: 40px;
		display: flex;
		border-radius: 26px;
		background-color: #fff;
		padding: 47px 12% 40px 30px;
		gap: 60px;
		> div:first-child {
			flex-basis: 40px;
			button {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				background-color: #525252;
			}
		}
		> div:last-child {
			flex: 1;
		}
		@media (max-width: 1200px) {
			flex-direction: column;
			padding: 24px;
			gap: 20px;
		}
	}
	h2 {
		font-size: 24px;
		font-family: 'TTInterfaces DemiBold';
		line-height: 21px;
		margin-bottom: 8px;
		+ p {
			color: black;
			font-size: 18px;
			font-family: 'TTInterfaces Regular';
			line-height: 28px;
			margin-bottom: 26px;
			@media (max-width: 992px) {
				margin-bottom: 20px;
			}
		}
	}
	form {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 50px;
		row-gap: 30px;
		> div {
			position: relative;
		}
		> div:last-child {
			grid-row-start: 5;
			grid-row-end: 6;
			grid-column-start: 2;
			grid-column-end: 3;
			display: flex;
			justify-content: flex-end;
		}
		button {
			border-radius: 60px;
			padding: 12px 72px;
			background-color: #000;
			color: #f6f6f6;
			font-size: 16px;
			font-family: 'TTInterfaces DemiBold';
			line-height: 26px;
			width: unset;
			min-width: unset;
			min-height: unset;
			@media (max-width: 992px) {
				width: 100%;
			}
		}
		@media (max-width: 1200px) {
			column-gap: 24px;
		}
		@media (max-width: 992px) {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
	}
	input,
	select {
		border-radius: 7px;
		padding: 14px 16px;
		border: 1px solid #d0d0d0;
		font-size: 16px;
		font-family: 'TTInterfaces Medium';
		width: 100%;
	}
	select {
		appearance: none;
		background-image: url(${arrow});
		background-repeat: no-repeat;
		background-position: right 10px top 50%;
	}
	input:not(:placeholder-shown),
	select:not([data-value='']) {
		padding: 16px 16px 12px 16px;
	}
	label {
		color: black;
		font-size: 11px;
		font-family: 'TTInterfaces Regular';
		position: absolute;
		left: 17px;
		top: 4px;
		line-height: 13px;
		transition: opacity 0.2s ease-in-out;
	}
	input:placeholder-shown + label {
		opacity: 0;
	}
	input:not(placeholder-shown) + label {
		opacity: 1;
	}
	select[data-value=''] + label {
		opacity: 0;
	}
	select:not([data-value='']) + label {
		opacity: 1;
	}
	#phoneLabel {
		left: 56px;
	}
	#phoneLabel[data-value=''] {
		opacity: 0;
	}
	#phoneLabel:not([data-value='']) {
		opacity: 1;
	}
`;

export default SetupCard;
