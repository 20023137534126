import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
	mainColors: {
		color: {
			textBlack: '#000000',
			textWhite: '#FFFFFF',
			textPrimary: '#191A1C',
			textSecondary: '#5B6166',
			textWarning: '#CC1440',
			separator: 'rgba(0, 0, 0, 0.1)',
			background: '#F8F8F8',
			backgroundWarning: '#FAE1E7',
			purple500: '#D6BBF7',
			yellow500: '#FFDA79',
			orange500: '#F3B398',
			pink500: '#E2A1C0',
			lightblue: '#CCE4E4',
		},
	},
	fonts: {
		color: {
			base: '#000000',
			muted: '#9D9D9D',
			light: '#FFFFFF',
		},
	},
	elements: {
		color: {
			grey: '#C8C8C8',
			backgroundGrey: '#F6F6F6',
			color1: '#CDB9DB',
			color2: '#C3E4E6',
		},
	},
	card: {
		hover: 'rgba(0, 0, 0, 0.05)',
	},
	cardImage: {
		hover: 'rgba(0, 0, 0, 0)',
	},
	points: {
		small: {
			fontNumber: 'normal normal bold 10px/10px "Roobert Semi Bold"',
			fontEnquiry: 'normal normal 10px/10px "Roobert Regular"',
			fontText: 'normal normal normal 8px/8px "Roobert Semi Bold"',
			size: '35px',
			borderRadius: '4px',
			padding: '2px',
		},
		intermediate: {
			fontNumber: 'normal normal bold 13px/13px "Roobert Semi Bold"',
			fontEnquiry: 'normal normal 9px/9px "Roobert Regular"',
			fontText: 'normal normal normal 10px/10px "Roobert Regular"',
			size: '50px',
			borderRadius: '7px',
			padding: '3px',
		},
		normal: {
			fontNumber: 'normal normal bold 16px/16px "Roobert Semi Bold"',
			fontEnquiry: 'normal normal 10px/10px "Roobert Regular"',
			fontText: 'normal normal normal 10px/10px "Roobert Regular"',
			size: '57px',
			borderRadius: '10px',
			padding: '5px',
		},
		large: {
			fontNumber: 'normal normal bold 24px/24px "Roobert Semi Bold"',
			fontEnquiry: 'normal normal 16px/16px "Roobert Regular"',
			fontText: 'normal normal normal 16px/16px "Roobert Regular"',
			size: '80px',
			borderRadius: '10px',
			padding: '10px',
		},
	},
	button: {
		small: {
			padding: '8px 20px 8px 20px',
			minWidth: '50px',
			font: 'normal normal 400 10px/16px "Moderat Regular"',
		},
		normal: {
			active: {
				color: '#ffffff',
				background: '#000000',
				border: '#000000',
			},
			disabled: {
				color: '#ffffff',
				background: '#000000',
				border: '#000000',
			},
			hover: {
				color: '#ffffff',
				background: '#000000',
				border: '#000000',
			},
			padding: '13px 20px 13px 20px',
			minWidth: '150px',
			font: 'normal normal 400 10px/16px "Moderat Regular"',
		},
		light: {
			active: {
				color: '#000000',
				background: '#ffffff',
				border: '#ffffff',
			},
			disabled: {
				color: '#000000',
				background: '#ffffff',
				border: '#ffffff',
			},
			hover: {
				color: '#000000',
				background: '#ffffff',
				border: '#ffffff',
			},
		},
		muted: {
			active: {
				color: '#ffffff',
				background: '#C8C8C8',
				border: '#C8C8C8',
			},
			disabled: {
				color: '#ffffff',
				background: '#C8C8C8',
				border: '#C8C8C8',
			},
			hover: {
				color: '#ffffff',
				background: '#C8C8C8',
				border: '#C8C8C8',
			},
		},
		color1: {
			active: {
				color: '#000000',
				background: '#CDB9DB',
				border: '#CDB9DB',
			},
			disabled: {
				color: '#000000',
				background: '#CDB9DB',
				border: '#CDB9DB',
			},
			hover: {
				color: '#000000',
				background: '#CDB9DB',
				border: '#CDB9DB',
			},
		},
		color2: {
			active: {
				color: '#000000',
				background: '#C3E4E6',
				border: '#C3E4E6',
			},
			disabled: {
				color: '#000000',
				background: '#C3E4E6',
				border: '#C3E4E6',
			},
			hover: {
				color: '#000000',
				background: '#C3E4E6',
				border: '#C3E4E6',
			},
		},
	},
	searchbox: {
		background: {
			active: '#F6F6F6',
			inactive: '#ffffff',
		},
	},
	blur: {
		start: 'rgba(246, 246, 246,0)',
		end: 'rgba(246, 246, 246,1)',
	},
	link: {
		solid: '#343a40',
		faded: '#6c757d',
	},
	input: {
		borderBottom: '#C8C8C8',
	},
	colors: {
		mainColor: '#FBF0E9',
		secundaryColor: 'white',
		muted: '#9D9D9D',
		light: '',
	},
	primaryButton: {
		backgroundColorLight: 'white',
		backgroundColorGrey: '#F2F2F2',
		backgroundColorDark: 'black',
		backgroundColorLightDisabled: 'white',
		backgroundColorDarkDisabled: 'grey',
		borderColor: 'black',
		colorLight: 'black',
		colorDark: 'white',
		colorLightDisabled: 'grey',
		colorDarkDisabled: 'white',
	},
	secondaryButton: {
		backgroundColor: 'white',
		backgroundColorHover: '#FBF0E9',
		borderColor: 'black',
	},
	productCard: {
		borderColor: 'black',
	},
	price: {
		backgroundColorLight: 'white',
		backgroundColorDark: 'black',
		backgroundColorLightDisabled: 'white',
		backgroundColorDarkDisabled: 'grey',
		borderColor: 'black',
		colorLight: 'black',
		colorDark: 'white',
	},
};

const Theme = ({ children }: any) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
