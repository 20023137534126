import React from 'react';
import styled from 'styled-components';
import { AiOutlineLeft } from 'react-icons/ai';
import PropTypes from 'prop-types';

const TopButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ showBack }) =>
		showBack ? `space-between` : `flex-end`};
	outline: none;

	height: 77px;
	top: 0px;
	postion: relative;
	margin-left: 40px;
	margin-right: 40px;
	@media (max-width: 991.98px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const BackButton = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	height: 35px;
	width: 70px;
	font-weight: bold;
	cursor: pointer;
	background: white;
	border: none;
	outline: none;
	&:focus {
		outline: none;
	}
`;

const StyledBoldButtonText = styled.div`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 22px;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;

const BackButtonContainer = ({ handleBackButton, show }) => {
	return (
		<TopButtonContainer>
			<BackButton onClick={handleBackButton}>
				{show && (
					<>
						<AiOutlineLeft
							size={14}
							style={{ marginRight: 12, fontWeight: 'bold' }}
						/>
						<StyledBoldButtonText>Back</StyledBoldButtonText>
					</>
				)}
			</BackButton>
		</TopButtonContainer>
	);
};

BackButtonContainer.propTypes = {
	handleBackButton: PropTypes.func,
};

BackButtonContainer.defaultProps = {
	handleBackButton: () => {},
};

export default BackButtonContainer;
