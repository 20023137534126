import React, { useState, useCallback } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Loading from '../../../utilities/Loading';
import {
	PSansMediumText,
	ModalButton,
	PSansText,
} from '../../../../styles/StylesV2';
import { Row, Col, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { BoxInputLabel } from '../../../../styles/StylesV2';
import { useAuth0 } from '../../../../contexts/react-auth0-spa';
import client from '../../../../api/client';

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledSavedAlert = styled.div`
	margin: 20px auto 20px auto;
	@media (max-width: 991.98px) {
		margin-right: 0px;
		width: 100%;
		margin-bottom: 20px;
	}
`;

const StyledToggleSwitchDefault = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: linear-gradient(0deg, #3dde87, #3dde87), #ffffff;
	position: relative;
	cursor: pointer;
`;

const StyledToggleSwitchOff = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: #e9e9ea;
	position: relative;
	cursor: pointer;
`;

const StyledWhiteCircle = styled.div`
	position: absolute;
	width: 16.36px;
	height: 16.36px;
	border-radius: 50%;
	background: white;
	bottom: 2px;
`;

const SetupBACS = ({ onHide, team }) => {
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const stripe = useStripe();
	const { getTokenSilently } = useAuth0();
	const [makeDefault, setMakeDefault] = useState(false);

	const getCheckoutSession = useCallback(async () => {
		try {
			setProcessing(true);
			const token = await getTokenSilently();
			client.defaults.headers.Authorization = 'Bearer ' + token;
			const response = await client.post(
				`/stripe/checkout-session/${team.id}`,
				{ makeDefault: makeDefault }
			);
			setProcessing(false);
			if (response.status === 200 && response.data) {
				return response.data.sessionId;
			} else {
				return false;
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [getTokenSilently, team.id, makeDefault]);

	const handleSubmitBACS = async (event) => {
		try {
			event.preventDefault();

			if (!stripe) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setProcessing(true);

			// Get or update setupIntent
			const resultCheckoutSession = await getCheckoutSession();

			if (resultCheckoutSession) {
				// Redirect and process sub on back-end
				stripe.redirectToCheckout({ sessionId: resultCheckoutSession });
			} else {
				setError(`SEPA setup could not be processed. Try again.`);
				setProcessing(false);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<form
			onSubmit={handleSubmitBACS}
			style={{ position: 'relative' }}
			className="w-100"
		>
			<Row noGutters>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel style={{ textTransform: 'none', fontSize: '20px' }}>
						{`You will be redirected to a secure page to register your direct
            debit.`}
					</StyledLabel>
				</Col>
			</Row>
			<Row noGutters>
				<StyledLabel style={{ textTransform: 'none' }}>
					Make this direct debit your default payment method
				</StyledLabel>
				{makeDefault ? (
					<>
						<StyledToggleSwitchDefault
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(false);
							}}
						>
							<StyledWhiteCircle style={{ right: 2 }} />
						</StyledToggleSwitchDefault>
						<PSansText>On</PSansText>
					</>
				) : (
					<>
						<StyledToggleSwitchOff
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(true);
							}}
							style={{ cursor: 'pointer' }}
						>
							<StyledWhiteCircle style={{ left: 2 }} />
						</StyledToggleSwitchOff>
						<PSansText>Off</PSansText>
					</>
				)}
			</Row>
			<Row
				noGutters
				className="text-left  justify-content-center align-items-center"
			>
				{/* Show any error that happens when processing the payment */}
				<StyledSavedAlert>
					{error && (
						<Alert
							className="text-center my-auto mx-auto p-1"
							variant={'danger'}
						>
							<PSansMediumText className="card-error" role="alert">
								{error}
							</PSansMediumText>
						</Alert>
					)}
				</StyledSavedAlert>
			</Row>
			<Row
				noGutters
				className="justify-content-around align-items-center py-2 mt-2"
			>
				<ModalButton type="button" version="light" onClick={onHide}>
					<PSansText>Cancel</PSansText>
				</ModalButton>
				<ModalButton disabled={processing} id="submit">
					<span id="button-text">
						{processing ? (
							<Loading className="d-flex align-items-center justify-content-center h-100 w-100" />
						) : (
							'Add'
						)}
					</span>
				</ModalButton>
			</Row>
		</form>
	);
};

export default SetupBACS;
