import React from 'react';
import { useAuth0 } from '../../../contexts/react-auth0-spa';
import Authenticated from './Authenticated';
import NonAuthenticated from './NonAuthenticated';

const BrandedCard = (props: any) => {
	const { isAuthenticated } = useAuth0();
	return isAuthenticated ? (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Authenticated {...props} />
	) : (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<NonAuthenticated {...props} />
	);
};

export default BrandedCard;
