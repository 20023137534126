import React from 'react';
import styled from 'styled-components';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { ReactComponent as Trash } from '../images/trash.svg';
import { ReactComponent as Gift } from '../images/gift.svg';
import { ReactComponent as Delete } from '../images/delete.svg';
import { ReactComponent as Add } from '../images/add.svg';
import { ReactComponent as Edit } from '../images/edit.svg';
import Search from '../images/Search';
import NoTeamMembers from './NoTeamMembers';
import Pager from '../../../styles/shared/Pager';
import Menu from '../../../styles/shared/Menu';

export type TeamMember = {
	userId: number;
	name: string;
	email: string;
	role: string;
	subStatus: string;
	budget: number | null;
	budgetCount: number;
	rewarder: boolean;
	points: number;
	address?: string;
};

const TeamMembersTable = ({
	team,
	selections,
	budgetMembers,
	teamBudgets,
	setSelections,
	updateRewarder,
	setCustomBudget,
}: {
	team: {
		id: number;
		currency: string;
		budget: number;
		fees: boolean;
		teamMembers: TeamMember[];
	};
	selections: Record<number, boolean>;
	budgetMembers: Record<
		number,
		{
			name: string;
			budgetAmount: number;
			memberAmount: number;
			budgetId: number;
		}[]
	> | null;
	teamBudgets: any[];
	setSelections: (
		update: (prevSelections: Record<number, boolean>) => Record<number, boolean>
	) => void;
	updateRewarder: (userId: number, rewarder: boolean) => Promise<void>;
	setCustomBudget: (budget: string) => void;
}) => {
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	const allowance = params.get('allowance') !== 'false';
	const nameSearch = params.get('search') || '';
	let page = +(params.get('page') || 1);
	const history = useHistory();
	const formatter = (whole = false) =>
		new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: team?.currency || 'gbp',
			maximumFractionDigits: whole ? 0 : undefined,
		});
	const query = (setParams: (p: URLSearchParams) => void) => {
		const newParams = new URLSearchParams(search);
		setParams(newParams);
		return newParams.toString();
	};
	const selectedUsers = Object.keys(selections).reduce((sels, userId) => {
		if (selections[+userId]) sels.push(+userId);
		return sels;
	}, [] as number[]);
	const filteredTeamMembers = team.teamMembers.filter(
		({ email, name, address }) =>
			!nameSearch ||
			email.toLowerCase().indexOf(nameSearch.toLowerCase()) !== -1 ||
			(name && name.toLowerCase().indexOf(nameSearch.toLowerCase()) !== -1) ||
			(team.id === 1053 &&
				address &&
				address.toLowerCase().indexOf(nameSearch.toLowerCase()) !== -1)
	);
	const numberOfPages = Math.ceil(filteredTeamMembers.length / 10);
	page = Math.min(page, numberOfPages);
	const start = (page - 1) * 10;
	const teamMemmbersPage = filteredTeamMembers.slice(start, start + 10);
	const onPlan = team.teamMembers.filter(
		({ subStatus }) => subStatus === 'active'
	).length;
	const offPlan = team.teamMembers.length - onPlan;
	return (
		<Members>
			<div className="header">
				<h3>Active users</h3>
				<div className="counts">
					<div className="count">{`${onPlan} on plan`}</div>
					<div className="count">{`${offPlan} off plan`}</div>
				</div>
				<div style={{ flex: 1 }} />
				<button
					type="button"
					disabled={
						!selectedUsers.length || !budgetMembers || !teamBudgets.length
					}
					onClick={() => {
						history.push(
							`${pathname}?${query((p) => {
								p.set('assign-budgets', 'true');
							})}`,
							{
								scroll: false,
							}
						);
					}}
				>
					Assign pot(s)
				</button>
				<Menu
					text="Invite team member"
					selected="email"
					setSelected={(option) => {
						history.push(
							`${pathname}?${query((p) => {
								p.set('invite-member', option);
							})}`,
							{
								scroll: false,
							}
						);
					}}
					options={[
						{
							key: 'email',
							value: 'Add by email address',
						},
						{
							key: 'csv',
							value: 'Upload a csv',
						},
					]}
				>
					<Add />
					Invite team member
				</Menu>
			</div>
			<div className="filter">
				<div>
					<Link
						to={{
							pathname,
							search: `?${query((p) => p.delete('allowance'))}`,
							state: { scroll: false },
						}}
						className={allowance ? 'selected' : undefined}
					>
						Show allowance
					</Link>
					<Link
						to={{
							pathname,
							search: `?${query((p) => p.set('allowance', 'false'))}`,
							state: { scroll: false },
						}}
						className={!allowance ? 'selected' : undefined}
					>
						Show balance
					</Link>
				</div>
				<div className="search">
					<Search stroke="rgb(102, 112, 133)" />
					<input
						placeholder="Search for people..."
						aria-label="Search for people"
						value={nameSearch}
						onChange={(e) => {
							history.replace(
								`${pathname}?${query((p) => {
									if (e.target.value) p.set('search', e.target.value);
									else p.delete('search');
									p.set('page', '1');
								})}`,
								{
									scroll: false,
								}
							);
							return false;
						}}
					/>
				</div>
			</div>
			{!teamMemmbersPage.length && <NoTeamMembers nameSearch={nameSearch} />}
			{!!teamMemmbersPage.length && (
				<>
					<table>
						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										checked={
											!!teamMemmbersPage.find(
												({ userId }) => !!selections[userId]
											)
										}
										onChange={(e) => {
											setSelections((prevSelections) =>
												teamMemmbersPage
													.filter(({ subStatus }) => subStatus === 'active')
													.reduce(
														(sels, { userId }) => ({
															...sels,
															[userId]: e.target.checked,
														}),
														prevSelections
													)
											);
										}}
									/>
								</th>
								<th>Name</th>
								<th>Role</th>
								<th>Status</th>
								<th>
									Juno Points ({`${allowance ? 'allowance' : 'balance'}`})
								</th>
								<th>Pots ({`${allowance ? 'allowance' : 'balance'}`})</th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							{teamMemmbersPage.map(
								({
									userId,
									name,
									email,
									role,
									subStatus,
									budget,
									budgetCount,
									rewarder,
									points,
									address,
								}) => (
									<tr
										key={email}
										className={selections[userId] ? 'selected' : undefined}
									>
										<td>
											{subStatus === 'active' && (
												<input
													id={email}
													type="checkbox"
													checked={!!selections[userId]}
													onChange={(e) => {
														setSelections((prevSelections) => ({
															...prevSelections,
															[userId]: e.target.checked,
														}));
													}}
												/>
											)}
										</td>
										<td>
											<h4>Name</h4>
											<label htmlFor={email}>
												<div className="name">{name}</div>
												<div>{email}</div>
												{team.id === 1053 ? <div>{address}</div> : null}
											</label>
										</td>
										<td>
											<label htmlFor={`${email}-role`}>Role</label>
											<select
												id={`${email}-role`}
												value={role}
												onChange={() => {
													history.push(
														`${pathname}?${query((p) => {
															p.set('change-role', userId.toString());
														})}`,
														{
															scroll: false,
														}
													);
												}}
											>
												<option value="admin">Admin</option>
												<option value="employee">Employee</option>
											</select>
											{role === 'employee' ? (
												<button
													type="button"
													aria-label="Toggle this employee's Rewarder status"
													onClick={() => updateRewarder(userId, !rewarder)}
												>
													<Gift className={rewarder ? 'on' : 'off'} />
												</button>
											) : null}
										</td>
										<td>
											<label htmlFor={`${email}-status`}>Status</label>
											<div className="status">
												<div className={subStatus} />
												<select
													id={`${email}-status`}
													value={subStatus}
													className={subStatus}
													onChange={() => {
														history.push(
															`${pathname}?${query((p) => {
																p.set('change-plan', userId.toString());
															})}`,
															{
																scroll: false,
															}
														);
													}}
												>
													<option value="active">On plan</option>
													<option value="inactive">Off plan</option>
												</select>
											</div>
										</td>
										<td>
											<h4>Juno Points ® (allowance)</h4>
											<div>
												{
													// eslint-disable-next-line no-nested-ternary
													subStatus === 'active' || !allowance
														? allowance
															? budget ?? team.budget
															: points
														: '-'
												}
												{allowance && subStatus === 'active' && (
													<button
														type="button"
														onClick={() => {
															setCustomBudget(
																(budget ?? team.budget).toString()
															);
															history.push(
																`${pathname}?${query((p) => {
																	p.set('custom-budget', userId.toString());
																})}`,
																{
																	scroll: false,
																}
															);
														}}
													>
														<Edit />
													</button>
												)}
											</div>
										</td>
										<td>
											<h4>Budgets (allowance)</h4>
											{subStatus === 'active' &&
											budgetCount > 0 &&
											(!budgetMembers || !!budgetMembers[userId]?.length) ? (
												<ul>
													{budgetMembers
														? [...budgetMembers[userId]]
																.sort((a, b) => a.name.localeCompare(b.name))
																.map(
																	({
																		name: budgetName,
																		budgetAmount,
																		memberAmount,
																		budgetId,
																	}) => (
																		<li key={budgetName}>
																			<div>
																				{budgetName}
																				<button
																					type="button"
																					aria-label="Delete budget membership"
																					onClick={() => {
																						history.push(
																							`${pathname}?${query((p) => {
																								p.set(
																									'delete-budgets-user',
																									userId.toString()
																								);
																								p.set(
																									'delete-budgets-budget',
																									budgetId.toString()
																								);
																							})}`,
																							{
																								scroll: false,
																							}
																						);
																					}}
																				>
																					<Delete />
																				</button>
																			</div>
																			<div>
																				{allowance
																					? formatter(true).format(budgetAmount)
																					: formatter().format(memberAmount)}
																			</div>
																		</li>
																	)
																)
														: 'Loading...'}
												</ul>
											) : (
												<p>
													{subStatus === 'active' ? 'No pots assigned' : '-'}
												</p>
											)}
										</td>
										<td>
											{role === 'employee' ? (
												<button
													type="button"
													aria-label="Remove from team"
													onClick={() => {
														history.push(
															`${pathname}?${query((p) => {
																p.set('remove', userId.toString());
															})}`,
															{
																scroll: false,
															}
														);
													}}
												>
													<Trash />
												</button>
											) : undefined}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<Pager total={filteredTeamMembers.length} />
				</>
			)}
		</Members>
	);
};

const Members = styled.div`
	background: #fff;
	border-radius: 18px;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	h3 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		line-height: 28px;
		margin: 0;
		margin-right: 8px;
	}
	.header,
	.filter {
		display: flex;
		padding: 20px 24px;
	}
	.header {
		border-bottom: 1px solid rgb(234, 236, 240);
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.header .count {
		height: 31px;
		padding: 4px 11px;
		border-radius: 18px;
		border: 2px solid rgb(7, 148, 85);
		color: rgb(7, 148, 85);
		display: flex;
		align-items: center;
		font-family: 'TTInterfaces DemiBold';
		font-size: 15px;
	}
	.header .counts {
		display: flex;
		gap: 10px;
	}
	.header .count + .count {
		border: 2px solid rgb(204, 20, 64);
		color: rgb(204, 20, 64);
	}
	.header button {
		display: flex;
		font-family: 'TTInterfaces DemiBold';
		font-size: 14.5px;
		line-height: 20px;
		color: rgb(52, 64, 84);
		padding: 10px 16px;
		border-radius: 8px;
		border: 1px solid rgb(208, 213, 221);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	.header button[disabled] {
		opacity: 40%;
	}
	.header button:nth-child(1) {
		background: rgb(25, 26, 28);
		color: #fff;
		svg {
			margin-right: 8px;
		}
	}
	.filter {
		border-bottom: 1px solid rgb(234, 236, 240);
	}
	.filter > div.search {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		box-shadow: none;
		height: unset;
		svg {
			position: relative;
			left: 36px;
		}
		input {
			max-width: 400px;
			display: flex;
			flex: 1;
			border: 1px solid rgb(208, 213, 221);
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			padding: 10px 14px;
			border-radius: 8px;
			padding-left: 48px;
		}
	}
	table {
		width: 100%;
	}
	button {
		border: 0;
		background: transparent;
	}
	svg.on path {
		stroke: rgb(71, 84, 103);
	}
	svg.off path {
		stroke: rgb(192, 192, 192);
	}
	tr {
		background: #fff;
		border-bottom: 1px solid rgb(234, 236, 240);
	}
	thead tr {
		background: rgb(252, 252, 253);
	}
	tr.selected {
		background: rgb(252, 252, 253);
	}
	th {
		font-family: 'TTInterfaces Medium';
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		height: 44px;
		padding: 12px 24px;
	}
	td {
		font-family: 'TTInterfaces Regular';
		font-size: 14px;
		line-height: 20px;
		.name {
			font-family: 'TTInterfaces Medium';
		}
		select {
			font-family: 'TTInterfaces Medium';
			font-size: 12.5px;
			line-height: 15px;
			padding: 6px 9px;
			border: 1px solid rgb(208, 213, 221);
			border-radius: 5px;
		}
		select + button {
			margin-left: 20px;
		}
		padding: 16px 24px;
		label {
			line-height: reset;
			margin-bottom: 0;
		}
		ul {
			padding: 16px;
			margin: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
			max-height: 132px;
			overflow: auto;
			li {
				padding: 4px 5px 4px 8px;
				border-radius: 6px;
				border: 1px solid rgb(208, 213, 221);
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				font-family: 'TTInterfaces Medium';
				font-size: 12px;
				line-height: 18px;
				display: flex;
				flex-direction: column;
				> div:first-child {
					display: flex;
					align-items: flex-start;
					flex-wrap: none;
					color: rgb(52, 64, 84);
				}
				> div:last-child {
					color: rgba(52, 64, 84, 0.6);
				}
				button {
					display: flex;
					padding: 0;
					padding-left: 5px;
					margin-left: 5px;
				}
			}
		}
		h4 {
			display: none;
		}
	}
	td:nth-child(2) {
		color: rgb(16, 24, 40);
		div:last-child {
			color: rgb(71, 84, 103);
		}
		div:nth-child(3) {
			font-size: 13px;
		}
	}
	td:nth-child(3) {
		white-space: nowrap;

		select {
			color: rgb(52, 64, 84);
		}
		label {
			display: none;
		}
	}
	td:nth-child(4) {
		div.status {
			display: flex;
			position: relative;
		}
		label {
			display: none;
		}
	}
	td:nth-child(4) select {
		border-radius: 16px;
		border: 2px solid rgb(204, 20, 64);
		color: rgb(204, 20, 64);
		font-size: 12.5px;
		line-height: 18px;
		padding: 2px 4px 3px 2px;
	}
	td:nth-child(4) select.active {
		border: 2px solid rgb(7, 148, 85);
		color: rgb(7, 148, 85);
		padding-left: 12px;
	}
	td:nth-child(4) div.active {
		width: 6px;
		height: 6px;
		background: rgb(7, 148, 85);
		border-radius: 50%;
		position: absolute;
		top: 11px;
		left: 8px;
	}
	td:nth-child(5) {
		color: rgb(71, 84, 103);
		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		> button {
			padding: 0;
		}
	}
	td:nth-child(6) {
		padding: 0;
		color: rgba(71, 84, 103);
		p {
			padding: 16px;
			margin: 0;
		}
	}
	input[type='checkbox'] {
		width: 20px;
		height: 20px;
		accent-color: rgb(25, 26, 28);
		vertical-align: middle;
	}
	@media (max-width: 1350px) {
		th,
		td {
			padding: 7px;
		}
		th:nth-child(1),
		td:nth-child(1) {
			padding-left: 24px;
		}
		th:nth-child(7),
		td:nth-child(7) {
			padding-right: 24px;
		}
	}
	@media (max-width: 1200px) {
		th,
		td {
			padding: 16px 24px;
		}
		.header {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			button {
				flex: 1;
				width: 100%;
			}
		}
		.filter {
			display: flex;
			flex-direction: column;
			a {
				width: 50%;
				display: flex;
				justify-content: center;
			}
			div.search {
				padding-top: 20px;
				position: relative;
				svg {
					position: absolute;
					left: 16px;
				}
				input {
					width: 100%;
					max-width: unset;
				}
			}
		}
		thead {
			display: none;
		}
		tr {
			display: flex;
			flex-wrap: wrap;
			position: relative;
		}
		td h4 {
			display: block;
			margin: 0;
			padding: 0;
			font-family: 'TTInterfaces Medium';
			font-size: 12px;
			line-height: 18px;
			color: rgba(71, 84, 103, 0.6);
			padding-bottom: 6px;
		}
		td:nth-child(1),
		td:nth-child(2),
		td:nth-child(5),
		td:nth-child(6) {
			flex: 100%;
		}
		td:nth-child(3),
		td:nth-child(4) {
			flex: 50%;
			label {
				display: block;
				font-family: 'TTInterfaces Medium';
				font-size: 12px;
				line-height: 18px;
				color: rgba(71, 84, 103, 0.6);
				padding-bottom: 6px;
			}
		}
		td:nth-child(7) {
			position: absolute;
			right: 0;
		}
		td:nth-child(5) > div {
			justify-content: flex-start;
		}
		td:nth-child(6) {
			padding: 0 8px;
			h4 {
				padding: 0 16px;
			}
		}
	}
`;

export default TeamMembersTable;
