import { useCurrency } from '../hooks';

const useCurrencyFormatter = () => {
	const currencies = useCurrency();

	// Calculating inverse of the 'currency-to-point' value figure stored in db
	const pointsToCurrencyMultipliers: { [key: string]: number } = {
		gbp: +(1 / currencies.gbp).toFixed(2),
		eur: +(1 / currencies.eur).toFixed(2),
		usd: +(1 / currencies.usd).toFixed(2),
	};

	return (points: number, currency: string) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency,
		});

		return formatter.format(
			+points * pointsToCurrencyMultipliers[currency] || 0
		);
	};
};

export default useCurrencyFormatter;
