import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { Circle, Container, BackgroundWrapper } from '../styles/ProductStyles';
import { H1 } from '../styles/StylesV3Main';
import { useProductContext } from '../contexts/productContext';
import { handleBackNavigation } from '../components/utilities/Functions';
import StyledLoading from '../styles/LoadingStyles';
import ProductFilter from '../styles/shared/ProductFilter';
import useFilter from '../styles/shared/ProductFilter/useFilter';
import ProductCard from '../styles/shared/ProductCard/ProductCard';

type CategoryProps = {
	match: { params: { categoryId?: string } };
};

const Category = ({ match }: CategoryProps) => {
	const { loading, products, categories } = useProductContext();
	const [orderBy, setOrderBy] = useState<
		'relevance' | 'newest' | 'dearest' | 'cheapest' | 'alphabetical'
	>('newest');
	const [pointsRange, setPointsRange] = useState<[number, number]>([
		0,
		Number.MAX_VALUE,
	]);
	const history = useHistory();

	const { maxPoints, products: filteredAndSortedProducts } = useFilter(
		products,
		pointsRange,
		orderBy,
		match.params.categoryId ? { [+match.params.categoryId]: true } : {}
	);

	const categoryName = match.params.categoryId
		? categories?.filter(
				({ id }: { id: number }) => id === +match.params.categoryId! // '!' we already check whether categoryId is undefined at the start of this expression. Eslint complaining still
		  )[0].name
		: 'All';

	if (loading) {
		return <StyledLoading height="80vh" />;
	}

	return (
		<BackgroundWrapper>
			<Container>
				<Circle
					onClick={() => {
						handleBackNavigation(history);
					}}
				>
					<MdArrowBack size={20} />
				</Circle>
				<div
					style={{
						marginTop: 32,
						marginBottom: 32,
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
					}}
				>
					<H1>{categoryName}</H1>
					<div style={{ display: 'flex' }}>
						<ProductFilter
							maxValue={maxPoints}
							showCategories={false}
							orderBy={orderBy}
							applyFilters={setPointsRange}
							setOrderBy={setOrderBy as any}
						/>
					</div>
				</div>
				<ProductRow>
					{filteredAndSortedProducts?.map(
						({
							id,
							imagePath,
							title,
							description,
							type,
							provider,
							location,
							productTypes,
							shortTitle,
							shortDescription,
						}) => {
							const multipleServices = productTypes.length > 1;
							const enquire = productTypes.length && productTypes[0].enquire;

							const pointsValue = () => {
								// Single product, just take basecost in only element of array
								if (productTypes.length === 1) {
									return productTypes[0].baseCost;
								}

								if (multipleServices) {
									return productTypes.reduce((prev, current) => {
										return prev < current.baseCost ? prev : current.baseCost;
									}, productTypes[0].baseCost);
								}

								// If no prices, default to '0'. Likely a card or enquiry product/service
								return 0;
							};

							return (
								<ProductCard
									key={id}
									imgUrl={imagePath}
									productTitle={shortTitle || title}
									productDescription={shortDescription || description}
									productId={id.toString()}
									provider={provider.title}
									providerImgUrl={provider.src}
									pointsValue={pointsValue()}
									reasonable={false}
									reason=""
									productType={type}
									isFromPrice={multipleServices}
									location={location}
									enquire={Boolean(enquire)}
								/>
							);
						}
					)}
					<HiddenElement />
					<HiddenElement />
					<HiddenElement />
				</ProductRow>
			</Container>
		</BackgroundWrapper>
	);
};

const HiddenElement = styled.div<{ mimicWidth?: string }>`
	flex: ${({ mimicWidth }) => (mimicWidth ? `1 0 ${mimicWidth}` : '1 0 260px')};
	visibility: hidden;
	content: '';
`;

const ProductRow = styled.div`
	max-width: 1250px;
	margin: auto;
	margin-bottom: 125px;

	@media (max-width: 500px) {
		margin-bottom: 25px;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 0 70px;
`;

export default Category;
