/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import styled from 'styled-components';
import { MdCloudUpload } from 'react-icons/md';
import PreviewComponent from './FilePreview';
import { useClient, useCurrentTeam } from '../../hooks';
import config from '../../config';
import { SmallTextSecondary } from '../../styles/StylesV3Main';
import { ReactComponent as Upload } from './upload.svg';

const StyledDropzone = styled(Dropzone)`
	&.dzu-dropzone {
		background: #f9f9f9;
		overflow: hidden !important;
	}
`;

const StyledPreviewComponet = styled(PreviewComponent)`
	height: 20px;
`;

const RecieptUploader = ({ setFiles, maxFiles, budget = false, ...props }) => {
	const client = useClient();
	const { id } = useCurrentTeam();

	const getUploadParams = async ({ file, meta }) => {
		const { data } = await client.put(
			`${config.platform.HOST}/payments/authorized/reimbursements/receipt${
				id ? `/${id}` : ''
			}?type=${encodeURIComponent(meta.type)}`
		);
		return {
			method: 'put',
			body: file,
			meta: { key: data.key },
			url: data.url,
		};
	};

	// called every time a file's `status` changes
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeStatus = ({ meta, file }, status, allFilesArray) => {
		setFiles({
			ready: allFilesArray.filter((f) =>
				['headers_received', 'done'].includes(f.meta.status)
			),
			all: allFilesArray,
		});
	};

	return (
		<StyledDropzone
			getUploadParams={getUploadParams}
			onChangeStatus={handleChangeStatus}
			accept="image/*, application/pdf"
			maxFiles={maxFiles}
			inputContent={
				<div
					key="content"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{!budget && <MdCloudUpload size={20} color="#5B6166" />}
					{budget && <Upload style={{ paddingBottom: '11px' }} />}
					<SmallTextSecondary>
						Drag and drop a file, or click to browse
					</SmallTextSecondary>
					{budget && (
						<p
							style={{
								width: '260px',
								textAlign: 'center',
								fontSize: '12px',
								fontFamily: 'TTInterfaces Regular',
								lineHeight: '16px',
								paddingTop: '11px',
							}}
						>
							Make sure the receipt is a clear scan, picture or file with a logo
							of the vendor and the amount. We only accept JPG, PNG or PDF.
						</p>
					)}
				</div>
			}
			inputWithFilesContent={
				<SmallTextSecondary key="upload">
					Upload more receipts
				</SmallTextSecondary>
			}
			PreviewComponent={(props) => {
				return <StyledPreviewComponet key="preview" {...props} />;
			}}
			styles={{
				dropzone: {
					background: !budget ? '#f9f9f9' : '#fff',
					border: !budget ? 0 : '2px #d0d0d0 dashed',
					overflow: 'hidden',
					height: !budget ? undefined : '227px',
					borderRadius: !budget ? undefined : '16px',
				},
				inputLabel: { fontSize: '14px', color: '#000' },
				inputLabelWithFiles: { fontSize: '14px', color: '#000' },
			}}
			{...props}
		/>
	);
};

export default RecieptUploader;
