/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as Arrow } from './arrow.svg';

const Pager = ({ total }: { total: number }) => {
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	let page = +(params.get('page') || 1);
	const query = (setParams: (p: URLSearchParams) => void) => {
		const newParams = new URLSearchParams(search);
		setParams(newParams);
		return newParams.toString();
	};
	const numberOfPages = Math.ceil(total / 10);
	page = Math.min(page, numberOfPages);
	const pages = {
		1: true,
		2: true,
		3: true,
		[page - 1]: true,
		[page]: true,
		[page + 1]: true,
		[numberOfPages - 2]: true,
		[numberOfPages - 1]: true,
		[numberOfPages]: true,
	};
	return (
		<Container>
			<div>
				{page > 1 ? (
					<Link
						to={{
							pathname,
							search: `?${query((p) => p.set('page', (page - 1).toString()))}`,
							state: { scroll: false },
						}}
					>
						<Arrow />
						<span>Previous</span>
					</Link>
				) : (
					<a className="disabled" aria-label="Previous">
						<Arrow />
						<span>Previous</span>
					</a>
				)}
			</div>
			<div className="links">
				<div className="count">{`Page ${page} of ${numberOfPages}`}</div>
				{Object.keys(pages).map((p, i, arr) => (
					<React.Fragment key={p}>
						{+p - +arr[i - 1] > 1 ? <a>...</a> : undefined}
						{+p > 0 && +p <= numberOfPages ? (
							<Link
								to={{
									pathname,
									search: `?${query((parms) => parms.set('page', p))}`,
									state: { scroll: false },
								}}
								className={+p === page ? 'selected' : undefined}
							>
								{p}
							</Link>
						) : undefined}
					</React.Fragment>
				))}
			</div>
			<div>
				{page < numberOfPages ? (
					<Link
						to={{
							pathname,
							search: `?${query((p) => p.set('page', (page + 1).toString()))}`,
							state: { scroll: false },
						}}
					>
						<span>Next</span>
						<Arrow />
					</Link>
				) : (
					<a className="disabled" aria-label="Next">
						<span>Next</span>
						<Arrow />
					</a>
				)}
			</div>
		</Container>
	);
};

const Container = styled.div`
	height: 68px;
	padding: 12px 24px 16px 24px;
	border: 1px 0px 0px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	a {
		display: flex;
		align-items: center;
		text-decoration: none !important;
		color: rgba(52, 64, 84) !important;
	}
	a.disabled {
		opacity: 40%;
	}
	div:nth-child(1) a,
	div:nth-child(3) a {
		height: 36px;
		padding: 8px 14px 8px 14px;
		border-radius: 8px;
		border: 1px;
		gap: 8px;
		border: 1px solid rgb(208, 213, 221);
		font-family: 'TTInterfaces DemiBold';
		font-size: 14.5px;
		line-height: 20px;
	}
	div:nth-child(1) a svg {
		transform: rotate(180deg);
	}
	div:nth-child(2) {
		font-family: 'TTInterfaces Medium';
		font-size: 14px;
		line-height: 20px;
		display: flex;
		a {
			width: 40px;
			height: 40px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
		}
		a.selected {
			background: rgb(249, 250, 251);
		}
	}
	.count {
		display: none;
	}
	@media (max-width: 1200px) {
		a span {
			display: none;
		}
		.count {
			display: block;
			font-family: 'TTInterfaces Medium';
			font-size: 14px;
			line-height: 20px;
			color: rgb(52, 64, 84);
		}
		.links a {
			display: none !important;
		}
	}
`;

export default Pager;
