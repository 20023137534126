import { useEffect } from 'react';
import config from '../config';
import useClient from './useClient';
import { useUserContext } from '../contexts/userContext';
import { useAuth0 } from '../contexts/react-auth0-spa';

const useOnboarding = () => {
	const client = useClient();
	const { userData, createTeam } = useUserContext();
	const { isAuthenticated } = useAuth0();

	const getMarketingCookie = () => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; marketingSignUp=`);
		if (parts.length === 2) return parts.pop()!.split(';').shift();
		return false;
	};

	const createTeamAndCheckout = async (
		teamName: string,
		budget: number,
		currency: string,
		seats: number,
		billingStartDate: string,
		pricingPlan: string
	) => {
		const teamId = await createTeam({
			teamName,
			junoDay: +billingStartDate.split('-')[0],
		});

		const response = await client.post(
			`${config.platform.HOST}/payments/authorized/subscriptions/checkout/${teamId}`,
			{
				budget,
				currency,
				seats,
				billingStartDate,
				pricingPlan,
			}
		);
		const url = response?.data?.returnUrl;
		if (response.status === 200 && url) window.location.href = url;
	};

	useEffect(() => {
		if (!isAuthenticated || !userData) return;
		const marketingCookie = getMarketingCookie();
		if (marketingCookie) {
			const {
				team: teamName,
				budget,
				currency,
				seats = 0,
				startDate,
				paymentFrequency: pricingPlan,
			} = JSON.parse(marketingCookie);

			const domain = document.location.hostname.split('.').slice(-2).join('.');
			document.cookie = `marketingSignUp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain};`;

			createTeamAndCheckout(
				teamName,
				budget,
				currency,
				seats,
				startDate,
				pricingPlan
			);
		}
	}, [isAuthenticated, userData]);
};

export default useOnboarding;
