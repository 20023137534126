import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EditorState, convertFromRaw } from 'draft-js';
import Button from '../../../styles/shared/Button/Button';
import { TeamBudget } from './types';

const Inspiration = ({
	inspirations,
}: {
	inspirations: (TeamBudget & { tagline: string })[];
}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	return (
		<>
			<InspirationInfo>
				<h3>Need some inspiration?</h3>
				<p>
					Create a pot tailored to any need or individual. The possibilities are
					endless. Here are a few example pots to inspire you...
				</p>
			</InspirationInfo>
			{inspirations.map(({ id, name, thumbnail, tagline }) => (
				<InspirationCard key={id}>
					<img src={thumbnail} alt={name} />
					<h4>{name}</h4>
					<p>{tagline}</p>
					<div style={{ flex: 1 }} />
					<div style={{ display: 'flex' }}>
						<Button
							state="default"
							size="small"
							onClick={() => {
								history.push(`${pathname}?inspiration=${id.toString()}`, {
									scroll: false,
								});
							}}
						>
							Set up
						</Button>
					</div>
				</InspirationCard>
			))}
		</>
	);
};

const displayPeriodLookup = {
	daily: 'Daily (weekday)',
	weekly: 'Weekly',
	monthly: 'Monthly',
	quarterly: 'Quarterly',
	biannually: 'Bi-Annually',
	yearly: 'Yearly',
	'once-off': 'Once-Off',
};

const Budgets = ({
	budgets,
	inspirations,
	currency,
}: {
	budgets: TeamBudget[];
	inspirations: (TeamBudget & { tagline: string })[];
	currency: string;
}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
	});
	return (
		<>
			{budgets.length === 0 && (
				<NoPots>
					<div className="table">
						<NewCard>
							<div>
								<h3>Start a new pot</h3>
								<p>Create a pot tailored to any need or individual</p>
								<Button
									state="default"
									size="small"
									onClick={() => {
										history.push(`${pathname}?add=true`, {
											scroll: false,
										});
									}}
								>
									Begin
								</Button>
							</div>
						</NewCard>
						<Inspiration inspirations={inspirations} />
					</div>
				</NoPots>
			)}
			{budgets.length !== 0 && (
				<Pots>
					<h2>Active Pots</h2>
					<div className="table">
						{budgets.map(
							({
								id,
								name,
								policy,
								thumbnail,
								amount,
								renewalPeriod,
								renewalType,
							}) => (
								<div key={id}>
									<img src={thumbnail} alt={name} />
									<h3>{name}</h3>
									<span>
										{`${
											displayPeriodLookup[renewalPeriod]
										} - ${formatter.format(amount)}
                                ${
																	renewalType === 'accumulate'
																		? ' - Rolls Over'
																		: ''
																}`}
									</span>
									<p>
										{EditorState.createWithContent(convertFromRaw(policy))
											.getCurrentContent()
											.getPlainText('\u0001')}
									</p>
									<div style={{ flex: 1 }} />
									<div style={{ display: 'flex', gap: '8px' }}>
										<Button
											state="default"
											buttonType="secondary"
											size="small"
											onClick={() => {
												history.push(`${pathname}?edit=${id.toString()}`, {
													scroll: false,
												});
											}}
										>
											Edit
										</Button>
										<Button
											state="default"
											buttonType="destructive"
											size="small"
											onClick={() => {
												history.push(`${pathname}?delete=${id.toString()}`, {
													scroll: false,
												});
											}}
										>
											Delete
										</Button>
									</div>
								</div>
							)
						)}
						<NewCard>
							<div>
								<h3>Start a new pot</h3>
								<p>Create a pot tailored to any need or individual</p>
								<Button
									state="default"
									size="small"
									onClick={() => {
										history.push(`${pathname}?add=true`, {
											scroll: false,
										});
									}}
								>
									Begin
								</Button>
							</div>
						</NewCard>
					</div>
					<div className="inspirationTable">
						<Inspiration inspirations={inspirations} />
						<NewCard>
							<div>
								<h3>Start a new pot</h3>
								<p>Create a pot tailored to any need or individual</p>
								<Button
									state="default"
									size="small"
									onClick={() => {
										history.push(`${pathname}?add=true`, {
											scroll: false,
										});
									}}
								>
									Begin
								</Button>
							</div>
						</NewCard>
					</div>
				</Pots>
			)}
		</>
	);
};

const InspirationInfo = styled.div`
	> h3 {
		color: #000;
		font-size: 24px;
		font-family: 'TTInterfaces Bold';
		margin-botom: 10px;
		@media (max-width: 900px) {
			font-size: 21px;
		}
	}
	> p {
		color: #000;
		line-height: 27px;
		font-size: 18px;
		opacity: 40%;
		font-family: 'TTInterfaces Medium';
		margin-bottom: 40px;
		@media (max-width: 900px) {
			font-size: 16px;
		}
	}
`;

const InspirationCard = styled.div`
	height: 350px;
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 24px;
	border-radius: 25px;
	img {
		height: 125px;
		border-radius: 16px;
		object-fit: cover;
	}
	h4 {
		color: #0a0f16;
		font-size: 18px;
		font-family: 'TTInterfaces Bold';
		padding-top: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	> p {
		line-height: 24px;
		font-size: 16px;
		opacity: 40%;
		font-family: 'TTInterfaces Medium';
		-webkit-line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	button {
		background-color: #7ba95e;
		padding: 4px 16px;
		font-size: 14px;
		&:hover {
			background-color: #629045;
		}
	}
`;

const NewCard = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: #fff;
	border-radius: 25px;
	border: 2px rgba(0, 0, 0, 0.5) dashed;
	> div {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		text-align: center;
	}
	h3 {
		margin: 0;
		color: #0a0f16;
		font-size: 20px;
		font-family: 'TTInterfaces Bold';
		@media (max-width: 900px) {
			font-size: 18px;
		}
	}
	p {
		padding: 10px 0 25px 0;
		margin: 0;
		color: #000;
		font-size: 18px;
		opacity: 40%;
		max-width: 240px;
		font-family: 'TTInterfaces Medium';
		@media (max-width: 900px) {
			font-size: 17px;
		}
	}
	button {
		padding: 4px 16px;
		font-size: 14px;
	}
`;

const NoPots = styled.div`
	.table {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		column-gap: 3%;
		> div:first-child {
			grid-row-start: 1;
			grid-row-end: 3;
		}
		> div:nth-child(2) {
			grid-column-start: 2;
			grid-column-end: 5;
		}
		> div:nth-child(n + 6) {
			margin-top: 60px;
		}
	}
	@media (max-width: 900px) {
		.table {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			> div:first-child {
				grid-row-start: unset;
				grid-row-end: unset;
				padding: 24px 0;
			}
			> div:nth-child(2) {
				grid-column-start: unset;
				grid-column-end: unset;
				margin-top: 40px;
			}
			> div:nth-child(n + 4) {
				margin-top: 40px;
			}
		}
	}
`;

const Pots = styled.div`
	h2 {
		color: '#000';
		font-size: 28px;
		line-height: 35px;
		font-family: 'TTInterfaces Bold';
		margin-bottom: 60px;
	}
	.table {
		display: grid;
		row-gap: 60px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		column-gap: 3%;
		margin-bottom: 60px;
		> div:not(:last-child) {
			height: 386px;
			flex: 1;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			padding: 24px;
			border-radius: 25px;
			> img {
				height: 160px;
				border-radius: 16px;
				object-fit: cover;
			}
			span {
				opacity: 40%;
				letter-spacing: 1px;
				font-family: 'TTInterfaces DemiBold';
				font-size: 12px;
				text-transform: uppercase;
			}
			h3 {
				color: #0a0f16;
				font-size: 18px;
				font-family: 'TTInterfaces Bold';
				padding-top: 20px;
			}
			> p {
				line-height: 24px;
				font-size: 16px;
				opacity: 40%;
				font-family: 'TTInterfaces Medium';
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
		}
		> div:last-child {
			display: flex;
			height: 386px;
		}
		button {
			padding: 4px 16px;
			font-size: 14px;
		}
	}
	.inspirationTable {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		column-gap: 3%;
		> div:first-child {
			grid-column-start: 1;
			grid-column-end: 5;
		}
		> div:nth-child(n + 2) {
			margin-bottom: 40px;
		}
	}
	@media (max-width: 1200px) {
		.table {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.inspirationTable {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			> div:first-child {
				grid-column-start: 1;
				grid-column-end: 3;
			}
		}
	}
	@media (max-width: 900px) {
		.table {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			row-gap: 40px;
			margin-bottom: 40px;
		}
		.inspirationTable {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			> div:first-child {
				grid-column-start: unset;
				grid-column-end: unset;
			}
			> div:last-child {
				padding: 24px 0;
			}
		}
	}
`;

export default Budgets;
