/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MdCheck } from 'react-icons/md';
import config from '../../../../config';
import { useUserContext } from '../../../../contexts/userContext';
import { useClient, useCurrentTeam } from '../../../../hooks';
import Button from '../../../../styles/shared/Button/Button';
import MultiSelect from '../../../../styles/shared/Select/MultiSelect';
import Spinner from '../../../../styles/shared/Spinner/Spinner';

const RewardPointsModal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 45px;
	gap: 40px;
	position: relative;
	font-family: 'TTInterfaces Medium';

	width: 475px;
	min-height: 415px;

	@media (max-width: 500px) {
		width: 100%;
		padding: 20px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 35px;
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 14px;

	input {
		width: 100%;
		padding: 15px 18px;
		height: 50px;

		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	textarea {
		width: 100%;
		padding: 15px 18px;
		height: 100px;

		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	label:first-of-type {
		font-family: 'TTInterfaces Demibold';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		margin: 0;
	}

	label:nth-of-type(2) {
		font-family: 'TTInterfaces Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		margin: 0;

		color: rgba(0, 0, 0, 0.35);
	}

	div {
		border-radius: 10px;

		input {
			opacity: 0;
			height: 0;
			width: 0;
			padding: 0;
			left: 50%;
			position: absolute;
		}
	}
	> div {
		width: 100%;
		border: 1.5px solid #e4e4e4;
		background: transparent;

		> div {
			> div {
				background: #fff;
			}
		}
	}
`;

const Headings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	text-align: center;

	h3 {
		font-family: 'TTInterfaces Demibold';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		margin: 0;
	}

	h4 {
		font-family: 'TTInterfaces Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 140%;
		color: rgba(0, 0, 0, 0.35);
		margin: 0;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 25px;
`;

const WalletToggle = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;

	background: #f8f8f8;
	border-radius: 12px;
	padding: 6px;
	gap: 12px;

	button {
		font-family: 'TTInterfaces Demibold';
		font-style: normal;
		font-weight: 600;
		font-size: 17px;
		border: none;

		flex: 1;
		background: #ffffff;
		border-radius: 7.5px;
		padding: 8px 40px;
		box-shadow: 


		justify-content: center;
		align-items: center;

		@media (max-width: 500px) {
			padding: 8px;
		}
	}

	button:first-of-type {
		background: ${(props) => (props.teamWalletSelected ? 'white' : 'transparent')};
		box-shadow: ${(props) =>
			props.teamWalletSelected ? '0px 0px 8px rgba(0, 0, 0, 0.08)' : null};
	}

	button:nth-of-type(2) {
		background: ${(props) => (!props.teamWalletSelected ? 'white' : 'transparent')};
		box-shadow: ${(props) =>
			!props.teamWalletSelected ? '0px 0px 8px rgba(0, 0, 0, 0.08)' : null};
	}

`;

const GreenCircle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 112px;
	height: 112px;
	background: #d9ecdb;
	border-radius: 50%;
`;

const RewardPoints = ({ close }) => {
	const membersRef = useRef(null);

	const ALL_MEMBERS = { label: 'All Members', value: 'ALL_MEMBERS' };
	const team = useCurrentTeam();
	const client = useClient();
	const { userData, transferPoints, updateUserData } = useUserContext();

	let receivers = [];
	const [teamWalletSelected, setTeamWalletSelected] = useState(
		team.teamMember.roles.includes('owner') || team.teamMember.rewarder
	);
	const [points, setPoints] = useState(0);
	const [companyValue, setCompanyValue] = useState('');
	const [personalMessage, setPersonalMessage] = useState('');

	const [selectedMembers, setSelectedMembers] = useState([]);
	const [allMembers, setAllMembers] = useState([]);
	const [showSuccess, setShowSuccess] = useState(false);
	const [loading, setLoading] = useState(true);

	const createDisplayName = ({ firstName, lastName, email }) => {
		return `${firstName && lastName ? `${firstName} ${lastName}` : email}`;
	};

	const getTeamMembers = async (teamId) => {
		const { data } = await client.get(`${config.teams.HOST}/teams/${teamId}`);
		setAllMembers([...data.filter(({ subStatus }) => subStatus === 'active')]);
	};

	const handleSelectMembers = async (newValue, actionMeta) => {
		if (
			actionMeta.action === 'select-option' &&
			actionMeta.option?.value === ALL_MEMBERS.value
		) {
			setSelectedMembers([ALL_MEMBERS]);
		} else if (actionMeta.action === 'clear') {
			setSelectedMembers([]);
		} else {
			const newAllMembersExcluded = newValue.filter(
				(nv) => nv.value !== ALL_MEMBERS.value
			);

			setSelectedMembers(newAllMembersExcluded);
		}
	};

	const sendPoints = async () => {
		setLoading(true);

		const receiverIds = receivers.map((member) => member.value);

		const formatNotes = (value, message) => {
			if (value && message) {
				return `${value}: ${message}`;
			}

			if (value) return value;
			if (message) return message;

			return undefined;
		};

		try {
			if (teamWalletSelected) {
				await client.post(`${config.teams.HOST}/teams/reward-team/${team.id}`, {
					recipients: receiverIds,
					points: +points,
					notes: formatNotes(companyValue, personalMessage),
				});
			} else {
				await transferPoints(
					team.id,
					+points,
					userData.id,
					receiverIds,
					formatNotes(companyValue, personalMessage)
				);
			}

			setLoading(false);
			updateUserData();
			setShowSuccess(true);
		} catch (err) {
			console.error(err);
		}
	};

	const handleWalletSwitch = async (isTeamWalletSelected) => {
		if (teamWalletSelected === isTeamWalletSelected) return;

		setTeamWalletSelected(isTeamWalletSelected);
		setSelectedMembers([]);
	};

	useEffect(async () => {
		getTeamMembers(team.id);
		setLoading(false);
	}, []);

	const listFormatter = new Intl.ListFormat('en-GB', {
		style: 'long',
		type: 'conjunction',
	});

	const memberOptions = [
		ALL_MEMBERS,
		...allMembers
			.filter(({ userId }) => teamWalletSelected || userId !== userData.id)
			.map((member) => {
				return { label: createDisplayName(member), value: member.userId };
			}),
	];

	if (
		selectedMembers.length === 1 &&
		selectedMembers[0].value === 'ALL_MEMBERS'
	) {
		receivers = memberOptions.slice(1);
	} else {
		receivers = selectedMembers;
	}

	const maxPoints = teamWalletSelected
		? team.points
		: team.teamMember.points + userData.points;

	const setWalletMessage = (wallet) => {
		const { valueMissing, rangeUnderflow, rangeOverflow, stepMismatch } =
			wallet.validity;

		wallet.setCustomValidity(
			valueMissing
				? 'Please enter a points amount'
				: rangeOverflow
				? `Oops, you only have ${maxPoints} Juno Points ® in your ${
						teamWalletSelected ? ' Team Wallet' : ' Personal Wallet'
				  }`
				: rangeUnderflow
				? 'Points amount must be greater than 0'
				: stepMismatch
				? 'Points must be a round number'
				: ''
		);
	};

	return (
		<RewardPointsModal>
			{loading && (
				<div style={{ margin: 'auto' }}>
					<Spinner />
				</div>
			)}

			{!loading &&
				(!showSuccess ? (
					<>
						<Headings>
							<h3>Recognise a colleague</h3>
							<h4>
								Use Juno Points ® to recognise a colleague for going above and
								beyond
							</h4>
						</Headings>

						{(team.teamMember.roles.includes('owner') ||
							team.teamMember.rewarder) && (
							<WalletToggle teamWalletSelected={teamWalletSelected}>
								<button onClick={() => handleWalletSwitch(true)} type="button">
									Team Wallet
								</button>
								<button onClick={() => handleWalletSwitch(false)} type="button">
									Personal
								</button>
							</WalletToggle>
						)}

						<form
							onSubmit={(e) => {
								e.preventDefault();
								sendPoints();
							}}
						>
							<InputContainer>
								<label htmlFor="amount">Amount</label>
								<input
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									id="amount"
									onChange={async ({ target: { value }, currentTarget }) => {
										await setPoints(value);
										setWalletMessage(currentTarget);
									}}
									value={points}
									required
									placeholder="0"
									step="1"
									type="number"
									min="1"
									max={maxPoints / (receivers.length || 1)}
									onInvalid={({ currentTarget }) => {
										setWalletMessage(currentTarget);
									}}
								/>
								<label>
									You have {maxPoints} Juno Points ® in your{' '}
									{teamWalletSelected ? ' Team Wallet' : ' Personal Wallet'}
								</label>
							</InputContainer>

							<InputContainer>
								<label htmlFor="recipients">Recipient(s)</label>
								<div>
									<MultiSelect
										placeholder="Select recipient(s)"
										isMulti
										options={memberOptions}
										value={selectedMembers}
										onChange={handleSelectMembers}
										name="recipientsList"
										required
										ref={membersRef}
									/>
									<input
										id="recipients"
										tabIndex={-1}
										autoComplete="off"
										value={selectedMembers}
										required
										onChange={() => {}}
										onFocus={() => membersRef.current.focus()}
									/>
								</div>
							</InputContainer>

							<InputContainer>
								<label htmlFor="companyvalues">Company value</label>
								<input
									id="companyvalues"
									onChange={({ target: { value } }) => {
										setCompanyValue(value);
									}}
									type="text"
									placeholder="Enter a company value"
								/>
							</InputContainer>

							<InputContainer>
								<label htmlFor="message">Personal message</label>
								<textarea
									id="message"
									onChange={({ target: { value } }) =>
										setPersonalMessage(value)
									}
									type="text"
									placeholder="Enter a personal message"
								/>
							</InputContainer>

							<Buttons>
								<Button
									type="button"
									size="small"
									onClick={() => close()}
									state="default"
									buttonType="hollow"
								>
									Cancel
								</Button>
								<Button
									type="submit"
									size="small"
									state={
										teamWalletSelected || team.teamMember.status !== 'suspended'
											? 'default'
											: 'disabled'
									}
									buttonType="primary"
								>
									Submit
								</Button>
							</Buttons>
						</form>
					</>
				) : (
					<>
						<GreenCircle>
							<MdCheck size={40} color="#2A552A" />
						</GreenCircle>
						<Headings>
							<h3>Points sent!</h3>
							<h4>
								You’ve sent {points} points to{' '}
								{listFormatter.format(
									receivers.map((recipient) => recipient.label)
								)}
							</h4>
						</Headings>
						<Button
							type="submit"
							size="small"
							onClick={() => close()}
							state="default"
							buttonType="primary"
						>
							Done
						</Button>
					</>
				))}
		</RewardPointsModal>
	);
};

export default RewardPoints;
