import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { H4 } from '../../styles/StylesV3Main';
import AccountsSideMenu from '../../components/user/AccountsSideMenu';
import AccountsView from '../../components/user/AccountsView';
import FavoritesView from '../../components/user/FavouritesView';
import OrdersView from '../../components/user/OrdersView';
import BenefitsView from './BenefitsView';
import BenefitDetails from './BenefitDetails';
import config from '../../config';
import { useUserContext } from '../../contexts/userContext';
import { useClient } from '../../hooks';
import { useAuth0 } from '../../contexts/react-auth0-spa';

const BackgroundWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${(props) => (props.align ? props.align : 'center')};
	background: ${(props) =>
		props.background
			? props.background
			: props.theme.mainColors.color.background};

	@media (max-width: 992px) {
		max-width: 100%;
	}
`;

const StyledContainer = styled.div`
	width: 100%;
	max-width: 1250px;
	margin: 40px 135px;

	@media (max-width: 1250px) {
		padding: 40px 80px;
		margin: 0px;
	}

	@media (max-width: 992px) {
		width: 100%;
		padding: 0px;
		margin: 0px;
	}
`;

const StyledAccountsMenu = styled.div`
	width: 100%;
	max-width: 250px;
	height: fit-content;
	background: #fff;
	border-radius: 3px;
	padding: 10px 0px;

	@media (max-width: 992px) {
		display: none;
	}
`;

const StyledAccountsMobileMenu = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: block;
		height: 67;
		overflow: scroll;
		position: relative;
		top: 0px;
		margin: 0px 20px;
		display: flex;
		flex-direction: row;
		webkit-overflow-scrolling: touch;
		::-webkit-scrollbar {
			width: 0; /* Remove scrollbar space */
		}
	}
`;

const StyledMobileMenuRow = styled.div`
	padding: 24px 30px 17px 30px;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-bottom: 2px solid grey;
	border-bottom: ${(props) =>
		props.selected === false
			? `2px solid rgba(0, 0, 0, 0.07)`
			: `2px solid black`};
`;

const AccountSettings = ({ match }) => {
	const { logout } = useAuth0();
	const client = useClient();
	const [selected, setSelected] = useState('accounts');
	const [benefits, setBenefits] = useState(null);
	const [loadingBenefits, setLoadingBenefits] = useState(true);
	const { userData } = useUserContext();

	const history = useHistory();

	const sideMenuTitle = [
		{ title: 'Account details', value: '' },
		{ title: 'My Favourites', value: 'favourites' },
		{ title: 'My Orders', value: 'orders' },
		// conditionally show benefits if user is on a team
		...(userData.currentTeam ? [{ title: 'Benefits', value: 'benefits' }] : []),
	];

	const benefitsFromApi = async () => {
		const benefitsApi = await client.get(
			`${config.teams.HOST}/teams/benefits/${userData.currentTeam}`
		);

		setBenefits(benefitsApi.data.benefits);
		setLoadingBenefits(false);
	};

	useEffect(() => {
		if (userData.currentTeam) {
			benefitsFromApi();
		}
	}, [userData.currentTeam]);

	useEffect(() => {
		try {
			setSelected(match.params.view ? match.params.view : '');
			// history.push(
			//   `/account-settings/${match.params.view ? match.params.view : ''}`
			// );
		} catch (err) {
			console.error(err.message);
		}
	}, [match.params.view /*history*/]);

	return (
		<BackgroundWrapper>
			<StyledContainer>
				<Row noGutters>
					<Col lg={3}>
						<StyledAccountsMenu>
							{sideMenuTitle.map((item, index) => (
								<AccountsSideMenu
									key={index}
									title={item.title}
									selected={selected === item.value}
									handleSelected={() =>
										history.push(`/account-settings/${item.value}`)
									}
								/>
							))}
							<AccountsSideMenu
								title="Logout"
								selected={false}
								handleSelected={logout}
							/>
						</StyledAccountsMenu>
						<StyledAccountsMobileMenu>
							{sideMenuTitle.map((item, index) => (
								<Row
									noGutters
									as={StyledMobileMenuRow}
									key={index}
									selected={selected === item.value}
									onClick={() => {
										history.push(`/account-settings/${item.value}`);
									}}
								>
									<H4>{item.title}</H4>
								</Row>
							))}
							<Row
								noGutters
								as={StyledMobileMenuRow}
								selected={false}
								onClick={logout}
							>
								<H4>Logout</H4>
							</Row>
						</StyledAccountsMobileMenu>
					</Col>
					<Col lg={9} md={12} sm={12} xs={12}>
						{!match.params.view && <AccountsView />}
						{match.params.view === 'favourites' && <FavoritesView />}
						{match.params.view === 'orders' && <OrdersView match={match} />}
						{userData.currentTeam &&
							match.params.view === 'benefits' &&
							!match.params.benefitId && (
								<BenefitsView
									match={match}
									benefits={benefits}
									loading={loadingBenefits}
								/>
							)}
						{userData.currentTeam &&
							match.params.view === 'benefits' &&
							match.params.benefitId && (
								<BenefitDetails
									match={match}
									benefits={benefits}
									loading={loadingBenefits}
								/>
							)}
					</Col>
				</Row>
			</StyledContainer>
		</BackgroundWrapper>
	);
};

export default AccountSettings;
