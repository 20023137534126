import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory, matchPath } from 'react-router-dom';
import Modal from '../../../styles/shared/Modal';
import { useUserContext } from '../../../contexts/userContext';
import RewardPoints from '../../team/ManagePoints/Wallet/RewardPoints';
import { useCurrentTeam, useFlexPot } from '../../../hooks';
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as SendPoints } from './images/send-points.svg';
import { ReactComponent as Budgets } from './images/budgets.svg';
import { ReactComponent as FlexCard } from './images/flex-card.svg';
import { ReactComponent as Expenses } from './images/expenses.svg';
import { ReactComponent as Team } from './images/team.svg';
import { ReactComponent as Expand } from './images/expand.svg';
import { ReactComponent as Collapse } from './images/collapse.svg';
import { ReactComponent as SwitchTeam } from './images/switch-team.svg';
import { ReactComponent as CreateTeam } from './images/create-team.svg';
import { ReactComponent as Avatar } from './images/avatar.svg';
import { ReactComponent as Points } from './images/points.svg';
import Location from '../Location';

const NavBarContainer = ({ active, setActive }: any) => {
	const navBar = useRef<HTMLDivElement>(null);
	const { userData, changeCurrentTeam } = useUserContext();
	const location = useLocation();
	const history = useHistory();
	const team = useCurrentTeam();
	const [budgetsActive, setBudgetsActive] = useState(false);
	const [pointsActive, setPointsActive] = useState(false);
	const [teamActive, setTeamActive] = useState(false);
	const [switchTeamActive, setSwitchTeamActive] = useState(false);
	const [rewardPointsModal, setRewardPointsModal] = useState(false);
	const closeNavBar = useCallback(() => {
		setActive(false);
		setPointsActive(false);
		setBudgetsActive(false);
		setTeamActive(false);
		setSwitchTeamActive(false);
	}, []);
	useEffect(() => {
		const onClick = ({ target }: any) => {
			if (!target.closest('button') && !navBar.current?.contains(target))
				closeNavBar();
		};
		window.document.addEventListener('click', onClick);
		return () => window.document.removeEventListener('click', onClick);
	}, [closeNavBar]);
	const flexPot = useFlexPot();
	const budgets =
		userData.teams
			.find(
				({ id, teamMember }: any) =>
					id === userData.currentTeam && teamMember.subStatus === 'active'
			)
			?.budgets.filter(({ id }: any) => flexPot !== id) || [];
	const otherTeams = userData.teams.filter(({ id }: any) => id !== team.id);
	const mobile = () => !window.matchMedia('(min-width: 500px)').matches;
	const owner =
		team.teamMember?.roles.find((r: string) => r === 'owner') || false;
	const onMouseLeave = ({
		currentTarget,
	}: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		if (!mobile()) {
			closeNavBar();
			if (
				document.activeElement &&
				currentTarget.contains(document.activeElement)
			) {
				(document.activeElement as any)?.blur();
			}
		}
	};
	return (
		<>
			<NavBarBlock
				active={active}
				onMouseEnter={() => setActive(true)}
				onMouseLeave={onMouseLeave}
				onMouseMove={() => setActive(true)}
			/>
			<NavBar
				ref={navBar}
				active={active}
				onFocus={() => {
					if (!mobile()) setActive(true);
				}}
				onBlur={({ currentTarget, relatedTarget }) => {
					if (relatedTarget && !currentTarget.contains(relatedTarget))
						closeNavBar();
				}}
				onMouseEnter={() => setActive(true)}
				onMouseLeave={onMouseLeave}
				onMouseMove={() => setActive(true)}
			>
				<span className="location">
					<Location />
				</span>
				<Link to="/" className="logo">
					<Logo />
				</Link>
				{!!team.id && (
					<>
						{team.id !== 30 && (
							<>
								<h2>Rewards & Recognition</h2>
								<div>
									<button
										type="button"
										onClick={() => {
											setRewardPointsModal(true);
											if (mobile()) closeNavBar();
										}}
									>
										<SendPoints />
										<div>Send points</div>
									</button>
								</div>
							</>
						)}
						<h2>Spending</h2>
						{!!flexPot && (
							<>
								<div>
									<button
										type="button"
										onClick={(e) => {
											e.stopPropagation();
											setPointsActive(!pointsActive);
										}}
									>
										<Points />
										<div>Points</div>
										{!pointsActive ? <Expand /> : <Collapse />}
									</button>
								</div>
								<SubMenu active={pointsActive}>
									<li>
										<Link
											to="/card"
											tabIndex={pointsActive ? 0 : -1}
											onClick={() => {
												if (mobile()) closeNavBar();
											}}
										>
											Cashback Card
										</Link>
									</li>
									<li>
										<Link
											to="/reimbursement"
											tabIndex={pointsActive ? 0 : -1}
											onClick={() => {
												if (mobile()) closeNavBar();
											}}
										>
											Expenses
										</Link>
									</li>
								</SubMenu>
							</>
						)}
						{!!budgets.length && (
							<>
								<div>
									<button
										type="button"
										onClick={(e) => {
											e.stopPropagation();
											setBudgetsActive(!budgetsActive);
										}}
									>
										<Budgets />
										<div>Pots</div>
										{!budgetsActive ? <Expand /> : <Collapse />}
									</button>
								</div>
								<SubMenu active={budgetsActive}>
									{budgets.map(({ id, name }: any) => (
										<li key={id}>
											<Link
												to={`/budget/${id}`}
												tabIndex={budgetsActive ? 0 : -1}
												onClick={() => {
													if (mobile()) closeNavBar();
												}}
											>
												{name}
											</Link>
										</li>
									))}
								</SubMenu>
							</>
						)}
						<div>
							<Link
								to={
									// eslint-disable-next-line no-nested-ternary
									!flexPot
										? '/card'
										: flexPot !== -1
										? `/budget/${flexPot}`
										: `/no-flex`
								}
								onClick={() => {
									if (mobile()) closeNavBar();
								}}
							>
								<FlexCard />
								<div>Flex Card</div>
							</Link>
						</div>
					</>
				)}
				{!team.id && <h2>Spending</h2>}
				<div>
					<Link
						to={
							// eslint-disable-next-line no-nested-ternary
							!flexPot
								? '/reimbursement'
								: flexPot !== -1
								? `/budget/${flexPot}?expense=true`
								: `/no-flex`
						}
						onClick={() => {
							if (mobile()) closeNavBar();
						}}
					>
						<Expenses />
						<div>Expenses</div>
					</Link>
				</div>
				<h2>Team</h2>
				{!!team.id && (
					<>
						<div>
							{owner ? (
								<button
									type="button"
									onClick={(e) => {
										e.stopPropagation();
										setTeamActive(!teamActive);
									}}
								>
									<Team />
									<div>{team.name}</div>
									{!teamActive ? <Expand /> : <Collapse />}
								</button>
							) : (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<a>
									<Team />
									<div>{team.name}</div>
								</a>
							)}
						</div>
						{owner && (
							<SubMenu active={teamActive}>
								{[
									{ url: 'team', text: 'Manage team' },
									{ url: 'plan', text: 'Manage plan' },
									{ url: 'budgets', text: 'Manage pots' },
									{ url: 'points', text: 'Team wallet' },
									{ url: 'engagement', text: 'Wellbeing report' },
									{ url: 'benefits', text: 'Core benefits' },
									{ url: 'addon', text: 'Add-ons' },
								].map(({ url, text }) => (
									<li key={text}>
										<Link
											to={`/team/${team.id}/${url}`}
											tabIndex={teamActive ? 0 : -1}
											onClick={() => {
												if (mobile()) closeNavBar();
											}}
										>
											{text}
										</Link>
									</li>
								))}
							</SubMenu>
						)}
					</>
				)}
				{!!otherTeams.length && (
					<>
						<div>
							<button
								type="button"
								onClick={(e) => {
									e.stopPropagation();
									setSwitchTeamActive(!switchTeamActive);
								}}
							>
								<SwitchTeam />
								<div>Switch team</div>
								{!switchTeamActive ? <Expand /> : <Collapse />}
							</button>
						</div>
						<SubMenu active={switchTeamActive}>
							{otherTeams.map(({ id, name }: any) => (
								<li key={id}>
									<button
										type="button"
										tabIndex={switchTeamActive ? 0 : -1}
										onClick={() => {
											if (
												location.pathname.indexOf('budget') > -1 ||
												location.pathname.indexOf('reimbursement') > -1
											) {
												history.push('/');
											}
											const match = matchPath<{ view: string }>(
												location.pathname,
												{
													path: '/team/:teamId/:view?',
												}
											);
											if (match) {
												history.push(`/team/${id}/${match.params.view || ''}`);
											}
											changeCurrentTeam(id);
											setSwitchTeamActive(false);
											if (mobile()) closeNavBar();
										}}
									>
										{name}
									</button>
								</li>
							))}
						</SubMenu>
					</>
				)}
				<CreateTeamMenuItem>
					<a href="https://www.withjuno.com/build-your-plan">
						<CreateTeam />
						<div>Create team</div>
					</a>
				</CreateTeamMenuItem>
				<Link
					className="avatar"
					to="/account-settings"
					onClick={() => {
						if (mobile()) closeNavBar();
					}}
				>
					{!userData.src ? (
						<div>
							<Avatar />
						</div>
					) : (
						<img alt="user-avatar" src={userData.src} />
					)}
				</Link>
			</NavBar>
			{rewardPointsModal &&
				userData.currentTeam &&
				userData.teams.filter(({ id }: any) => id === userData.currentTeam)
					.length > 0 && (
					<Modal
						open={rewardPointsModal}
						ariaLabel="Recognition modal"
						onClose={() => {
							setRewardPointsModal(false);
						}}
					>
						<RewardPoints
							close={() => {
								setRewardPointsModal(false);
							}}
						/>
					</Modal>
				)}
		</>
	);
};

const NavBarBlock = styled.div<{ active: boolean }>`
	width: ${({ active }) => (!active ? '95px' : '268px')};
	transition-property: width;
	transition-duration: 0.5s;
	@media (max-width: 500px) {
		display: none;
	}
`;

const NavBar = styled.div<{ active: boolean }>`
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	bottom: 0;
	top: 35px;
	width: ${({ active }) => (!active ? '95px' : '268px')};
	background-color: #f0f0f0;
	> div > button {
		background-color: #f0f0f0;
	}
	padding-left: 25px;
	margin-bottom: 90px;
	transition-property: width;
	transition-duration: 0.5s;
	> button:first-child {
		display: none;
	}
	@media (max-width: 500px) {
		top: 95px;
		width: ${({ active }) => (!active ? '0' : '100%')};
		padding: 0;
		z-index: 10;
		overflow-y: scroll;
		height: calc(100vh - 95px);
		> button:first-child {
			display: block;
		}
		.logo {
			display: none;
		}
	}
	h2 {
		padding-top: 30px;
		padding-bottom: 10px;
		margin: 0;
		text-transform: uppercase;
		padding-left: 11px;
		white-space: nowrap;
		opacity: ${({ active }) => (!active ? '0' : '1')};
		transition-property: opacity;
		transition-duration: 0.5s;
		overflow: hidden;
		font-family: 'TTInterfaces DemiBold';
		font-size: 11px;
		letter-spacing: 1.5px;
		color: #9f9f9f;
		@media (max-width: 500px) {
			padding-left: 35px;
		}
	}
	> div {
		padding-left: 11px;
		position: relative;
		@media (max-width: 500px) {
			padding-left: 35px;
		}
	}
	> span.location {
		display: none;
		@media (max-width: 500px) {
			display: flex;
			justify-content: center;
		}
	}
	> div button,
	> div a {
		display: flex;
		width: calc(100% - 10px);
		align-items: center;
		border: 0;
		padding: 15px 0;
		margin-bottom: 10px;
		div {
			flex: 1;
			text-align: left;
			padding-left: 16px;
			white-space: nowrap;
			opacity: ${({ active }) => (!active ? '0' : '1')};
			transition-property: opacity;
			transition-duration: 0.5s;
			overflow: hidden;
			font-family: 'TTInterfaces Medium';
			font-size: 15px;
			color: #525252;
		}
		svg:nth-child(3) {
			position: absolute;
			right: unset;
			left: 200px;
			opacity: ${({ active }) => (!active ? '0' : '1')};
			@media (max-width: 500px) {
				right: 35px;
				left: unset;
			}
		}
	}
	> a {
		width: 44px;
		height: 44px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	a,
	a:hover {
		text-decoration: none;
	}
	.logo {
		background-color: #000;
	}
	.avatar {
		position: fixed;
		left: 24px;
		bottom: 30px;
		div {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #f1f1f1;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			border: 1px solid #b2b5bb;
		}
		img {
			width: 35px;
			height: 35px;
			border-radius: 50%;
			object-fit: cover;
		}
		@media (max-width: 500px) {
			position: unset;
			padding-left: 44px;
		}
	}
`;

const SubMenu = styled.ul<{ active: boolean }>`
	font-family: 'TTInterfaces Medium';
	font-size: 15px;
	list-style: none;
	margin: 0 20px 0 0;
	padding: 2px 10px 0 50px;
	white-space: nowrap;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: ${({ active }) => (!active ? '0' : '360px')};
	opacity: ${({ active }) => (!active ? '0' : '1')};
	transition: 0.5s linear;
	li {
		padding: 1px 0;
	}
	a,
	a:hover {
		padding: 0;
		margin: 0;
		display: block;
		color: rgba(82, 82, 82, 0.7);
		padding-bottom: 23px;
	}
	button {
		border: 0;
		width: 100%;
		text-align: left;
		color: rgba(82, 82, 82, 0.7);
		padding-bottom: 23px;
	}
`;

const CreateTeamMenuItem = styled.div`
	a {
		margin-left: -6px;
		div {
			margin-left: -4px;
		}
	}
`;

export default NavBarContainer;
