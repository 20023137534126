/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { H2, ModalButton, PSansText } from '../../styles/StylesV2';
import Loading from './Loading';

const ConfirmationPopUp = ({
	show,
	showCallback,
	callback,
	title,
	subtitle,
	children,
	backText,
	disabled,
	confirmText,
	version,
	isLoading,
}) => {
	return (
		<Modal show={show} onHide={showCallback} centered animation={false}>
			<Row noGutters className="my-5 px-2 justify-content-center text-center">
				<Col>
					<H2>{title}</H2>
					<PSansText className="mt-1">{subtitle}</PSansText>
					{children}
				</Col>
			</Row>
			<Row noGutters className="justify-content-around my-3 mb-4">
				<ModalButton version="light" onClick={showCallback}>
					{backText || `Cancel`}
				</ModalButton>
				<ModalButton
					version={version || 'dark'}
					disabled={disabled}
					onClick={callback}
				>
					{isLoading && <Loading />}
					{!isLoading && (confirmText || `Confirm`)}
				</ModalButton>
			</Row>
		</Modal>
	);
};

export default ConfirmationPopUp;
