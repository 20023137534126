import useCurrentTeam from './useCurrentTeam';

const useFlexPot = () => {
	const team = useCurrentTeam();
	let flexPot = null;
	const flexPotTeams = {
		1049: 'Wellness',
		1121: 'Wellness',
		1122: 'Wellness',
		1195: 'Wellbeing',
	} as any;
	const flexPotName = flexPotTeams[team.id];
	if (flexPotName) {
		flexPot =
			(team.teamMember.subStatus === 'active' &&
				team.budgets.find(({ name }: any) => name.indexOf(flexPotName) !== -1)
					?.id) ||
			-1;
	}
	return flexPot;
};

export default useFlexPot;
