import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
	CompositeDecorator,
	convertFromRaw,
	EditorState,
	RawDraftContentState,
	Editor,
	ContentBlock,
	ContentState,
} from 'draft-js';
import { useCurrentTeam } from '../../hooks';
import BenefitsContainer, {
	icons,
} from '../../styles/shared/Benefits/BenefitsContainer';
import Loading from '../../components/utilities/Loading';
import Label from '../../styles/shared/Label/Label';
import Button from '../../styles/shared/Button/Button';
import { Paragraph } from '../../styles/StylesV3Main';
import { useUserContext } from '../../contexts/userContext';

const CircleArrow = styled(Link)`
	width: 48px;
	height: 48px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.5s ease;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

const Row = styled.div`
	margin-right: 0;
	margin-left: 0;
	> div {
		padding: 0;
	}
`;

const StyledContainer = styled.div`
	width: 100%;
	height: auto;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	padding: 40px;
	margin-left: 0px;
`;

const StyledHeader = styled.h2`
	font-weight: 700;
	font-size: 24px;
	line-height: 28.8px;
	margin-bottom: 32px;
`;

const StyledHeaderOne = styled.h1`
	font-size: 32px;
	font-weight: 700;
	line-height: 38.4px;
	margin-bottom: 24px;
	margin-top: 32px;
`;

const SmallHeading = styled.h6`
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #5b6166;
	margin-top: 32px;
`;

const TwoColumnContainer = styled.div`
	display: grid;
	grid-template-columns: 55% 45%;
	grid-gap: 20px;
	.parsed-description {
		a {
			text-decoration: underline;
			font-weight: 700;
			color: #191a1c;
		}

		h2 {
			font-family: 'Roobert Bold';
			font-style: normal;
			font-size: 24px;
			line-height: 29px;
		}
		h3 {
			font-family: 'Roobert Bold';
			font-style: normal;
			font-size: 20px;
			line-height: 24px;
		}
		h4 {
			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-size: 18px;
			line-height: 24px;
		}
		h5 {
			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-size: 14px;
			line-height: 17px;
		}
		h6 {
			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
		}

		p {
			font-family: 'Roobert Regular';
			font-size: 16px;
			line-height: 160%;
			font-weight: 400;
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.borderLine {
		padding-left: 20px;
		border-left: 1px solid rgba(0, 0, 0, 0.05);
	}
	@media (max-width: 900px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));

		.borderLine {
			border: none;
			padding: 0;
		}
	}
`;

const FlexDiv = styled.div`
	display: flex;
`;

const EditorContainer = styled.div`
	font-family: 'Roobert Regular';
	font-size: 20px;
	line-height: 28.8px;

	h5 {
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-size: 20px;
		line-height: 17px;
	}

	a,
	a:hover {
		font-style: normal;
		font-size: 20px;

		line-height: 28.8px;
		text-decoration: underline;
		color: unset;
	}
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 32px 0;
`;

const BenefitDetails = ({ match, benefits, loading }: any) => {
	const currentTeam = useCurrentTeam();

	const [otherBenefits, setOtherBenefits] = useState<
		| {
				id: number;
				title: string;
				description: RawDraftContentState;
				type: string;
		  }[]
		| undefined
	>();

	const [benefitContent, setBenefitContent] = useState<
		| {
				id: number;
				title: string;
				description: RawDraftContentState;
				type: string;
				eligibility: string;
				signUpSteps: RawDraftContentState | null;
				signUpUrl: string;
		  }
		| undefined
	>();

	const { userData } = useUserContext();

	const benefitType = (type: string) => {
		if (type) {
			const transformType = type.toLowerCase();
			return transformType.replace(/\s/g, '');
		}
		return '';
	};

	const benefitsFromApi = async (id: number) => {
		if (benefits) {
			const filteredBenefits = benefits.filter(
				(benefit: {
					id: number;
					title: string;
					description: RawDraftContentState;
					type: string;
				}) => {
					return benefit.id !== +id;
				}
			);

			const [benefitContentApi] = benefits.filter(
				(benefit: {
					id: number;
					title: string;
					description: RawDraftContentState;
					type: string;
					eligibility: string;
					signUpSteps: RawDraftContentState | null;
					signUpUrl: string;
				}) => {
					return benefit.id === +id;
				}
			);

			setOtherBenefits(filteredBenefits);
			setBenefitContent(benefitContentApi);
		}
	};

	useEffect(() => {
		benefitsFromApi(match?.params?.benefitId);
	}, [match?.params?.benefitId, benefits]);

	const editorLink = ({ contentState, entityKey, children }: any) => {
		const { url } = contentState.getEntity(entityKey).getData();

		return <a href={url}>{children}</a>;
	};

	const findLinkEntities = (
		contentBlock: ContentBlock,
		callback: any,
		contentState: ContentState
	) => {
		contentBlock.findEntityRanges((character) => {
			const entityKey = character.getEntity();
			return (
				entityKey !== null &&
				contentState.getEntity(entityKey).getType() === 'LINK'
			);
		}, callback);
	};

	const decorators = [{ strategy: findLinkEntities, component: editorLink }];

	const descriptionContentState =
		benefitContent && convertFromRaw(benefitContent.description);
	const descriptionState =
		benefitContent &&
		EditorState.createWithContent(
			descriptionContentState!,
			new CompositeDecorator(decorators)
		);

	const signUpStepsContentState =
		benefitContent?.signUpSteps && convertFromRaw(benefitContent.signUpSteps);
	const signUpStepsState =
		signUpStepsContentState &&
		EditorState.createWithContent(
			signUpStepsContentState,
			new CompositeDecorator(decorators)
		);

	return (
		<StyledContainer>
			<Row>
				<FlexDiv>
					<CircleArrow to="/account-settings/benefits">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
								fill="#191A1C"
							/>
						</svg>
					</CircleArrow>
				</FlexDiv>
			</Row>
			{loading || !benefitContent ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '50vh',
						width: '100%',
						textAlign: 'center',
					}}
				>
					<Loading />
				</div>
			) : (
				<>
					<Row>
						<StyledHeaderOne>{benefitContent.title}</StyledHeaderOne>
					</Row>
					<Row>
						<TwoColumnContainer>
							<EditorContainer>
								<Editor
									editorState={descriptionState!}
									onChange={() => {}}
									readOnly
								/>
							</EditorContainer>

							<div>
								<div className="borderLine">
									<FlexDiv>
										<Label
											size="small"
											backgroundColor={
												icons[benefitType(benefitContent.type)]?.backgroundColor
											}
											fontColor={icons[benefitType(benefitContent.type)]?.color}
											radius="rounded"
										>
											{icons[benefitType(benefitContent.type)]?.icon}{' '}
											{benefitContent.type}
										</Label>
									</FlexDiv>
									{benefitContent.type === 'Juno' &&
									!benefitContent.eligibility ? (
										<Row>
											<SmallHeading>Eligibility</SmallHeading>
											<Paragraph>
												Everyone working fulltime at {currentTeam.name} receives{' '}
												{currentTeam.budget} Juno Points per month
											</Paragraph>
										</Row>
									) : (
										benefitContent.eligibility && (
											<Row>
												<SmallHeading>Eligibility</SmallHeading>
												<Paragraph>{benefitContent.eligibility}</Paragraph>
											</Row>
										)
									)}

									{signUpStepsState && (
										<Row style={{ marginBottom: '32px' }}>
											<SmallHeading>Sign up</SmallHeading>
											<EditorContainer>
												<Editor
													editorState={signUpStepsState}
													onChange={() => {}}
													readOnly
												/>
											</EditorContainer>
										</Row>
									)}
									{benefitContent.signUpUrl && (
										<a
											rel="noreferrer"
											target="_blank"
											href={`//${benefitContent.signUpUrl}`}
										>
											<Button
												state="default"
												size="small"
												buttonType="secondary"
											>
												Sign up
											</Button>
										</a>
									)}
								</div>
							</div>
						</TwoColumnContainer>
					</Row>
					<StyledHr />
					{!!otherBenefits?.length && (
						<Row>
							<StyledHeader>Other Benefits</StyledHeader>
							<BenefitsContainer
								benefits={otherBenefits}
								user="employee"
								handleUpdate={() => {}}
								openEditModal={() => {}}
								openAddModal={() => {}}
							/>
						</Row>
					)}
				</>
			)}
		</StyledContainer>
	);
};

export default BenefitDetails;
