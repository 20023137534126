import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	Circle,
	Container,
	BackgroundWrapper,
	OrderContainer,
	DashHorizontal,
	BorderedCircle,
	RowView,
} from '../../styles/ProductStyles';
import { MdArrowBack, MdCheck } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import {
	H1,
	H3,
	H5,
	ParagraphGrey,
	H5TextSecondary,
	Paragraph,
	Separator,
	Small,
} from '../../styles/StylesV3Main';
import { PrimaryButton } from '../../styles/Buttons';
import CustomTitle from './CustomProduct/CustomTitle';

import ConfirmationPopUp from '../utilities/ConfirmationPopUp';
import { useUserContext } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import config from '../../config';

import OrderInformation from './OrderInformation';

const TitleRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 560px;
	height: fit-content;
	border-radius: 3px;
	margin: 32px 0px;
	@media (max-width: 992px) {
		width: 100%;
	}
`;

const WRow = styled(Row)`
	width: 100%;
`;

const StyledInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 32px 0px;
	width: 100%;
	height: fit-content;
	border-top: ${(props) =>
		props.border
			? `1px solid ${props.theme.mainColors.color.separator}`
			: 'none'};
	margin: 0px;

	@media (max-width: 992px) {
		padding: 16px 0px;
	}
`;

const Text1 = styled(Small)`
	margin-right: 48px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: -160px;
	}
`;
const Text2 = styled(Small)`
	margin-right: 40px;
	margin-left: 34px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: -69px;
	}
`;
const Text3 = styled(Small)`
	margin-left: 48px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: 38px;
	}
`;

const OrderResultPage = ({
	product,
	service,
	cost,
	fee,
	options,
	booking,
	type,
	cardType,
	handleCancel,
	enquiry,
}) => {
	const { userData, cancelBookingUser } = useUserContext();

	const history = useHistory();

	const [message, setMessage] = useState();
	const [showModal, setShowModal] = useState(false);

	const isEnquiry = service?.enquire || enquiry;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<BackgroundWrapper>
				<Container>
					<Row noGutters className="justify-content-start align-items-start">
						<Circle size={'48px'} onClick={() => handleCancel()}>
							<MdArrowBack size={20} />
						</Circle>
					</Row>
					<Row noGutters className="justify-content-center align-items-center">
						{type ? (
							<CustomTitle type={type} cardType={cardType} service={service} />
						) : isEnquiry ? (
							<TitleRow>
								<Circle size={'80px'} bg={'#D6BBF7'} className="mb-4">
									<MdCheck size={40} style={{ mixBlendMode: 'overlay' }} />
								</Circle>
								<H1 className="mb-1">We’ve received your enquiry</H1>
								<ParagraphGrey className="text-center">
									We will get back to you as soon as possible.
								</ParagraphGrey>
							</TitleRow>
						) : (
							<TitleRow>
								<Row
									noGutters
									className="justify-content-center align-items-center"
								>
									<Col className="justify-content-center align-items-center">
										<Circle size={'32px'} bg={'black'} />
									</Col>
									<Col className="justify-content-center align-items-center">
										<DashHorizontal />
									</Col>

									<Col className="justify-content-center align-items-center">
										<BorderedCircle
											size={'32px'}
											borderColor={'black'}
											borderWidth={'4px'}
										>
											<Circle size={'16px'} bg={'black'} />
										</BorderedCircle>
									</Col>
									<Col className="justify-content-center align-items-center">
										<DashHorizontal bg={'rgba(0, 0, 0, 0.1)'} />
									</Col>

									<Col className="justify-content-center align-items-center">
										<BorderedCircle
											size={'32px'}
											borderColor={'rgba(0, 0, 0, 0.1)'}
											borderWidth={'4px'}
										/>
									</Col>
								</Row>
								<Row
									noGutters
									className="justify-content-between align-items-center mt-1 mb-4"
									style={{ position: 'relative' }}
								>
									<Text1>Order placed</Text1>

									<Text2>Processing</Text2>

									<Text3>Confirmed</Text3>
								</Row>
								<H1 className="mb-1 text-center">Processing your order</H1>
								<ParagraphGrey className="text-center">
									A confirmation email is on its way to {userData.email}. Please
									note, some orders take some time to process. We will update
									you via email.
								</ParagraphGrey>
							</TitleRow>
						)}
					</Row>
					<Row noGutters className="justify-content-center align-items-center">
						<OrderContainer>
							<OrderInformation
								product={product}
								service={service}
								options={options}
								type={type}
							/>
							{type && fee !== 0 && (
								<>
									<Col xs={12} className="px-0 py-4 mt-2">
										<Separator />
									</Col>
									<Col xs={12} className="px-0 py-0">
										<RowView
											border={false}
											className="align-items-center"
											style={{
												padding: 0,
											}}
										>
											<H5TextSecondary>
												{type === 'card'
													? 'Fee'
													: type === 'concierge'
													? 'Concierge Fee (only for completed orders)'
													: type === 'reimbursement'
													? 'Fee (only for completed expenses)'
													: ''}
											</H5TextSecondary>
											<H3>{`${fee || service.fee.amount} points`}</H3>
										</RowView>
									</Col>
								</>
							)}

							{isEnquiry ? (
								<>
									<Row
										noGutters
										as={StyledInfoRow}
										border={!type && !fee !== 0}
										style={{ alignItems: 'center' }}
									>
										<H5TextSecondary>Total</H5TextSecondary>
										<H1>Upon enquiry</H1>
									</Row>
								</>
							) : (
								<>
									<Row
										noGutters
										as={StyledInfoRow}
										border={!type && !fee !== 0}
										style={{ alignItems: 'center' }}
									>
										<H5TextSecondary>Total</H5TextSecondary>
										<H1>{fee > 0 ? cost + fee : cost} Points</H1>
									</Row>
								</>
							)}

							<WRow noGutters>
								{/* <Col className="px-0 py-0 mb-3"> */}
								<PrimaryButton
									width={'100%'}
									height={'64px'}
									className="mb-3"
									onClick={() => {
										history.push('/');
									}}
								>
									Done
								</PrimaryButton>
								{/* </Col>
                <Col className="px-0 py-0"> */}
								{!message && (
									<PrimaryButton
										width={'100%'}
										height={'64px'}
										cancelButton
										onClick={() => setShowModal(true)}
									>
										{type
											? type === 'card'
												? cardType === 'createCard'
													? 'Cancel Order'
													: 'Cancel Top-up'
												: type === 'concierge'
												? 'Cancel Enquiry'
												: type === 'reimbursement'
												? 'Cancel Expense'
												: 'Cancel Order'
											: isEnquiry
											? 'Cancel Enquiry'
											: 'Cancel Order'}
									</PrimaryButton>
								)}
								{message && <H5 className="py-2">{message}</H5>}
								{/* </Col> */}
							</WRow>
						</OrderContainer>
					</Row>
				</Container>
			</BackgroundWrapper>
			<ConfirmationPopUp
				show={showModal}
				showCallback={() => {
					setShowModal(false);
				}}
				callback={() => {
					const result = cancelBookingUser(booking);
					if (result.status === 'failure') {
						setMessage(result.message);
					} else {
						setMessage('Your order has been cancelled.');
					}
					setShowModal(false);
				}}
				backText="Close"
				title={isEnquiry ? 'Cancel enquiry' : 'Cancel order'}
			>
				<Paragraph className="mt-2">
					Are you sure you want to cancel your {isEnquiry ? 'enquiry' : 'order'}
					?
				</Paragraph>
			</ConfirmationPopUp>
		</>
	);
};

export default OrderResultPage;
