import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { H5, H5TextSecondary } from '../../StylesV3Main';
import Button from '../Button/Button';

type MenuProps = {
	selected: string;
	setSelected: (selected: string) => void;
	options: { key: string; value: string }[];
	text: string;
	className?: string;
	children?: any;
};

const Menu = ({
	selected,
	options,
	setSelected,
	text,
	className,
	children,
}: MenuProps) => {
	const [current, setCurrent] = useState(selected);
	const [open, setOpen] = useState(false);
	const menu = useRef<HTMLUListElement | any>(null);
	const menuButton = useRef<HTMLButtonElement | any>(null);

	useEffect(() => {
		const close = ({ type, target }: any) => {
			if (
				(type === 'click' && !menu.current?.parentNode.contains(target)) ||
				type === 'resize'
			) {
				setOpen(false);
			}
		};
		document.addEventListener('click', close);
		window.addEventListener('resize', close);
		return () => {
			document.removeEventListener('click', close);
			window.removeEventListener('resize', close);
		};
	});
	useLayoutEffect(() => {
		menu.current?.querySelector('.current')?.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
	});
	let top = menuButton.current?.offsetHeight;
	let left = 0;
	let parent = menuButton.current;
	while (parent && getComputedStyle(parent).position !== 'absolute') {
		top += parent.offsetTop;
		left += parent.offsetLeft;
		parent = parent.offsetParent;
	}
	return (
		<div className={className}>
			<StyledButton
				ref={menuButton}
				buttonType="secondary"
				state="default"
				size="small"
				type="button"
				icon={!children ? MdOutlineKeyboardArrowDown : undefined}
				onClick={() => {
					setOpen(!open);
					setCurrent(selected);
					setTimeout(() => {
						menuButton.current?.focus();
					}, 0);
				}}
				onKeyDown={(e) => {
					const { key } = e;
					if (!open) {
						if (key === 'ArrowDown') {
							setOpen(true);
							e.preventDefault();
						}
						return;
					}
					if (key === 'Escape' || key === 'Tab') {
						setOpen(false);
						setCurrent(selected);
					}
					if (key === 'Enter') {
						setSelected(current);
					}
					if (key === 'ArrowDown' || key === 'ArrowUp') {
						e.preventDefault();
						const newCurrent =
							(options.findIndex((option) => option.key === current) +
								1 * (key === 'ArrowDown' ? 1 : -1) +
								options.length) %
							options.length;
						setCurrent(options[newCurrent].key);
					}
				}}
				style={{ width: !children ? '238px' : undefined }}
			>
				{children ||
					`${text} ${options.find(({ key }) => key === selected)?.value}`}
			</StyledButton>
			{open && (
				<StyledList ref={menu} style={{ top, left }}>
					{options.map((option) => (
						<ListItem
							key={option.key}
							onMouseOver={() => setCurrent(option.key)}
							onClick={() => {
								setSelected(current);
								setOpen(false);
							}}
							current={current === option.key}
						>
							{current === option.key ? (
								<H5 as="p" className="current">
									{option.value}
								</H5>
							) : (
								<H5TextSecondary as="p"> {option.value}</H5TextSecondary>
							)}
						</ListItem>
					))}
				</StyledList>
			)}
		</div>
	);
};

const StyledButton = styled(Button)`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledList = styled.ul`
	position: absolute;
	z-index: 1000;
	display: flex;
	max-height: 250px;
	overflow-y: auto;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px;
	gap: 8px;
	width: 238px;
	background: #ffffff;
	margin-top: 8px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
	border-radius: 3px;
`;

const ListItem = styled.li<{ current: boolean }>`
	height: 38px;
	background: white;
	box-sizing: border-box;
	list-style-type: none;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px;
	gap: 10px;
	width: 222px;
	cursor: default;
	background: ${({ current }) => (current ? 'rgba(0, 0, 0, 0.03)' : 'unset')};

	> p {
		margin-top: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&:hover {
		background: rgba(0, 0, 0, 0.03);
		border-radius: 3px;
	}
`;

export default Menu;
