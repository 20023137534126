import React, { useRef, useLayoutEffect } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { H5TextSecondary, ExtraSmall } from '../../StylesV3Main';

const CostSlider = muiStyled(Slider)(() => ({
	color: 'black',
	height: 3,
	padding: '13px 0',
	'& .MuiSlider-thumb': {
		height: 14,
		width: 14,
		backgroundColor: '#000',
		webkitBoxShadow: 'none',
		boxShadow: 'none',
		opacity: 1,
	},
	'& .MuiSlider-thumb:hover': {
		webkitBoxShadow: 'none',
		boxShadow: 'none',
	},
	'& .MuiSlider-thumb:focus': {
		webkitBoxShadow: 'none',
		boxShadow: 'none',
	},
	'& .MuiSlider-thumb:active': {
		webkitBoxShadow: 'none',
		boxShadow: 'none',
	},
	'&..Mui-focusVisible': {
		webkitBoxShadow: 'none',
		boxShadow: 'none',
	},
	'& .MuiSlider-track': {
		webkitBoxShadow: 'none',
		height: 3,
	},
	'& .MuiSlider-rail': {
		webkitBoxShadow: 'none',
		color: '#ededed',
		height: 3,
	},
}));

type CostFilterProps = {
	value: [number | string, number | string];
	setValue: (value: [number | string, number | string]) => void;
	maxValue: number;
};

const CostFilter = ({ value, setValue, maxValue }: CostFilterProps) => {
	const slider = useRef<HTMLElement | null>(null);
	useLayoutEffect(() => {
		slider.current
			?.querySelectorAll('input')[1]
			.setAttribute('autofocus', 'true');
	}, []);
	return (
		<>
			<Label>Cost in points</Label>
			<CostSlider
				ref={slider}
				components={{ Thumb: SliderThumb }}
				getAriaLabel={(index: any) =>
					index === 0 ? 'Minimum cost' : 'Maximum cost'
				}
				min={0}
				step={10}
				max={maxValue}
				value={[+value[0], +value[1] || 0]}
				onChange={(_event, newValue) => setValue(newValue as [number, number])}
				valueLabelDisplay="auto"
			/>
			<PointsDisplay>
				<H5TextSecondary>0</H5TextSecondary>
				<H5TextSecondary>{maxValue}</H5TextSecondary>
			</PointsDisplay>

			<MinMaxContainer>
				<Box style={{ marginRight: 16 }}>
					<label>
						<Text>Minimum</Text>
						<Number
							type="number"
							value={value[0] || 0}
							min="0"
							max={value[1]}
							step={1}
							required
							onChange={({ currentTarget }) =>
								setValue([currentTarget.value, value[1]])
							}
						/>
					</label>
				</Box>
				<Box>
					<label>
						<Text>Maximum</Text>
						<Number
							type="number"
							value={value[1] || 0}
							min={value[0]}
							max={maxValue}
							step={1}
							required
							onChange={({ currentTarget }) =>
								setValue([value[0], currentTarget.value])
							}
						/>
					</label>
				</Box>
			</MinMaxContainer>
		</>
	);
};

const Label = styled.label`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 16.8px;
	color: ${({ theme }) => theme.mainColors.color.textSecondary}!important;
`;

const PointsDisplay = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const MinMaxContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 4px 16px;
	width: 112px;
	height: 56px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
`;

const Text = styled(ExtraSmall)`
	font-family: 'Roobert Medium';
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

export const Number = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	width: 100%;
	background: transparent;
	color: #191a1c;
	line-height: 160%;
	min-width: 33px;
	font-variant-numeric: tabular-nums;
	border: 0;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&focus: {
		border: revert;
	}
`;

export default CostFilter;
