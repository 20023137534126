import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 15px;
	min-height: 60px;
	background-color: #faefe9;
	border-radius: 5px;
	align-items: center;
	text-align: center;
	margin: 2vw 2vw 2vw 2vw;
`;

const StyledHeading = styled.h3`
	font-family: 'AT Surt DemiBold';
	font-size: 1.25rem;
	line-height: 120%;
	display: flex;
	color: ${(props) => (props.variant === 'danger' ? '#FF580C' : 'black')};
`;

const StyledText = styled.p`
	font-family: 'AT Surt DemiBold';
	font-size: 1rem;
	line-height: 120%;
	display: flex;
	color: ${(props) => (props.variant === 'danger' ? '#FF580C' : 'black')};
`;

const Alert = ({ heading, message, variant }) => {
	return (
		<>
			{message !== '' && (
				<StyledContainer>
					<StyledHeading className="my-2 ml-4" variant={variant}>
						{heading}
					</StyledHeading>
					<StyledText variant={variant}>{message}</StyledText>
				</StyledContainer>
			)}
		</>
	);
};

export default Alert;
