import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
	StyledH1,
	StyledH2,
	StyledPFancy,
	StyledDivider,
} from '../styles/MainStyles';
import { ReactComponent as Logo } from '../logo.svg';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled.div`
	background-color: ${(props) =>
		props.background
			? props.theme.colors.mainColor
			: props.theme.colors.secundaryColor};
`;

const StyledP = styled.p`
	font-family: 'AT Surt DemiBold';
	font-size: 1rem;
	margin: 0px;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 152px 80px;
	max-width: 1250px;
	@media (max-width: 992px) {
		margin: 0px 20px 40px;
		max-width: 100%;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 88px;
	width: 100%;
	margin-bottom: 80px;
	border-bottom: 2px solid rgba(0, 0, 0, 0.05);
	max-width: 1250px;
	@media (max-width: 992px) {
		margin-bottom: 40px;
		max-width: 100%;
	}
`;

const TermsConditionView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 752px;
	@media (max-width: 992px) {
		width: 100%;
	}
`;

const PrivacyPolicy = () => {
	const history = useHistory();

	return (
		<MainContainer>
			<LogoContainer>
				<Logo />
			</LogoContainer>
			<Container>
				<TermsConditionView>
					<StyledContainer>
						<Row noGutters>
							<Col>
								<StyledH1>Privacy Policy</StyledH1>
							</Col>
						</Row>
						<StyledDivider />
						<Row noGutters>
							<Col>
								<StyledPFancy>Last updated: February 12, 2020</StyledPFancy>
								<StyledH2 className="py-3">
									Hello, and welcome to our Privacy Policy.
								</StyledH2>
								<StyledP className="py-1">
									This Privacy Policy helps you to make informed decisions about
									your relationship with us, so please read it carefully. We
									hope you are sitting in a comfortable setting. So here we go …
								</StyledP>
								<StyledH2 className="py-3">1. Introduction</StyledH2>
								<StyledP className="py-1">
									Thanks for choosing to subscribe to our platform! To provide
									you with the Juno Service (or “Service” both as defined in the
									Terms and Conditions of User), With Juno Limited (‘Juno”,
									“us”, “we”, or “our”) needs to collect some information about
									you. This Privacy Policy is where we describe the information
									we collect, what we do with it and how we manage and control
									the use of your information. By using or interacting with Juno
									Service, you are entering into a binding contract with us and
									agreeing to the use of your information as explained in the
									Privacy Policy. If you don’t agree with these terms, then
									please don’t use the Service.
								</StyledP>
								<StyledH2 className="py-3">
									2. The Information we collect
								</StyledH2>
								<StyledP className="py-1">
									a) Information gathered and why we need it
									<br />
									When you use the Service, we may collect various information,
									as detailed in the Usage log data & cookies section. We
									require this information to create and maintain user accounts
									and monitor unauthorised behaviour. We may also track how you
									are using the Service for the express purpose of supporting
									our product development and improving the Service experience.
									<br />
									For this data collection, we rely on both internal system
									complying with the terms of this Privacy Policy; and on
									third-parties applications, namely: Google Analytics, Mailjet,
									Hotjar and Stripe. We invite you to read their Privacy Policy
									to better understand how they deal with the data gathered on
									our behalf.
									<br />
									<br />
									b) Usage log data and cookies
									<br />
									When you use the Service, we automatically collect certain
									information, including: (i) personally-identifiable
									information on registration; (ii) the details of the queries
									you make; (iii) your interactions with the Service, including
									bookings, Third Party content (as defined in the Terms &
									Conditions) and products and services which are offered,
									linked or made available on the Service; (iv) technical data,
									which may include the URL you are coming from, your IP
									address, unique device ID, MAC address or the Device’s
									wireless network interface or mobile number used by the
									Device, mobile network information, language and identifying
									information, operating system, and Juno application version;
									(v) location information: We may store this information so
									that it can be used in Section 3.
									<br />
									<br />
									c) Payment data
									<br />
									When you make purchases through the Service, Juno does not
									collect your personal payment details. Nonetheless, if you
									sign up for a subscription through the Service, your credit or
									debit card information (such as card type and expiration date)
									and other financial data that we need to process your payment
									may be collected and stored by the payment processors with
									which we work. In addition, the payment processors generally
									provide us with some limited information related to you, such
									as a unique token that enables you to make additional
									purchases using the information they’ve stored, and your
									card’s type, expiration date, and certain digits of your card
									number. We may be able to collect data on how many purchases
									were made with certain devices or in which countries.
								</StyledP>
								<StyledH2 className="py-3">
									3. How we use the information we collect.
								</StyledH2>
								<StyledP className="py-1">
									We may use the information we collect, including your personal
									information to: (i) provide, personalise and improve your
									experience with the Service and products and services made
									available through the Service; (ii) ensure technical
									functioning of the Service and products and services made
									available through the Service, develop new products and
									services, and analyse your use of the Service, including your
									interaction with the application, products and services that
									are made available, linked to or offered in the Service; (iii)
									communicate with you for Service-related purposes; (iv) enable
									and promote the Service, including features and content of the
									Service and products and services made available through the
									Service, such as notifications, suggestions, and integration
									with third party services; (v) enforce this Privacy Policy and
									the Terms and Conditions of Use.
									<br />
									By using the Service, you agree to the collection and use of
									information in accordance with this policy. Unless otherwise
									defined in this Privacy Policy, terms used in this Privacy
									Policy have the same meanings as in our Terms of Services.
								</StyledP>
								<StyledH2 className="py-3">
									4. How we share the information we collect.
								</StyledH2>
								<StyledP className="py-1">
									Except as described in this Policy, we will not disclose the
									Personal Information that we collect or store on the Service
									to third parties without the consent of the applicable User.
									We may disclose information to third parties if you consent to
									us doing so, as well as in the following circumstances:
									<br />
									We will not use or share your information with anyone except
									as described in this Privacy Policy.
									<br />
									We work with third party service providers who provide
									website, application development, hosting, maintenance, and
									other services for us. These third parties may have access to,
									or process Personal Information as part of providing those
									services for us. We limit the information provided to these
									service providers to that which is reasonably necessary for
									them to perform their functions, and our contracts with them
									require them to maintain the confidentiality of such
									information.
								</StyledP>
								<StyledH2 className="py-3">5. Cookies</StyledH2>
								<StyledP className="py-1">
									Cookies are files with small amounts of data, which may
									include an anonymous unique identifier. Cookies are sent to
									your browser from a website, such as Facebook and stored on
									your computer hard drive. We use cookies to distinguish you
									from other users of the platform. This helps us to provide you
									with a good experience and also allows us to improve the
									Services.
								</StyledP>
								<StyledH2 className="py-3">6. Communications</StyledH2>
								<StyledP className="py-1">
									Upon agreeing to receiving news and offers by email (Marketing
									communication opt-in), we may use your Personal Information to
									contact you with newsletters, marketing or promotional
									materials and other information that may be of interest to
									you. You may opt out of receiving any, or all, of these
									communications from us by following the unsubscribe link or
									instructions provided in any email we send.
								</StyledP>
								<StyledH2 className="py-3">7. Compliance With Laws</StyledH2>
								<StyledP className="py-1">
									All processing of Personal Information is performed in
									accordance with privacy rights and regulations following the
									EU Directive 95/46/EC of the European Parliament and of the
									Council of 24 October 1995 (the Directive), and the
									implementations of the Directive in local legislation. From
									May 25th, 2018, the Directive and local legislation based on
									the Directive will be replaced by the Regulations (EU)
									2016/679 of the European Parliament and of the Council of 27
									April 2016 on the protection of natural persons with regard to
									the processing of Personal Data and on the free movement of
									such data, known as the General Data Protection Regulation
									(GDPR), and Juno’s processing will take place in accordance
									with the GDPR.
									<br />
									Juno processes Personal Information both as a Processor and as
									a Controller, as defined in the Directive and the GDPR:
									<br />
									The Juno entity which you as a User entered an agreement with
									when using Juno’s platform, will be the Controller for User
									data, as outlined above in “The Information We Collect”
									section.
									<br />
									We will disclose your Personal Information where required to
									do so by law or in accordance with an order of a court of
									competent jurisdiction, or if we believe that such action is
									necessary to comply with the law and the reasonable requests
									of law enforcement or to protect the security or integrity of
									our Service.
								</StyledP>
								<StyledH2 className="py-3">
									8. Access, Correction, Deletion
								</StyledH2>
								<StyledP className="py-1">
									We respect your privacy rights and provide you with reasonable
									access to the Personal Information that you may have provided
									through your use of the Services. If you wish to access or
									amend any other Personal Information we hold about you, or to
									request that we delete or transfer any information about you
									that we have obtained from an Integrated Service, you may
									contact us as set forth in the “Contact Us” section. At your
									request, we will have any reference to you deleted or blocked
									in our database.
									<br />
									You may update, correct, or delete your Account information
									and preferences at any time by contact us as set forth in the
									“Contact Us” section. Please note that while any changes you
									make will be reflected in active user databases instantly or
									within a reasonable period of time, we may retain all
									information you submit for backups, archiving, prevention of
									fraud and abuse, analytics, satisfaction of legal obligations,
									or where we otherwise reasonably believe that we have a
									legitimate reason to do so.
									<br />
									You may decline to share certain Personal Information with us,
									in which case we may not be able to provide to you some of the
									features and functionality of the Service.
									<br />
									At any time, you may object to the processing of your Personal
									Information, on legitimate grounds, except if otherwise
									permitted by applicable law. If you believe your right to
									privacy granted by applicable data protection laws has been
									infringed upon, please contact us as set forth in the “Contact
									Us” section. You also have a right to lodge a complaint with
									data protection authorities.
								</StyledP>
								<StyledH2 className="py-3">9. Data Retention</StyledH2>
								<StyledP className="py-1">
									We only retain the Personal Information collected from a User
									for as long as the User’s account is active or otherwise for a
									limited period of time as long as we need it to fulfill the
									purposes for which we have initially collected it, unless
									otherwise required by law. We will retain and use information
									as necessary to comply with our legal obligations, resolve
									disputes, and enforce our agreements as follows:
									<br />
									the contents of closed accounts are deleted within 6 months of
									the date of closure; backups are kept for 12 months;
									<br />
									information on legal transactions between Client and Juno is
									retained for a period of 10 years as of their provision to
									Juno
								</StyledP>
								<StyledH2 className="py-3">
									10. Transfer to other countries
								</StyledH2>
								<StyledP className="py-1">
									Juno processes information about our users on servers located
									in other countries. Juno may also subcontract processing to or
									share your information with third parties located in
									countries, other than your home country. Information collected
									within the European Economic Area (“EEA”) and Switzerland may,
									for example, be transferred to, and processed by, companies in
									the Juno group or other third parties located in a country
									outside of the EEA and Switzerland, where you may have fewer
									rights in relation to your information. However, Juno only
									processes your information as described on this Privacy Policy
									in every country. You expressly consent to your information
									being used as set out in this section 7. In the event that a
									dispute arises with regards to the international transfer of
									data, you agree that the courts of England and Wales shall
									have exclusive jurisdiction over the matter.
								</StyledP>
								<StyledH2 className="py-3">11. Links To Other Sites</StyledH2>
								<StyledP className="py-1">
									Our Service may contain links to other sites that are not
									operated by us. If you click on a third party link, you will
									be directed to that third party’s site. We strongly advise you
									to review the Privacy Policy of every site you visit.
									<br />
									We have no control over, and assume no responsibility for, the
									content, privacy policies or practices of any third party
									sites or services.
								</StyledP>
								<StyledH2 className="py-3">12. Security</StyledH2>
								<StyledP className="py-1">
									We are committed to protecting our users’ information. Your
									password protects your user account, so you should use a
									unique and strong password. While we take reasonable data
									protection precautions, no security measures are 100% secure,
									and we do not guarantee the security of user information at
									any time.
								</StyledP>
								<StyledH2 className="py-3">13. Children</StyledH2>
								<StyledP className="py-1">
									Our Service does not address anyone under the age of 13
									(“Children”). We do not knowingly collect personally
									identifiable information from Children under 13. If you are a
									parent or guardian and you are aware that your Children have
									provided us with Personal Information, please contact us. If
									we become aware that we have collected Personal Information
									from a child under age 13 without verification of parental
									consent, we take steps to remove that information from our
									servers.
								</StyledP>
								<StyledH2 className="py-3">
									14. Changes to the Privacy Policy
								</StyledH2>
								<StyledP className="py-1">
									We may update our Privacy Policy from time to time, so please
									visit the Privacy Policy regularly.
									<br />
									If we make any material changes to this Privacy Policy, we
									will notify you either through the email address you have
									provided us, or through the Service. Your continued use of the
									Service thereafter constitutes acceptance of the changes.
								</StyledP>

								<StyledH2 className="py-3">15. Contact us</StyledH2>
								<StyledP className="py-1">
									To learn more about the information we store about you,
									correct any information we have about you, request we delete
									information we store about you or if you have any questions or
									comments about this Privacy Policy, please contact us at
									support@withjuno.com. We will respond within a reasonable
									period of time upon verification of your identity.
								</StyledP>
							</Col>
						</Row>
					</StyledContainer>
				</TermsConditionView>
			</Container>
		</MainContainer>
	);
};

export default PrivacyPolicy;
