import React, { useState } from 'react';
import styled from 'styled-components';
import CardImageRight from '../../../components/junoCard/card-logo-right.svg';
import Loading from '../../../components/utilities/Loading';
import ActionButton from '../ActionButton';
import CardDetailsPopup from '../CardDetail';
import PinPopup from '../Pin';
import JunoLogo from './Juno.png';

const Card = ({
	balance,
	card,
	open,
	pinOpen,
	setOpen,
	setPinOpen,
	updateCard,
}: {
	balance?: string;
	card?: CardDetails;
	open: boolean;
	pinOpen: boolean;
	setOpen: (status: boolean) => void;
	setPinOpen: (status: boolean) => void;
	updateCard: (active: boolean) => Promise<void>;
}) => {
	const [loading, setLoading] = useState(false);
	return !card ? (
		<LoadingContainer>
			<Loading />
		</LoadingContainer>
	) : (
		<CardContainer type={card?.type}>
			<div>
				<CardType>
					<img style={{ marginRight: '8px' }} src={JunoLogo} alt="junologo" />
					{card.type === 'physical' && 'Physical '}Flex Card®
				</CardType>
				<CardNumber>{`•••• •••• •••• ${card.last4}`}</CardNumber>
				<CardName>{card.cardholder}</CardName>
			</div>
			<div style={{ flex: 1 }}>
				<CardActions>
					<div>
						<h3>Status</h3>
						<Status active={!!card.active}>
							{card.active ? 'Active' : 'Inactive'}
						</Status>
					</div>
					<div>
						<h3>Available balance</h3>
						<p>{balance}</p>
					</div>
				</CardActions>
				<ActionContainer>
					{card.type === 'physical' ? (
						<ActionButton onClick={() => setPinOpen(true)} text="View PIN" />
					) : (
						<ActionButton
							onClick={() => setOpen(true)}
							text="View Card Details"
						/>
					)}
					<ActionButton
						text={card.active ? 'Deactivate' : 'Activate'}
						color={card.active ? '#cc1440' : '#1cae71'}
						loading={loading}
						onClick={async () => {
							try {
								setLoading(true);
								await updateCard(!card.active);
							} finally {
								setLoading(false);
							}
						}}
					/>
				</ActionContainer>
			</div>
			{open && (
				<CardDetailsPopup open={open} setOpen={setOpen} cardInfo={card} />
			)}
			{pinOpen && (
				<PinPopup pinOpen={pinOpen} setPinOpen={setPinOpen} cardId={card.id} />
			)}
		</CardContainer>
	);
};

export type CardDetails = {
	id: string | null;
	last4: string | null;
	cardholder: string | null;
	active: boolean | null;
	city: string;
	country: string;
	dob: string;
	fullName: string;
	line1: string;
	line2: string;
	phoneNumber: string | null;
	postCode: string;
	budgetId: string;
	cvc: string | undefined;
	expire: string;
	number: string | undefined;
	personalAddress: {
		line1: string;
		line2: string;
		postCode: string;
		city: string;
		country: string;
	};
	type: string;
	isCardOlderThan7Days: boolean | null;
};

const LoadingContainer = styled.div`
	height: 276px;
	width: 608px;
	@media (max-width: 992px) {
		height: 100px;
		width: 100%;
	}
`;

const CardContainer = styled.div<{ type: string }>`
	height: '276px';
	width: 656px;
	background-color: #fff;
	border-radius: 24px;
	padding: 24px;
	margin-top: 40px;
	filter: drop-shadow(0px 12px 16px rgba(226, 203, 214, 0.3));
	display: flex;

	@keyframes slideInFromBottom {
		0% {
			opacity: 0;
			transform: translateY(50%);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	animation: 0.4s ease-in-out 0s 1 slideInFromBottom;

	> div:first-child {
		border-radius: 8px;
		height: 228px;
		width: 360px;
		background: url(${CardImageRight}) no-repeat right bottom;
		background-color: #191a1c;
		padding: 24px;
		color: #fff;
	}
	> div:nth-child(2) {
		margin-left: 25px;
	}
	@media (max-width: 992px) {
		background-color: transparent;
		display: unset;
		filter: unset;

		> div:first-child {
			width: 335px;
			height: 204px;
			max-width: calc(100vw - 48px);
			margin: auto;
		}
		> div:nth-child(2) {
			margin-left: 0;
		}
	}
`;

const CardActions = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 12px 0 6px 0;
	h3 {
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #5b6166;
		margin: 20px 0 0 0;
	}
	h3 + p {
		font-family: 'Roobert Bold';
		font-style: normal;
		font-size: 24px;
		line-height: 29px;
		color: #191a1c;
		margin: 8px 0 12px 0;
	}
	div:first-child h3 {
		display: none;
	}
	@media (max-width: 992px) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin: 0 0px;
		div:first-child h3 {
			display: unset;
		}
		> div {
			display: flex;
			flex-direction: column;
			width: 100%;
			text-align: center;
		}
	}
`;

const CardType = styled.h2`
	font-family: 'Roobert Semi Bold';
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin: 0;
`;

const CardNumber = styled.p`
	font-family: 'Roobert Bold';
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.05em;
	font-variant-numeric: tabular-nums;
	margin: 0;
	padding-top: 61px;
`;

const CardName = styled.p`
	font-family: 'Roobert Semi Bold';
	font-size: 16px;
	line-height: 19px;
	margin: 0;
`;

const Status = styled.div<{ active: boolean }>`
	display: inline-block;
	height: 30px;
	width: min-content;
	padding: 4px 12px;
	margin: auto;
	background-color: ${({ active }) =>
		active ? 'rgba(42, 185, 125, 0.1)' : 'rgba(251, 232, 240)}'};
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	color: ${({ active }) => (active ? '#1cae71' : '#cc1440')};
	@media (max-width: 992px) {
		margin-top: 8px;
	}
`;

const ActionContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-bottom: 0px;
	border-radius: 4px;
	button {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}
`;

export default Card;
