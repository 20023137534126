import React from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import background from '../images/background.png';
import Search from '../images/Search';

const NoTeamMembers = ({ filter }: { filter: boolean }) => {
	const { pathname, search } = useLocation();
	const history = useHistory();
	return (
		<Container>
			<div>
				<Search />
			</div>
			<p>No transactions</p>
			<p>
				{filter &&
					`Your search did not match any transactions. Please try again.`}
			</p>
			<div>
				{filter && (
					<button
						type="button"
						onClick={() => {
							const p = new URLSearchParams(search);
							p.delete('pot');
							p.delete('status');
							history.push(`${pathname}?${p.toString()}`, {
								scroll: false,
							});
						}}
					>
						Clear search
					</button>
				)}
			</div>
		</Container>
	);
};

const Container = styled.div`
	button {
		background: transparent;
		display: flex;
		font-family: 'TTInterfaces DemiBold';
		font-size: 14.5px;
		line-height: 20px;
		color: rgb(52, 64, 84);
		padding: 10px 16px;
		border-radius: 8px;
		border: 1px solid rgb(208, 213, 221) !important;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	button:last-child {
		background: rgb(25, 26, 28);
		color: #fff;
		svg {
			margin-right: 8px;
		}
	}
	background-image: url(${background});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 480px;
	min-height: 326px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 48px 0;
	> div:first-child {
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		border: 1px solid rgb(234, 236, 240);
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}
	> div + p {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		line-height: 24px;
		color: rgb(16, 24, 40);
		padding: 20px 0 0 0;
	}
	> p + p {
		text-align: center;
		font-family: 'TTInterfaces Medium';
		font-size: 15px;
		line-height: 21px;
		color: rgb(71, 84, 103);
		width: 352px;
	}
	> div:last-child {
		display: flex;
		gap: 10px;
	}
`;

export default NoTeamMembers;
