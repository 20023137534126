import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Alert } from 'react-bootstrap';
import {
	H4,
	H5,
	Small,
	SmallTextSecondary,
	Para,
} from '../../../../styles/StylesV3Main';
import { MdOutlineCreditCard, MdAccountBalance } from 'react-icons/md';
import moment from 'moment';
import LongIconMenu from './LongIconMenu';
import ConfirmationPopUpBox from '../../../utilities/ConfirmationPopUpBox';

const LogoSquare = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 40px;
	height: 40px;
	background: #f8f8f8;
	border-radius: 3px;
`;

const StyledCol = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	margin-left: 16px;
`;

const CardDetails = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: 96px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	&:last-child {
		border-bottom: none;
	}
`;

const SetDefaultButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	width: 77px;
	height: 30px;
	background: #e1fddc;
	border-radius: 3px;
	flex: none;
	order: 2;
	flex-grow: 0;
`;

type PaymentMethodDisplayProps = {
	defaultPaymentMethod: () => boolean;
	handleDefaultChange: (pmId: any, teamId?: number) => void;
	deletePaymentMethodTeam: (pm: string, teamId: number) => void;
	teamId: number;
	paymentMethod: any;
};

const PaymentMethodDisplay = ({
	paymentMethod,
	defaultPaymentMethod,
	teamId,
	handleDefaultChange,
	deletePaymentMethodTeam,
}: PaymentMethodDisplayProps) => {
	const [errorMessage, setErrorMessage] = useState('');
	const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);
	const [pm, setPm] = useState<any | null>(null);
	const [processing, setProcessing] = useState(false);

	const capitalizeStrng = (s: string) =>
		s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

	const handleType = (paymentMethod: {
		brand: string;
		type: any;
		last4: any;
		exp_month: any;
		exp_year: any;
		sort_code: any;
		country: string;
		bank_code: string;
		branch_code: string;
	}) => {
		try {
			switch (paymentMethod.type) {
				case 'card':
					return {
						...paymentMethod,
						title: '',
						deleteTitle: 'Delete this card',
						confirmationDelete: 'Are you sure you want to delete this card?',
						lines: [
							{
								title: capitalizeStrng(paymentMethod.brand),

								value: `•••• ` + paymentMethod.last4,
							},
							{
								title: `Expires`,
								value: `${moment()
									.month(paymentMethod.exp_month - 1)
									.format('MMMM')}  ${paymentMethod.exp_year}`,
							},
						],
					};
				case 'bacs_debit':
					return {
						...paymentMethod,
						title: 'Bacs Direct Debit',
						deleteTitle: 'Delete this direct debit',
						confirmationDelete:
							'Are you sure you want to delete this direct debit mandate?',
						lines: [
							{
								title: `•••• `,
								value: paymentMethod.sort_code,
							},
							{
								title: ``,
								value: ``,
							},
						],
					};
				case 'sepa_debit':
					return {
						...paymentMethod,
						title: 'Sepa Direct Debit',
						deleteTitle: 'Delete this direct debit',
						confirmationDelete:
							'Are you sure you want to delete this direct debit mandate?',
						lines: [
							{
								title: `•••• `,
								value: paymentMethod.last4,
							},
							{
								title: ``,
								value: ``,
							},
						],
					};
				default:
					return null;
			}
		} catch (err: any) {
			console.error(err.message);
		}
	};

	useEffect(() => {
		try {
			paymentMethod && setPm(handleType(paymentMethod));
		} catch (err: any) {
			console.error(err.message);
		}
	}, [paymentMethod]);

	if (!pm) {
		return <></>;
	}

	return (
		<>
			<CardDetails>
				<LogoSquare>
					{pm.type === 'card' ? (
						<MdOutlineCreditCard size={20} color="#5B6166" />
					) : (
						<MdAccountBalance size={20} color="#5B6166" />
					)}
				</LogoSquare>

				<StyledCol>
					<H4>
						{pm.title} {pm.lines[0].title} {pm.lines[0].value}
					</H4>

					<SmallTextSecondary>
						{pm.lines[1].title} {pm.lines[1].value}
					</SmallTextSecondary>
				</StyledCol>
				{defaultPaymentMethod() ? (
					<SetDefaultButton>
						<H5>Default</H5>
					</SetDefaultButton>
				) : (
					<LongIconMenu
						handleDefaultChange={() => handleDefaultChange(pm.pmId)}
						handleDelete={() => {
							setShowConfirmationDelete(true);
						}}
					/>
				)}
			</CardDetails>

			{showConfirmationDelete && (
				<ConfirmationPopUpBox
					show={showConfirmationDelete}
					showCallback={() => {
						setShowConfirmationDelete(false);
					}}
					callback={async () => {
						setProcessing(true);
						await deletePaymentMethodTeam(pm.pmId, teamId);
						setProcessing(false);
						setShowConfirmationDelete(false);
					}}
					title={pm.deleteTitle}
					disabled={processing}
				>
					{errorMessage !== '' && (
						<Alert
							variant={'warning'}
							onClose={() => setErrorMessage('')}
							dismissible
						>
							<Small>{errorMessage}</Small>
						</Alert>
					)}
					<Small>{pm.confirmationDelete}</Small>
				</ConfirmationPopUpBox>
			)}
		</>
	);
};

export default PaymentMethodDisplay;
