import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../../styles/shared/Modal';
import ModalBackground from './modal-decorative.png';
import BudgetCard from './BudgetCard';

const ModalBody = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	background-image: url(${ModalBackground});
	background-repeat: no-repeat;
	background-position: 11px -5px;
	background-size: 168px;
	display: flex;
	flex-direction: column;
	padding: 8px 24px 24px 24px;
`;

const HeaderRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TitleRow = styled.div`
	align-items: left;
	padding-bottom: 28px;
`;

const Title = styled.h2`
	font-family: 'TTInterfaces DemiBold';
	font-size: 21px;
	line-height: 28px;
	padding-top: 24px !important;
`;

const SubTitle = styled.h3`
	font-family: 'TTInterfaces Regular';
	font-size: 16px;
	line-height: 20px;
	color: #475467;
`;

const BodyContent = styled.ul`
	flex-wrap: wrap;
	gap: 20px;
	max-width: 1020px;
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
`;
const ButtonRow = styled.div`
	border-top: 1px solid #eaecf0;
	margin-top: 32px;
	padding-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 8px;
`;

const DollarLogo = styled.div`
	display: flex;
	width: 48px;
	height: 48px;
	margin: 0px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid var(--gray-200, #eaecf0);
	background: var(--base-white, #fff);
`;

type AssignBudgetModalProps = {
	isOpen: boolean;
	budgets: any[];
	currency: string;
	onClose: () => void;
	onSubmit: (budgetIds: number[]) => void;
};

const AssignBudgetModal = ({
	isOpen,
	budgets,
	onClose,
	onSubmit,
	currency,
}: AssignBudgetModalProps) => {
	const [selectedBudgetLookup, setSelectedBudgetLookup] = useState<{
		[key: number]: { selected?: boolean };
	}>({});

	const handleToggleBudget = (budgetId: number) => {
		setSelectedBudgetLookup((prevLookup) => {
			const newLookup = prevLookup;

			return {
				...newLookup,
				[budgetId]: {
					selected: !newLookup[budgetId]?.selected,
				},
			};
		});
	};

	const selectedBudgets = Object.entries(selectedBudgetLookup).filter(
		([, value]) => value.selected
	);

	const handleAssignBudgets = () => {
		onSubmit(selectedBudgets.map((budget) => +budget[0]));
		onClose();
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			ariaLabel="Assign budget(s)"
			ariaDescribedBy="Grant specific users access to existing budgets"
		>
			<ModalBody>
				<HeaderRow>
					<DollarLogo>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
						>
							<path
								d="M11.8 11.4C9.53 10.81 8.8 10.2 8.8 9.25C8.8 8.16 9.81 7.4 11.5 7.4C12.92 7.4 13.63 7.94 13.89 8.8C14.01 9.2 14.34 9.5 14.76 9.5H15.06C15.72 9.5 16.19 8.85 15.96 8.23C15.54 7.05 14.56 6.07 13 5.69V5C13 4.17 12.33 3.5 11.5 3.5C10.67 3.5 10 4.17 10 5V5.66C8.06 6.08 6.5 7.34 6.5 9.27C6.5 11.58 8.41 12.73 11.2 13.4C13.7 14 14.2 14.88 14.2 15.81C14.2 16.5 13.71 17.6 11.5 17.6C9.85 17.6 9 17.01 8.67 16.17C8.52 15.78 8.18 15.5 7.77 15.5H7.49C6.82 15.5 6.35 16.18 6.6 16.8C7.17 18.19 8.5 19.01 10 19.33V20C10 20.83 10.67 21.5 11.5 21.5C12.33 21.5 13 20.83 13 20V19.35C14.95 18.98 16.5 17.85 16.5 15.8C16.5 12.96 14.07 11.99 11.8 11.4Z"
								fill="black"
								fillOpacity="0.54"
							/>
						</svg>
					</DollarLogo>
				</HeaderRow>
				<TitleRow>
					<Title>Assign Pot(s)</Title>
					<SubTitle>Grant specific users access to existing pots</SubTitle>
				</TitleRow>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleAssignBudgets();
					}}
				>
					<BodyContent>
						{budgets.map((budget, bIdx) => {
							return (
								<BudgetCard
									currency={currency}
									key={budget.id}
									budget={budget}
									selected={selectedBudgetLookup[budget.id]?.selected || false}
									onToggle={handleToggleBudget}
									invalid={selectedBudgets.length === 0 && bIdx === 0}
								/>
							);
						})}
					</BodyContent>
					<ButtonRow>
						<StyledSecondaryButton onClick={onClose} type="button">
							Cancel
						</StyledSecondaryButton>
						<StyledPrimaryButton type="submit">
							{selectedBudgets.length
								? `Assign ${selectedBudgets.length} pot${
										selectedBudgets.length > 1 ? 's' : ''
								  }`
								: 'Assign pot(s)'}
						</StyledPrimaryButton>
					</ButtonRow>
				</form>
			</ModalBody>
		</Modal>
	);
};

const StyledSecondaryButton = styled.button`
	border-radius: 8px;
	border: 1px solid var(--gray-300, #d0d5dd);
	background: #ffffff;
	color: #344054;
	height: 44px;
	padding: 10px 18px;
	display: flex;
	height: 44px;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	font-family: 'TTInterfaces DemiBold';
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
`;

const StyledPrimaryButton = styled.button`
	border-radius: 8px;
	border: 0px;
	background: #4ca30d;
	color: #ffffff;
	height: 44px;
	padding: 10px 18px;
	display: flex;
	height: 44px;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	font-family: 'TTInterfaces DemiBold';
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
`;

export default AssignBudgetModal;
