/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { MdArrowForward } from 'react-icons/md';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../logo.svg';
import config from '../../../config';

const NavBarContainer = () => {
	const { pathname } = useLocation();
	const [scrolling, setScrolling] = useState(false);

	useEffect(() => {
		const listener = () => {
			setScrolling((document.scrollingElement?.scrollTop || 0) >= 32);
		};
		document.addEventListener('scroll', listener);
		return () => {
			document.removeEventListener('scroll', listener);
		};
	});

	return (
		<NavBar scrolling={scrolling ? 'on' : undefined}>
			<div>
				{pathname !== '/' ? (
					<Link to="/">
						<Logo />
					</Link>
				) : (
					<a href="https://www.withjuno.com">
						<Logo />
					</a>
				)}
				<div
					style={{
						display: 'flex',
						flexWrap: 'nowrap',
						alignItems: 'center',
					}}
				>
					<SignIn href={`${config.web.HOST}/login`} target="_blank">
						Sign in
					</SignIn>
					<GetStarted href="https://www.withjuno.com/sign-up">
						Learn more
						<MdArrowForward size={18} />
					</GetStarted>
				</div>
			</div>
		</NavBar>
	);
};

const NavBar = styled.div<{
	scrolling: string | undefined;
}>`
	padding: 25px 0;
	background: ${({ scrolling }) => {
		return !scrolling ? '#f8f8f8' : '#fff';
	}};
	box-shadow: ${({ scrolling }) =>
		!scrolling ? 'none' : '0px 3px 18px rgba(0, 0, 0, 0.1)'};
	border-bottom: ${({ scrolling }) =>
		!scrolling ? 'none' : '1px solid #e4e4e4'};
	position: sticky;
	top: 0;
	z-index: 2000;
	justify-content: center;
	width: 100%;
	display: flex;
	font-family: 'Roobert Medium';
	font-size: 15px;
	> div {
		max-width: 1250px;
		height: 35px;
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	@media (max-width: 1250px) {
		padding: 25px 48px;
	}
	@media (max-width: 992px) {
		padding: 25px 24px;
	}
`;

const SignIn = styled.a`
	color: #0a0f16;
	text-decoration: none;
	margin-right: 32px;
	white-space: nowrap;
	:hover {
		color: #0a0f16;
		text-decoration: none;
	}
	@media (max-width: 330px) {
		margin-right: 10px;
		font-size: 13.5px;
	}
`;

const GetStarted = styled.a`
	background: #0a0f16;
	backdrop-filter: blur(10px);
	border-radius: 50px;
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 15px;
	line-height: 12px;
	text-align: center;
	color: #fff;
	padding: 10px 18px 10px 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	svg {
		margin-left: 8px;
	}
	:hover {
		color: #fff;
		text-decoration: none;
	}
	@media (max-width: 480px) {
		svg {
			display: none;
		}
	}
	@media (max-width: 330px) {
		padding: 7px 15px;
		font-size: 13.5px;
	}
`;

export default NavBarContainer;
