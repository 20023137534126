/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import Markdown from 'markdown-to-jsx';
import { H1, H5, ParagraphGreyMD } from '../../styles/StylesV3Main';
import {
	Circle,
	Container,
	ReadMoreBtn,
	BackgroundWrapper,
} from '../../styles/ProductStyles';
import config from '../../config';
import ProviderInfo from './ProviderInfo';
import OrderOptionsPage from './OrderOptionsPage';
import ServiceDetail from './ServiceDetail';

const H5TextSecondary = styled(H5)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const ImageContainer = styled.div`
	width: 463px;
	height: 410px;
	object-fit: cover;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-collapse: separate;
	position: relative;
	background: ${({ background }) => background};
	margin-bottom: 48px;
	@media (max-width: 1080px) {
		width: 420px;
	}

	@media (max-width: 992px) {
		width: 100%;
		height: fit-content;
	}
`;

const Image = styled.img`
	width: 100%;
	height: 410px;
	object-fit: cover;
	mix-blend-mode: ${({ overlay }) => overlay && 'overlay'};
`;

const TitleContainer = styled.div`
	height: fit-content;
	width: 560px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin-bottom: 48px;
	@media (max-width: 1200px) {
		width: 480px;
	}

	@media (max-width: 992px) {
		width: 100%;
	}
`;

const ProviderContainer = styled.div`
	width: 100%;
	margin: 0px 133px;
	height: fit-content;
	max-width: 1250px;

	@media (max-width: 992px) {
		margin: 0px 20px;
		max-width: 100%;
	}
`;

const DesktopView = styled.div`
	display: block;
	@media (max-width: 992px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: block;
	}
`;

const ReadMoreDiv = styled.div`
	max-height: ${(props) => (props.isReadMore ? '100%' : '230px')};
	display: -webkit-box;
	position: relative;
	top: auto;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${(props) => (props.isReadMore ? 'none' : '4')};
	overflow: hidden;
`;

const ServicePage = ({
	product,
	service,
	options,
	cost,
	processing,
	handlePreviousStep,
	handleNextStep,
	handleCostUpdate,
	proceedButtons,
	handleChange,
}) => {
	const [selectedColor, setSelectedColor] = useState('');
	const [isReadMore, setIsReadMore] = useState(false);
	const [requiresReadMore, setRequiresReadMore] = useState(false);
	const readMoreContainerRef = useRef(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		const colors = ['#D6BBF7', '#FFDA79', '#F3B398', '#E2A1C0'];
		const randomColor = colors[Math.floor(Math.random() * colors.length)];
		setSelectedColor(randomColor);
	}, []);

	useEffect(() => {
		if (readMoreContainerRef.current) {
			// Do check above - else we get a 'not defined' error on next line
			const { scrollHeight, offsetHeight } = readMoreContainerRef.current;
			if (offsetHeight < scrollHeight) {
				setRequiresReadMore(true);
			}
		}
	}, [readMoreContainerRef.current]);

	return (
		<>
			<BackgroundWrapper>
				<Container>
					<Row noGutters className="justify-content-start align-items-start">
						<Circle size="48px" onClick={() => handlePreviousStep()}>
							<MdArrowBack size={20} />
						</Circle>
					</Row>
					<Row className="justify-content-between align-items-start mt-5">
						<Col
							xs={12}
							lg={6}
							className="justify-content-start align-items-start "
						>
							<ImageContainer background={!service.images && selectedColor}>
								<Image
									src={
										service.images
											? `${service.images[0].src}?width=500`
											: config.PLACEHOLDER_IMAGE
									}
									overlay={!service.images}
								/>
							</ImageContainer>

							<DesktopView>
								<ServiceDetail service={service} product={product} />
							</DesktopView>

							<MobileView>
								<TitleContainer>
									<H1 className="mb-3"> {service.title}</H1>

									<ParagraphGreyMD className="mt-3">
										<ReadMoreDiv
											ref={(node) => {
												readMoreContainerRef.current = node;
											}}
											isReadMore={isReadMore}
										>
											<Markdown
												options={{
													forceBlock: true,
													overrides: {
														p: {
															props: {
																className: 'my-0',
															},
														},
														a: {
															props: {
																target: '_blank',
															},
														},
													},
												}}
											>
												{service.description}
											</Markdown>
										</ReadMoreDiv>
									</ParagraphGreyMD>
									{requiresReadMore && (
										<ReadMoreBtn onClick={() => setIsReadMore(!isReadMore)}>
											<H5TextSecondary>
												{isReadMore ? 'Show less' : 'Read more'}
											</H5TextSecondary>
										</ReadMoreBtn>
									)}
								</TitleContainer>
							</MobileView>
						</Col>

						<Col
							xs={12}
							lg={6}
							className=" justify-content-start align-items-start"
						>
							<MobileView className="mb-5">
								<ServiceDetail service={service} product={product} />
							</MobileView>
							<DesktopView>
								<TitleContainer>
									<H1 className="mb-3"> {service.title}</H1>
									<ParagraphGreyMD>
										<ReadMoreDiv
											ref={(node) => {
												readMoreContainerRef.current = node;
											}}
											isReadMore={isReadMore}
										>
											<Markdown
												options={{
													forceBlock: true,
													overrides: {
														p: {
															props: {
																className: 'my-0',
															},
														},
														a: {
															props: {
																target: '_blank',
															},
														},
													},
												}}
											>
												{service.description}
											</Markdown>
										</ReadMoreDiv>
									</ParagraphGreyMD>
									{requiresReadMore && (
										<ReadMoreBtn onClick={() => setIsReadMore(!isReadMore)}>
											<H5TextSecondary>
												{isReadMore ? 'Show less' : 'Read more'}
											</H5TextSecondary>
										</ReadMoreBtn>
									)}
								</TitleContainer>
							</DesktopView>
							<OrderOptionsPage
								product={product}
								service={service}
								options={options}
								cost={cost}
								processing={processing}
								handleOptionsChange={(values) => {
									handleChange(values);
								}}
								handleCostUpdate={handleCostUpdate}
								handleNextStep={() => handleNextStep()}
								proceedButtons={proceedButtons}
							/>
						</Col>
					</Row>
				</Container>
			</BackgroundWrapper>
			<BackgroundWrapper background="white">
				<ProviderContainer>
					<ProviderInfo provider={product.provider} />
				</ProviderContainer>
			</BackgroundWrapper>
		</>
	);
};

export default ServicePage;
