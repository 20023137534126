import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { Form } from 'formik';
import { CirclePicker, CompactPicker } from 'react-color';

export const StepTitle = styled.h1`
	font-weight: 600;
`;

export const StepSubtitle = styled.p`
	color: grey;
`;

export const StyledPSmall = styled.p`
	font-family: 'AT Surt SemiBold';
	font-size: 0.5rem;
	margin: 0px;
`;

export const StyledForm = styled(Form)`
	margin-left: 20vw;
	margin-right: 20vw;
	margin-top: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: white;
	min-height: 100%;

	@media only screen and (max-width: 768px) {
		margin-left: 10vw;
		margin-right: 10vw;
	}
`;

export const StyledBlock = styled.div`
	margin-left: 20%;
	margin-right: 20%;
	margin-top: 20px;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: white;
`;

export const StepText = styled.p`
	margin-bottom: 0px;
	font-weight: 600;
	color: ${(props) =>
		props.selected === true ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.2)'};
`;

export const FormContainer = styled(Container)`
	padding-top: 50px;
	background-color: ${(props) => props.theme.colors.mainColor};
	padding-bottom: 50px;
	height: 100%;
	min-height: 120vh;
`;

export const StyledRow = styled(Row)`
	margin-top: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid black;
	padding-top: 20px;
	padding-bottom: 20px;
`;

export const StyledLabel = styled.label`
	margin: 5px 5px 5px 5px;
`;

export const StyledInput = styled.input`
	padding: 8px;
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	width: 100%;
`;

export const StyledTeamSearchInput = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	height: 22px;
	display: block;
	border: none;
	width: 140px;
	outline: none;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
	@media only screen and (max-width: 380px) {
		width: 90px;
	}
	@media only screen and (max-width: 320px) {
		width: 60px;
	}
`;

export const StyledInputBox = styled.input`
	height: 50px;
	background: rgba(0, 0, 0, 0.03);
	border: none;
	border-radius: 3px;
	margin: 0px 0px 25px 0px;
	width: 100%;
	outline: none;
	padding-left: 15px;
	@media only screen and (max-width: 768px) {
		border: 0.5px solid rgba(0, 0, 0, 0.3);
		box-sizing: border-box;
	}
`;

export const StyledLabelForm = styled.label`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 13px;
	line-height: 14px;
	margin: 0px 0px 5px 0px;
`;

export const StyledPasswordContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const StyledShowPwd = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	margin: 0px 0px 9px 0px;
	border-bottom: 1.5px solid black;
	cursor: pointer;
`;

export const StyledOR = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 80px;
	margin: 0px 0px 0px 0px;
	width: 100%;
	@media only screen and (max-width: 768px) {
		height: 65px;
	}
`;

export const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 25px;
	position: relative;
	bottom: 22px;
	color: red;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 16px;
`;

export const StyledForgotPassword = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	margin: 0px 0px 25px 0px;
	width: fit-content;
	@media only screen and (max-width: 768px) {
		height: 65px;
	}
`;

export const StyledForgotPwdText = styled.p`
	margin: 0px;
	padding-bottom: 5px;
	border-bottom: 1px solid black;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
`;

export const StyledORDivider = styled.div`
	width: 45px;
	height: 1px;
	background: #e9e9e9;
`;

export const StyledORText = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 10px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	margin: 0px 18px;
`;

export const LoginButton = styled.button`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
	height: 50px;
	background: rgba(0, 0, 0, 1);
	border-radius: 3px;
	margin: 0px 0px 0px 0px;
	width: 100%;
	outline: none;
	border: 0px;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
`;

export const SocialLoginButton = styled.button`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 16px;
	line-height: 18px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	position: relative;
	box-sizing: border-box;
	border-radius: 3px;
	margin: 0px 0px 0px 0px;
	width: 100%;
	outline: none;
	cursor: pointer;
	&:focus {
		outline: 0;
	}
`;

export const StyledTextArea = styled.textarea`
	padding: 8px;
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	width: 100%;
`;

export const StyledCoupon = styled.input`
	padding: 8px;
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	width: 100%;
`;

export const StyledSelect = styled.select`
	padding: 8px;
	display: block;
	border: none;
	border-bottom: 1px solid #ccc;
	width: 100%;
	height: 100%;
	text-align-last: right;
`;

export const StyledColorPicker = styled(CirclePicker)`
	margin-top: 5px;
	margin-bottom: 5px;
	margin-right: auto !important;
	margin-left: auto;
`;

export const StyledPreciseColorPicker = styled(CompactPicker)`
	margin: 5px !important;
	padding: 5px !important;
	text-align: center;
	width: 100% !important;
`;

export const MinusButton = styled.button`
	min-width: 50px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background-color: white;
	color: black;
	border: 1px solid black;
	border-right-width: 0px;
	margin: 20px 0px 20px 0px;
	width: 50%;
	height: 100%;
	cursor: pointer;
`;

export const PlusButton = styled.button`
	min-width: 50px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: white;
	color: black;
	border: 1px solid black;
	margin: 20px 0px 20px 0px;
	width: 50%;
	height: 100%;
	cursor: pointer;
`;

export const StyledDivider = styled(Row)`
	min-height: 100px;
	border-bottom: 1px solid black;
	margin-top: auto;
	margin-bottom: auto;
`;

export const StyledBottomRow = styled(Row)`
	min-height: 10vh;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
	border-top: 1px solid black;
	background-color: white;
`;

export const StyledTabLink = styled.div`
	font-family: 'AT Surt SemiBold';
	background-color: ${(props) =>
		props.theme.primaryButton.backgroundColorLight};
	color: ${(props) => props.theme.primaryButton.colorLight};
	border-radius: 20px;
	border-color: ${(props) => props.theme.primaryButton.borderColor};
	display: inline-block;
	margin-bottom: 0;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid;
	padding: 6px 12px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	font-size: ${(props) => (props.size ? props.size * 18 + 'px' : 'auto')};

	&.active {
		background-color: ${(props) =>
			props.theme.primaryButton.backgroundColorDark};
		color: ${(props) => props.theme.primaryButton.colorDark};
	}
`;
