import React from 'react';
import { useAuth0 } from '../../../contexts/react-auth0-spa';
import OrderOptionsPageAuthenticated from './Authenticated';
import OrderOptionsPageUnauthenticated from './NonAuthenticated';

const OrderOptionsPage = ({
	product,
	service,
	cost,
	fee,
	type,
	processing,
	options,
	handleCostUpdate,
	handleNextStep,
	proceedButtons,

	handleOptionsChange,
}: any) => {
	const { isAuthenticated } = useAuth0();

	return isAuthenticated ? (
		<OrderOptionsPageAuthenticated
			product={product}
			service={service}
			cost={cost}
			fee={fee}
			type={type}
			processing={processing}
			options={options}
			handleCostUpdate={handleCostUpdate}
			handleNextStep={handleNextStep}
			proceedButtons={proceedButtons}
			handleOptionsChange={handleOptionsChange}
		/>
	) : (
		<OrderOptionsPageUnauthenticated />
	);
};

export default OrderOptionsPage;
