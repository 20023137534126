import React, { ButtonHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { useUserContext } from '../../../contexts/userContext';
import { useClient } from '../../../hooks';
import {
	H2,
	H3,
	ParagraphSemiBoldLarge,
	ParagraphGrey,
} from '../../StylesV3Main';
import Button from '../Button/Button';
import Label from '../Label/Label';
import Modal from '../Modal';

type BenefitsProps = {
	benefits: {
		id: number;
		title: string;
		description: RawDraftContentState;
		signUpSteps?: RawDraftContentState | undefined;
		signUpUrl?: string | undefined;
		type: string;
		eligibility?: string | undefined;
	}[];
	user: string;
	handleUpdate: () => void;
	openEditModal: (id: number) => void;
	openAddModal: () => void;
} & ButtonHTMLAttributes<HTMLLabelElement>;

const CardContentContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: 16px;
	row-gap: 16px;

	@media (max-width: 900px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

const CardContent = styled.div`
	padding: 24px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	width: 100%;

	> div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		label {
			margin-bottom: 0;
		}
	}
`;

const AdminButtons = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	gap: 16px;

	@media (max-width: 900px) {
		gap: 6px;
	}
`;

const DeleteModalContainer = styled.div`
	width: 400px;
	position: relative;
	background: white;
`;

const Heading2 = styled(H2)`
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
`;

const DeleteModalBody = styled.div`
	padding: 24px;
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const Description = styled.p`
	margin-top: 8px;
	color: #5b6166;
	font-size: 18px;
	font-weight: 400;
	line-height: 160%;
	font-family: 'Roobert Regular';
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;

	@media (max-width: 900px) {
		min-height: 100px;
	}
`;

const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
`;

const NoBenefitText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	gap: 8px;
`;

const NoBenefitsWarning = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	gap: 24px;
	margin-bottom: 8px;

	background: #f8f5f4;
	border-radius: 8px;
`;

const Icon = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 80px;
	height: 80px;

	background: #f7dfea;
`;

const CurvedRectangle = styled.div`
	width: 28px;
	height: 40px;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;

	border: 2.66667px solid #e2a1c0;

	background: #f7dfea;
`;

type IconMap = {
	[key: string]: {
		icon: string | null;
		backgroundColor: string;
		color: string;
	};
};

export const icons: IconMap = {
	health: {
		icon: '🏥',
		backgroundColor: '#D6BBF7',
		color: '#000',
	},
	life: {
		icon: '❤️',
		backgroundColor: '#E2A1C0',
		color: '#000',
	},
	dental: {
		icon: '🦷',
		backgroundColor: '#F3B398',
		color: '#000',
	},
	vision: {
		icon: '👀',
		backgroundColor: '#F3B398',
		color: '#000',
	},
	food: {
		icon: '🥗',
		backgroundColor: '#DCEC9A',
		color: '#000',
	},
	home: {
		icon: '🏡',
		backgroundColor: '#F3B398',
		color: '#000',
	},
	fitness: {
		icon: '🏋🏽‍♀️',
		backgroundColor: '#CCE4E4',
		color: '#000',
	},
	transport: {
		icon: '🚂',
		backgroundColor: '#FB9458',
		color: '#000',
	},
	financial: {
		icon: '🏦',
		backgroundColor: '#FFDA79',
		color: '#000',
	},
	pension: {
		icon: '👵🏽',
		backgroundColor: '#FFF897',
		color: '#000',
	},
	otherinsurance: {
		icon: '🌪',
		backgroundColor: '#FF5353',
		color: '#000',
	},
	leave: {
		icon: '🏖',
		backgroundColor: '#F3B398',
		color: '#000',
	},
	cycletowork: {
		icon: '🚲',
		backgroundColor: '#F3B398',
		color: '#000',
	},
	other: {
		icon: null,
		backgroundColor: '#F8F5F4',
		color: '#000',
	},
};

const BenefitsContainer = ({
	benefits,
	user,
	openEditModal,
	openAddModal,
	handleUpdate,
}: BenefitsProps) => {
	const client = useClient();
	const { userData } = useUserContext();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [selectedBenefitId, setSelectedBenefitId] = useState<
		number | undefined
	>(undefined);

	const handleDeleteSubmit = async (
		e: React.FormEvent<HTMLFormElement>,
		id?: number
	) => {
		e.preventDefault();

		setDeleteModalOpen(false);
		await client.put(
			`${config.teams.HOST}/teams/benefits/${userData.currentTeam}/delete`,
			{
				benefit: {
					id,
				},
			}
		);

		handleUpdate();
	};

	return (
		<>
			{benefits.length ? (
				<CardContentContainer>
					{benefits.map((benefit) => {
						const benefitType = benefit.type?.toLowerCase();

						const contentState = convertFromRaw(benefit.description);
						const description = EditorState.createWithContent(contentState)
							.getCurrentContent()
							.getPlainText('\u0001');

						return (
							<CardContent key={benefit.id}>
								<H3>{benefit.title}</H3>
								<Description>{description}</Description>
								<div>
									{user === 'employee' ? (
										<Link to={`/account-settings/benefits/${benefit.id}`}>
											<Button
												state="default"
												size="small"
												buttonType="secondary"
											>
												View details
											</Button>
										</Link>
									) : (
										<div>
											<AdminButtons>
												<Button
													state="default"
													buttonType="secondary"
													size="small"
													onClick={() => {
														openEditModal(benefit.id);
													}}
												>
													Edit
												</Button>
												<Button
													state="default"
													buttonType="destructive"
													size="small"
													onClick={() => {
														setSelectedBenefitId(benefit.id);
														setDeleteModalOpen(true);
													}}
												>
													Delete
												</Button>
											</AdminButtons>
										</div>
									)}
									<Label
										size="small"
										backgroundColor={icons[benefitType]?.backgroundColor}
										fontColor={icons[benefitType]?.color}
										radius="rounded"
									>
										{icons[benefitType]?.icon} {benefit?.type}
									</Label>
								</div>
							</CardContent>
						);
					})}
				</CardContentContainer>
			) : (
				<NoBenefitsWarning>
					<Icon>
						<CurvedRectangle />
					</Icon>
					<NoBenefitText>
						<ParagraphSemiBoldLarge data-testid="no-benefits-heading">
							No Benefits?
						</ParagraphSemiBoldLarge>
						{user === 'employee' ? (
							<ParagraphGrey style={{ maxWidth: '556px' }}>
								Your team currently has no benefits to display
							</ParagraphGrey>
						) : (
							<>
								<ParagraphGrey style={{ maxWidth: '556px' }}>
									We don’t believe you. Add your benefits to this space so your
									employees know that you care for them and what’s available.
								</ParagraphGrey>
								<Button
									state="default"
									buttonType="primary"
									size="small"
									onClick={() => openAddModal()}
									style={{ marginTop: '16px' }}
								>
									Add your Benefits
								</Button>
							</>
						)}
					</NoBenefitText>
				</NoBenefitsWarning>
			)}
			<Modal
				ariaLabel="Delete Modal"
				ariaDescribedBy="Confirm a Benefit should be Deleted"
				onClose={() => {
					setDeleteModalOpen(false);
				}}
				open={deleteModalOpen}
			>
				<DeleteModalContainer>
					<form
						method="post"
						onSubmit={(e) => handleDeleteSubmit(e, selectedBenefitId)}
					>
						<DeleteModalBody>
							<Heading2>Are you sure?</Heading2>
							<StyledHr />
							<ButtonRow>
								<Button
									state="default"
									buttonType="secondary"
									size="small"
									type="reset"
									onClick={() => setDeleteModalOpen(false)}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									state="default"
									buttonType="destructive"
									size="small"
								>
									Yes, delete
								</Button>
							</ButtonRow>
						</DeleteModalBody>
					</form>
				</DeleteModalContainer>
			</Modal>
		</>
	);
};
export default BenefitsContainer;
