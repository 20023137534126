export const bankTypes = {
	UK: 'UK Bank Account',
	US: 'US Bank Account',
	Canada: 'Canadian Bank Account',
	IBAN: 'IBAN Bank Account',
	Other: 'Other',
};

export const bankTypeOptions = [
	{
		label: 'Select bank account type...',
		value: 'PLACEHOLDER',
		disabled: true,
	},
	{
		label: 'UK Bank Account',
		value: bankTypes.UK,
	},
	{
		label: 'US Bank Account',
		value: bankTypes.US,
	},
	{
		label: 'Canadian Bank Account',
		value: bankTypes.Canada,
	},
	{
		label: 'IBAN Bank Account',
		value: bankTypes.IBAN,
	},
	{
		label: 'Other',
		value: bankTypes.Other,
	},
];
