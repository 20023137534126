import React, { memo } from 'react';
import styled from 'styled-components';
import { H5TextSecondary } from '../../StylesV3Main';
import checkmark from './images/checkmark.svg';

type CategoryFilterProps = {
	categories: { [key: number]: boolean };
	setCategories: (value: { [key: number]: boolean }) => void;
};

const CategoryFilter = ({ categories, setCategories }: CategoryFilterProps) => (
	<CategoriesContainer>
		<H5TextSecondary>Categories</H5TextSecondary>
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{[
				{ id: 1, name: 'Fitness' },
				{ id: 2, name: 'SelfCare' },
				{ id: 3, name: 'Health' },
				{ id: 4, name: 'Life' },
				{ id: 5, name: 'Nutrition' },
				{ id: 6, name: 'Home' },
				{ id: 7, name: 'Learning' },
				{ id: 8, name: 'Mind' },
				{ id: 9, name: 'Social' },
				{ id: 10, name: 'Entertainment' },
				{ id: 11, name: 'Giving' },
				{ id: 13, name: 'Finance' },
				{ id: 15, name: 'Parenting' },
				{ id: 16, name: 'Events' },
			].map((item: any) => (
				<CategoryItem key={item.id}>
					<Checkbox>
						<input
							type="checkbox"
							name={item.id}
							id={item.id}
							value={item.id}
							checked={!!categories[item.id]}
							onChange={({ target }) => {
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								const { [item.id]: id, ...rest } = categories;
								if (target.checked) rest[item.id] = true;
								setCategories(rest);
							}}
						/>
					</Checkbox>
					<Label htmlFor={item.id}>{item.name}</Label>
				</CategoryItem>
			))}
		</div>
	</CategoriesContainer>
);

const CategoriesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const CategoryItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 0 0 50%;
	justify-content: flex-start;
	margin-top: 12px;
	cursor: pointer;
`;

const Checkbox = styled.div`
	width: 16px;
	margin-right: 16px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;

	input {
		appearance: none;
		background-color: #fff;
		font: inherit;
		width: 16px;
		height: 16px;
		margin-right: 16px;
		border: 1px solid rgba(0, 0, 0);
	}
	input:checked {
		background-color: black;
		background-image: url(${checkmark});
		background-repeat: no-repeat, repeat;
		background-position: center;
		background-size: 0.9em auto, 100%;
	}
	&:hover {
		cursor: pointer;
		background-color: #f8f8f8;
	}
`;

const Label = styled.label`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
	cursor: pointer;
`;

export default memo(CategoryFilter);
