import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useClient } from '../../../hooks';
import config from '../../../config';
import Spinner from '../../../styles/shared/Spinner/Spinner';
import Pager from '../../../styles/shared/Pager';
import Menu from '../../../styles/shared/Menu';
import { ReactComponent as Filter } from '../images/filter.svg';
import { ReactComponent as Receipt } from '../images/receipt.svg';
import ReceiptModal from './ReceiptModal';
import { Booking, TeamBudget } from './types';
import NoTransactions from './NoTransactions';

const Transactions = ({
	team,
	budgets,
}: {
	team: { id: number };
	budgets: TeamBudget[];
}) => {
	const client = useClient();
	const [refresh, setRefresh] = useState(0);
	const [bookings, setBookings] = useState<Booking[]>([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const { pathname, search } = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(search);
	const budgetId = params.get('pot') || '';
	const bookingId = +(params.get('receipt') || 0);
	const filterStatus = params.get('status') || '';
	const page = +(params.get('page') || 1);
	const query = (setParams: (p: URLSearchParams) => void) => {
		const newParams = new URLSearchParams(search);
		setParams(newParams);
		return newParams.toString();
	};
	const receiptsRequired = !!budgets.find(({ id, receiptRequired }) =>
		!budgetId ? receiptRequired : id === +budgetId && receiptRequired
	);
	useEffect(() => {
		let cancel = false;
		(async () => {
			try {
				setLoading(true);
				const searchParams = new URLSearchParams();
				searchParams.set('page', page.toString());
				if (budgetId) searchParams.set('budgetId', budgetId);
				if (filterStatus && receiptsRequired)
					searchParams.set('receiptStatus', filterStatus);
				const resp = await client.get(
					`${config.teams.HOST}/teams/budgets/bookings/${team.id}?${searchParams}`
				);
				if (!cancel) {
					if (!resp.data.count || resp.data.rows.length) {
						setBookings(resp.data.rows);
						setCount(resp.data.count);
						setLoading(false);
					} else {
						const lastPage = Math.ceil(resp.data.count / 10);
						if (page !== lastPage) {
							history.replace(
								`${pathname}?${query((p) => {
									p.set('page', lastPage.toString());
								})}`,
								{
									scroll: false,
								}
							);
						}
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		})();
		return () => {
			cancel = true;
		};
	}, [team.id, page, budgetId, filterStatus, refresh]);
	const closeReceiptModal = () => {
		history.push(
			`${pathname}?${query((p) => {
				p.delete('receipt');
			})}`,
			{
				scroll: false,
			}
		);
	};
	const updateReceiptStatus = async (
		bookingReceiptId: number,
		status: 'flagged' | 'reviewed' | 'unmarked'
	) => {
		setBookings((prevBookings) =>
			prevBookings.map((booking) =>
				booking.id !== bookingReceiptId
					? booking
					: {
							...booking,
							receiptStatus: status !== 'unmarked' ? status : null,
					  }
			)
		);
		closeReceiptModal();
		try {
			await client.put(
				`${config.teams.HOST}/teams/budgets/receipt/${bookingReceiptId}`,
				{
					status,
				}
			);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			if (filterStatus) setRefresh((prevRefresh) => prevRefresh + 1);
		}
	};
	const remindReceipt = async (bookingRemindId: number) => {
		setBookings((prevBookings) =>
			prevBookings.map((booking) =>
				booking.id !== bookingRemindId
					? booking
					: {
							...booking,
							reminder: new Date(),
					  }
			)
		);
		try {
			await client.put(
				`${config.teams.HOST}/teams/budgets/remind/${bookingRemindId}`
			);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			setRefresh((prevRefresh) => prevRefresh + 1);
		}
	};
	const booking = bookingId
		? bookings.find(({ id }) => id === bookingId)
		: null;
	return (
		<Bookings>
			<div className="header">
				<h2>
					Team Spending
					{loading && <Spinner />}
				</h2>
				{receiptsRequired && (
					<Menu
						text="Receipt status"
						className={!filterStatus ? 'inactive' : undefined}
						selected={filterStatus}
						setSelected={(option) => {
							if (option === filterStatus) return;
							history.push(
								`${pathname}?${query((p) => {
									if (option) p.set('status', option);
									else p.delete('status');
									p.delete('page');
								})}`,
								{
									scroll: false,
								}
							);
						}}
						options={[
							{ key: '', value: 'All' },
							{ key: 'missing', value: 'Missing' },
							{ key: 'unmarked', value: 'Unmarked' },
							{ key: 'flagged', value: 'Flagged' },
							{ key: 'reviewed', value: 'Reviewed' },
						]}
					>
						<Filter />
						{`${filterStatus || 'Receipts'}
					`}
					</Menu>
				)}
				<Menu
					text="Pots"
					className={!budgetId ? 'inactive' : undefined}
					selected={budgetId}
					setSelected={(option) => {
						if (option === budgetId) return;
						history.push(
							`${pathname}?${query((p) => {
								if (option) p.set('pot', option);
								else p.delete('pot');
								p.delete('page');
							})}`,
							{
								scroll: false,
							}
						);
					}}
					options={[
						{ key: '', value: 'All' },
						...budgets.map(({ id, name }: any) => ({
							key: id.toString(),
							value: name,
						})),
					]}
				>
					<Filter />
					{`${
						budgets.find(({ id }: any) => id === +(budgetId ?? 0))?.name ??
						'Pots'
					}
					`}
				</Menu>
			</div>
			{!bookings.length && (
				<NoTransactions filter={!!(budgetId || filterStatus)} />
			)}
			{!!bookings.length && (
				<>
					<table>
						<thead>
							<tr>
								<th>User</th>
								<th>Pot</th>
								<th>Type</th>
								<th>Merchant</th>
								<th>Cost</th>
								<th>Transaction Date</th>
								<th>Receipt</th>
								<th>Reminder Sent</th>
							</tr>
						</thead>
						<tbody>
							{bookings.map(
								({
									id,
									firstName,
									lastName,
									email,
									budgetName,
									card,
									merchant,
									amount,
									currency,
									dateOrdered,
									receipt,
									receiptStatus,
									reminder,
									receiptRequired,
								}) => (
									<tr key={id}>
										<td>
											<h3>User</h3>
											{firstName && lastName
												? `${firstName} ${lastName}`
												: email}
										</td>
										<td>
											<h3>Pot</h3>
											{budgetName}
										</td>
										<td>
											<h3>Type</h3>
											{card ? 'Visa Card' : 'Reimbursement'}
										</td>
										<td>
											<h3>Merchant</h3>
											{card ? merchant : 'N/A'}
										</td>
										<td>
											<h3>Cost</h3>
											{Intl.NumberFormat('en-US', {
												style: 'currency',
												currency,
											}).format(amount)}
										</td>
										<td>
											<h3>Transaction Date</h3>
											{moment(dateOrdered).format('DD MMM YYYY')}
										</td>
										<td>
											<h3>Receipt</h3>
											{
												// eslint-disable-next-line no-nested-ternary
												receipt ? (
													<Link
														className={receiptStatus || 'unmarked'}
														to={{
															pathname,
															search: `?${query((p) => {
																p.set('receipt', id.toString());
															})}`,
															state: { scroll: false },
														}}
													>
														<Receipt />
														{receiptStatus || 'View receipt'}
													</Link>
												) : receiptRequired && amount > 0 ? (
													<button
														type="button"
														onClick={() => remindReceipt(id)}
													>
														Send Reminder
													</button>
												) : (
													'N/A'
												)
											}
										</td>
										<td>
											<h3>Reminder Sent</h3>
											{
												// eslint-disable-next-line no-nested-ternary
												receipt || !receiptRequired || amount < 0
													? 'N/A'
													: reminder
													? moment(reminder).format('DD MMM YYYY')
													: '-'
											}
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					<Pager total={count} />
				</>
			)}
			{!!bookingId && (
				<ReceiptModal
					booking={booking || { id: bookingId }}
					onUpdate={(status) => updateReceiptStatus(bookingId, status)}
					onCancel={closeReceiptModal}
				/>
			)}
		</Bookings>
	);
};

const Bookings = styled.div`
	background: #fff;
	border-radius: 18px;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	.header {
		display: flex;
		padding: 20px 24px;
		border-bottom: 1px solid rgb(234, 236, 240);
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.inactive button {
			background: rgb(249, 250, 251);
			color: rgb(29, 41, 57);
			border: 1px solid rgb(208, 213, 221);
			svg {
				stroke: rgb(29, 41, 57);
			}
		}
		button {
			background: rgb(25, 26, 28);
			color: #fff;
			padding: 12px 14px;
			font-size: 15px;
			border-radius: 10px;
			width: 160px;
			text-transform: capitalize;
			text-align: left;
			svg {
				stroke: #fff;
				margin-right: 8px;
				margin-top: -4px;
			}
		}
		div:last-child button {
			text-transform: unset;
		}
		@media (max-width: 900px) {
			flex-direction: column;
			> h2,
			div {
				width: 100%;
				button {
					width: 100%;
				}
			}
		}
	}
	h2 {
		font-family: 'TTInterfaces DemiBold';
		font-size: 21px;
		line-height: 28px;
		margin: 0;
		margin-right: 8px;
		flex: 1;
	}
	h2 > svg {
		margin-left: 8px;
	}
	table {
		width: 100%;
	}
	tr {
		background: #fff;
		border-bottom: 1px solid rgb(234, 236, 240);
		@media (max-width: 1200px) {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			padding: 20px;
		}
	}
	thead {
		@media (max-width: 1200px) {
			display: none;
		}
	}
	thead tr {
		background: rgb(252, 252, 253);
	}
	tr.selected {
		background: rgb(252, 252, 253);
	}
	th {
		font-family: 'TTInterfaces Medium';
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		height: 44px;
		padding: 12px 16px;
	}
	td {
		font-family: 'TTInterfaces Regular';
		font-size: 14px;
		line-height: 20px;
		padding: 12px 16px;
		text-overflow: ellipsis;
		overflow: hidden;
		a {
			font-family: 'TTInterfaces Medium';
			width: 140px;
			display: flex;
			justify-content: center;
			gap: 8px;
			white-space: nowrap;
			color: #344054;
			text-decoration: none;
			border-radius: 8px;
			padding: 10px 12px;
			border: 1px solid #d0d5dd;
			svg {
				fill: #344054;
			}
		}
		a:hover,
		button:hover {
			background-color: rgba(208, 213, 221, 0.1);
		}
		a.flagged,
		a.reviewed {
			text-transform: capitalize;
		}
		a.reviewed {
			color: #067647;
			border: 1.5px solid #067647;
			svg {
				fill: #067647;
			}
		}
		a.reviewed:hover {
			background-color: rgba(7, 148, 85, 0.1);
		}
		a.flagged {
			color: #cc1440;
			border: 1.5px solid #cc1440;
			svg {
				fill: #cc1440;
			}
		}
		a.flagged:hover {
			background-color: rgba(204, 20, 64, 0.1);
		}
		button {
			width: 140px;
			padding: 10px 16px;
			border: 1px solid #d0d5dd;
			border-radius: 8px;
			background-color: #fff;
			font-family: 'TTInterfaces DemiBold';
		}
		h3 {
			display: none;
			@media (max-width: 1200px) {
				display: block;
				font-family: 'TTInterfaces Regular';
				font-size: 13px;
				line-height: 8px;
				color: #9199a4;
				margin: 30px 0 12px 0;
			}
		}
	}
	td:nth-child(1) {
		color: #1d1d1d;
	}
	td:nth-child(2),
	td:nth-child(5) {
		color: #000;
		font-family: 'TTInterfaces DemiBold';
	}
	td:nth-child(3),
	td:nth-child(4),
	td:nth-child(6) {
		color: #76808d;
	}
	td:nth-child(7) {
		text-align: center;
		display: flex;
		justify-content: center;
		@media (max-width: 1200px) {
			text-align: unset;
			display: unset;
		}
	}
	@media (max-width: 1250px) {
		td,
		th {
			padding: 4px 8px;
		}
	}
	@media (max-width: 1200px) {
		td {
			color: #000 !important;
			a,
			button {
				padding: 8px 20px;
				font-size: 13px;
			}
		}
		td:first-child {
			font-family: 'TTInterfaces DemiBold';
		}
		td:nth-child(-n + 2) h3 {
			margin-top: 0;
		}
	}
`;

export default Transactions;
