import React, { useState, useEffect } from 'react';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';
import Loading from '../../../utilities/Loading';
import {
	PSansMediumText,
	ModalButton,
	PSansText,
} from '../../../../styles/StylesV2';
import { Row, Col, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { BoxInputLabel } from '../../../../styles/StylesV2';
import { useAuth0 } from '../../../../contexts/react-auth0-spa';
import { useUserContext } from '../../../../contexts/userContext';
import config from '../../../../config';
import client from '../../../../api/client';

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledDivBox = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 0px 15px 0px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 991.98px) {
		width: 100%;
		max-width: 100%;
		background: white;
		color: black;
		box-shadow: 0px 0px 5px #ccc;
		-moz-box-shadow: 0px 0px 5px #ccc;
		-webkit-box-shadow: 0px 0px 5px #ccc;
	}
`;

const StyledSavedAlert = styled.div`
	margin: 20px auto 20px auto;
	@media (max-width: 991.98px) {
		margin-right: 0px;
		width: 100%;
		margin-bottom: 20px;
	}
`;

const StyledToggleSwitchDefault = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: linear-gradient(0deg, #3dde87, #3dde87), #ffffff;
	position: relative;
	cursor: pointer;
`;

const StyledToggleSwitchOff = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: #e9e9ea;
	position: relative;
	cursor: pointer;
`;

const StyledWhiteCircle = styled.div`
	position: absolute;
	width: 16.36px;
	height: 16.36px;
	border-radius: 50%;
	background: white;
	bottom: 2px;
`;

const SetupCard = ({ onHide, onSuccess, teamId }) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState('');
	const stripe = useStripe();
	const elements = useElements();
	const { getTokenSilently } = useAuth0();
	const { userData, addPaymentMethodTeam } = useUserContext();
	const [makeDefault, setMakeDefault] = useState(false);

	useEffect(() => {
		const getData = async () => {
			try {
				const token = await getTokenSilently();
				client.defaults.headers.Authorization = 'Bearer ' + token;
				const response = await client.get(
					`${config.api.HOST}/stripe/setupIntentTeam/${
						userData.teams.filter((team) => team.id === teamId)[0].id
					}`
				);
				if (response.data) {
					setClientSecret(response.data);
				} else {
					console.log('Setup intent could not be received');
				}
			} catch (err) {
				console.error(err.message);
			}
		};
		if (userData) {
			getData();
		}
	}, [userData, getTokenSilently, teamId]);

	const cardStyle = {
		style: {
			base: {
				fontFamily: 'Arial',
				fontSize: '16px',
				fontWeight: '400',
				backgroundColor: 'transparent',
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	};

	const handleChange = async (event) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : '');
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setProcessing(true);
		const result = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: elements.getElement(CardNumberElement),
				billing_details: {
					name: userData.firstName + ' ' + userData.lastName,
				},
			},
		});

		if (result.error) {
			setError(`Adding card failed. ${result.error.message}`);
			setProcessing(false);
		} else {
			// The setup has succeeded. Display a success message and send
			// result.setupIntent.payment_method to your server to save the
			// card to a Customer
			const response = await addPaymentMethodTeam(
				result.setupIntent.payment_method,
				teamId,
				makeDefault
			);
			if (response) {
				setError(null);
				setProcessing(false);
				setSucceeded(true);
			} else {
				setError(`Card could not be added. Contact support.`);
				setDisabled(true);
				setProcessing(false);
			}
			elements.getElement(CardNumberElement).clear();
			onSuccess && onSuccess();
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{ position: 'relative' }}
			className="w-100"
		>
			<Row noGutters>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>Card Number</StyledLabel>
					<StyledDivBox>
						<CardNumberElement
							id="cardNumber"
							className="w-100"
							options={cardStyle}
							onChange={handleChange}
							showIcon
						/>
					</StyledDivBox>
				</Col>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>Exp. Date</StyledLabel>
					<StyledDivBox>
						<CardExpiryElement
							id="expiry"
							className="w-100"
							options={cardStyle}
							onChange={handleChange}
						/>
					</StyledDivBox>
				</Col>
			</Row>
			<Row noGutters>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>CVV</StyledLabel>
					<StyledDivBox>
						<CardCvcElement
							id="cvc"
							className="w-100"
							options={cardStyle}
							onChange={handleChange}
						/>
					</StyledDivBox>
				</Col>
			</Row>
			<Row noGutters>
				<StyledLabel style={{ textTransform: 'none' }}>
					Make this card your default payment method
				</StyledLabel>
				{makeDefault ? (
					<>
						<StyledToggleSwitchDefault
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(false);
							}}
						>
							<StyledWhiteCircle style={{ right: 2 }} />
						</StyledToggleSwitchDefault>
						<PSansText>On</PSansText>
					</>
				) : (
					<>
						<StyledToggleSwitchOff
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(true);
							}}
							style={{ cursor: 'pointer' }}
						>
							<StyledWhiteCircle style={{ left: 2 }} />
						</StyledToggleSwitchOff>
						<PSansText>Off</PSansText>
					</>
				)}
			</Row>
			<Row
				noGutters
				className="text-left  justify-content-center align-items-center"
			>
				{/* Show any error that happens when processing the payment */}
				<StyledSavedAlert>
					{error && (
						<Alert
							className="text-center my-auto mx-auto p-1"
							variant={'danger'}
						>
							<PSansMediumText className="card-error" role="alert">
								{error}
							</PSansMediumText>
						</Alert>
					)}

					{/* Show a success message upon completion */}

					{succeeded && (
						<Alert
							className="text-center my-auto mx-auto p-1 "
							variant={'success'}
						>
							<PSansMediumText
								className={
									succeeded ? 'result-message' : 'result-message d-none'
								}
							>
								Card successfully added
							</PSansMediumText>
						</Alert>
					)}
				</StyledSavedAlert>
			</Row>
			<Row
				noGutters
				className="justify-content-around align-items-center py-2 mt-2"
			>
				<ModalButton type="button" version="light" onClick={onHide}>
					<PSansText>Cancel</PSansText>
				</ModalButton>
				<ModalButton disabled={processing || disabled || succeeded} id="submit">
					<span id="button-text">
						{processing ? (
							<Loading className="d-flex align-items-center justify-content-center h-100 w-100" />
						) : (
							'Add'
						)}
					</span>
				</ModalButton>
			</Row>
		</form>
	);
};

export default SetupCard;
