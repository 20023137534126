/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';
import statesList from './states.json';
import accountTypes from './accountTypes.json';
import Select from '../../styles/shared/Select';

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	margin-top: 8px;
	font-size: 12px;
	line-height: 14px;
	font-family: 'TTInterfaces Regular';
	color: #cc1440;
`;

const StyledInputBox = styled.input`
	font-family: 'TTInterfaces Regular';
	font-size: 16px;

	width: 100%;
	height: 56px;
	border: none;

	border-radius: 3px;
	padding: 16px;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 14px;

	input {
		width: 100%;
		padding: 15px 18px;
		height: 50px;

		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	textarea {
		width: 100%;
		padding: 15px 18px;
		height: 100px;

		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	label:first-of-type {
		font-family: 'TTInterfaces Demibold';

		font-size: 16px;
		line-height: 20px;
		margin: 0;
	}

	label:nth-of-type(2) {
		font-family: 'TTInterfaces Medium';

		font-size: 14px;
		line-height: 20px;
		margin: 0;

		color: rgba(0, 0, 0, 0.35);
	}

	div {
		border-radius: 10px;

		input {
			opacity: 0;
			height: 0;
			width: 0;
			padding: 0;
			left: 50%;
			position: absolute;
		}
	}
	> div {
		width: 100%;

		background: transparent;

		> div {
			> div {
				background: #fff;
			}
		}
	}
`;

const AmericanBankDetails = ({ setFieldValue, values }) => {
	return (
		<>
			<InputContainer>
				<label htmlFor="accountTypeUS">Account Type</label>
				<Field
					type="text"
					id="accountTypeUS"
					name="accountType"
					as={BookingFormSelect}
					value={values.accountType}
					options={accountTypes}
					onChange={(e) => {
						setFieldValue('accountType', e.target.value);
					}}
				/>
				<ErrorMessage name="accountType" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="accountTypeUS">Account Number</label>
				<Field
					type="text"
					name="accountNumber"
					as={StyledInputBox}
					value={values.accountNumber}
					onChange={(e) => {
						setFieldValue('accountNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="accountNumber" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="routingNumber">Routing Number</label>
				<Field
					type="text"
					name="routingNumber"
					id="routingNumber"
					as={StyledInputBox}
					value={values.routingNumber}
					onChange={(e) => {
						setFieldValue('routingNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="routingNumber" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="line1">Address Line 1</label>
				<Field
					type="text"
					name="line1"
					id="line1"
					as={StyledInputBox}
					value={values.line1}
					onChange={(e) => {
						setFieldValue('line1', e.target.value);
					}}
				/>
				<ErrorMessage name="line1" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="line2">Address Line 2</label>
				<Field
					type="text"
					name="line2"
					id="line2"
					as={StyledInputBox}
					value={values.line2}
					onChange={(e) => {
						setFieldValue('line2', e.target.value);
					}}
				/>
				<ErrorMessage name="line2" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="city">City</label>
				<Field
					type="text"
					name="city"
					id="city"
					as={StyledInputBox}
					value={values.city}
					onChange={(e) => {
						setFieldValue('city', e.target.value);
					}}
				/>
				<ErrorMessage name="city" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="state">State</label>
				<Field
					type="text"
					name="state"
					id="state"
					as={BookingFormSelect}
					options={statesList}
					onChange={(e) => {
						setFieldValue('state', e.target.value);
					}}
				/>
				<ErrorMessage name="state" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="zip">Zip Code</label>
				<Field
					type="text"
					name="zip"
					id="zip"
					as={StyledInputBox}
					value={values.zip}
					onChange={(e) => {
						setFieldValue('zip', e.target.value);
					}}
				/>
				<ErrorMessage name="zip" component={StyledErrorMessage} />
			</InputContainer>
		</>
	);
};

const UnitedKingdomBankDetails = ({ setFieldValue, values }) => {
	return (
		<>
			<InputContainer>
				<label id="sortCode">Sort Code</label>
				<Field
					type="text"
					name="sortCode"
					id="sortCode"
					as={StyledInputBox}
					value={values.sortCode}
					onChange={(e) => {
						setFieldValue('sortCode', e.target.value);
					}}
				/>
				<ErrorMessage name="sortCode" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="accountNumber">Account Number</label>
				<Field
					type="text"
					name="accountNumber"
					id="accountNumber"
					as={StyledInputBox}
					value={values.accountNumber}
					onChange={(e) => {
						setFieldValue('accountNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="accountNumber" component={StyledErrorMessage} />
			</InputContainer>
		</>
	);
};

const CanadaBankDetails = ({ setFieldValue, values }) => {
	return (
		<>
			<InputContainer>
				<label htmlFor="accountType">Account Type</label>
				<Field
					type="text"
					name="accountType"
					id="accountType"
					as={BookingFormSelect}
					value={values.accountType}
					options={accountTypes}
					onChange={(e) => {
						setFieldValue('accountType', e.target.value);
					}}
				/>
				<ErrorMessage name="accountType" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="institutionNumber">Institution Number</label>
				<Field
					type="text"
					name="institutionNumber"
					id="institutionNumber"
					as={StyledInputBox}
					value={values.institutionNumber}
					onChange={(e) => {
						setFieldValue('institutionNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="institutionNumber" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="transitNumber">Transit Number</label>
				<Field
					type="text"
					name="transitNumber"
					id="transitNumber"
					as={StyledInputBox}
					value={values.transitNumber}
					onChange={(e) => {
						setFieldValue('transitNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="transitNumber" component={StyledErrorMessage} />
			</InputContainer>
			<InputContainer>
				<label htmlFor="accountNumber">Account Number</label>
				<Field
					type="text"
					name="accountNumber"
					id="accountNumber"
					as={StyledInputBox}
					value={values.accountNumber}
					onChange={(e) => {
						setFieldValue('accountNumber', e.target.value);
					}}
				/>
				<ErrorMessage name="accountNumber" component={StyledErrorMessage} />
			</InputContainer>
		</>
	);
};

const EuropeBankDetails = ({ setFieldValue, values }) => {
	return (
		<InputContainer>
			<label htmlFor="iban">IBAN</label>
			<Field
				type="text"
				name="iban"
				id="iban"
				as={StyledInputBox}
				value={values.iban}
				onChange={(e) => {
					setFieldValue('iban', e.target.value);
				}}
			/>
			<ErrorMessage name="iban" component={StyledErrorMessage} />
		</InputContainer>
	);
};

const OtherBankDetails = ({ setFieldValue, values }) => {
	return (
		<InputContainer>
			<label htmlFor="email">Personal email address</label>
			<Field
				type="text"
				name="email"
				id="email"
				as={StyledInputBox}
				value={values.email}
				onChange={(e) => {
					setFieldValue('email', e.target.value);
				}}
			/>
			<label>
				After your reimbursement is approved, you will receive an email from
				Wise, our payment provider, with steps to retrieve the amount
				you&apos;ve requested. This will happen on Tuesday mornings for
				reimbursements approved before Monday at 17:00 (GMT), and Thursday
				evenings for reimbursements approved before Thursday 16:00 (GMT).
			</label>
			<ErrorMessage name="email" component={StyledErrorMessage} />
		</InputContainer>
	);
};

const bankDetailMap = {
	'US Bank Account': AmericanBankDetails,
	'UK Bank Account': UnitedKingdomBankDetails,
	'IBAN Bank Account': EuropeBankDetails,
	'Canadian Bank Account': CanadaBankDetails,
};

const BankDetailsFromCurrency = ({ currencyCode, setFieldValue, values }) => {
	const BankDetails = bankDetailMap[currencyCode];
	if (!currencyCode) return null;

	return (
		<>
			{!!BankDetails && (
				<BankDetails setFieldValue={setFieldValue} values={values} />
			)}
			{!BankDetails && (
				<OtherBankDetails setFieldValue={setFieldValue} values={values} />
			)}
		</>
	);
};

export const BookingFormSelect = ({
	options,
	value,
	onChange,
	defaultValue,
}) => {
	return (
		<Select
			value={value}
			defaultValue={defaultValue}
			onChange={(e) => onChange(e)}
			options={options}
		/>
	);
};

export default BankDetailsFromCurrency;
