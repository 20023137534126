import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { AiOutlineHeart } from 'react-icons/ai';
import { Header, PSansMediumText } from '../../styles/StylesV2';
import { useUserContext } from '../../contexts/userContext';
import { useProductContext } from '../../contexts/productContext';
import ProductCard from '../carousels/Cards/ProductCard';
import StyledLoading from '../../styles/LoadingStyles';

const StyledView = styled.div`
	width: 100%;
	height: auto;
	background: #fff;
	border-radius: 3px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	margin-left: 0px;
	@media (max-width: 1200px) {
		margin-left: 40px;
	}
	@media (max-width: 992px) {
		margin-left: 0px;
		width: 100%;
		height: auto;
		max-width: 100%;
		background: #f8f8f8;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;
	}
`;

const StyledContainer = styled.div`
	padding: 0px 40px 56px 56px;
	height: 100%;
	width: 100%;
	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		padding: 0px 30px 56px 46px;
	}
	@media (max-width: 767px) {
		padding: 0px 16px 50px 16px;
	}
`;

const StyledHeader = styled(Header)`
	line-height: 120%;
	margin-bottom: 34px;
	margin-top: 50px;

	@media (max-width: 991.8px) {
		margin-bottom: 32px;
		margin-top: 10px;
		padding: 0px;
		background: #f8f8f8;
	}
`;

const StyledNoFavourites = styled(Row)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 30vh;
`;

const StyledHeart = styled(AiOutlineHeart)`
	animation: 1s ease 0s infinite beat;

	@keyframes beat {
		0%,
		100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
	}
`;

const FavouriteRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 10px;
	@media (max-width: 992px) {
		column-gap: 0;
	}
	& > div {
		margin-bottom: 10px;
		flex: 0 0 calc(50% - 8px);
		@media (max-width: 992px) {
			flex: 100%;
		}
	}
`;

const FavouritesView = () => {
	const { userData } = useUserContext();
	const { loading, products } = useProductContext();

	return (
		<StyledView>
			<StyledContainer>
				<Row
					noGutters
					className="d-flex flex-row justify-content-center align-items-center"
				>
					<StyledHeader>My favourites</StyledHeader>
				</Row>
				{loading ? (
					<StyledLoading height="80vh" />
				) : (
					<>
						<FavouriteRow>
							{products &&
								userData.favorites.length > 0 &&
								products
									.filter((item) => userData.favorites.indexOf(item.id) !== -1)
									.map((item) => (
										<ProductCard product={item} maxHeight="30vh" />
									))}
						</FavouriteRow>
						{userData.favorites.length === 0 && (
							<Row noGutters as={StyledNoFavourites} className="text-center">
								<Row noGutters className="py-3">
									<PSansMediumText style={{ fontSize: 20 }}>
										No favourites yet, add them by clicking on the{' '}
										<span>
											<StyledHeart size={30} />
										</span>
									</PSansMediumText>
								</Row>
							</Row>
						)}
					</>
				)}
			</StyledContainer>
		</StyledView>
	);
};

export default FavouritesView;
