import React from 'react';
import styled from 'styled-components';
import { MdArrowForward } from 'react-icons/md';
import Button from '../../../styles/shared/Button/Button';

const Booking = ({
	disabled,
	eventData,
	loadingWidget,
	openTickittoWidget,
}: any) => {
	const activeState = loadingWidget ? 'loading' : 'default';
	return (
		<div>
			<ButtonContainer loading={loadingWidget ? 1 : 0}>
				<Button
					state={!disabled ? activeState : 'disabled'}
					buttonType="primary"
					onClick={() => openTickittoWidget()}
					icon={MdArrowForward}
				>
					Book now
				</Button>
			</ButtonContainer>
			{eventData.cancellationPolicy && (
				<InfoContainer border>
					<HeadingFour>Cancellation & Refunds</HeadingFour>
					<p>{eventData.cancellationPolicy}</p>
				</InfoContainer>
			)}
			{eventData.entryNotes?.length && (
				<InfoContainer border>
					<HeadingFour>Entry Notes</HeadingFour>
					<StyledList>
						{eventData.entryNotes.map((note: any) => {
							return <li key={note}>{note}</li>;
						})}
					</StyledList>
				</InfoContainer>
			)}
			{eventData.venueName && eventData.country && (
				<InfoContainer>
					<HeadingFour>Venue</HeadingFour>
					<p>
						{eventData.venueName && `${eventData.venueName}, `}
						{eventData.venueAddress && `${eventData.venueAddress},`}{' '}
						{eventData.country}
					</p>
				</InfoContainer>
			)}
		</div>
	);
};

const ButtonContainer = styled.div<{ loading: number }>`
	border: solid 1px #f2f2f2;
	button {
		display: flex;
		justify-content: ${({ loading }) => !loading && 'space-between'};
		padding: 15px 40px;
	}
`;

const InfoContainer = styled.div<{ border?: boolean }>`
	margin-top: 24px;
	padding: 0 16px;
	border-bottom: ${({ border }) => border && '1px solid #f2f2f2'};
	p,
	li {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #191a1c;
	}
`;

const HeadingFour = styled.h4`
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #5b6166;
`;

const StyledList = styled.ul`
	padding: 0 0 16px 16px;
	margin: 0;
`;

export default Booking;
