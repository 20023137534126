import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../styles/shared/Button/Button';
import Cardholder from '../../../styles/shared/CardHolder/Cardholder';
import CardDetail from '../../../styles/shared/CardDetail';
import Description from './Description';
import cardImageLeft from '../../junoCard/card-logo-left.svg';
import cardImageRight from '../../junoCard/card-logo-right.svg';
import cards from './cards.png';
import { useUserContext } from '../../../contexts/userContext';
import { useFlexPot } from '../../../hooks';

const BrandedCard = ({ stripeCardholderId, product, card, getCard }: any) => {
	const [cardDetail, setCardDetail] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const { userData } = useUserContext();
	const flexPot = useFlexPot();

	if (!userData.currentTeam) {
		return (
			<Layout>
				<div />
				<div>
					<h2>
						You&lsquo;ll need to join a team before you can set up a Juno Card®
					</h2>
					<p>Ask your administrator to invite you to their team</p>
				</div>
			</Layout>
		);
	}

	return (
		<>
			{!showForm ? (
				<CardLayout>
					<h2>Your Juno Card®</h2>
					<Divider />
					<Card
						cardColorFrom={product.cardColorFrom || '#383939'}
						cardColorTo={product.cardColorTo || '#0f1012'}
					>
						<Juno />
						<Logo>
							{product.logoPath && (
								<img
									src={`${product.logoPath}?width=600`}
									alt={`${product.provider.title} Flex Card®`}
									loading="lazy"
								/>
							)}
						</Logo>
						<Footer>
							<div>
								{card?.id ? (
									<>
										<p>{`•••• •••• •••• ${card.last4}`}</p>
										<p>{card.cardholder}</p>
									</>
								) : (
									<BlurredContainer>
										<div />
										<div />
									</BlurredContainer>
								)}
							</div>
							<div />
						</Footer>
					</Card>
					{card?.id && (
						<>
							<Button
								type="button"
								onClick={() => setCardDetail(true)}
								state="default"
								buttonType="hollow"
								style={{ marginBottom: '50px' }}
							>
								View card details
							</Button>
							<Divider />
							{product.discountDescription && (
								<Discount>
									<Description>{product.discountDescription}</Description>
								</Discount>
							)}

							<a
								href={product.affiliateLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								Visit {product.provider.title}
							</a>
						</>
					)}
					{(!card ? !stripeCardholderId || flexPot === -1 : !card.id) && (
						<>
							<p>
								{flexPot !== -1
									? `This is a virtual Visa card you can use to spend your ${
											!flexPot ? 'JunoPoints' : 'Juno Allowance'
									  } on any of ${
											product.provider.title
									  } products — it's completely free and super easy to set up.`
									: `Your Allowance Pot hasn't been activated yet. Please check back in a little while, or check with your company admin.`}
							</p>
							<Button
								type="submit"
								state={flexPot !== -1 ? 'default' : 'disabled'}
								buttonType="primary"
								onClick={() => setShowForm(true)}
							>
								<h5>Set up your free card</h5>
							</Button>
						</>
					)}
				</CardLayout>
			) : (
				<FormContainer>
					<Cardholder
						card={card}
						getCard={getCard}
						backAction={() => setShowForm(false)}
					/>
				</FormContainer>
			)}
			{card && (
				<CardDetail open={cardDetail} setOpen={setCardDetail} cardInfo={card} />
			)}
		</>
	);
};

const Layout = styled.div`
	flex-basis: 525px;
	align-self: flex-start;
	background-color: #fff;
	@media (max-width: 992px) {
		flex-grow: 1;
	}
	div:first-child {
		background-image: url(${cards});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 380px;
		width: 100%;
	}
	div:nth-child(2) {
		padding: 0 50px 50px;
		@media (max-width: 550px) {
			padding: 0 32px 32px;
		}
	}
	h2 {
		font-family: Roobert Semi Bold;
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 34px;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 16px;
		margin: 0;
		@media (max-width: 550px) {
			padding-top: 0;
			font-size: 21px;
			line-height: 25px;
		}
	}
	p {
		font-family: Roobert Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		padding-bottom: 30px;
		margin: 0;
		text-align: center;
		@media (max-width: 550px) {
			font-size: 13px;
			line-height: 16px;
		}
	}
	a,
	a:hover {
		background: linear-gradient(87.35deg, #e08177 0%, #d67186 100%);
		border-radius: 6px;
		display: block;
		text-align: center;
		color: #fff;
		text-decoration: none;
		font-family: Roobert Regular;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		padding: 19px 0;
		@media (max-width: 550px) {
			font-size: 13px;
			line-height: 16px;
		}
	}
`;

const FormContainer = styled.div`
	flex-basis: 525px;

	@media (max-width: 992px) {
		flex-grow: 1;
		flex-basis: unset;
		width: 100%;
		max-width: unset;
	}
`;

const CardLayout = styled.div`
	flex-basis: 525px;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
	padding: 50px;
	@media (max-width: 992px) {
		flex-grow: 1;
		padding: 32px;
	}
	h2 {
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 34px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: -0.03em;
		padding-bottom: 40px;
		margin: 0;
		@media (max-width: 550px) {
			flex-grow: 1;
			font-size: 21px;
			line-height: 25px;
			padding-bottom: 26px;
		}
	}

	a {
		text-align: center;
		padding: 19px 0;
		width: 100%;
		max-width: 425px;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		font-family: 'TTInterfaces DemiBold';
		color: #fff;
		border: 1.5px solid #e4e4e4;
		background: #0a0f16;
		border-radius 100px;
		margin-top: 30px;
		@media (max-width: 550px) {
			padding: 11px 0;
			font-size: 13px;
			line-height: 16px;
			margin-top: 19px;
		}
	}

	h5 {
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		margin: 0;

		@media (max-width: 550px) {
			font-size: 13px;
			line-height: 16px;
		}
	}

	p {
		font-family: 'Roobert Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		text-align: center;

		color: #5c6165;
		margin-bottom: 40px;

		@media (max-width: 550px) {
			font-size: 13px;
		}
	}
	a:hover {
		color: #fff;
		text-decoration: none;
	}
`;

const Divider = styled.div`
	height: 1px;
	background: #e4e4e4;
	width: 100%;

	@media (max-width: 992px) {
		width: 425px;
	}

	@media (max-width: 550px) {
		width: 100%;
	}
`;

const Card = styled.div<{ cardColorFrom: string; cardColorTo: string }>`
	height: 270px;
	width: 100%;
	max-width: 425px;
	display: flex;
	background: ${({ cardColorFrom, cardColorTo }) =>
		`linear-gradient(111.86deg, ${cardColorFrom} 0%, ${cardColorTo} 100%);`}
	border-radius: 24px;
	flex-direction: column;
	margin: 40px 0;
	
	@media (max-width: 550px) {
		max-height: 186px;
		margin: 26px 0;
	}
`;

const Juno = styled.div`
	flex-basis: 64px;
	width: 64px;
	background: url(${cardImageLeft}) no-repeat left top;
	@media (max-width: 550px) {
		flex-basis: 50px;
		width: 50px;
		background-size: 128px 35px;
	}
`;

const Logo = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		height: 60px;
		@media (max-width: 550px) {
			height: 40px;
		}
	}
`;

const BlurredContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	> div:first-child {
		width: 90%;
		max-width: 191px;
		height: 16px;

		background: #f2f2f2;
		opacity: 0.12;
		border-radius: 12px;
		margin-bottom: 8px;
	}

	> div:last-child {
		width: 70%;
		max-width: 130px;

		height: 16px;

		background: #f2f2f2;
		opacity: 0.12;
		border-radius: 12px;
	}
`;

const Discount = styled.div`
	width: 100%;
	max-width: 425px;
	background: rgba(149, 182, 97, 0.1);
	border: 1px solid #95b661;
	border-radius: 6px;
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 20px 60px;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	font-family: 'Roobert Regular';
	p {
		color: #61891f;
		margin: 0;
	}
	@media (max-width: 550px) {
		padding: 13px 30px;
		font-size: 12px;
		line-height: 14px;
		margin-top: 26px;
	}
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	flex-basis: 80px;
	@media (max-width: 992px) {
		flex-basis: 60px;
	}
	> div:first-child {
		flex: 1;
		padding-left: 24px;
		padding-bottom: 24px;
		align-self: flex-end;
		p {
			padding: 0;
			margin: 0;
			color: #fff;
			text-align: left;
		}
		p:first-child {
			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-weight: 700;
			letter-spacing: 0.05em;
			font-size: 23px;
			line-height: 28px;
			font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on;
			text-shadow: 0px 1.02657px 0px rgba(0, 0, 0, 0.3);
			@media (max-width: 550px) {
				font-size: 16px;
				line-height: 20px;
			}
		}
		p:last-child {
			font-family: 'Roobert Semi Bold';
			font-style: normal;
			font-weight: 600;
			font-size: 19px;
			line-height: 23px;
			@media (max-width: 550px) {
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
	> div:last-child {
		background: url(${cardImageRight}) no-repeat right bottom;
		flex-basis: 120px;
		color: #fff;
		@media (max-width: 550px) {
			flex-basis: 86px;
			background-position: bottom 5px right 0;
			background-size: 83px 40px;
		}
	}
`;

export default BrandedCard;
