import moment from 'moment';
import { Col } from 'react-bootstrap';
import { BiLoaderAlt } from 'react-icons/bi';
import styled, { keyframes } from 'styled-components';
import {
	H3,
	Small,
	SmallTextSecondaryMedium,
} from '../../../styles/StylesV3Main';
import integrationImages from './integrationImages';

const StyledRow = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	padding-bottom: 16px;
	display: flex;
	flex-direction: row;
`;

const ImageContainer = styled.img`
	width: 40px;
	height: 40px;
	margin-right: 8px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

const StyledH3 = styled(H3)`
	width: 98px;
	height: 25px;
`;

const StyledStatus = styled(Small)`
	color: rgba(28, 174, 113, 1);
	height: 22px;
	width: 40px;
`;

const StyledActiveContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 0px;
	margin: auto;
	padding: 4px 12px 4px 12px;

	background-color: rgba(42, 185, 125, 0.1);
`;

const spinnerFrames = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled(BiLoaderAlt)`
	animation: ${spinnerFrames} 1s linear infinite;
	margin: auto;
`;

const IntegrationInfo = ({ integrationName, syncing, lastSyncDate }) => {
	return (
		<StyledRow>
			<ImageContainer src={integrationImages[integrationName]} />
			<Col noGutters>
				<StyledH3>{integrationName}</StyledH3>

				{syncing ? (
					<SmallTextSecondaryMedium>Syncing...</SmallTextSecondaryMedium>
				) : lastSyncDate ? (
					<SmallTextSecondaryMedium>
						Last sync: {moment(lastSyncDate).format('lll')}
					</SmallTextSecondaryMedium>
				) : null}
			</Col>

			{syncing ? (
				<Spinner size={20} />
			) : (
				<StyledActiveContainer>
					<StyledStatus>Active</StyledStatus>
				</StyledActiveContainer>
			)}
		</StyledRow>
	);
};

export default IntegrationInfo;
