import React, { useState, useCallback, useEffect } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import Loading from '../../../utilities/Loading';
import {
	PSansMediumText,
	ModalButton,
	PSansText,
	PMuted,
} from '../../../../styles/StylesV2';
import { Row, Col, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { BoxInputLabel, BoxInput } from '../../../../styles/StylesV2';
import { useAuth0 } from '../../../../contexts/react-auth0-spa';
import client from '../../../../api/client';
import { useUserContext } from '../../../../contexts/userContext';

const Input = styled(BoxInput)`
	background: rgba(0, 0, 0, 0.03);
	padding: 0px 10px 0px 10px;
`;

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledDivBox = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 0px 15px 0px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 991.98px) {
		width: 100%;
		max-width: 100%;
		background: white;
		color: black;
		box-shadow: 0px 0px 5px #ccc;
		-moz-box-shadow: 0px 0px 5px #ccc;
		-webkit-box-shadow: 0px 0px 5px #ccc;
	}
`;

const StyledSavedAlert = styled.div`
	margin: 20px auto 20px auto;
	@media (max-width: 991.98px) {
		margin-right: 0px;
		width: 100%;
		margin-bottom: 20px;
	}
`;

const StyledToggleSwitchDefault = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: linear-gradient(0deg, #3dde87, #3dde87), #ffffff;
	position: relative;
	cursor: pointer;
`;

const StyledToggleSwitchOff = styled.div`
	width: 33.64px;
	height: 20px;
	border-radius: 30px;
	background: #e9e9ea;
	position: relative;
	cursor: pointer;
`;

const StyledWhiteCircle = styled.div`
	position: absolute;
	width: 16.36px;
	height: 16.36px;
	border-radius: 50%;
	background: white;
	bottom: 2px;
`;

const SetupSEPA = ({ onHide, onSuccess, teamId }) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	const stripe = useStripe();
	const elements = useElements();
	const { getTokenSilently } = useAuth0();
	const [makeDefault, setMakeDefault] = useState(false);
	const [clientSecret, setClientSecret] = useState();
	const [accountHolderName, setAccountHolderName] = useState('');
	const [accountHolderEmail, setAccountHolderEmail] = useState('');
	const { addPaymentMethodTeam } = useUserContext();

	const getSetupIntentSEPA = useCallback(async () => {
		try {
			// Prepare the setupIntent payload
			const payload = {
				customerType: 'team',
				customerId: teamId,
				paymentMethodType: 'sepa_debit',
			};

			// Create or update the setupIntent
			const token = await getTokenSilently();
			client.defaults.headers.Authorization = 'Bearer ' + token;
			const responseCreate = await client.post(`/stripe/setupIntent`, payload);
			if (responseCreate && responseCreate.status === 200) {
				setClientSecret(responseCreate.data);
				return responseCreate.data;
			} else {
				return false;
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [teamId, getTokenSilently]);

	useEffect(() => {
		getSetupIntentSEPA();
	}, [getSetupIntentSEPA]);

	const IBAN_ELEMENT_OPTIONS = {
		supportedCountries: ['SEPA'],
		// Elements can use a placeholder as an example IBAN that reflects
		// the IBAN format of your customer's country. If you know your
		// customer's country, we recommend that you pass it to the Element as the
		// placeholderCountry.
		placeholderCountry: 'DE',
		style: {
			base: {
				textAlign: 'center',
				color: '#32325d',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
				':-webkit-autofill': {
					color: '#32325d',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
				':-webkit-autofill': {
					color: '#fa755a',
				},
			},
		},
	};

	const handleChange = async (event) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : '');
	};

	const handleSubmitSEPA = async (event) => {
		try {
			event.preventDefault();

			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setProcessing(true);

			// Get or update setupIntent
			const resultSetup = clientSecret || (await getSetupIntentSEPA());

			if (resultSetup) {
				// Prepare setup payload
				const payload = {
					payment_method: {
						type: 'sepa_debit',
						sepa_debit: elements.getElement(IbanElement),
						billing_details: {
							name: accountHolderName,
							email: accountHolderEmail,
						},
					},
				};

				// Submit setupIntent
				const result = await stripe.confirmSepaDebitSetup(
					resultSetup.clientSecret,
					payload
				);

				if (result.error) {
					setError(`Adding SEPA failed. ${result.error.message}`);
					setProcessing(false);
				} else {
					const response = await addPaymentMethodTeam(
						result.setupIntent.payment_method,
						teamId,
						makeDefault
					);
					if (response) {
						setError(null);
						setProcessing(false);
						setSucceeded(true);
					} else {
						setProcessing(false);
						return false;
					}
					elements.getElement(IbanElement).clear();
					onSuccess && onSuccess();
				}
			} else {
				setError(`SEPA setup could not be processed. Try again.`);
				setProcessing(false);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<form
			onSubmit={handleSubmitSEPA}
			style={{ position: 'relative' }}
			className="w-100"
		>
			<Row noGutters>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>IBAN</StyledLabel>
					<StyledDivBox>
						<IbanElement
							className="w-100"
							id="iban-element"
							options={IBAN_ELEMENT_OPTIONS}
							onChange={handleChange}
						/>
					</StyledDivBox>
				</Col>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>Account holder name</StyledLabel>
					<Input
						type="text"
						className="w-100"
						onChange={({ target: { value } }) => setAccountHolderName(value)}
						value={accountHolderName || ''}
					/>
				</Col>
				<Col xs={12} lg={12} className="px-0 py-0">
					<StyledLabel>Email</StyledLabel>
					<Input
						type="email"
						className="w-100"
						style={{ textTransform: 'none' }}
						onChange={({ target: { value } }) => setAccountHolderEmail(value)}
						value={accountHolderEmail || ''}
					/>
				</Col>
				<Col xs={12} lg={12} className="px-0 py-0 pt-4">
					<PMuted>
						By providing your payment information and confirming this payment,
						you authorise (A) With Juno Limited and Stripe, our payment service
						provider, to send instructions to your bank to debit your account
						and (B) your bank to debit your account in accordance with those
						instructions. As part of your rights, you are entitled to a refund
						from your bank under the terms and conditions of your agreement with
						your bank. A refund must be claimed within 8 weeks starting from the
						date on which your account was debited. Your rights are explained in
						a statement that you can obtain from your bank. You agree to receive
						notifications for future debits up to 2 days before they occur.
					</PMuted>
				</Col>
			</Row>
			<Row noGutters>
				<StyledLabel style={{ textTransform: 'none' }}>
					Make this direct debit your default payment method
				</StyledLabel>
				{makeDefault ? (
					<>
						<StyledToggleSwitchDefault
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(false);
							}}
						>
							<StyledWhiteCircle style={{ right: 2 }} />
						</StyledToggleSwitchDefault>
						<PSansText>On</PSansText>
					</>
				) : (
					<>
						<StyledToggleSwitchOff
							className={'mr-2'}
							onClick={() => {
								setMakeDefault(true);
							}}
							style={{ cursor: 'pointer' }}
						>
							<StyledWhiteCircle style={{ left: 2 }} />
						</StyledToggleSwitchOff>
						<PSansText>Off</PSansText>
					</>
				)}
			</Row>
			<Row
				noGutters
				className="text-left  justify-content-center align-items-center"
			>
				{/* Show any error that happens when processing the payment */}
				<StyledSavedAlert>
					{error && (
						<Alert
							className="text-center my-auto mx-auto p-1"
							variant={'danger'}
						>
							<PSansMediumText className="card-error" role="alert">
								{error}
							</PSansMediumText>
						</Alert>
					)}

					{/* Show a success message upon completion */}

					{succeeded && (
						<Alert
							className="text-center my-auto mx-auto p-1 "
							variant={'success'}
						>
							<PSansMediumText
								className={
									succeeded ? 'result-message' : 'result-message d-none'
								}
							>
								SEPA Direct Debit successfully added
							</PSansMediumText>
						</Alert>
					)}
				</StyledSavedAlert>
			</Row>
			<Row
				noGutters
				className="justify-content-around align-items-center py-2 mt-2"
			>
				<ModalButton type="button" version="light" onClick={onHide}>
					<PSansText>Cancel</PSansText>
				</ModalButton>
				<ModalButton disabled={processing || disabled || succeeded} id="submit">
					<span id="button-text">
						{processing ? (
							<Loading className="d-flex align-items-center justify-content-center h-100 w-100" />
						) : (
							'Add'
						)}
					</span>
				</ModalButton>
			</Row>
		</form>
	);
};

export default SetupSEPA;
