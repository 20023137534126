import React from 'react';
import styled, { keyframes } from 'styled-components';
import { PSansMediumText } from '../../styles/StylesV2';

const StatusColours = {
	completed: {
		text: '#39ae71',
		background: '#eaf8f2',
	},
	refunded: {
		text: '#1c5fae',
		background: '#eaf5f8',
	},
	declined: {
		text: '#bf165d',
		background: '#fbe8f0',
	},
	pending: {
		text: '#000',
		background: '#f0f0f0',
	},
};

const StatusAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const StatusContainer = styled.div`
	padding: ${(props) => (props.background ? '5px 10px' : '0px')};
	display: inline-block;
	width: ${(props) => (props.width ? props.width : 'auto')};
	background-color: ${(props) =>
		props.background ? props.colour.background : 'transparent'};
	animation-name: ${StatusAnimation};
	animation-duration: 2s;
`;

const StatusText = styled(PSansMediumText)`
	color: ${(props) => props.colour.text};
`;

const StatusInfo = ({
	text,
	status = 'success',
	background = true,
	animate = false,
	...props
}) => {
	const colour = StatusColours[status] || StatusColours['success'];

	return (
		<StatusContainer
			key={text}
			colour={colour}
			background={background}
			{...props}
		>
			<StatusText colour={colour}>{text}</StatusText>
		</StatusContainer>
	);
};

export default StatusInfo;
