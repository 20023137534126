/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row } from 'react-bootstrap';
import {
	StyledInputBox,
	LoginButton,
	StyledOR,
	StyledORText,
	StyledORDivider,
	SocialLoginButton,
	StyledLabelForm,
	StyledPasswordContainer,
	StyledForgotPassword,
	StyledForgotPwdText,
	StyledShowPwd,
	StyledErrorMessage,
} from '../../styles/Form';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import PasswordOverlay from './PasswordOverlay';

const StyledSpan = styled.span`
	font-family: 'Roboto Bold';
	font-size: 0.875rem;
	margin: 0px;
`;

const StyledImage = styled.img`
	max-height: 20px;
	object-fit: contain;
`;

const StyledMuted = styled.p`
	font-family: 'Product Sans Medium';
	color: #8f8f8f;
	font-size: 0.875rem;
	margin-bottom: 3px;
`;

const StyledA = styled.a`
	font-family: 'Product Sans Medium';
	color: #8f8f8f;
	font-size: 0.875rem;
`;

const StyledInputContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const AuthoriseInvite = ({
	errorCallback,
	invite,
	handleChangePasswordReset,
}) => {
	const [passwordShown, setPasswordShown] = useState(false);
	const [position, setPosition] = useState('right');
	const [error, setError] = useState('');
	const {
		signUpWithCredentialsInvite,
		loginWithSocialInvite,
		loginWithCredentialsInvite,
	} = useAuth0();

	const togglePasswordVisiblity = (password) => {
		setPasswordShown(!password);
	};

	useEffect(() => {
		if (window.innerWidth < 998) setPosition('bottom');
	}, [position]);

	const validationSchema = Yup.object({
		email: Yup.string()
			.email('This email is not in a recognised format')
			.required('Please enter your email address'),
		password: Yup.string()
			.required('Please enter your password')
			.min(8, 'Password is too short - should be 8 chars minimum.')
			.matches(
				/^(?=.*[A-Za-z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/,
				'Password must contain at least one lower case, one upper case, one number and one among these special characters !@#$%^&*.'
			),
	});

	const handleSignUp = async (values) => {
		try {
			if (invite.user) {
				return await loginWithCredentialsInvite(
					values.email,
					values.password,
					invite.invite
				);
			}

			return await signUpWithCredentialsInvite(
				values.email,
				values.password,
				invite.invite
			);
		} catch (err) {
			setError(err.description);
			errorCallback(err.description);
			console.error(err);
		}
	};

	const handleLogIn = async () => {
		try {
			return await loginWithSocialInvite('google-oauth2', invite.invite);
		} catch (err) {
			setError(err.description);
			errorCallback(err.description);
			console.error(err);
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					email:
						invite.invite.email === 'pinnacle invite'
							? ''
							: invite.invite.email,
					password: '',
				}}
				enableReinitialize
				validateOnChange={false}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleSignUp(values);
					setSubmitting(false);
				}}
			>
				{({ values, submitting, handleSubmit, setFieldValue }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Row noGutters>
								<StyledLabelForm htmlFor="email">Email</StyledLabelForm>
								<Field
									type="email"
									name="email"
									disabled={invite.invite.email !== 'pinnacle invite'}
									placeholder="you@example.com"
									as={StyledInputBox}
								/>
								<ErrorMessage name="email" component={StyledErrorMessage} />
							</Row>
							<Row noGutters>
								<StyledPasswordContainer>
									<StyledLabelForm htmlFor="password">Password</StyledLabelForm>
									<StyledShowPwd
										onClick={() => togglePasswordVisiblity(passwordShown)}
									>
										{!passwordShown ? 'Show' : 'Hide'}
									</StyledShowPwd>
								</StyledPasswordContainer>
								<StyledInputContainer>
									<Field
										type={passwordShown ? 'text' : 'password'}
										name="password"
										placeholder="Please enter your password"
										as={StyledInputBox}
										onChange={({ target: { value } }) => {
											if (error !== '') {
												setError('');
												errorCallback('');
											}
											setFieldValue('password', value);
										}}
									/>
									{!invite.user && (
										<PasswordOverlay values={values} position={position} />
									)}
								</StyledInputContainer>

								<ErrorMessage name="password" component={StyledErrorMessage} />
								{error !== '' && (
									<StyledErrorMessage>{error}</StyledErrorMessage>
								)}
							</Row>

							{invite.user && (
								<Row noGutters>
									<StyledForgotPassword
										onClick={() => handleChangePasswordReset(true)}
									>
										<StyledForgotPwdText>
											Forgot your password?
										</StyledForgotPwdText>
									</StyledForgotPassword>
								</Row>
							)}

							<Row noGutters>
								<LoginButton size={1} type="submit" disabled={submitting}>
									{invite.user ? 'Sign in' : 'Sign up'}
								</LoginButton>
							</Row>
						</Form>
					);
				}}
			</Formik>
			<Row noGutters>
				<StyledOR>
					<StyledORDivider />
					<StyledORText>OR</StyledORText>
					<StyledORDivider />
				</StyledOR>
			</Row>
			<Row noGutters>
				<SocialLoginButton
					type="button"
					onClick={() =>
						handleLogIn().then((err) => {
							if (err) {
								errorCallback(err.message);
							}
						})
					}
					version="light"
					className="w-100"
				>
					<StyledImage src="../images/login/google/g-logo.png" />
					<StyledSpan className="ml-3 my-auto">
						{invite.user ? 'Sign in with Google' : 'Sign up with Google'}
					</StyledSpan>
				</SocialLoginButton>
			</Row>

			{!invite.user && (
				<Row noGutters className="px-1 text-center">
					<StyledMuted className="my-3 ">
						By signing up, you agree to our&nbsp;
						<StyledA
							style={{ cursor: 'pointer' }}
							target="_blank"
							href="/privacy-policy"
						>
							Privacy Policy
						</StyledA>
						&nbsp;and&nbsp;
						<StyledA
							style={{ cursor: 'pointer' }}
							target="_blank"
							href="https://www.withjuno.com/juno-terms-and-conditions"
						>
							Terms and Conditions.
						</StyledA>
					</StyledMuted>
				</Row>
			)}
		</>
	);
};

export default AuthoriseInvite;
