import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TeamInvoices from './ManagePlan/TeamInvoice';
import TeamBillingWrapper from './ManagePlan/TeamBilling';
import PaymentMethodWrapper from './ManagePlan/TeamPayments/PaymentMethodWrapper';
import { Paragraph } from '../../styles/StylesV3Main';

const ManagePlanWrapper = ({ team, handleUpdate }) => {
	return (
		<Wrapper>
			{team.stripeSubscriptionId ? (
				<>
					<TeamBillingWrapper team={team} handleUpdate={handleUpdate} />
				</>
			) : (
				<>
					<Container>
						<Paragraph>
							This team currently has no active subscription. Contact support to
							sign up to a plan.
						</Paragraph>
					</Container>
				</>
			)}

			<PaymentMethodWrapper team={team} />

			<TeamInvoices teamId={team.id} handleUpdate={handleUpdate} />
		</Wrapper>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px;
	width: 100%;
	height: 100%;
	background: #f8f5f4;
	margin: 24px 0px;

	@media (max-width: 1250px) {
		flex-direction: column;
		padding: 20px;
	}
`;

const Wrapper = styled.div`
	padding: 40px 80px;

	@media (max-width: 1250px) {
		padding: 0px;
	}
`;

export default ManagePlanWrapper;
