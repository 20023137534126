import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import { useClient, useCurrentTeam } from '../../../hooks';
import Button from '../Button/Button';
import Modal from '../Modal';

const ModalOpacityBackground = styled.div`
	background: rgba(0, 0, 0, 0.4);
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
`;

const CardModalContainer = styled.div`
	width: 500px;
	position: relative;
	background: white;
	@media (max-width: 550px) {
		width: 90vw;
	}
	@media (max-width: 400px) {
		width: 85vw;
	}
`;

const Label = styled.label`
	font-style: normal;
	font-size: 14px;
	text-transform: capitalize;
`;

const CardInfoDiv = styled.div`
	margin-bottom: 20px;
`;

const Input = styled.div`
	width: 100%;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 15px;
	margin-bottom: 20px;
	color: rgb(0, 0, 0);
	font-size: 16px;
	font: normal normal 400 16px/16px 'Product Sans Regular';
	div {
		height: 16px;
	}
`;

const SplitColumns = styled.div`
	display: grid;
	grid-template-columns: 49% 49%;
	grid-gap: 2%;
`;

const Heading2 = styled.h2`
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
	text-align: center;
	margin-bottom: 32px;
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const CardModalBody = styled.div`
	padding: 24px;
`;

const CardDetails = ({
	open,
	setOpen,
	cardInfo,
}: {
	open: boolean;
	cardInfo: {
		id: string | null;
		last4: string | null;
		cardholder: string | null;
		active: boolean | null;
		city: string;
		country: string;
		dob: string;
		fullName: string;
		line1: string;
		line2: string;
		phoneNumber: string | null;
		postCode: string;
		budgetId: string;
		cvc: string | undefined;
		expire: string;
		number: string | undefined;
	};
	setOpen: (status: boolean) => void;
}) => {
	const stripe = useStripe();
	const client = useClient();
	const team = useCurrentTeam();

	const numberRef = useRef(null);
	const cvcRef = useRef(null);

	const showSecureCardDetails = async () => {
		if (!stripe) return;

		const { nonce } = await stripe.createEphemeralKeyNonce({
			issuingCard: cardInfo.id!,
		});

		const { data } = await client.post(
			`${config.platform.HOST}/payments/authorized/cards/${team.id}/ephemeral-key`,
			{ cardId: cardInfo.id, nonce }
		);

		const { ephemeralKeySecret } = data;

		const number = stripe.elements().create('issuingCardNumberDisplay', {
			issuingCard: cardInfo.id!,
			nonce,
			ephemeralKeySecret,
			style: {
				base: {
					fontWeight: '400',
					fontSize: '16px',
					color: 'rgb(0,0,0)',
				},
			},
		});

		const cvc = stripe.elements().create('issuingCardCvcDisplay', {
			issuingCard: cardInfo.id!,
			nonce,
			ephemeralKeySecret,
			style: {
				base: {
					fontWeight: '400',
					fontSize: '16px',
					color: 'rgb(0,0,0)',
				},
			},
		});

		if (document.querySelector('#card-number')) {
			number.mount('#card-number');
			cvc.mount('#cvc');
		}
	};

	useEffect(() => {
		if (open) showSecureCardDetails();
	}, [open, stripe]);

	return (
		<Modal
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			aria-labelledby="Card details"
			aria-describedby="View your card details"
		>
			<ModalOpacityBackground onClick={() => setOpen(false)} />
			<CardModalContainer>
				<CardModalBody>
					<Heading2>Card details</Heading2>

					<div>
						<Label>Cardholder</Label>
						<Input tabIndex={-1}>{cardInfo.cardholder}</Input>
					</div>
					<div>
						<Label>Card Number</Label>
						<Input ref={numberRef} id="card-number">
							Loading...
						</Input>
					</div>
					<SplitColumns>
						<div>
							<Label>Expiration</Label>
							<Input>{cardInfo.expire}</Input>
						</div>
						<div>
							<Label>CVC</Label>
							<Input ref={cvcRef} id="cvc">
								Loading...
							</Input>
						</div>
					</SplitColumns>
					<CardInfoDiv>
						<Label>Billing Address</Label>
						{cardInfo.line1 && <div>{cardInfo.line1}</div>}
						{cardInfo.line2 && <div>{cardInfo.line2}</div>}
						{cardInfo.city && <div>{cardInfo.city}</div>}
						{cardInfo.postCode && <div>{cardInfo.postCode}</div>}
						{cardInfo.country && <div>{cardInfo.country}</div>}
					</CardInfoDiv>
					{cardInfo.phoneNumber && (
						<CardInfoDiv>
							<Label>Phone Number</Label>
							<div>{cardInfo.phoneNumber}</div>
						</CardInfoDiv>
					)}
					<StyledHr />
					<Button
						buttonType="primary"
						state="default"
						onClick={() => {
							setOpen(false);
						}}
					>
						Done
					</Button>
				</CardModalBody>
			</CardModalContainer>
		</Modal>
	);
};

export default CardDetails;
