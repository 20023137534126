import React, {
	useState,
	useRef,
	useMemo,
	ReactNode,
	CSSProperties,
} from 'react';
import styled from 'styled-components';
import { default as BaseCarousel } from 'react-slick';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { H2 } from '../../styles/StylesV2';

const StyledArrow = styled.button`
	margin: 0px;
	border-radius: 24px;
	background-color: rgba(0, 0, 0, 0.05);
	width: 48px;
	height: 48px;
	border: 0px;
	color: ${({ disabled }) => (disabled ? 'rgba(0,0,0,0.05)' : 'black')};
`;

const SDiv = styled.div<{ paddingLeft?: string; paddingRight?: string }>`
	padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '0xp')};
	padding-right: ${({ paddingRight }) => (paddingRight ? paddingRight : '0xp')};
`;

const SContainer = styled.div``;

const SDesktop = styled.div`
	display: block;
	@media only screen and (max-width: 480px) {
		display: none;
	}
`;

const SCol = styled(Col)`
	display: block;
	@media only screen and (max-width: 575.98px) {
		display: none;
	}
`;

const SMobile = styled.div`
	display: none;
	@media only screen and (max-width: 480px) {
		display: block;
	}
`;

const TitleRow = styled(Row)`
	width: 100%;
	margin-top: 32px;
	margin-bottom: 32px;
`;

const ArrowSettings = {
	size: 15,
	strokeWidth: 1,
};

type CarouselProps = {
	loading: boolean;
	title: string;
	itemPadding: number;
	draggable: boolean;
	initialSlide: number;
	items: ReactNode[];
	style: CSSProperties;
};

const Carousel = ({
	loading = false,
	title = '',
	itemPadding = 0,
	draggable = false,
	initialSlide = 0,
	items = [],
	style,
}: CarouselProps) => {
	const [currentSlide, setCurrentSlide] = useState(initialSlide);
	const sliderRef = useRef<BaseCarousel>(null);

	const settings = {
		arrows: false,
		draggable: draggable,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		rows: 1,
		slidesToScroll: 1,
		initialSlide: initialSlide,
		beforeChange: (current: number, next: number) => {
			setCurrentSlide(next < 0 ? 0 : next);
		},
		responsive: [
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 1080,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					infinite: true,
					dots: true,
				},
			},
		],
	};

	const slidesToShow = useMemo(
		() =>
			settings.responsive
				.sort((a, b) => (a.breakpoint > b.breakpoint ? -1 : 1))
				.filter((item) => item.breakpoint <= window.innerWidth).length > 0
				? settings.responsive
						.sort((a, b) => (a.breakpoint > b.breakpoint ? -1 : 1))
						.filter((item) => item.breakpoint <= window.innerWidth)[0].settings
						.slidesToShow
				: settings.slidesToShow,
		[settings]
	);

	return (
		<SContainer style={style}>
			<TitleRow noGutters className="justify-content-between">
				<Col className="text-left my-auto" xs={8} md={8} lg={10}>
					<H2>{title}</H2>
				</Col>
				<SCol md={4} lg={2} xs={4} className="my-auto">
					<Row noGutters className="justify-content-end align-items-center">
						<StyledArrow
							disabled={currentSlide === 0}
							onClick={() => {
								sliderRef.current && sliderRef.current.slickPrev();
							}}
							style={{ marginRight: '24px' }}
						>
							<BsChevronLeft {...ArrowSettings} />
						</StyledArrow>
						<StyledArrow
							disabled={
								items.length <= slidesToShow ||
								currentSlide === items.length - 1
							}
							onClick={() => {
								sliderRef.current && sliderRef.current.slickNext();
							}}
						>
							<BsChevronRight {...ArrowSettings} />
						</StyledArrow>
					</Row>
				</SCol>
			</TitleRow>
			{loading ? (
				<div
					style={{
						height: '20vh',
						borderRadius: '10px',
						marginTop: '30px',
						marginBottom: '30px',
						marginLeft: '30px',
						marginRight: '30px',
					}}
				>
					<Skeleton
						style={{
							borderRadius: '15px',
							paddingLeft: '5%',
							paddingRight: '0%',
						}}
						delay={1}
						height="100%"
						width="30%"
					/>
					<Skeleton
						style={{
							borderRadius: '15px',
							marginLeft: '5%',
							marginRight: '5%',
						}}
						delay={1}
						height="100%"
						width="30%"
					/>
					<Skeleton
						style={{
							borderRadius: '15px',
							paddingLeft: '0%',
							paddingRight: '5%',
						}}
						delay={1}
						height="100%"
						width="30%"
					/>
				</div>
			) : (
				<>
					<SDesktop>
						<BaseCarousel {...settings} arrows={false} ref={sliderRef}>
							{/* {sliderRef.current && */}
							{items.map((item, index) => (
								<SDiv
									paddingLeft={
										index === currentSlide ? '0px' : `${itemPadding / 2}px`
									}
									paddingRight={
										index ===
										(slidesToShow + currentSlide - 1 >= items.length
											? currentSlide % (slidesToShow - 1)
											: slidesToShow + currentSlide - 1)
											? '0px'
											: `${itemPadding / 2}px`
									}
									key={index}
								>
									{item}
								</SDiv>
							))}
						</BaseCarousel>
					</SDesktop>
					<SMobile>
						{items.slice(0, 3).map((item, index) => (
							<Col xs={12} className="px-0 py-0" key={index}>
								<SDiv
									paddingLeft={index === currentSlide ? '0px' : `0px`}
									paddingRight={
										index ===
										(slidesToShow + currentSlide - 1 >= items.length
											? currentSlide % (slidesToShow - 1)
											: slidesToShow + currentSlide - 1)
											? '0px'
											: `0px`
									}
								>
									{item}
								</SDiv>
							</Col>
						))}
					</SMobile>
				</>
			)}
		</SContainer>
	);
};

export default Carousel;
