import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { useProductContext } from '../../contexts/productContext';
import { Circle, Container } from '../../styles/ProductStyles';
import { H1, ParagraphLarge } from '../../styles/StylesV3Main';
import { handleBackNavigation } from '../utilities/Functions';
import Loading from '../utilities/Loading';
import CreateReimbursementForm from './CreateReimbursementForm';
import config from '../../config';
import OrderResultPage from '../productComponent/OrderResultPage';
import { useCurrentTeam } from '../../hooks';

const BackgroundContainer = styled.div`
	width: 100%;
	background-color: #f8f8f8;
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const FormContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	padding: 48px;

	border: none;

	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 26px;

	&::backdrop {
		background: rgba(0, 0, 0, 0.4);
	}

	@media (max-width: 992px) {
		margin-top: 20px;
		padding: 20px;
	}
`;

const CardImage = styled.img`
	width: 100%;
	height: 360px;
	object-fit: cover;
	object-position: center;
	padding-bottom: 20px;
`;

const ParaTextSecondary = styled(ParagraphLarge)`
	font-family: 'TTInterfaces Regular';
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	@media (max-width: 992px) {
		padding-right: 0%;
	}
	font-size: 18px;
`;

const Headings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	text-align: center;

	h3 {
		font-family: 'TTInterfaces Demibold';
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		margin: 0;
	}

	h4 {
		font-family: 'TTInterfaces Medium';
		font-size: 18px;
		line-height: 140%;
		color: rgba(0, 0, 0, 0.35);
		margin: 0;
	}
`;

const StyledSubcript = styled.h5`
	font-family: 'TTInterfaces DemiBold';
	font-size: 14px;
	color: #5b6166;
`;

const CreateReimbursement = () => {
	const history = useHistory();
	const [booking, setBooking] = useState(null);
	const { loading: productLoading, customProducts } = useProductContext();
	const { fees = true } = useCurrentTeam();
	const { PRODUCT, FEES_SERVICE, NO_FEES_SERVICE } =
		config.customPages.reimbursement;
	const product = !productLoading
		? customProducts.find((prod) => prod.id === PRODUCT)
		: null;
	const serviceId = fees ? FEES_SERVICE : NO_FEES_SERVICE;
	const service = product?.productTypes.find(
		(productType) => productType.id === serviceId
	);

	return (
		<>
			{!booking && (
				<BackgroundContainer>
					<Container>
						<Row noGutters className="justify-content-start align-items-start">
							<Circle size="48px" onClick={() => handleBackNavigation(history)}>
								<MdArrowBack size={20} />
							</Circle>
						</Row>
						<Row style={{ marginTop: '20px' }}>
							<Col sm={12} md={6}>
								{(productLoading || !product) && (
									<LoadingContainer>
										<Loading />
									</LoadingContainer>
								)}
								{!productLoading && product && (
									<>
										<CardImage loading="lazy" src={product.imagePath} />
										<StyledSubcript>{product.provider.title}</StyledSubcript>
										<H1 className="mt-2">{product.title}</H1>
										<ParaTextSecondary className="mt-3">
											<Markdown>{service.description}</Markdown>
										</ParaTextSecondary>
									</>
								)}
							</Col>
							<Col sm={12} md={6}>
								<FormContainer>
									<Headings>
										<h3>Submit an expense</h3>
										<h4>
											Want to purchase something that doesn’t fall within one of
											our expense categories?{' '}
											<a
												href="https://withjuno.typeform.com/to/LziLsgN3"
												target="_blank"
												style={{ flex: '1 1 30%' }}
												rel="noreferrer"
											>
												Add your own brand or product to the marketplace.
											</a>
										</h4>
									</Headings>
									<CreateReimbursementForm setBooking={setBooking} />
								</FormContainer>
							</Col>
						</Row>
					</Container>
				</BackgroundContainer>
			)}
			{booking && (
				<OrderResultPage
					product={product}
					service={service}
					cost={booking.cost}
					fee={booking.fee}
					options={booking.options}
					enquiry={booking.enquiry}
					booking={booking}
					type="reimbursement"
					handleCancel={() => {
						setBooking(null);
						history.push(`/reimbursement`);
					}}
				/>
			)}
		</>
	);
};

export default CreateReimbursement;
