import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import { useClient, useCurrentTeam } from '../../../hooks';
import Button from '../Button/Button';
import Modal from '../Modal';

const ModalOpacityBackground = styled.div`
	background: rgba(0, 0, 0, 0.4);
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
`;

const CardModalContainer = styled.div`
	width: 500px;
	position: relative;
	background: white;
	@media (max-width: 550px) {
		width: 90vw;
	}
	@media (max-width: 400px) {
		width: 85vw;
	}
`;

const Label = styled.label`
	font-style: normal;
	font-size: 14px;
	text-transform: capitalize;
`;

const Input = styled.div`
	width: 100%;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 15px;
	margin-bottom: 20px;
	color: rgb(0, 0, 0);
	font-size: 16px;
	font: normal normal 400 16px/16px 'Product Sans Regular';
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const CardModalBody = styled.div`
	padding: 24px;
`;

const Pin = ({
	pinOpen,
	setPinOpen,
	cardId,
}: {
	pinOpen: boolean;
	cardId: string | null;
	setPinOpen: (status: boolean) => void;
}) => {
	const stripe = useStripe();
	const client = useClient();
	const team = useCurrentTeam();

	const pinRef = useRef(null);

	const showSecurePin = async () => {
		if (!stripe) return;

		const { nonce } = await stripe.createEphemeralKeyNonce({
			issuingCard: cardId!,
		});

		const { data } = await client.post(
			`${config.platform.HOST}/payments/authorized/cards/${team.id}/ephemeral-key`,
			{ cardId, nonce }
		);

		const { ephemeralKeySecret } = data;

		const pin = stripe.elements().create('issuingCardPinDisplay', {
			issuingCard: cardId!,
			nonce,
			ephemeralKeySecret,
			style: {
				base: {
					fontWeight: '400',
					fontSize: '16px',
					color: 'rgb(0,0,0)',
				},
			},
		});

		if (document.querySelector('#pin')) {
			pin.mount('#pin');
		}
	};

	useEffect(() => {
		if (pinOpen) showSecurePin();
	}, [pinOpen, stripe]);

	return (
		<Modal
			open={pinOpen}
			onClose={() => {
				setPinOpen(false);
			}}
			aria-labelledby="Pin modal"
			aria-describedby="View your pin"
		>
			<ModalOpacityBackground onClick={() => setPinOpen(false)} />
			<CardModalContainer>
				<CardModalBody>
					<div>
						<Label>PIN</Label>
						<Input ref={pinRef} id="pin">
							Loading...
						</Input>
					</div>
					<StyledHr />
					<Button
						buttonType="primary"
						state="default"
						onClick={() => {
							setPinOpen(false);
						}}
					>
						Close
					</Button>
				</CardModalBody>
			</CardModalContainer>
		</Modal>
	);
};

export default Pin;
