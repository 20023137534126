import React, { CSSProperties, ForwardedRef, forwardRef } from 'react';
import Select, { GroupBase, Props, StylesConfig } from 'react-select';

/**
 * Styling of this component can be achieved using custom styles objects of type CSSProperties
 * See https://react-select.com/styles for more information on which objects should be created/overriden
 */

const customControlStyle: CSSProperties = {
	border: '0px',
	borderRadius: '3px',
	background: 'rgba(0,0,0,0.03)',
	minHeight: '38px',
	fontSize: '18px',
	fontFamily: 'Roobert Regular',
	fontWeight: '400',
	lineHeight: '160%',
	color: '#191A1C',
	paddingLeft: '8px',
};

const customMultiValue: CSSProperties = {
	backgroundColor: 'rgb(230, 230, 230)',
	borderRadius: '2px',
	display: 'flex',
	// margin: '2px',
	minWidth: '0px',
	boxSizing: 'border-box',
};

const customMultiValueLabel: CSSProperties = {
	// borderRadius: '2px',
	color: 'rgb(51, 51, 51)',
	fontSize: '12px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

const customValueContainer: CSSProperties = {
	minHeight: '56px',
};

const customIndicatorSeparator: CSSProperties = {
	visibility: 'hidden',
};

export type Option = { value: string; label: string };
const CustomSelect = forwardRef(
	(props: Props<Option, true, GroupBase<Option>>, ref: ForwardedRef<any>) => {
		const customStyles: StylesConfig<Option, true, GroupBase<Option>> = {
			control: (provided) => {
				return { ...provided, ...customControlStyle };
			},
			multiValue: (provided) => {
				return { ...provided, ...customMultiValue };
			},
			multiValueLabel: (provided) => {
				return { ...provided, ...customMultiValueLabel };
			},
			indicatorSeparator: (provided) => {
				return { ...provided, ...customIndicatorSeparator };
			},
			valueContainer: (provided) => {
				return { ...provided, ...customValueContainer };
			},
		};

		return (
			<Select
				ref={ref}
				isMulti
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				styles={customStyles}
				theme={(theme) => ({ ...theme, borderRadius: 0 })}
			/>
		);
	}
);

export default CustomSelect;
