import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { Circle, Container } from '../../styles/ProductStyles';
import {
	H1,
	H2,
	H4,
	H5,
	H6,
	Separator,
	ParagraphLarge,
} from '../../styles/StylesV3Main';
import config from '../../config';
import ProductServiceCard from './ProductServiceCard';
import RecommendedProduct from './RecommendedProduct';
import ProviderInfo from './ProviderInfo';
import {
	handleBackNavigation,
	getEnquiryOrPoints,
} from '../utilities/Functions';
import FavouritesButton from '../utilities/FavouritesButton';
import { useUserContext } from '../../contexts/userContext';
import { useClient, useCurrentTeam, useFlexPot } from '../../hooks';
import BrandedCard from './BrandedCard';
import Description from './BrandedCard/Description';

const BackgroundWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: ${(props) =>
		props.background
			? props.background
			: props.theme.mainColors.color.background};

	@media (max-width: 992px) {
		max-width: 100%;
	}
`;

const H5TextSecondary = styled(H5)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const H6TextSecondary = styled(H6)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
`;

const ParaTextSecondary = styled(ParagraphLarge)`
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	padding-right: 30%;
	@media (max-width: 992px) {
		padding-right: 0%;
	}
`;

const TitleContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	aling-items: flex-start;
	@media (max-width: 992px) {
		width: 100%;
		margin-top: 32px;

		height: fit-content;
	}
`;

const VerticalSeparator = styled.div`
	width: 1px;
	height: 46px;
	background: ${(props) => props.theme.mainColors.color.separator};
`;

const ProductPoints = styled.div`
	width: 100%;
	height: 94px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 3px;
	border: 1.5px solid ${(props) => props.theme.mainColors.color.separator};
	margin-right: 30%;
	@media (max-width: 991px) {
		margin-right: 0%;
	}
`;

const StyledImage = styled.img`
	width: 100%;
	height: 327px;
	object-fit: cover;
	object-position: ${(props) =>
		props.verticalOffset ? `50% ${props.verticalOffset}%` : '50% 50%'};
	max-height: 50vh;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.19) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	@media (max-width: 991px) {
		height: 100%;
	}
`;

const ServiceDetails = styled.div`
	width: 100%;
	@media (max-width: 991px) {
		margin-right: 0px;
	}
`;

const ServiceContainer = styled.div`
	width: 100%;
	max-width: 1250px;
	padding: 64px 0px;
	margin: 0px 152px;
	height: fit-content;
	display: flex;
	justify-content: start;
	align-items: center;
	background: ${(props) => props.theme.mainColors.color.background};
	@media (max-width: 1250px) {
		padding: 64px;
		margin: 0px;
	}
	@media (max-width: 1250px) {
		padding: 64px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 50px 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const ContainerView = styled.div`
	width: 100%;
	margin: 0px 133px;
	height: fit-content;
	max-width: 1250px;
	@media (max-width: 1200px) {
		padding: 0px 64px;
		max-width: 100%;
	}
	@media (max-width: 992px) {
		padding: 0px 20px;
		margin: 0px 20px;
		max-width: 100%;
	}
`;

const StyledColumn1 = styled(Col)`
	order: 1;
	@media (max-width: 992px) {
		order: 2;
	}
`;
const StyledColumn2 = styled(Col)`
	order: 2;
	@media (max-width: 992px) {
		order: 1;
	}
`;

const DesktopView = styled.div`
	display: block;
	@media (max-width: 992px) {
		display: none;
	}
`;

const DesktopRow = styled(Row)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: auto;
	& > a {
		/*
    1/3  - 3 columns per row
    10px - spacing between columns 
  */
		box-sizing: border-box;
		margin: 0px 16px 16px 0px;
		width: ${({ length }) =>
			length >= 4 && `calc(1 / 4 * 100% - (1 - 1 / 4) * 16px);`};
	}
	& > a:nth-child(4n) {
		margin-right: 0;
	}

	&::after {
		content: '';
		flex: auto;
	}

	@media (min-width: 1250px) {
		width: 1250px;
	}
	@media (max-width: 1250px) {
		width: 100%;
		margin: 0px 10px 10px 0px;
		justify-content: start;
	}
`;

const MobileView = styled.div`
	display: none;

	@media (max-width: 992px) {
		display: block;
	}
`;

const ProductPage = ({ product, service }: any) => {
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (product.type === 'Card') return <BrandedCardProduct product={product} />;

	return (
		<>
			<BackgroundWrapper>
				<Container>
					<Row noGutters className="justify-content-start align-items-start">
						<Circle
							size="48px"
							onClick={() => {
								handleBackNavigation(history);
							}}
						>
							<MdArrowBack size={20} />
						</Circle>
					</Row>

					<Row
						noGutters
						className="justify-content-between align-items-start mt-5"
					>
						<Col lg={6} xs={12} as={StyledColumn1}>
							<TitleContainer>
								<H5TextSecondary>{product.provider.title}</H5TextSecondary>
								<H1 className="mt-2">{product.title}</H1>
								<FavouritesButton product={product} />
								<ParaTextSecondary className="mt-3">
									<Description>{product.description}</Description>
								</ParaTextSecondary>

								<Row
									noGutters
									className="mt-3 justify-content-start align-items-center"
									style={{ width: '100%' }}
								>
									<ProductPoints>
										<Col lg={6} xs={6}>
											<Row noGutters className="mb-2">
												<H6TextSecondary>Juno Points</H6TextSecondary>
											</Row>

											<H4>{getEnquiryOrPoints(product)}</H4>
										</Col>

										<Col lg={6} xs={6}>
											<Row>
												<VerticalSeparator />
												<Col>
													<Row noGutters className="mb-2">
														<H6TextSecondary>Type</H6TextSecondary>
													</Row>
													<H4>{product.type}</H4>
												</Col>
											</Row>
										</Col>
									</ProductPoints>
								</Row>
							</TitleContainer>
						</Col>
						<Col lg={6} xs={12} as={StyledColumn2}>
							<StyledImage
								loading="lazy"
								src={
									product.imagePath
										? `${product.imagePath}?width=1200`
										: config.PLACEHOLDER_IMAGE
								}
								verticalOffset={
									product.verticalOffset ? product.verticalOffset : null
								}
							/>
						</Col>
					</Row>
				</Container>
			</BackgroundWrapper>
			<BackgroundWrapper>
				<ContainerView>
					<Separator />
				</ContainerView>
			</BackgroundWrapper>

			<BackgroundWrapper>
				<ServiceContainer>
					<ServiceDetails>
						<H2 className=" pb-2 mb-4">Pick your option</H2>
						<Row noGutters className="justify-content-start align-items-start">
							<Col lg={12} xs={12} className="px-0 py-0">
								<Row noGutters>
									<DesktopView>
										<DesktopRow noGutters length={product.productTypes.length}>
											{product.productTypes
												.sort((a, b) => (a.baseCost > b.baseCost ? 1 : -1))
												.map((productType) => (
													<Link
														className="px-0 py-0"
														to={`/product/${product.id}/service/${productType.id}/details`}
														style={{
															textDecoration: 'none',
														}}
														key={productType.id}
													>
														<ProductServiceCard
															productType={productType}
															product={product}
															selected={service?.id === productType.id}
														/>
													</Link>
												))}
										</DesktopRow>
									</DesktopView>
									<MobileView>
										<Row>
											{product.productTypes
												.sort((a, b) => (a.baseCost > b.baseCost ? 1 : -1))
												.map((productType) => (
													<Col xs={12} md={6} key={productType.id}>
														<Link
															to={`/product/${product.id}/service/${productType.id}`}
															style={{
																textDecoration: 'none',
															}}
														>
															<ProductServiceCard
																productType={productType}
																product={product}
																selected={service?.id === productType.id}
															/>
														</Link>
													</Col>
												))}
										</Row>
									</MobileView>
								</Row>
							</Col>
						</Row>
					</ServiceDetails>
				</ServiceContainer>
			</BackgroundWrapper>

			<BackgroundWrapper background="white">
				<ContainerView>
					<ProviderInfo provider={product.provider} />
					<Separator />
				</ContainerView>
			</BackgroundWrapper>
			<BackgroundWrapper background="white">
				<RecommendedProduct product={product} />
			</BackgroundWrapper>
		</>
	);
};

const BrandedCardProduct = ({ product }: any) => {
	const history = useHistory();
	const { updateUserData } = useUserContext();
	const client = useClient();
	const [card, setCard] = useState();
	const gettingCard = useRef(false);
	const team = useCurrentTeam();
	const { stripeCardholderId } = team?.teamMember || {
		stripeCardholderId: null,
	};
	const flexPot = useFlexPot();

	const getCard = async (cardholder) => {
		try {
			gettingCard.current = true;
			const { data } = await client.post(
				`${config.platform.HOST}/payments/authorized/cards/${
					!flexPot ? team.id : `budgets/${flexPot}`
				}`,
				{ cardholder }
			);
			setCard({ ...data, stripeCardholderId });
			updateUserData();
		} finally {
			gettingCard.current = false;
		}
	};

	useEffect(() => {
		if (card?.stripeCardholderId !== stripeCardholderId) setCard(null);
		if (
			flexPot !== -1 &&
			stripeCardholderId &&
			!gettingCard.current &&
			(!card || card.stripeCardholderId !== stripeCardholderId)
		) {
			getCard();
		}
	}, [stripeCardholderId, flexPot, getCard, card]);
	return (
		<div style={{ background: '#f8f8f8' }}>
			<Container style={{ margin: 'auto' }}>
				<Circle
					size="48px"
					onClick={() => {
						handleBackNavigation(history);
					}}
				>
					<MdArrowBack size={20} />
				</Circle>

				<BrandedCardLayout verticalOffset={product.verticalOffset}>
					<Product>
						<img
							src={
								product.imagePath
									? `${product.imagePath}?width=1200`
									: config.PLACEHOLDER_IMAGE
							}
							alt={product.title}
						/>
						<h1>
							<img src={product.provider.src} alt={product.provider.title} />
							{product.title}
						</h1>
						<Description>{product.description}</Description>
					</Product>
					<BrandedCard
						stripeCardholderId={stripeCardholderId}
						product={product}
						card={card}
						getCard={getCard}
					/>
				</BrandedCardLayout>
			</Container>
		</div>
	);
};

const BrandedCardLayout = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 48px;
	gap: 40px;

	@media (max-width: 992px) {
		flex-wrap: wrap;
		padding-bottom: 24px;
	}
`;

const Product = styled.div`
	flex-basis: 490px;
	align-self: flex-start;
	font-family: 'Roobert Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: #5c6165;
	@media (max-width: 992px) {
		flex-grow: 1;
	}
	img {
		width: 100%;
		height: 490px;
		object-fit: cover;
		object-position: ${({ verticalOffset }) =>
			verticalOffset ? `50% ${verticalOffset}%` : '50% 50%'};
		@media (max-width: 992px) {
			height: 165px;
		}
	}
	h1 {
		display: flex;
		align-items: center;
		font-family: 'Roobert Semi Bold';
		font-style: normal;
		font-weight: 600;
		font-size: 34px;
		line-height: 41px;
		margin: 50px 0 20px 0;
		@media (max-width: 992px) {
			font-size: 24px;
			line-height: 29px;
			margin: 30px 0 15px 0;
		}
	}
	h1 img {
		object-fit: fill;
		width: 45px;
		height: 45px;
		margin-right: 12px;
		border: 1px solid #e4e4e4;
		@media (max-width: 992px) {
			display: none;
		}
	}
`;

export default ProductPage;
