import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';
import { H4, SmallTextSecondary } from '../../styles/StylesV3Main';
import config from '../../config';
import ServicePoints from './ServicePoints';

const CardContainer = styled.div`
	width: 272px;
	height: 340px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	cursor: pointer;
	box-shadow: 0 0 1px transparent;
	transition-duration: 0.2s;
	transition-property: box-shadow, transform;

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		transition: 0.2s;
	}

	&:hover::after {
		opacity: 1;
	}

	@media (max-width: 1250px) {
		width: 236px;
	}
	@media (max-width: 1090px) {
		width: 220px;
	}

	@media (max-width: 992px) {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 16px;
	}
`;
const MobileCenterAlign = styled.div`
	@media (max-width: 992px) {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const ImageContainer = styled.div`
	width: 100%;
	height: 240px;
	object-fit: cover;
	border-collapse: separate;
	position: relative;
	background: ${({ background }) => background};
	box-sizing: border-box;
`;

const Image = styled.img`
	width: 100%;
	height: 240px;
	object-fit: cover;
	mix-blend-mode: ${({ overlay }) => overlay && 'overlay'};
`;

const Desc = styled(SmallTextSecondary)`
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	margin-top: 8px;
`;

const TitleView = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
`;

const SRow = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	padding: 24px;
`;

const PlainText = ({ children }) => <span>{children} </span>;

const ProductSerciveCard = ({ productType }) => {
	const [selectedColor, setSelectedColor] = useState('');

	useEffect(() => {
		const colors = ['#D6BBF7', '#FFDA79', '#F3B398', '#E2A1C0'];
		const randomColor = colors[Math.floor(Math.random() * colors.length)];
		setSelectedColor(randomColor);
	}, [selectedColor]);

	const getProductDescription = () => {
		if (productType.description) {
			return productType.description;
		} else {
			return '';
		}
	};

	return (
		<MobileCenterAlign>
			<CardContainer>
				<ImageContainer background={!productType.images && selectedColor}>
					<Image
						overlay={!productType.images}
						src={
							productType.images
								? productType.images[0].src + '?width=500'
								: config.PLACEHOLDER_IMAGE
						}
					/>
				</ImageContainer>
				<SRow>
					<TitleView>
						<H4 style={{ fontSize: 18 }}>{productType.title}</H4>
					</TitleView>
					<Row noGutters className="justify-content-start align-items-center">
						<ServicePoints productType={productType} />
					</Row>
				</SRow>
			</CardContainer>
		</MobileCenterAlign>
	);
};

export default ProductSerciveCard;
