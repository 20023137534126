import React from 'react';
import styled from 'styled-components';
import Button from '../../../styles/shared/Button/Button';
import Modal from '../../../styles/shared/Modal';
import { TeamBudget } from './types';

const Heading2 = styled.h2`
	font-family: 'TTInterfaces DemiBold';
	font-size: 21px;
	line-height: 30px;
	color: rgb(16, 24, 40);
`;

const ModalContainer = styled.div`
	position: relative;
	background: white;
	width: 560px;
	padding: 24px;

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

const StyledHr = styled.hr`
	height: 1px;
	background: rgba(0, 0, 0, 0.05);
	border: none;
	margin: 20px 0 24px;
`;

const Description = styled.p`
	margin-top: 8px;
	color: #5b6166;
	font-size: 14px;
	font-weight: 500;
	line-height: 160%;
	font-family: 'Roobert Regular';
`;

const AdminButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-grow: 0;
	margin-top: 24px;
	gap: 16px;
`;

interface DeleteBudgetModalProps {
	budget: TeamBudget;
	onSaveClick: () => Promise<void>;
	onCancelClick: () => void;
	isOpen: boolean;
}

const ConfirmDeleteModal: React.FC<DeleteBudgetModalProps> = ({
	budget,
	onSaveClick,
	onCancelClick,
	isOpen,
}) => {
	return (
		<Modal ariaLabel="Add Budget" open={isOpen} onClose={onCancelClick}>
			<ModalContainer>
				<form
					method="post"
					onSubmit={(e) => {
						e.preventDefault();
						onSaveClick();
					}}
				>
					<Heading2>Are you sure?</Heading2>
					<Description>
						{`Deleting the "${budget.name}" pot will prevent your team from accessing it`}
					</Description>

					<StyledHr style={{ marginBottom: '0' }} />

					<AdminButtons>
						<Button
							type="button"
							size="small"
							onClick={() => {
								onCancelClick();
							}}
							state="default"
							buttonType="secondary"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							state="default"
							buttonType="destructive"
							size="small"
						>
							Yes, delete
						</Button>
					</AdminButtons>
				</form>
			</ModalContainer>
		</Modal>
	);
};

export default ConfirmDeleteModal;
