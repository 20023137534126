import React from 'react';
import styled from 'styled-components';
import Loading from '../../components/utilities/Loading';
import BenefitsContainer from '../../styles/shared/Benefits/BenefitsContainer';

const Row = styled.div<{
	minHeight?: string | null;
}>`
	margin-right: 0;
	margin-left: 0;
	min-height: ${(props) => props.minHeight};
`;

const StyledContainer = styled.div`
	width: 100%;
	height: auto;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	padding: 40px;
	margin-left: 0px;

	@media (max-width: 900px) {
		padding: 16px;
	}
`;

const StyledHeader = styled.h1`
	font-size: 32px;
	font-weight: 700;
	line-height: 38.4px;
`;

const Paragraph = styled.p`
	font-weight: 400;
	font-size: 18px;
	line-height: 28.8px;
	color: #5b6166;
`;

const BenefitsView = ({ benefits, loading }: any) => {
	return (
		<StyledContainer>
			<Row>
				<StyledHeader>Benefits</StyledHeader>
				<Paragraph>
					These are all the benefits provided by your company and are available
					to you. For any questions related to these benefits, please contact
					your HR department.
				</Paragraph>
			</Row>
			<Row minHeight="30vh">
				{loading || !benefits ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '50vh',
							width: '100%',
							textAlign: 'center',
						}}
					>
						<Loading />
					</div>
				) : (
					<BenefitsContainer
						benefits={benefits}
						user="employee"
						handleUpdate={() => {}}
						openEditModal={() => {}}
						openAddModal={() => {}}
					/>
				)}
			</Row>
		</StyledContainer>
	);
};

export default BenefitsView;
