import React from 'react';
import Markdown from 'markdown-to-jsx';

const Description = ({ children }: any) => (
	<Markdown
		options={{
			forceBlock: true,
			overrides: {
				a: {
					props: {
						target: '_blank',
					},
				},
			},
		}}
	>
		{children}
	</Markdown>
);

export default Description;
