import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../contexts/userContext';
import { useFlexPot } from '../../hooks';

const Square = styled(Link)`
	background-color: #000;
	height: 35px;
	display: flex;
	padding: 0 6px;
	min-width: 35px;
	border-radius: 6px;
	gap: 2px;
	flex-direction: column;
	justify-content: center;
	color: #0a0f16;
	:hover {
		text-decoration: none;
	}
	p {
		margin: 0;
	}
`;

const StyledNumber = styled.p`
	color: #fff;
	font-family: 'TTInterfaces Demibold';
	font-style: normal;
	font-size: 12.5px;
	line-height: 12px;
`;

const StyledPoints = styled.p`
	color: #fff;
	font-family: 'TTInterfaces Medium';
	font-size: 8.5px;
	line-height: 9px;
	opacity: 0.5;
`;

const NavBarPoints = ({ onClick }: any) => {
	const { userData } = useUserContext();
	const flexPot = useFlexPot();
	if (flexPot) return null;
	return (
		<Square
			id="points"
			to="/wallet"
			onClick={() => {
				onClick?.();
			}}
			className="your-points"
		>
			<StyledNumber className="text-center">
				{(userData?.points || 0) +
					(userData?.teams.reduce((acc, cur) => {
						return cur.teamMember.roles.includes('member') &&
							cur.id === userData.currentTeam
							? acc + cur.teamMember.points
							: acc;
					}, 0) || 0)}
			</StyledNumber>
			<StyledPoints className="m-0 text-center">PTS</StyledPoints>
		</Square>
	);
};

export default NavBarPoints;
