import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useFlexPot } from '../hooks';

const NoFlex = () => {
	const history = useHistory();
	const flexPot = useFlexPot();
	useEffect(() => {
		if (!flexPot) history.replace('/card');
		else if (flexPot !== -1) history.replace(`/budget/${flexPot}`);
	}, [flexPot]);
	return (
		<Container>
			<div>
				<div>
					<h1>
						Your Allowance Pot hasn&apos;t been activated yet. Please check back
						in a little while, or check with your company admin.
					</h1>
				</div>
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1250px;
	> div {
		height: 400px;
		border-radius: 30px;
		display: flex;
		flex-direction: column;
		justify-content: end;
		background-image: url(https://platformwithjuno-images-prod.s3.eu-west-1.amazonaws.com/misc/other.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		> div {
			border-radius: 0 0 30px 30px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 0 40px 40px 40px;
			height: 224px;
			color: #fff;
			background: linear-gradient(
				180deg,
				rgba(48, 48, 48, 0) 0%,
				rgba(48, 48, 48, 0.66) 48%,
				rgba(47.81, 47.81, 47.81, 0.95) 100%
			);
			h1 {
				font-size: 36px;
				font-family: 'TTInterfaces DemiBold';
				line-height: 36px;
				font-weight: normal;
				margin: 0;
				span {
					font-family: 'TTInterfaces Regular';
				}
				@media (max-width: 992px) {
					line-height: 26px;
					font-size: 26px;
				}
			}
			@media (max-width: 992px) {
				padding: 0 24px 24px 24px;
			}
		}
	}
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 1250px) {
		padding: 48px;
		margin: 0px;
	}
	@media (max-width: 992px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

export default NoFlex;
