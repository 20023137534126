/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { handleBackNavigation } from '../../components/utilities/Functions';
import { useClient, useCurrentTeam } from '../../hooks';
import {
	H1,
	H2,
	SmallTextSecondaryMedium,
	SmallTextSecondary,
	Separator,
} from '../../styles/StylesV3Main';
import config from '../../config';
import Button from '../../styles/shared/Button/Button';
import Loading from '../../components/utilities/Loading';
import EventCard from '../../styles/shared/EventCard/EventCard';
import Modal from '../../styles/shared/Modal';
import Checkout from './Checkout';

import Booking from './Booking';
import { useUserContext } from '../../contexts/userContext';
import { useProductContext } from '../../contexts/productContext';

const EventDetail = ({ eventId }: { eventId: string }) => {
	const history = useHistory();
	const currentTeam = useCurrentTeam();
	const { userData } = useUserContext();

	const [eventsData, setEventsData] = useState([]);
	const { allEvents, event: eventData, setEventId } = useProductContext();
	const [widgetURL, setWidgetURL] = useState<string | null>(null);
	const [ticketAdded, setTicketAdded] = useState(false);
	const [loadingWidget, setLoadingWidget] = useState(false);
	const [basketId, setBasketId] = useState<string | null>(null);
	const [basket, setBasket] = useState<{
		basketContents: {
			eventName: string;
			quantity: number;
			date: string;
			amount: number;
			currency: string;
			eventImage: string;
		};
		sufficientPoints: boolean;
	} | null>(null);

	const [purchased, setPurchased] = useState(false);
	const random = useRef<undefined | { start: number; eventId: string }>();

	const client = useClient();

	useEffect(() => {
		setEventId(eventId);
		if (allEvents && random.current?.eventId !== eventId) {
			random.current = {
				start: Math.floor(Math.random() * (allEvents.length - 4)),
				eventId,
			};
			const events = allEvents
				.filter((event: { id: string }) => event.id !== eventId)
				.slice(random.current.start, random.current.start + 4);
			setEventsData(events);
		}
	}, [eventId, allEvents]);

	const openTickittoWidget = async () => {
		setLoadingWidget(true);

		try {
			const response = await client.post(
				`${config.products.HOST}/products/events/basket/${eventId}`
			);

			if (response.status === 200 && response.data) {
				setBasketId(response.data.basketId);

				setWidgetURL(
					`${response.data.viewUrl}&new_tab=true&currency=JUN&locale=en-GB`
				);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingWidget(false);
		}
	};

	useEffect(() => {
		window.addEventListener('message', messageReceiver, false);
		return () => window.removeEventListener('message', messageReceiver);
	});

	useEffect(() => {
		if (ticketAdded && !purchased) {
			setBasket(null);
			getBasketContent(basketId!);
		}
	}, [ticketAdded, userData.points]);

	const getBasketContent = async (id: string) => {
		try {
			const response = await client.get(
				`${config.products.HOST}/products/events/basket/${id}/${
					currentTeam?.id || ''
				}`
			);

			if (response.status === 200 && response.data) {
				setBasket(response.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const messageReceiver = async (event: any) => {
		const frameRef = document.getElementsByTagName('iframe')[0];

		if (event.data.messageData != null) {
			if (event.data.type === 'NEW_HEIGHT') {
				const newHeight = event.data.messageData.height;
				frameRef.setAttribute('height', newHeight);
			}

			if (event.data.type === 'NEW_TICKET_SELECTED' && !ticketAdded) {
				setTicketAdded(true);
			}
		}
	};

	const close = () => {
		setBasket(null);
		setWidgetURL(null);
		setTicketAdded(false);
	};

	return (
		<StyledContainer>
			{eventData && eventData.id === eventId ? (
				<>
					<FlexContainer
						margin="0 0 40px 0"
						justify="space-between"
						align="center"
					>
						<FlexContainer margin="0">
							<CircleArrow
								onClick={() => {
									handleBackNavigation(history);
								}}
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
										fill="#191A1C"
									/>
								</svg>
							</CircleArrow>
							<TitleContainer>
								<SmallTextSecondary>
									Events / {eventData.categories?.[0]}
								</SmallTextSecondary>
								<H1>{eventData.title}</H1>
								<SmallTextSecondary>
									{eventData.city}, {eventData.country}
								</SmallTextSecondary>
							</TitleContainer>
						</FlexContainer>
						<Points>
							<small>from</small> {eventData.fromPrice} pts
						</Points>
					</FlexContainer>

					<TwoColumnsContainer>
						<div>
							<ImageContainer>
								<Image
									alt={eventData.title}
									src={eventData.image || config.PLACEHOLDER_IMAGE}
								/>
							</ImageContainer>
							<div>
								{eventData && eventData.description && (
									<Description>{eventData.description}</Description>
								)}
							</div>
						</div>
						<Booking
							disabled={currentTeam.teamMember?.status === 'suspended'}
							eventData={eventData}
							loadingWidget={loadingWidget}
							openTickittoWidget={openTickittoWidget}
						/>
					</TwoColumnsContainer>
				</>
			) : (
				<Loading />
			)}
			{eventData && eventsData.length > 0 && (
				<div>
					<HeadingTwo>Other Events</HeadingTwo>
					<FourColumnsContainer>
						{eventsData.map((item: any) => (
							<EventCard key={item.id} event={item} />
						))}
					</FourColumnsContainer>
				</div>
			)}

			{widgetURL && (
				<Modal
					ariaLabel="Tickitto Widget"
					open={widgetURL}
					onClose={() => {
						close();
					}}
				>
					<ModalContainer>
						{!ticketAdded ? (
							<>
								<H2 style={{ paddingBottom: '4px' }}>{eventData?.title}</H2>
								<SmallTextSecondaryMedium>
									Please follow the steps below to select the right tickets for
									you
								</SmallTextSecondaryMedium>

								<StyledIframe src={widgetURL} />

								<Separator />

								<ButtonsRow>
									<Button
										type="button"
										size="small"
										onClick={() => {
											setWidgetURL(null);
											setTicketAdded(false);
										}}
										state="default"
										buttonType="secondary"
									>
										Cancel
									</Button>
								</ButtonsRow>
							</>
						) : (
							<Checkout
								basket={basket}
								basketId={basketId}
								close={close}
								setPurchased={(boolean) => setPurchased(boolean)}
								purchased={purchased}
							/>
						)}
					</ModalContainer>
				</Modal>
			)}
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	width: 100%;
	padding: 48px 0px;
	margin: auto;
	max-width: 1250px;
	@media (max-width: 1250px) {
		max-width: 100%;
		padding: 24px 20px;
		margin: 0px;
	}
`;

const StyledIframe = styled.iframe`
	border: none;
	width: 619px;
	background: transparent;
	margin: 24px 0px;
	overflow: show;
	@media (max-width: 992px) {
		width: unset;
	}
`;

const ModalContainer = styled.div`
	position: relative;
	background: white;
	width: 667px;
	padding: 24px;

	@media (max-width: 992px) {
		width: unset;
	}

	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

const ButtonsRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-grow: 0;
	margin-top: 24px;
	gap: 16px;
`;

const TwoColumnsContainer = styled.div`
	display: grid;
	grid-template-columns: calc(65% - 16px) calc(35% - 16px) repeat(2, 1fr);
	grid-gap: 32px;
	@media (max-width: 992px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

const Description = styled.p`
	white-space: pre-line;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 29px;
	color: #191a1c;
`;

const ImageContainer = styled.div<{ background?: string }>`
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-collapse: separate;
	position: relative;
	background: ${({ background }) => background};
	margin-bottom: 48px;
	@media (max-width: 992px) {
		width: 100%;
		height: fit-content;
	}
`;

const HeadingTwo = styled.h2`
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #191a1c;
	margin-top: 38px;
	margin-bottom: 32px;
`;

const CircleArrow = styled.button`
	border: none;
	width: 48px;
	height: 48px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.5s ease;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

const FlexContainer = styled.div<{
	margin?: string;
	justify?: string;
	align?: string;
}>`
	display: flex;
	margin: ${({ margin }) => margin};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
`;

const Image = styled.img<{ overlay?: string }>`
	width: 100%;
	max-height: 560px;
	object-fit: contain;
	mix-blend-mode: ${({ overlay }) => overlay && 'overlay'};
`;

const TitleContainer = styled.div`
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin-left: 24px;
`;

const Points = styled.p`
	text-align: right;
	color: #5b6166;
	font-size: 24px;
	line-height: 28.8px;
	font-weight: 400;
	color: black;
	margin: 0;
	small {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #5b6166;
	}
`;

const FourColumnsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
	@media (max-width: 992px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

export default EventDetail;
