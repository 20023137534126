/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { BiPencil } from 'react-icons/bi';
import { IoStatsChart } from 'react-icons/io5';
import config from '../../../config';
import TeamPictureUpload from './TeamPictureUpload';
import { BackgroundWrapper } from '../../../styles/ProductStyles';
import { PSansText, PSansBoldText, H1Title } from '../../../styles/StylesV2';

import TeamReports from './TeamReports';
import { useClient } from '../../../hooks';

const TaxReportContainer = styled.button`
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	width: 215px;
	height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 14px;
	gap: 12px;
	cursor: pointer;
	margin-left: auto;
	@media (max-width: 550px) {
		margin-left: 10px;
	}
	border: 0;
`;

const TaxReportImage = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 5px;
	background: #e2a1c0;
	border-radius: 5px;
	width: 24px;
	height: 24px;
`;

const StyledTaxReportText = styled.div`
	width: 100%;
	font-family: 'TTInterfaces Bold';
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: black;
`;

const StyledContainer = styled.div`
	width: 100%;
	padding: 0px;
	background: #f8f8f8 0% 0% no-repeat padding-box;
	justify-content: flex-start;
`;

const StyledImageContainer = styled.div`
	position: relative;
`;

const StyleTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	padding-left: 20px;
	@media (max-width: 992px) {
		padding-left: 12px;
	}
	@media (max-width: 400px) {
		padding-left: 10px;
	}
	@media (max-width: 330px) {
		padding-left: 8px;
	}
`;
const StyledTitle = styled(H1Title)`
	line-height: 38px;
	color: black;
	text-transform: capitalize;
	@media (max-width: 992px) {
		font-family: 'Roobert Bold';
		font-style: normal;
		font-size: 30px;
	}
	@media (max-width: 400px) {
		font-family: 'Roobert Bold';
		font-style: normal;
		font-size: 26px;
	}
	@media (max-width: 330px) {
		font-family: 'Roobert Bold';
		font-style: normal;
		font-size: 20px;
	}
`;

const StyledLabel = styled.label`
	position: relative;
	min-width: 2em;
	min-height: 1em;
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 32px;
	padding: 0px;
	margin: 2px;
	&:focus {
		outline: 0;
	}
	@media (max-width: 992px) {
		font-size: 30px;
	}
	@media (max-width: 400px) {
		font-size: 26px;
	}
	@media (max-width: 330px) {
		font-size: 20px;
	}
`;

const StyledSpan = styled.span`
	white-space: pre;
	outline: 0 !important;
	padding: 0px;
	margin: 0px;
	&:focus {
		outline: 0;
	}
`;

const StyledInput = styled.input`
	padding: 0px;
	margin: 0px;
	line-height: 38px;
	border: none;
	border-bottom: 2px solid black;
	/* added styles */
	font-family: inherit;
	font-size: inherit;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: #f8f8f8;
	&:focus {
		outline: 0;
	}
`;

const StyledMembers = styled(PSansText)`
	margin: 0px;
	color: rgba(0, 0, 0, 0.5);
	line-height: 17px;
	text-transform: capitalize;
`;

const TeamHeader = ({ team, handleUpdate }) => {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState();
	const client = useClient();
	const [localPic, setLocalPic] = useState(null);
	const [showDatePickers, setShowDatePickers] = useState(false);

	useEffect(() => {
		setName(team.name);
	}, [team.name]);

	const handleChange = async () => {
		try {
			if (name !== team.name) {
				await client.put(`${config.api.HOST}/teams/${team.id}`, { name });
				await handleUpdate();
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<BackgroundWrapper background="#f8f8f8">
			<StyledContainer>
				<Row noGutters className="justify-content-start align-items-center">
					<StyledImageContainer>
						<TeamPictureUpload
							src={localPic || team?.src}
							teamId={team.id}
							handleChange={(value) => setLocalPic(value)}
						/>
					</StyledImageContainer>
					<StyleTitleContainer>
						<Row noGutters className="justify-content-start align-items-end">
							{open ? (
								<StyledLabel>
									<StyledSpan>{name}</StyledSpan>
									<StyledInput
										autoFocus
										onChange={({ target: { value } }) => setName(value)}
										value={name}
										onBlur={() => {
											handleChange();
											setOpen(false);
										}}
									/>
								</StyledLabel>
							) : (
								<>
									<StyledTitle> {team?.name ? team.name : ''}</StyledTitle>
									<Row
										noGutters
										className="justify-content-start align-items-center"
										onClick={() => setOpen(!open)}
										onMouseDown={(e) => e.preventDefault()}
										style={{ cursor: 'pointer' }}
									>
										<BiPencil size={30} color="#767479" className="pl-1" />
										<PSansBoldText
											style={{
												color: '#767479',
												left: -15,
												top: 9,
												position: 'relative',
											}}
										>
											--
										</PSansBoldText>
									</Row>
								</>
							)}
						</Row>
						<Row
							noGutters
							className="justify-content-start align-items-center "
						>
							<StyledMembers className="mr-2">
								{
									team.teamMembers.filter((item) => item.status === 'active')
										.length
								}{' '}
								Member
								{team.teamMembers.filter((item) => item.status === 'active')
									.length === 1
									? ''
									: 's'}
							</StyledMembers>
						</Row>
					</StyleTitleContainer>

					<TaxReportContainer
						onClick={() => {
							setShowDatePickers(true);
						}}
					>
						<TaxReportImage>
							<IoStatsChart style={{ color: 'rgba(0,0,0,0.3)' }} />
						</TaxReportImage>
						<StyledTaxReportText>Generate Tax Report</StyledTaxReportText>
					</TaxReportContainer>
					<TeamReports
						showDatePickers={showDatePickers}
						setShowDatePickers={setShowDatePickers}
						team={team}
					/>
				</Row>
			</StyledContainer>
		</BackgroundWrapper>
	);
};

export default TeamHeader;
