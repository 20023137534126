import styled from 'styled-components';

export const H1 = styled.h1`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 32px;
	line-height: 38px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
	@media (max-width: 1250px) {
		font-size: 22px;
	}
	@media (max-width: 992px) {
		font-size: 32px;
	}
	@media (max-width: 450px) {
		font-size: 22px;
	}
`;

export const H2 = styled.h2`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 24px;
	line-height: 29px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

export const H3 = styled.h3`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 20px;
	line-height: 24px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

export const H4 = styled.h4`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 18px;
	line-height: 24px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

export const H4TextSecondary = styled.h5`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 18px;
	line-height: 24px;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin-bottom: 0px;
`;

export const H5 = styled.h5`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

export const H5SemiBold = styled.h5`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin-bottom: 0px;
`;

export const H5TextSecondary = styled.h5`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin-bottom: 0px;
`;

export const H5TextWarning = styled.h5`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => props.theme.mainColors.color.textWarning};
	margin-bottom: 0px;
`;

export const H6 = styled.h6`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin-bottom: 0px;
`;

export const H6TextSecondary = styled.h6`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin-bottom: 0px;
`;

export const Para = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 16px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const ParaSemiBold = styled.p`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 16px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const Paragraph = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const ParagraphMedium = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const ParagraphMD = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const ParagraphGrey = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin: 0px;
`;

export const ParagraphGreyMD = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 18px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin: 0px;
`;

export const ParagraphLarge = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 20px;
	line-height: 140%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const ParagraphSemiBoldLarge = styled.div`
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 20px;
	line-height: 24px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const Category = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 16px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const Small = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const SmallTextSecondary = styled.p`
	font-family: 'TTInterfaces Regular';
	font-size: 14px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin: 0px;
`;

export const SmallTextWarning = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textWarning};
	margin: 0px;
`;

export const SmallTextSecondaryMedium = styled.p`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	color: ${(props) => props.theme.mainColors.color.textSecondary};
	margin: 0px;
`;

export const AmountSmall = styled.p`
  font-family: "Roobert Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 17px
  color: ${(props) =>
		props.color ? props.color : props.theme.mainColors.color.textPrimary};
  margin: 0px;
`;

export const ExtraSmall = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	color: ${(props) =>
		props.color ? props.color : props.theme.mainColors.color.textPrimary};
	margin: 0px;
`;

export const Body2 = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: #0a0f16;
	margin: 0px;
`;

export const Body3 = styled.p`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: #0a0f16;
	margin: 0px;
`;

export const Separator = styled.div`
	height: 1px;
	width: 100%;
	background: ${(props) => props.theme.mainColors.color.separator};
`;

export const U = styled.p`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 18px;
	color: ${(props) => props.theme.mainColors.color.textPrimary};
	text-transform: capitalize;
	line-height: 160%;
	margin-bottom: 0px;
	cursor: pointer;
`;

export const MobileView = styled.div`
	display: none;
	@media (max-width: 992px) {
		display: block;
		// width: 100%;
	}
`;

export const DesktopView = styled.div`
	display: block;
	width: 100%;
	@media (max-width: 992px) {
		display: none;
	}
`;
