import { useUserContext } from '../contexts/userContext';

const useCurrentTeam = () => {
	const { userData } = useUserContext();
	const currentTeam = userData?.teams.find(
		(team) => team.id === userData.currentTeam
	);

	return currentTeam || {};
};

export default useCurrentTeam;
