import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PhoneInput, {
	Value,
	isValidPhoneNumber,
} from 'react-phone-number-input';
import countries from '../../../data/countries.json';
import Button from '../Button/Button';
import arrowLeft from './arrow-left.svg';

import { CardDetails } from '../JunoCard';

const Cardholder = ({
	card,
	getCard,
	backAction,
}: {
	card: CardDetails;
	getCard: (cardholder: CardholderDetails) => Promise<any>;
	backAction?: () => void;
}) => {
	const [fullName, setFullName] = useState(card?.fullName || '');
	const [addressLine1, setAddressLine1] = useState(
		card?.personalAddress.line1 || ''
	);
	const [addressLine2, setAddressLine2] = useState(
		card?.personalAddress.line2 || ''
	);
	const [townOrCity, setTownOrCity] = useState(
		card?.personalAddress.city || ''
	);
	const [postalCode, setPostalCode] = useState(
		card?.personalAddress.postCode || ''
	);
	const [country, setCountry] = useState<any>(
		card?.personalAddress.country || ''
	);
	const [phoneNumber, setPhoneNumber] = useState<Value | undefined>('');
	const [loading, setLoading] = useState(false);
	const phoneRef = useRef<HTMLInputElement>(null);

	const limitedForm = card && !card?.phoneNumber;

	return (
		<CardholderContainer>
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					if (e.currentTarget.checkValidity()) {
						setLoading(true);
						try {
							await getCard({
								fullName,
								addressLine1,
								addressLine2,
								townOrCity,
								postalCode,
								country:
									country !== 'GB_' && country !== 'US_'
										? country
										: country.substring(0, 2),
								phoneNumber: phoneNumber! as string,
							});

							if (backAction) backAction();
						} catch (ex) {
							console.error(ex);
							setLoading(false);
						}
					}
				}}
			>
				{backAction && (
					<button
						type="button"
						style={{
							background: 'none',
							border: 'none',
							lineHeight: '36px',
							position: 'absolute',
						}}
						onClick={() => backAction()}
					>
						<img src={arrowLeft} alt="back arrow" />
					</button>
				)}
				<Headings>
					<h3>{limitedForm ? 'Add Phone Number' : 'Set up card'}</h3>
				</Headings>
				<InputContainer>
					<label htmlFor="fullName">Full Name</label>
					<input
						id="fullName"
						required
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={!limitedForm}
						pattern=".*[^\s]+[\s]+[^\s]+.*"
						value={fullName}
						maxLength={24}
						disabled={limitedForm}
						onChange={({ target }) => {
							setFullName(target.value);
							target.setCustomValidity(
								target.validity.patternMismatch
									? 'Must include first and last name.'
									: ''
							);
						}}
					/>
				</InputContainer>
				<InputContainer>
					<label htmlFor="addressLine1">Address Line 1</label>
					<input
						id="addressLine1"
						required
						value={addressLine1}
						disabled={limitedForm}
						onChange={({ target }) => setAddressLine1(target.value)}
					/>
				</InputContainer>
				<InputContainer>
					<label htmlFor="addressLine2">Address Line 2</label>
					<input
						id="addressLine2"
						value={addressLine2}
						disabled={limitedForm}
						onChange={({ target }) => setAddressLine2(target.value)}
					/>
				</InputContainer>
				<InputContainer>
					<label htmlFor="townOrCity">Town or City</label>
					<input
						id="townOrCity"
						required
						value={townOrCity}
						disabled={limitedForm}
						onChange={({ target }) => setTownOrCity(target.value)}
					/>
				</InputContainer>
				<InputContainer>
					<label htmlFor="postalCode">Postal Code</label>
					<input
						id="postalCode"
						required
						value={postalCode}
						disabled={limitedForm}
						onChange={({ target }) => setPostalCode(target.value)}
					/>
				</InputContainer>
				<InputContainer>
					<label htmlFor="country">Country</label>
					<select
						id="country"
						required
						value={country}
						disabled={limitedForm}
						onChange={({ target }) => setCountry(target.value)}
					>
						{[
							{ value: '', label: '' },
							{ value: 'GB_', label: 'United Kingdom' },
							{ value: 'US_', label: 'United States' },
							...countries.slice(2),
						].map(({ value, label }) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</select>
				</InputContainer>
				<PhoneInputContainer>
					<label htmlFor="phoneNumber">Phone Number</label>
					<div style={{ width: '100%' }}>
						<StyledPhoneInput
							required
							ref={phoneRef as any}
							defaultCountry="GB"
							id="phoneNumber"
							value={phoneNumber}
							autoFocus={limitedForm}
							onChange={(value: any) => {
								setPhoneNumber(value);
								phoneRef.current!.setCustomValidity(
									!phoneRef.current!.value ||
										(value && isValidPhoneNumber(value as string))
										? ''
										: 'Please enter a valid phone number'
								);
							}}
						/>
					</div>
				</PhoneInputContainer>
				<hr />
				<Button
					type="submit"
					state={!loading ? 'default' : 'loading'}
					buttonType="primary"
				>
					{limitedForm ? 'Add phone number' : 'Set up card'}
				</Button>
			</form>
		</CardholderContainer>
	);
};

export type CardholderDetails = {
	fullName: string;
	addressLine1: string;
	addressLine2: string;
	townOrCity: string;
	postalCode: string;
	country: string;
	phoneNumber: string | null;
};

const PhoneInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 14px;
`;

const StyledPhoneInput = styled(PhoneInput)`
	width: 100%;
	padding: 15px 18px;
	height: 50px;
	border: 1.5px solid #e4e4e4;
	border-radius: 10px;

	> input {
		height: 48px;
		padding-left: 18px;
		border-radius: 10px;
		border: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	gap: 14px;

	input,
	select {
		width: 100%;
		padding: 15px 18px;
		height: 50px;
		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	textarea {
		width: 100%;
		padding: 15px 18px;
		height: 100px;
		border: 1.5px solid #e4e4e4;
		border-radius: 10px;
	}

	label:first-of-type {
		font-family: 'TTInterfaces Demibold';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		margin: 0;
	}

	label:nth-of-type(2) {
		font-family: 'TTInterfaces Medium';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		margin: 0;
		color: rgba(0, 0, 0, 0.35);
	}

	div {
		border-radius: 10px;
		input {
			opacity: 0;
			height: 0;
			width: 0;
			padding: 0;
			left: 50%;
			position: absolute;
		}
	}
	> div {
		width: 100%;
		border: 1.5px solid #e4e4e4;
		background: transparent;

		> div {
			> div {
				background: #fff;
			}
		}
	}
`;

const Headings = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	text-align: center;
	width: 100%;

	h3 {
		font-family: 'TTInterfaces Demibold';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 130%;
		text-align: center;
		margin: 0;
	}
`;

const CardholderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	padding: 48px;

	border: none;

	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
	border-radius: 26px;

	&::backdrop {
		background: rgba(0, 0, 0, 0.4);
	}

	@keyframes slideInFromBottom {
		0% {
			opacity: 0;
			transform: translateY(5%);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	animation: 0.4s ease-in-out 0s 1 slideInFromBottom;

	@media (max-width: 1200px) {
		width: 100%;
		padding: 20px;
		margin-bottom: 24px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 35px;
	}
`;

export default Cardholder;
