import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col, Modal, ModalBody } from 'react-bootstrap';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import config from '../../config';
import useClient from '../../hooks/useClient';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import {
	Header,
	PSansMediumText,
	PSansText,
	PSansBoldText,
	H1Title,
	TH,
	TD,
	TR,
} from '../../styles/StylesV2';
import { useUserContext } from '../../contexts/userContext';
import Loading from '../utilities/Loading';
import DataTable from '../utilities/DataTable';

const StyledContainer = styled.div`
	width: 100%;
	height: auto;
	background: #fff;
	border-radius: 3px;
	margin-left: 40px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	padding: 0px 80px 60px 60px;
	margin-left: 0px;
	@media (max-width: 1200px) {
		margin-left: 40px;
	}
	@media (max-width: 991.98px) {
		margin-left: 0px;
		width: 100%;
		height: auto;
		max-width: 100%;
		background: #f8f8f8;
		padding: 0px 20px 50px 20px;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;
	}
`;

const StyledHeader = styled(Header)`
	line-height: 120%;
	margin-bottom: 34px;
	margin-top: 50px;

	@media (max-width: 991.98px) {
		margin-bottom: 34px;
		margin-top: 10px;
		padding: 0px;
	}
`;

const StyledDesktopView = styled.div`
	display: block;
	width: 100%;

	@media (max-width: 991.98px) {
		display: none;
	}
`;
const StyledMobileView = styled.div`
	display: none;
	width: 100%;
	@media (max-width: 991.98px) {
		display: block;
		border-top: 1px solid #f8f8f8;
	}
`;

const StyledTableHeader = styled(PSansMediumText)`
	color: rgba(0, 0, 0, 0.3);
	letter-spacing: 0.25px;
	font-size: 10px;
	line-height: 20px;
`;

const StyledOrderNumber = styled(PSansText)`
	font-size: 14px;
	line-height: 160%;
	color: #000000;
	@media (max-width: 991.98px) {
		margin-top: 5px;
		margin-bottom: 15px;
	}
`;

const StyledProgrammeName = styled(PSansText)`
	font-size: 14px;
	line-height: 160%;
	width: fit-content;
	text-decoration: underline;
	text-underline-position: under;
	text-transform: capitalize;
	text-decoration-thickness: 1.5px;
	color: black;
	overflow-y: hidden;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 20px; /* fallback */
	max-height: 50px; /* fallback */
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
	@media (max-width: 991.98px) {
		margin-top: 5px;
		margin-bottom: 15px;
	}
`;

const StyledStatus = styled(PSansText)`
	font-size: 11px;
	line-height: 150%;
	letter-spacing: 1px;
	text-transform: uppercase;
	width: fit-content;
	padding: 5px;
	border-radius: 3px;

	${({ status }) =>
		status === 'Open' &&
		`
        background: rgba(138, 66, 229, 0.14);
        color: #8A42E6;
    `}

	${({ status }) =>
		status === 'Open enquiry' &&
		`
        background: rgba(191, 210, 0, 0.14);
        color: #bfd200;
    `}

  ${({ status, assignedTo }) =>
		(status === 'Open enquiry' || status === 'Open') &&
		assignedTo !== null &&
		`
        background: rgba(253, 177, 55, 0.14);
        color: #FDB137;
    `}

  ${({ status }) =>
		status === 'Booked' &&
		` background: rgba(51, 195, 69, 0.14);; 
        color: #33C345;`}

  ${({ status }) =>
		status === 'Cancelled' ||
		(status === 'Rejected' &&
			` background: rgba(239, 35, 60, 0.14);;
        color: #ef233c;`)}

   ${({ status }) =>
		status === 'Closed' &&
		` background: rgba(72, 191, 227, 0.14);;
        color: #48bfe3;`}

   ${({ status }) =>
		status === 'Refunded' &&
		` background: rgba(58, 12, 163, 0.14);;
        color: #3a0ca3;`}

  ${({ status }) =>
		status === 'Pending' &&
		` background: rgba(92, 103, 125, 0.14);;
        color: #5c677d;`}


  @media (max-width: 991.98px) {
		margin-top: 5px;
		margin-bottom: 15px;
	}
`;

const StyledDate = styled(PSansText)`
	font-size: 14px;
	line-height: 160%;
	color: black;
	@media (max-width: 991.98px) {
		margin-top: 5px;
		margin-bottom: 15px;
	}
`;

const StyledRowMobile = styled(Row)`
	width: 100%;

	height: fit-content;
	border-bottom: 1px solid #f8f8f8;
`;

const StyledMobileColumn = styled(Col)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

const StyledNoOrders = styled(Row)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 30vh;
`;

const StyledCancel = styled(PSansBoldText)`
	color: red;
	cursor: pointer;
`;

const StylePoints = styled(PSansText)`
	font-size: 14px;
	line-height: 160%;
	color: black;
	@media (max-width: 991.98px) {
		margin-top: 5px;
	}
`;

const StyledModal = styled(Modal)``;

const StyledModalBody = styled(ModalBody)`
	position: relative;
	height: fit-content;
`;

const StyledTitle = styled(H1Title)`
	color: black;
	margin-top: 40px;
	margin-bottom: 10px;
	@media (max-width: 992px) {
		margin: 34px 20px;
	}
`;

const ModalButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	border: none;
	border-radius: 3px;
	width: 188px;
	height: 50px;
	${({ cancel }) =>
		cancel &&
		`   background: rgba(255, 0, 0, 0.05);
        border: 1px solid #FF0000;
        box-sizing: border-box;
        color: #FF0000;
    `}
	&:focus {
		outline: 0;
	}
	@media (max-width: 500px) {
		width: 150px;
	}
`;

const BookingsView = () => {
	const [bookings, setBookings] = useState(null);
	const [booking, setBooking] = useState(null);
	const [params, setParams] = useState({});
	const { loading, userData, cancelBookingUser } = useUserContext();
	const { getTokenSilently } = useAuth0();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const client = useClient();

	const history = useHistory();
	const location = useLocation();

	const getBookings = useCallback(async () => {
		try {
			const response = await client.get(`${config.api.HOST}/bookings`);
			if (response.data) {
				setBookings(response.data);
			}
		} catch (err) {
			console.error(err.message);
		}
	}, [getTokenSilently, userData]);

	const handleCancel = useCallback(
		async (booking) => {
			const result = await cancelBookingUser({
				...booking,
				id: booking.originalId,
			});
			return result;
		},
		[cancelBookingUser]
	);

	useEffect(() => {
		if (!loading) {
			getBookings();
		}
	}, [loading, getBookings]);

	useEffect(() => {
		setParams(queryString.parse(location.search));
	}, [location.search]);

	const columns = useMemo(() => {
		return bookings
			? [
					{
						Header: <StyledTableHeader>Order number</StyledTableHeader>,
						accessor: 'id', // accessor is the "key" in the data
						Cell: ({ value }) => <StyledOrderNumber>{value}</StyledOrderNumber>,
						flexGrow: 2,
					},
					{
						Header: <StyledTableHeader>Experience</StyledTableHeader>,
						accessor: 'experience', // accessor is the "key" in the data
						Cell: ({ row, value }) => (
							<StyledProgrammeName
								style={{
									cursor:
										row?.original?.product?.id &&
										!row?.original?.product?.customProduct &&
										'pointer',
								}}
								onClick={() => {
									row?.original?.product?.id &&
										!row?.original?.product?.customProduct &&
										history.push(`/product/${row?.original?.product?.id}`);
								}}
							>
								{value}
							</StyledProgrammeName>
						),
						flexGrow: 3,
					},
					{
						Header: <StyledTableHeader>Status</StyledTableHeader>,
						accessor: 'status', // accessor is the "key" in the data
						Cell: ({ row, value }) => (
							<StyledStatus
								status={value}
								assignedTo={row?.original?.assignedTo}
							>
								{(value === 'Open' || value === 'Open enquiry') &&
								row?.original?.assignedTo !== null
									? 'Processing'
									: value}
							</StyledStatus>
						),
						flexGrow: 2,
					},
					{
						Header: <StyledTableHeader>Date</StyledTableHeader>,
						accessor: 'dateOrdered', // accessor is the "key" in the data
						Cell: ({ value }) => (
							<StyledDate>
								{moment(value).isSame(moment(), 'days')
									? 'Today'
									: moment(value).format('DD MMM YYYY')}
							</StyledDate>
						),
						flexGrow: 2,
					},
					{
						Header: <StyledTableHeader>Cost</StyledTableHeader>,
						accessor: 'cost', // accessor is the "key" in the data
						Cell: ({ value }) => <StylePoints>{value}</StylePoints>,
						flexGrow: 2,
					},
					{
						accessor: 'cancel', // accessor is the "key" in the data
						Cell: ({ row }) => {
							return (
								<>
									{['Open', 'Open enquiry', 'Pending user feedback'].indexOf(
										row?.original?.status
									) !== -1 && (
										<StyledCancel
											onClick={() => {
												setBooking(row?.original);
												handleShow(true);
											}}
										>
											Cancel
										</StyledCancel>
									)}
								</>
							);
						},
						flexGrow: 1,
					},
			  ]
			: [];
	}, [bookings, history]);

	const dataDisplay = useMemo(() => {
		return bookings
			? bookings
					.map((item) => ({
						...item,
						originalId: item.id,
						id: '#' + item.id + ' ',
						experience: item?.product?.title || '',
						cost: !item.budgetId
							? `${item.bookingCost} points`
							: new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: item.team?.currency || 'gbp',
							  }).format(item.bookingCost / 100),
					}))
					.filter((item) => item)
			: [];
	}, [bookings]);

	return (
		<StyledContainer>
			<Row noGutters>
				<StyledHeader>My Orders</StyledHeader>
			</Row>
			<StyledDesktopView>
				{bookings?.length === 0 && (
					<Row noGutters as={StyledNoOrders}>
						<Row noGutters className="py-3">
							<PSansMediumText style={{ fontSize: 20 }}>
								No orders made yet
							</PSansMediumText>
						</Row>
					</Row>
				)}
				{loading || !bookings ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '50vh',
							width: '100%',
							textAlign: 'center',
						}}
					>
						<Loading />
					</div>
				) : (
					<DataTable
						columns={columns}
						data={dataDisplay}
						filtersActive
						defaultSorts={[{ id: 'dateOrdered', desc: true }]}
						defaultFilters={
							params?.id &&
							dataDisplay.filter((item) => item.id === '#' + params?.id + ' ')
								.length > 0
								? [{ id: 'id', value: '#' + params?.id + ' ' }]
								: []
						}
						TR={TR}
						TH={TH}
						TD={TD}
					/>
				)}
			</StyledDesktopView>
			<StyledMobileView>
				{loading || !bookings ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '50vh',
							width: '100%',
							textAlign: 'center',
						}}
					>
						<Loading />
					</div>
				) : bookings && bookings.length === 0 ? (
					<Row noGutters as={StyledNoOrders}>
						<Row noGutters className="py-3">
							<PSansMediumText style={{ fontSize: 20 }}>
								No orders made yet
							</PSansMediumText>
						</Row>
					</Row>
				) : (
					bookings
						.sort((a, b) =>
							moment(a.dateOrdered) > moment(b.dateOrdered) ? -1 : 1
						)
						.map((item, index) => (
							<Row noGutters as={StyledRowMobile} key={index} className="py-3">
								<Col xs={5} as={StyledMobileColumn}>
									<StyledTableHeader>ORDER NUMBER</StyledTableHeader>
									<StyledOrderNumber>#{item?.zendeskId}</StyledOrderNumber>
								</Col>
								<Col xs={7} as={StyledMobileColumn}>
									<StyledTableHeader>PROGRAMME</StyledTableHeader>
									<StyledProgrammeName
										style={{ cursor: 'pointer' }}
										onClick={() => {
											history.push(`/product/${item?.product?.id}`);
										}}
									>
										{item?.product?.title}
									</StyledProgrammeName>
								</Col>

								<Col xs={5} as={StyledMobileColumn}>
									<StyledTableHeader>STATUS</StyledTableHeader>
									<StyledStatus
										status={item?.status}
										assignedTo={item?.assignedTo}
									>
										{(item.status === 'Open' ||
											item.status === 'Open enquiry') &&
										item.assignedTo !== null
											? 'Processing'
											: item?.status}
									</StyledStatus>
								</Col>
								<Col xs={7} as={StyledMobileColumn}>
									<StyledTableHeader>DATE</StyledTableHeader>
									<StyledDate>
										{moment(item?.dateOrdered).isSame(moment(), 'days')
											? 'Today'
											: moment(item?.dateOrdered).format('DD MMM YYYY')}
									</StyledDate>
								</Col>

								<Col xs={5} as={StyledMobileColumn}>
									<StyledTableHeader>COST</StyledTableHeader>
									<StylePoints>
										{!item.budgetId
											? `${item.bookingCost} points`
											: new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: item.team?.currency || 'gbp',
											  }).format(item.bookingCost / 100)}
									</StylePoints>
								</Col>
							</Row>
						))
				)}
			</StyledMobileView>

			<StyledModal
				show={show}
				onHide={handleClose}
				centered
				animation={false}
				size={'md'}
			>
				<StyledModalBody>
					<Row noGutters className="justify-content-center align-items-center">
						<StyledTitle>Are you sure?</StyledTitle>
					</Row>
					<Row
						noGutters
						className="justify-content-center align-items-center ml-5 mr-5 text-center"
					>
						<PSansText>Are you sure you want to cancel your order?</PSansText>
					</Row>
					<Row noGutters className="justify-content-around my-3 mt-5">
						<ModalButton version="light" onClick={handleClose}>
							<PSansText>Close</PSansText>
						</ModalButton>
						<ModalButton
							onClick={() => {
								handleCancel(booking);
								handleClose();
							}}
							cancel={true}
						>
							<PSansText>Confirm cancellation</PSansText>
						</ModalButton>
					</Row>
				</StyledModalBody>
			</StyledModal>
		</StyledContainer>
	);
};

export default BookingsView;
