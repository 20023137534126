import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { H3Upper, H3 } from '../styles/StylesV2';

const NotFound = () => {
	const [processing, setProcessing] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => setProcessing(false), 4000);
		return () => clearTimeout(timer);
	}, [processing]);

	return processing ? (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '50vh',
				width: '100%',
				textAlign: 'center',
			}}
		>
			<Row noGutters className="align-items-center justify-content-center">
				<H3 className="w-100 mb-4">You took a wrong turn somewhere</H3>
				<H3Upper className="w-100" style={{ fontSize: '30px' }}>
					That's OK - we will redirect you to the right place in a few seconds
				</H3Upper>
			</Row>
		</div>
	) : (
		<Redirect to="/" />
	);
};

export default NotFound;
