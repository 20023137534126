import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row } from 'react-bootstrap';
import {
	StyledInputBox,
	LoginButton,
	StyledLabelForm,
	StyledErrorMessage,
} from '../../styles/Form';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const ResetPassword = ({ modeCallback }) => {
	const { changePasswordCredentials } = useAuth0();
	const [successMessage, setSuccessMessage] = useState(false);

	const validationSchema = Yup.object({
		email: Yup.string()
			.email('This email is not in a recognised format')
			.required('Please enter your email address'),
	});

	const handleReset = async (values) => {
		try {
			return await changePasswordCredentials(values.email);
		} catch (err) {
			console.error(err.message);
		}
	};

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={{ email: '' }}
				validateOnChange={false}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleReset(values);
					setSubmitting(false);
					setSuccessMessage(true);
					setTimeout(() => {
						setSuccessMessage(false);
						modeCallback('login');
					}, 1500);
				}}
			>
				{({ dirty, isValidating, isSubmitting, handleSubmit }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Row noGutters className="mt-5">
								<StyledLabelForm htmlFor="email">Email</StyledLabelForm>
								<Field
									name="email"
									type="email"
									as={StyledInputBox}
									placeholder={'you@example.com'}
									disabled={successMessage}
								/>
								<ErrorMessage name="email" component={StyledErrorMessage} />
							</Row>
							<Row noGutters>
								{!successMessage ? (
									<LoginButton
										type="submit"
										disabled={
											!dirty || (isSubmitting && !isValidating) ? true : false
										}
									>
										Send me a reset password email
									</LoginButton>
								) : (
									<LoginButton
										type="button"
										style={{ backgroundColor: 'green' }}
									>
										<AiOutlineCheckCircle size={20} color="white" />
									</LoginButton>
								)}
							</Row>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default ResetPassword;
