import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ModalButton from '../../styles/Modal/ModalButton';

type BudgetSelectorProps = {
	points: string;
	setPoints: (points: string) => void;
	teamBudget?: any;
	showReset?: boolean;
	minPoints?: number;
};

const BudgetSelector = ({
	points,
	setPoints,
	teamBudget,
	showReset = true,
	minPoints = 0,
}: BudgetSelectorProps) => {
	const pointsInput = useRef<HTMLInputElement>(null);

	const focus = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (focus.current) focus.current.focus();
	}, []);

	return (
		<>
			<FlexRow>
				<div>
					<Points
						type="number"
						aria-labelledby="junopoints"
						ref={pointsInput}
						value={points}
						points={points}
						min={minPoints}
						max="1000"
						required
						onInvalid={({ currentTarget }) => {
							setMessage(currentTarget, minPoints);
						}}
						onChange={({ currentTarget }) => {
							setPoints(currentTarget.value);
							setMessage(currentTarget, minPoints);
						}}
					/>{' '}
					<Label htmlFor="points" id="junopoints">
						Juno Points
					</Label>
				</div>
				{showReset && (
					<FlexMiddle>
						{Number(points) !== teamBudget && (
							<ModalButton
								type="button"
								version="grey"
								width="75px"
								height="40px"
								className="mr-3"
								onClick={(e) => {
									e.preventDefault();
									setPoints(String(teamBudget));
									if (focus.current) focus.current.focus();
								}}
							>
								Reset
							</ModalButton>
						)}
					</FlexMiddle>
				)}
			</FlexRow>
			<PointsSlider
				ref={focus}
				type="range"
				name="points"
				value={points}
				min="60"
				max="1000"
				step="10"
				onChange={({ currentTarget }) => {
					pointsInput.current?.setCustomValidity('');
					setPoints(currentTarget.value);
				}}
			/>
		</>
	);
};

const setMessage = (teamName: HTMLInputElement, minPoints: number) => {
	const { valueMissing, rangeUnderflow, rangeOverflow, stepMismatch } =
		teamName.validity;
	teamName.setCustomValidity(
		valueMissing
			? 'Please enter some points'
			: rangeUnderflow || rangeOverflow
			? `Points must be between ${minPoints} and 1000`
			: stepMismatch
			? 'Points must be a round number'
			: ''
	);
};

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;

	> div:first-of-type {
		flex-grow: 1;
	}
`;

const FlexMiddle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	> button:first-of-type {
		padding: 0 !important;
		margin: 0 !important;
	}
`;

const Points = styled.input<{ points: string }>`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 48px;
	line-height: 57.6px;
	color: #191a1c;
	width: ${({ points }) => points && `${String(points).length * 33}px`};
	min-width: 33px;
	max-width: 132px;
	font-variant-numeric: tabular-nums;
	border: 0;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&focus {
		border: revert;
	}
`;

const Label = styled.label`
	font-family: 'Roobert Medium';
	font-style: normal;
	font-size: 24px;
	line-height: 28.8px;
	color: ${({ theme }) => theme.mainColors.color.textSecondary}!important;
`;

const PointsSlider = styled.input`
	height: 25px;
	-webkit-appearance: none;
	margin: 32px 0;
	width: 100%;
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 8px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px #000000;
		background: #ededed;
		border-radius: 10px;
		border: 0px solid #000000;
	}
	&::-webkit-slider-thumb {
		box-shadow: 0px 0px 0px 4px #ededed;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: #000000;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -2px;
		transition-duration: 0.3s;
		transition-property: box-shadow;
	}
	&:hover::-webkit-slider-thumb {
		box-shadow: 0px 0px 0px 7px #ededed;
	}
	&:focus::-webkit-slider-thumb {
		box-shadow: 0px 0px 0px 7px #ededed;
	}
	&:focus::-webkit-slider-runnable-track {
		background: #ededed;
	}
	::-moz-range-track {
		width: 100%;
		height: 8px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px #000000;
		background: #ededed;
		border-radius: 10px;
		border: 0px solid #000000;
	}
	::-moz-range-thumb {
		box-shadow: 0px 0px 0px 4px #ededed;
		border: 0;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: #000000;
		cursor: pointer;
		transition-duration: 0.3s;
		transition-property: box-shadow;
	}
	&:focus::-moz-range-thumb {
		box-shadow: 0px 0px 0px 7px #ededed;
	}
	&:hover::-moz-range-thumb {
		box-shadow: 0px 0px 0px 7px #ededed;
	}
`;

export default BudgetSelector;
