import React from 'react';
import styled from 'styled-components';
import { useProductContext } from '../../../contexts/productContext';
import Carousel from '../../utilities/Carousel';
import BrandedCard from './BrandedCard';
import { H2 } from '../../../styles/StylesV2';

const MainContainer = styled.div`
	width: 100%;
	.slick-slide {
		overflow: hidden;
	}
`;

const GridContainer = styled.div`
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	column-gap: 16px;
	row-gap: 16px;

	@media (max-width: 900px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

const BrandedVirtualCardCarousel = () => {
	const { brandedProducts } = useProductContext();

	return (
		<MainContainer data-test-id="brandedCarousel">
			{brandedProducts?.length > 3 && (
				<Carousel
					loading={!brandedProducts}
					initialSlide={0}
					style={{}}
					title="Juno Card®"
					itemPadding={16}
					draggable={false}
					items={brandedProducts.map((item: any) => (
						<BrandedCard key={item.id} product={item} />
					))}
				/>
			)}
			{brandedProducts?.length && brandedProducts?.length <= 3 ? (
				<>
					<H2>Juno Card®</H2>
					<GridContainer>
						{brandedProducts.map((item: any) => (
							<BrandedCard key={item.id} product={item} />
						))}
					</GridContainer>
				</>
			) : null}
		</MainContainer>
	);
};

export default BrandedVirtualCardCarousel;
