import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { BoxInputLabel, ModalButton } from '../../styles/StylesV2';
import MultiPartAddress from '../utilities/MultiPartAddress';
import Loading from '../utilities/Loading';
import 'react-phone-number-input/style.css';

const Container = styled.div`
	width: 100%;
	height: fit-content;
	margin-top: 32px;
`;

const StyledErrorMessage = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	position: relative;
	bottom: 0px;
	margin-top: 8px;
	font: normal normal 400 12px/14px 'Roobert Regular';
	color: #cc1440;
`;

const StyledInputBox = styled.input`
	font: normal normal 400 14px/16px 'Product Sans Regular';
	width: 100%;
	height: 56px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 16px;

	&:focus {
		background: rgba(0, 0, 0, 0.05);
		outline: none;
	}
`;

const StyledLabel = styled(BoxInputLabel)`
	text-align: left;
	margin: 24px 0px 8px 0px;
	box-sizing: border-box;
	font-size: 14px;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledRow = styled(Row)`
	padding: 0px;
	margin: 0px !important;
`;

const StyledButton = styled(ModalButton)`
	margin-top: 24px;
	width: 100%;
`;

const BorderTopRow = styled(Row)`
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	padding: 0px;
	margin: 0px !important;
`;

const StyledPhoneInput = styled(PhoneInput)`
    width: 100%;
    .PhoneInputInput {
        font: normal normal 400 14px/16px "Product Sans Regular";
        width: 100%;
        height: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        padding 10px;
    }
`;

const validationSchema = Yup.object({
	fullName: Yup.string()
		.min(1, 'Must be at least 1 character.')
		.max(24, 'Must be no more than 24 characters.')
		.required('First name is required.')
		.matches(/[^\s]+[\s]+[^\s]+/, 'Must include first and last name.')
		.typeError('First name is required.'),
	billing: Yup.object({
		line1: Yup.string()
			.required('Address Line 1 is required.')
			.typeError('Address Line 1 is required.'),
		city: Yup.string()
			.required('Address Town or City is required.')
			.typeError('Address Town or City is required.'),
		postalCode: Yup.string()
			.required('Address Postal Code is required.')
			.typeError('Address Postal Code is required.'),
		country: Yup.string()
			.required('Address Country is required.')
			.typeError('Address Country is required.'),
	}),
	phone: Yup.string()
		.required('Phone number is required.')
		.test(
			'phone',
			'Invalid phone number',
			(val) => val && isValidPhoneNumber(val)
		)
		.typeError('Phone number is required.'),
});

const CreateJunoCardForm = ({ onSubmit = () => {}, loading }: any) => {
	return (
		<Container>
			<Formik validationSchema={validationSchema} initialValues={{}}>
				{({
					values,
					isSubmitting,
					isValidating,
					isValid,
					dirty,
					setFieldValue,
				}) => {
					return (
						<Form>
							<BorderTopRow>
								<StyledLabel>Full Name</StyledLabel>
								<Field
									type="text"
									name="fullName"
									as={StyledInputBox}
									value={values.fullName}
									onChange={(e) => {
										setFieldValue('fullName', e.target.value);
									}}
								/>
								<ErrorMessage name="fullName" component={StyledErrorMessage} />
							</BorderTopRow>
							<StyledRow>
								<MultiPartAddress
									option={{
										title: 'Billing Address',
										compulsory: true,
										value: values.billing,
									}}
									name="billing"
									value={values.billing}
									onChange={(e) => {
										setFieldValue('billing', e.target.value);
									}}
								/>
								<ErrorMessage name="billing" component={StyledErrorMessage} />
							</StyledRow>
							<StyledRow>
								<StyledLabel>Phone Number</StyledLabel>
								<Field>
									{({ field }) => (
										<StyledPhoneInput
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...field}
											name="phone"
											placeholder="Enter phone number"
											defaultCountry="GB"
											value={values.phone}
											onChange={(e) => {
												setFieldValue('phone', e);
											}}
										/>
									)}
								</Field>
								<ErrorMessage name="phone" component={StyledErrorMessage} />
							</StyledRow>
							<StyledRow>
								<StyledButton
									disabled={
										loading ||
										isSubmitting ||
										isValidating ||
										!(isValid && dirty)
									}
									onClick={() => onSubmit(values)}
								>
									{!loading && 'Request Card'}
									{loading && <Loading />}
								</StyledButton>
							</StyledRow>
						</Form>
					);
				}}
			</Formik>
		</Container>
	);
};

export default CreateJunoCardForm;
