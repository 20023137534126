import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from './logo.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	background: #f8f8f8;
	border-top: 1px solid #e4e4e4;
	font-family: 'TTInterfaces Medium';
`;
const StyledBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1250px;
	height: fit-content;
	margin: 0;

	padding: 55px 0px;
	justify-content: space-between;

	@media (max-width: 1250px) {
		max-width: 980px;
		margin: 0px 80px;
	}

	@media (max-width: 992px) {
		max-width: 100%;
		padding: 30px 0px;
		margin: 0px 20px;
	}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	width: 45%;

	@media (max-width: 992px) {
		width: 100%;
	}
`;
const Juno = styled.img`
	width: 55px;
	height: 26px;
	margin-bottom: 18px;
	transition: opacity 150ms ease-out;

	:hover {
		opacity: 60%;
	}
`;
const Subtext = styled.p`
	font-style: normal;
	font-size: 15px;
	line-height: 20px;
	color: #0a0f16;
	opacity: 70%;
	margin-bottom: 30px;
	@media (max-width: 992px) {
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
	}
`;
const ImgLinksWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
`;
const ImgLinks = styled.img`
	width: 30px;
	height: 30px;

	transition: opacity 150ms ease-out;
	:hover {
		opacity: 60%;
	}
`;

const Spacer = styled.div`
	width: 15px;
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 55px;

	@media (max-width: 992px) {
		flex-direction: column;
	}
`;
const Copyright = styled.p`
	margin: 0;
	padding: 0;
	font-style: normal;
	font-size: 15px;
	line-height: 20px;
	color: #0a0f16;

	@media (max-width: 992px) {
		margin-bottom: 10px;
		font-style: normal;
		font-weight: 500;
		font-size: 12.5px;
		line-height: 15px;
	}
`;
const StyledLink = styled.a`
	padding: 0;
	font-style: normal;
	font-size: 15px;
	line-height: 20px;
	color: #0a0f16;
	transition: opacity 150ms ease-out;

	:hover {
		color: #0a0f16;
		opacity: 60%;
		text-decoration: none;
	}

	@media (max-width: 992px) {
		font-style: normal;
		font-weight: 500;
		font-size: 12.5px;
		line-height: 15px;
	}
`;

const Footer = () => {
	return (
		<FooterContainer>
			<StyledBox>
				<Top>
					<Link to="/">
						<Juno alt="Juno Logo" src={logo} />
					</Link>
					<Subtext>
						Are you an employer? Give your teams freedom and choice. Our simple
						subscription gives businesses a way to create a flexible, inclusive,
						comprehensive, engaging and cost-effective support programme.
					</Subtext>
					<ImgLinksWrapper>
						<a
							href="https://www.instagram.com/with__juno/"
							target="_blank"
							rel="noreferrer"
						>
							<ImgLinks alt="instagram" src={instagram} />
						</a>
						<Spacer />
						<a
							href="https://www.linkedin.com/company/withjuno/mycompany/"
							target="_blank"
							rel="noreferrer"
						>
							<ImgLinks alt="linkedin" src={linkedin} />
						</a>
					</ImgLinksWrapper>
				</Top>
				<Bottom>
					<Copyright>© {new Date().getFullYear()} With Juno Ltd</Copyright>
					<div>
						<StyledLink
							className="mr-4"
							href="https://www.withjuno.com/juno-terms-and-conditions"
							target="_blank"
						>
							Terms of Service
						</StyledLink>
						<StyledLink href="/privacy-policy" target="_blank" rel="noreferrer">
							Privacy Policy
						</StyledLink>
					</div>
				</Bottom>
			</StyledBox>
		</FooterContainer>
	);
};

export default Footer;
