import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	H2,
	H4,
	H5,
	SmallTextSecondary,
} from '../../../../styles/StylesV3Main';
import { Row, Col } from 'react-bootstrap';
import AddNewPaymentMethodWrapper from '../AddNewPaymentMethodWrapper';
import PaymentMethodDisplay from './PaymentMethodDisplay';
import { useUserContext } from '../../../../contexts/userContext';
import StyledLoading from '../../../../styles/LoadingStyles';
import { MdOutlineCreditCard } from 'react-icons/md';

const PaymentContainer = styled.div`
	margin: 64px 0px;

	@media (max-width: 992px) {
		margin: 32px 0px;
	}
`;

const StyledRow = styled(Row)`
	width: 100%;
`;

type AddPaymentButtonProps = {
	bg?: string;
	hover?: string;
};
const AddPaymentButton = styled.div<AddPaymentButtonProps>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	width: 163px;
	height: 32px;
	background: ${(props) => (props.bg ? props.bg : 'rgba(25, 26, 28, 0.05)')};
	border-radius: 3px;
	cursor: pointer;
	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;
	&:hover {
		background: ${(props) =>
			props.hover ? props.hover : 'rgba(25, 26, 28, 0.1)'};
	}
`;

const DesktopView = styled.div`
	display: block;
	@media (max-width: 992px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 992px) {
		margin-top: 16px;
		display: block;
	}
`;

type WrapperProps = {
	align?: string;
	padding?: string;
};

const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.align ? props.align : 'flex-start')};
	padding: ${(props) => (props.padding ? props.padding : '0px 24px')};
	width: 100%;
	height: fit-content;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	border-radius: 8px;
	/* Inside auto layout */
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 0;
	margin-top: 27px;
`;

type CircleProps = {
	size?: string;
	bg?: string;
};

const Circle = styled.div<CircleProps>`
  width: ${(props) => (props.size ? props.size : '48px')};
  height: ${(props) => (props.size ? props.size : '48px')};
  border-radius: 50%;
  cursor: pointer;
  background: ${(props) => (props.bg ? props.bg : 'rgba(0,0,0,0.05)')};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background:${(props) => (props.bg ? props.bg : 'rgba(0, 0, 0, 0.1)')}; 
`;

type PaymentMethodWrapperProps = {
	team: any;
};

const PaymentMethodWrapper = ({ team }: PaymentMethodWrapperProps) => {
	const [addPMView, setAddPMView] = useState(false);
	const { userData, updateDefaultPaymentMethodTeam, deletePaymentMethodTeam } =
		useUserContext();

	const [cards, setCards] = useState<any[]>([]);

	useEffect(() => {
		if (userData) {
			setCards(
				userData.teams.filter((item: { id: number }) => item.id === team.id)[0]
					.stripeCustomerPaymentMethods
					? userData.teams.filter(
							(item: { id: number }) => item.id === team.id
					  )[0].stripeCustomerPaymentMethods
					: []
			);
		}
	}, [userData, team.id]);

	const handleDefaultChange = (pmId: string, teamId: number) => {
		try {
			updateDefaultPaymentMethodTeam(pmId, teamId);
		} catch (err: any) {
			console.log(err);
			console.error(err.message);
		}
	};

	const IsDefaultPaymentMethod = (pmId: any, userData: any) => {
		if (
			pmId ===
			userData.teams.find((item: any) => item.id === team.id)
				.defaultStripeCustomerPaymentMethodId
		) {
			return true;
		} else return false;
	};

	if (!cards) {
		return <StyledLoading height="20vh" />;
	}

	return (
		<PaymentContainer>
			{cards.length > 0 ? (
				<>
					<StyledRow
						noGutters
						className={'justify-content-between align-items-center'}
					>
						<H2>Payment methods</H2>
						<DesktopView>
							<AddPaymentButton onClick={() => setAddPMView(true)}>
								<H5>Add payment method</H5>
							</AddPaymentButton>
						</DesktopView>
					</StyledRow>
					<StyledRow
						noGutters
						className={'justify-content-start align-items-center'}
					>
						<Col xs={12} className="px-0 py-0">
							<Wrapper>
								{cards.reverse().map((pm, index) => (
									<PaymentMethodDisplay
										key={index}
										paymentMethod={pm}
										teamId={team.id}
										defaultPaymentMethod={() =>
											IsDefaultPaymentMethod(pm.pmId, userData)
										}
										handleDefaultChange={() =>
											handleDefaultChange(pm.pmId, team.id)
										}
										deletePaymentMethodTeam={deletePaymentMethodTeam}
									/>
								))}
							</Wrapper>
						</Col>
					</StyledRow>
					<MobileView>
						<Row noGutters className={'justify-content-end'}>
							<AddPaymentButton onClick={() => setAddPMView(true)}>
								<H5>Add payment method</H5>
							</AddPaymentButton>
						</Row>
					</MobileView>
				</>
			) : (
				<StyledRow
					noGutters
					className={'justify-content-between align-items-center'}
				>
					<H2>Payment methods</H2>
					<Wrapper align="center" padding="24px">
						<Circle size="80px" bg="#F8F8F8">
							<MdOutlineCreditCard size={20} color="#5B6166" />
						</Circle>
						<H4 className="mt-3">No payment methods</H4>
						<SmallTextSecondary className="mt-1 text-center">
							Please add a payment method now to be able to use the Juno
							platform without any issues
						</SmallTextSecondary>
						<AddPaymentButton
							onClick={() => setAddPMView(true)}
							bg="black"
							hover="black"
							className="mt-3"
						>
							<H5 style={{ color: 'white' }}>Add payment method</H5>
						</AddPaymentButton>
					</Wrapper>
				</StyledRow>
			)}
			<AddNewPaymentMethodWrapper
				show={addPMView}
				onHide={() => setAddPMView(false)}
				team={team}
			/>
		</PaymentContainer>
	);
};

export default PaymentMethodWrapper;
