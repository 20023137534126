import { useMemo } from 'react';

type ProductType = {
	baseCost: number;
	enquire: boolean;
	active: boolean;
};

type Product = {
	productTypes: ProductType[];
	createdAt: string;
	title: string;
	categories: { id: number }[];
	score: number;
	description: string;
	imagePath: string;
	id: number;
	type: 'Card' | 'Delivery' | 'Physical' | 'Online' | 'Event';
	provider: any;
	cost: number | null;
	location: string;
	shortTitle?: string;
	shortDescription?: string;
};

const useFilter = (
	products: Product[],
	pointsRange: [number, number],
	orderBy: 'relevance' | 'newest' | 'dearest' | 'cheapest' | 'alphabetical',
	categories: { [key: number]: boolean } = {}
) => {
	const maxPoints = useMemo(
		() =>
			products?.reduce(
				(max, { productTypes }) =>
					productTypes.reduce(
						(subMax, { baseCost, enquire }) =>
							Math.max(subMax, !enquire ? baseCost : 0),
						max
					),
				0
			),
		[products]
	);

	const getMinPoints = ({ productTypes }: Product) =>
		productTypes
			.filter(({ active, enquire }) => active && !enquire)
			.sort((a, b) => a.baseCost - b.baseCost)[0]?.baseCost ?? -1;

	const sorter: { [key: string]: (a: Product, b: Product) => number } = {
		relevance: (a, b) => {
			return b.score - a.score;
		},
		newest: (a, b) =>
			new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		dearest: (a, b) => getMinPoints(b) - getMinPoints(a),
		cheapest: (a, b) => getMinPoints(a) - getMinPoints(b),
		alphabetical: (a, b) => a.title.localeCompare(b.title),
	};

	const filteredAndSortedProducts = useMemo(
		() =>
			products
				?.filter(({ productTypes, categories: cats }) => {
					let inRange = false;
					let allEnquire = !pointsRange[0];

					productTypes.forEach(({ enquire, baseCost }) => {
						if (!enquire) {
							allEnquire = false;
							if (baseCost >= pointsRange[0] && baseCost <= pointsRange[1]) {
								inRange = true;
							}
						}
					});

					inRange = inRange || allEnquire;

					return (
						inRange &&
						(!Object.keys(categories).length ||
							cats.find(({ id }) => categories[id]))
					);
				})
				.sort(sorter[orderBy]),
		[products, pointsRange, orderBy, categories]
	);

	return { maxPoints, products: filteredAndSortedProducts };
};

export default useFilter;
