export const handleBackNavigation = (history) => {
	if (history.location.key) {
		history.goBack();
	} else {
		history.push('/');
	}
};

export const getUsernameEmail = (userData) => {
	if (userData.firstName && userData.lastName)
		return `${userData.firstName} ${userData.lastName}`;
	return userData.email;
};

export const getFirstNameText = (userData) => {
	if (userData.firstName) return `Hi ${userData.firstName}`;
	return `Welcome back`;
};

export const getTeamName = (userData) => {
	if (userData.teams.length !== 0) {
		if (!userData.currentTeam) {
			return 'Team';
		}
		return userData.teams.filter(
			(item) => item.id === userData.currentTeam
		)?.[0]?.name;
	}
	return 'Team';
};

export const getEnquiryOrPoints = (product) => {
	if (
		product.productTypes.length > 0 &&
		product.productTypes.filter((service) => service.enquire && service.active)
			.length === product.productTypes.length
	) {
		return 'Upon Enquiry';
	}
	return `From ${
		product.productTypes
			.filter((service) => !service.enquire && service.active)
			.sort((a, b) => {
				return a.baseCost > b.baseCost ? 1 : -1;
			})[0].baseCost
	}`;
};

export const getMinPoints = (product) => {
	if (
		product.productTypes.length > 0 &&
		product.productTypes.filter((service) => service.enquire && service.active)
			.length === product.productTypes.length
	) {
		// a Brand with all enquiry products
		return 'TBC';
	}
	return `${
		product.productTypes
			.filter((service) => !service.enquire && service.active)
			.sort((a, b) => {
				return a.baseCost > b.baseCost ? 1 : -1;
			})[0].baseCost
	}+`;
};
