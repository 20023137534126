import React from 'react';
import styled from 'styled-components';
import { BiChevronRight } from 'react-icons/bi';
import Spinner from '../Spinner/Spinner';

const ActionButton = ({
	text,
	color,
	loading = false,
	onClick,
	...props
}: any) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Button
		type="button"
		color={color}
		disabled={loading}
		onClick={onClick}
		{...props}
	>
		{text}
		{!loading ? (
			<BiChevronRight size="24" />
		) : (
			<Spinner color="rgba(91,97,102,0.5)" />
		)}
	</Button>
);

const Button = styled.button<{ color: string }>`
	width: 100%;
	color: ${({ color }) => color || '#191a1c'};
	height: 48px;
	font-family: 'Roobert Semi Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 160%;
	display: flex;
	justify-content: space-between;
	padding: 13px 20px 13px 16px;
	background-color: #fff;
	border: 0;
	transition: padding-right 300ms;
	&:hover,
	&:focus {
		padding-right: 8px;
	}
	svg {
		color: rgba(91, 97, 102, 0.5);
	}
	&:disabled svg {
		padding: 3px;
	}
`;
export default ActionButton;
