import React, { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import arrowDown from './arrow-down.svg';

const Select = ({
	options,
	...props
}: {
	options: { value: string; label: string; disabled?: boolean }[];
} & SelectHTMLAttributes<HTMLSelectElement>) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<StyledSelect {...props}>
		{options.map(({ value, label, disabled }) => (
			<option key={value} value={value} disabled={disabled}>
				{label}
			</option>
		))}
	</StyledSelect>
);

const StyledSelect = styled.select`
	font-family: 'TTInterfaces Regular';
	width: 100%;
	font-size: 16px;
	padding: 10px 18px;
	border: 1.5px solid #eaecf0;
	border-radius: 10px;
	appearance: none;
	background-image: url(${arrowDown});
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.7em auto, 100%;
`;

export default Select;
