/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { MdArrowBack } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';
import axios from 'axios';
import {
	Circle,
	Container,
	BackgroundWrapper,
	OrderContainer,
	RowView,
} from '../../styles/ProductStyles';
import {
	H1,
	Paragraph,
	H5TextSecondary,
	H3,
	Separator,
	Small,
} from '../../styles/StylesV3Main';

import { PrimaryButton } from '../../styles/Buttons';
import Loading from '../utilities/Loading';
import Alert from '../../components/utilities/Alert';
import { useUserContext } from '../../contexts/userContext';
import OrderInformation from './OrderInformation';
import config from '../../config';
import { useAuth0 } from '../../contexts/react-auth0-spa';
import client from '../../api/client';

const TitleRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 560px;
	height: fit-content;
	border-radius: 3px;
	@media (max-width: 992px) {
		width: 100%;
	}
`;

const WRow = styled(Row)`
	width: 100%;
`;

const StyledInfoRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
	padding: 32px 0px;
	width: 100%;
	height: fit-content;
	border-top: ${(props) =>
		props.border
			? `1px solid ${props.theme.mainColors.color.separator}`
			: 'none'};
	margin: 0px;

	@media (max-width: 992px) {
		padding: 16px 0px;
	}
`;

const ConfirmOrderPage = ({
	product,
	service,
	cost,
	fee,
	type,
	cardType,
	options,
	proceedButtons,
	balance,
	handlePreviousStep,
	handleNextStep,
	processingCostUpdate,
}) => {
	const { createBookingUser, userData } = useUserContext();
	const [alert, setAlert] = useState({
		heading: '',
		message: '',
		variant: '',
	});
	const [processing, setProcessing] = useState(false);
	const { getTokenSilently } = useAuth0();

	const uploadToS3 = useCallback(
		async (option) => {
			try {
				const token = await getTokenSilently();
				client.defaults.headers.Authorization = 'Bearer ' + token;
				const results = await Promise.allSettled(
					option.value.map(async (value) => {
						const response = await client.post(
							`${config.api.HOST}/s3/signedURL`,
							{
								fileName: value.file.name,
								fileType: value.file.type,
							}
						);
						await axios.put(response.data.signedUrl, value.file, {
							headers: {
								'Content-Type': value.file.type,
							},
						});

						return {
							publicUrl: response.data.publicUrl,
							directUrl: response.data.directUrl,
							fileName: value.file.name,
							fileType: value.file.type,
						};
					})
				);
				return {
					...option,
					value: results.map((result) => result.value),
				};
			} catch (err) {
				console.error(err.message);
			}
		},
		[getTokenSilently]
	);

	const bookExperience = useCallback(
		async (acrossAccounts) => {
			try {
				setProcessing(true);
				const optionsRe = await Promise.all(
					options[service.id].values.map(async (option) =>
						option.type === 'file' ? await uploadToS3(option) : option
					)
				);
				const response = await createBookingUser({
					product,
					service,
					options: optionsRe,
					acrossAccounts,
				});
				if (response && response.status === 'success') {
					setProcessing(false);
					handleNextStep(response.result);
				} else {
					setAlert({
						heading: 'Order an experience',
						message: `We could not complete the order. ${response.error}`,
						variant: 'danger',
					});
					setProcessing(false);
				}
			} catch (err) {
				console.error(err.message);
			}
		},
		[
			uploadToS3,
			createBookingUser,
			handleNextStep,
			options,
			product,
			service,
			userData.email,
			userData.firstName,
			userData.id,
			userData.lastName,
		]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<BackgroundWrapper>
			<Container>
				<Row noGutters className="justify-content-start align-items-start">
					<Circle size="48px" onClick={() => handlePreviousStep()}>
						<MdArrowBack size={20} />
					</Circle>
				</Row>
				<Row
					noGutters
					className="justify-content-center align-items-center my-5"
				>
					<TitleRow>
						<H1>
							{type
								? type === 'card'
									? cardType === 'createCard'
										? 'Confirm your order'
										: 'Confirm your top-up'
									: type === 'concierge'
									? 'Confirm Enquiry'
									: type === 'reimbursement'
									? 'Confirm your reimbursement'
									: 'Confirm your order'
								: service && service.enquire
								? 'Confirm enquiry'
								: 'Confirm your order'}
						</H1>
					</TitleRow>
				</Row>
				<Row noGutters className="justify-content-center align-items-center">
					<OrderContainer>
						<OrderInformation
							product={product}
							service={service}
							options={options}
							type={type}
						/>

						{processingCostUpdate ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: '10vh',
									width: '100%',
									textAlign: 'center',
								}}
							>
								<Loading />
							</div>
						) : (
							<>
								{type && fee !== 0 && (
									<>
										<Col xs={12} className="px-0 py-4 mt-2">
											<Separator />
										</Col>
										<Col xs={12} className="px-0 py-0">
											<RowView
												border={false}
												className="align-items-center"
												style={{
													padding: 0,
												}}
											>
												<H5TextSecondary>
													{type === 'card'
														? 'Fee'
														: type === 'concierge'
														? 'Concierge Fee (only for completed orders)'
														: type === 'reimbursement'
														? 'Fee (only for completed reimbursements)'
														: ''}
												</H5TextSecondary>
												<H3>{fee} points</H3>
											</RowView>
										</Col>
									</>
								)}
								{service && service.enquire && (
									<Row
										noGutters
										as={StyledInfoRow}
										border={!type && !fee !== 0}
										style={{ alignItems: 'center' }}
									>
										<H5TextSecondary>Total</H5TextSecondary>
										<H1>Upon enquiry</H1>
									</Row>
								)}
								{service && !service.enquire && (
									<>
										<Row
											noGutters
											as={StyledInfoRow}
											border={!type && !fee !== 0}
											style={{ alignItems: 'center' }}
										>
											<H5TextSecondary>Total</H5TextSecondary>
											<H1>{fee > 0 ? cost + fee : cost} Points</H1>
										</Row>

										{balance &&
											balance.length > 0 &&
											balance.map((item, index) => (
												<WRow
													noGutters
													className="justify-content-between align-items-start mb-4 pb-2"
													key={index}
												>
													<Col xs={9}>
														<H5TextSecondary>{item.title}</H5TextSecondary>
													</Col>
													<Col xs={3}>
														<Paragraph
															style={{
																textAlign: 'right',
																lineHeight: 'initial',
															}}
														>
															{item.value} points
														</Paragraph>
													</Col>
												</WRow>
											))}
									</>
								)}

								<Alert
									heading={alert.heading}
									message={alert.message}
									variant={alert.variant}
								/>
								{proceedButtons && (
									<WRow
										noGutters
										className="justify-content-center align-items-center"
									>
										{proceedButtons?.book && (
											<PrimaryButton
												width="100%"
												height="64px"
												disabled={processing}
												onClick={() => bookExperience(false)}
											>
												{processing
													? 'Processing'
													: service && service.enquire
													? 'Enquire'
													: 'Pay with Juno Points'}
											</PrimaryButton>
										)}
										{proceedButtons?.transfer && (
											<PrimaryButton
												width="100%"
												height="64px"
												disabled={processing}
												onClick={() => bookExperience(true)}
											>
												{processing
													? 'Processing'
													: service && service.enquire
													? 'Enquire'
													: 'Pay with Juno Points'}
											</PrimaryButton>
										)}
									</WRow>
								)}
								{product && product.thirdPartyTc && (
									<WRow
										noGutters
										className="justify-content-center align-items-center mt-4 pt-2"
									>
										<Small>
											<Markdown
												options={{
													forceBlock: true,
													overrides: {
														a: {
															props: {
																target: '_blank',
															},
														},
													},
												}}
											>
												{product.thirdPartyTc}
											</Markdown>
										</Small>
									</WRow>
								)}
							</>
						)}
					</OrderContainer>
				</Row>
			</Container>
		</BackgroundWrapper>
	);
};

export default ConfirmOrderPage;
