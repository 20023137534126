/* eslint-disable import/prefer-default-export */

export const ExpenseCategories = [
	{ value: 'PLACEHOLDER', label: 'Select expense category', disabled: true },
	{ value: 'childcare', label: 'Childcare' },
	{ value: 'gym', label: 'Gym' },
	{ value: 'fitness', label: 'Fitness' },
	{ value: 'nutrition', label: 'Nutrition' },
	{ value: 'beauty', label: 'Beauty / self care' },
	{ value: 'work-from-home', label: 'Work from home' },
	{ value: 'home', label: 'Home' },
	{ value: 'tech', label: 'Tech' },
	{ value: 'medical', label: 'Medical' },
	{ value: 'education', label: 'Education' },
	{ value: 'travel', label: 'Travel' },
];
