const rules = {
	visitor: {
		static: ['products:list', 'home-page:visit'],
		dynamic: {
			'team:visit': ({ roles }) => roles.includes('owner'),
			'team:manage': ({ roles }) => roles.includes('owner'),
			'plan:manage': ({ roles }) => roles.includes('owner'),
		},
	},
	subscriber: {
		static: [
			'products:list',
			'products:create',
			'users:getSelf',
			'home-page:visit',
			'profile-page:visit',
			'balance:see',
		],
		dynamic: {
			'products:edit': ({ userId, postOwnerId }) => {
				if (!userId || !postOwnerId) return false;
				return userId === postOwnerId;
			},
		},
	},
	member: {
		static: ['team:transfer'],
	},
	owner: {
		static: ['team:manage', 'plan:manage'],
	},
	admin: {
		static: [
			'profile-page:visit',
			'admin-page:visit',
			'admin-product:visit',
			'partners-page:visit',
			'support-page:visit',
			'report-page:visit',
			'users:add',
			//"users:delete",
			'users:download',
			'invites:add',
			'invites:delete',
			'invites:download',
			'teams:add',
			//"teams:delete",
			'teams:download',
			'bookings:add',
			'bookings:delete',
			'bookings:download',
			'teamMemberships:add',
			'teamMemberships:delete',
			'teamMemberships:download',
			'providers:add',
			//"providers:delete",
			'providers:download',
			'products:add',
			//"products:delete",
			'products:download',
			'services:add',
			//"services:delete",
			'services:download',
			'categories:add',
			//"categories:delete",
			'categories:download',
			'team:visit',
			'plan:manage',
			'team:manage',
			'report:download',
		],
		dynamic: {
			'bookings:refund': ({ userId, bookingOwnerId }) => {
				if (!userId || !bookingOwnerId) return false;
				return userId !== bookingOwnerId;
			},
		},
	},
	support: {
		static: ['support-page:visit'],
	},
	partner: {
		static: ['partners-page:visit'],
	},
};

export default rules;
