import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useSearchParam from './useSearchParam';
import { OpenAILogo } from '../images';

const StyledSearchContainer = styled.form`
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const OpenAIMessage = styled.div`
	width: 181px;
	height: 21px;
	display: flex;
	flex-direction: row;
	margin-top: 35px;

	h3 {
		font-family: 'TTInterfaces Medium';
		font-style: normal;
		font-size: 17px;
		line-height: 21px;
		opacity: 0.35;
		margin-right: 9px;
	}

	img {
		height: 20px;
		width: 80px;
	}
`;

const StyledHeading = styled.label`
	font-family: 'TTInterfaces Demibold';
	font-size: 36px;
	line-height: 48px;
	color: #000000;
	text-align: center;
`;

const SearchInputContainer = styled.div`
	width: 550px;
	max-width: 100%;
	height: 60px;
	background: #ffffff;
	box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
	border-radius: 100px;
	display: flex;
	padding: 0 26px;
	justify-content: center;
	align-items: center;
	margin-top: 35px;
	position: relative;
	button {
		padding: 0;
		background-color: transparent;
		position: absolute;
		right: 3%;
		border: 0;
	}
`;

const StyledSearchInput = styled.input`
	width: 90%;
	height: 90%;
	border: 0px;
	font-family: 'TTInterfaces Medium';
	color: #000000;
	font-size: 23px;
	line-height: 30px;
	caret-color: #438ce2;
	:focus {
		outline: none;
		opacity: 1;
		::placeholder {
			color: transparent;
		}
	}
	::placeholder {
		color: #000;
		opacity: 0.3;
	}
	text-align: center;
`;

const Search = () => {
	const history = useHistory();
	const searchParam = useSearchParam();
	const [searchValue, setSearchValue] = useState(searchParam || '');

	useEffect(() => {
		setSearchValue(searchParam || '');
	}, [searchParam]);

	return (
		<StyledSearchContainer
			onSubmit={(e) => {
				e.preventDefault();
				if (searchValue !== searchParam) {
					if (searchValue)
						history.push(`?search=${encodeURIComponent(searchValue)}`);
					else history.push('/');
				}
			}}
		>
			<h1 style={{ margin: 0 }}>
				<StyledHeading htmlFor="searchInput">
					What would you like to focus on?
				</StyledHeading>
			</h1>
			<SearchInputContainer>
				<StyledSearchInput
					id="searchInput"
					placeholder="e.g. I want to overcome my anxiety"
					type="text"
					name="searchInput"
					value={searchValue}
					onChange={(e) => {
						setSearchValue(e.target.value);
					}}
				/>
				<button type="submit">
					<svg
						width="26"
						height="26"
						viewBox="0 0 26 26"
						fill="none"
						aria-label="Search"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.978321 10.8027C0.978321 12.1563 1.23262 13.4277 1.74121 14.6172C2.24981 15.7984 2.95527 16.8402 3.85762 17.7426C4.75996 18.6449 5.80176 19.3504 6.98301 19.859C8.17246 20.3676 9.44395 20.6219 10.7975 20.6219C11.8639 20.6219 12.8811 20.4578 13.849 20.1297C14.817 19.8016 15.7029 19.3504 16.5068 18.7762L22.5607 24.8424C22.7084 24.9818 22.8684 25.0844 23.0406 25.15C23.2211 25.2238 23.4098 25.2607 23.6066 25.2607C23.8855 25.2607 24.1316 25.1951 24.3449 25.0639C24.5582 24.9408 24.7223 24.7686 24.8371 24.5471C24.9602 24.3256 25.0217 24.0795 25.0217 23.8088C25.0217 23.6119 24.9848 23.4273 24.9109 23.2551C24.8453 23.0828 24.7469 22.9311 24.6156 22.7998L18.5986 16.7459C19.2303 15.9256 19.7225 15.0109 20.0752 14.002C20.4361 12.993 20.6166 11.9266 20.6166 10.8027C20.6166 9.44922 20.3623 8.18184 19.8537 7.00059C19.3451 5.81113 18.6396 4.76524 17.7373 3.86289C16.835 2.96055 15.7891 2.25508 14.5996 1.74649C13.4184 1.23789 12.151 0.983595 10.7975 0.983595C9.44395 0.983595 8.17246 1.23789 6.98301 1.74649C5.80176 2.25508 4.75996 2.96055 3.85762 3.86289C2.95527 4.76524 2.24981 5.81113 1.74121 7.00059C1.23262 8.18184 0.978321 9.44922 0.978321 10.8027ZM3.08242 10.8027C3.08242 9.73633 3.2793 8.73965 3.67305 7.8127C4.075 6.87754 4.62871 6.05723 5.33418 5.35176C6.04785 4.63809 6.86816 4.08438 7.79512 3.69063C8.73027 3.28867 9.73105 3.0877 10.7975 3.0877C11.8639 3.0877 12.8605 3.28867 13.7875 3.69063C14.7227 4.08438 15.543 4.63809 16.2484 5.35176C16.9539 6.05723 17.5076 6.87754 17.9096 7.8127C18.3115 8.73965 18.5125 9.73633 18.5125 10.8027C18.5125 11.8691 18.3115 12.8699 17.9096 13.8051C17.5076 14.732 16.9539 15.5482 16.2484 16.2537C15.543 16.9592 14.7227 17.5129 13.7875 17.9148C12.8605 18.3168 11.8639 18.5178 10.7975 18.5178C9.73105 18.5178 8.73027 18.3168 7.79512 17.9148C6.86816 17.5129 6.04785 16.9592 5.33418 16.2537C4.62871 15.5482 4.075 14.732 3.67305 13.8051C3.2793 12.8699 3.08242 11.8691 3.08242 10.8027Z"
							fill="#B2B5B8"
						/>
					</svg>
				</button>
			</SearchInputContainer>
			<OpenAIMessage>
				<h3>Powered by</h3>
				<img alt="open-ai-logo" src={OpenAILogo} />
			</OpenAIMessage>
		</StyledSearchContainer>
	);
};

export default Search;
