import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Alert, Spinner } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { Header, BoxInputLabel, PSansText } from '../../styles/StylesV2';
import { useUserContext } from '../../contexts/userContext';
import ImageUpload from '../utilities/ImageUpload';
import PhoneModal from '../../components/productComponent/PhoneModal';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const StyledAccountsView = styled.div`
	width: 100%;
	height: auto;
	background: #fff;
	border-radius: 3px;

	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	margin-left: 0px;
	@media (max-width: 1200px) {
		margin-left: 40px;
	}
	@media (max-width: 991.98px) {
		margin-left: 0px;
		width: 100%;
		height: auto;
		max-width: 100%;
		padding: 20px 20px;
		background: #f8f8f8;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;
	}
`;

const StyledHeader = styled(Header)`
	line-height: 120%;
	margin-bottom: 34px;
	margin-top: 50px;

	@media (max-width: 991.98px) {
		margin-bottom: 32px;
		margin-top: 0px;
		padding: 0px;
		background: #f8f8f8;
	}
`;

const StyledFormRow = styled(Row)`
	width: 100%;
	padding: 10px 50px 10px 60px;
	@media (max-width: 991.98px) {
		padding: 0px 0px;
	}
`;

const StyledLabel = styled(BoxInputLabel)`
	margin: 24px 0px 4px 0px;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 991.98px) {
		padding-bottom: 5px;
	}
`;

const StyledText = styled.div`
	font-family: 'Roobert Bold';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	box-sizing: border-box;
	width: 100%;
	margin: 0px;
`;

const StyledInputBox = styled.input`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 95%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 0px 15px 0px 15px;
	position: relative;
	@media (max-width: 991.98px) {
		width: 100%;
		max-width: 100%;
		background: white;
		color: black;
		box-shadow: 0px 0px 5px #ccc;
		-moz-box-shadow: 0px 0px 5px #ccc;
		-webkit-box-shadow: 0px 0px 5px #ccc;
	}
	&:focus {
		outline: 0;
	}
`;

const StyledDivBox = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	width: 95%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 0px 15px 0px 15px;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	@media (max-width: 991.98px) {
		width: 100%;
		max-width: 100%;
		background: white;
		color: black;
		box-shadow: 0px 0px 5px #ccc;
		-moz-box-shadow: 0px 0px 5px #ccc;
		-webkit-box-shadow: 0px 0px 5px #ccc;
	}
	&:focus {
		outline: 0;
	}
`;

const StyledTextArea = styled.textarea`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 14px;
	line-height: 25px;
	width: 95%;
	border: none;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 3px;
	padding: 0px 15px 0px 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;
	@media (max-width: 991.98px) {
		width: 100%;
		max-width: 100%;
		background: white;
		color: black;
		box-shadow: 0px 0px 5px #ccc;
		-moz-box-shadow: 0px 0px 5px #ccc;
		-webkit-box-shadow: 0px 0px 5px #ccc;
	}
	&:focus {
		outline: 0;
	}
`;

const StyledInputRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-top: 4%;
	@media (max-width: 991.98px) {
		padding-top: 0%;
		padding-bottom: 0%;
		width: 100%;
		margin: 0px;
	}
`;

const StyledDateContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const StyledButtonRow = styled(Row)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-top: 40px;
	padding-right: 2%;
	margin-bottom: 40px;
	@media (max-width: 991.98px) {
		padding-right: 0px;
		margin-bottom: 40px;
	}
`;

const StyledSaveButton = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: black;
	color: white;
	border-radius: 3px;
	border: none;
	cursor: pointer;
	width: 105px;
	height: 50px;
	font-size: 16px;
	${({ disabled }) =>
		disabled &&
		`    background: #d3d3d3;
         color: "#DCDCDC";
         cursor: none;
    `}
	@media (max-width: 991.98px) {
		width: 100%;
	}
	&:focus {
		outline: 0;
	}
`;

const StyledSavedAlert = styled.div`
	margin-right: 20%;
	@media (max-width: 991.98px) {
		margin-right: 0px;
		width: 100%;
		margin-bottom: 20px;
	}
`;

export const BookingPhone = ({ option, setSave, ...props }) => {
	const [showPhoneModal, setShowPhoneModal] = useState(false);
	return (
		<>
			<StyledDivBox onClick={() => setShowPhoneModal(true)}>
				{option.value
					? formatPhoneNumberIntl(option.value.trim())
						? formatPhoneNumberIntl(option.value.trim())
						: option.value.trim()
					: ''}
			</StyledDivBox>

			<PhoneModal
				setShowModal={setShowPhoneModal}
				value={option.value ? option.value.trim() : ''}
				showModal={showPhoneModal}
				name="Your phone number"
				description={option.description}
				goBack={() => {
					setShowPhoneModal(false);
				}}
				{...props}
			/>
		</>
	);
};

const AccountsView = () => {
	const { loading, userData, updateUserFieldsBatch } = useUserContext();
	const [successMessage, setSuccessMessage] = useState('');

	useEffect(() => {
		const timer = setTimeout(() => setSuccessMessage(''), 4000);
		return () => clearTimeout(timer);
	}, [successMessage]);

	if (loading || !userData) {
		return <div>Loading</div>;
	}

	return (
		<StyledAccountsView>
			<Row noGutters>
				<StyledHeader>Account Details</StyledHeader>
			</Row>
			<Row
				noGutters
				className="d-flex flex-column justify-content-center align-items-center"
			>
				<ImageUpload id={userData?.id} />
			</Row>
			<Row noGutters as={StyledFormRow} className="pt-2">
				<Col lg={12}>
					<Formik
						initialValues={{
							firstName: userData.firstName,
							lastName: userData.lastName,
							email: userData.email,
							phoneNumber: userData.phoneNumber,
							address: userData.address,
							dateOfBirth: userData.dateOfBirth,
						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							await updateUserFieldsBatch(
								Object.keys(values).map((item) => ({
									field: item,
									value: values[item],
								}))
							);
							setSuccessMessage('Your changes have been successfully saved.');
							setSubmitting(false);
							resetForm({ values });
						}}
					>
						{({ dirty, isSubmitting, isValidating, setFieldValue, values }) => {
							return (
								<Form>
									<Row noGutters as={StyledInputRow}>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="firstName">First Name</StyledLabel>
											<Field
												type="text"
												name="firstName"
												as={StyledInputBox}
												placeholder="Amanda"
											/>
										</Col>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="lastName">Last Name</StyledLabel>
											<Field
												type="text"
												name="lastName"
												as={StyledInputBox}
												placeholder="Smith"
											/>
										</Col>
									</Row>
									<Row noGutters as={StyledInputRow}>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="email">Email</StyledLabel>
											<Field
												type="email"
												name="email"
												as={StyledInputBox}
												disabled
												placeholder="amanda-smith@gmail.com"
											/>
										</Col>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="phoneNumber">
												Phone Number
											</StyledLabel>
											{/* <Field
                        type='tel'
                        name='phoneNumber'
                        as={StyledInputBox}
                        placeholder='+44 7878 787878'
                      /> */}
											<Field
												as={BookingPhone}
												name="phoneNumber"
												option={{
													value: values.phoneNumber,
													title: 'Your phone number',
												}}
												onChange={async ({ target: { value } }) => {
													setFieldValue('phoneNumber', value);
												}}
											/>
										</Col>
									</Row>
									<Row noGutters as={StyledInputRow}>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="address">Address</StyledLabel>
											<Field
												placeholder="1 Pall Mall, SW1Y 5NG, London, UK"
												type="textarea"
												name="address"
												rows="2"
												as={StyledTextArea}
											/>
										</Col>
										<Col xs={12} lg={6} className="px-0 py-0">
											<StyledLabel htmlFor="dateOfBirth">
												Date of Birth
											</StyledLabel>
											<StyledDateContainer>
												<Field
													placeholder="26/02/92"
													type="date"
													name="dateOfBirth"
													as={StyledInputBox}
												/>
											</StyledDateContainer>
										</Col>
									</Row>
									<Row noGutters as={StyledButtonRow}>
										<>
											{successMessage !== '' && (
												<StyledSavedAlert>
													<Alert
														className="text-center my-auto mx-auto p-1 "
														variant={'success'}
														onClose={() => setSuccessMessage('')}
													>
														<PSansText>{successMessage}</PSansText>
													</Alert>
												</StyledSavedAlert>
											)}
										</>
										<StyledSaveButton
											type="submit"
											disabled={
												!dirty || (isSubmitting && !isValidating) ? true : false
											}
										>
											<StyledText>
												{isSubmitting ? <Spinner animation="grow" /> : 'Save'}
											</StyledText>
										</StyledSaveButton>
									</Row>
								</Form>
							);
						}}
					</Formik>
				</Col>
			</Row>
		</StyledAccountsView>
	);
};

export default AccountsView;
