import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
	Circle,
	DashHorizontal,
	BorderedCircle,
} from '../../../styles/ProductStyles';
import { H1, ParagraphGrey, Small } from '../../../styles/StylesV3Main';
import { useUserContext } from '../../../contexts/userContext';

const TitleRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 560px;
	height: fit-content;
	border-radius: 3px;
	margin: 32px 0px;
	@media (max-width: 992px) {
		width: 100%;
	}
`;

const Text1 = styled(Small)`
	margin-right: 48px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: -160px;
	}
`;
const Text2 = styled(Small)`
	margin-right: 40px;
	margin-left: 34px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: -69px;
	}
`;
const Text3 = styled(Small)`
	margin-left: 48px;

	@media (max-width: 500px) {
		position: absolute;
		top: 0px;
		left: 38px;
	}
`;

const CustomTitle = ({ type, cardType, service }) => {
	const { userData } = useUserContext();

	return (
		<TitleRow>
			<Row noGutters className="justify-content-center align-items-center">
				<Col className="justify-content-center align-items-center">
					<Circle size={'32px'} bg={'black'} />
				</Col>
				<Col className="justify-content-center align-items-center">
					<DashHorizontal />
				</Col>

				<Col className="justify-content-center align-items-center">
					<BorderedCircle
						size={'32px'}
						borderColor={'black'}
						borderWidth={'4px'}
					>
						<Circle size={'16px'} bg={'black'} />
					</BorderedCircle>
				</Col>
				<Col className="justify-content-center align-items-center">
					<DashHorizontal bg={'rgba(0, 0, 0, 0.1)'} />
				</Col>

				<Col className="justify-content-center align-items-center">
					<BorderedCircle
						size={'32px'}
						borderColor={'rgba(0, 0, 0, 0.1)'}
						borderWidth={'4px'}
					/>
				</Col>
			</Row>
			<Row
				noGutters
				className="justify-content-between align-items-center mt-1 mb-4"
				style={{ position: 'relative' }}
			>
				<Text1>Requested</Text1>

				<Text2>Processing</Text2>

				<Text3>Confirmed</Text3>
			</Row>
			<H1 className="mb-1 text-center">
				Processing your{' '}
				{type && type
					? type === 'card'
						? cardType === 'createCard'
							? 'request'
							: 'top-up'
						: type === 'concierge'
						? 'enquiry'
						: type === 'reimbursement'
						? 'expense'
						: 'order'
					: 'order'}
			</H1>
			<ParagraphGrey className="text-center">
				{type === 'reimbursement' &&
					'Reimbursements approved before Monday at 17:00 (GMT) will be transferred to your bank on Tuesday mornings. Reimbursements approved before Thursday at 16:00 (GMT) will be transferred on Thursday evening. Transfers are made through Wise, our payment provider.'}
				{type !== 'reimbursement' && (
					<>
						A confirmation email is on its way to {userData.email}. Please note,{' '}
						{type && type
							? type === 'card'
								? cardType === 'createCard'
									? 'requesting a Juno Flex Card® '
									: 'topping up '
								: type === 'concierge'
								? 'enquiries '
								: type === 'reimbursement'
								? 'expense '
								: 'some orders '
							: 'some orders '}
						takes some time to process. We will update you via email.
					</>
				)}
			</ParagraphGrey>
		</TitleRow>
	);
};

export default CustomTitle;
