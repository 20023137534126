import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { H3, ParagraphGrey } from '../../../styles/StylesV3Main';
import Points from '../../product/Points';
import config from '../../../config';

const StyledContainer = styled.div`
	width: 368px;
	height: 381px;
	border: 1px solid rgba(0, 0, 0, 0.1);

	&:hover {
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05),
			0px 20px 24px rgba(0, 0, 0, 0.08);
	}
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	cursor: pointer;

	@media (max-width: 1250px) {
		width: 320px;
	}
	@media (max-width: 1090px) {
		width: 290px;
	}

	@media (max-width: 992px) {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 16px;
	}
`;

const CardImage = styled.img`
	width: 100%;
	min-height: 280px;
	object-fit: cover;
	object-position: center;
	max-height: ${(props) => (props.size === 'hero' ? 'auto' : '25vh')};
`;

const SRow = styled(Row)`
	width: 100%;
	height: 98px;
	padding: 25px;
`;

const AddOnProductCard = ({ product }) => {
	return (
		<StyledContainer>
			<CardImage
				loading="lazy"
				src={
					product.imagePath
						? product.imagePath + '?width=500'
						: config.PLACEHOLDER_IMAGE
				}
			/>
			<SRow noGutters>
				<Col xs={10}>
					<H3>
						{product.title.length > 20
							? product.title.substring(0, 19) + '...'
							: product.title}
					</H3>
					<ParagraphGrey className="mt-2">
						{product.provider.title}
					</ParagraphGrey>
				</Col>
				<Col xs={2}>
					<Points product={product} size="intermediate" />
				</Col>
			</SRow>
		</StyledContainer>
	);
};

export default AddOnProductCard;
