import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import { useLocation, useHistory, Link, Redirect } from 'react-router-dom';
import { ReactComponent as Logo } from '../logowhite.svg';
import { StyledH1, StyledP1 } from '../styles/MainStyles';
import SignUp from '../components/signInUp/SignUp';
import TroubleLoginPopup from '../components/signInUp/TroubleLoginPopup';
import TermsAndConditionsFooter from '../components/utilities/TermsAndConditionsFooter';
import { useAuth0 } from '../contexts/react-auth0-spa';

const StyledLeftContainer = styled.div`
	width: 100%;
	height: 100%;
	@media only screen and (max-width: 991.98px) {
		display: none;
	}
`;

const StyledSide = styled.div`
	width: 100%;
	height: 100%;
	position: relative;

	@media only screen and (max-width: 1200px) {
		height: 75%;
	}
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 80%;
`;

const StyledLogo = styled.div`
	position: absolute;
	left: 32px;
	top: 32px;
`;

const StyledRightContainer = styled.div`
	width: 100%;
	height: 100vh;
`;

const MainContainer = styled.div`
	height: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media (max-width: 991.98px) {
		margin-top: 9%;
		justify-content: start;
	}
	@media (max-width: 400px) {
		margin-top: 4%;
	}
`;

const FormContainer = styled.div`
	width: 508px;
	height: 503px;
	@media (max-width: 991.98px) {
		width: 90%;
		height: 100%;
	}
	@media (max-width: 400px) {
		padding-bottom: 10%;
	}
`;

const TopButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	outline: none;

	height: 77px;
	top: 0px;
	postion: relative;
	margin-left: 40px;
	margin-right: 40px;
	@media (max-width: 991.98px) {
		margin-left: 20px;
		margin-right: 20px;
	}
`;

const StyledButtonText = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 13px;
	line-height: 22px;
	color: black;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;

const AlreadyHaveAccountTextBtn = styled.div`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 35px;
	margin-bottom: 0px;
	position: absolute;
	background: white;
	border: none;
	right: 16%;
	@media (max-width: 768px) {
		right: 30%;
	}
`;

const SignInUpButton = styled.button`
	font-family: 'Roobert Regular';
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 35px;
	width: 75px;
	border: 1px solid rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	padding: 10px;
	background: white;
	border: 1px solid black;
	cursor: pointer;
	&:focus {
		outline: none;
	}
`;

const SignUpForm = () => {
	const location = useLocation();
	const params = queryString.parse(location.search);
	const [mode, setMode] = useState(params?.referral ? 'signup' : 'login');
	const [error, setError] = useState('');
	const [showPopup, setShowPopup] = useState(true);
	const [percentRange, setProgress] = useState(33.33);
	const history = useHistory();
	const { isAuthenticated } = useAuth0();

	useEffect(() => {
		setError('');
		document.title = 'Juno - Sign Up';
	}, [mode]);

	const handleSignInUpClick = () => {
		if (mode === 'login') {
			setMode('signup');
		} else {
			setMode('login');
		}
		history.push('/login');
	};

	const handleNextSignUp = () => {
		console.log('click next');
		setProgress(percentRange < 100 ? percentRange + 33.33 : 100);
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	return (
		<Row noGutters>
			<Col xs={12} sm={12} md={12} lg={4}>
				<StyledLeftContainer>
					<StyledSide>
						<StyledImage loading="lazy" src="../images/login/login1.png" />
						<StyledLogo>
							<Logo style={{ stroke: 'none', fill: 'white' }} />
						</StyledLogo>
					</StyledSide>
				</StyledLeftContainer>
			</Col>
			<Col xs={12} sm={12} md={12} lg={8}>
				<StyledRightContainer>
					<TopButtonContainer>
						<AlreadyHaveAccountTextBtn>
							<StyledButtonText>Already have an account?</StyledButtonText>
						</AlreadyHaveAccountTextBtn>
						<SignInUpButton>
							<Link
								to="/login"
								style={{ textDecoration: 'none' }}
								onClick={handleSignInUpClick}
							>
								<StyledButtonText>Sign In</StyledButtonText>
							</Link>
						</SignInUpButton>
					</TopButtonContainer>

					<MainContainer>
						{error !== '' && showPopup && (
							<TroubleLoginPopup handleClosePopup={handleClosePopup} />
						)}
						<FormContainer>
							<StyledH1>Create your account</StyledH1>
							<StyledP1>
								Your account is nearly ready — choose a password and enter your
								payment details to continue. Don&apos;t worry, you won&apos;t be
								billed until you add team members to your plan.
							</StyledP1>
							<SignUp
								errorCallback={(err) => setError(err)}
								handleNextSignUp={handleNextSignUp}
							/>
							<Row noGutters className="mt-3">
								<TermsAndConditionsFooter text="By signing up to Juno you agree to our" />
							</Row>
						</FormContainer>
					</MainContainer>
				</StyledRightContainer>
			</Col>
		</Row>
	);
};

export default SignUpForm;
