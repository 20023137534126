/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useUserContext } from './userContext';
import useClient from '../hooks/useClient';
import config from '../config';

export const ProductContext = React.createContext();
export const useProductContext = () => useContext(ProductContext);
export const ProductProvider = ({ children }) => {
	const client = useClient();
	const [products, setProducts] = useState();
	const [allProducts, setAllProducts] = useState();
	const [customProducts, setCustomProducts] = useState();
	const [brandedProducts, setBrandedProducts] = useState();
	const [categories, setCategories] = useState();
	const [loading, setLoading] = useState(true);
	const { userData } = useUserContext();
	const recommendedProductsData = useRef({});
	const [allEvents, setAllEvents] = useState();
	const [eventId, setEventId] = useState();
	const [event, setEvent] = useState();
	const [orderBy, setOrderBy] = useState('popularity');
	const [customEventIds, setCustomEventIds] = useState('');
	const [customEvents, setCustomEvents] = useState();
	const eventsById = useRef({});
	const searchCache = useRef({});

	const recommendedProducts = async (product) => {
		try {
			if (!recommendedProductsData.current[product]) {
				const response = await client.get(`/products/${product}/recommended`);
				recommendedProductsData.current[product] = response.data;
			}
			return recommendedProductsData.current[product];
		} catch (error) {
			console.error(error);
		}
		return products.slice(0, 10);
	};

	const getAllEvents = async () => {
		try {
			const response = await client.get(
				`${config.products.HOST}/products/events?sortBy=${orderBy}`
			);

			if (response?.status === 200 && response.data) {
				setAllEvents(response.data);
				response.data.forEach((newEvent) => {
					eventsById.current[newEvent.id] = newEvent;
				});
			}
		} catch (error) {
			console.error(error);
			setAllEvents([]);
		}
	};

	const search = async (searchParam) => {
		if (
			searchCache.current.userData?.locationData?.address?.formatted_address !==
			userData?.locationData?.address?.formatted_address
		)
			searchCache.current = {};

		if (searchCache.current[searchParam])
			return searchCache.current[searchParam];
		const response = await client.get(
			`${config.products.HOST}/products/focus/search/${encodeURIComponent(
				searchParam
			)}`
		);
		if (response?.status === 200 && response.data) {
			searchCache.current.userData = userData;
			searchCache.current[searchParam] = response.data;
			response.data.forEach((newEvent) => {
				if (newEvent.type === 'Event') {
					const evtId = newEvent.id.replace('t_', '');
					eventsById.current[evtId] = { ...newEvent, id: evtId };
				}
			});
		}
		return response.data;
	};

	const reason = async (searchParam, product) => {
		if (searchCache.current[searchParam]?.reasons?.[product])
			return searchCache.current[searchParam].reasons[product];
		const {
			data: { reason: focusReason },
		} = await client.get(
			`${
				config.products.HOST
			}/products/focus/reason/${product}/${encodeURIComponent(searchParam)}`
		);
		const result = searchCache.current[searchParam];
		if (result) {
			result.reasons = result.reasons || {};
			result.reasons[product] = focusReason;
		}
		return focusReason;
	};

	const fetchEventDetail = async (evtId) => {
		try {
			let currentEvent = eventsById.current[evtId];
			setEvent(currentEvent);
			if (!currentEvent?.shortDescription) {
				const response = await client.get(
					`${config.products.HOST}/products/events/${evtId}`
				);
				if (response.status === 200 && response.data) {
					currentEvent = response.data;
					eventsById.current[evtId] = response.data;
					if (evtId === eventId) setEvent(currentEvent);
				}
			}
			return currentEvent;
		} catch (error) {
			console.error(error);
		}
		return null;
	};

	const getCustomEvents = async () => {
		const customEventsList = await Promise.all(
			customEventIds
				.split(',')
				.map(async (i) => (i ? fetchEventDetail(i.trim()) : null))
		);
		setCustomEvents(customEventsList.filter((customEvent) => customEvent));
	};

	useEffect(() => {
		try {
			if (userData && allProducts) {
				const userProducts = [];
				const userBrandedProducts = [];
				const userCustomProducts = [];
				const categoriesLookup = {};
				const userCategories = [];
				const isOwner =
					userData.currentTeam &&
					userData.teams.filter((team) => team.id === userData.currentTeam)
						.length > 0 &&
					userData.teams
						.filter((team) => team.id === userData.currentTeam)[0]
						.teamMember.roles.includes('owner');

				const country = userData.locationData?.address.address_components?.find(
					(loc) => loc.types.includes('country')
				)?.long_name;

				allProducts
					.map((product) => ({
						...product,
						productTypes: product.productTypes.filter(
							(productType) => !productType.groupBooking || isOwner
						),
					}))
					.filter(
						(item) =>
							item.customProduct ||
							((item.productTypes.length > 0 || item.type === 'Card') &&
								(country
									? item.type === 'Online' ||
									  item.countries.Global ||
									  item.countries[country]
									: true))
					)
					.forEach((item) => {
						if (item.type === 'Card') {
							userBrandedProducts.push(item);
						}

						if (!item.customProduct) userProducts.push(item);
						else userCustomProducts.push(item);

						if (item.customProduct) return;

						item.categories.forEach((cat) => {
							if (cat.filter) return;

							if (!categoriesLookup[cat.id]) {
								// eslint-disable-next-line no-param-reassign
								cat.logos = [item.provider.src];
								userCategories.push(cat);
								categoriesLookup[cat.id] = cat;
							} else {
								item.provider.src &&
									categoriesLookup[cat.id].logos.push(item.provider.src);
							}
						});
					});

				setProducts(userProducts);
				setBrandedProducts(userBrandedProducts);
				setCustomProducts(userCustomProducts);
				setCategories(userCategories);
				recommendedProductsData.current = {};
				setLoading(false);
			}
		} catch (error) {
			console.error(error);
		}
	}, [userData, allProducts]);

	useEffect(() => {
		if (userData) {
			const [city] =
				userData?.locationData?.address.address_components?.filter(
					(loc: any) =>
						loc.types.includes('locality') || loc.types.includes('postal_town')
				) || [];
			if (orderBy === 'proximity' && !city?.long_name) setOrderBy('popularity');
			else getAllEvents();
		}
	}, [userData, orderBy]);

	useEffect(() => {
		if (eventId) fetchEventDetail(eventId);
	}, [eventId]);

	useEffect(() => {
		if (customEventIds) getCustomEvents();
	}, [customEventIds]);

	useEffect(async () => {
		if (allProducts) return;
		const response = await client.get('/products');
		setAllProducts(response.data);
	}, [allProducts]);

	const contextValue = useMemo(
		() => ({
			products,
			allProducts,
			customProducts,
			brandedProducts,
			categories,
			loading,
			recommendedProducts,
			allEvents,
			setOrderBy,
			orderBy,
			customEvents,
			setCustomEventIds,
			event: event?.id === eventId ? event : eventsById[eventId],
			eventId,
			setEventId,
			search,
			reason,
		}),
		[
			products,
			allProducts,
			customProducts,
			brandedProducts,
			categories,
			loading,
			recommendedProducts,
			allEvents,
			orderBy,
			customEvents,
			event,
			eventId,
			search,
			reason,
		]
	);

	return (
		<ProductContext.Provider value={contextValue}>
			{children}
		</ProductContext.Provider>
	);
};
