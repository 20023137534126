import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyedLink = styled(Link)<{ src: string }>`
	padding: 0;
	border: none;

	height: 325px;
	width: 260px;
	background: #ffffff;
	box-shadow: 0px 0px 22.4px rgba(0, 0, 0, 0.04);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-grow: 1;
	margin-bottom: 75px;

	@media (max-width: 500px) {
		margin-bottom: 50px;
	}

	> div:first-of-type {
		width: 100%;
		height: 43%;

		background: url(${(props) => props.src}) center no-repeat, #bbbec2;

		background-size: cover;
	}

	> div:nth-of-type(2) {
		margin: 16px 22px 22px 20px;
		height: 84px;

		h3 {
			font-family: 'TTInterfaces DemiBold';
			font-size: 21px;
			line-height: 21px;

			display: flex;
			align-items: center;
			color: #0a0f16;
		}

		p {
			font-family: 'TTInterfaces Medium';
			font-style: normal;
			font-size: 17px;

			color: #000000;
			opacity: 0.4;
			margin: 0;
			text-align: left;
		}
	}

	> div:nth-of-type(3) {
		margin: 0px 22px;
		display: flex;
		flex-direction: row;
		gap: 8px;
	}
`;

const StyledLogo = styled.img`
	height: 47px;
	width: 47px;

	margin-left: 50%;
	margin-top: 50%;
	transform: translateX(-50%) translateY(-50%);
`;

const Category = ({ name, description, src, route, logos }: any) => {
	return (
		<StyedLink
			to={route}
			style={{
				textDecoration: 'none',
			}}
			src={src}
		>
			<div />

			<div>
				<h3>{name}</h3>

				<p>{description}</p>
			</div>

			<div>
				{logos &&
					logos.map((logo: { alt: string; img: string }, idx: number) => (
						<div
							// eslint-disable-next-line react/no-array-index-key
							key={idx}
							style={{
								height: '45px',
								width: '45px',
								border: '0.5px solid #e4e4e4',
								borderRadius: '12px',
								overflow: 'hidden',
							}}
						>
							<StyledLogo
								alt={`${logo.alt}-logo`}
								src={`${logo.img}?width=80px&height=80px`}
							/>
						</div>
					))}
			</div>
		</StyedLink>
	);
};

export default Category;
